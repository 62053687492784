import { Component, OnDestroy } from '@angular/core';
import { SharedataService } from '@services/sharedata.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history-api',
  templateUrl: './history-api.component.html',
  styleUrls: ['./history-api.component.scss'],
})
export class HistoryApiComponent implements OnDestroy {
  history: string[] = ['/application/home'];
  private subscription$: Subscription;

  constructor(private data: SharedataService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.subscription$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd),
      )
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        const route = this.activatedRoute.firstChild;
        const snapshot = route.snapshot.data;

        const history: string[] = this.data.getHistory();

        const index = history.findIndex(h => h === 'goBack');
        if (snapshot.setHistory === false) {
          return;
        }

        if (index !== -1) {
          history.splice(-1, 1);
          this.data.setHistory(history);
        } else {
          if (!snapshot.setHistory && snapshot.backURL) {
            this.data.setHistory([...history, snapshot.backURL]);
          } else if (snapshot.setHistory && snapshot.backURL) {
            this.data.setHistory(HistoryApiComponent.filteredHistory([snapshot.backURL, urlAfterRedirects], history));
          } else {
            if (snapshot.setHistory !== false) {
              if (!urlAfterRedirects.includes('template')) {
                this.data.setHistory(HistoryApiComponent.filteredHistory([urlAfterRedirects], history));
              }
            }
          }
        }
      });
  }

  static filteredHistory(newUrls: string[], history: string[]): string[] {
    const lastUrl = history[history.length - 1];
    if (newUrls && newUrls.length) {
      newUrls.forEach(newUrl => {
        if (lastUrl !== newUrl) {
          history = [...history, newUrl];
        }
      });
    }
    return history;
  }

  ngOnDestroy(): void {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }
}
