import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedataService } from '@services/sharedata.service';
import { NotifyService } from '@services/notify.service';
import { IError } from '@interfaces/siam';
import { siamConst } from '@interfaces/siamConst';
import { SiamAuthorizationError, SiamServerConnectionError } from '@interfaces/siamError';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private dataService: SharedataService, private window: Window) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // start loader here - request started
    return next.handle(request).pipe(
      tap({
        next: event => {
          if (event instanceof HttpResponse) {
            // stop loader here - request ended
          }
        }
      }),
      catchError((error: IError) => {
        // ignore document Options request Fehler
        if (request.method === 'OPTIONS') {
          return EMPTY;
        }
        switch (error.status) {
          case 401:
            localStorage.removeItem(siamConst.currentUser);
            return throwError(() => new SiamAuthorizationError());

          case 503:
            this.dataService.polling$.next(true);
            // TODO replace on dxLoadPanel
            this.onServerLostConnection();
            break;
          case 504: // gateway timeout
            return throwError(() => new SiamServerConnectionError());

          case 901:
            return throwError(() => error);

          default:
            NotifyService.serverGlobalError(error, request);
            break;
        }
        return throwError(() => error);
      })
    );
  }

  private onServerLostConnection(): void {
    NotifyService.global.error('Verbindung zum Server verloren.');
    const body = this.window.document.querySelector('body');
    if (body) {
      body.classList.add('no-server');
    }
  }
}
