<div>
    <app-component-notification></app-component-notification>
    <div id="kanban">
        <dx-scroll-view
                class="scrollable-board"
                direction="horizontal"
                showScrollbar="always">

            <dx-sortable
                    class="sortable-lists"
                    itemOrientation="horizontal"
                    handle=".list-title"
                    (onReorder)="onListReorder($event)">
                <div class="list" *ngFor="let panel of taskPanels; let listIndex = index">
                    <div class="list-title">
                        {{ panel.label }}
                        <span class="tasks-quantity">{{ panel.tasks.length }}</span>
                    </div>

                    <dx-scroll-view
                            class="scrollable-list"
                            direction="vertical"
                            showScrollbar="always">

                        <dx-sortable
                                class="sortable-cards"
                                group="cardsGroup"
                                [data]="panel.tasks"
                                (onDragStart)="onTaskDragStart($event)"
                                (onReorder)="onTaskDrop($event)"
                                (onAdd)="onTaskDrop($event)">

                            <div class="card dx-card" *ngFor="let task of panel.tasks">
                                <div class="card-priority" [ngClass]="'priority-' + task.priority"></div>
                                <div class="card-subject" (click)="selectTask(task)">{{ task.subject }}</div>
                                <div class="card-info">
                                    <div class="card-info-chair" *ngIf="task.chairurl">
                                        <img class="user-avatar-name" src="{{ task.chairurl }}"/>
                                    </div>
                                    <div class="card-info-date" *ngIf="task.enddate">
                                        {{ task.enddate | date }}
                                    </div>
                                </div>
                            </div>
                        </dx-sortable>
                    </dx-scroll-view>
                </div>
            </dx-sortable>
        </dx-scroll-view>
    </div>
</div>
