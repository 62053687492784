import { Pipe, PipeTransform } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ListsService } from '@services/lists.service';
import { SiamListItem } from '@interfaces/siam';

@Pipe({
  name: 'getDecisionType'
})
export class GetDecisionTypePipe implements PipeTransform {
  constructor(private listsService: ListsService) {
  }

  async transform(value: string): Promise<string> {
    const decisionTypes: SiamListItem[] = await lastValueFrom(this.listsService.getListEntries('Beschlussvermerke'));
    if (decisionTypes) {
      const decisionValue = decisionTypes.find(ref => ref.value === value);
      return decisionValue.label;
    }
    return value;
  }
}
