import { Pipe, PipeTransform } from '@angular/core';
import { IDocument, TEffectivePermission } from '@interfaces/siam';

@Pipe({
  name: 'fieldHasPermission'
})
export class FieldHasPermissionPipe implements PipeTransform {
  transform(document: IDocument, field: string, permission: TEffectivePermission): boolean {
    let result = false;
    if (document.fields[field]?.effectivePermissions) {
      result = !document.fields[field]?.effectivePermissions?.includes(permission);
    }
    return result;
  }
}
