import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) {
  }

  /**
   * Always should be used inside [innerHtml] directive,
   * otherwise will receive warning inside the resultant string
   *
   * @param value
   * @param options
   */
  transform(value: string, options?: string): SafeUrl | SafeHtml {
    if (options === 'url') {
      return this.sanitized.bypassSecurityTrustUrl(value);
    }
    return this.sanitized.bypassSecurityTrustHtml(value?.replaceAll('\n', '<br>'));
  }
}
