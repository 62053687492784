import { clone, sortBy } from '@factories/helpers';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ListsService } from '@services/lists.service';
import { DxListComponent } from 'devextreme-angular';
import { DocumentService } from '@services/document.service';
import { IDocument, IEventLabel, ILabel } from '@interfaces/siam';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-labels-include',
  templateUrl: './labels-include.component.html',
  styleUrls: ['./labels-include.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LabelsIncludeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DxListComponent) labelList: DxListComponent;
  @Input() currentDocument: IDocument;
  @Output() labelResult: EventEmitter<IEventLabel> = new EventEmitter<IEventLabel>();

  label: string[] = [];
  labelsDropDownListVisible = false;
  isLabelsChanged = false;
  targetLabels: EventTarget;

  globalLabels: ILabel[] = [];
  selectedLabels: ILabel[] = [];

  private $destroyable = new Subject<void>();

  constructor(private listService: ListsService, private documentService: DocumentService) {
    this.listService
      .getGlobalLabels()
      .pipe(takeUntil(this.$destroyable))
      .subscribe(labels => {
        const filteredLabels = clone(labels);
        this.globalLabels = sortBy(filteredLabels, 'name');
      });
  }

  ngOnInit(): void {
    if (this.currentDocument) {
      this.documentService
        .getLabels(this.currentDocument)
        .pipe(takeUntil(this.$destroyable))
        .subscribe(labels => (this.selectedLabels = labels));
    }
  }

  ngOnChanges(): void {
    if (this.currentDocument) {
      this.documentService
        .getLabels(this.currentDocument)
        .pipe(takeUntil(this.$destroyable))
        .subscribe(labels => {
          this.selectedLabels = sortBy(labels, 'name');
          if (Array.isArray(this.globalLabels)) {
            this.globalLabels.forEach(n => {
              if (n?.deactivated === true) {
                const isFound = this.selectedLabels.some(value => value.value === n.value);
                if (isFound) {
                  n.visible = true;
                } else {
                  n.visible = false;
                }
              }
            });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.$destroyable.next();
    this.$destroyable.complete();
  }

  onHidden(): void {
    if (this.isLabelsChanged) {
      this.labelResult.emit({
        command: 'select',
        labels: this.selectedLabels
      });
      this.isLabelsChanged = false;
    }
  }

  onSelectionChanged(): void {
    this.isLabelsChanged = true;
  }

  showLabelSelector(target: EventTarget): void {
    this.labelsDropDownListVisible = true;
    this.targetLabels = target;
  }
}
