import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectBox } from '@interfaces/fieldClient';
import { ValidationRule } from 'devextreme-angular/common';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
@Component({
  selector: 'app-form-selectbox',
  templateUrl: './form-selectbox.component.html',
  styleUrls: ['./form-selectbox.component.scss']
})
export class FormSelectboxComponent {
  @Input() isEditing = false;

  @Input() text: string;

  @Input() field: SelectBox;

  @Input() label = '';

  @Input() mask: string = null;

  @Input() validationGroupId: string = null;

  @Input() validators: ValidationRule[] = [];

  @Input() value!: string;

  @Output() valueChange = new EventEmitter<string>();

  valueChanged(e: ValueChangedEvent) {
    this.valueChange.emit(e.value as string);
  }
}
