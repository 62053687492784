import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, zip } from 'rxjs';
import { ListsService } from '@services/lists.service';
import { TagsService } from '@services/tags.service';
import { SharedataService } from '@services/sharedata.service';
import { ICategory, IDynamicList, INavigationGroup, IUser, TApplicationMode } from '@interfaces/siam';
import { siamConst } from '@interfaces/siamConst';
import { LoginService } from '@services/login.service';
import { takeUntil, map } from 'rxjs/operators';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit, OnDestroy {
  applicationMode: TApplicationMode = 'application';

  navigationDataSource: INavigationGroup[];

  navigationDataSourceDocuments: INavigationGroup = {
    name: 'vorgaenge',
    text: 'Vorgänge',
    isClosed: false,
    forAdminOnly: false,
    links: []
  };

  navigationDataSourceTools: INavigationGroup = {
    name: 'tools',
    text: 'Tools',
    isClosed: true,
    forAdminOnly: false,
    links: [
      {
        id: 10,
        text: 'Managercockpit',
        name: 'managercockpit',
        icon: 'bar_chart',
        routerLink: ['', 'dashboard']
      }
    ]
  };

  #currentUser: IUser;
  #destroyable$ = new Subject<void>();

  constructor(
    private listsService: ListsService,
    private loginService: LoginService,
    private tagsService: TagsService,
    private sharedDataService: SharedataService
  ) {}

  ngOnInit(): void {
    this.loginService.getCurrentUser
      .pipe(
        switchMap(user => {
          this.#currentUser = user;
          return zip(
            this.listsService.getList(siamConst.globalTagsListName),
            this.listsService.getList(siamConst.dynamicListsListName)
          );
        }),
        map(response => ({ tags: response[0], dynamicLists: response[1] })),
        takeUntil(this.#destroyable$)
      )
      .subscribe(list => {
        this.navigationDataSourceDocuments.links = [];
        this.tagsService.setGlobalTagsList(list.tags);
        let index = 1;
        let dynamicViews: IDynamicList[] = [];
        if (list.dynamicLists) {
          dynamicViews = list.dynamicLists.entries.map(entry => this.listsService.getDynamicListEntryData(entry));
        }
        const navigationGroups: INavigationGroup[] = [];
        dynamicViews
          ?.filter(tag => tag.isGroup)
          .forEach(grp => {
            if (this.isUserHasAccess(grp) && grp.isVisible) {
              navigationGroups.push({
                name: grp.name,
                text: grp.label,
                isClosed: grp.isClosed,
                forAdminOnly: false,
                links: [],
                icon: grp.icon
              });
            }
          });
        if (!navigationGroups.find(grp => grp.name === this.navigationDataSourceDocuments.name)) {
          navigationGroups.unshift(this.navigationDataSourceDocuments);
        }

        dynamicViews
          ?.filter(tag => !tag.isGroup)
          .forEach(tag => {
            if (this.isUserHasAccess(tag) && tag.isVisible) {
              // assign view without group to dafault group (Vorgänge)
              if (!tag.groupName) {
                const defaultgroupIndex = navigationGroups.findIndex(
                  grp => grp.name === this.navigationDataSourceDocuments.name
                );
                navigationGroups[defaultgroupIndex].links.push({
                  id: index,
                  text: tag.label,
                  name: tag.name,
                  icon: tag.icon,
                  routerLink: ['documents', tag.name]
                });
              }
              // assign view to group
              const groupIndex = navigationGroups.findIndex(grp => grp.name === tag.groupName);
              if (groupIndex > -1) {
                if (dynamicViews.find(g => g.name === tag.groupName).isVisible) {
                  navigationGroups[groupIndex].links.push({
                    id: index,
                    text: tag.label,
                    name: tag.name,
                    icon: tag.icon,
                    routerLink: ['documents', tag.name]
                  });
                }
              }
              index++;
            }
          });

        this.navigationDataSourceDocuments.links.sort((a, b) => a.id - b.id);
        this.navigationDataSource = [
          ...navigationGroups.filter(grp => grp.links.length),
          this.navigationDataSourceTools
        ];
      });
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  getScrollPosition(position: number): void {
    let value = false;
    if (position > 20) {
      value = true;
    }
    this.sharedDataService.setScrollToTop(value);
  }

  private isUserHasAccess(category: ICategory | IDynamicList): boolean {
    if (!category.access) {
      return true;
    }
    if (!category.access.users?.length && !category.access.roles?.length) {
      return true;
    }
    if (category.access.users?.includes(this.#currentUser.id)) {
      return true;
    }

    for (const role of this.#currentUser.roles) {
      if (category.access.roles?.includes(role.id)) {
        return true;
      }
    }

    return false;
  }
}
