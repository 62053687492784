import { ISiamListPropriety, SiamListItem, SiamListPropriety } from './siam';
import { siamConst } from './siamConst';

export class SiamList {
  id: string = null;
  name = '';
  description = '';
  tags: string[] = [];
  entries: SiamListItem[] = [];
  properties: ISiamListPropriety = { dataType: 'string' };

  constructor(init?: Partial<SiamList>) {
    if (init) {
      this.id = init.id || null;
      this.name = init.name || '';
      this.description = init.description || '';
      this.tags = init.tags || [];
      this.entries = init.entries?.map(entry => Object.assign({}, entry)) || [];
      const dataType =
        ((this.entries[0]?.properties as Record<string, string>)?.dataType as SiamListPropriety) || 'string';
      const isTreeList = init?.properties?.isTreeList || this.tags.includes(siamConst.treelistTag);
      this.properties = Object.keys(init.properties)?.length ? init.properties : { dataType, isTreeList };
    }
  }

  isEqual(list: SiamList): boolean {
    if (this.name && list.name) {
      return this.name.trim().toLowerCase() === list.name.trim().toLowerCase();
    }
    return !(!this.name || !list.name);
  }
}
