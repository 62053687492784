import { DocumentService } from '@services/document.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IDocument, TEditMode, TNavigationSource } from '@interfaces/siam';
import { LoggerService } from './logger.service';
import { SharedataService } from './sharedata.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentHelperService {
  constructor(
    private logger: LoggerService,
    private documentService: DocumentService,
    private router: Router,
    private data: SharedataService
  ) {}

  openDocument(
    document: IDocument,
    editMode: TEditMode = 'Edit',
    navigationSource: TNavigationSource = 'application'
  ): void {
    const docType = this.documentService.getTypeName(document);
    this.logger.debug('=== document-helper.openDocument() editMode "{@editMode}"', editMode);
    this.logger.debug('=== document-helper.openDocument() for documenttype "{@type}"', docType);
    let params = {};
    if (editMode !== 'ReadOnly') {
      params = { queryParams: { editMode } };
    }
    this.router.navigate([navigationSource, 'documents', docType, document.id], params).then(null, error => {
      this.logger.error('Fehler beim Öffnung des Dokument {@id}: {@error}', document.id, error);
    });
    const isNavPinned = JSON.parse(localStorage.getItem('navigation-pinned')) as boolean;
    if (!isNavPinned) {
      this.data.hideNavigationPane(true);
    }
  }
}
