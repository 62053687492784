<div #chartContainer id="chartContainer" class="ta-r">
  <span class="material-icons fullscreen" (click)="openChart()" style="cursor: pointer" title="Vollbildmodus umschalten"> </span>
  <ng-container [ngTemplateOutlet]="chartDiagram"></ng-container>
</div>

<ng-template #chartDiagram>
  <div *ngIf="dataSource?.length">
    <!-- Chart piechart -->
    <dx-pie-chart
      *ngIf="chart && chart.chartSettings.type === 'piechart'"
      id="piechart"
      [elementAttr]="{ class: 'card-chart' }"
      [palette]="chart.chartSettings.palette"
      [series]="pieChartSeries"
      [dataSource]="dataSource">
      <dxo-title [text]="chart.chartSettings.title">
        <dxo-subtitle [text]="chart.chartSettings.subtitle"></dxo-subtitle>
      </dxo-title>
      <dxo-label [visible]="true">
        <dxo-connector [visible]="true" [width]="1"></dxo-connector>
      </dxo-label>
      <dxo-tooltip
        [enabled]="chart.chartSettings?.tooltip?.visible"
        [zIndex]="9999"
        [customizeTooltip]="customizeTooltip"></dxo-tooltip>

      <dxo-export [enabled]="false"></dxo-export>
    </dx-pie-chart>
    <!-- Chart doughnut -->
    <dx-pie-chart
      *ngIf="chart && chart.chartSettings.type === 'doughnut'"
      id="doughnut"
      type="doughnut"
      [elementAttr]="{ class: 'card-chart' }"
      [palette]="chart.chartSettings.palette"
      [series]="pieChartSeries"
      innerRadius="0.2"
      [dataSource]="dataSource">
      <dxo-title [text]="chart.chartSettings.title">
        <dxo-subtitle [text]="chart.chartSettings.subtitle"></dxo-subtitle>
      </dxo-title>
      <dxo-tooltip
        [enabled]="chart.chartSettings?.tooltip?.visible"
        [zIndex]="9999"
        [customizeTooltip]="customizeTooltip"></dxo-tooltip>
      <dxo-export [enabled]="false"></dxo-export>
    </dx-pie-chart>
    <!-- Chart stackedbar -->
    <dx-chart
      *ngIf="chart && chart.chartSettings.type === 'stackedbar'"
      id="stackedbar"
      [elementAttr]="{ class: 'card-chart' }"
      [dataSource]="dataSource"
      [palette]="chart.chartSettings.palette"
      [series]="series"
      [commonSeriesSettings]="commonSeriesSettings">
      <dxo-title [text]="chart.chartSettings.title">
        <dxo-subtitle [text]="chart.chartSettings.subtitle"></dxo-subtitle>
      </dxo-title>
      <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="top"> </dxo-legend>
      <dxo-tooltip
        [enabled]="chart.chartSettings?.tooltip?.visible"
        location="edge"
        [zIndex]="9999"
        [customizeTooltip]="customizeTooltip">
      </dxo-tooltip>
    </dx-chart>
  </div>
  <div *ngIf="!dataSource?.length" style="text-align: center">
    <div class="chart-header">
      <div class="chart-title">{{ chart.chartSettings.title }}</div>
      <div class="chart-sub-title">{{ chart.chartSettings.subtitle }}</div>
    </div>
    <div class="chart-body">
      <span>{{ noDataText }}</span>
    </div>
  </div>
</ng-template>

<dx-popup
  id="popupChart"
  #popupChart
  width="70vw"
  height="90vh"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [(visible)]="showInfoPopup"
  [fullScreen]="false"
  [showTitle]="false"
  [showCloseButton]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [shading]="true"
  [toolbarItems]="infoPopupToolbarItems"
  (onShowing)="onShowingChartPopup()"
  (onHidden)="closeInfoPopup()">
  <div *dxTemplate="let data of 'content'">
    <ng-container [ngTemplateOutlet]="chartDiagram"></ng-container>
  </div>
</dx-popup>
