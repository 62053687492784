import { switchMap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { clone } from '../factories/helpers';
import { IBackground, IClientConfiguration, ITheme } from '@interfaces/siam';
import { SharedataService } from './sharedata.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme: string;
  private readonly themesList: ITheme[] = [
    {
      name: 'Carbon',
      cssClass: 'theme-carbon',
      imageSrc: '/assets/img/theme-pic/carbon.jpg'
    },
    {
      name: 'Clear',
      cssClass: 'theme-clear',
      imageSrc: '/assets/img/theme-pic/clear.jpg'
    },
    {
      name: 'Dark',
      cssClass: 'theme-dark',
      imageSrc: '/assets/img/theme-pic/dark.jpg'
    },
    {
      name: 'Dark Moon',
      cssClass: 'theme-dark-moon',
      imageSrc: '/assets/img/theme-pic/dark-moon.jpg'
    },
    {
      name: 'Dark Turquoise',
      cssClass: 'theme-dark-turquoise',
      imageSrc: '/assets/img/theme-pic/dark-turquoise.jpg'
    },
    {
      name: 'Lava',
      cssClass: 'theme-lava',
      imageSrc: '/assets/img/theme-pic/lava.jpg'
    },
    {
      name: 'Light Blue',
      cssClass: 'theme-light-blue',
      imageSrc: '/assets/img/theme-pic/light-blue.jpg'
    },
    {
      name: 'Light Green',
      cssClass: 'theme-light-green',
      imageSrc: '/assets/img/theme-pic/light-green.jpg'
    },
    {
      name: 'Light Red',
      cssClass: 'theme-light-red',
      imageSrc: '/assets/img/theme-pic/light-red.jpg'
    },
    {
      name: 'Sky',
      cssClass: 'theme-sky',
      imageSrc: '/assets/img/theme-pic/sky.jpg'
    }
  ];

  private readonly backgroundsList: IBackground[] = [
    {
      title: 'Bamboo',
      background: 'bamboo',
      backgroundSrc: '/assets/img/background-pic/bamboo.jpg',
      previewSrc: '/assets/img/background-pic/bamboo_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Calla',
      background: 'calla',
      backgroundSrc: '/assets/img/background-pic/calla.jpg',
      previewSrc: '/assets/img/background-pic/calla_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Christmas',
      background: 'christmas',
      backgroundSrc: '/assets/img/background-pic/christmas.jpg',
      previewSrc: '/assets/img/background-pic/christmas_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Court',
      background: 'court',
      backgroundSrc: '/assets/img/background-pic/court.jpg',
      previewSrc: '/assets/img/background-pic/court_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Facade',
      background: 'facade',
      backgroundSrc: '/assets/img/background-pic/facade.jpg',
      previewSrc: '/assets/img/background-pic/facade_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Graffiti',
      background: 'graffiti',
      backgroundSrc: '/assets/img/background-pic/graffiti.jpg',
      previewSrc: '/assets/img/background-pic/graffiti_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Graffiti 2',
      background: 'graffiti_2',
      backgroundSrc: '/assets/img/background-pic/graffiti_2.jpg',
      previewSrc: '/assets/img/background-pic/graffiti_2_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Graffiti 3',
      background: 'graffiti_3',
      backgroundSrc: '/assets/img/background-pic/graffiti_3.jpg',
      previewSrc: '/assets/img/background-pic/graffiti_3_prev.jpg',
      colorMode: 'light'
    },

    {
      title: 'Jellyfish',
      background: 'jellyfish',
      backgroundSrc: '/assets/img/background-pic/jellyfish.jpg',
      previewSrc: '/assets/img/background-pic/jellyfish_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Lily of the valley',
      background: 'lily-of-the-valley',
      backgroundSrc: '/assets/img/background-pic/lily-of-the-valley.jpg',
      previewSrc: '/assets/img/background-pic/lily-of-the-valley_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Maybach',
      background: 'maybach',
      backgroundSrc: '/assets/img/background-pic/maybach.jpg',
      previewSrc: '/assets/img/background-pic/maybach_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Monk',
      background: 'monk',
      backgroundSrc: '/assets/img/background-pic/monk.jpg',
      previewSrc: '/assets/img/background-pic/monk_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Mushrooms',
      background: 'mushrooms',
      backgroundSrc: '/assets/img/background-pic/mushrooms.jpg',
      previewSrc: '/assets/img/background-pic/mushrooms_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Origami',
      background: 'origami',
      backgroundSrc: '/assets/img/background-pic/origami.jpg',
      previewSrc: '/assets/img/background-pic/origami_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Pampas',
      background: 'pampas',
      backgroundSrc: '/assets/img/background-pic/pampas.jpg',
      previewSrc: '/assets/img/background-pic/pampas_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Pattern',
      background: 'pattern',
      backgroundSrc: '/assets/img/background-pic/pattern.jpg',
      previewSrc: '/assets/img/background-pic/pattern_prev.jpg',
      colorMode: 'dark'
    },
    {
      title: 'Pattern 2',
      background: 'pattern_2',
      backgroundSrc: '/assets/img/background-pic/pattern_2.jpg',
      previewSrc: '/assets/img/background-pic/pattern_2_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Pattern 3',
      background: 'pattern_3',
      backgroundSrc: '/assets/img/background-pic/pattern_3.jpg',
      previewSrc: '/assets/img/background-pic/pattern_3_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Pattern 4',
      background: 'pattern_4',
      backgroundSrc: '/assets/img/background-pic/pattern_4.jpg',
      previewSrc: '/assets/img/background-pic/pattern_4_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Pattern 5',
      background: 'pattern_5',
      backgroundSrc: '/assets/img/background-pic/pattern_5.jpg',
      previewSrc: '/assets/img/background-pic/pattern_5_prev.jpg',
      colorMode: 'dark'
    },
    {
      title: 'Pattern 6',
      background: 'pattern_6',
      backgroundSrc: '/assets/img/background-pic/pattern_6.jpg',
      previewSrc: '/assets/img/background-pic/pattern_6_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Pattern 7',
      background: 'pattern_7',
      backgroundSrc: '/assets/img/background-pic/pattern_7.jpg',
      previewSrc: '/assets/img/background-pic/pattern_7_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Porsche',
      background: 'porsche',
      backgroundSrc: '/assets/img/background-pic/porsche.jpg',
      previewSrc: '/assets/img/background-pic/porsche_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Regatta',
      background: 'regatta',
      backgroundSrc: '/assets/img/background-pic/regatta.jpg',
      previewSrc: '/assets/img/background-pic/regatta_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Road',
      background: 'road',
      backgroundSrc: '/assets/img/background-pic/road.jpg',
      previewSrc: '/assets/img/background-pic/road_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Rowing',
      background: 'rowing',
      backgroundSrc: '/assets/img/background-pic/rowing.jpg',
      previewSrc: '/assets/img/background-pic/rowing_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Ship',
      background: 'ship',
      backgroundSrc: '/assets/img/background-pic/ship.jpg',
      previewSrc: '/assets/img/background-pic/ship_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Space',
      background: 'space',
      backgroundSrc: '/assets/img/background-pic/space.jpg',
      previewSrc: '/assets/img/background-pic/space_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Space 2',
      background: 'space 2',
      backgroundSrc: '/assets/img/background-pic/space_2.jpg',
      previewSrc: '/assets/img/background-pic/space_2_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Sunflower',
      background: 'sunflower',
      backgroundSrc: '/assets/img/background-pic/sunflower.jpg',
      previewSrc: '/assets/img/background-pic/sunflower_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Sunrise',
      background: 'sunrise',
      backgroundSrc: '/assets/img/background-pic/sunrise.jpg',
      previewSrc: '/assets/img/background-pic/sunrise_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Sunset',
      background: 'sunset',
      backgroundSrc: '/assets/img/background-pic/sunset.jpg',
      previewSrc: '/assets/img/background-pic/sunset_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Time capsule',
      background: 'time capsule',
      backgroundSrc: '/assets/img/background-pic/time_capsule.jpg',
      previewSrc: '/assets/img/background-pic/time_capsule_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Urban',
      background: 'urban',
      backgroundSrc: '/assets/img/background-pic/urban.jpg',
      previewSrc: '/assets/img/background-pic/urban_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Zen',
      background: 'zen',
      backgroundSrc: '/assets/img/background-pic/zen.jpg',
      previewSrc: '/assets/img/background-pic/zen_prev.jpg',
      colorMode: 'light'
    }
  ];

  private readonly startPageBackgroundsList: IBackground[] = [
    {
      title: 'Coming times',
      background: 'coming times',
      backgroundSrc: '/assets/img/background-pic/coming_times.jpg',
      previewSrc: '/assets/img/background-pic/coming_times_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Finding',
      background: 'finding',
      backgroundSrc: '/assets/img/background-pic/finding.jpg',
      previewSrc: '/assets/img/background-pic/finding_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Meetingroom',
      background: 'meetingroom',
      backgroundSrc: '/assets/img/background-pic/meetingroom.jpg',
      previewSrc: '/assets/img/background-pic/meetingroom_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Red Couch',
      background: 'red couch',
      backgroundSrc: '/assets/img/background-pic/red_couch.jpg',
      previewSrc: '/assets/img/background-pic/red_couch_prev.jpg',
      colorMode: 'dark'
    },
    {
      title: 'Space Bridge',
      background: 'space bridge',
      backgroundSrc: '/assets/img/background-pic/space_bridge.jpg',
      previewSrc: '/assets/img/background-pic/space_bridge_prev.jpg',
      colorMode: 'dark'
    },
    {
      title: 'Space Livingroom',
      background: 'space livingroom',
      backgroundSrc: '/assets/img/background-pic/space_livingroom.jpg',
      previewSrc: '/assets/img/background-pic/space_livingroom_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Space Office',
      background: 'space office',
      backgroundSrc: '/assets/img/background-pic/space_office.jpg',
      previewSrc: '/assets/img/background-pic/space_office_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Sunsetbeach',
      background: 'sunsetbeach',
      backgroundSrc: '/assets/img/background-pic/sunsetbeach.jpg',
      previewSrc: '/assets/img/background-pic/sunsetbeach_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Work in Progress',
      background: 'work in progress',
      backgroundSrc: '/assets/img/background-pic/work_in_progress.jpg',
      previewSrc: '/assets/img/background-pic/work_in_progress_prev.jpg',
      colorMode: 'dark'
    },
    {
      title: 'Workingtable',
      background: 'workingtable',
      backgroundSrc: '/assets/img/background-pic/workingtable.jpg',
      previewSrc: '/assets/img/background-pic/workingtable_prev.jpg',
      colorMode: 'light'
    },
    {
      title: 'Zen garden',
      background: 'zen garden',
      backgroundSrc: '/assets/img/background-pic/zen_garden.jpg',
      previewSrc: '/assets/img/background-pic/zen_garden_prev.jpg',
      colorMode: 'light'
    }
  ];

  private themeToBackgroundMap = new Map([
    ['theme-light-blue', 'graffiti'],
    ['theme-light-green', 'graffiti_3'],
    ['theme-light-red', 'porsche'],
    ['theme-dark-moon', 'jellyfish'],
    ['theme-clear', 'pattern_2'],
    ['theme-sky', 'pampas'],
    ['theme-lava', 'ship'],
    ['theme-dark-turquoise', 'pattern_5'],
    ['theme-carbon', 'maybach'],
    ['theme-agilicision', 'space'],
    ['theme-dark', 'sunset']
  ]);

  constructor(private data: SharedataService, private configurationService: ConfigurationService) {}

  applyTheme(theme: string): void {
    const body = document.getElementsByTagName('body')[0];
    if (this.currentTheme) {
      body.classList.remove(this.currentTheme);
    }
    if (theme) {
      body.classList.add(theme);
    }
    this.currentTheme = theme;
  }

  createConfiguration(): IClientConfiguration {
    return {
      color: '',
      logo: null,
      background: null
    };
  }

  getBackgrounds(): IBackground[] {
    return clone(this.backgroundsList);
  }

  getStartPageBackgrounds(): IBackground[] {
    return clone(this.startPageBackgroundsList);
  }

  getAllBackgrounds(): IBackground[] {
    const allBackgrounds = [...this.backgroundsList, ...this.startPageBackgroundsList];
    return allBackgrounds;
  }

  getBackground(): Observable<string> {
    return this.configurationService.getBackgroundImage().pipe(
      switchMap(background => {
        if (background) {
          this.data.setCustomBackground(background);
          this.data.setIsCustomBackground(true);
          return of(background);
        }
        return this.configurationService.resolveAppBackgroud();
      }),
      map(background => {
        const item = this.backgroundsList.find(bg => bg.background === background);
        let url = background;
        if (item) {
          url = item.backgroundSrc;
        }
        return url;
      })
    );
  }

  getThemes(): ITheme[] {
    return clone(this.themesList);
  }

  getThemeBackground(theme: string): string {
    return this.themeToBackgroundMap.get(theme) || 'pattern';
  }

  getAppTheme(): Observable<string> {
    return this.configurationService.getWebAppDesign('user').pipe(map(theme => theme || 'theme-clear'));
  }
}
