<dx-tag-box
  stylingMode="filled"
  [(value)]="value"
  (onSelectionChanged)="onBrandSelectionChanged($event)"
  [dataSource]="field.editorOptions.dataSource"
  [displayExpr]="field.editorOptions.displayExpr"
  [valueExpr]="field.editorOptions.valueExpr"
  [itemTemplate]="userItemTemplate"
  [tagTemplate]="userTagTemplate"
  [searchEnabled]="field.editorOptions.searchEnabled"
  [showDropDownButton]="field.editorOptions.showDropDownButton"
  [showClearButton]="field.editorOptions.showClearButton"
  [showSelectionControls]="field.editorOptions.showSelectionControls"
  [hint]="field.editorOptions.hint"
  [placeholder]="field.editorOptions.placeholder"
  (onValueChanged)="valueChanged($event)">
  <dx-validator
  validationGroup="{{ validationGroupId }}"
  [validationRules]="field.editorOptions.validationRules"></dx-validator>

</dx-tag-box>
