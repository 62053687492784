import { Pipe, PipeTransform } from '@angular/core';
import { IAgenda } from '@interfaces/siam';

interface Details {
  taskCount: number;
  protocolCount: number;
  approvalCount: number;
}

@Pipe({
  name: 'referenceDetails',
  pure: false
})
export class ReferenceDetailsPipe implements PipeTransform {

  transform(agendaItem: IAgenda): Details {
    const details: Details = {
      taskCount: 0,
      protocolCount: 0,
      approvalCount: 0
    };
    if (agendaItem.document && agendaItem.document.references) {
      details.approvalCount = agendaItem.document.fields['--approval_type'] ? 1 : 0;
      agendaItem.document.references.forEach(ref => {
        details.taskCount = details.taskCount + ref.tags.filter(x => x === 'app:document-type:task').length;
        details.protocolCount = details.protocolCount + ref.tags.filter(x => x === 'app:document-type:protocol').length;
      });
    }
    return details;
  }
}
