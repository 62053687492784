import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import * as Factory from '@factories/user.factory';
import { copy } from '@factories/user.factory';
import {
  IAccount,
  IPasswordRequirements,
  IPermissionTarget,
  ISearchUser,
  ISearchUserRersponse,
  IUser
} from '@interfaces/siam';
import { UserApi } from '../api/user.api';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { LoggerService } from './logger.service';
import { createPermissionTarget } from '@factories/workflow.factory';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  #isAllLoaded = false;
  #baseUrl: string;

  constructor(
    private userApi: UserApi,
    private store: Store<fromStore.AppState>,
    private logger: LoggerService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.#baseUrl = `${baseUrl}api`;
  }

  /**
   * Change the password
   *
   * @param user as userInfo
   * @param oldPassword as string
   * @param newPassword as string
   */
  changePassword(user: IUser, oldPassword: string, newPassword: string): Observable<boolean> {
    return this.userApi.changePassword(user, oldPassword, newPassword);
  }

  /**
   * get password requirements
   */
  getPasswordRequirements(): Observable<IPasswordRequirements> {
    return this.userApi.getPasswordRequirements();
  }

  createUser(user: IUser, password: string): Observable<IUser> {
    return this.userApi.create(user, password).pipe(
      tap(createdUser => {
        this.store.dispatch(new fromStore.CreateUser({ user: createdUser }));
      })
    );
  }

  /**
   * deleting the user from DB
   *
   * @param user
   */
  delete(user: IUser): Observable<IUser> {
    return this.userApi.delete(user).pipe(
      tap(() => {
        this.store.dispatch(new fromStore.DeleteUser({ user }));
      })
    );
  }

  /**
   * Returns the current loggedin user information
   *
   * @return user information
   */
  getCurrentUser(): Observable<IUser> {
    if (!Factory.isOutdated()) {
      return of(Factory.getCurrentUser());
    } else {
      this.logger.info('Request required: Time expired');
    }

    return this.getUser(null);
  }

  getAllActiveUsers(): Observable<IUser[]> {
    return this.getAllUsers().pipe(
      switchMap(users => of(users.filter(user => user.isArchived === false).map(user => copy(user))))
    );
  }

  /**
   * Get all users
   *
   * @params force?: force to get data from DB directly
   * @return list of users
   */
  getAllUsers(force = false): Observable<IUser[]> {
    if (!this.#isAllLoaded) {
      force = true;
    }
    return this.getUsers(null, force);
  }

  /**
   * Returns user information by user ID
   *
   * @param userId - user id must be string (guid)
   * @param force
   * @return user information
   */
  getUser(userId: string, force = false): Observable<IUser> {
    let stream$: Observable<IUser>;
    let isUpdateCache = false;
    if (force) {
      isUpdateCache = true;
      stream$ = this.userApi.getUser(userId);
    } else {
      stream$ = this.store.select(fromStore.getUserById(userId)).pipe(take(1));
    }
    return stream$.pipe(
      switchMap(user => {
        if (!user) {
          isUpdateCache = true;
          return this.userApi.getUser(userId);
        }
        return of(user);
      }),
      tap(user => {
        if (user && isUpdateCache) {
          this.store.dispatch(new fromStore.SetUsers({ users: [user] }));
          isUpdateCache = false;
        }
      }),
      map(user => {
        if (user) {
          return Factory.copy(user);
        } else {
          // this might be, when for some reason user clicks on the row of already deleted user
          // or may be better throw an error?
          return Factory.create();
        }
      })
    );
  }

  /**
   * Get certain users
   *
   * @param userIds: List of requested UserIds
   * @param force: force to get data from DB directly
   * @return list of users
   */
  getUsers(userIds?: string[], force = false): Observable<IUser[]> {
    let stream$: Observable<IUser[]>;
    let isUpdateCache = false;
    if (force) {
      isUpdateCache = true;
      stream$ = this.userApi.getUsers(userIds);
    } else {
      stream$ = this.store.select(fromStore.allUsers).pipe(take(1));
    }
    return stream$.pipe(
      switchMap(users => {
        // after login only one user presents in the cache,
        // so need to force to get all the users at least once
        if (!users || !users.length || users.length === 1) {
          isUpdateCache = true;
          return this.userApi.getUsers(userIds);
        }
        return of(users);
      }),
      tap(users => {
        if (isUpdateCache) {
          this.store.dispatch(new fromStore.SetUsers({ users }));
          if (!userIds || !userIds.length) {
            this.#isAllLoaded = true;
          }
          isUpdateCache = false;
        }
      }),
      map(users => {
        if (Array.isArray(userIds) && userIds.length) {
          users = users.filter(user => userIds.indexOf(user.id) !== -1);
        }
        return users.map(user => Factory.copy(user));
      })
    );
  }

  getUsersWithCapabilities(): Observable<IAccount> {
    return this.userApi.getUsersWithCapabilities();
  }

  getAvatarUrl(user: IUser, force = false): string {
    return this.userApi.getAvatarUrl(user, force);
  }

  onLogout(): void {
    this.#isAllLoaded = false;
  }

  removeAvatar(userId: string): Observable<void> {
    return this.userApi.removeAvatar(userId);
  }

  /**
   * update a user
   *
   * @param user
   */
  update(user: IUser): Observable<IUser> {
    return this.userApi.update(user).pipe(
      tap(result => {
        this.store.dispatch(new fromStore.UpdateUser({ user: result }));
      })
    );
  }

  /**
   * update a user
   *
   * @param user
   */
  updateProfile(user: IUser): Observable<IUser> {
    return this.userApi.updateProfile(user).pipe(
      tap(result => {
        this.store.dispatch(new fromStore.UpdateUser({ user: result }));
      })
    );
  }

  /**
   * import LDAP users
   *
   */
  importAllLDAPUsers(): Observable<IUser[]> {
    return this.userApi.importUsers();
  }

  uploadAvatar(user: IUser, picture: File): Observable<void> {
    return this.userApi.uploadAvatar(user, picture);
  }

  getImpersonationCandidates(): Observable<IUser[]> {
    return this.userApi.getImpersonationCandidates();
  }

  getSubstitutes(user: IUser): Observable<IPermissionTarget[]> {
    return this.userApi.getSubstitutes(user);
  }

  searchUsers(user: ISearchUser): Observable<ISearchUserRersponse[]> {
    return this.userApi.searchUsers(user);
  }

  setSubstitutes(user: IUser): Observable<void> {
    const substitutes = user.clientSubstitutes.map(substitute => {
      if (typeof substitute === 'string') {
        return createPermissionTarget(substitute);
      } else {
        return substitute;
      }
    });

    return this.userApi.setSubstitutes(user.id, substitutes);
  }

  getDefaultUserAvatar = (): string => `${this.#baseUrl}/images/$default/DefaultUserImage-01.png`;
}
