interface ISiamError {
  title: string;
  detail: string;
}

export class SiamAuthorizationError extends Error {
  error: ISiamError;

  constructor() {
    super('fehlerhafte Zugangsdaten');
    Object.setPrototypeOf(this, SiamAuthorizationError.prototype);
    this.error = {
      title: 'Die Zugangsdaten sind ungültig.',
      detail: 'Die Zugangsdaten sind ungültig.'
    };
  }
}

export class SiamServerConnectionError extends Error {
  error: ISiamError;

  constructor() {
    super('Verbindungsfehler');
    Object.setPrototypeOf(this, SiamServerConnectionError.prototype);
    this.error = {
      title: 'Verbindungsfehler',
      detail: 'Der Server antwortete nicht auf die Anfrage.'
    };
  }
}
