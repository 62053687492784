import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { onBrandSelectionChanged } from '@factories/helpers';
import { PermissionTarget } from '@interfaces/fieldClient';
import { IUserRoleEntry } from '@interfaces/siam';
import { ValidationRule } from 'devextreme/common';
import { ValueChangedEvent } from 'devextreme/ui/tag_box';

@Component({
  selector: 'app-form-tagbox',
  templateUrl: './form-tagbox.component.html',
  styleUrls: ['./form-tagbox.component.scss']
})
export class FormTagboxComponent {
  @Input() isEditing = false;

  @Input() text: string;

  @Input() field: PermissionTarget;

  @Input() label = '';

  @Input() mask: string = null;

  @Input() icon: string = null;

  @Input() validationGroupId: string = null;

  @Input() validators: ValidationRule[] = [];

  @Input() value!: string;

  @Output() valueChange = new EventEmitter<string[]>();

  onBrandSelectionChanged = onBrandSelectionChanged;

  valueChanged(e: ValueChangedEvent) {
    const value = e.value as string[];
    if (!this.field.editorOptions.allowMultiple && value && value.length > 1) {
      e.component.option('value', value.slice(-1));
      this.cdRef.detectChanges();
    }
    this.valueChange.emit(value);
  }

  userItemTemplate(user: IUserRoleEntry): string {
    let result = '';
    if (user?.type === 'user' && user?.profile) {
      if (user.profile.picture && user.profile.picture.url) {
        result = `<img class="user-avatar-name" src="${user.profile.picture.url}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
      result = result + `<span>${user.selectorName}</span>`;
      return result;
    }
    if (user?.type === 'role') {
      result = `<i class="material-icons mr-1">group</i><span>${user.name}</span>`;
    }
    return result;
  }

  userTagTemplate = (user: IUserRoleEntry): string => {
    let result = '';
    if (user.type === 'user') {
      if (user && user.profile && user.profile.picture && user.profile.picture.url) {
        result = `<img class="user-avatar-name" src="${user.profile.picture.url}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
    }
    if (user.type === 'role') {
      result = `<i class="material-icons mr-1">group</i>`;
    }
    const removeButton = '<div class="dx-tag-remove-button"></div>';
    const spanClass = user.visible === false ? 'class="is-archived-user"' : '';
    return `<div class="dx-tag-content name-selector">${result}<span ${spanClass}>${user.name}</span>${removeButton}</div>`;
  };
  constructor(private cdRef: ChangeDetectorRef) {}
}
