import { clone } from './helpers';
import { IPermissionTarget } from '@interfaces/siam';

export const copy = <T>(value: T): T => clone(value);
export const checkIfValidUUID = (str: string): boolean => {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};
export const createPermissionTarget = (compositeId: string): IPermissionTarget => {
  if (typeof compositeId === 'string') {
    const [type, targetId] = compositeId.split(':', 2);
    switch (type) {
      case 'user':
      case 'role':
        return {
          type,
          targetId,
          compositeId: `${type}:${targetId}`
        };
      default:
        throw new TypeError("ID must be of type (('user')|('role')):(([0-9a-fA-F]{32})|([-0-9a-fA-F]{36}))");
    }
  } else {
    throw new TypeError("ID must be of type (('user')|('role')):(([0-9a-fA-F]{32})|([-0-9a-fA-F]{36}))");
  }
};

export const createPermissionTargetWithoutCompositeId = (compositeId: string): IPermissionTarget => {
  if (typeof compositeId === 'string') {
    const [type, targetId] = compositeId.split(':', 2);
    switch (type) {
      case 'user':
      case 'role':
        return {
          type,
          targetId
        };
    }
  }
  throw new TypeError("ID must be of type (('user')|('role')):(([0-9a-fA-F]{32})|([-0-9a-fA-F]{36}))");
};

export const permissionTarget = (source: IPermissionTarget): IPermissionTarget => {
  if (source.type && source.targetId) {
    const type = source.type;
    const targetId = source.targetId;
    return {
      type,
      targetId,
      compositeId: `${type}:${targetId}`
    };
  }
  throw new TypeError(
    "Object must be of type {type: ('user')|('role'), targetID: (([0-9a-fA-F]{32})|([-0-9a-fA-F]{36}))}"
  );
};
export const isCompositeId = (compositeId: string): boolean => {
  if (typeof compositeId !== 'string') {
    return false;
  }
  const [type, targetId] = compositeId.split(':', 2);
  if ((type === 'role' || type === 'user') && checkIfValidUUID(targetId)) {
    return true;
  }
  return false;
};
