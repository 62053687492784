import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  IActionData,
  IAssignment,
  IAuditInformation,
  IDocument,
  IDocumentFields,
  IFieldNewValue,
  IPermissionTarget,
  IPossibleVote,
  IRole,
  IUser,
  IUserPipe,
  IWorkflowDocument,
  IWorkflowHistory,
  IWorkflowHistoryActionLog,
  IWorkflowHistoryAssignee,
  IWorkflowHistoryUser,
  IWorkflowHistoryVote,
  TAssignment
} from '@interfaces/siam';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { Edge, Node } from '@swimlane/ngx-graph';
import { RoleService } from '@services/roles.service';
import { UserService } from '@services/user.service';
import { DocumentService } from '@services/document.service';
import { IEdgeServer, IVertexServer, IWorkflowCreateBallotActionServer, TActionServer } from '@interfaces/edgeServer';
import { WorkflowDiagramComponent } from './workflow-diagram/workflow-diagram.component';
import * as userFactory from '@factories/user.factory';
import { getCurrentUser } from '@factories/user.factory';
import { LoggerService } from '@services/logger.service';
import { TemplateService } from '@services/template.service';
import {
  IWorkflowCreateBallotActionClient,
  IWorkflowMeetingInviteActivityClient,
  IWorkflowPeekSerialNumberActivityClient,
  IWorkflowPermissionScriptActionClient,
  IWorkflowSendMailActionClient,
  IWorkflowSetValueActionClient,
  TActionClient
} from '@interfaces/edgeClient';
import { mapActionsServerToClient } from '@factories/workflow.factory';
import {
  CheckBox,
  CheckBoxGroup,
  DateBox,
  DxSlider,
  FieldClient,
  FieldTypeClient,
  FileUploader,
  Group,
  LinkText,
  NumberBox,
  PermissionTarget,
  RadioGroup,
  RichTextEditor,
  SelectBox,
  StaticText,
  TextArea,
  TextBox
} from '@interfaces/fieldClient';
import { TemplateClient } from '@interfaces/templateClient';
import { mapServerToClientDoc } from '@factories/template.factory';
import { DxFormComponent, DxPopoverComponent } from 'devextreme-angular';
import * as roleFactory from '@factories/role.factory';
import { getStringLength } from '@factories/helpers';
import { ShowingEvent } from 'devextreme/ui/popover';
import { ICheckBoxGroupEditorOptions } from '@interfaces/fields';

interface IActionValue {
  icon: string;
  label: string;
}

interface IDocInfos {
  assignee?: IWorkflowHistoryUser[];
  currentAssignee?: IWorkflowHistoryUser[];
  creation: IAuditInformation;
  creator?: IWorkflowHistoryUser[];
  state?: string;
  stateLabel: string;
}

interface IPossibleVoteValue {
  label: string;
}

interface IHistoryFieldActionLog {
  type: string;
  start: Date;
  end: Date;
  isSuccess: boolean;
  actionId?: string;
  entries: {
    type: string;
    level: string;
    message: string;
  }[];
}

type TActionType = 'center' | 'after' | 'before';
interface IHistoryField {
  assignees: { targetId: string; type: string }[];
  user: { userId: string; logonUserId?: string };
  timestamp: Date;
  selectedEdge: IEdgeServer;
  targetVertex: IVertexServer;
  sourceVertex: IVertexServer;
  actionLogs?: IHistoryFieldActionLog[];
  variables: Record<string, unknown>;
}

interface IPermission {
  user?: IUser;
  role?: IRole;
  data?: IUserPipe;
  read: boolean;
  update: boolean;
}

@Component({
  selector: 'app-workflow-info',
  templateUrl: './workflow-info.component.html',
  styleUrls: ['./workflow-info.component.scss']
})
export class WorkflowInfoComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() currentDocument: IDocument;
  @Input() workflowDocument: IWorkflowDocument;
  @ViewChild(WorkflowDiagramComponent) workflowDiagram: WorkflowDiagramComponent;
  @ViewChild('detailsActionForm') detailsActionForm: DxFormComponent;
  @ViewChild('popOverCreatorField') popOverCreatorField: DxPopoverComponent;
  @ViewChild('popOverUserHistory') popOverUserHistory: DxPopoverComponent;

  templateClient: TemplateClient;
  nodes: Node[] = [];
  links: Edge[] = [];
  currentUser: IUser;
  currentActivity: IWorkflowHistoryActionLog;
  currentAction: IActionData;
  currentPopupActivityVisible = false;
  currentTargetId: string;
  permissionsData: IPermission[] = [];
  docInfos: IDocInfos = null;
  documentConfidential: string;
  workflowHistory: IWorkflowHistory[] = [];
  dialogOpen = false;
  dialogPermissionOpen = false;
  popoverElement: HTMLElement;
  popoverCreatorText: string;
  popoverElementUser: HTMLElement;
  popoverTextUser: string;
  center$ = new Subject<boolean>();
  edgeDocument: IDocument = null;
  formItems: FieldClient[] = [];
  formData: Record<string, unknown> = {};
  private destroyed$ = new Subject<void>();

  /* eslint-disable */
  private selectableActions: Record<string, IActionValue> = {
    PermissionScriptWorkflowAction: { icon: 'card', label: 'Berechtigungen setzen' },
    CopyPermissionsWorkflowAction: { icon: 'material-icons content_copy', label: 'Berechtigungen kopieren' },
    SetValueWorkflowAction: { icon: 'edit', label: 'Dokumentwerte setzen' },
    ModifyTagWorkflowAction: { icon: 'tags', label: 'Tags setzen' },
    ExecuteDocumentWorkflowAction: { icon: 'material-icons mediation', label: 'Dokumentworkflow ausführen' },
    ModifyNameFieldWorkflowAction: { icon: 'refersh', label: 'Namensfeld modifizieren' },
    SendMailWorkflowAction: { icon: 'email', label: 'Mail versenden' },
    SendMailTemplateWorkflowAction: { icon: 'email', label: 'Mailschablone versenden' },
    CreateBallotWorkflowAction: { icon: 'comment', label: 'Abstimmung starten' },
    AbortBallotWorkflowAction: { icon: 'clear', label: 'Abstimmung abbrechen' },
    MeetingInviteWorkflowAction: { icon: 'export', label: 'Sitzungseinladung durchführen' },
    MeetingProtocolApprovalWorkflowAction: { icon: 'exportselected', label: 'Sitzungsprotokoll freigeben' },
    MeetingAbortWorkflowAction: { icon: 'clear', label: 'Sitzung absagen' },
    PeekSerialNumberWorkflowAction: { icon: 'orderedlist', label: 'Nummernkreis abfragen' },
    ConsumeSerialNumberWorkflowAction: {
      icon: 'orderedlist',
      label: 'Nummernkreis abfragen und verbrauchen'
    },
    CreateDocumentWorkflowAction: { icon: 'plus', label: 'Referenzdokument erstellen' }
  };

  /* eslint-enable */

  constructor(
    private documentService: DocumentService,
    private templateService: TemplateService,
    private userService: UserService,
    private roleService: RoleService,
    private logger: LoggerService,
    private cdRef: ChangeDetectorRef,
    private nameDataPipe: GetNameDataPipe
  ) {}

  ngOnInit(): void {
    this.templateClient = mapServerToClientDoc(this.currentDocument.template);
    this.currentUser = getCurrentUser();
    this.center$.next(true);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  async getPermission(document: IDocument): Promise<IPermission[]> {
    const permissionsData: IPermission[] = [];
    for (const permission of document.permissions) {
      const currentPermission: IPermission = { update: false, read: false };

      if (permission.allows.some(s => s === 'update')) {
        currentPermission.update = true;
      }

      if (permission.allows.some(s => s === 'read')) {
        currentPermission.read = true;
      }

      if (permission.type === 'role') {
        currentPermission.data = await lastValueFrom(
          this.nameDataPipe.transform({ type: 'role', targetId: permission.role.id })
        );
        currentPermission.role = permission.role;
        if (!permissionsData.find(p => p.role?.id === permission?.role?.id)) {
          permissionsData.push(currentPermission);
        }
      }

      if (permission.type === 'user') {
        currentPermission.data = await lastValueFrom(
          this.nameDataPipe.transform({ type: 'user', targetId: permission.user.id })
        );
        currentPermission.user = permission.user;
        if (!permissionsData.find(p => p.user?.id === permission?.user?.id)) {
          permissionsData.push(currentPermission);
        }
      }
    }

    return permissionsData;
  }

  /**
   * Get workflow history from current document
   */
  getWorkflowHistory(): void {
    if (!this.currentDocument || !this.workflowDocument) {
      return;
    }
    const workflow = this.workflowDocument.workflow;
    this.nodes = workflow.vertices.map(v => ({ id: v.name, label: v.label }));
    this.links = workflow.edges.map(e => ({ source: e.source, target: e.target, label: e.label }));

    this.documentService
      .getDocumentPermissions(this.currentDocument)
      .pipe(
        switchMap(document => this.getPermission(document)),
        tap(data => {
          this.permissionsData = data.sort(this.sortByName);
        }),
        switchMap(() => this.getDocumentHistory()),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  getDocumentHistory(): Observable<void> {
    return this.documentService.getWorkflowHistory(this.currentDocument).pipe(
      switchMap(async document => {
        // const fields = document.fields;
        const workflowDocument = document.workflowDocuments.find(w => w.id === this.workflowDocument.id);
        const workflowHistory: IWorkflowHistory[] = [];
        const history = workflowDocument.fields.history;
        if (history && Array.isArray(history.value)) {
          // const data = this.findBallotData(fields, workflowDocument);
          for (const entry of history.value as IHistoryField[]) {
            let actionLogs: IWorkflowHistoryActionLog[] = [];
            if (entry.actionLogs) {
              actionLogs = entry.actionLogs.map(action => {
                const selectedAction = this.selectableActions[action.type];
                return {
                  type: selectedAction?.label,
                  start: new Date(action.start),
                  end: new Date(action.end),
                  icon: selectedAction?.icon,
                  message: action.entries.find(e => e.type === 'final-status').message,
                  isSuccess: action.isSuccess,
                  action: this.findAction(action.actionId, entry),
                  actionType: this.getActionType(action.actionId, entry),
                  actionComment: this.getActionComment(action.actionId, entry),
                  actionId: action.actionId,
                  detailsVisible: false
                };
              });
            }

            const votes: IWorkflowHistoryVote[] = [];
            /* const ballotDocumentIds = entry.actionLogs
              .filter(a => a.type === 'CreateBallotWorkflowAction')
              .map(a => a.actionId);
            if (ballotDocumentIds?.length) {
              for (const ballotId of ballotDocumentIds) {
                const ballotDocumentId = data[ballotId];
                if (ballotDocumentId) {
                  const ballotDocument = document.workflowDocuments.find(wf => wf.id === ballotDocumentId);
                  let voteHistory: IVoteHistory[];
                  if (ballotDocument && ballotDocument.fields['vote:history']) {
                    voteHistory = ballotDocument.fields['vote:history'].value as IVoteHistory[];
                  }

                  if (voteHistory) {
                    for (const vote of voteHistory) {
                      votes.push({
                        time: new Date(vote.timestamp),
                        voter: await lastValueFrom(this.getHistoryUser(vote.voter.targetId)),
                        vote: this.getVoteValue(ballotDocument, vote.vote)
                      });
                    }
                    votes.sort(
                      (a: IWorkflowHistoryVote, b: IWorkflowHistoryVote) => a.time.valueOf() - b.time.valueOf()
                    );
                  }
                }
              }
            } */
            const selectedEdge = workflowDocument.workflow.edges.find(
              edge => edge.edgeId === entry.selectedEdge.edgeId
            );
            let isDocument = false;
            let subformName = '';
            let userInputTemplateLink: ITemplateServer = null;
            if (selectedEdge?.userInputTemplate?.layouts?.length) {
              subformName = selectedEdge.userInputTemplate?.caption;
              userInputTemplateLink = selectedEdge.userInputTemplate;
              isDocument = true;
            } else if (selectedEdge?.userInputTemplateLink) {
              subformName = selectedEdge.userInputTemplateLink?.caption;
              isDocument = true;
            } else if (selectedEdge?.userInputTemplateLinkId) {
              userInputTemplateLink = await lastValueFrom(
                this.templateService.getTemplate(selectedEdge?.userInputTemplateLinkId)
              );
              subformName = userInputTemplateLink?.caption;
              isDocument = true;
            }

            const user =
              entry.user.userId === entry.user.logonUserId
                ? await lastValueFrom(this.getHistoryUser(entry.user.userId))
                : await lastValueFrom(this.getHistoryImpersonatedUser(entry.user.userId, entry.user.logonUserId));
            workflowHistory.push({
              time: new Date(entry.timestamp),
              user,
              edgeId: entry.selectedEdge.edgeId,
              edge: entry.selectedEdge.label,
              name: entry.selectedEdge.name,
              sourceVertex: entry.sourceVertex?.label || null,
              targetVertex: entry.targetVertex.label,
              activitiesVisible: false,
              variables: entry.variables,
              assignee: await this.getHistoryAssignee(entry),
              actionLogs: actionLogs.length ? actionLogs : null,
              votes,
              isDocument,
              subformName,
              userInputTemplateLink,
              onMouseEnterUser: (event: Event) => {
                if (entry.user.userId !== entry.user.logonUserId) {
                  this.popoverElementUser = event.target as HTMLElement;
                  this.popoverTextUser = `Vertretung durch (<strong>${user.logonUser.displayName}</strong>)`;
                  void this.popOverUserHistory.instance.show();
                }
              },
              onMouseLeaveUser: () => {
                void this.popOverUserHistory.instance.hide();
              },
              onDocument: () => {
                if (!selectedEdge) {
                  return;
                }
                if (selectedEdge.userInputTemplate?.layouts?.length) {
                  this.assignVariables(this.documentService.create(selectedEdge.userInputTemplate), entry.variables);
                } else if (userInputTemplateLink) {
                  this.assignVariables(this.documentService.create(userInputTemplateLink), entry.variables);
                } else if (selectedEdge.userInputTemplateLinkId) {
                  this.templateService
                    .getTemplate(selectedEdge.userInputTemplateLinkId)
                    .pipe(
                      switchMap(templateServer => this.documentService.createDocumentByTemplateId(templateServer)),
                      takeUntil(this.destroyed$)
                    )
                    .subscribe(doc => {
                      this.assignVariables(doc, entry.variables);
                    });
                }
              }
            });
          }
          this.workflowHistory = workflowHistory.sort(
            (a: IWorkflowHistory, b: IWorkflowHistory) => a.time.valueOf() - b.time.valueOf()
          );
          this.logger.debug('Workflow History: {@m} ', this.workflowHistory);
        }
      })
    );
  }

  findAction(actionId: string, entry: IHistoryField): IActionData {
    const actions = [].concat(
      entry.selectedEdge?.actions || [],
      entry.sourceVertex?.enterActions || [],
      entry.sourceVertex?.leaveActions || [],
      entry.targetVertex?.enterActions || [],
      entry.targetVertex?.leaveActions || []
    ) as TActionServer[];
    const find = actions.find(a => a.actionId === actionId);
    if (!find) {
      return null;
    }
    return this.mapAction(mapActionsServerToClient([find])[0], entry);
  }
  getActionComment(actionId: string, entry: IHistoryField): string {
    const actions = [].concat(
      entry.selectedEdge?.actions || [],
      entry.sourceVertex?.enterActions || [],
      entry.sourceVertex?.leaveActions || [],
      entry.targetVertex?.enterActions || [],
      entry.targetVertex?.leaveActions || []
    ) as TActionServer[];
    const find = actions.find(a => a.actionId === actionId);
    if (!find) {
      return null;
    }
    return find.variables?.actionComment as string;
  }
  getActionType(actionId: string, entry: IHistoryField): string {
    let type: TActionType = null;
    if (entry.selectedEdge?.actions.find(a => a.actionId === actionId)) {
      type = 'center';
    } else if (entry.sourceVertex?.leaveActions.find(a => a.actionId === actionId)) {
      type = 'before';
    } else if (entry.targetVertex?.enterActions.find(a => a.actionId === actionId)) {
      type = 'after';
    }
    if (!type) {
      return null;
    }
    switch (type) {
      case 'after':
        return 'login';
      case 'before':
        return 'logout';
      case 'center':
        return 'east';

      default:
        break;
    }
    return type;
  }

  mapAction(action: TActionClient, entry: IHistoryField): IActionData | null {
    const result: IActionData = {
      formData: {},
      formItems: [
        new Group({
          caption: null,
          colCount: 2,
          items: []
        })
      ]
    };

    switch (action.type) {
      case 'send-mail':
      case 'send-mail-template':
        switch ((action as IWorkflowSendMailActionClient).to.type) {
          case 'static':
            {
              const value = (action as IWorkflowSendMailActionClient).to.value;
              let assignees: IWorkflowHistoryUser[] = [];
              // it is array of selected roles or users
              if (Array.isArray(value)) {
                const targets: IPermissionTarget[] = [];
                (value as string[]).forEach(v => {
                  try {
                    targets.push(this.createPermissionTarget(v));
                  } catch (e) {
                    // might be ok, because it can be simply e-mail
                    assignees.push({
                      name: v,
                      picture: ''
                    });
                  }
                });
                assignees.push(...this.mapPermissionTarget(targets));
              } else {
                // assume it is JSON value, so just to show this value
                assignees = [
                  {
                    name: value as string,
                    picture: ''
                  }
                ];
              }

              const field = {
                kind: 'dxTagBox',
                name: 'assignee',
                caption: 'Empfänger',
                colSpan: 2,
                editorOptions: {
                  displayExpr: 'name',
                  valueExpr: 'name',
                  dataSource: assignees,
                  tagTemplate: this.userItemTemplate
                }
              } as FieldClient;
              const permission = this.getFieldClient('dxTagBox', field);
              result.formItems[0].items.push(permission);
              result.formData.assignee = assignees;
            }
            break;

          case 'current-user':
            {
              const user = { targetId: entry.user.userId, type: 'user' } as IPermissionTarget;
              const assignees = this.mapPermissionTarget([user]);
              const field = {
                kind: 'dxTagBox',
                name: 'assignees',
                caption: 'Empfänger',
                colSpan: 2,
                editorOptions: {
                  displayExpr: 'name',
                  valueExpr: 'name',
                  dataSource: [user],
                  tagTemplate: this.userItemTemplate
                }
              } as FieldClient;
              const permission = this.getFieldClient('dxTagBox', field);
              result.formItems[0].items.push(permission);
              result.formData.assignees = assignees;
            }
            break;
          case 'creation-user':
            {
              const user = { targetId: this.currentDocument.creation.user.id, type: 'user' } as IPermissionTarget;
              const assignees = this.mapPermissionTarget([user]);
              const field = {
                kind: 'dxTagBox',
                name: 'assignees',
                caption: 'Empfänger',
                colSpan: 2,
                editorOptions: {
                  displayExpr: 'name',
                  valueExpr: 'name',
                  dataSource: [user],
                  tagTemplate: this.userItemTemplate
                }
              } as FieldClient;
              const permission = this.getFieldClient('dxTagBox', field);
              result.formItems[0].items.push(permission);
              result.formData.assignees = assignees;
            }
            break;

          case 'variable':
            {
              const variable = (action as IWorkflowSendMailActionClient).to.value as string;
              const assigneeValue = entry.variables[variable];
              if (typeof assigneeValue === 'string') {
                const field = {
                  kind: 'dxTextBox',
                  name: 'assignees',
                  caption: 'Empfänger',
                  colSpan: 2
                } as FieldClient;
                const permission = this.getFieldClient('dxTextBox', field);
                result.formItems[0].items.push(permission);
                result.formData.assignees = assigneeValue;
              } else {
                const assignees =
                  variable && entry.variables ? this.mapPermissionTarget(assigneeValue as IPermissionTarget[]) : null;
                const field = {
                  kind: 'dxTagBox',
                  name: 'assignees',
                  caption: 'Empfänger',
                  colSpan: 2,
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: assignees,
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.assignees = assignees;
              }
            }
            break;

          case 'document':
            {
              const variable = (action as IWorkflowSendMailActionClient).to.value as string;
              if (variable === 'creation.user') {
                const newValue = this.currentDocument.creation.user;
                const user = { targetId: newValue.id, type: 'user' } as IPermissionTarget;
                const fieldNewValue = this.mapPermissionTarget([user]);
                const field = {
                  kind: 'dxTagBox',
                  name: 'fieldNewValue',
                  caption: 'Neuer Wert',
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: [user],
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.fieldNewValue = fieldNewValue;
              } else {
                const fieldName = variable.startsWith('fields') ? variable.split('.')[1] : variable;
                const assignees = fieldName
                  ? this.mapPermissionTarget(this.currentDocument.fields[fieldName]?.value as IPermissionTarget[])
                  : null;
                if (!assignees) {
                  return null;
                }
                const field = {
                  kind: 'dxTagBox',
                  name: 'assignees',
                  caption: 'Empfänger',
                  colSpan: 2,
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: assignees,
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.assignees = assignees;
              }
            }
            break;

          default:
            break;
        }
        break;

      case 'create-ballot':
        switch ((action as IWorkflowCreateBallotActionClient).voters.type) {
          case 'static':
            {
              const value = (action as IWorkflowCreateBallotActionClient).voters.value;
              const targets = (value as string[]).filter(v => v.startsWith('role') || v.startsWith('user'))
                ? (value as string[]).map(v => this.createPermissionTarget(v))
                : value;
              const assignees = this.mapPermissionTarget(targets as IPermissionTarget[]);
              const field = {
                kind: 'dxTagBox',
                name: 'assignee',
                caption: 'Teilnehmer',
                colSpan: 2,
                editorOptions: {
                  displayExpr: 'name',
                  valueExpr: 'name',
                  dataSource: assignees,
                  tagTemplate: this.userItemTemplate
                }
              } as FieldClient;
              const permission = this.getFieldClient('dxTagBox', field);
              result.formItems[0].items.push(permission);
              result.formData.assignee = assignees;
            }
            break;

          case 'variable':
            {
              const variable = (action as IWorkflowCreateBallotActionClient).voters.value as string;
              const assigneeValue = entry.variables[variable];
              if (typeof assigneeValue === 'string') {
                const field = {
                  kind: 'dxTextBox',
                  name: 'assignees',
                  caption: 'Empfänger',
                  colSpan: 2
                } as FieldClient;
                const permission = this.getFieldClient('dxTextBox', field);
                result.formItems[0].items.push(permission);
                result.formData.assignees = assigneeValue;
              } else {
                const assignees =
                  variable && entry.variables ? this.mapPermissionTarget(assigneeValue as IPermissionTarget[]) : null;
                const field = {
                  kind: 'dxTagBox',
                  name: 'assignees',
                  caption: 'Teilnehmer',
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: assignees,
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.assignees = assignees;
              }
            }
            break;

          case 'document':
            {
              const variable = (action as IWorkflowCreateBallotActionClient).voters.value as string;
              if (variable === 'creation.user') {
                const newValue = this.currentDocument.creation.user;
                const user = { targetId: newValue.id, type: 'user' } as IPermissionTarget;
                const fieldNewValue = this.mapPermissionTarget([user]);
                const field = {
                  kind: 'dxTagBox',
                  name: 'fieldNewValue',
                  caption: 'Neuer Wert',
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: [user],
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.fieldNewValue = fieldNewValue;
              } else {
                const fieldName = variable.startsWith('fields') ? variable.split('.')[1] : variable;
                const assignees = fieldName
                  ? this.mapPermissionTarget(this.currentDocument.fields[fieldName]?.value as IPermissionTarget[])
                  : null;
                if (!assignees) {
                  return null;
                }
                const field = {
                  kind: 'dxTagBox',
                  name: 'assignees',
                  caption: 'Teilnehmer',
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: assignees,
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.assignees = assignees;
              }
            }
            break;

          default:
            break;
        }
        break;

      case 'modify-name-field':
      case 'set-value':
        {
          const text = this.getFieldClient('dxTextBox', { name: 'targetField', caption: 'Feldname' } as FieldClient);
          result.formItems[0].items.push(text);
          result.formData.targetField = (action as IWorkflowSetValueActionClient).destination.value as string;

          switch ((action as IWorkflowSetValueActionClient).source.type) {
            case 'static':
              {
                let value = (action as IWorkflowSetValueActionClient).source.value;
                const target = (action as IWorkflowSetValueActionClient).destination.value as string;
                const fieldName = target.startsWith('fields') ? target.split('.')[1] : target;
                const newValue = this.getFieldValueFromDocument(fieldName);
                const editorOptions = (this.templateClient.fields.find(f => f.name === fieldName) as CheckBoxGroup)
                  ?.editorOptions;
                if (newValue?.type === 'dxTagBox') {
                  if (Array.isArray(value)) {
                    const sources: IPermissionTarget[] = value.map((v: string) => {
                      const data = v.split(':');
                      return {
                        type: data[0] as TAssignment,
                        targetId: data[1]
                      };
                    });
                    value = this.mapPermissionTarget(sources);
                  }
                  editorOptions.displayExpr = 'name';
                  editorOptions.valueExpr = 'name';
                }
                if (newValue?.type === 'dxHtmlEditor') {
                  newValue.type = 'dxTextBox';
                  value = this.sanitizeHTML(value as string);
                }

                const field = this.getFieldClient(newValue?.type, {
                  name: 'fieldNewValue',
                  caption: 'Neuer Wert',
                  editorOptions
                } as FieldClient);
                result.formItems[0].items.push(field);
                result.formData.fieldNewValue = value;
              }
              break;

            case 'variable':
              {
                const variable = (action as IWorkflowSetValueActionClient).source.value as string;
                const value = entry.variables[variable];
                if (
                  Array.isArray(value) &&
                  (value as IPermissionTarget[])[0] &&
                  (value as IPermissionTarget[])[0].type &&
                  (value as IPermissionTarget[])[0].targetId
                ) {
                  const fieldNewValuePermissionTarget = this.mapPermissionTarget(value as IPermissionTarget[]);
                  const field = {
                    kind: 'dxTagBox',
                    name: 'fieldNewValue',
                    caption: 'Neuer Wert',
                    editorOptions: {
                      displayExpr: 'name',
                      valueExpr: 'name',
                      dataSource: fieldNewValuePermissionTarget,
                      tagTemplate: this.userItemTemplate
                    }
                  } as FieldClient;
                  const permission = this.getFieldClient('dxTagBox', field);
                  result.formItems[0].items.push(permission);
                  result.formData.fieldNewValue = fieldNewValuePermissionTarget;
                } else {
                  const fieldNewValue = this.isJsonString(value as string)
                    ? (JSON.parse(value as string) as string)
                    : this.sanitizeHTML(value as string);
                  const field = this.getFieldClient('dxTextBox', {
                    name: 'fieldNewValue',
                    caption: 'Neuer Wert'
                  } as FieldClient);
                  result.formItems[0].items.push(field);
                  result.formData.fieldNewValue = fieldNewValue;
                }
              }
              break;

            case 'document':
              {
                const variable = (action as IWorkflowSetValueActionClient).source.value as string;
                if (variable === 'creation.user') {
                  const newValue = this.currentDocument.creation.user;
                  const user = { targetId: newValue.id, type: 'user' } as IPermissionTarget;
                  const fieldNewValue = this.mapPermissionTarget([user]);
                  const field = {
                    kind: 'dxTagBox',
                    name: 'fieldNewValue',
                    caption: 'Neuer Wert',
                    editorOptions: {
                      displayExpr: 'name',
                      valueExpr: 'name',
                      dataSource: [user],
                      tagTemplate: this.userItemTemplate
                    }
                  } as FieldClient;
                  const permission = this.getFieldClient('dxTagBox', field);
                  result.formItems[0].items.push(permission);
                  result.formData.fieldNewValue = fieldNewValue;
                } else {
                  const fieldName = variable.startsWith('fields') ? variable.split('.')[1] : variable;
                  const newValue = this.getFieldValueFromDocument(fieldName);
                  if (!newValue) {
                    return null;
                  }
                  const editorOptions = this.templateClient.fields.find(f => f.name === fieldName)?.editorOptions;
                  const field = this.getFieldClient(newValue?.type, {
                    name: 'fieldNewValue',
                    caption: 'Neuer Wert',
                    editorOptions
                  } as FieldClient);
                  result.formItems[0].items.push(field);
                  result.formData.fieldNewValue =
                    newValue?.value || ((action as IWorkflowSetValueActionClient).source.value as string);
                }
              }
              break;

            case 'current-user':
              {
                const user = { targetId: entry.user.userId, type: 'user' } as IPermissionTarget;
                const fieldNewValue = this.mapPermissionTarget([user]);
                const field = {
                  kind: 'dxTagBox',
                  name: 'fieldNewValue',
                  caption: 'Neuer Wert',
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: [user],
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.fieldNewValue = fieldNewValue;
              }
              break;
            case 'creation-user':
              {
                const user = { targetId: this.currentDocument.creation.user.id, type: 'user' } as IPermissionTarget;
                const fieldNewValueCreator = this.mapPermissionTarget([user]);
                const field = {
                  kind: 'dxTagBox',
                  name: 'fieldNewValueCreator',
                  caption: 'Neuer Wert',
                  editorOptions: {
                    displayExpr: 'name',
                    valueExpr: 'name',
                    dataSource: [user],
                    tagTemplate: this.userItemTemplate
                  }
                } as FieldClient;
                const permission = this.getFieldClient('dxTagBox', field);
                result.formItems[0].items.push(permission);
                result.formData.fieldNewValueCreator = fieldNewValueCreator;
              }
              break;

            default:
              {
                const value = (action as IWorkflowSetValueActionClient).source.value as string;
                const fieldNewValue = this.isJsonString(value) ? (JSON.parse(value) as string) : value;
                const field = this.getFieldClient('dxTextBox', {
                  name: 'fieldNewValue',
                  caption: 'Neuer Wert'
                } as FieldClient);
                result.formItems[0].items.push(field);
                result.formData.fieldNewValue = fieldNewValue;
              }
              break;
          }
        }
        break;

      case 'serial-number-peek':
      case 'serial-number-consume':
        {
          const fieldTarget = this.getFieldClient('dxTextBox', {
            name: 'targetField',
            caption: 'Feldname'
          } as FieldClient);

          const fielSerialNumber = this.getFieldClient('dxTextBox', {
            name: 'serialNumberName',
            caption: 'Nummerkreis'
          } as FieldClient);

          const targetField = (action as IWorkflowPeekSerialNumberActivityClient).target.value as string;
          const serialNumberName = (action as IWorkflowPeekSerialNumberActivityClient).serialNumberName;
          result.formItems[0].items.push(fieldTarget, fielSerialNumber);
          result.formData.targetField = targetField;
          result.formData.serialNumberName = serialNumberName;
        }
        break;

      case 'permission-script':
        {
          const fieldTarget = this.getFieldClient('dxTextArea', {
            name: 'targetField',
            caption: 'Script'
          } as FieldClient);
          const targetField = (action as IWorkflowPermissionScriptActionClient).script.value as string;
          result.formItems[0].items.push(fieldTarget);
          result.formData.targetField = targetField;
        }
        break;

      case 'meeting-invite':
      case 'meeting-protocol-approval':
        {
          const participantField = (action as IWorkflowMeetingInviteActivityClient).meetingParticipantsFieldName || '';
          const recorderField = (action as IWorkflowMeetingInviteActivityClient).recorderFieldName || '';
          const speakersField = (action as IWorkflowMeetingInviteActivityClient)?.speakersFieldName?.length
            ? (action as IWorkflowMeetingInviteActivityClient).speakersFieldName
            : '';
          const fielParticipantdName = participantField.startsWith('fields')
            ? participantField.split('.')[1]
            : participantField;
          const recorderFieldName = participantField.startsWith('fields') ? recorderField.split('.')[1] : recorderField;
          const fielSpeakersName = speakersField.startsWith('fields') ? speakersField.split('.')[1] : speakersField;
          if (fielParticipantdName && this.currentDocument.fields[fielParticipantdName]?.value) {
            const assignees = this.mapPermissionTarget(
              this.currentDocument.fields[fielParticipantdName]?.value as IPermissionTarget[]
            );
            const field = {
              kind: 'dxTagBox',
              name: 'assignees',
              caption: 'Teilnehmer',
              editorOptions: {
                displayExpr: 'name',
                valueExpr: 'name',
                dataSource: assignees,
                tagTemplate: this.userItemTemplate
              }
            } as FieldClient;
            const permission = this.getFieldClient('dxTagBox', field);
            result.formItems[0].items.push(permission);
            result.formData.assignees = assignees;
          }
          if (recorderFieldName?.length && this.currentDocument.fields[recorderFieldName]?.value) {
            const recorders = this.mapPermissionTarget(
              this.currentDocument.fields[recorderFieldName]?.value as IPermissionTarget[]
            );
            const field = {
              kind: 'dxTagBox',
              name: 'recorders',
              caption: 'Protokollführer',
              editorOptions: {
                displayExpr: 'name',
                valueExpr: 'name',
                dataSource: recorders,
                tagTemplate: this.userItemTemplate
              }
            } as FieldClient;
            const permission = this.getFieldClient('dxTagBox', field);
            result.formItems[0].items.push(permission);
            result.formData.recorders = recorders;
          }
          if (fielSpeakersName && this.currentDocument.fields[fielSpeakersName]?.value) {
            const speakers = this.mapPermissionTarget(
              this.currentDocument.fields[fielSpeakersName]?.value as IPermissionTarget[]
            );
            const field = {
              kind: 'dxTagBox',
              name: 'speakers',
              caption: 'Referenten',
              editorOptions: {
                displayExpr: 'name',
                valueExpr: 'name',
                dataSource: speakers,
                tagTemplate: this.userItemTemplate
              }
            } as FieldClient;
            const permission = this.getFieldClient('dxTagBox', field);
            result.formItems[0].items.push(permission);
            result.formData.speakers = speakers;
          }
        }
        break;

      default:
        break;
    }
    if (!Object.keys(result.formData).length) {
      return null;
    }
    if (result.formItems[0].items.length === 1) {
      (result.formItems[0] as Group).colCount = 1;
    }
    return result;
  }

  isJsonString = (value: string): boolean => {
    let result = true;
    try {
      JSON.parse(value);
    } catch (e) {
      result = false;
    }
    return result;
  };

  isArray(array: unknown): boolean {
    return Array.isArray(array);
  }

  /**
   * Sanitaze HTML
   *
   * @param str {string}
   */
  private sanitizeHTML(str: string): string {
    const temporaryDiv = document.createElement('div');
    temporaryDiv.innerHTML = str;
    return (temporaryDiv.textContent || temporaryDiv.innerText || '').trim();
  }

  mapPermissionTarget(assignees: IPermissionTarget[]): IWorkflowHistoryUser[] {
    const result: IWorkflowHistoryUser[] = [];
    if (assignees?.length) {
      for (const assignee of assignees) {
        if (assignee.type === 'role') {
          this.roleService
            .getRole(assignee.targetId)
            .pipe(
              filter(role => !!role),
              takeUntil(this.destroyed$)
            )
            .subscribe(role => {
              result.push({
                name: role.name,
                picture: ''
              });
            });
        }
        if (assignee.type === 'user') {
          this.userService
            .getUser(assignee.targetId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(user => {
              result.push({
                name: user.displayName || '[Benutzer wurde nicht gefunden]',
                picture: this.userService.getAvatarUrl(user)
              });
            });
        }
      }
    }
    return result;
  }

  getFieldValueFromDocument(fieldName: string): IFieldNewValue | null {
    const fieldValue = this.currentDocument.fields[fieldName]?.value as unknown;
    const result: IFieldNewValue = {
      type: this.templateClient.fields.find(f => f.name === fieldName)?.type,
      value: fieldValue
    };

    switch (result.type) {
      case 'dxTagBox':
        result.value = this.mapPermissionTarget(fieldValue as IPermissionTarget[]);
        break;
    }
    return result.type && (result.value || typeof result.value === 'boolean') ? result : null;
  }

  getFieldClient(type: FieldTypeClient, field: FieldClient): FieldClient | null {
    let newField: FieldClient;
    field.editorOptions = field.editorOptions ? field.editorOptions : { fieldVisible: true };
    (field.editorOptions as ICheckBoxGroupEditorOptions).stylingMode = 'outlined';
    switch (type) {
      case 'dxTagBox':
        newField = new PermissionTarget(field as PermissionTarget);
        break;
      case 'dxFileUploader':
        newField = new FileUploader(field as FileUploader);
        break;
      case 'dxRadioGroup':
        newField = new RadioGroup(field as RadioGroup);
        break;
      case 'dxTextArea':
        newField = new TextArea(field as TextArea);
        break;
      case 'staticText':
        newField = new StaticText(field as StaticText);
        break;
      case 'linkText':
        newField = new LinkText(field as LinkText);
        break;
      case 'dxSelectBox':
        newField = new SelectBox(field as SelectBox);
        break;
      case 'dxTextBox':
        newField = new TextBox(field as TextBox);
        break;
      case 'dxNumberBox':
        newField = new NumberBox(field as NumberBox);
        break;
      case 'dxCheckBox':
        newField = new CheckBox(field as CheckBox);
        break;
      case 'dxCheckboxGroup':
        newField = new CheckBoxGroup(field as CheckBoxGroup);
        break;
      case 'dxDateBox':
        newField = new DateBox(field as DateBox);
        break;
      case 'dxSlider':
        newField = new DxSlider(field as DxSlider);
        break;
      case 'dxHtmlEditor':
        newField = new RichTextEditor(field as RichTextEditor);
        break;
      default:
        newField = null;
        break;
    }
    return newField;
  }

  createPermissionTarget(compositeId: string): IPermissionTarget {
    const [type, targetId] = compositeId.split(':', 2);
    switch (type) {
      case 'user':
      case 'role':
        return {
          type,
          targetId,
          compositeId: `${type}:${targetId}`
        };
    }
    throw new TypeError("ID must be of type (('user')|('role')):(([0-9a-fA-F]{32})|([-0-9a-fA-F]{36}))");
  }

  /**
   * Get document workflow information
   */
  async getWorkflowInfo(): Promise<void> {
    if (!this.workflowDocument) {
      return;
    }
    const creationUser = await lastValueFrom(this.getHistoryUser(this.currentDocument.creation.user.id));

    let creator: IWorkflowHistoryUser[] = [
      {
        name: creationUser?.name || '[Benutzer wurde nicht gefunden]',
        picture: creationUser?.picture
      }
    ];
    if (this.currentDocument.creation.user.id !== this.currentDocument.creation.logonUserId) {
      const logonUserId = this.currentDocument.creation.logonUserId;
      const logonUser = await lastValueFrom(this.userService.getUser(logonUserId));
      creator = [
        {
          name: creationUser?.name ? `${creationUser?.name} *` : `[Benutzer wurde nicht gefunden] *`,
          picture: creationUser?.picture,
          logonUser
        }
      ];
    }
    const creation = this.currentDocument.creation;

    const stateLabel = this.documentService.getWorkflowStateLabel(this.workflowDocument) || '[unbekannt]';
    const fields = this.workflowDocument.fields;
    const assignees: IWorkflowHistoryUser[] = [];
    const currentAssignees: IWorkflowHistoryUser[] = [];
    if (fields?.assignee?.value) {
      for (const assignee of fields.assignee.value as IAssignment[]) {
        if (assignee.type === 'role') {
          const role = await lastValueFrom(
            this.roleService.getRole(assignee.targetId).pipe(
              filter(r => !!r),
              takeUntil(this.destroyed$)
            )
          );
          assignees.push({
            name: role.name,
            picture: ''
          });
        }
        if (assignee.type === 'user') {
          const user = await lastValueFrom(
            this.userService.getUser(assignee.targetId).pipe(takeUntil(this.destroyed$))
          );
          assignees.push({
            name: user?.displayName || '[Benutzer wurde nicht gefunden]',
            picture: this.userService.getAvatarUrl(user)
          });
        }
      }
    }
    if (fields.currentAssignee?.value) {
      for (const assignee of fields.currentAssignee.value as IAssignment[]) {
        if (assignee.type === 'role') {
          const role = await lastValueFrom(
            this.roleService.getRole(assignee.targetId).pipe(
              filter(r => !!r),
              takeUntil(this.destroyed$)
            )
          );
          currentAssignees.push({
            name: role.name,
            picture: ''
          });
        }
        if (assignee.type === 'user') {
          const user = await lastValueFrom(
            this.userService.getUser(assignee.targetId).pipe(takeUntil(this.destroyed$))
          );
          currentAssignees.push({
            name: user?.displayName || '[Benutzer wurde nicht gefunden]',
            picture: this.userService.getAvatarUrl(user)
          });
        }
      }
    }
    this.docInfos = { creation, creator, assignee: assignees, currentAssignee: currentAssignees, stateLabel };
  }

  /**
   * Show info popup
   */
  show = (): void => {
    void this.getWorkflowInfo();
    this.getWorkflowHistory();
    this.dialogOpen = true;
  };

  /**
   * Close info popup
   */
  close = (): void => {
    this.dialogOpen = false;
  };

  closePermissions = (): void => {
    this.dialogPermissionOpen = false;
  };

  userItemTemplate = (data: IWorkflowHistoryUser, element: HTMLElement): string => {
    let result: string;
    if (data.picture) {
      if (data?.picture) {
        result = `<img class="user-avatar-name" src="${data.picture}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
    } else {
      result = `<i class="material-icons mr-1">group</i>`;
    }
    if (data.logonUser) {
      this.popoverElement = element;
      this.popoverCreatorText = `Vertretung durch (<strong>${data.logonUser.displayName}</strong>)`;
      element.onmouseenter = this.showPopover;
      element.onmouseleave = this.hidePopover;
    }
    return `<div id="popoverCreatorElement" class="dx-tag-content name-selector">${result}<span>${data.name}</span></div>`;
  };

  showPopover = (): void => {
    void this.popOverCreatorField?.instance?.show();
  };

  hidePopover = (): void => {
    void this.popOverCreatorField?.instance?.hide();
  };

  showDiagram(): void {
    this.workflowDiagram.show();
  }

  showPermission(): void {
    this.getDocumentConfidential();
    this.dialogPermissionOpen = true;
  }

  onHideFormular = (): void => {
    this.edgeDocument = null;
  };

  showActivityDetails = (activity: IWorkflowHistoryActionLog, index: number): void => {
    const historyIndex = index.toString();
    this.currentActivity = activity;
    this.currentTargetId = `#popover-${this.currentActivity.actionId + historyIndex}`;
    this.currentPopupActivityVisible = true;
  };

  onShowing = (e: ShowingEvent): void => {
    this.currentAction = this.currentActivity.action;
    this.currentAction.formItems.forEach(formItem => {
      formItem.items.forEach(item => {
        if (!item) {
          const newIitem = this.getFieldClient('dxTextBox', {
            name: 'fieldNewValue',
            caption: 'Neuer Wert'
          } as FieldClient);
          const index = formItem.items.indexOf(item as FieldClient);
          formItem.items.splice(index, 1, newIitem);
          return;
        }
        if (
          (item as CheckBoxGroup).kind === 'dxCheckboxGroup' &&
          (item as CheckBoxGroup).editorOptions.checkboxGroupColumns > 0
        ) {
          const longestWord = (item as CheckBoxGroup).editorOptions.choices?.reduce((c, v) =>
            c.label?.length > v.label?.length ? c : v
          );
          (item as CheckBoxGroup).editorOptions.checkboxGroupColumnWidth = longestWord
            ? getStringLength(longestWord?.label, e.element, 'dx-widget') + 40
            : 200;
        }
      });
    });
    void this.detailsActionForm?.instance?.repaint();
  };

  onHidden = (): void => {
    this.currentAction = null;
    void this.detailsActionForm?.instance?.repaint();
  };

  private getDocumentConfidential() {
    const noneConfidentialId = '00000000-0000-0000-0000-000000000000';
    const docConfidentialId = this.currentDocument.confidential?.confidentialId;
    const confidentialAllowed = this.currentDocument.confidentialAllowed;
    if (noneConfidentialId === docConfidentialId) {
      this.documentConfidential = '';
      return;
    }
    const currendConfid = confidentialAllowed.find(({ confidentialId }) => confidentialId === docConfidentialId);
    if (currendConfid) {
      this.documentConfidential = currendConfid.confidential.name;
    }
  }

  private sortByName = (a: IPermission, b: IPermission): number => {
    const typeA = a.user ? 'user' : 'role';
    const typeB = b.user ? 'user' : 'role';
    if (typeA === 'user' && typeB === 'role') {
      return -1;
    }
    if (typeA === 'role' && typeB === 'user') {
      return 1;
    }
    const nameA = a.data?.name?.toLowerCase();
    const nameB = b.data?.name?.toLowerCase();
    return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
  };

  /**
   * Get user from current document history
   */
  private getHistoryUser(userId: string): Observable<IWorkflowHistoryUser> {
    return this.userService.getUser(userId).pipe(
      map(_user => ({
        name: _user.displayName,
        picture: this.userService.getAvatarUrl(_user)
      })),
      takeUntil(this.destroyed$)
    );
  }

  /**
   * Get user and LogonUser from current document history
   */
  private getHistoryImpersonatedUser(userId: string, logonUserId: string): Observable<IWorkflowHistoryUser> {
    return this.userService.getUsers([userId, logonUserId]).pipe(
      map(_users => {
        const logonUser = _users.find(user => user.id === logonUserId);
        const impersonatedUser = _users.find(user => user.id === userId);
        return {
          name: impersonatedUser ? `${impersonatedUser?.displayName} *` : `[Benutzer wurde nicht gefunden]`,
          picture: this.userService.getAvatarUrl(impersonatedUser),
          logonUser
        };
      }),
      takeUntil(this.destroyed$)
    );
  }

  /**
   * Get Assignee from current document history
   */
  private async getHistoryAssignee(field: IHistoryField): Promise<IWorkflowHistoryAssignee[]> {
    const histAssignee: IWorkflowHistoryAssignee[] = [];
    for (const assignee of field.assignees) {
      if (assignee.type === 'user') {
        const user = await lastValueFrom(this.userService.getUser(assignee.targetId));
        histAssignee.push({
          type: 'user',
          name: user.displayName || '[Benutzer wurde nicht gefunden]',
          picture: this.userService.getAvatarUrl(user)
        });
      } else {
        const role = await lastValueFrom(this.roleService.getRole(assignee.targetId));
        if (role) {
          histAssignee.push({
            type: 'role',
            name: role.name,
            picture: ''
          });
        }
      }
    }
    return histAssignee;
  }

  private findBallotData(fields: IDocumentFields, workflowDocument: IWorkflowDocument): Record<string, unknown> {
    const edges = workflowDocument.workflow.edges;
    const nodes = workflowDocument.workflow.vertices;
    const edgeActions = edges.map(e => e.actions?.filter(a => a.type === 'create-ballot') || [])?.flat();
    const nodeActions = nodes
      .map(
        n =>
          [].concat(
            n.enterActions?.filter(a => a.type === 'create-ballot') || [],
            n.leaveActions?.filter(a => a.type === 'create-ballot') || []
          ) as TActionServer[]
      )
      .flat();
    const actions = [].concat(edgeActions || [], nodeActions || []) as TActionServer[];
    const result: Record<string, unknown> = {};
    for (const action of actions) {
      if (!result[action.actionId]) {
        const ballotIdSource = (action as IWorkflowCreateBallotActionServer).ballotIdStore.source.split('fields.')[1];
        const source = fields[ballotIdSource];
        result[action.actionId] = source ? source.value : null;
      }
    }

    return result;
  }

  private getVoteValue(ballotDocument: IWorkflowDocument, vote: string): string {
    let label = '';
    try {
      const values = ballotDocument.fields.possibleVotes.value as IPossibleVote[];
      const value = values.find((item: IPossibleVote) => item.value === vote) as IPossibleVoteValue;
      label = value.label;
    } catch (e) {
      // nothing to do
    }
    return label;
  }

  private assignVariables(document: IDocument, variables: Record<string, unknown>): void {
    Object.keys(document.fields).forEach(key => {
      document.fields[key].value = variables[key] || null;
    });
    this.edgeDocument = document;
  }
}
