<dx-popup
  id="popup-notifications-list"
  width="50vw"
  [height]="'auto'"
  maxHeight="70vh"
  [fullScreen]="dialogFullscreen"
  [shading]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [(visible)]="notificationsVisible"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: { type: 'normal', icon: 'material-icons md-24 notifications', elementAttr: { class: 'button-icon' } }
    },
    { text: 'Benachrichtigungen', location: 'before', toolbar: 'top' },
    {
      name: 'Fullscreen',
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: {
        type: 'normal',
        icon: 'material-icons md-22 open_in_full',
        hint: 'Vollbildmodus umschalten'
      },
      onClick: dialogToogleFullscreen
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      options: {
        type: 'normal',
        icon: 'close',
        hint: 'Schließen'
      },
      onClick: closeNotifications
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view #scrollViewNotifications showScrollbar="always">
      <div
        *ngFor="let notification of notifications"
        class="window-container notifications__item"
        id="{{ notification.id }}">
        <div class="window-container__header notifications__header">
          <div class="window-container__title">
            {{ notification.subject }}
          </div>
        </div>
        <div class="window-container_content notifications__content">
          <dx-html-editor valueType="html" readOnly="true" value="{{ notification.htmlContent }}"></dx-html-editor>
        </div>
        <div *ngIf="notification.attachment" class="notifications__attachment">
          <span>Anlage: </span>
          <span class="notifications__attachment__name">{{ notification.attachment.name }}</span>
          <a
            [href]="notification.attachment | fileDownloadLink : 'url' | async"
            download="{{ notification.attachment.name }}">
            <i class="material-icons">save_alt</i>
          </a>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
