
<dx-form
  #approvalForm
  id="approvalForm"
  *ngIf="approvalFormData"
  [readOnly]="!(editMode === 'Edit')"
  [showRequiredMark]="editMode === 'Edit'"
  labelLocation="left"
  [showColonAfterLabel]="false"
  [showValidationSummary]="true"
  [formData]="approvalFormData"
  validationGroup="approvalForm"
  (onContentReady)="onContentFormReady($event)"
  class="decision-form">
  <dxi-item>
    <dx-validation-summary></dx-validation-summary>
  </dxi-item>

  <dxi-item itemType="group" colCount="2" cssClass="group-item">
    <dxi-item caption="Beschlussdaten" itemType="group" colCount="1" cssClass="group-item">
      <dxi-item
        [label]="{ text: 'Beschlusskategorie' }"
        dataField="category"
        editorType="dxSelectBox"
        [editorOptions]="{
          searchEnabled: true,
          dataSource: decisionCategories,
          displayExpr: 'label',
          valueExpr: 'value',
          acceptCustomValue: true,
          onValueChanged: onFieldDataChanged,
          placeholder: 'Bitte wählen Sie die Beschlusskategorie aus.',
          readOnly: currentDocument | fieldHasPermission : '--approval_category' : 'update'
        }">
        <dxi-validation-rule
          type="required"
          message="Bitte geben Sie die
          Beschlusskategorie an."></dxi-validation-rule>
      </dxi-item>
      <dxi-item
        [label]="{ text: 'Beschlussnummer' }"
        dataField="number"
        editorType="dxTextBox"
        [editorOptions]="{ readOnly: true }">
      </dxi-item>
      <dxi-item
        [label]="{ text: 'Beschlussdatum' }"
        dataField="date"
        editorType="dxDateBox"
        [editorOptions]="{
          placeholder: 'Bitte geben Sie das Beschlussdatum an.',
          invalidDateMessage: 'Der Wert muss ein Datum sein.',
          useMaskBehavior: true,
          displayFormat: 'dd.MM.yyyy',
          readOnly: currentDocument | fieldHasPermission : '--approval_date' : 'update'
        }">
        <dxi-validation-rule type="required" message="Bitte geben Sie das Beschlussdatum an."></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
    <dxi-item caption="Beschlussvermerk*" itemType="group" cssClass="group-item">
      <dxi-item
        dataField="type"
        editorType="dxRadioGroup"
        [editorOptions]="{
          dataSource: decisionTypes | async,
          displayExpr: 'label',
          valueExpr: 'value',
          layout: 'vertical',
          width: 'auto',
          readOnly: currentDocument | fieldHasPermission : '--approval_type' : 'update'
        }">
        <dxo-label [visible]="false"></dxo-label>
        <dxi-validation-rule
          type="required"
          message="Bitte wählen Sie den
          Beschlussvermerk aus."></dxi-validation-rule>
      </dxi-item>
    </dxi-item>
  </dxi-item>
  <dxi-item caption="Beschlusskommentar" itemType="group" cssClass="group-item">
    <dxi-item
      dataField="comment"
      editorType="dxTextArea"
      [editorOptions]="{
        minHeight: '100px',
        maxHeight: '100px',
        readOnly: currentDocument | fieldHasPermission : '--approval_comment' : 'update'
      }">
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>
  </dxi-item>
</dx-form>
