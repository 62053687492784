<dx-progress-bar
  #progressBar
  *ngIf="tasksCount > 0"
  id="totalStatus"
  width="100%"
  height="50"
  [class.complete]="progressBar.value === 100"
  [min]="0"
  [max]="100"
  [statusFormat]="progressBarFormat"
  [value]="projectProgress">
</dx-progress-bar>

<app-dynamic-list
  *ngIf="showTaskList"
  [documentType]="documentType"
  [embeddedMode]="false"
  [noState]="true"
  [allowDeleting]="hasDeletePermission"
  [additionalSettings]="additionalSettings"
  (emitter)="eventDynamicListResult($event)"
  [buttons]="dynamicListButtons"></app-dynamic-list>

<dx-gantt
  *ngIf="showGantt && dataSource.length"
  taskListWidth="600"
  scaleType="weeks"
  height="'auto'"
  (onTaskDblClick)="onTaskDblClick($event)"
  id="projectIncludeGantt"
  [taskTooltipContentTemplate]="'taskTooltipContentTemplate'">
  <dxo-toolbar>
    <dxi-item name="collapseAll"></dxi-item>
    <dxi-item name="expandAll"></dxi-item>
    <dxi-item name="zoomIn"></dxi-item>
    <dxi-item name="zoomOut"></dxi-item>
    <dxi-item
      widget="dxCheckBox"
      cssClass="column-checkbox"
      [options]="{
        text: 'Priorität',
        value: columnVisible,
        onValueChanged: onShowGanttColumn
      }"></dxi-item>
  </dxo-toolbar>

  <dxo-editing
    [enabled]="true"
    [allowDependencyAdding]="false"
    [allowDependencyDeleting]="false"
    [allowResourceAdding]="false"
    [allowResourceDeleting]="false"
    [allowTaskAdding]="false"
    [allowTaskDeleting]="false"
    [allowTaskUpdating]="false">
  </dxo-editing>
  <dxo-context-menu [enabled]="false"></dxo-context-menu>

  <dxo-tasks [dataSource]="dataSource"></dxo-tasks>

  <dxi-column dataField="title" caption="Aufgabe" [width]="200"></dxi-column>
  <dxi-column dataField="chairdata" caption="Verantwortlich" [width]="'auto'" minWidth="200" [encodeHtml]="false">
  </dxi-column>

  <dxi-column
    caption="Priorität"
    [width]="'auto'"
    dataField="document.fields.priority.value"
    [(visible)]="columnVisible">
  </dxi-column>

  <dxi-column dataField="status" caption="Status" [width]="'auto'"></dxi-column>

  <dxo-validation [autoUpdateParentTasks]="true"></dxo-validation>

  <div *dxTemplate="let task of 'taskTooltipContentTemplate'" class="task-tooltip">
    <div class="task-tooltip__title">{{ task.title }}</div>
    <div class="task-tooltip__content">
      <span *ngIf="task.endDate">Ende</span><span *ngIf="task.endDate">{{ task.endDate | date: 'dd.MM.y' }}</span>
      <span *ngIf="task.progress">Fortschritt</span><span *ngIf="task.progress">{{ task.progress }}%</span>
      <span>Verantwortlich</span><span [innerHTML]="task.chairdata | safeHtml"></span>
    </div>
  </div>
  <div *dxTemplate="let cell of 'priorityTemplate'">
    {{ getPriorityDataSource(cell) }}
  </div>
</dx-gantt>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup-Dialoge -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  [(visible)]="popupFreeTasksListVisible"
  width="75vw"
  [height]="600"
  [showTitle]="true"
  [title]="popupFreeTaskListTitle"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="true"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'add', text: 'Übernehmen', visible: 'true' },
      onClick: popupFreeTaskListOk
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
      onClick: popupFreeTaskListCancel
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <app-component-notification></app-component-notification>
    <dx-scroll-view width="100%" height="100%">
      <app-dynamic-list
        [documentType]="documentType"
        [embeddedMode]="false"
        [selectionMode]="selectionMode"
        [additionalSettings]="additionalSettingsFreeTasks"
        (emitter)="eventDynamicListResultFreeTasks($event)"></app-dynamic-list>
    </dx-scroll-view>
  </div>
</dx-popup>
<app-document-editor [parentDocument]="currentDocument" (dialogResult)="dialogResult($event)"> </app-document-editor>
