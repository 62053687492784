import { Pipe, PipeTransform } from '@angular/core';
import { isAssigneeFormular } from '@factories/workflow.factory';
import { IEdgeClient } from '@interfaces/edgeClient';

@Pipe({
  name: 'isAssigneeFormular'
})
export class IsAssigneeFormularPipe implements PipeTransform {

  transform(edge: IEdgeClient): boolean {
    return isAssigneeFormular(edge);
  }
}
