<div class="error404">
  <svg viewBox="0 0 230.072 91.434" xmlns="http://www.w3.org/2000/svg" class="error404__picture">
    <path
      class="error404__picture--fill"
      d="M115.036 91.434c-6.559
            0-12.8-1.389-18.445-3.887a.924.924 0 0 1-.267-.12 46.018 46.018 0 0
            1-19.073-16 .937.937 0 0 1-.21-.31 45.457 45.457 0 0
            1-7.722-25.4c0-18.482 11.024-34.437 26.842-41.636a.926.926 0 0 1
            .64-.287A45.464 45.464 0 0 1 115.037 0c25.208 0 45.717 20.508 45.717
            45.717 0 9.391-2.847 18.13-7.723 25.4a.925.925 0 0 1-.21.31c-8.237
            12.07-22.101 20.007-37.784 20.007zm-13.098-3.856a43.708 43.708 0 0 0
            13.098 1.998c4.561 0 8.96-.7 13.1-1.998zm-4.88-1.858h35.884c.023 0
            .045 0 .067.002a44.134 44.134 0 0 0 17.347-14.03h-62.43c4.781 4.825
            9.754 7.866 9.83 7.912a.93.93 0 1 1-.963
            1.59c-.345-.209-6.236-3.812-11.456-9.502h-5.62a44.142 44.142 0 0 0
            17.34 14.028zM86.18 69.834h65.473a43.583 43.583 0 0 0
            7.231-23.188h-81.48c.316 4.658 1.273 11.002 3.962 16.298 1.27 2.5
            2.978 4.822 4.814 6.89zm-7.761
            0h5.304c-1.516-1.85-2.913-3.879-4.014-6.05-2.878-5.668-3.873-12.309-4.186-17.138h-4.336a43.594
            43.594 0 0 0 7.232 23.188zm-1.113-25.046h81.579a43.587 43.587 0 0
            0-7.028-22.877h-37.162c.166 1.058.22 2.154.155 3.274-.24 4.161-2.081
            8.162-5.185 11.266s-7.105 4.945-11.266
            5.185c-4.205.243-8.065-1.172-10.876-3.983s-4.226-6.674-3.984-10.876a16.617
            16.617 0 0 1 1.034-4.867h-6.357a43.59 43.59 0 0 0-7.029
            22.878h4.243c-.096-2.761.023-4.614.037-4.815a.93.93 0 0 1
            1.854.128c-.004.049-.121 1.893-.015 4.687zm23.602-32.63c-.269
            0-.538.008-.81.023-3.706.214-7.279 1.863-10.06 4.644-2.78 2.78-4.43
            6.353-4.643 10.06-.212 3.665 1.01 7.022 3.442 9.454 2.431 2.43 5.79
            3.652 9.454 3.442 3.707-.214 7.279-1.863 10.06-4.644 5.71-5.71
            6.25-14.465 1.201-19.514-2.25-2.25-5.296-3.465-8.644-3.465zm13.369
            7.894h36.305a44.123 44.123 0 0 0-17.705-14.4H97.195a44.117 44.117 0
            0 0-17.705 14.4h5.907a18.236 18.236 0 0 1 3.328-4.541c3.104-3.104
            7.105-4.945 11.266-5.185 4.202-.245 8.065 1.172 10.876 3.984a13.522
            13.522 0 0 1 3.41 5.742zM102.136
            3.794h25.8c-4.08-1.258-8.412-1.936-12.9-1.936s-8.82.678-12.9
            1.936zM99.195 26.91h-.203a.93.93 0 0 1 0-1.858h.203a.93.93 0 0 1 0
            1.858z" />
    <path
      class="error404__picture--fill"
      d="M45.588
                67.672v23.762h-2.841V67.672H0v-3.487L41.197
                0h4.39v65.089h15.24v2.583zM42.747 3.099h-.39L2.454
                65.09h40.293zM214.833
                67.672v23.762h-2.842V67.672h-42.746v-3.487L210.442
                0h4.39v65.089h15.24v2.583zM211.99 3.099h-.389L171.7
                65.09h40.292V3.099z" />
  </svg>

  <h2>
    Oops, der von Ihnen besuchte Inhalt existiert leider nicht mehr, <br />
    oder Sie besitzen keine ausreichenden Rechte, <br />
    um diesen Inhalt einsehen zu dürfen.
  </h2>

  <p>
    Gehe zurück zur
    <a href="javascript:window.history.back()"><i class="material-icons">arrow_back</i>vorherigen Seite</a>
    oder zur
    <a href="/application/home"><i class="material-icons">home</i>Startseite</a>
  </p>
</div>
