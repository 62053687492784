import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { StartpageComponent } from '@components/startpage/startpage.component';
import { PageNotFoundComponent } from '@components/page-not-found/page-not-found.component';
import { ApplicationComponent } from './application/application.component';
import { AdministrationComponent } from './administration/administration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { isPersistanceGuard, loginRouteGuard } from './_core/guards';
import { siamConst } from '@interfaces/siamConst';

const routes: Routes = [
  // -- Loginseite
  { path: '', redirectTo: '/start', pathMatch: 'full' },
  {
    path: 'start',
    component: StartpageComponent,
    canActivate: [isPersistanceGuard()],
    data: { breadcrumbLabel: `${siamConst.appTitle}` },
  },

  // -- Navigationseinträge Anwendungsfunktionen
  {
    path: 'application',
    component: ApplicationComponent,
    canActivate: [loginRouteGuard()],
    loadChildren: () => import('./application/application.module').then(mod => mod.ApplicationModule),
  },

  // -- Navigation für das Dashboard
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [loginRouteGuard()],
    loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
  },

  // -- Navigationseinträge Adminbereich
  {
    path: 'administration',
    component: AdministrationComponent,
    canActivate: [loginRouteGuard()],
    loadChildren: () => import('./administration/administration.module').then(mod => mod.AdministrationModule),
  },

  // unbekannte Routen führen zur PageNotFound-Seite
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // debugging purposes only
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
