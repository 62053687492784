import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { isDate, mapper } from '@factories/helpers';
import { LoggerService } from '@services/logger.service';

@Component({
  selector: 'app-jsonviewer',
  templateUrl: './jsonviewer.component.html',
  styleUrls: ['./jsonviewer.component.scss']
})
export class JsonviewerComponent implements OnInit, OnChanges {
  @Input() json: Record<string, unknown> | Record<string, unknown>[];

  items: Record<string, unknown>[] = [];

  constructor(private logger: LoggerService) {
    logger.debug('Konstruktor gestartet.');
  }

  ngOnInit(): void {
    const isObject = this.json !== null && typeof this.json === 'object';
    if (!isObject && !Array.isArray(this.json)) {
      this.logger.warn('JSON value is neither an object or array. Given value was: {@json}', this.json);
      return;
    }
    this.items = mapper<Record<string, unknown>>(this.json, (v: unknown, k: unknown) => this.createItem(v, k)).sort(
      (a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0)
    );
  }

  ngOnChanges(): void {
    this.items = mapper<Record<string, unknown>>(this.json, (v: unknown, k: unknown) => this.createItem(v, k)).sort(
      (a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0)
    );
  }

  createItem(value: unknown, key: unknown): Record<string, unknown> {
    const item: Record<string, unknown> = {
      key: typeof key === 'undefined' || key === null ? '""' : key,
      value,
      title: value,
      type: undefined,
      isOpened: false,
      isExpandable: false,
      valueClasses: {
        'item-title': true
      }
    };

    if (typeof item.value === 'string') {
      item.type = 'string';
      item.title = `"${item.value}"`;
    } else if (typeof item.value === 'number') {
      item.type = 'number';
    } else if (typeof item.value === 'boolean') {
      item.type = 'boolean';
    } else if (isDate(item.value)) {
      item.type = 'date';
    } else if (Array.isArray(item.value)) {
      item.type = 'array';
      item.title = `Array(${item.value.length})`;
      item.isExpandable = item.value.length !== 0;
    } else if (typeof item.value === 'function') {
      item.type = 'function';
    } else if (item.value !== null && typeof item.value === 'object') {
      item.type = 'object';      
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (Object.keys(Object(item.value)).length > 0) {
        item.title = 'object';
        item.isExpandable = true;
      } else {
        item.title = 'object {}';
        item.isExpandable = false;
      }
    } else if (item.value === null) {
      item.type = 'null';
    } else if (typeof item.value === 'undefined') {
      item.type = 'undefined';
    }

    (item.valueClasses as Record<string, boolean>)[`obj-${item.type as string}`] = true;

    return item;
  }

  toggle(item: Record<string, unknown>): void {
    if (item.isExpandable) {
      item.isOpened = !item.isOpened;
    }
  }
}
