<div class="wrapper">
  <header class="header header-bg">
    <app-header-container [applicationMode]="applicationMode"></app-header-container>
  </header>

  <div id="main-view" class="main-wrapper homepage-bg">
    <app-navigation-pane *ngIf="navigationDataSource" [naviDataSource]="navigationDataSource" [applicationMode]="applicationMode"></app-navigation-pane>
    <main appScrollPosition (scrollPosition)="getScrollPosition($event)" class="main-content">
      <app-history-api></app-history-api>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
