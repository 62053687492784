import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { LoggerService } from './logger.service';
import { NotifyService } from '@services/notify.service';

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class AudioRecordService {
  private stream: MediaStream;
  private recorder: RecordRTC.StereoAudioRecorder;
  private interval: ReturnType<typeof setInterval>;
  private startTime: DateTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  constructor(private logger: LoggerService) {}

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      return;
    }
    this._recordingTime.next('00:00');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(s => {
        this.stream = s;
        this.record();
      })
      .catch((error: string) => {
        this._recordingFailed.next(error);
        NotifyService.global.error(`Fehler beim Zugriff auf das Mikrofon`);
        this.logger.error(`Fehler beim Zugriff auf das Mikrofon: ${error}`);
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  stopRecording(docName = 'agenda') {
    if (this.recorder) {
      this.recorder.stop(blob => {
        if (this.startTime) {
          const mp3Name = encodeURIComponent(`${docName}_audio.mp3`).replace(/%20/g, '_');
          this.stopMedia();
          this._recorded.next({ blob: blob, title: mp3Name });
        }
        this.stopMedia();
        this._recordingFailed.next('STOP');
      });
    }
  }

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/webm'
    });

    this.recorder.record();
    this.startTime = DateTime.now();
    this.interval = setInterval(() => {
      const currentTime = DateTime.now();
      const diffTime = currentTime.diff(this.startTime);
      const time = diffTime.toFormat("mm':'ss");
      this._recordingTime.next(time);
    }, 1000);
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }
}
