import { Component } from '@angular/core';
import { INavigationGroup, TApplicationMode } from '@interfaces/siam';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent {
  applicationMode: TApplicationMode = 'administration';

  naviDataSource: INavigationGroup[] = [
    {
      text: 'KONFIGURATION',
      icon: 'checklist_rtl',
      isClosed: false,
      forAdminOnly: false,
      links: [
        {
          icon: 'usb',
          text: 'Schnittstellen',
          name: 'schnittstellen',
          routerLink: ['interfaces'],
          capabilities: ['/ventuno/account/administrator', '/ventuno/configuration']
        },
        {
          icon: 'speaker_notes',
          text: 'Benachrichtigungen',
          name: 'benachrichtigungen',
          routerLink: ['notifications'],
          capabilities: ['/ventuno/account/administrator', '/siam/notifications']
        },
        {
          icon: 'filter_none',
          text: 'Dokumenttypen',
          name: 'dokumenttypen',
          routerLink: ['templates'],
          capabilities: ['/ventuno/account/administrator', '/siam/templates']
        },
        {
          icon: 'rocket_launch',
          text: 'Workflows',
          name: 'workflows',
          routerLink: ['workflows'],
          capabilities: ['/ventuno/account/administrator', '/siam/workflows']
        },
        {
          icon: 'lock',
          text: 'Sicherheitsstufen',
          name: 'confidential',
          routerLink: ['confidential'],
          forAdminOnly: true,
          capabilities: ['/ventuno/account/administrator']
        },
        {
          icon: 'email',
          text: 'Mailschablonen',
          name: 'mailschablonen',
          routerLink: ['mailtemplates'],
          capabilities: ['/ventuno/account/administrator', '/siam/email-templates']
        },
        {
          icon: 'format_list_numbered',
          text: 'Nummernkreise',
          name: 'nummernkreise',
          routerLink: ['numbergroups'],
          capabilities: ['/ventuno/account/administrator', '/siam/numbers']
        },
        {
          icon: 'approval',
          text: 'Beschlusskategorien',
          name: 'Beschlusskategorien',
          routerLink: ['decisionstamp'],
          capabilities: ['/ventuno/account/administrator']
        },
        {
          icon: 'list',
          text: 'Schlüsselwerte',
          name: 'schluesselwerte',
          routerLink: ['lists'],
          capabilities: ['/ventuno/account/administrator', '/siam/lists']
        },
        {
          icon: 'label',
          text: 'Labels',
          name: 'labels',
          routerLink: ['labels'],
          capabilities: ['/ventuno/account/administrator', '/siam/lists']
        },
        {
          icon: 'palette',
          text: 'Design',
          name: 'design',
          routerLink: ['layout'],
          forAdminOnly: true,
          capabilities: ['/ventuno/account/administrator']
        },
        {
          icon: 'settings',
          text: 'Erweiterte Einstellungen',
          name: 'configuration',
          routerLink: ['configuration'],
          forAdminOnly: true,
          capabilities: ['/ventuno/account/administrator']
        }
      ]
    },
    {
      text: 'BENUTZER',
      icon: 'recent_actors',
      forAdminOnly: false,
      isClosed: true,
      links: [
        {
          icon: 'person',
          text: 'Benutzer',
          name: 'benutzer',
          routerLink: ['user'],
          forAdminOnly: false,
          capabilities: ['/ventuno/account/administrator', '/ventuno/account/account-administrator']
        },
        {
          icon: 'supervisor_account',
          text: 'Rollen',
          name: 'rollen',
          routerLink: ['roles'],
          forAdminOnly: false,
          capabilities: ['/ventuno/account/administrator', '/ventuno/account/account-administrator']
        },
        {
          icon: 'vpn_key',
          text: 'Berechtigungen',
          name: 'berechtigungen',
          routerLink: ['capabilities'],
          capabilities: [
            '/ventuno/account/administrator',
            '/ventuno/account/account-administrator',
            '/ventuno/capabilities'
          ]
        },
        {
          icon: 'verified',
          text: 'Audit',
          name: 'audit',
          routerLink: ['audit'],
          capabilities: ['/ventuno/account/administrator', '/ventuno/account/account-administrator']
        }
      ]
    },
    {
      text: 'TOOLS',
      icon: 'construction',
      forAdminOnly: false,
      isClosed: true,
      links: [
        {
          icon: 'file_copy',
          text: 'Backoffice',
          name: 'backoffice',
          routerLink: ['backoffice'],
          forAdminOnly: true,
          capabilities: ['/ventuno/account/administrator']
        },
        {
          icon: 'description',
          text: 'Logs',
          name: 'logs',
          routerLink: ['logs'],
          forAdminOnly: false,
          capabilities: [
            '/ventuno/account/administrator',
            '/ventuno/account/account-administrator',
            '/siam/lists',
            '/siam/numbers',
            '/siam/email-templates',
            '/siam/workflows',
            '/siam/templates',
            '/siam/notifications',
            '/ventuno/configuration'
          ]
        },
        {
          icon: 'pending_actions',
          text: 'Hintergrundjobs',
          name: 'backgroundjobs',
          routerLink: ['backgroundjobs'],
          forAdminOnly: true,
          capabilities: ['/ventuno/account/administrator']
        },
        {
          icon: 'outgoing_mail',
          text: 'E-Mails',
          name: 'emails',
          routerLink: ['emails'],
          forAdminOnly: false,
          capabilities: [
            '/ventuno/account/administrator',
            '/ventuno/account/account-administrator',
            '/siam/lists',
            '/siam/numbers',
            '/siam/email-templates',
            '/siam/workflows',
            '/siam/templates',
            '/siam/notifications',
            '/ventuno/configuration'
          ]
        },
        {
          icon: 'insert_link',
          text: 'GraphiQL',
          name: 'graphiQL',
          href: '/graphiql',
          forAdminOnly: true,
          capabilities: ['/ventuno/account/administrator']
        },
        {
          icon: 'insert_link',
          text: 'Swagger',
          name: 'swagger',
          href: '/swagger',
          forAdminOnly: true,
          capabilities: ['/ventuno/account/administrator']
        }
      ]
    }
  ];
}
