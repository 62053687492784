import { DocumentEditorComponent } from 'src/app/application/components/dialogs/document-editor/document-editor.component';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NotifyService } from '@services/notify.service';
import { TemplateService } from '@services/template.service';
import { DocumentService } from '@services/document.service';
import { DxDataGridComponent, DxPopoverComponent, DxTextBoxComponent } from 'devextreme-angular';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { concat, forkJoin, lastValueFrom, Observable, of, Subject, zip, concatMap, toArray } from 'rxjs';
import { ApprovalDialogComponent } from '../../../dialogs/approval-dialog/approval-dialog.component';
import { ApprovalService } from '@services/approval.service';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { PopupSettings, ToolbarItem } from '@services/popup-helper.service';
import {
  IActionStandard,
  IAgenda,
  IApprovalForm,
  IDocument,
  IDocumentFields,
  IDocumentsSearch,
  IError,
  IEventEmitter,
  IEventTask,
  INameSelectorDialogRecord,
  IPermissionTarget,
  IReference,
  ISearch,
  IUser,
  IUserRoleEntry,
  TEditMode,
  TTag
} from '@interfaces/siam';
import { IDxDropDownItem, IDxFormItemTemplate } from '@interfaces/devextreme';
import * as Factory from '@factories/document.factory';
import { confirm } from 'devextreme/ui/dialog';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { LoggerService } from '@services/logger.service';
import { create } from '@factories/user.factory';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { LoadPanelService } from '@services/load-panel.service';
import { siamConst } from '@interfaces/siamConst';
import { CellHoverChangedEvent, ContentReadyEvent, ToolbarPreparingEvent } from 'devextreme/ui/data_grid';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';
import { ClickEvent } from 'devextreme/ui/button';
import { ItemRenderedEvent } from 'devextreme/ui/action_sheet';
import { ValueChangedEvent } from 'devextreme/ui/slider';
import { NumberGroupsService } from '@services/number-groups.service';
import { ConfirmPopupComponent, IConfirmPopupSettings } from '@dialogs/confirm-popup/confirm-popup.component';
import { NameSelectorComponent } from '@dialogs/name-selector/name-selector.component';

const FIELDNAME_AGENDALIST = '--siam-agendaitems';
const selectedFieldsSubmission = [
  'fields.subject',
  'fields.attachments',
  'fields.desireddate',
  'fields.desiredduration',
  'fields.body',
  'fields.speakers',
  'template.caption',
  'documentWorkflowDocument.currentStatusLabel',
  'creation.timestamp',
  'creation.userId',
  'tags',
  'references'
];

interface ITimeSliderSettings {
  min: number;
  max: number;
  step: number;
  value: number;
}

interface IAgendaCreatemAction {
  value: number;
  label?: string;
  template?: (data: unknown, index: number, element: HTMLElement) => string;
  action: 'newSubmission' | 'newBreak' | 'newFreeTop' | 'newOldTop' | 'addTop' | 'none';
  name?: string;
  icon?: string;
  disabled?: boolean;
}

interface IFreeTop {
  id: string;
  subject: string;
  document: IDocument;
  creatordata?: string;
  creatorname?: string;
  attachment?: boolean;
  agendaSubject?: string;
  agendaType?: string;
}

interface IParentDocument {
  parentDocumentType?: string;
  parentDocumentIcon?: string;
  parentDocumentSubject?: string;
}

interface ISubmissionTop {
  id: string;
  subject: string;
  type: string;
  document: IDocument;
  state?: string;
  creatordata?: string;
  speakers?: string;
  creatorname?: string;
  department?: string;
  assigneename?: string;
  parentDocuments?: IParentDocument[];
}

const splitterTpl = (data: unknown, index: number, element: HTMLElement): string => {
  element.classList.add('is-menu-separator-ct');
  return '<hr class="is-menu-separator">';
};

@Component({
  selector: 'app-agenda-include',
  templateUrl: './agenda-include.component.html',
  styleUrls: ['./agenda-include.component.scss']
})
export class AgendaIncludeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DocumentEditorComponent, { static: true }) documentEditorComponent: DocumentEditorComponent;
  @ViewChild('submissionSelectionGrid') submissionSelectionGrid: DxDataGridComponent;
  @ViewChild('freeTopSelectionGrid') freeTopSelectionGrid: DxDataGridComponent;
  @ViewChild('childrenTopSelectionGrid') childrenTopSelectionGrid: DxDataGridComponent;
  @ViewChild(ApprovalDialogComponent, { static: true }) approvalDialog: ApprovalDialogComponent;
  @ViewChild(ConfirmPopupComponent) confirmPopup: ConfirmPopupComponent;
  @ViewChild(NameSelectorComponent) nameSelector: NameSelectorComponent;
  @ViewChild('timeBox') timeBox: DxTextBoxComponent;
  @ViewChild('popOverCreationColumn') popOverCreationColumn: DxPopoverComponent;

  @Input() agendaDocument: IDocument = null;
  @Input() agendaData: IAgenda[] = null;
  @Input() startDateChanged: string = null;
  @Input() formData: Record<string, unknown> = {};
  @Input() editMode: TEditMode = 'ReadOnly';
  @Output() agendaResult = new EventEmitter<IEventEmitter<IAgenda[]>>();
  @Input() fieldContainer: IDxFormItemTemplate;

  referencedDocuments: IDocument[] = [];
  selectedAItem: IAgenda;
  dragTo: 'inside' | 'outside';

  agendaItems: IAgenda[] = [];
  selectedIndex?: number = null;
  selectedAgendaItemDocument: IDocument = null;

  popupSubmissionsListVisible = false;
  popupFreeTopsListVisible = false;
  popupchildrenTopsListVisible = false;
  popupOldTopsListVisible = false;
  popupSubmissionsListTitle = 'Bitte wählen Sie die gewünschte Vorlage';
  popupFreeTopsListTitle = 'Bitte wählen Sie die gewünschten Tops';
  popupChildrenTopsListTitle = 'Bitte wählen Sie die TOPs aus, die auf der Agenda erscheinen sollen';
  expanded = false;

  submissionsList: ISubmissionTop[] = null;
  freeTopsList: IFreeTop[] = null;
  childrenTopsList: IFreeTop[] = null;
  oldTopsList: IFreeTop[] = null;
  filterSubmissionValue = ['state', 'contains', 'sitzung'];

  agendaItemActionsList = [] as IActionStandard[];
  agendaItemActionTarget: EventTarget;
  agendaItemActionTargetItem: IAgenda;
  agendaItemActionsVisible: boolean;
  agendaStartDate: Date;
  agendaTotalTime = '';
  agendaEndTime: string;
  disableDraggeble = false;
  indexAgendaItemCreate: number;
  timeSliderSettings: ITimeSliderSettings[] = [{ min: 0, max: 300, step: 5, value: null }];
  timeSliderVisible = false;
  actionSheetTarget: EventTarget;
  hasUpdatePermission = true;

  workflowStatus: string;
  addTopButtonText = 'Neuen TOP erstellen';
  addButtons: IAgendaCreatemAction[] = [
    { value: 1, name: 'Vorlage hinzufügen', action: 'newSubmission', icon: 'plus' },
    { value: 2, name: 'Pause hinzufügen', action: 'newBreak', icon: 'coffee' },
    { value: 3, name: 'Freien TOP hinzufügen', action: 'newFreeTop', icon: 'plus' },
    { value: 3, name: 'TOP aus Sitzung übernehmen', action: 'newOldTop', icon: 'plus' },
    { value: 0, label: '', action: 'none', template: splitterTpl, disabled: true },
    { value: 4, name: 'Fehlende TOPs neu referenzieren', action: 'addTop', icon: 'material-icons sync' }
  ];
  topTypes = [
    { id: 0, name: ' ', value: ' ' },
    { id: 1, name: 'Kenntnisnahme', value: 'K' },
    { id: 2, name: 'Aufgabe', value: 'A' },
    { id: 3, name: 'Vereinbarung', value: 'V' },
    { id: 4, name: 'Beschlussantrag', value: 'B' }
  ];

  popOverText: string;
  popoverTarget: HTMLElement;

  #destroyable$ = new Subject<void>();
  readonly #loadPanelMessage = 'Verfügbare Vorgänge werden geladen...';

  constructor(
    private cdRef: ChangeDetectorRef,
    private documentService: DocumentService,
    private templateService: TemplateService,
    private approvalService: ApprovalService,
    private loadPanelService: LoadPanelService,
    private logger: LoggerService,
    private nameDataPipe: GetNameDataPipe,
    private numberGroupService: NumberGroupsService
  ) {}

  ngOnInit(): void {
    this.submissionsList = null;
    this.freeTopsList = null;
    this.childrenTopsList = null;
    this.oldTopsList = null;

    this.logger.info('=== agenda-include-component started.');
    // Aktionen für AgendaItems registrieren ...
    // {text: string, typ?: string, icon?: string; disabled: boolean, action: any}
    this.agendaItemActionsList = [
      { text: 'Protokoll erstellen', type: 'standard', disabled: false, action: 'protocol' },
      { text: 'Beschluss erstellen', type: 'standard', disabled: false, action: 'decision' },
      { text: 'Aufgabe erstellen', type: 'standard', disabled: false, action: 'task' }
    ];
    this.agendaItemActionsVisible = false;
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fetchAgendaStartDate();

    if (changes?.agendaDocument?.currentValue) {
      if (this.agendaDocument?.references?.length) {
        this.loadPanelService.show();
        this.getData()
          .pipe(takeUntil(this.#destroyable$))
          .subscribe({
            next: () => {
              this.loadPanelService.hide();
            },
            complete: () => {
              this.loadPanelService.hide();
            },
            error: () => {
              this.loadPanelService.hide();
            }
          });
      }
    }
    if (changes?.startDateChanged?.currentValue && !changes?.startDateChanged?.firstChange) {
      void this.updateAgendaItemsFields();
    }
  }

  getData(): Observable<void> {
    return this.documentService.getReferencedDocuments(this.agendaDocument).pipe(
      map(refDocuments => {
        this.referencedDocuments = refDocuments;
        this.fetchAgendaStartDate();
        this.agendaItems = this.getAgendaItemsFromField();
        this.getWorkflowStatus();
      }),
      takeUntil(this.#destroyable$)
    );
  }

  formatSliderTime = (value: string): string => `${value} Min.`;

  getWorkflowStatus(): void {
    if (this.agendaDocument) {
      const workflowDocument = this.agendaDocument.workflowDocuments?.find(
        x => !x.tags.length || x.tags.includes('app:document-type:document-workflow')
      );
      this.workflowStatus = this.documentService.getWorkflowStatus(workflowDocument)?.value as string;
    }
  }

  /**
   * map the agendaItem from references and put the value in agendaItem field on
   *
   * @param agendaItem
   */
  setAItemFieldDetailsFromReferences(agendaItem: IAgenda): void {
    if (agendaItem.document && agendaItem.document.references) {
      const protocolDocument = this.documentService.getChildDocumentWithTag(
        agendaItem.document,
        'app:document-type:protocol'
      );
      agendaItem.approved = this.isAgendaItemApproved(agendaItem.document);
      agendaItem.taskCount = this.documentService.countChildOfTag(agendaItem.document, 'app:document-type:task') || 0;
      agendaItem.protocolId = protocolDocument ? protocolDocument.referencedDocumentId : null;
    }
  }

  /**
   *  updating the references, just for display purpose, as the server ignores any reference content
   *  ther server allows reference changes only via API calls makeResponse/removeReference
   *
   * @param item
   * @param references
   */
  updateAgendaItemsReferences(item: IDocument, references: IReference[]): void {
    if (this.agendaItems.length > 0) {
      const index = this.agendaItems.findIndex(aItem => aItem.documentId === item.id);
      if (index > -1) {
        this.agendaItems[index].document.references = references;
      } else {
        this.agendaItems.forEach(aItem => {
          if (aItem.children && aItem.children.length) {
            const childIndex = aItem.children.findIndex(childItem => childItem.documentId === item.id);
            if (childIndex > -1) {
              aItem.children[childIndex].document.references = references;
            }
          }
        });
      }
    }
  }

  updateAgendaItemDocument(itemDocument: IDocument): void {
    if (this.agendaItems.length > 0) {
      const index = this.agendaItems.findIndex(aItem => aItem.documentId === itemDocument.id);
      if (index > -1) {
        this.agendaItems[index].document = itemDocument;
      } else {
        this.agendaItems.forEach(aItem => {
          if (aItem.children && aItem.children.length) {
            const childIndex = aItem.children.findIndex(childItem => childItem.documentId === itemDocument.id);
            if (childIndex > -1) {
              aItem.children[childIndex].document = itemDocument;
            }
          }
        });
      }
    }
  }

  /**
   * TOP-Metadaten in benutzerdefiniertem Feldder TO verwalten
   */
  async updateAgendaItemsFields(): Promise<void> {
    let parentIndex = 1;
    this.agendaItems.forEach(ai => {
      ai.index = parentIndex;
      parentIndex += 1;
      if (ai.children && ai.children.length) {
        let childIndex = 1;
        ai.children.forEach(ch => {
          ch.index = childIndex;
          childIndex += 1;
        });
      }
    });
    const aItems: IAgenda[] = [];

    if (this.agendaItems.length > 0) {
      let themaIndex = 1;
      let position = 1;
      for (let index = 0; index < this.agendaItems.length; index++) {
        const aItem = this.agendaItems[index];
        const document =
          aItem.document || (await lastValueFrom(this.documentService.getDocumentById(aItem.documentId)));
        aItem.document = document;
        const protocolDocument = this.documentService.getChildDocumentWithTag(document, 'app:document-type:protocol');

        const item = {
          index: aItem.index || null,
          position,
          document,
          approved: this.isAgendaItemApproved(document),
          type: aItem.type || 0,
          parentDocId: aItem.parentDocId || null,
          parentIndex: aItem.parentIndex || null,
          documentId: aItem.documentId,
          speakers: aItem.speakers || null,
          children: aItem.children || [],
          taskCount: this.documentService.countChildOfTag(aItem.document, 'app:document-type:task') || 0,
          protocolId: protocolDocument ? protocolDocument.referencedDocumentId : null,
          startTime: aItem.startTime || null,
          endTime: aItem.endTime || null,
          totalEndTime: aItem.totalEndTime || null,
          timeInterval: typeof aItem.timeInterval === 'number' ? aItem.timeInterval : null
        } as IAgenda;
        // calculate time
        if (this.documentService.isPause(document)) {
          item.index = null;
        } else {
          item.index = themaIndex;
          themaIndex++;
        }
        position++;

        if (item.children && item.children.length) {
          let themaIndexChild = 1;
          let positionChild = 0;
          for (const child of item.children) {
            if (this.documentService.isPause(child.document)) {
              child.index = null;
            } else {
              child.document =
                child.document || (await lastValueFrom(this.documentService.getDocumentById(child.documentId)));

              child.index = themaIndexChild;
              themaIndexChild++;
            }

            positionChild++;
            child.position = positionChild;
            child.parentIndex = item.index;
            this.setAItemFieldDetailsFromReferences(child);
          }
        }
        this.updateAgendaItemTime(item, aItems[index - 1]);
        this.setAItemFieldDetailsFromReferences(item);
        aItems.push(item);
      }
      this.updateAgendaItemsTime(aItems);
    }

    // update the agendaItems after maping the agenda object
    this.setAgendaTotalTime(aItems);
    this.agendaItems = aItems;
    this.agendaResult.emit({
      command: 'agendaItems',
      object: this.agendaItems?.length ? this.agendaItems : null
    });
  }

  setSpeakers(aItem: IAgenda): void {
    if (this.editMode !== 'Edit') {
      return;
    }
    this.nameSelector.show({
      title: 'Bitte wählen Sie den gewünschten Mitarbeiter aus:',
      selectedRecords: aItem.speakers,
      fullScreen: false,
      selectType: 'Personen',
      selectionMode: 'multiple',
      filteringEnabled: true
    });
  }

  nameSelectorResult = (result: IUser[]): void => {
    const speakers: INameSelectorDialogRecord[] = [];
    const userIds = result.reduce((pv, user) => (pv.push(user.id), pv), [] as string[]);
    userIds.forEach(id => {
      speakers.push({ targetId: id, type: 'user' });
    });

    this.selectedAItem.speakers = speakers;
    this.selectedAItem.document.fields['--speakers'] = {
      value: speakers
    };

    this.documentService.save(this.selectedAItem.document);

    const speakersFromItems: INameSelectorDialogRecord[] = this.agendaItems
      .map(item => item.speakers)
      .reduce((pv, item) => (pv && item && pv.push(...item), pv), [] as INameSelectorDialogRecord[]);
    const speakersFromChildItems: INameSelectorDialogRecord[] = this.agendaItems
      .map(item => item.children)
      .reduce((pv, item) => (pv && item && pv.push(...item), pv), [] as IAgenda[])
      .map(item => item.speakers)
      .reduce((pv, item) => (pv && item && pv.push(...item), pv), [] as INameSelectorDialogRecord[]);
    const filteredSpeakers: INameSelectorDialogRecord[] = [];
    speakersFromItems.concat(speakersFromChildItems).forEach(s => {
      if (!filteredSpeakers.find(f => f === s)) {
        filteredSpeakers.push(s);
      }
    });

    this.agendaDocument.fields['--allSpeakers'] = { value: filteredSpeakers };

    this.agendaResult.emit({
      command: 'agendaItems',
      object: this.agendaItems
    });
  };

  selectAItem(e: Event, aItem: IAgenda): void {
    e.stopPropagation();
    this.selectedAItem = aItem;
  }

  getAgendaItemsFromField(): IAgenda[] {
    if (!this.agendaDocument) {
      this.logger.warn('getAgendaItemsFromField(): kein agendaDocument verfügbar.');
      return [];
    }
    this.hasUpdatePermission = !this.fieldContainer?.editorOptions?.readOnly;
    if (this.agendaDocument.fields[FIELDNAME_AGENDALIST]?.effectivePermissions) {
      this.hasUpdatePermission =
        this.agendaDocument.fields[FIELDNAME_AGENDALIST].effectivePermissions.includes('update') &&
        !this.fieldContainer?.editorOptions?.readOnly;
    }

    const aItems: IAgenda[] = this.agendaData || [];

    this.logger.debug('AgendaItems-Feld aus der TO: {@m} ', aItems);

    const filteredReferencedDocuments = this.referencedDocuments.filter(
      ref => !ref.tags.some(tag => tag === 'app:document-type:meetingMinutes')
    );
    if (aItems && filteredReferencedDocuments && filteredReferencedDocuments.length) {
      const refDocumentMap = filteredReferencedDocuments.reduce(
        (pv, item) => ((pv[item.id] = item), pv),
        {} as { [documentId: string]: IDocument }
      );

      // this.logger.debug('geladene AgendaItems:');
      /*       let invalidAgendaItems: IAgenda[] = [];
      if (aItems && aItems.length > 0) {
        aItems.forEach(aItem => (aItem.document = refDocumentMap[aItem.documentId]));
        invalidAgendaItems = aItems.filter(aItem => !aItem.document);
      }

      if (invalidAgendaItems.length > 0) {
        invalidAgendaItems.forEach(inv => {
          const i = aItems.findIndex(item => item.documentId === inv.documentId);
          aItems.splice(i, 1);
        });
      } */
      /*       const validItems: IAgenda[] = [];
      aItems.forEach((item, index) => {
        const existedItem = filteredReferencedDocuments.find(f => f.id === item.documentId);
        if (existedItem) {
          validItems.push(Factory.copy(item));
          validItems[index].children = [];
        }
        if (item.children?.length) {
          item.children.forEach(child => {
            const existedChildItem = filteredReferencedDocuments.find(f => f.id === child.documentId);
            if (existedChildItem) {
              validItems[index].children.push(child);
            }
          });
        }
      }); */

      const filterdItems: IAgenda[] = aItems.reduce((output, item) => {
        if (!item.document) {
          output.push(item);
        } else {
          if (!item.document.tags.some(tag => tag === 'app:document-type:meetingMinutes')) {
            output.push(item);
          }
        }
        return output;
      }, [] as IAgenda[]);

      this.updateAgendaItemsTime(filterdItems);
      filterdItems.forEach((aItem: IAgenda) => {
        aItem.document = refDocumentMap[aItem.documentId];
        aItem.attachment = this.documentService.hasAttachment(aItem.document);
        this.setAItemFieldDetailsFromReferences(aItem);
        if (aItem.children && aItem.children.length) {
          aItem.children.forEach((child: IAgenda) => {
            child.document = refDocumentMap[child.documentId];
            this.setAItemFieldDetailsFromReferences(child);
          });
        }
      });
      this.setAgendaTotalTime(filterdItems);
      return filterdItems;
    } else {
      return [];
    }
  }

  /**
   *  update the calculation of time for a given agenda item
   *
   * @param toItem set time for the item
   * @param fromItem set time from obve item
   */
  updateAgendaItemTime(toItem: IAgenda, fromItem: IAgenda): void {
    const timeInterval = toItem.timeInterval || toItem.timeInterval === 0 ? toItem.timeInterval : 15;
    const startTime = (fromItem && fromItem.endTime) || this.agendaStartDate;
    const endTime = this.addMinutes(startTime, timeInterval) || this.addMinutes(this.agendaStartDate, timeInterval);
    toItem.startTime = startTime;
    toItem.endTime = endTime;
    toItem.totalEndTime = endTime;
    toItem.timeInterval = toItem.timeInterval || this.calculateTimeInMinutes(startTime, endTime);
  }

  updateAgendaItemsTime(items: IAgenda[]): void {
    items.forEach((aItem: IAgenda, parentIndex) => {
      aItem.attachment = this.documentService.hasAttachment(aItem.document);
      this.updateAgendaItemTime(aItem, items[parentIndex - 1]);
      this.setAItemFieldDetailsFromReferences(aItem);
      if (aItem.children && aItem.children.length) {
        if (items[parentIndex - 1]) {
          aItem.endTime = items[parentIndex - 1].endTime;
          aItem.startTime = items[parentIndex - 1].endTime;
          aItem.totalEndTime = items[parentIndex - 1].endTime;
        } else {
          aItem.endTime = this.agendaStartDate;
          aItem.startTime = this.agendaStartDate;
          aItem.totalEndTime = this.agendaStartDate;
        }
        aItem.timeInterval = 0;
        aItem.children.forEach((child: IAgenda, childIndex) => {
          const timeObject = aItem.children[childIndex - 1] || aItem;
          this.setAItemFieldDetailsFromReferences(child);
          this.updateAgendaItemTime(child, timeObject);
        });
        aItem.startTime = aItem.children[0].startTime;
        aItem.endTime = aItem.children[aItem.children.length - 1].endTime;
        aItem.timeInterval = this.calculateTimeInMinutes(aItem.startTime, aItem.endTime);
      }
    });
  }

  /**
   * Returs the total time
   *
   * @param startTime
   * @param endTime
   */
  calculateTimeInHours(startTime: Date, endTime: Date): string {
    const duration = endTime.valueOf() - startTime.valueOf();
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const hh = hours < 10 ? `0${hours}` : hours;
    const min = minutes < 10 ? `0${minutes}` : minutes;
    return `${hh}:${min}`;
  }

  /**
   * Returs the total time in minuts
   *
   * @param startTime
   * @param endTime
   */
  calculateTimeInMinutes(startTime: Date, endTime: Date): number {
    const msec = endTime.valueOf() - startTime.valueOf();
    return Math.floor(msec / 60000);
  }

  /**
   * calculating the total agenda time
   *
   * @param agendaItems
   */
  setAgendaTotalTime(agendaItems: IAgenda[]): void {
    if (agendaItems && agendaItems.length) {
      const startTime: Date = new Date(agendaItems[0].startTime);
      const endTime: Date = new Date(agendaItems[agendaItems.length - 1].endTime);
      this.agendaEndTime = endTime.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit'
      });
      this.agendaTotalTime = this.calculateTimeInHours(startTime, endTime);
      // Run change detection explicitly after the change
      this.cdRef.detectChanges();
    }
  }

  /**
   * adding time in minutes
   *
   * @param date
   * @param minutes
   */
  addMinutes(date: Date, minutes: number): Date {
    if (date) {
      const formatedDate = Date.parse(date.toString());
      return new Date(formatedDate + minutes * 60000);
    }
    return null;
  }

  /**
   * sets a agenda time on slider move
   *
   * @param aItem
   */
  setAgendaTime(aItem: IAgenda): void {
    const timeInterval: number = aItem.timeInterval;
    aItem.endTime = this.addMinutes(aItem.startTime, timeInterval);

    this.setAgendaItemTime(aItem);
    void this.updateAgendaItemsFields();
  }

  /**
   * disable the draggability of the item
   */
  disableDragItem(): void {
    this.disableDraggeble = true;
  }

  /**
   * enable the draggability of the element
   */
  enableDragItem(): void {
    this.disableDraggeble = false;
  }

  /**
   * updating the agenda time on every with slder move
   *
   * @param currentAItem
   */
  setAgendaItemTime(currentAItem: IAgenda): void {
    const firstLevelItemIndex = this.agendaItems.findIndex(x => x === currentAItem);
    if (currentAItem.children && currentAItem.children.length) {
      // if current agenda have child
      const firstChild = currentAItem.children[0];
      firstChild.startTime = currentAItem.endTime;
      firstChild.endTime = this.addMinutes(currentAItem.endTime, firstChild.timeInterval);
      this.setAgendaItemTime(firstChild);
    } else if (firstLevelItemIndex > -1) {
      // if current agenda has no children and also at first level
      const nextAItem = this.agendaItems[firstLevelItemIndex + 1];
      if (nextAItem) {
        nextAItem.startTime = currentAItem.endTime;
        nextAItem.endTime = this.addMinutes(currentAItem.endTime, nextAItem.timeInterval);
        this.setAgendaItemTime(nextAItem);
      }
    } else if (firstLevelItemIndex < 0) {
      // if current agenda has no children but also at 2nd level
      const parentAItem = this.agendaItems.find(x => x.documentId === currentAItem.parentDocId);
      const currentChildIndex = parentAItem.children.findIndex(x => x === currentAItem);
      const nextChild = parentAItem.children[currentChildIndex + 1];
      const lastChild = parentAItem.children[parentAItem.children.length - 1];
      if (lastChild.documentId === currentAItem.documentId) {
        const parentAItemIndex = this.agendaItems.findIndex(x => x.documentId === currentAItem.parentDocId);
        const nextAItem = this.agendaItems[parentAItemIndex + 1];
        if (!nextAItem) {
          return;
        }
        nextAItem.startTime = lastChild.endTime;
        nextAItem.endTime = this.addMinutes(lastChild.endTime, nextAItem.timeInterval);
        this.setAgendaItemTime(nextAItem);
      } else if (nextChild) {
        nextChild.startTime = currentAItem.endTime;
        nextChild.endTime = this.addMinutes(currentAItem.endTime, nextChild.timeInterval);
        this.setAgendaItemTime(nextChild);
      }
    }
  }

  /**
   * Anzeigen der verfügbaren Vorlagen zum Hinzufügen zur Agenda
   */
  showSubmissionsList(index?: number): void {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.logger.debug('--- agenda-include.component: showSubmissionsList() called');
    this.indexAgendaItemCreate = index + 1;
    // alle zuordnenbaren Vorlagen einlesen
    if (this.submissionsList === null) {
      this.refreshSubmissionData();
    } else {
      // -- herausfiltern der Vorlagen, die bereits auf der Liste sind
      this.popupSubmissionsListVisible = true;
    }
  }

  showFreeTopsList(index?: number): void {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.loadPanelService.show(this.#loadPanelMessage);
    this.logger.debug('--- agenda-include.component: showFreeTopsList() called');
    this.indexAgendaItemCreate = index + 1;
    // alle zuordnenbaren Vorlagen einlesen
    const search: ISearch = {
      query: '-tags:"app:document-type:decision"',
      requiredTag: 'app:document-type:top',
      resultConfiguration: {
        returnFieldLists: false,
        selectedFields: selectedFieldsSubmission
      }
    };

    this.documentService
      .searchDocuments(search)
      .pipe(
        map(r => r.documents),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: documents => {
          void (async () => {
            const topList: IFreeTop[] = [];
            for (const document of documents) {
              const user = create();
              user.id = document.creation.userId;
              const _userData = await lastValueFrom(this.nameDataPipe.transform(user, document.creation.logonUserId));
              const top: IFreeTop = {
                id: document.id,
                subject: document.fields.subject?.value as string,
                document,
                creatorname: _userData.name,
                creatordata: _userData.avatarName,
                attachment: this.documentService.hasAttachment(document)
              };

              const hasProtocol = this.documentService.hasChildOfTag(document, 'app:document-type:protocol');
              const hasDecision = this.isAgendaItemApproved(document);
              const hasParent = this.documentService.hasChildOfTag(document, 'app:document-type:agenda');
              if (!hasProtocol && !hasDecision && !hasParent) {
                topList.push(top);
              }
            }
            this.freeTopsList = topList;
            this.popupFreeTopsListVisible = true;
            this.loadPanelService.hide();
          })();
        },
        complete: () => {
          this.loadPanelService.hide();
        },
        error: (error: string) => {
          NotifyService.global.error(error);
          this.loadPanelService.hide();
        }
      });
  }

  showChildrenTopsList(index?: number): void {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.loadPanelService.show(this.#loadPanelMessage);
    this.indexAgendaItemCreate = index + 1;
    const excludeDocumentIds: string[] = [];
    // Alle agendaItems bei der Suche filtern
    this.agendaItems.forEach(parent => {
      if (!excludeDocumentIds.find(id => parent.documentId === id)) {
        excludeDocumentIds.push(parent.documentId);
      }
      if (parent.children?.length) {
        const parentIndex = this.agendaItems.findIndex(i => i.documentId === parent.documentId);
        if (parentIndex > -1) {
          parent.children.forEach(child => {
            if (!excludeDocumentIds.find(id => child.documentId === id)) {
              excludeDocumentIds.push(child.documentId);
            }
          });
        }
      }
    });
    const search: IDocumentsSearch = {
      tag: '',
      excludeTags: ['app:document-type:meetingMinutes'],
      includeParentIds: [this.agendaDocument.id],
      documentFields: selectedFieldsSubmission,
      isIncludeLists: false,
      excludeDocumentIds
    };

    this.documentService
      .documentsSearch(search)
      .pipe(
        map(r => r.documents),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: documents => {
          void (async () => {
            const cildrenList: IFreeTop[] = [];
            for (const document of documents) {
              const user = create();
              user.id = document.creation.userId;
              const _userData = await lastValueFrom(this.nameDataPipe.transform(user, document.creation.logonUserId));
              const top: IFreeTop = {
                id: document.id,
                subject: document.fields.subject?.value as string,
                document,
                creatorname: _userData.name,
                creatordata: _userData.avatarName,
                attachment: this.documentService.hasAttachment(document)
              };

              cildrenList.push(top);
            }
            this.childrenTopsList = cildrenList;
            this.popupchildrenTopsListVisible = true;
            this.loadPanelService.hide();
          })();
        },
        complete: () => {
          this.loadPanelService.hide();
        },
        error: (error: string) => {
          NotifyService.global.error(error);
          this.loadPanelService.hide();
        }
      });
  }

  showOldTopsList(index?: number): void {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.logger.debug('--- agenda-include.component: showOldTopsList() called');
    this.indexAgendaItemCreate = index + 1;
    // alle zuordnenbaren Vorlagen einlesen
    if (this.oldTopsList === null) {
      this.loadPanelService.show(this.#loadPanelMessage);

      const search: ISearch = {
        query: null,
        requiredTag: 'app:document-type:top',
        resultConfiguration: {
          returnFieldLists: false,
          selectedFields: selectedFieldsSubmission
        }
      };
      this.documentService
        .searchDocuments(search)
        .pipe(
          switchMap(data => forkJoin([this.getParentsFromDocuments(data.documents), of(data)])),
          map(data => {
            const documents = data[1].documents;
            const parents = data[0];
            return documents.map(doc => {
              doc.references = doc.references.map(r => {
                r.document = parents.find(p => p.id === r.referencedDocumentId);
                return r;
              });
              return doc;
            });
          }),
          takeUntil(this.#destroyable$)
        )
        .subscribe({
          next: documents => {
            void (async () => {
              const topList: IFreeTop[] = [];
              for (const document of documents) {
                const user = create();
                user.id = document.creation.userId;
                const _userData = await lastValueFrom(this.nameDataPipe.transform(user, document.creation.logonUserId));

                const hasParent = this.documentService.hasChildOfTag(document, 'app:document-type:agenda');
                if (hasParent) {
                  const parentDocument = document.references.find(
                    reference => reference.tags.some(tag => tag === 'app:document-type:agenda') === true
                  );
                  const subject = parentDocument?.document?.fields?.subject?.value
                    ? `(${parentDocument?.document?.fields?.subject?.value as string})`
                    : '';
                  const topSubject = document?.fields?.subject?.value
                    ? `(${document?.fields?.subject?.value as string})`
                    : '';
                  const parentCreationDate = parentDocument?.document?.creation?.timestamp
                    ? new Date(parentDocument?.document?.creation?.timestamp).toLocaleDateString().slice(0, 10)
                    : '';
                  const parentCreationSubject = parentCreationDate + subject;
                  const top: IFreeTop = {
                    id: document.id,
                    subject: topSubject,
                    document,
                    creatorname: _userData.name,
                    creatordata: _userData.avatarName,
                    attachment: this.documentService.hasAttachment(document),
                    agendaSubject: parentCreationSubject,
                    agendaType: parentDocument?.document?.template?.caption || ''
                  };
                  if (parentDocument?.document?.id !== this.agendaDocument.id) {
                    topList.push(top);
                  }
                }
              }
              this.oldTopsList = topList;
              this.popupOldTopsListVisible = true;
              this.loadPanelService.hide();
            })();
          },
          complete: () => {
            this.loadPanelService.hide();
          },
          error: (error: string) => {
            NotifyService.global.error(error);
            this.loadPanelService.hide();
          }
        });
    } else {
      // -- herausfiltern der Vorlagen, die bereits auf der Liste sind
      this.popupOldTopsListVisible = true;
    }
  }

  /**
   * Reagieren auf das Cancel-Schließén der Auswahlliste
   */
  popupSubmissionsListCancel = (): void => {
    this.popupSubmissionsListVisible = false;
  };

  popupFreeTopsListCancel = (): Promise<void> & JQueryPromise<void> => {
    this.popupFreeTopsListVisible = false;
    return this.freeTopSelectionGrid.instance.deselectAll();
  };
  popupChildrenTopsListCancel = (): Promise<void> & JQueryPromise<void> => {
    this.popupchildrenTopsListVisible = false;
    return this.childrenTopSelectionGrid.instance.deselectAll();
  };

  popupOldTopsListCancel = (): Promise<void> & JQueryPromise<void> => {
    this.popupOldTopsListVisible = false;
    this.expanded = false;
    return this.freeTopSelectionGrid.instance.deselectAll();
  };

  /**
   * add toggle button on toolbar in Data Grid
   */
  onToolbarPreparingOldTops(e: ToolbarPreparingEvent): void {
    e.toolbarOptions.items.unshift({
      location: 'before',
      widget: 'dxButton',
      options: {
        elementAttr: { id: 'collapseBtn' },
        icon: 'expand',
        height: '35px',
        stylingMode: 'outlined',
        onClick: this.onCollapseAll
      }
    });
  }

  /**
   * toggel collapse group in Data Grid
   */
  onCollapseAll = (e: ClickEvent): void => {
    this.expanded = !this.expanded;
    e.component.option({
      icon: this.expanded ? 'collapse' : 'expand'
    });
  };

  /**
   * check Data Grid grouped or not & hide/show collapse button
   */
  onContentReadyOldTops(e: ContentReadyEvent): void {
    const isGrouped = !!e.component.columnOption('groupIndex:0');
    const element: HTMLElement = e.element.querySelector('#collapseBtn');
    element.hidden = !isGrouped;
  }

  // Reagieren auf das Ok-Schließen der Auswahlliste
  popupSubmissionsListOk = (): void => {
    this.popupSubmissionsListVisible = false;
    this.loadPanelService.show();
    const submissionGrid = this.submissionSelectionGrid.instance;
    const selectedItems: ISubmissionTop[] = submissionGrid.getSelectedRowsData();
    const selectedDocuments = selectedItems.map(s => s.document);

    const filteredItems: IDocument[] = [];
    selectedDocuments.forEach(item => {
      if (this.agendaItems.findIndex(ai => ai.documentId === item.id) === -1) {
        filteredItems.push(item);
        this.addEntry(item);
      } else {
        NotifyService.global.info(`das Dokument ${item?.fields?.subject.value as string} ist bereits hinzufügt!`);
      }
    });
    if (filteredItems.length > 0) {
      lastValueFrom(this.setParents(filteredItems)).then(res => {
        this.loadPanelService.hide();
        if (res) {
          void this.updateAgendaItemsFields();
          submissionGrid.clearSelection();
          NotifyService.global.success('Die Vorlagen wurden in der Tagesordnung hinzufügt');
        }
      }, NotifyService.component.error);
    } else {
      submissionGrid.clearSelection();
      this.loadPanelService.hide();
    }
  };

  setParents(selectedItems: IDocument[]): Observable<IDocument[]> {
    const array = selectedItems.map(item => this.documentService.setReference(item.id, this.agendaDocument.id));
    return concat(...array).pipe(map(res => ([] as IDocument[]).concat(...(Object.values(res) as IDocument[]))));
  }

  popupFreeTopsListOk = (): void => {
    this.popupFreeTopsListVisible = false;
    void this.setReferenceFreeTops();
  };
  popupChildrenTopsListOk = (): void => {
    this.popupchildrenTopsListVisible = false;
    const childrenTopGrid = this.childrenTopSelectionGrid.instance;
    const selectedItems: IFreeTop[] = childrenTopGrid.getSelectedRowsData();
    const selectedDocuments = selectedItems.map(s => s.document);
    for (const item of selectedDocuments) {
      this.addEntry(item);
    }
    void this.updateAgendaItemsFields();
    this.loadPanelService.hide();
    void childrenTopGrid.deselectAll();
  };
  popupOldTopsListOk = (): void => {
    this.popupOldTopsListVisible = false;
    void this.setReferenceFreeTops();
  };

  async setReferenceFreeTops(iterator?: IterableIterator<IDocument>): Promise<void> {
    const freeTopGrid = this.freeTopSelectionGrid.instance;
    const selectedItems: IFreeTop[] = freeTopGrid.getSelectedRowsData();
    const selectedDocuments = selectedItems.map(s => s.document);

    if (!iterator) {
      iterator = selectedDocuments[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      void this.updateAgendaItemsFields();
      this.loadPanelService.hide();
      await freeTopGrid.deselectAll();
      return;
    }

    try {
      const document = await lastValueFrom(this.documentService.getDocumentById((result.value as IDocument).id));
      const copyDocument = this.documentService.copyToCreate(document);
      const newDoc = await lastValueFrom(this.documentService.save(copyDocument));
      this.documentService
        .setReference(newDoc.id, this.agendaDocument.id)
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          next: (changedDocument): void => {
            this.addEntry(changedDocument);
            this.loadPanelService.hide();
          },
          error: (error: string): void => {
            this.loadPanelService.hide();
            NotifyService.global.error(error);
          },
          complete: (): void => {
            void this.setReferenceFreeTops(iterator);
          }
        });
    } catch (error) {
      this.loadPanelService.hide();
    }
  }

  popupFreeTopDelete = (): void => {
    void confirm('Möchten Sie diesen freien TOP wirklich löschen?', 'Freien TOP löschen').then(dialogResult => {
      if (dialogResult) {
        void this.deleteFreeTopsConfirmed();
      }
    });
  };
  popupChildrenTopDeleteReference = (): void => {
    void this.removeReferencesTops();
  };

  onSubmissionListToolbarPreparing(e: ToolbarPreparingEvent): void {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          elementAttr: { id: 'collapseBtn' },
          icon: 'refresh',
          hint: 'Liste neu laden',
          height: '35px',
          stylingMode: 'outlined',
          onClick: this.refreshSubmissionData
        }
      },
      {
        location: 'after',
        widget: 'dxDropDownButton',
        options: {
          useSelectMode: true,
          selectedItemKey: 1,
          width: '250',
          displayExpr: 'name',
          keyExpr: 'value',
          items: [
            { value: 1, name: 'Zur Sitzungsvorbereitung', icon: 'selectall' },
            { value: 2, name: 'alle Vorlagen', icon: 'unselectall' }
          ],
          onItemClick: this.onDropDownFiltering
        }
      }
    );
  }

  refreshSubmissionData = (): void => {
    this.loadPanelService.show(this.#loadPanelMessage);
    this.submissionsList = [];
    // const tags = this.fieldContainer.editorOptions.childrenTags;
    const submissionTemplateIds = this.fieldContainer.editorOptions?.agendaAllowedTemplates?.templateSubmissionIds;
    let query = '-tags:"app:document-type:decision"';

    if (submissionTemplateIds?.length) {
      const ids = submissionTemplateIds.map(l => ` template.id:${JSON.stringify(l)}`).join(' || ');
      query += ` +(${ids})`;
    } else {
      query += ` +tags:"app:document-type:submission"`;
    }
    const search: ISearch = {
      query,
      resultConfiguration: {
        returnFieldLists: false,
        selectedFields: selectedFieldsSubmission
      }
    };

    this.documentService
      .searchDocuments(search)
      .pipe(
        switchMap(data => forkJoin([this.getParentsFromDocuments(data.documents), of(data)])),
        map(data => {
          const documents = data[1].documents;
          const parents = data[0];
          return documents.map(doc => {
            doc.references = doc.references.map(r => {
              r.document = parents.find(p => p.id === r.referencedDocumentId);
              return r;
            });
            return doc;
          });
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: documents => {
          void (async () => {
            const data: ISubmissionTop[] = [];
            for (const document of documents) {
              const user = create();
              user.id = document.creation.userId;
              const _userData = await lastValueFrom(this.nameDataPipe.transform(user, document.creation.logonUserId));
              const _speakers = await lastValueFrom(
                this.nameDataPipe.transform(document.fields?.speakers?.value as string)
              );
              const _department = document.fields.department
                ? (document.fields.department.value as string)
                : _userData.department;
              const submission: ISubmissionTop = {
                id: document.id,
                document,
                subject: document.fields?.subject?.value as string,
                type: document.template.caption,
                state:
                  document.documentWorkflowDocument.currentStatusLabel ||
                  this.documentService.fetchWorkflowStatus(document.workflowDocuments, 'label'),
                creatorname: _userData.name,
                creatordata: _userData.avatarName,
                speakers: _speakers.avatarName,
                department: _department,
                parentDocuments: []
              };
              if (document.references.length) {
                const parents = document.references
                  .filter(p => p.tags.includes(siamConst.parentTag) && p.document)
                  .map(ref => ref.document);
                const tagType = 'app:document-icon:';

                for (const parent of parents) {
                  if (parent.tags.some(t => t === 'app:document-type:agenda')) {
                    const parentDocument: IParentDocument = {};
                    if (parent?.fields?.subject) {
                      parentDocument.parentDocumentSubject = parent?.fields?.subject?.value as string;
                    }

                    parentDocument.parentDocumentType = (parent?.template && parent.template.caption) || null;
                    parentDocument.parentDocumentIcon =
                      parent?.tags
                        .filter(tag => tag.startsWith(tagType))
                        .map(tag => tag.split(new RegExp(`^${tagType}`))[1])[0] || null;
                    submission.parentDocuments.push(parentDocument);
                  }
                }
              }
              data.push(submission);
            }

            this.submissionsList = data;
            this.popupSubmissionsListVisible = true;
            this.loadPanelService.hide();
          })();
        },
        complete: () => {
          this.loadPanelService.hide();
        },
        error: (error: string) => {
          NotifyService.global.error(error);
          this.loadPanelService.hide();
        }
      });
  };

  onDropDownFiltering = (e: ItemClickEvent): void => {
    if ((e.itemData as IDxDropDownItem<number>).value === 1) {
      void this.submissionSelectionGrid.instance.refresh().then(() => {
        this.filterSubmissionValue = ['state', 'contains', 'sitzung'];
      });
    } else {
      this.submissionSelectionGrid.instance.clearFilter();
    }
  };

  // ==========================================================================================================
  // === AgendaItem-Actions
  // ==========================================================================================================
  /**
   * wird getriggert, wenn der Aktionsbutton je eines AgendaItems angklickt wurde
   *
   * @param e
   * @param aItem
   * @param row
   */
  showAgendaItemActionsList(e: Event, aItem: IAgenda, row: number): void {
    // Verhindern, dass weitere Events ausgeführt werden
    e.stopPropagation();

    if (this.agendaItemActionsList.length > 0) {
      if (aItem.document) {
        const target = e.target;
        this.agendaItemActionTarget = target;
        this.agendaItemActionTargetItem = aItem;
        this.agendaItemActionsVisible = true;
        this.selectedAItem = aItem;

        this.logger.debug('-- showAgendaItemActionsList(): target: {@0}', target);
        this.logger.debug('row [{0}], document: {@1}', row, aItem.document);

        const allowCreateDecision = this.fieldContainer?.editorOptions?.agendaAllowedTemplates?.allowCreateDecision;
        if (allowCreateDecision === false) {
          this.agendaItemActionsList = [
            { text: 'Protokoll erstellen', type: 'standard', disabled: false, action: 'protocol' },
            { text: 'Aufgabe erstellen', type: 'standard', disabled: false, action: 'task' }
          ];
        }
        if (this.workflowStatus === 'abgeschlossen') {
          this.agendaItemActionsList = [
            { text: 'Aufgabe erstellen', type: 'standard', disabled: false, action: 'task' }
          ];
        }
        // Aktionen für selektierten Agendaeintrag konfigurieren
        if (this.documentService.hasChildOfTag(aItem.document, 'app:document-type:protocol')) {
          this.setActionOption(this.agendaItemActionsList, 'protocol', 'text', 'Protokoll bearbeiten');
        } else {
          this.setActionOption(this.agendaItemActionsList, 'protocol', 'text', 'Protokoll erstellen');
        }

        const ad = this.isAgendaItemApproved(aItem.document);
        if (ad) {
          this.setActionOption(this.agendaItemActionsList, 'decision', 'text', 'Beschluss bearbeiten');
        } else {
          this.setActionOption(this.agendaItemActionsList, 'decision', 'text', 'Beschluss erstellen');
        }
      } else {
        NotifyService.global.warn('Der Vorgang ist kein gültiges Dokument.');
      }
    } else {
      NotifyService.global.warn('Es sind keine Aktionen für diesen Vorgang verfügbar.');
    }
  }

  approveSelectedItem(e: Event, agendaItem: IAgenda): void {
    e.stopPropagation();
    this.selectedAgendaItemDocument = agendaItem.document;
    this.approvalDialog.approve(this.selectedAgendaItemDocument);
  }

  /**
   * wird getriggert, wenn eine AgendaItem-Aktion in der UI aufgerufen wurde
   *
   * @param value: die ausgelöste Aktion
   */
  selectAgendaItemAction(value: IActionStandard): void {
    this.selectedAgendaItemDocument = this.agendaItemActionTargetItem.document;

    this.logger.debug(
      'selectAgendaItemAction()\n-- action: [{0}], AgendaDocument: {@1}',
      value.action,
      this.selectedAgendaItemDocument
    );

    switch (value.action) {
      case 'protocol': {
        this.editAgendaDocument(this.selectedAgendaItemDocument, ['app:document-type:protocol']);
        break;
      }
      case 'task': {
        const topTaskId = this.fieldContainer?.editorOptions?.agendaAllowedTemplates?.templateTopTaskId;
        this.documentEditorComponent.createDocument(
          ['app:document-type:task'],
          this.selectedAgendaItemDocument,
          topTaskId
        );
        break;
      }
      case 'decision': {
        this.approvalDialog.approve(this.selectedAgendaItemDocument);
        break;
      }
      default: {
        NotifyService.global.warn(`unbekannte Aktion: ${value.action}`);
      }
    }
    this.agendaItemActionsVisible = false;
  }

  addClassToPopupActions(e: ItemRenderedEvent<IActionStandard>): void {
    e.itemElement.classList.add('agenda-item__action-popup');
  }

  // ==========================================================================================================
  // === Time Slider
  // ==========================================================================================================
  /**
   * wird getriggert, wenn der Aktionsbutton je eines AgendaItems angklickt wurde
   *
   * @param e
   * @param aItem
   */
  showTimeSlider(e: Event, aItem: IAgenda): void {
    this.logger.debug('--- agenda-include.component: showTimeSlider() called');
    // Verhindern, dass weitere Events ausgeführt werden
    e.stopPropagation();

    if (aItem.children?.length) {
      return;
    }
    if (aItem.document) {
      this.selectedAItem = aItem;
      this.actionSheetTarget = e.target;
      this.timeSliderVisible = true;
      this.timeSliderSettings[0].value = aItem.timeInterval;
    }
  }

  timeSliderValueChanged(e: ValueChangedEvent): void {
    const value = e.value as number;
    this.selectedAItem.timeInterval = value;
    this.timeSliderSettings[0].value = value;
  }

  // ==========================================================================================================
  // === AgendaItems
  // ==========================================================================================================
  selectAgendaItem(aItem: IAgenda, row: number): void {
    this.logger.debug('--- agenda-include.component: selectAgendaItem() called');
    this.selectedIndex = row;
    this.selectedAgendaItemDocument = aItem.document;
    this.agendaItemActionsVisible = false;

    if (!aItem.document) {
      NotifyService.global.warn('Zu diesem TOP wurde kein Dokument gefunden.');
      return;
    }
    this.showAgendaItem(aItem);
  }

  /**
   * Öffnet ein Popup und zeigt den Inhalt an, ggf. inkl. Bearbeitungsmöglichkeit
   *
   * @param agendaItem
   */
  showAgendaItem(agendaItem: IAgenda): void {
    this.logger.debug('--- agenda-include.component: showAgendaItem() called');
    this.logger.debug('showAgendaItem(): öffne Popup für {@d}', agendaItem);

    this.documentService
      .getDocumentById(agendaItem.document.id)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (aiDocument): void => {
          const iType = this.documentService.getType(aiDocument);
          this.logger.debug('Dokumenttyp des selektierten Agendaeintrags:', iType);
          if (!iType) {
            NotifyService.global.warn(
              'Der Typ des Agendapunktes konnte nicht ermittelt werden. Ggf. arbeitest Du mit veralteten Vorlagen?'
            );
          }

          const settings: PopupSettings = {};
          settings.title = `${this.documentService.getTypeLabel(aiDocument)} : ${
            (aiDocument.fields.subject?.value as string) || ''
          }`;

          // Setzen der PopupAktionen je nach dem, welcher TOP-Typ (TOP oder Vorlage) geöffnet werden soll
          if (
            (!this.documentService.isTop(aiDocument) && !this.documentService.isPause(aiDocument)) ||
            this.editMode === 'ReadOnly' ||
            !this.hasUpdatePermission
          ) {
            settings.editMode = 'ReadOnly';
          } else {
            settings.editMode = 'EditPreview';
          }

          // EditorKomponente für angegebenes Dokumentout und den definierten Settings öffnen
          void this.documentEditorComponent.show(aiDocument, settings);
        },
        error: (error: string): void => {
          this.logger.error('Fehler in showAgendaItem');
          NotifyService.global.error(error);
        }
      });
  }

  /**
   * create a new document as from Template 'Top'
   */
  createAgendaItem(index?: number): void {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    if (index > -1) {
      this.indexAgendaItemCreate = index + 1;
    } else {
      this.indexAgendaItemCreate = this.agendaItems.length;
    }
    this.logger.info('--- agenda-include.component: createAgendaItem() called');

    this.templateService
      .getActiveTemplates('create', ['app:document-type:top'])
      .pipe(first(), takeUntil(this.#destroyable$))
      .subscribe({
        next: templates => {
          void (async () => {
            if (templates && !templates.length) {
              NotifyService.global.error(
                'Es wurde keine Vorlage für die Erstellung eines TOP-Dokuments gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
              );
              return;
            }
            let topTemplateAgenda: ITemplateServer = null;
            const topId = this.fieldContainer?.editorOptions?.agendaAllowedTemplates?.templateTopId;
            if (topId) {
              topTemplateAgenda = templates.find(template => template?.id === topId);
            } else {
              topTemplateAgenda = templates.find(template =>
                template?.name?.toLowerCase().includes(this.agendaDocument?.template?.name?.toLocaleLowerCase())
              );
            }
            const topTemplate = topTemplateAgenda || templates[0];

            this.logger.debug('parent-docid für das child:', this.agendaDocument.id);
            const layout = await lastValueFrom(this.documentService.createDocumentByTemplateId(topTemplate));
            void this.documentEditorComponent.show(layout, {
              editMode: 'Edit',
              title: 'Neuer Tagesordnungspunkt'
            });
          })();
        }
      });
  }

  /**
   * create a new break
   */
  createBreakItem(index?: number): void {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.loadPanelService.show();
    this.indexAgendaItemCreate = index + 1;
    this.logger.info('--- agenda-include.component: createAgendaItem() called');

    this.templateService
      .getActiveTemplates('create', ['app:document-type:pause'])
      .pipe(first(), takeUntil(this.#destroyable$))
      .subscribe({
        next: (templates): void => {
          if (templates && !templates.length) {
            this.loadPanelService.hide();
            NotifyService.global.error(
              'Es wurde keine Vorlage für die Erstellung eine TOP-Pause gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
            );
            return;
          }
          this.logger.debug('parent-docid für das child:', this.agendaDocument.id);
          this.saveBreakItem(this.documentService.create(templates[0]));
        },
        error: (error: string): void => {
          this.loadPanelService.hide();
          NotifyService.global.error(error);
        }
      });
  }

  saveBreakItem(pauseDocument: IDocument): void {
    pauseDocument.fields.subject = { value: 'Pause' };
    let documentId: string;
    this.documentService
      .save(pauseDocument)
      .pipe(
        switchMap(savedDocument => {
          documentId = savedDocument.id;
          if (savedDocument.workflowDocuments) {
            return this.documentService.setReference(documentId, this.agendaDocument.id);
          }
          return of(null);
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: (response: IDocument): void => {
          if (response) {
            NotifyService.global.success('Erfolgreich gespeichert');
            this.storeDocument(documentId);
          }
        },
        error: NotifyService.component.error
      });
  }

  // ==========================================================================================================
  // === Agenda Add Buttons
  // ==========================================================================================================
  onAddItemClick(e: ItemClickEvent): void {
    const itemData = e.itemData as IAgendaCreatemAction;
    switch (itemData.action) {
      case 'newSubmission': {
        this.showSubmissionsList();
        break;
      }
      case 'newFreeTop': {
        this.showFreeTopsList();
        break;
      }
      case 'addTop': {
        this.showChildrenTopsList();
        break;
      }
      case 'newOldTop': {
        this.showOldTopsList();
        break;
      }
      case 'newBreak': {
        this.createBreakItem();
        break;
      }
    }
  }

  /**
   * delete the agendlist by index number
   *
   * @param event
   * @param aItem
   */
  removeAgendaItem(event: Event, aItem: IAgenda): void {
    // Ausführung weiterer HTML-Events verhindern
    event.stopPropagation();
    this.selectedAItem = aItem;
    // ungültige Einträge werden einfach aus der Liste gelöscht, es gibt kein Dokument im Hintergrund
    this.logger.debug('--- agenda-include.component: removeAgendaItem() called');

    // removing childern document
    if (aItem.children && aItem.children.length) {
      NotifyService.global.error('Bitte löschen Sie zuerst die untergeordneten Tops bzw. Vorlagen');
      return;
    } else if (
      this.documentService.hasChildOfTag(aItem.document, 'app:document-type:protocol') ||
      this.isAgendaItemApproved(aItem.document)
    ) {
      NotifyService.global.error(
        'Für diesen TOP wurde bereits ein Protokoll bzw Beschluss erstellt, daher kann er nicht mehr von der Tagesordnung entfernt werden.'
      );
      return;
    } else {
      // removing parent document
      this.removeAgendItemFromServer(aItem);
    }
  }

  confirmPopupDeleteClose(): void {
    this.loadPanelService.hide();
  }

  // entgültig löschen
  confirmPopupDeleteResult(): void {
    this.documentService
      .removeReference(this.selectedAItem.document.id, this.agendaDocument.id)
      .pipe(
        switchMap(() => this.documentService.delete(this.selectedAItem.document)),
        takeUntil(this.#destroyable$)
      )
      .subscribe(() => {
        this.removeEntry(this.selectedAItem.document.id);
        NotifyService.global.success('Der Top wurde endgültig gelöscht.');
      });
  }

  // entfernen und parken
  confirmPopupDeleteResultExtra(): void {
    this.documentService
      .removeReference(this.agendaDocument.id, this.selectedAItem.document.id)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: () => {
          this.removeEntry(this.selectedAItem.document.id);
          NotifyService.global.success('Der Top wurde entfernt und geparkt.');
        },
        error: (): void => {
          this.loadPanelService.hide();
        }
      });
  }

  // helper-Methoden
  setActionOption(actions: IActionStandard[], action: string, option: string, value: string): void {
    const index = actions.findIndex(item => item.action === action);
    if (index > -1) {
      // this.logger.debug('setze ActionOption [{a}] für Action [{b}]', option, action);
      actions[index][option] = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragStart(_event: DragEvent): void {
    // nothing to do
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragged(item: IAgenda, _list: IAgenda[], _effect: DropEffect): void {
    if (item.children && item.children.length) {
      return;
    }
  }

  onDragEnd(): void {
    // void this.updateAgendaItemsFields();
  }

  onDrop2InsideTop(event: DndDropEvent, list: IAgenda[], index: number, parent: IAgenda): void {
    this.dragTo = 'inside';
    let child: IAgenda = event.data as IAgenda;
    if (child.children && child.children.length) {
      return;
    }
    const dropInsideSelf = child?.index === index && !child.parentDocId?.length;
    if (dropInsideSelf) {
      return;
    }
    const result = this.removeChildFromParent(list, child);
    list = result.list;
    child = result.child;

    if (parent.children && parent.children.find(x => x.documentId === child.documentId)) {
      return;
    }
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      if (typeof index === 'undefined') {
        index = list.length;
      }
      child.parentDocId = (parent.document && parent.document.id) || parent.documentId;
      child.parentIndex = parent.index;
      if (parent.children && parent.children.length) {
        let newIndex = 1;
        parent.children.splice(index, 0, child);
        parent.children.forEach(ch => {
          ch.index = newIndex;
          newIndex += 1;
        });
        void this.updateAgendaItemsFields();
      } else {
        parent.children = [child];
        child.index = 1;
        void this.updateAgendaItemsFields();
      }
    }
  }

  onDrop2OutsideTop(event: DndDropEvent, list: IAgenda[], index: number): void {
    this.dragTo = 'outside';
    let child = event.data as IAgenda;
    if (child?.children.length) {
      const listAsChild = list.findIndex(l => l.parentDocId === child.documentId);
      if (listAsChild > -1) {
        return;
      }
    }
    if (list.findIndex(l => l.parentDocId?.length) > -1 && !child.parentDocId?.length) {
      return;
    }
    if (list.find(l => l.parentDocId?.length && l.parentDocId !== child.parentDocId)) {
      return;
    }
    const result = this.removeChildFromParent(list, child);
    list = result.list;
    child = result.child;
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, child);
      let newIndex = 1;
      let newPostion = 1;
      list.forEach(parent => {
        if (this.documentService.getType(parent.document) === 'app:document-type:pause') {
          parent.position = newPostion;
          parent.index = null;
        } else {
          parent.position = newPostion;
          parent.index = newIndex;
          newIndex += 1;
        }
        newPostion += 1;
      });
      void this.updateAgendaItemsFields();
    }
  }

  removeChildFromParent(list: IAgenda[], child: IAgenda): { list: IAgenda[]; child: IAgenda } {
    if (child.children && child.children.length) {
      // return { list: list, child: child };
    }
    const parent = list.find(x => x.documentId === child.parentDocId);
    const index = parent && parent.children ? parent.children.findIndex(x => x.documentId === child.documentId) : -1;
    if (index > -1) {
      parent.children.splice(index, 1);
      child.parentDocId = null;
      child.parentIndex = null;
      return { list, child };
    }

    const indexChild = list.findIndex(x => x.documentId === child.documentId);
    if (indexChild > -1) {
      list.splice(indexChild, 1);
      return { list, child };
    }
    return { list, child };
  }

  refreshAgendaList = (): void => {
    void this.updateAgendaItemsFields();
  };

  // ----------------------------------------------------------------------------------
  // ------------- Dialog-Events ------------------------------------------------------
  // ----------------------------------------------------------------------------------
  documentEditorClose(): void {
    return;
  }

  documentEditorResult(e: IEventTask): void {
    switch (e.command) {
      case 'store':
        this.storeDocument(e.object.id);
        break;

      case 'cancel':
      case 'saved':
        this.selectedAgendaItemDocument = null;
        this.loadPanelService.hide();
        break;

      case 'loading':
        this.loadPanelService.show();
        break;

      case 'reload':
        this.documentService
          .getDocumentById(this.selectedAgendaItemDocument.id)
          .pipe(takeUntil(this.#destroyable$))
          .subscribe({
            next: document => {
              this.updateAgendaItemsReferences(document, document.references);
              void this.updateAgendaItemsFields();
              this.loadPanelService.hide();
            },
            error: (error: IError) => {
              this.loadPanelService.hide();
              NotifyService.component.error(error);
            }
          });
        break;
    }
  }

  // ==========================================================================================================
  // -- Beschlussdialog
  // ==========================================================================================================
  approvalDialogClose(): void {
    this.agendaItemActionTargetItem = null;
    // Dialog abgebrochen, nichts zu tun...
  }

  approvalDialogResult(result: IApprovalForm): void {
    let document: IDocument;
    if (result) {
      this.logger.debug(
        'Beschlussdialog positiv bestätigt, speichere Beschlussdaten:\n {@a}\n in Dokument {@b}',
        result,
        this.selectedAgendaItemDocument
      );

      document = this.approvalService.bindApprovalForm(result, this.selectedAgendaItemDocument);
    } else {
      this.logger.debug('Beschlussmarkierung zurücksetzen in Dokument {@b}', this.selectedAgendaItemDocument);
      document = this.approvalService.removeApproval(this.selectedAgendaItemDocument);
    }
    document.fields = this.mapFormDataToDocumentFields(document);
    this.documentService
      .save(document)
      .pipe(
        switchMap(savedDocument => {
          if (!this.documentService.isDecision(this.selectedAgendaItemDocument)) {
            return zip(of(savedDocument), this.numberGroupService.getNext(result.category));
          }
          return zip(of(savedDocument));
        }),
        map(response => response[0]),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: savedDocument => {
          this.selectedAgendaItemDocument = savedDocument;
          this.updateAgendaItemDocument(savedDocument);
          void this.updateAgendaItemsFields();
          NotifyService.global.success('Erfolgreich gespeichert');
        },
        error: (error: IError) => {
          this.loadPanelService.hide();
          NotifyService.component.error(error);
        }
      });
  }

  mapFormDataToDocumentFields(document: IDocument): IDocumentFields {
    return Object.keys(document.fields)
      .map(key => ({
        key,
        field: {
          value: document.fields[key].value as unknown
        }
      }))
      .reduce((fields, item) => {
        const field = document.fields[item.key];
        // include or exclude document from the payload
        let isInclude = true;
        if (field && field.effectivePermissions) {
          const permissions = field.effectivePermissions;
          if (permissions.includes('list')) {
            // if field has 'list' value should be set to null
            item.field.value = null;
          } else if (!permissions.includes('read') && !permissions.includes('update')) {
            // if field does not have any permissions - it should be excluded from payload
            isInclude = false;
          }
        }

        if (isInclude) {
          const namesField = document.template?.fields?.find(
            f => f.name === item.key && f.type === 'permission-targets'
          );
          if (namesField !== undefined) {
            let permissions: IPermissionTarget[] = [];
            if (item.field.value) {
              for (const val of item.field.value as unknown[]) {
                let source = val;
                if ((source as IPermissionTarget).targetId && (source as IPermissionTarget).type) {
                  permissions.push({
                    type: (source as IPermissionTarget).type,
                    targetId: (source as IPermissionTarget).targetId
                  });
                } else {
                  if (typeof val !== 'string') {
                    source = (val as IUserRoleEntry).id || (val as IPermissionTarget).compositeId;
                    permissions.push(Factory.createPermissionTargetWithoutCompositeId(source as string));
                  }
                  if (typeof val === 'string' && (val.startsWith('user') || val.startsWith('role'))) {
                    source = val;
                    permissions.push(Factory.createPermissionTargetWithoutCompositeId(source as string));
                  }
                }
              }
            } else {
              permissions = null;
            }
            fields[item.key] = { value: permissions };
          } else {
            fields[item.key] = item.field;
          }
        }
        return fields;
      }, {} as IDocumentFields);
  }

  // ==========================================================================================================
  // -- Aufgabendialog
  // ==========================================================================================================
  taskDialogResult(result: IEventTask): void {
    switch (result.command) {
      case 'loading':
        this.loadPanelService.show();
        break;
      case 'saved': {
        this.loadPanelService.hide();
        this.logger.debug('Aufgabe erstellt:\n {@a}', result);
        if (result.command === 'saved') {
          const docummentServer = result.object.document;
          if (docummentServer) {
            this.selectedAItem.taskCount = this.selectedAItem.taskCount ? this.selectedAItem.taskCount + 1 : 1;
            void this.updateAgendaItemsFields();
            this.updateAgendaItemsReferences(this.selectedAgendaItemDocument, docummentServer.references);
          }
        }
      }
    }
  }

  // ==========================================================================================================
  // Protokollverarbeitung
  // ==========================================================================================================

  /**
   * create new protocol or edit existing one
   */
  editAgendaDocument(parentDoc: IDocument, type: TTag[]): void {
    if (!parentDoc) {
      NotifyService.component.error('Es wurde kein Dokument übergeben, für das das Protokoll erstellt werden könnte!');
      return;
    }
    this.logger.debug('--- agenda-include.component:editAgendaDocument() called for parentDoc: {@a}', parentDoc);

    this.selectedAgendaItemDocument = parentDoc;
    // Prüfen, ob es bereits ein Protokoll für den übergebenen TOP gibt,
    // falls das der Fall ist, dieses laden, sonst neu erstellen
    let childDoc: IReference = null;
    if (parentDoc.references) {
      this.logger.debug('references: {@0}', parentDoc.references);
      childDoc = parentDoc.references.find(reference => reference.tags.includes(type[0]));
      this.logger.debug('selected doc: {@p}', parentDoc);
      this.logger.debug('first Child: {@p}', childDoc);
    }

    const settings: PopupSettings = { readOnly: false };
    let document$: Observable<IDocument>;
    if (childDoc) {
      settings.title = `Protokoll für TOP '${(parentDoc.fields.subject?.value as string) || ''}'`;
      document$ = this.editChildDocument(childDoc);
    } else {
      settings.title = `Neues Protokoll für TOP '${(parentDoc.fields.subject?.value as string) || ''}'`;
      settings.editMode = 'Edit';
      document$ = this.createChildDocument(type);
    }

    document$.pipe(takeUntil(this.#destroyable$)).subscribe({
      next: (doc): void => {
        if (!doc) {
          return;
        }
        if (this.documentService.checkMatchingFields(doc, parentDoc) && !childDoc) {
          void this.confirmDialogBox().then(async dialogResult => {
            if (dialogResult) {
              try {
                doc = await this.documentService.createFromParent(doc, parentDoc);
                void this.documentEditorComponent.show(doc, settings);
              } catch (error) {
                this.logger.error(
                  '--- agenda-include.component:editAgendaDocument() called: createFromParent(): {@error}: ',
                  error
                );
                NotifyService.component.error(
                  `Das Übernehmen der Inhalte ist fehlgeschlagen, die Erstellung des TOP-Protokolls wurde abgebrochen. Möglicherweise liegt ein Konfigurationsfehler im entsprechenden Dokumenttyp vor!
                  <br> ${(error as TypeError).message}`
                );
              }
            } else {
              void this.documentEditorComponent.show(doc, settings);
            }
          });
        } else {
          void this.documentEditorComponent.show(doc, settings);
        }
      },
      error: () => {
        this.loadPanelService.hide();
        NotifyService.component.error(
          'Der notwendige Dokumenttyp mit dem Tag [' + 'protocol' + '] wurde nicht gefunden!'
        );
      }
    });
  }

  async onShowToolTipColumn(e: CellHoverChangedEvent<IFreeTop>): Promise<void> {
    if (e.rowType === 'data' && e.column.dataField === 'creatordata' && e.eventType === 'mouseover') {
      if (e.data.document.creation.userId !== e.data.document.creation.logonUserId) {
        const logonUser = create();
        logonUser.id = e.data.document.creation.logonUserId;
        const userInformation = await lastValueFrom(this.nameDataPipe.transform(logonUser));
        this.popOverText = `Vertretung durch (<strong>${userInformation.name}</strong>)`;
        this.popoverTarget = e.cellElement;
        void this.popOverCreationColumn?.instance?.show();
      }
    }
    if (e.rowType === 'data' && e.eventType === 'mouseout') {
      void this.popOverCreationColumn?.instance?.hide();
    }
  }

  protected addEntry(document: IDocument): void {
    let tmpIndex = this.agendaItems.length + 1;
    let speakersFromFreeTop: INameSelectorDialogRecord[] = [];
    let timeInterval = 15;
    if (document.fields['--speakers'] && document.fields['--speakers'].value) {
      speakersFromFreeTop = document.fields['--speakers'].value as INameSelectorDialogRecord[];
    }
    if (document.fields.speakers && document.fields.speakers.value) {
      speakersFromFreeTop = document.fields.speakers.value as INameSelectorDialogRecord[];
    }
    if (document.fields.desiredduration && document.fields.desiredduration.value) {
      timeInterval = document.fields.desiredduration.value as number;
    }
    if (this.indexAgendaItemCreate) {
      tmpIndex = this.indexAgendaItemCreate;
      this.agendaItems.splice(tmpIndex, 0, {
        index: tmpIndex,
        position: tmpIndex,
        documentId: document.id,
        document,
        speakers: speakersFromFreeTop,
        timeInterval
      } as IAgenda);
    } else {
      this.agendaItems.push({
        index: tmpIndex,
        position: tmpIndex,
        documentId: document.id,
        document,
        speakers: speakersFromFreeTop,
        timeInterval
      } as IAgenda);
    }
  }

  protected removeEntry(documentId: string): void {
    const parentIndex: number = this.agendaItems.findIndex(ai => (ai.document?.id || ai.documentId) === documentId);
    if (parentIndex !== -1) {
      this.agendaItems.splice(parentIndex, 1);
      void this.updateAgendaItemsFields();
    } else {
      this.agendaItems.forEach((ai, parentIndex) => {
        if (ai.children) {
          const childIndex: number = ai.children.findIndex(ch => (ch.document?.id || ch.documentId) === documentId);
          if (childIndex > -1) {
            this.agendaItems[parentIndex].children.splice(childIndex, 1);
            void this.updateAgendaItemsFields();
          }
        }
      });
    }
  }

  private fetchAgendaStartDate(): void {
    const startDate = this.formData?.startdate;
    this.agendaStartDate = startDate ? new Date(startDate as string) : new Date();
  }

  private storeDocument(documentId: string): void {
    this.loadPanelService.hide();
    this.documentService
      .getDocumentById(documentId)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe(savedDocument => {
        // TOP wurde erstellt oder geändert, Liste aktualisieren
        if (savedDocument) {
          if (this.documentService.isTop(savedDocument)) {
            this.logger.debug('TOP wurde verarbeitet, aktualisiere TOP-Liste...');
            let childIndex = -1;
            let parentIndex = -1;
            const index = this.agendaItems.findIndex((x: IAgenda) => x.documentId === savedDocument.id);
            let shouldSkip = false;

            this.agendaItems.forEach((item, i) => {
              if (shouldSkip) {
                return;
              }
              if (item.children?.length) {
                parentIndex = i;
                childIndex = item.children.findIndex(ch => ch.documentId === savedDocument.id);
                if (childIndex > -1) {
                  shouldSkip = true;
                }
              }
            });
            if (index > -1) {
              this.agendaItems[index].position = index + 1;
              this.agendaItems[index].document = savedDocument;
              this.agendaItems[index].documentId = savedDocument.id;
              this.logger.debug('TOP-Position aktualisiert.');
            } else if (childIndex > -1 && parentIndex > -1) {
              this.agendaItems[parentIndex].children[childIndex].position = childIndex + 1;
              this.agendaItems[parentIndex].children[childIndex].document = savedDocument;
              this.agendaItems[parentIndex].children[childIndex].documentId = savedDocument.id;
            } else {
              this.addEntry(savedDocument);
              this.logger.debug('TOP in AgendaList aufgenommen.');
              this.logger.debug('TO-Dokument: {@a}', this.agendaDocument);
            }
            // Aktualisierung des benutzerdefinierten Feldes zum Speichern der Meta-Informationen für die Agenda
            void this.updateAgendaItemsFields();
          } else if (this.documentService.isPause(savedDocument)) {
            this.logger.debug('TOP-Pause wurde verarbeitet, aktualisiere TOP-Liste...');
            const index = this.agendaItems.findIndex(agendaItem => agendaItem.documentId === savedDocument.id);

            if (index > -1) {
              this.agendaItems[index].index = null;
              this.agendaItems[index].position = index + 1;
              this.agendaItems[index].document = savedDocument;
              this.agendaItems[index].documentId = savedDocument.id;
              this.logger.debug('TOP-Position aktualisiert.');
            } else {
              this.addEntry(savedDocument);
              this.logger.debug('TOP in AgendaList aufgenommen.');
              this.logger.debug('TO-Dokument: {@a}', this.agendaDocument);
            }
            void this.updateAgendaItemsFields();
          } else {
            this.logger.debug(
              'setze übergeordnetes Dokument {@0} als parent für dieses Doc {@1}',
              this.selectedAgendaItemDocument,
              savedDocument
            );
            if (this.selectedAgendaItemDocument?.id !== savedDocument.id) {
              const selectedDocumentId = this.selectedAgendaItemDocument.id;
              this.documentService
                .setReference(savedDocument.id, selectedDocumentId)
                .pipe(
                  switchMap(() => this.documentService.getDocumentById(selectedDocumentId)),
                  takeUntil(this.#destroyable$)
                )

                .subscribe({
                  next: data => {
                    this.updateAgendaItemsReferences(data, data.references);
                    void this.updateAgendaItemsFields();
                  },
                  error: (error: IError) => {
                    this.loadPanelService.hide();
                    NotifyService.component.error(error);
                  }
                });
            }
          }
        }
      });
  }

  private isAgendaItemApproved(document: IDocument): boolean {
    if (this.documentService.isDecision(document)) {
      return !!this.approvalService.getApprovalData(document);
    }
    return false;
  }

  private deleteFreeTopsConfirmed(iterator?: IterableIterator<IDocument>): void {
    const freeTopGrid = this.freeTopSelectionGrid.instance;
    const selectedItems: IDocument[] = freeTopGrid.getSelectedRowsData();
    if (!iterator) {
      iterator = selectedItems[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      NotifyService.global.success('freien TOPs sind gelöscht...');
      void this.freeTopSelectionGrid.instance.refresh();
      return;
    }

    const document = result.value as IDocument;

    this.documentService
      .delete(document, true, true)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (): void => {
          const index = this.freeTopsList.findIndex(entry => entry.id === document.id);
          if (index !== -1) {
            this.freeTopsList.splice(index, 1);
          }
        },
        error: (error: string) => {
          this.loadPanelService.hide();
          NotifyService.global.error(error);
        },
        complete: () => {
          this.deleteFreeTopsConfirmed(iterator);
        }
      });
  }

  private removeReferencesTops(iterator?: IterableIterator<IDocument>): void {
    const childrenTopGrid = this.childrenTopSelectionGrid.instance;
    const selectedItems: IDocument[] = childrenTopGrid.getSelectedRowsData();
    if (!iterator) {
      iterator = selectedItems[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      NotifyService.global.success('Die Verknüpfung wurde gelöst.');
      void this.childrenTopSelectionGrid.instance.refresh();
      return;
    }

    const document = result.value as IDocument;

    this.documentService
      .removeReference(document.id, this.agendaDocument.id)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (): void => {
          const index = this.childrenTopsList.findIndex(entry => entry.id === document.id);
          if (index !== -1) {
            this.childrenTopsList.splice(index, 1);
          }
        },
        error: (error: string) => {
          this.loadPanelService.hide();
          NotifyService.global.error(error);
        },
        complete: () => {
          this.removeReferencesTops(iterator);
        }
      });
  }

  private createChildDocument(documenttype: TTag[]): Observable<IDocument> {
    return this.templateService.getActiveTemplates('create', documenttype).pipe(
      first(),
      switchMap(templates => {
        if (templates && !templates.length) {
          NotifyService.global.error(
            'Es wurde keine Vorlage für die Erstellung eines TOP-Protokolls gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
          );
          return null;
        }
        let topProtocolTemplateAgenda: ITemplateServer = null;
        const topProtocolId = this.fieldContainer?.editorOptions?.agendaAllowedTemplates?.templateTopProtocolId;
        if (topProtocolId) {
          topProtocolTemplateAgenda = templates.find(template => template?.id === topProtocolId);
        } else {
          topProtocolTemplateAgenda = templates.find(template =>
            template?.name?.toLowerCase().includes(this.agendaDocument?.template?.name?.toLocaleLowerCase())
          );
        }

        const topProtocolTemplate = topProtocolTemplateAgenda || templates[0];
        return this.documentService.createDocumentByTemplateId(topProtocolTemplate);
      })
    );
  }

  private confirmDialogBox(): Promise<boolean> {
    const confirmMsg = `Sollen Inhalte des TOPs übernommen werden?`;
    const confirmTitel = `Erstellung des TOP-Protokolls`;
    return confirm(confirmMsg, confirmTitel);
  }

  private editChildDocument(childDoc: IReference): Observable<IDocument> {
    this.logger.debug('editProtocol()  current childDoc: {@c}', childDoc);

    const childDocId = (childDoc.document && childDoc.document.id) || childDoc.referencedDocumentId;

    if (!childDocId) {
      NotifyService.component.error('Es wurde keine Dokument-ID übergeben!');
      return of(null);
    }
    return this.documentService.getDocumentById(childDocId);
  }

  private removeAgendItemFromServer(aItem: IAgenda) {
    const document = aItem?.document;
    if (!document) {
      if (aItem.documentId && !this.agendaDocument.references.find(r => r.referencedDocumentId === aItem.documentId)) {
        this.removeEntry(aItem.documentId);
        return;
      } else {
        this.logger.warn('ungültiger Eintrag, kein Dokument vorhanden.');
        return;
      }
    }

    if (this.documentService.isTop(document)) {
      const button: ToolbarItem = {
        widget: 'dxButton',
        name: 'saveExtra',
        location: 'before',
        toolbar: 'bottom',
        options: { text: 'Parken', icon: 'check' }
      };
      const title = { text: 'Tagesordnungspunkt löschen', name: 'delete', location: 'before', toolbar: 'top' };
      const removeSetting: IConfirmPopupSettings = {
        title: 'Tagesordnungspunkt löschen',
        message:
          'Möchten Sie diesen Tagesordnungspunkt endgültig löschen, oder für eine spätere Verwendung parken? (Der TOP kann später über die Aktion "Freien TOP hinzufügen" erneut einer Tagesordnung zugeordnet werden)',
        items: [button, title]
      };
      this.confirmPopup.show(removeSetting);
    } else if (this.documentService.isPause(document)) {
      this.documentService
        .removeReference(document.id, this.agendaDocument.id)
        .pipe(
          switchMap(() => this.documentService.delete(document)),
          takeUntil(this.#destroyable$)
        )
        .subscribe({
          next: () => {
            this.removeEntry(document.id);
          },
          error: () => {
            this.loadPanelService.hide();
          }
        });
    } else {
      this.documentService
        .removeReference(document.id, this.agendaDocument.id)
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          next: () => {
            NotifyService.global.success('Die Vorlage wurde aus der Tagesordnung entfernt.');
            this.removeEntry(document.id);
          },
          error: () => {
            this.loadPanelService.hide();
          }
        });
    }
  }

  private getParentsFromDocuments(documents: IDocument[]): Observable<IDocument[]> {
    if (!documents.length) {
      return of([] as IDocument[]);
    }
    return of(documents).pipe(
      map(data =>
        data
          .map(d => d.references)
          .filter(all => all.filter(r => r.tags.includes(siamConst.parentTag)))
          .reduce((acc, value) => (acc && acc.concat(value)) || value, null)
      ),
      map(references => references.map(r => r.referencedDocumentId)),
      map(ids => ids.filter((id, pos) => ids.indexOf(id) === pos)),
      switchMap(ids => this.getDocumentsByIds(ids)),
      concatMap(response => response),
      toArray()
    );
  }

  private getDocumentsByIds(ids: string[]): Observable<IDocument[]> {
    if (!ids.length) {
      return of([] as IDocument[]);
    }
    // not more than 1024 OR conditions per request is allowed
    const step = 800;
    let batch = step;
    const uniqueIds = Array.from(new Set(ids));
    const streams$: Observable<IDocument[]>[] = [];
    const length = uniqueIds.length;

    for (let i = 0; i < length; i += step) {
      const part = uniqueIds.slice(i, batch);

      const query = part
        .map(id => {
          const _id = JSON.stringify(id);
          return `id:${_id}`;
        })
        .join(' ');
      const search: ISearch = {
        query,
        resultConfiguration: {
          selectedFields: selectedFieldsSubmission
        }
      };
      streams$.push(this.documentService.searchDocuments(search).pipe(map(data => data.documents)));

      batch += step;
    }

    return concat(...streams$);
  }
}
