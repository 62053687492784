import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTag'
})
export class GetTagPipe implements PipeTransform {

  transform(tags: string[], tagPostfix?: string): string | string[] {
    if (!tags) {
      tags = [];
    }
    if (tagPostfix) {
      const tagType = `app:document-${tagPostfix}:`;
      return tags.filter(tag => tag.startsWith(tagType))
        .map(tag  => tag.split(new RegExp(`^${tagType}`))[1])[0] || null;
    }
    return tags;
  }
}
