<div class="json-viewer">
  <div *ngFor="let item of items" class="item">
    <div class="rep-obj" (click)="toggle(item)">
      <div class="toggler" *ngIf="item.isExpandable" [ngClass]="{'opened': item.isOpened}">
        <i class="material-icons">keyboard_arrow_right</i>
      </div>
      <div class="rep-obj__row">
        <span class="item-key">{{item.key}}:</span>
        <span [ngClass]="item.valueClasses">{{item.title}}</span>
      </div>
    </div>
    <div *ngIf="item.isExpandable && item.isOpened" class="sub-child">
      <app-jsonviewer [json]="item.value"></app-jsonviewer>
    </div>
  </div>
</div>
