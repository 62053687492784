import { Pipe, PipeTransform } from '@angular/core';
import { IPossibleVote } from '@interfaces/siam';

@Pipe({
  name: 'getVoteIcons'
})
export class GetVoteIconsPipe implements PipeTransform {
  transform(value: string, data?: IPossibleVote[]): string {
    if (data) {
      const success = data.find(v => v.value === value);
      let cssClass: string;
      let icon: string;
      if (success) {
        if (success.isSuccess) {
          cssClass = 'vote-value--yes';
          icon = 'done';
        } else {
          cssClass = 'vote-value--no';
          icon = 'close';
        }
      } else {
        cssClass = 'vote-value--abstention';
        icon = 'hourglass_empty';
      }
      return `<i class="material-icons ${cssClass}">${icon}</i>`;
    }
    return '';
  }
}
