<div class="audio-record-wrapper">
  <div *ngIf="!isRecording && !blobUrl" class="button-container">
    <button i18n class="start-button" (click)="startRecording()"></button>
    <span>Aufnahme starten</span>
    <div>{{ recordedTime }}</div>
  </div>

  <div *ngIf="isRecording && !blobUrl" class="button-container">
    <button class="stop-button" (click)="stopRecording()"></button>
    <span>Aufnahme beenden</span>
    <div>{{ recordedTime }}</div>
  </div>

  <div class="audio-record-items" *ngIf="!isRecording && blobUrl">
    <div>
      <audio controls>
        <source [src]="blobUrl" type="audio/webm" />
      </audio>
    </div>
    <div>
      <button i18n class="btn cancel-button" (click)="clearRecordedData()">
        <i class="material-icons">delete</i>Aufnahme löschen
      </button>
      <button i18n class="btn cancel-button" (click)="download()">
        <i class="material-icons">download</i>Aufnahme herunterladen
      </button>
    </div>
  </div>
</div>
