<ng-container *ngIf="(documentExist$ | async) === false; then pageNotFount; else documentTemplate"></ng-container>
<ng-template #documentTemplate>
  <div
    id="document-base"
    class="bg-container"
    [ngClass]="{
      'pattern-background': isPattern
    }"
    *ngIf="currentDocument && currentDocument.template">
    <div class="bg-header" [class.bg-header--sticky]="isStickyHeader">
      <div class="header-caption">
        <div class="header-caption__title">
          <!-- Dokument name and badges -->
          <h3>
            <span class="header-caption__category" *ngIf="isShowCategoryTitle">{{ categoryTitle }}</span>
            <i class="material-icons mr-1">{{ currentDocument.tags | getTag : 'icon' }}</i>
            <span class="header-caption__name" (click)="editMode === 'Edit' ? onShowLabelsDropDown($event) : false">
              {{ currentDocument.template.caption }}
            </span>

            <!-- Badge für Beschluss -->
            <span
              *ngFor="let decision of decisionDocuments"
              id="decisionBadge-{{ decision.document.id }}"
              class="header-container_status"
              [ngStyle]="{ 'background-color': decision.color }"
              (click)="onShowDecisionInformation(decision.document)">
              <i class="material-icons md-18">bookmark</i>{{ decision.title }}
              <dx-tooltip
                target="#decisionBadge-{{ decision.document.id }}"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend">
                <div *dxTemplate="let data of 'content'">
                  {{ decision.isActive ? 'Beschluss Activ' : 'Beschluss als Entwurf' }}
                </div>
              </dx-tooltip>
            </span>

            <!-- Badge für Status -->
            <span
              *ngIf="workflowDocument"
              id="statusBadge"
              class="header-container_status types-selectbox_background-{{ getWorkflowStatusColor | apply }}"
              (click)="onShowWorkflowInformation()">
              <i class="material-icons md-18">forward</i>{{ workflowStatusLabel }}
            </span>

            <dx-tooltip target="#statusBadge" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">Statusinformationen</div>
            </dx-tooltip>

            <!-- Badge für Abstimmung Info -->
            <span
              *ngIf="ballotDocuments?.length"
              id="voteBadge"
              class="header-container_status header-container_status--ballot"
              (click)="onShowBallotInformation()">
              <i class="material-icons md-16">how_to_vote</i>
            </span>

            <dx-tooltip target="#voteBadge" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
              <div *dxTemplate="let data of 'content'">Abstimmungsinformationen</div>
            </dx-tooltip>
          </h3>

          <span *ngIf="confidentialAllowed.length" class="label" (click)="onShowConfidentialsDropDown($event)">
            <i class="material-icons md-16">{{
              confidentialMap[currentDocument.confidential?.confidentialId]?.icon
            }}</i>
            {{ confidentialMap[currentDocument.confidential?.confidentialId]?.name }}
          </span>
        </div>

        <div class="header-caption__subtitle">
          <dx-popover
            class="labels"
            title="Sicherheitsstufe:"
            position="top"
            [target]="targetElementConfidential"
            [(visible)]="isShowConfidentialDropDown"
            [showTitle]="true"
            [width]="300"
            [shading]="true"
            shadingColor="rgba(0, 0, 50, 0.5)">
            <div *dxTemplate="let data of 'content'">
              <dx-list
                [elementAttr]="{ id: 'confidentialsList' }"
                keyExpr="confidentialId"
                displayExpr="name"
                selectionMode="single"
                searchExpr="id"
                searchMode="contains"
                itemTemplate="itemTemplate"
                [items]="confidentialAllowed"
                [(selectedItems)]="confidentialSelected"
                [searchEnabled]="false"
                (onSelectionChanged)="onSelectConfidential($event)">
                <div *dxTemplate="let item of 'itemTemplate'">
                  <span [ngStyle]="{ width: '20px', height: '20px', display: 'inline-block', margin: '0 5px' }">
                    <i class="material-icons md-16">{{ item.icon }}</i>
                  </span>
                  <span> {{ item.name }}</span>
                </div>
              </dx-list>
            </div>
          </dx-popover>

          <div *ngIf="parentDocuments?.length" class="parent-info__container">
            <div *ngFor="let parentDocument of parentDocuments" (click)="openParentDocument(parentDocument)">
              <i class="material-icons md-12">keyboard_return</i>
              gehört zu
              <i class="material-icons md-12">{{ parentDocument.tags | getTag : 'icon' }}</i>
              {{ parentDocument.template.caption }}
              {{ parentDocument.fields.subject?.value ? ' - ' + parentDocument.fields.subject.value : '' }}
              {{
                parentDocument.fields.startdate?.value
                  ? ' (' + (parentDocument.fields.startdate.value | date) + ')'
                  : ''
              }}
              {{
                parentDocument.fields.startDate?.value
                  ? ' (' + (parentDocument.fields.startDate.value | date) + ')'
                  : ''
              }}
              {{
                parentDocument.fields['--approval_number']?.value
                  ? '(' + parentDocument.fields['--approval_number'].value + ')'
                  : ''
              }}
            </div>
          </div>
          <!-- Labels Componente -->
          <app-labels-include
            class="labels"
            [currentDocument]="currentDocument"
            (labelResult)="onEmitLabelIncludeComponent($event)">
          </app-labels-include>
        </div>
      </div>

      <!-- Button-Leiste -->
      <div class="buttons-container">
        <!-- Standard-Buttons -->
        <button type="submit" class="btn" #closeBtn (click)="closeDocument()">
          <i class="material-icons">clear</i>
          <span i18n>Schließen</span>
        </button>

        <button type="success" class="btn" (click)="onSaveDocument(true)" *ngIf="editMode === 'Edit'">
          <i class="material-icons">save</i>
          <span i18n>Speichern</span>
        </button>

        <button type="submit" class="btn" (click)="onModeChanged()" *ngIf="editMode === 'EditPreview'">
          <i class="material-icons">edit</i>
          <span i18n>Bearbeiten</span>
        </button>

        <button i18n type="submit" class="btn" (click)="onDeleteDocument()" *ngIf="isCanDelete">
          <i class="material-icons">delete</i>
          <span>Löschen</span>
        </button>

        <!-- Druck actions -->
        <button i18n type="submit" class="btn" (click)="onPrintDocument()" *ngIf="isCanPrint && currentDocument?.id">
          <i class="material-icons">print</i>
          <span>Drucken</span>
        </button>

        <!-- Pulldownmenü für Workflowaktionen -->
        <button
          id="workflowActions"
          type="submit"
          class="btn"
          (click)="onShowStandardActions($event)"
          *ngIf="workflowActionsWithoutVotes.length > 0">
          <i class="material-icons">forward</i>
          <span>{{ 'Workflow ...' }}</span>
        </button>
        <button
          *ngFor="let voteAction of voteActions"
          type="submit"
          class="btn"
          (click)="onSelectWorkflowAction(voteAction)">
          <i class="material-icons md-16">how_to_vote</i>
          <span>{{ voteAction.text }}</span>
        </button>

        <dx-action-sheet
          id="workflowActionSheet"
          [showTitle]="false"
          [showCancelButton]="true"
          [cancelText]="'Abbrechen'"
          [usePopover]="true"
          [target]="targetElementWorkflowActionSheet"
          [width]="'auto'"
          [dataSource]="workflowActionsWithoutVotes"
          [(visible)]="isShowWorkflowActionSheet"
          itemTemplate="list-item"
          itemHoldTimeout="7000"
          (onItemClick)="onSelectWorkflowAction($event)"
          (onItemHold)="onWorkflowItemHold()"
          (onItemRendered)="onActionSheetItemRendered($event)">
          <div *dxTemplate="let data of 'list-item'; let index = index" class="workflow-action-container">
            <dx-button
              [elementAttr]="{ class: 'workflow-action-button' }"
              icon="{{ data.icon }}"
              type="{{ data.type }}"
              text="{{ data.text }}"
              hint="{{ data.hint }}">
            </dx-button>
          </div>
        </dx-action-sheet>

        <dx-action-sheet
          id="standardActionSheet"
          [showTitle]="false"
          [showCancelButton]="true"
          [cancelText]="'Abbrechen'"
          [usePopover]="true"
          [target]="targetElementWorkflowActionSheet"
          [width]="400"
          [dataSource]="standardActions"
          [(visible)]="isShowStandardActionsSheet"
          (onItemClick)="onSelectStandardAction($event)">
        </dx-action-sheet>

        <!-- agendaActions -->
        <button id="agendaActions" type="submit" class="btn" (click)="onShowAgendaActions($event)" *ngIf="isAgenda">
          <i class="material-icons">gavel</i>
          <span>{{ 'Sitzungsaktionen...' }}</span>
        </button>
        <dx-action-sheet
          target="#agendaActions"
          [showTitle]="false"
          [showCancelButton]="true"
          [cancelText]="'Abbrechen'"
          [usePopover]="true"
          [width]="'auto'"
          [items]="agendaActions"
          itemTemplate="list-item"
          [(visible)]="isShowAgendaActionsSheet"
          (onItemClick)="onSelectAgendaAction($event)">
          <div *dxTemplate="let data of 'list-item'; let index = index" class="workflow-action-container">
            <dx-button
              [elementAttr]="{ class: 'workflow-action-button' }"
              icon="{{ data.icon }}"
              type="{{ data.type }}"
              text="{{ data.text }}"
              hint="{{ data.hint }}">
            </dx-button>
          </div>
        </dx-action-sheet>
        <div class="buttons-container__secondary">
          <i
            *ngIf="isAgenda && isSecureContext"
            id="audioRecordBtn"
            class="material-icons"
            [ngClass]="{ recording: recordActiveClass }"
            (click)="showAudioRecording()">
            keyboard_voice
          </i>
          <dx-tooltip
            target="#audioRecordBtn"
            position="top"
            [showEvent]="{ name: 'mouseover' }"
            hideEvent="dxhoverend">
            <div *dxTemplate="let data of 'content'" i18n>Audioaufzeichnung</div>
          </dx-tooltip>
          <i *ngIf="currentDocument.id" id="refreshBtn" class="material-icons" (click)="onRefreshDocument()">refresh</i>
          <dx-tooltip
            target="#refreshBtn"
            position="top"
            [showEvent]="{ name: 'mouseover', delay: 500 }"
            hideEvent="dxhoverend">
            <div *dxTemplate="let data of 'content'" i18n>Dokument neu öffnen</div>
          </dx-tooltip>
        </div>
      </div>
    </div>

    <app-document-include
      *ngIf="documentToShow"
      [currentDocument]="documentToShow"
      [editMode]="editMode"
      [ballotDocuments]="ballotDocuments"
      [workflowDocument]="workflowDocument"
      (eventResult)="onEmitEvent($event)">
    </app-document-include>

    <!-- -------------------------------- -->
    <!-- optionale Funktions-Komponetnen  -->
    <!-- -------------------------------- -->

    <app-name-selector (dialogResult)="onEmitNameSelectorDialog($event)"></app-name-selector>
    <app-approval-dialog
      [currentDocument]="currentDocument"
      [editMode]="editMode"
      (dialogHidden)="onEmitApprovalDialogClose()"
      (dialogResult)="onEmitApprovalDialog($event)">
    </app-approval-dialog>

    <app-workflow-dialog (dialogResult)="onEmitWorkflowDialog($event)"></app-workflow-dialog>

    <!-- ------------------------------------- -->
    <!-- Popups: Workflowinfo Abstimmungsinfo  -->
    <!-- ------------------------------------- -->
    <app-workflow-info [currentDocument]="currentDocument" [workflowDocument]="workflowDocument"></app-workflow-info>

    <app-ballot-info [currentDocument]="currentDocument" [ballotDocuments]="ballotDocuments"></app-ballot-info>
    <app-confirm-popup></app-confirm-popup>
  </div>
</ng-template>
<ng-template #pageNotFount>
  <app-page-not-found></app-page-not-found>
</ng-template>
<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Sicheren datenraum -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  [(visible)]="isShowBrainLoopDialog"
  [showTitle]="true"
  [width]="520"
  [height]="'auto'"
  [title]="'Hochladen in den Sicheren Datenraum'"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="false"
  (onShown)="onShownPopup()"
  shadingColor="rgba(0, 0, 50, 0.5)">
  <div *dxTemplate="let data of 'content'" class="login-container">
    <!-- Anmeldeformular -->
    <dx-validation-summary></dx-validation-summary>
    <form (submit)="processUpload()">
      <dx-form #brainLoopLoginForm [formData]="brainLoop" [showValidationSummary]="true">
        <dxi-item itemType="group" cssClass="info-message">
          <small>
            Die Unterlagen zu "{{ this.currentDocument.template.caption }}"
            {{
              this.currentDocument.fields?.startdate
                ? 'vom ' + (this.currentDocument.fields.startdate.value | date : 'dd.MM.y')
                : ''
            }}
            sollen in den Sicheren Datenraum hochgeladen werden. Dazu ist eine Anmeldung erforderlich:
          </small>
        </dxi-item>
        <dxi-item
          *ngIf="!isBrainLoopPinRequired && !isBrainLoopTotpRequired"
          itemType="group"
          caption="Benutzeranmeldung"
          cssClass="login-brainloop">
          <!-- Benutzername -->
          <dxi-item [label]="{ text: 'Benutzer' }" dataField="username" editorType="dxTextBox">
            <dxi-validation-rule type="required" message="Benutzer wird benötigt."></dxi-validation-rule>
          </dxi-item>

          <!-- Passwort -->
          <dxi-item
            [label]="{ text: 'Kennwort' }"
            dataField="password"
            editorType="dxTextBox"
            [editorOptions]="{ showClearButton: 'true', mode: 'password' }">
            <dxi-validation-rule type="required" message="Passwort wird benötigt."></dxi-validation-rule>
          </dxi-item>
        </dxi-item>
        <dxi-item
          *ngIf="isBrainLoopPinRequired || isBrainLoopTotpRequired"
          itemType="group"
          caption="2-Faktor-Authentifizierung"
          cssClass="login-brainloop">
          <dxi-item *ngIf="isBrainLoopPinRequired" cssClass="message-text">
            <span class="material-icons md-18 mr-2">{{
              brainLoopPinType === 'mobile' ? 'phone_iphone' : 'email'
            }}</span>
            <small>
              Eine TAN wurde an
              {{ brainLoopPinType === 'mobile' ? 'Ihr Mobiltelefon' : 'Ihre Email-Adresse' }} gesendet.
            </small>
          </dxi-item>
          <dxi-item *ngIf="isBrainLoopTotpRequired" cssClass="message-text">
            <span class="material-icons md-18 mr-2"> vpn_key </span>
            <small> Die Anmeldung erfordert die Eingabe eines Sicherheitscodes.</small>
          </dxi-item>
          <dxi-item
            [label]="{ text: 'Gesendet an' }"
            dataField="receiver"
            [visible]="!!brainLoop.receiver"
            editorType="dxTextBox"
            [editorOptions]="{
              readOnly: 'false'
            }"
            *ngIf="isBrainLoopPinRequired">
          </dxi-item>
          <dxi-item
            [visible]="!!brainLoop.expires"
            [label]="{ text: 'Gültig bis' }"
            dataField="expires"
            editorType="dxDateBox"
            [editorOptions]="{ displayFormat: 'dd.MM.yyyy HH:mm', readOnly: 'false' }"
            *ngIf="isBrainLoopPinRequired">
          </dxi-item>
          <dxi-item
            [label]="{ text: 'TAN eingeben' }"
            dataField="pin"
            editorType="dxTextBox"
            *ngIf="isBrainLoopPinRequired">
            <dxi-validation-rule type="required" message="Tan wird benötigt."></dxi-validation-rule>
          </dxi-item>
          <dxi-item
            [label]="{ text: 'Sicherheitscode' }"
            dataField="totp"
            editorType="dxTextBox"
            *ngIf="isBrainLoopTotpRequired">
            <dxi-validation-rule type="required" message="Sicherheitscode wird benötigt."></dxi-validation-rule>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group">
          <div *dxTemplate class="form-buttons">
            <dx-button id="button" text="Ok" icon="check" [useSubmitBehavior]="true"></dx-button>
            <dx-button
              id="buttonCanel"
              text="Abbrechen"
              icon="close"
              [useSubmitBehavior]="false"
              (onClick)="onCancelUpload()">
            </dx-button>
          </div>
        </dxi-item>
      </dx-form>
    </form>
  </div>
</dx-popup>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Audioaufzeichnung -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  [(visible)]="isShowAudioRecordingDialog"
  [showTitle]="true"
  [width]="480"
  [height]="180"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [shading]="false"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: {
        type: 'normal',
        icon: 'material-icons md-24 keyboard_voice',
        elementAttr: {
          class: 'button-icon'
        }
      }
    },
    {
      text: 'Audioaufzeichnung',
      location: 'before',
      toolbar: 'top'
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      options: {
        type: 'normal',
        icon: 'close',
        hint: 'Schließen'
      },
      onClick: closeAudioRecord
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <app-audio-record
      [document]="currentDocument"
      (isRecordingInProgress)="isRecordingInProgress($event)"></app-audio-record>
  </div>
</dx-popup>

<app-document-editor [parentDocument]="currentDocument"></app-document-editor>
