<dx-popup
  [(visible)]="showSubstitution"
  [minWidth]="750"
  width="50vw"
  height="auto"
  [fullScreen]="false"
  [dragEnabled]="true"
  [showCloseButton]="false"
  [showTitle]="false"
  [shading]="true"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: { type: 'normal', icon: 'material-icons md-24 supervisor_account', elementAttr: { class: 'button-icon' } }
    },
    { text: 'Vertretung', location: 'before', toolbar: 'top' },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      options: {
        type: 'normal',
        icon: 'close',
        hint: 'Schließen'
      },
      onClick: closeSubstitutesPopup
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      visible: !substitutionActivated,
      options: { icon: 'check', text: 'Vertretung beginnen' },
      onClick: processSubstitutes
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      visible: substitutionActivated,
      options: { icon: 'material-icons logout', text: 'Vertretung beenden' },
      onClick: closeSubstitutes
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'close', text: !substitutionActivated ? 'Abbrechen' : 'Vertretung fortsetzen' },
      onClick: closeSubstitutesPopup
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <div *ngIf="!substitutionActivated; else logout">
      <p>
        Sie können als Vertretung eines anderen Benutzers agieren. <br />
        Öffnen Sie die Drop-down-Liste und wählen den gewünschte Benutzer aus.
      </p>
      <dx-form #substitutionForm [formData]="loginSubstitutes">
        <dxi-item
          [label]="{ text: 'Vertreter' }"
          dataField="substitute"
          editorType="dxSelectBox"
          [editorOptions]="{
            items: currentCandidates,
            valueExpr: 'value',
            displayExpr: 'label'
          }">
          <dxi-validation-rule type="required" message="Benutzer wird benötigt."></dxi-validation-rule>
        </dxi-item>
      </dx-form>
    </div>

    <ng-template #logout>
      <p>
        Sie vertreten den Benutzer {{ impersonatedUser?.name }} - {{ impersonatedUser?.email }} <br />
        Sie können Ihre Vertretung beenden indem Sie auf den Button "Vertretung beenden" klicken.
      </p>
    </ng-template>
  </div>
</dx-popup>
