<div *ngIf="currentSetting">

  <dx-popup
    width="50vw"
    height="'auto'"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [(visible)]="confirmOpen"
    [dragEnabled]="true"
    [hideOnOutsideClick]="false"
    [showCloseButton]="false"
    [shading]="true"
    [position]="{ my: 'top', at: 'top', of: '#main-view' }"
    [(toolbarItems)]=currentSetting.items>

    <div>
      <p>{{currentSetting.message}}</p>
    </div>

  </dx-popup>

</div>
