<div *ngIf="user">
  <app-component-notification *ngIf="!changePasswordMode"></app-component-notification>
  <dx-validation-summary></dx-validation-summary>

  <dx-form #userForm colCount="2" class="my-0" [formData]="user" (onFieldDataChanged)="onFieldDataChanged($event)">
    <!-- Basisuserinfos  -->
    <dxi-item itemType="group" caption="Basisangaben" colCount="2">
      <dxi-item
        dataField="profile.title"
        [label]="{ text: 'Titel' }"
        [colSpan]="2"
        editorType="dxTextBox"
        [editorOptions]="{
          placeholder: 'Geben Sie hier optional den Titel an.',
          readOnly: isMyProfile || connectedUser
        }">
      </dxi-item>

      <dxi-item
        dataField="profile.forename"
        [label]="{ text: 'Vorname' }"
        editorType="dxTextBox"
        [editorOptions]="{ placeholder: 'Geben Sie hier den Vornamen an.', readOnly: isMyProfile || connectedUser }">
      </dxi-item>

      <dxi-item
        dataField="profile.surname"
        [label]="{ text: 'Nachname' }"
        editorType="dxTextBox"
        [editorOptions]="{ placeholder: 'Geben Sie hier den Nachnamen an.', readOnly: isMyProfile || connectedUser }">
      </dxi-item>

      <dxi-item
        dataField="name"
        [label]="{ text: 'Benutzername' }"
        editorType="dxTextBox"
        [editorOptions]="{
          placeholder: 'Geben Sie hier den Benutzernamen an.',
          readOnly: isMyProfile || connectedUser
        }">
        <dxi-validation-rule type="required" message="Benutzername wird benötigt."></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        dataField="email"
        [label]="{ text: 'E-Mail' }"
        editorType="dxTextBox"
        [editorOptions]="{
          showClearButton: 'true',
          placeholder: 'Geben Sie hier die Email-Adresse des User an.',
          readOnly: isMyProfile || connectedUser
        }">
        <dxi-validation-rule type="required" message="Die Emailadresse wird von der Anwendung benötigt.">
        </dxi-validation-rule>
        <dxi-validation-rule
          type="pattern"
          [pattern]="emailPattern"
          message="Die eingegebene Emailadresse hat ein ungültiges Format.">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item
        *ngIf="createMode"
        dataField="password"
        [label]="{ text: 'Kennwort' }"
        editorType="dxTextBox"
        [editorOptions]="{
          showClearButton: 'true',
          mode: getPasswordMode(),
          placeholder: 'Geben Sie hier das Kennwort des User ein.'
        }">
        <dxi-validation-rule type="required" message="Passwort wird benötigt."></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        *ngIf="createMode"
        label="Kennwort bestätigen"
        editorType="dxTextBox"
        [editorOptions]="{
          showClearButton: 'true',
          mode: getPasswordMode(),
          placeholder: 'Kennwort bestätigen',
          buttons: [
            {
              location: 'after',
              name: 'password-visibility',
              options: {
                type: 'default',
                icon: getPasswordMode() === 'password' ? 'material-icons visibility_off' : 'material-icons visibility',
                elementAttr: { class: 'button-in-field' },
                onClick: togglePasswordVisibility
              }
            }
          ]
        }">
        <dxi-validation-rule type="required" message="Passwort wird benötigt."></dxi-validation-rule>
        <dxi-validation-rule
          type="compare"
          [comparisonTarget]="passwordComparisonNewUser"
          message="Die Passworteingaben stimmen nicht überein."></dxi-validation-rule>
      </dxi-item>

      <!-- Avatar -->
      <dxi-item
        *ngIf="user.id"
        cssClass="avatar-form"
        [editorOptions]="{
          readOnly: 'false'
        }"
        colSpan="3">
        <div class="upload-container">
          <dx-file-uploader
            #fileUploaderUserAvatar
            accept="image/*"
            uploadMode="useForm"
            selectButtonText="Avatar auswählen"
            [multiple]="false"
            [showFileList]="false"
            (onValueChanged)="onFileValueChanged($event)"
            (onContentReady)="onContentReady($event)">
          </dx-file-uploader>
        </div>

        <div *ngIf="!attachment" class="avatar-preview">
          <img *ngIf="user.profile && user.profile.picture" [src]="user | getUserAvatar | async" width="100" alt="" />
          <div>
            <button i18n class="btn mt-3" (click)="removeAvatar()">
              <i class="material-icons">redo</i>Avatar rücksetzen
            </button>
          </div>
        </div>

        <div *ngIf="attachment" class="avatar-preview">
          <img [src]="attachment | fileDownloadLink : 'url' | async" alt="" />
          <div>
            <button i18n class="btn mt-3" (click)="removeAvatar()">
              <i class="material-icons">redo</i>Avatar rücksetzen
            </button>
          </div>
        </div>
      </dxi-item>
    </dxi-item>

    <!-- weitere Angaben -->
    <dxi-item itemType="group" caption="weitere Angaben">
      <dxi-item
        dataField="rolesIds"
        [label]="{ text: 'Rollen' }"
        editorType="dxTagBox"
        [editorOptions]="{
          dataSource: roles,
          valueExpr: 'compositeId',
          displayExpr: 'name',
          showSelectionControls: 'true',
          searchEnabled: 'true',
          showClearButton: 'true',
          showDropDownButton: 'true',
          readOnly: isMyProfile,
          onSelectionChanged: onSelectionChanged,
          placeholder: 'Geben Sie hier die Rollen des Users an.'
        }"></dxi-item>
      <dxi-item
        dataField="clientSubstitutes"
        [label]="{ text: 'Vertreter' }"
        editorType="dxTagBox"
        [editorOptions]="{
          placeholder: 'Geben Sie hier optional die Vertreter des Users an.',
          readOnly: !canSubstitutesUpdate,
          dataSource: usersRolesSource,
          valueExpr: 'id',
          displayExpr: 'name',
          searchEnabled: true,
          showSelectionControls: true,
          showDropDownButton: true,
          showClearButton: true,
          applyValueMode: 'instantly',
          hideSelectedItems: false,
          onSelectionChanged: onSelectionChanged,
          itemTemplate: userItemTemplate,
          tagTemplate: userTagTemplate
        }"></dxi-item>
      <dxi-item
        dataField="profile.department"
        [label]="{ text: 'Abteilung' }"
        [editorOptions]="{
          placeholder: 'Geben Sie die Abteilung des Users ein...',
          readOnly: isMyProfile || connectedUser
        }"></dxi-item>
      <dxi-item
        dataField="profile.company"
        [label]="{ text: 'Firma' }"
        [editorOptions]="{
          placeholder: 'Geben Sie den Namen der Firma des Users ein...',
          readOnly: isMyProfile || connectedUser
        }"></dxi-item>
      <dxi-item
        dataField="profile.jobTitle"
        [label]="{ text: 'Funktion' }"
        [editorOptions]="{
          placeholder: 'Geben Sie die Funktionsbezeichnung des Users ein...',
          readOnly: isMyProfile || connectedUser
        }"></dxi-item>
      <dxi-item
        dataField="profile.location"
        [label]="{ text: 'Dienstort' }"
        [editorOptions]="{
          placeholder: 'Geben Sie den Ort des Users ein...',
          readOnly: isMyProfile || connectedUser
        }"></dxi-item>

      <!-- Das Feld 'Mobile Tel.' wird zur Zeit nicht verwendet -->
      <dxi-item
        [visible]="false"
        dataField="profile.mobilePhone"
        [label]="{ text: 'Mobile Tel.' }"
        [editorOptions]="{ placeholder: '+49 XXX XXX XX XX', readOnly: isMyProfile || connectedUser }"
        editorType="dxNumberBox"></dxi-item>
    </dxi-item>
  </dx-form>

  <!-- Popups: Password-Change -->
  <dx-popup
    #userPasswordPopup
    [height]="'auto'"
    [width]="600"
    [dragEnabled]="true"
    [hideOnOutsideClick]="false"
    [showCloseButton]="false"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [(visible)]="changePasswordMode"
    [toolbarItems]="[
      {
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        visible: true,
        options: { type: 'normal', icon: 'material-icons md-24 verified_user', elementAttr: { class: 'button-icon' } }
      },
      { text: 'Kennwort ändern', location: 'before', toolbar: 'top' },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'top',
        visible: true,
        options: { type: 'normal', icon: 'close', hint: 'Schließen' },
        onClick: dialogCancel
      },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { icon: 'check', text: 'Übernehmen', visible: 'true' },
        onClick: changePassword
      },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { icon: 'close', text: 'Abbrechen' },
        onClick: dialogCancel
      }
    ]">
    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view width="100%" height="100%">
        <app-component-notification></app-component-notification>
        <dx-form #userPasswordForm [formData]="password">
          <dxi-item itemType="group" cssClass="password-form">
            <dxi-item cssClass="btn-visibility">
              <button class="btn btn-link" (click)="togglePasswordVisibility()">
                <i class="material-icons" *ngIf="!fieldPasswordMode">visibility</i>
                <i class="material-icons" *ngIf="fieldPasswordMode">visibility_off</i>
              </button>
            </dxi-item>

            <!-- Old Password -->
            <dxi-item
              [label]="{ text: 'Aktuelles Kennwort' }"
              *ngIf="isMyProfile"
              dataField="oldPassword"
              editorType="dxTextBox"
              [editorOptions]="{ showClearButton: 'true', mode: getPasswordMode(), width: '90%' }">
              <dxi-validation-rule type="required" message="Passwort wird benötigt."></dxi-validation-rule>
            </dxi-item>

            <!-- New Password -->
            <dxi-item
              [label]="{ text: 'Neues Kennwort' }"
              dataField="newPassword"
              editorType="dxTextBox"
              [editorOptions]="{ showClearButton: 'true', mode: getPasswordMode(), width: '90%' }">
              <dxi-validation-rule
                type="required"
                message="Passwort wird benötigt. Mindestens {{ passwordRequirements?.requiredLength }} Zeichen,
               {{ passwordRequirements?.requireUppercase ? 'ein Großbuchstabe (A–Z), ' : '' }} 
               {{ passwordRequirements?.requireLowercase ? 'ein Kleinbuchstabe (a-z), ' : '' }}
               {{ passwordRequirements?.requireDigit ? 'eine Ziffer (0–9), ' : '' }}
               {{ passwordRequirements?.requireNonAlphanumeric ? 'ein nicht-alphanumerisches Zeichen' : '' }}">
              </dxi-validation-rule>
            </dxi-item>

            <!-- confirm password -->
            <dxi-item
              [label]="{ text: 'Kennwort bestätigen' }"
              dataField="confirmPassword"
              editorType="dxTextBox"
              [editorOptions]="{ showClearButton: 'true', mode: getPasswordMode(), width: '90%' }">
              <dxi-validation-rule type="required" message="Passwort wird benötigt."></dxi-validation-rule>
              <dxi-validation-rule
                type="compare"
                message="Die Passworteingaben stimmen nicht überein."
                [comparisonTarget]="passwordComparison">
              </dxi-validation-rule>
            </dxi-item>
          </dxi-item>
        </dx-form>
      </dx-scroll-view>
    </div>
  </dx-popup>
</div>
