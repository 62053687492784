<ng-container *ngIf="dialogVisible">
  <dx-popup
    #workflowDialog
    [height]="'auto'"
    [visible]="dialogVisible"
    maxHeight="100%"
    [width]="'60%'"
    [fullScreen]="dialogFullscreen"
    [dragEnabled]="true"
    [hideOnOutsideClick]="false"
    [showCloseButton]="false"
    [shading]="true"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [position]="{ my: 'top', at: 'top', of: '#main-view' }"
    [toolbarItems]="[
      {
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        visible: true,
        options: { type: 'normal', icon: 'material-icons md-24 forward', elementAttr: { class: 'button-icon' } }
      },
      { text: dialogTitle, location: 'before', toolbar: 'top' },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { icon: 'add', text: 'Übernehmen', visible: 'true' },
        onClick: dialogOk
      },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
        onClick: dialogCancel
      }
    ]">
    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view>
        <app-component-notification></app-component-notification>
        <app-document-include
          *ngIf="dialogDocument"
          [isPopupDocument]="true"
          [editMode]="'Edit'"
          [currentDocument]="dialogDocument"
          [parentDocumentId]="parentDocumentId">
        </app-document-include>
      </dx-scroll-view>
    </div>
  </dx-popup>
</ng-container>
