import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NotifyService } from '@services/notify.service';
import { Subject, Subscription } from 'rxjs';
import { ISiamNotification } from '@interfaces/siam';

@Component({
  selector: 'app-component-notification',
  templateUrl: './component-notification.component.html',
  styleUrls: ['./component-notification.component.scss'],
})
export class ComponentNotificationComponent implements OnDestroy {
  errors$ = new Subject<string[]>();
  warnings$ = new Subject<string[]>();
  infos$ = new Subject<string[]>();
  detailError = '';
  isShowDetails = false;

  private readonly subscription$: Subscription;

  constructor(private cdRef: ChangeDetectorRef) {
    this.subscription$ = NotifyService.getNotifications()
      .subscribe(notifications => this.receiveNotifications(notifications));
  }

  ngOnDestroy(): void {
    this.closeComponentNotifications();
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  closeComponentNotifications(): void {
    this.errors$.next([]);
    this.warnings$.next([]);
    this.infos$.next([]);

    NotifyService.destroyNotifications();
  }

  private receiveNotifications(notifications: ISiamNotification[]): void {
    const errors: string[] = [];
    const warnings: string[] = [];
    const infos: string[] = [];
    notifications.forEach(notification => {
      const message = notification.message;
      switch (notification.class) {
        case 'error':
          errors.push(message);
          break;
        case 'warning':
          warnings.push(message);
          break;
        case 'info':
          infos.push(message);
          break;
        default:
          break;
      }
      this.detailError = notification.details;
    });

    this.errors$.next(errors);
    this.warnings$.next(warnings);
    this.infos$.next(infos);
    this.cdRef.detectChanges();
  }
}
