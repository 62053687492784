<dx-popup
        [width]="70 + 'vw'"
        [height]="'auto'"
        [fullScreen]="false"
        [dragEnabled]="true"
        [showCloseButton]="false"
        [showTitle]="false"
        [shading]="true"
        shadingColor="rgba(0, 0, 50, 0.5)"
        [wrapperAttr]="{ class: 'user-settings' }"
        [(visible)]="showUserSettings"
        [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: { type: 'normal', icon: 'icon ion-md-color-palette', elementAttr: { class: 'button-icon' } }
    },
    { text: 'Benutzereinstellungen', location: 'before', toolbar: 'top' },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: cancelUserSettings
    }
  ]">
    <div *dxTemplate="let data of 'content'">
        <dx-tab-panel
                id="myTabPanel"
                [dataSource]="tabs"
                [selectedIndex]="0"
                [animationEnabled]="false"
                [swipeEnabled]="false">
            <div *dxTemplate="let tab of 'title'">
                {{ tab.text }}
            </div>

            <div *dxTemplate="let tab of 'item'">
                <!--Tab Background settings -->
                <div *ngIf="tab.index === 0 && !isCustomBackground">
                    <dx-tile-view
                            [height]="60 + 'vh'"
                            [baseItemWidth]="160"
                            [baseItemHeight]="140"
                            [itemMargin]="0"
                            [direction]="'vertical'"
                            [showScrollbar]="'onScroll'">
                        <dxi-item *ngFor="let data of backgroundsList" class="background__content">
                            <img
                                    class="background__image"
                                    (click)="switchBackground(data.background)"
                                    src="{{ data.previewSrc }}"
                                    alt="{{ data.title }}"/>
                            <span class="background__name">{{ data.title }}</span>
                        </dxi-item>
                    </dx-tile-view>
                    <!--  <div class="buttons-container buttons-container_right">
                       <button i18n type="submit" class="btn btn-outline-success btn-sm" (click)="cancelUserSettings()">
                         <i class="material-icons">close</i>Schließen
                       </button>
                     </div> -->
                </div>

                <!--Tab Theme settings -->
                <div *ngIf="tab.index === 1 && !globalOnly">
                    <dx-tile-view
                            [height]="60 + 'vh'"
                            [baseItemWidth]="200"
                            [baseItemHeight]="160"
                            [itemMargin]="0"
                            [direction]="'vertical'"
                            [showScrollbar]="'onScroll'"
                            itemTemplate="THtile"
                            [dataSource]="themesList">
                        <div class="theme__content" *dxTemplate="let data of 'THtile'">
                            <img
                                    class="theme__image"
                                    (click)="switchTheme(data)"
                                    [class.theme__image--current]="currentTheme === data.cssClass"
                                    src="{{ data.imageSrc }}"
                                    alt="{{ data.name }}"/>
                            <span class="theme__name">{{ data.name }}</span>
                        </div>
                    </dx-tile-view>
                </div>
            </div>
        </dx-tab-panel>
    </div>
</dx-popup>
