import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { copy } from '@factories/role.factory';
import { RoleState } from '../entities/role.entity';
import { IRole } from '@interfaces/siam';

export const getRoleState = createFeatureSelector<RoleState>('roleState');

export const getAllRoles = createSelector(
  getRoleState,
  roles => Object.values(roles.entities).map(role => copy(role))
);

// eslint-disable-next-line
export const getRoleById = (id: string): MemoizedSelector<object, IRole> => createSelector(
  getRoleState,
  roles => copy(roles.entities[id])
);
