<div class="startpage-wrapper">
  <div class="header">
    <!-- Company -->
    <div class="company v-center">
      <div
        *ngIf="svgLogo"
        class="app-logo"
        [innerHTML]="svgLogo"
        style="width: 150px; height: auto; margin-right: 0.5rem"></div>
      <img *ngIf="!svgLogo" [src]="pngLogo" alt="AgiliCision Logo" />
      <span>{{ sharedData.appTitle$ | async }}</span>
    </div>
  </div>

  <div class="content">
    <!-- Willkommem Text -->
    <div class="welcome-text">
      <h1>Willkommen!</h1>
      <p>Wir freuen uns, dass Sie wieder da sind.</p>
    </div>
    <!-- Anmeldeformular -->
    <div class="login-form">
      <form (ngSubmit)="login()">
        <dx-form #formLogin id="form" [formData]="loginForm" [showColonAfterLabel]="false" [showRequiredMark]="false">
          <dxi-item itemType="group" caption="Anmelden">
            <dxi-item
              [label]="{ text: 'Benutzername', location: 'top' }"
              dataField="username"
              [editorOptions]="{ onInitialized: setFocus }">
              <dxi-validation-rule type="required" message="Benutzername wird benötigt"> </dxi-validation-rule>
            </dxi-item>
            <dxi-item
              [label]="{ text: 'Kennwort', location: 'top' }"
              dataField="password"
              [editorOptions]="{ mode: 'password' }">
              <dxi-validation-rule type="required" message="Kennwort wird benötigt"> </dxi-validation-rule>
            </dxi-item>
            <dxi-item itemType="group" [colCount]="2" cssClass="login-options">
              <dxi-item
                itemType="simple"
                [label]="{ visible: false, text: '' }"
                dataField="isPersistent"
                editorType="dxCheckBox"
                [editorOptions]="{
                  text: 'angemeldet bleiben'
                }">
              </dxi-item>
              <dxi-item [visible]="false" [template]="'forgotPassword'"></dxi-item>
            </dxi-item>
          </dxi-item>

          <dxi-item
            itemType="button"
            [buttonOptions]="{
              text: 'Anmelden',
              useSubmitBehavior: true,
              width: '100%',
              type: 'default',
              disabled: submitDisabled
            }">
          </dxi-item>

          <div *dxTemplate="let data of 'forgotPassword'" class="forgot-pw-link">
            <!-- to activeta link, edit scss style .forgot-pw-link>a -->
            <a href="">Kennwort vergessen?</a>
          </div>
        </dx-form>
      </form>
    </div>
  </div>
  <app-component-notification></app-component-notification>
</div>
