import { Component, Input, ViewChild, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { IDxFormItemTemplate } from '@interfaces/devextreme';
import { IDocument, IEventEmitter, IResultDiagram, IWorkflowDocument } from '@interfaces/siam';
import { DxDiagramComponent } from 'devextreme-angular';
import dxDiagram from 'devextreme/ui/diagram';

interface IDxOnCustomCommand {
  component: dxDiagram;
  element: HTMLElement;
  name: string;
}

@Component({
  selector: 'app-diagram-include',
  templateUrl: './diagram-include.component.html',
  styleUrls: ['./diagram-include.component.scss']
})
export class DiagramIncludeComponent implements AfterViewInit, OnInit {
  @ViewChild(DxDiagramComponent, { static: false }) diagram: DxDiagramComponent;

  @Input() currentDocument: IDocument;
  @Input() workflowDocument: IWorkflowDocument;
  @Input() fieldContainer: IDxFormItemTemplate;

  @Output() eventDiagram = new EventEmitter<IEventEmitter<IResultDiagram>>();

  allowUpdate = true;

  ngOnInit(): void {
    this.allowUpdate = this.currentDocument.effectivePermissions?.some(p => p === 'update') &&
      !this.fieldContainer.editorOptions.readOnly;
  }

  ngAfterViewInit(): void {
    if (this.fieldContainer && this.currentDocument) {
      const value = this.currentDocument.fields[this.fieldContainer.name]?.value as string;
      if (value) {
        this.diagram.instance.import(JSON.stringify(value));
      }
    }
  }

  onCustomCommand(event: IDxOnCustomCommand): void {
    if (event.name === 'save') {
      const data = this.diagram?.instance?.export();
      const value = JSON.parse(data) as string;
      this.eventDiagram.emit({ command: 'diagram', object: { fieldName: this.fieldContainer.name, value } });
    }
  }
}
