<app-dynamic-list
  *ngIf="displayMode === 'Liste'"
  [buttons]="dynamicListButtons"
  [documentType]="documentType"></app-dynamic-list>

<app-dynamic-diagramm
  *ngIf="displayMode === 'Diagramm'"
  [buttons]="dynamicDiagrammButtons"
  [documentType]="documentType">
</app-dynamic-diagramm>
