import { Pipe, PipeTransform } from '@angular/core';
import { IRolePermission, IUserPermission, TEffectivePermission, TPermission } from '@interfaces/siam';
import { CellClickEvent } from 'devextreme/ui/data_grid';

@Pipe({
  name: 'templatePermission',
})
export class TemplatePermissionPipe implements PipeTransform {
  /**
   * returns the current documentPermission or templatePermission as boolean
   *
   * @param cell current cell data
   * @param permissions e.g: documenetPermission or templatePermissions
   * @param effectivePermission e.g read or execute
   * @param allowOrDenied permission is allows or denied
   */
  transform(
    cell: CellClickEvent<TPermission>,
    permissions: TPermission[],
    effectivePermission: TEffectivePermission,
    allowOrDenied: 'allows' | 'denies',
  ): boolean {
    const currentPermission = cell.data;

    let value = false;
    permissions.forEach(permission => {
      switch (permission.type) {
        case 'role': {
          if (permission.roleId && permission.roleId === (currentPermission as IRolePermission).roleId) {
            value = this.checkPermission(permission, allowOrDenied, effectivePermission);
          }
        }
          break;

        case 'user': {
          if (permission.userId && permission.userId === (currentPermission as IUserPermission).userId) {
            value = this.checkPermission(permission, allowOrDenied, effectivePermission);
          }
        }
          break;
      }
    });
    return value;
  }

  private checkPermission(permission: TPermission, allowOrDenied: string, effectivePermission: TEffectivePermission): boolean {
    switch (allowOrDenied) {
      case 'allows':
        return permission.allows.indexOf(effectivePermission) !== -1;
      case 'denies':
        return permission.denies.indexOf(effectivePermission) !== -1;
      default:
        return false;
    }
  }
}
