<div class="agenda-items">
  <!-- Agenda items list -->
  <div *ngIf="agendaItems && agendaItems.length > 0" class="agenda-items-header">
    <div i18n class="agenda-column agenda-column__index">
      <!-- expand/collapse button -->
      <div *ngIf="selectAllItems; else expandBlock" class="agenda-items-buttons">
        <i
          id="collapseButton"
          (click)="selectAllItems = false; selectedItem = null"
          class="dx-icon dx-icon-collapse"></i>
      </div>
      <dx-tooltip target="#collapseButton" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">Alle Einträge zuklappen</div>
      </dx-tooltip>
      <ng-template #expandBlock>
        <div class="agenda-items-buttons">
          <i id="expandButton" (click)="selectAllItems = true; selectedItem = null" class="dx-icon dx-icon-expand"></i>
        </div>
        <dx-tooltip target="#expandButton" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data = data; of: 'content'">Alle Einträge aufklappen</div>
        </dx-tooltip>
      </ng-template>
      Nr.
    </div>
    <div i18n class="agenda-column agenda-column__subject">TOP</div>
    <div i18n class="agenda-column agenda-column__speakers">Referent</div>
    <div i18n class="agenda-column agenda-column__approval"><i class="material-icons">bookmark</i>Beschluss</div>
  </div>

  <div class="protocol-tops">
    <ng-template *ngIf="agendaItems && agendaItems.length > 0; then dataBlockContainer"></ng-template>

    <ng-template #dataBlockContainer>
      <ng-container *ngTemplateOutlet="dataBlock; context: { $implicit: agendaItems, effect: 'move' }"></ng-container>
    </ng-template>

    <ng-template #dataBlock let-items let-effect="effect">
      <div *ngFor="let aItem of items" class="agenda-item">
        <!-- Tops title -->
        <div id="AgendaItem">
          <div class="agenda-item__parent" (click)="openAItemDetails(aItem)">
            <!-- index  -->
            <div class="agenda-column agenda-column__index" (click)="openAItem(aItem); $event.stopPropagation()">
              <span *ngIf="aItem.parentIndex" class="agenda-column__parent-index">{{ aItem.parentIndex }}.</span>
              <span>{{ aItem.index }}</span>
            </div>

            <!-- subject -->
            <div class="agenda-column agenda-column__subject">
              <span *ngIf="aItem | getDocument : validDocuments | async as top; else placeholder">
                {{ top.fields['subject']?.value }}</span
              >
            </div>

            <!-- speakers  -->
            <div class="agenda-column agenda-column__speakers">
              <span *ngIf="aItem.speakers">
                {{ aItem.speakers | mapAuthorName | async }}
              </span>
            </div>

            <!-- approval -->
            <div
              class="agenda-column agenda-column__approval"
              *ngIf="aItem | getDocument : validDocuments | async as top; else placeholder">
              <span *ngIf="top.fields && top.fields['--approval_number']">
                {{ top.fields['--approval_number'].value }}
              </span>
            </div>
          </div>
        </div>

        <!-- Placeholder for agenda items -->
        <ng-template #placeholder></ng-template>

        <!-- Tops content -->
        <div class="protocol-tops__content" *ngIf="(selectedItem && aItem === selectedItem) || selectAllItems">
          <!-- Approval -->
          <div
            *ngIf="aItem | getDocument : validDocuments | async as top; else placeholder"
            class="protocol-tops__approval">
            <ng-container *ngIf="isDecision(top)">
              <h1><i class="material-icons">bookmark</i>Beschluss</h1>
              <!-- Approval header -->
              <div *ngIf="top.fields['--approval_number']" class="protocol-tops__approval__header">
                <span *ngIf="top.fields['--approval_number']">
                  Beschluss-Nr.: <strong>{{ top.fields['--approval_number'].value }}</strong>
                </span>
                <span *ngIf="top.fields['--approval_type']">
                  Beschlussvermerk:
                  <strong>{{ top.fields['--approval_type'].value | getDecisionType | async }}</strong>
                </span>
                <span *ngIf="top.fields['--approval_category']">
                  Beschlusskategorie:
                  <strong>{{ top.fields['--approval_category'].value }}</strong>
                </span>
                <span *ngIf="top.fields['--approval_date']">
                  Beschlussdatum:
                  <strong>{{ top.fields['--approval_date'].value | date : 'dd.MM.yyyy' }}</strong>
                </span>
              </div>

              <!-- Approval content -->
              <div
                *ngIf="top.fields['--approval_comment'] && top.fields['--approval_comment'].value"
                class="protocol-tops__approval__comment">
                <span>Beschlusskommentar: {{ top.fields['--approval_comment'].value }}</span>
              </div>
            </ng-container>
          </div>

          <!-- Protokoll -->
          <div *ngIf="aItem | getProtocol | async as protocol; else placeholder" class="protocol-tops__protocol">
            <ng-container *ngIf="protocol">
              <h1>
                <i class="material-icons">book</i>Protokoll
                <span class="protocol-tops__protocol__details">
                  (<a id="openProtocol" (click)="openAItemProtocol(protocol)">Details</a>)
                </span>
                <span *ngIf="hasAttachment(protocol)">
                  <i class="material-icons agenda-column__speakers-icon state-hover">attach_file</i>
                </span>
              </h1>
              <dx-tooltip target="#openProtocol" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
                <div *dxTemplate="let data = data; of: 'content'">Für das vollständige TOP-Protokoll hier klicken</div>
              </dx-tooltip>
              <dx-html-editor
                valueType="html"
                readOnly="true"
                [elementAttr]="{ style: 'border: none' }"
                value="{{ protocol.fields['body']?.value || protocol.fields['inhalt']?.value }}"></dx-html-editor>
            </ng-container>
          </div>

          <!-- Tasks -->
          <div *ngIf="aItem.documentId | getTasks | async as tasks; else placeholder" class="protocol-tops__tasks">
            <ng-container *ngIf="tasks.length > 0">
              <h1><i class="material-icons">alarm_on</i>Aufgaben</h1>
              <table class="table">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Termin</th>
                  <th scope="col">Verantwortlich</th>
                  <th scope="col">Thema</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let task of tasks; index as index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ task.fields.enddate?.value | date : 'dd.MM.yyyy' }}</td>
                  <td>{{ task.fields.chair?.value[0] | mapAuthorName | async }}</td>
                  <td>{{ task.fields.subject?.value }}</td>
                </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </div>

        <!-- Tops children -->
        <div class="agenda-item__children">
          <div *ngIf="aItem && aItem.children && aItem.children.length">
            <ng-container *ngTemplateOutlet="dataBlock; context: { $implicit: aItem.children, effect: 'move' }">
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<app-document-editor
  [parentDocument]="meetingsMinutesDoc"
  (dialogResult)="documentEditorResult($event)">
</app-document-editor>
