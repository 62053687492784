<dx-diagram id="diagram" [readOnly]="!allowUpdate" (onCustomCommand)="onCustomCommand($event)">
  <dxo-view-toolbar [visible]="true">
    <dxi-command name="save" icon="save" text="Speichern"></dxi-command>
    <dxi-command name="separator"></dxi-command>
    <dxi-command name="zoomLevel"></dxi-command>
    <dxi-command name="fullScreen"></dxi-command>
    <dxi-command name="separator"></dxi-command>
    <dxi-command name="export" icon="export">
      <dxi-item name="exportSvg"></dxi-item>
      <dxi-item name="exportPng"></dxi-item>
      <dxi-item name="exportJpg"></dxi-item>
    </dxi-command>
  </dxo-view-toolbar>
</dx-diagram>
