<div>
  <ng-container *ngIf="isShowKanban">
    <dx-button
      *ngIf="displayMode === 'DX-Kanban'"
      [elementAttr]="{ id: 'listBtn' }"
      text="Tabellenansicht"
      icon="detailslayout"
      (onClick)="changeModeToList()"></dx-button>
  </ng-container>

  <ng-container *ngIf="isShowDiagramm">
    <dx-button
      *ngIf="displayMode === 'DX-Kanban'"
      [elementAttr]="{ id: 'diagrammBtn' }"
      text="Diagrammansicht"
      icon="chart"
      (onClick)="changeModeToDiagramm()"></dx-button>
  </ng-container>
</div>

<app-dynamic-list
  *ngIf="displayMode === 'Liste'"
  [documentType]="documentType"
  [buttons]="dynamicListButtons"
  [embeddedMode]="embeddedMode"
  [personalMode]="personalMode">
</app-dynamic-list>

<ng-container *ngIf="isShowDiagramm">
  <app-dynamic-diagramm
    *ngIf="displayMode === 'Diagramm'"
    [buttons]="dynamicDiagrammButtons"
    [documentType]="documentType">
  </app-dynamic-diagramm>
</ng-container>

<ng-container *ngIf="isShowKanban">
  <app-tasks-kanban-board
    *ngIf="displayMode === 'DX-Kanban'"
    [embeddedMode]="embeddedMode"
    [personalMode]="personalMode">
  </app-tasks-kanban-board>
</ng-container>
