<dx-popup
        [(visible)]="dialogOpen"
        width="75vw"
        [height]="'auto'"
        [fullScreen]="false"
        [hideOnOutsideClick]="false"
        [showCloseButton]="false"
        [shading]="true"
        shadingColor="rgba(0, 0, 50, 0.5)"
        [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'material-icons md-24 how_to_vote', elementAttr: { class: 'button-icon' } }
    },
    { text: 'Abstimmungsstatus', location: 'before', toolbar: 'top' },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: close
    }
  ]"
>
    <div *dxTemplate="let data of 'content'">
        <app-ballot-data-include [currentDocument]="currentDocument" [ballotDocuments]="ballotDocuments">
        </app-ballot-data-include>
    </div>
</dx-popup>
