<dx-popup
        [(visible)]="showFeedback"
        [minWidth]="750"
        width="50vw"
        height="auto"
        [fullScreen]="false"
        [dragEnabled]="true"
        [showCloseButton]="false"
        [showTitle]="false"
        [shading]="true"
        shadingColor="rgba(0, 0, 50, 0.5)"
        [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: { type: 'normal', icon: 'material-icons md-24 feedback', elementAttr: { class: 'button-icon' } }
    },
    { text: 'Feedback', location: 'before', toolbar: 'top' },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      visible: sendButtonVisible,
      options: { icon: 'message', text: 'Senden' },
      onClick: sendFeedback
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'close', text: sendButtonVisible ? 'Abbrechen' : 'Schließen' },
      onClick: closeFeedback
    }
  ]">
    <div *dxTemplate="let data of 'content'" class="feedback-form">
        <div *ngIf="showFeedbackForm; else success">
            <dx-form #feedbackForm [formData]="currentFeedback">
                <dxi-item
                        dataField="subject"
                        [label]="{ visible: false }"
                        editorType="dxSelectBox"
                        [editorOptions]="{
            items: subjects,
            placeholder: 'Bitte wählen Sie ein Thema aus',
            onValueChanged: onSubjectChange
          }">
                </dxi-item>
                <dxi-item dataField="isHtmlBody" [label]="{ text: 'HTML-Inhalt verwenden' }"
                          editorType="dxSwitch"></dxi-item>
                <dxi-item
                        [visible]="!currentFeedback.isHtmlBody"
                        dataField="body"
                        [label]="{ visible: false }"
                        editorType="dxTextArea"
                        [editorOptions]="{ placeholder: 'Ihre Nachricht', height: 300 }">
                    <dxi-validation-rule type="required"
                                         message="Sie müssen eine Nachricht angeben."></dxi-validation-rule>
                </dxi-item>
                <dxi-item
                        [visible]="currentFeedback.isHtmlBody"
                        dataField="body"
                        [label]="{ visible: false }"
                        [template]="'htmlEditorTemplate'">
                    <dxi-validation-rule type="required"
                                         message="Sie müssen eine Nachricht angeben."></dxi-validation-rule>
                </dxi-item>
                <dxi-item
                        dataField="attachments"
                        [label]="{ visible: false }"
                        editorType="dxFileUploader"
                        [editorOptions]="{
            multiple: true,
            allowCanceling: true,
            uploadMode: 'useButtons',
            readyToUploadMessage: ''
          }">
                </dxi-item>
                <div *dxTemplate="let data of 'htmlEditorTemplate'">
                    <dx-html-editor
                            [(value)]="currentFeedback.body"
                            allowSoftLineBreak="true"
                            height="300"
                            placeholder="Ihre Nachricht">
                        <dxo-toolbar [items]="editorToolbarItems" [multiline]="false"></dxo-toolbar>
                    </dx-html-editor>
                </div>
            </dx-form>
        </div>

        <ng-template #success>
            <p class="feedback_success_msg">
                Ihre Nachricht wurde an {{ feedbackEmail | async }} versendet.<br/>
                Sie können diesen Dialog nun schließen...
            </p>
        </ng-template>

    </div>
</dx-popup>
