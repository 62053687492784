import { Pipe, PipeTransform } from '@angular/core';
import { IDocument } from '@interfaces/siam';
import { DocumentService } from '@services/document.service';

@Pipe({
  name: 'hasAttachment'
})
export class HasAttachmentPipe implements PipeTransform {

  constructor(private docuemtService: DocumentService) {
  }

  transform(document: IDocument): boolean {
    return this.docuemtService.hasAttachment(document);
  }
}
