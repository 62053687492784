import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { ILogLevel, ILogServer, TLogLevel } from '@interfaces/siam';

interface IDownload {
  zip: Blob;
  fileName: string;
}

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  private readonly apiBaseUrl: string;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.apiBaseUrl = `${this.baseUrl}api/log`;
  }

  deleteLogs(): Observable<ILogServer[]> {
    return this.http.delete<ILogServer[]>(this.apiBaseUrl).pipe(
      tap({
        next: () => {
          this.logger.info('Delete all logs from service');
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des Logs: {@error}', error);
        }
      })
    );
  }

  download(from: Date, to?: Date, forUser?: string, maxEntries?: number, level?: string): Observable<IDownload> {
    const formData = new FormData();
    formData.append('Content-Type', 'application/x-zip-compressed');
    formData.append('Accept', 'application/json');

    const params = this.getParams(from, to, forUser, maxEntries, level).set('allowNoPassword', true);

    return this.http
      .get<Blob>(`${this.apiBaseUrl}/download`, {
        observe: 'response',
        params,
        responseType: 'blob' as 'json'
      })
      .pipe(
        tap({
          next: () => {
            this.logger.info('Download logs from service');
          },
          error: error => {
            this.logger.error('Fehler beim Download des Logs: {@error}', error);
          }
        }),
        map(response => {
          if (!response.body) {
            return null;
          }
          const contentDisposition = response.headers.get('content-disposition');
          return {
            zip: new Blob([response.body], { type: response.headers.get('Content-Type') }),
            fileName: contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim()
          };
        })
      );
  }

  getLogs(from: Date, to?: Date, forUser?: string, maxEntries = 1000): Observable<ILogServer[]> {
    return this.http.get<ILogServer[]>(this.apiBaseUrl, { params: this.getParams(from, to, forUser, maxEntries) }).pipe(
      tap({
        next: () => {
          this.logger.info('Get logs from service');
        },
        error: error => {
          this.logger.error('Fehler beim Abrufen des Logs: {@error}', error);
        }
      })
    );
  }

  getLogLevel(): Observable<ILogLevel> {
    return this.http.get<ILogLevel>(`${this.apiBaseUrl}/level`).pipe(
      tap({
        next: () => {
          this.logger.info('Get log-level from service');
        },
        error: error => {
          this.logger.error('Fehler beim Abrufen des log-levels: {@error}', error);
        }
      })
    );
  }

  getDefaultLogLevel(): Observable<TLogLevel> {
    return this.http.get<TLogLevel>(`${this.apiBaseUrl}/level/standard`).pipe(
      tap({
        next: () => {
          this.logger.info('Get default log-level from server');
        },
        error: error => {
          this.logger.error('Fehler beim Abrufen des Default log-level: {@error}', error);
        }
      })
    );
  }

  setLogLevel(logLevel: ILogLevel): Observable<ILogLevel> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.put<ILogLevel>(`${this.apiBaseUrl}/level`, logLevel, { headers }).pipe(
      tap({
        next: () => {
          this.logger.info('put log-level from service');
        },
        error: error => {
          this.logger.error('Fehler beim speichern des log-level: {@error}', error);
        }
      })
    );
  }

  resetLogLevel(): Observable<ILogLevel> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.put<ILogLevel>(`${this.apiBaseUrl}/level/reset`, { headers }).pipe(
      tap({
        next: () => {
          this.logger.info('reset log-level from service');
        },
        error: error => {
          this.logger.error('Fehler beim wiederherstellen des log-levels: {@error}', error);
        }
      })
    );
  }

  private getParams(from: Date, to?: Date, forUser?: string, maxEntries?: number, level?: string): HttpParams {
    const fromDate = from && from.toISOString();
    const toDate = to && to.toISOString();
    let params = new HttpParams();

    if (fromDate) {
      params = params.set('from', fromDate);
    }

    if (toDate) {
      params = params.set('to', toDate);
    }

    if (level) {
      params = params.set('levels', level);
    }

    params = params.set('maxEntries', maxEntries || 1000);

    if (forUser) {
      params = params.set('forUser', forUser);
    }
    return params;
  }
}
