import { Pipe, PipeTransform } from '@angular/core';
import { IAttachment } from '@interfaces/siam';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Pipe({
  name: 'getAttachments'
})
export class GetAttachmentsPipe implements PipeTransform {
  transform(
    attachmentChanged$: BehaviorSubject<boolean>,
    dataField: string,
    formData: Record<string, unknown>
  ): Observable<IAttachment[]> {
    return attachmentChanged$.pipe(map(() => (formData[dataField] as IAttachment[]) || ([] as IAttachment[])));
  }
}
