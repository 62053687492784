import { IAttachment } from '@interfaces/siam';

export class UserNotification {
  id?: string;
  validSince: string;
  validUntil: string;
  ietfLanguageTag?: string;
  subject: string;
  htmlContent: string;
  attachment?: IAttachment | File;

  constructor(init?: Partial<UserNotification>) {
    this.validSince = init ? init.validSince : null;
    this.validUntil = init ? init.validUntil : null;
    this.ietfLanguageTag = init ? init.ietfLanguageTag : '';
    this.subject = init ? init.subject : '';
    this.htmlContent = init ? init.htmlContent : '';
    this.attachment = init ? init.attachment : null;
    if (init && init.id) {
      this.id = init.id;
    }
  }
}
