import { SiamList } from '@interfaces/siamList';
import { Action } from '@ngrx/store';

interface IPayload {
  list: SiamList;
}

export enum ListActionTypes {
  CreateList = '[List] Create List',
  Delete = '[List] Delete All'
}

export class CreateList implements Action {
  public readonly type = ListActionTypes.CreateList;

  constructor(public payload: IPayload) {
  }
}

export class Delete implements Action {
  public readonly type = ListActionTypes.Delete;
}

export type ListActions = CreateList | Delete;
