<div *ngFor="let label of selectedLabels">
  <span *ngIf="label" class="label" [ngStyle]="{ 'background-color': label.color }">
    {{ label.name }}
  </span>
</div>

<dx-popover
  #popOverLabels
  title="Labels:"
  position="top"
  [target]="targetLabels"
  [(visible)]="labelsDropDownListVisible"
  [showTitle]="true"
  [shading]="true"
  shadingColor="rgba(0, 0, 50, 0.5)"
  (onHidden)="onHidden()">
  <div *dxTemplate="let data of 'content'">
    <dx-list
      [elementAttr]="{ id: 'labelsList' }"
      keyExpr="id"
      displayExpr="name"
      selectionMode="multiple"
      searchExpr="id"
      searchMode="contains"
      itemTemplate="itemTemplate"
      [items]="globalLabels"
      [(selectedItems)]="selectedLabels"
      [showSelectionControls]="true"
      [searchEnabled]="false"
      (onSelectionChanged)="onSelectionChanged()">
      <div *dxTemplate="let item of 'itemTemplate'">
        <span
          class="label-list-icon"
          [ngStyle]="{
            'background-color': item.color,
            width: '20px',
            height: '20px',
            display: 'inline-block',
            margin: '0 5px'
          }"></span>
        <span>{{ item.name }}</span>
      </div>
    </dx-list>
  </div>
</dx-popover>
