<dx-data-grid
    #gridSignature
    id="signatureGrid"
    [elementAttr]="{ class: 'document-grid' }"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [allowColumnReordering]="true"
    [rowAlternationEnabled]="false"
    [allowColumnResizing]="true"
    [showBorders]="true"
    (onCellHoverChanged)="onShowToolTipColumn($event)"
    (onCellClick)="onShowFullComment($event)">
    <div *dxTemplate="let cell of 'signatureTemplate'">
        <img onContextMenu="return false;"
             style="max-height: 60px"
             *ngIf="cell?.data?.historyEntry?.entry?.variables?.signature?.length"
             [src]="cell.data.historyEntry.entry.variables.signature[0] | fileDownloadLink : 'blobUrl' | async"
             alt=""/>
    </div>

    <div *dxTemplate="let cell of 'iconTemplate'">
        <i class="material-icons md-18 dx-icon-doc"
           style="cursor: pointer"
           id="subformSignatureName-{{ cell.rowIndex }}"
           *ngIf="cell.data.historyEntry.isDocument"
           (click)="cell.data.historyEntry.onOpenDocument()">
        </i>
        <dx-tooltip target="#subformSignatureName-{{ cell.rowIndex }}"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend">
            <div *dxTemplate="let data = data; of: 'content'">
                {{
                cell.data.historyEntry.subformName
                    ? 'Dialogformular: ' + cell.data.historyEntry.subformName
                    : 'ausgefülltes Workflowformular'
                }}
            </div>
        </dx-tooltip>
    </div>
</dx-data-grid>

<dx-popover #popOverCreatorColumn id="popOverCreatorColumn" [(target)]="popoverElement">
    <div *dxTemplate="let data of 'content'">
        <span [innerHtml]="popoverText | safeHtml"></span>
    </div>
</dx-popover>

<!-- Benutzerangaben zum Workflowschritt Popup -->
<dx-popup
        title="Benutzerangaben zum Workflowschritt"
        width="90vw"
        height="auto"
        maxHeight="100%"
        [fullScreen]="false"
        [showCloseButton]="false"
        [visible]="this.edgeDocument"
        [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: onHideFormular
    }
  ]">
    <dx-scroll-view>
        <app-document-include
                *ngIf="!!this.edgeDocument"
                [currentDocument]="edgeDocument"
                [editMode]="'ReadOnly'">
        </app-document-include>
    </dx-scroll-view>
</dx-popup>
