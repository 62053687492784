import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEventTask, ITask, SiamListItem } from '@interfaces/siam';

@Component({
  selector: 'app-tasksboard-entry',
  templateUrl: './tasks-entry.component.html',
  styleUrls: ['./tasks-entry.component.scss']
})
export class TasksEntryComponent {

  @Input() taskPriorities: SiamListItem[];
  @Input() task: ITask;
  @Output() taskEntryResult: EventEmitter<IEventTask> = new EventEmitter<IEventTask>();

  constructor() {
  }

  public select(_event?: any): void {
    const eventObject: IEventTask = {
      command: 'select',
      object: this.task
    };
    this.taskEntryResult.emit(eventObject);
  }

  public deleteTask(event?: any): void {
    const eventObject: IEventTask = {
      command: 'delete',
      object: this.task
    };
    this.taskEntryResult.emit(eventObject);

    try {
      event.cancelBubble = true;
      event.stopPropagation();
    } catch (_exception) {
    }
  }

  public getPriorityLabel(value: string): string {
    if (this.taskPriorities) {
      const index = this.taskPriorities.findIndex(v => v.value === value);
      if (index !== -1) {
        return this.taskPriorities[index].label;
      }
    }
    return '';
  }
}
