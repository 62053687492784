<dx-popup
  width="50vw"
  [height]="'auto'"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [showTitle]="true"
  [fullScreen]="isFullscreen"
  [(visible)]="dialogTaskVisible"
  (onShown)="onShownPopup()"
  [shading]="true"
  shadingColor="rgba(0, 0, 50, 0.3)"
  [toolbarItems]="[
  { widget: 'dxButton', location: 'before', toolbar: 'top', visible: true,
    options: {type: 'normal', icon: 'material-icons md-24 ic-task', elementAttr:{class: 'button-icon'}} },
  { text: 'Aufgabe', location: 'before', toolbar: 'top' },
  {
  widget: 'dxButton',
  location: 'after',
  toolbar: 'top',
  visible: true,
  options: {
  type: 'normal',
  icon: 'fullscreen',
  hint: 'Vollbildmodus umschalten'
  },
  onClick: toggleFullscreen
  },
  {
  widget: 'dxButton', location: 'after', toolbar: 'bottom',
  options: {text: 'Bearbeiten', icon: 'check'},
  onClick: openTask
  },
  {
  widget: 'dxButton', location: 'after', toolbar: 'bottom',
  options: {text: 'Abbrechen', icon: 'close'},
  onClick: cancelTask
  }]">

  <div *dxTemplate="let data of 'content'">
    <app-component-notification></app-component-notification>
    <dx-form #taskForm id="taskForm" labelLocation="left" [showColonAfterLabel]="false" [showRequiredMark]="false"
             [formData]="task" validationGroup="taskForm" readOnly="true">

      <dxi-item itemType="group" colCount="3">

        <dxi-item itemType="group" colCount="1" [colSpan]="2">

          <dxi-item dataField="subject" [label]="{text: ' '}" editorType="dxTextBox"
                    [editorOptions]="{stylingMode: 'filled', placeholder: 'Inhalt'}">
            <dxi-validation-rule type="required" message="Sie müssen ein Thema angeben."></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="body" [label]="{ text: ' '}" editorType="dxTextArea" [editorOptions]="{stylingMode: 'filled', height: '250',
            placeholder: 'Geben Sie hier die Aufgabenbeschreibung an.'}">
            <!-- <dxi-validation-rule type="required" message="Sie müssen den Aufgabeninhalt angeben."></dxi-validation-rule> -->
          </dxi-item>
        </dxi-item>

        <dxi-item itemType="group" colCount="1" [colSpan]="1">

          <dxi-item dataField="enddate" [label]="{ text: ' '}" editorType="dxDateBox"
                    [editorOptions]="{stylingMode: 'filled', placeholder: 'Frist', min: currentDate}">
          </dxi-item>

          <dxi-item dataField="priority" [label]="{ text: ' '}" editorType="dxSelectBox" [editorOptions]="{stylingMode: 'filled',
            placeholder: 'Priorität',
            searchEnabled: true,
            dataSource: taskPriorities | async,
            displayExpr: 'label',
            valueExpr: 'value'}">
          </dxi-item>

          <dxi-item dataField="chair" [label]="{ text: ' '}" editorType="dxTagBox" [editorOptions]="{stylingMode: 'filled',
            placeholder: 'Verantwortlich',
            searchEnabled: true,
            dataSource: usersAndRolesDataSource,
            itemTemplate: 'namesTemplate',
            displayExpr: 'name',
            valueExpr: 'id'}">
          </dxi-item>
        </dxi-item>

      </dxi-item>
      <!-- Names Template -->
      <span *dxTemplate="let nameData of 'namesTemplate'">
        <img class="user-avatar-name" src="{{nameData.avatar}}" alt="">{{nameData.name}}
      </span>

    </dx-form>
  </div>
</dx-popup>
