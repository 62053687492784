import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  LabelsIncludeComponent
} from 'src/app/application/components/forms/subforms/labels-include/labels-include.component';
import { ICategory, IDynamicGridSettingsToolbar, IUser, TDisplayMode, TNavigationSource } from '@interfaces/siam';
import { DocumentService } from '@services/document.service';
import { ListsService } from '@services/lists.service';
import { LoggerService } from '@services/logger.service';
import { IDxEditorToolbarItem } from '@interfaces/devextreme';

@Component({
  selector: 'app-tasksboard',
  templateUrl: './tasks-board.component.html',
  styleUrls: ['./tasks-board.component.scss']
})
export class TasksBoardComponent implements OnDestroy {
  @ViewChild(LabelsIncludeComponent) labelsInclude: LabelsIncludeComponent;

  @Input() displayMode: TDisplayMode;
  @Input() showDisplayModeSwitch = true;
  @Input() embeddedMode = false;
  @Input() personalMode = false;
  @Input() navigationSource: TNavigationSource;
  @Input() user: IUser;

  dynamicListButtons: IDxEditorToolbarItem[] = [];
  dynamicDiagrammButtons: IDxEditorToolbarItem[] = [];
  documentType = 'task';
  isShowKanban = false;
  isShowDiagramm = false;

  #destroyable = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private listsService: ListsService,
    private logger: LoggerService
  ) {
    this.logger.debug('tasks-board.component gestartet.');
    this.listsService.getListItemByType(this.documentType)
      .pipe(takeUntil(this.#destroyable))
      .subscribe(entry => {
        const info = entry.properties as ICategory;
        let toolbarSettings: IDynamicGridSettingsToolbar = null;
        if (info.settings) {
          toolbarSettings = info.settings.toolbar;
        }

        if (toolbarSettings?.isShowKanban === undefined) {
          this.isShowKanban = true;
        } else {
          this.isShowKanban = !!toolbarSettings?.isShowKanban;
        }

        this.isShowDiagramm = !!toolbarSettings?.isShowKanban;

        if (this.displayMode === 'DX-Kanban' && !this.isShowKanban) {
          this.displayMode = 'Liste';
        }

        const buttons = {
          kanban: {
            options: {
              elementAttr: { id: 'kanbanBtn' },
              icon: 'columnfield',
              text: 'Kanbanansicht',
              hint: 'Kanbanansicht',
              height: '35px',
              stylingMode: 'outlined',
              onClick: this.changeModeToKanban
            }
          },
          diagramm: {
            options: {
              elementAttr: { id: 'diagrammBtn' },
              icon: 'chart',
              text: 'Diagrammansicht',
              hint: 'Diagrammansicht',
              height: '35px',
              stylingMode: 'outlined',
              onClick: this.changeModeToDiagramm
            }
          },
          list: {
            options: {
              elementAttr: { id: 'listBtn' },
              icon: 'detailslayout',
              text: 'Tabellenansicht',
              hint: 'Tabellenansicht',
              height: '35px',
              stylingMode: 'outlined',
              onClick: this.changeModeToList
            }
          }
        };

        if (this.isShowKanban) {
          this.dynamicListButtons.push(buttons.kanban);
          this.dynamicDiagrammButtons.push(buttons.kanban);
        }

        if (this.isShowDiagramm) {
          this.dynamicListButtons.push(buttons.diagramm);
          this.dynamicDiagrammButtons.push(buttons.list);
        }
      });
  }

  ngOnDestroy(): void {
    this.#destroyable.next();
    this.#destroyable.complete();
  }

  changeModeToKanban = (): void => {
    this.displayMode = 'DX-Kanban';
  }

  changeModeToDiagramm = (): void => {
    this.displayMode = 'Diagramm';
  }

  changeModeToList = (): void => {
    this.displayMode = 'Liste';
  }
}
