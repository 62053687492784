import { Component, Input, ViewChild } from '@angular/core';
import { BallotDataIncludeComponent } from '../../forms/subforms/ballot-data-include/ballot-data-include.component';
import { IDocument, IWorkflowDocument } from '@interfaces/siam';

@Component({
  selector: 'app-ballot-info',
  templateUrl: './ballot-info.component.html',
  styleUrls: ['./ballot-info.component.scss']
})
export class BallotInfoComponent {
  @Input() currentDocument: IDocument;
  @Input() ballotDocuments: IWorkflowDocument[];
  @ViewChild(BallotDataIncludeComponent, { static: false }) ballotDataComponent: BallotDataIncludeComponent;

  dialogOpen = false;

  show() {
    this.dialogOpen = true;
  }

  close = () => {
    this.dialogOpen = false;
  }
}
