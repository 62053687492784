<app-dynamic-list
  [documentType]="documentType"
  [embeddedMode]="false"
  [noState]="true"
  [allowDeleting]="hasDeletePermission"
  [additionalSettings]="additionalSettings"
  [listener]="dynamicGridListener"
  (emitter)="eventDynamicListResult($event)"
  [buttons]="dynamicListButtons">
</app-dynamic-list>

<dx-action-sheet
  target="#{{createChildButtonId}}"
  width="'auto'"
  [showTitle]="false"
  [usePopover]="true"
  [(visible)]="actionSheetChildrenVisible"
  [items]="creatableChildTemplates"
  (onItemClick)="createChildProcess($event)"
  itemTemplate="templateSheet">
  <div *dxTemplate="let item of 'templateSheet'">
    <div class="action-sheet-link">
      <i class="material-icons mr-1 md-18">{{ item.icon }}</i>
      <a>{{ item.label }}</a>
      <span class="action-sheet-link__name">{{ item.source }}</span>
    </div>
  </div>
</dx-action-sheet>

<dx-tooltip target="#create" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
  <div *dxTemplate="let data = data; of: 'content'">Dokument erstellen</div>
</dx-tooltip>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Vorlagenliste -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  [(visible)]="popupFreeChildrenListVisible"
  width="75vw"
  [height]="600"
  [showTitle]="true"
  [title]="popupFreeChildrenListTitle"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="true"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'add', text: 'Übernehmen', visible: 'true' },
      onClick: popupFreeChildrenListOk
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
      onClick: popupFreeChildrenListCancel
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <app-dynamic-list
        [documentType]="documentType"
        [embeddedMode]="false"
        [noState]="true"
        [selectionMode]="selectionMode"
        [additionalSettings]="dynamicGridSettings"
        (emitter)="eventDynamicListResultFreeChildren($event)">
      </app-dynamic-list>
    </dx-scroll-view>
  </div>
</dx-popup>
<app-document-editor
  [parentDocument]="currentDocument"
  (dialogResult)="dialogResult($event)">
</app-document-editor>
