import { Pipe, PipeTransform } from '@angular/core';
import { createPermissionTargetWithoutCompositeId, isCompositeId } from '@factories/document.factory';
import { isHasProperty } from '@factories/helpers';
import { IPermissionTarget } from '@interfaces/siam';

@Pipe({
  name: 'find'
})
export class FindPipe implements PipeTransform {
  /**
   * Find the item from array and send true or false return
   * fields['black','white']
   * e.g. {{fields|find:'white'}}
   *
   * @param array
   * @param target
   */
  transform(
    array: number | number[] | string[] | IPermissionTarget[] | IPermissionTarget[][],
    target: number | string | IPermissionTarget | IPermissionTarget[]
  ): boolean {
    if (!Array.isArray(array)) {
      return false;
    }
    if (Array.isArray(target)) {
      const newTarget = target.map(t => {
        if (t.type && t.targetId) {
          return {
            type: t.type,
            targetId: t.targetId
          };
        }
        return t;
      });
      const newArray = (array as IPermissionTarget[][]).map(x =>
        x.map(t => {
          if (t.type && t.targetId) {
            return {
              type: t.type,
              targetId: t.targetId
            };
          }
          return t;
        })
      );
      return !!newArray.find(x => JSON.stringify(newTarget) === JSON.stringify(x));
    }
    if (typeof target === 'object') {
      return !!(array as unknown[])
        .map(x => {
          if (isHasProperty(x, 'type') && isHasProperty(x, 'targetId')) {
            return {
              type: (x as IPermissionTarget).type,
              targetId: (x as IPermissionTarget).targetId
            } as IPermissionTarget;
          }
          if (isCompositeId(x as string)) {
            return createPermissionTargetWithoutCompositeId(x as string);
          }
          return x as IPermissionTarget;
        })
        .find(x => x.type === target.type && x.targetId === target.targetId);
    }

    if (typeof target === 'string') {
      return !!(array as string[]).find(x => x === target);
    }
    if (typeof target === 'number') {
      if ((array as number[]).findIndex(x => x === target) > -1) {
        return true;
      } else {
        return false;
      }
    }
    return !!(array as string[]).find(x => x === target);
  }
}
