import { Pipe, PipeTransform } from '@angular/core';
import { TagsService } from '@services/tags.service';

@Pipe({
  name: 'getType'
})
export class GetTypePipe implements PipeTransform {
  constructor(private tagsService: TagsService) { }
  transform(tags: string[], args?: string): string {
    const tagType = `app:document-type:`;
    const result = tags.find(tag => tag.startsWith(tagType));
    if (result) {
      if (args === 'label') {
        return this.tagsService.getTypeLabel(result);
      } else {
        return result.split(new RegExp(`^${tagType}`))[1] || '';
      }
    } else {
      return null;
    }
  }

}
