import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { ListsService } from '@services/lists.service';
import { DocumentService } from '@services/document.service';
import { ConfigurationService } from '@services/configuration.service';
import { IDxDropDownItem, IDxEditorToolbarItem } from '@interfaces/devextreme';
import {
  ICategory,
  IDocument,
  IDocumentsSearch,
  IDynamicDocumentInfo,
  IDynamicGridSettings
} from '@interfaces/siam';
import { takeUntil } from 'rxjs/operators';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DxPieChart from 'devextreme/viz/pie_chart';
import Box from 'devextreme/ui/box';
import { LoadPanelService } from '@services/load-panel.service';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';

interface IGroupedData extends Record<string, unknown> {
  count: number;
  items: IDocument[];
}

interface IDxPiePointObject {
  hide(): void;

  isVisible(): boolean;

  show(): void;
}

@Component({
  selector: 'app-dynamic-diagramm',
  templateUrl: './dynamic-diagramm.component.html',
  styleUrls: ['./dynamic-diagramm.component.scss']
})
export class DynamicDiagrammComponent implements OnInit, OnDestroy {
  @Input() buttons: IDxEditorToolbarItem[] = [];
  @Input() documentType: Observable<string> | string;
  @Input() embeddedMode = false;
  @Input() personalMode = false;

  chartDataStore: ArrayStore<IDocument, string>; // for storing the data
  chartData: DataSource; // aggregated data for connecting to widget
  readonly dateRangeValues: IDxDropDownItem<number>[] = [
    { value: 0, name: 'Nur Aktuelle', icon: 'icon ion-md-alarm' },
    { value: 3, name: '3 Monate', icon: 'icon ion-md-alarm' },
    { value: 6, name: '6 Monate', icon: 'icon ion-md-alarm' },
    { value: 12, name: '12 Monate', icon: 'icon ion-md-alarm' },
    { value: -1, name: 'Gesamt', icon: 'icon ion-md-timer' }
  ];
  documentInfo: IDynamicDocumentInfo;
  selectedDateRange = 0;
  settings: IDynamicGridSettings;

  #destroyable$ = new Subject<void>();
  #documentType: string = null;

  constructor(
    private configurationService: ConfigurationService,
    private documentService: DocumentService,
    private listsService: ListsService,
    private loadPanelService: LoadPanelService
  ) {
    this.chartDataStore = new ArrayStore({ key: 'id', data: [] as IDocument[] });
    this.chartData = new DataSource({
      store: this.chartDataStore,
      postProcess: (groupedData: IGroupedData[]) => {
        groupedData.forEach(item => (item.count = item.items.length));
        return groupedData;
      },
    });

    //  IE 11 or Edge browsers FallbackLayoutStrategy use flex
    Box.defaultOptions({
      options: {
        _layoutStrategy: 'flex',
      }
    });
  }

  private static toggleVisibility(item: IDxPiePointObject): void {
    if (item.isVisible()) {
      item.hide()
    } else {
      item.show();
    }
  }

  ngOnInit(): void {
    this.loadPanelService.show();
    let stream$: Observable<string>;
    if (typeof this.documentType === 'string') {
      stream$ = of(this.documentType);
    } else {
      stream$ = this.documentType;
    }
    stream$.pipe(
      switchMap(type => {
        this.#documentType = type;
        return this.listsService.getListItemByType(type);
      }),
      takeUntil(this.#destroyable$)
    ).subscribe({
      next: (entry) => {
        if (entry) {
          const info = entry.properties as ICategory;
          this.documentInfo = {
            tag: entry.value as string,
            icon: info.icon,
            name: entry.label
          };
          this.settings = info.settings;
        }
        this.doQuery();
      }
    });
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  onPieChartPointClick(e: { target: IDxPiePointObject }): void {
    DynamicDiagrammComponent.toggleVisibility(e.target);
  }

  onPieChartLegendClick(e: { target: number | string | Date; component: DxPieChart }): void {
    const arg = e.target;
    const item = (e.component.getAllSeries()[0].getPointsByArg(arg)[0] as unknown) as IDxPiePointObject;
    DynamicDiagrammComponent.toggleVisibility(item);
  }

  /**
   * Called on change date range in corresponding drop down list of toolbar
   *
   * @param e
   */
  onSelectDateRange = (e: ItemClickEvent): void => {
    this.selectedDateRange = (e.itemData as IDxDropDownItem<number>).value;
    this.doQuery();
  }

  private doQuery(): void {
    this.loadPanelService.show();
    const properties: IDocumentsSearch = {
      tag: this.documentInfo.tag,
      documentFields: ['tags', 'documentWorkflowDocument.currentStatusLabel', 'creation.timestamp'],
      isIncludeLists: false,
      excludeTags: this.settings?.excludeTags,
      excludeStatus: this.settings?.excludeStatus
    };

    if (this.settings?.toolbar.isShowDateFilter && this.selectedDateRange !== -1) {
      const fromDate = new Date();
      fromDate.setMonth(fromDate.getMonth() - this.selectedDateRange);
      properties.dateFrom = fromDate;
      properties.dateField = this.settings?.toolbar.dateField || null;
    }

    this.chartDataStore.clear();

    this.documentService.documentsSearch(properties).pipe(
      takeUntil(this.#destroyable$)
    ).subscribe({
      next: (response) => {
        response.documents.forEach(doc => {
          doc.fields = {
            state: { value: doc.documentWorkflowDocument.currentStatusLabel }
          }

          void this.chartDataStore.insert(doc);
        });
        void this.chartData.group('fields.state.value');
        void this.chartData.reload();
        this.loadPanelService.hide();
      }
    });
  }
}
