import { DocumentService } from '@services/document.service';
import { TemplateService } from '@services/template.service';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { LoggerService } from '@services/logger.service';
import { IDxFormItemTemplate } from '@interfaces/devextreme';
import {
  IDocument,
  IHistoryField,
  IPermissionTarget,
  ISignatureColumn,
  IWorkflowDocument
} from '@interfaces/siam';
import { lastValueFrom, Subject, switchMap, takeUntil } from 'rxjs';
import { NotifyService } from '@services/notify.service';
import { CellClickEvent, CellHoverChangedEvent, Column } from 'devextreme/ui/data_grid';
import { DxDataGridComponent, DxPopoverComponent } from 'devextreme-angular';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { create } from '@factories/user.factory';
import { clone } from '@factories/helpers';
import { IEdgeServer } from '@interfaces/edgeServer';

interface ISignature {
  timestamp: Date;
  user: string;
  selectedEdge: IEdgeServer;
  variables?: { [KEY: string]: string };
  entry?: IHistoryField;
  isDocument?: boolean;
  onOpenDocument?: () => void;
  subformName?: string;
}

interface ISignatureDataSource {
  historyEntry: ISignature;
}

@Component({
  selector: 'app-signature-include',
  templateUrl: './signature-include.component.html',
  styleUrls: ['./signature-include.component.scss']
})
export class SignatureIncludeComponent implements OnInit, OnChanges {
  @ViewChild('gridSignature', { static: true }) gridSignature: DxDataGridComponent;
  @ViewChild('popOverCreatorColumn') popOverCreatorColumn: DxPopoverComponent;

  @Input() currentDocument: IDocument;
  @Input() workflowDocument: IWorkflowDocument;
  @Input() fieldContainer: IDxFormItemTemplate;

  dataSource: ISignatureDataSource[] = [];
  columns: Record<string, boolean> = {};
  documentHasSignature = false;
  popoverElement: HTMLElement;
  popoverText: string;
  commentColumnClass = 'comment-column comment-column--limited';
  edgeDocument: IDocument = null;

  #destroyable$ = new Subject<void>();

  constructor(
    private logger: LoggerService,
    private templateService: TemplateService,
    private documentService: DocumentService,
    private nameDataPipe: GetNameDataPipe
  ) {
  }

  ngOnInit(): void {
    this.logger.debug('=== app-signature-include started.');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workflowDocument?.currentValue) {
      void this.setData();
    }
  }

  async setData(): Promise<void> {
    const history = this.workflowDocument?.fields?.history;
    if (history && Array.isArray(history.value)) {
      let dataArray: ISignatureDataSource[] = [];
      for (const entry of history.value as IHistoryField[]) {
        if (entry.variables?.is_workflow_signature) {
          const selectedEdge = this.workflowDocument.workflow.edges.find(e => e.edgeId === entry.selectedEdge.edgeId);
          // might be that this edge does not exists anymore in the workflow
          if (!selectedEdge) {
            NotifyService.component.warn(
              'Die Workflow-Konfiguration wurde geändert. Hierdurch kann es zu einer inkorrekten Anzeige der Historie kommen.'
            );
            continue;
          }
          const user = entry.user ? ({ targetId: entry.user.userId, type: 'user' } as IPermissionTarget) : null;
          const _userData = await lastValueFrom(this.nameDataPipe.transform(user, entry.user.logonUserId));
          const creatorNameWithAvatar = _userData.avatarName;

          let assignees = '';
          for (const assignee of entry.assignees) {
            const _assigneeData = await lastValueFrom(this.nameDataPipe.transform(assignee));
            if (_assigneeData) {
              assignees = assignees?.length
                ? assignees.concat(', ').concat(_assigneeData.avatarName)
                : _assigneeData.avatarName;
            }
          }

          let isDocument = false;
          let subformName = '';
          let userInputTemplateLink: ITemplateServer = null;
          if (selectedEdge?.userInputTemplate?.layouts?.length) {
            subformName = selectedEdge.userInputTemplate?.caption;
            userInputTemplateLink = selectedEdge.userInputTemplate;
            isDocument = true;
          } else if (selectedEdge?.userInputTemplateLink) {
            subformName = selectedEdge.userInputTemplateLink?.caption;
            isDocument = true;
          } else if (selectedEdge?.userInputTemplateLinkId) {
            userInputTemplateLink = await lastValueFrom(
              this.templateService.getTemplate(selectedEdge?.userInputTemplateLinkId)
            );
            subformName = userInputTemplateLink?.caption;
            isDocument = true;
          }
          const onOpenDocument = () => {
            if (!selectedEdge) {
              return;
            }
            if (selectedEdge.userInputTemplate?.layouts?.length) {
              this.assignVariables(this.documentService.create(selectedEdge.userInputTemplate), entry.variables);
            } else if (userInputTemplateLink) {
              this.assignVariables(this.documentService.create(userInputTemplateLink), entry.variables);
            } else if (selectedEdge.userInputTemplateLinkId) {
              this.templateService
                .getTemplate(selectedEdge.userInputTemplateLinkId)
                .pipe(
                  switchMap(templateServer => this.documentService.createDocumentByTemplateId(templateServer)),
                  takeUntil(this.#destroyable$)
                )
                .subscribe(doc => {
                  this.assignVariables(doc, entry.variables);
                });
            }
          };
          const historyEntry: ISignature = {
            timestamp: entry.timestamp,
            user: creatorNameWithAvatar,
            selectedEdge: entry.selectedEdge,
            isDocument,
            onOpenDocument,
            subformName,
            variables: {
              kommentar: entry.variables.kommentar as string,
              assignee: assignees
            },
            entry
          };
          const data: ISignatureDataSource = {
            historyEntry
          };
          dataArray.push(data);
        }
        if (entry.targetVertex?.flags?.includes('resetWorkflowSignatures')) {
          dataArray = [];
        }
      }
      this.documentHasSignature = !!dataArray.length;
      this.dataSource = dataArray;

      void this.gridSignature?.instance?.beginUpdate();
      this.setColumns();
      void this.gridSignature?.instance?.endUpdate();
    }
  }

  onHideFormular = (): void => {
    this.edgeDocument = null;
  };

  onShowFullComment(e: CellClickEvent<string>): void {
    if (e.column.dataField === 'historyEntry.variables.kommentar' && e.text) {
      e?.cellElement?.classList.toggle('comment-column--limited');
    }
  }

  async onShowToolTipColumn(e: CellHoverChangedEvent<ISignatureDataSource>): Promise<void> {
    if (e.rowType === 'data' && e.column.dataField === 'historyEntry.user' && e.eventType === 'mouseover') {
      if (e?.data?.historyEntry?.entry?.user?.logonUserId !== e?.data?.historyEntry?.entry?.user?.userId) {
        const logonUser = create();
        logonUser.id = e.data.historyEntry.entry.user.logonUserId;
        const userInformation = await lastValueFrom(this.nameDataPipe.transform(logonUser));
        this.popoverText = `Vertretung durch (<strong>${userInformation.name}</strong>)`;
        this.popoverElement = e.cellElement;
        await this.popOverCreatorColumn?.instance?.show();
      }
    }
    if (e.rowType === 'data' && e.eventType === 'mouseout') {
      await this.popOverCreatorColumn?.instance?.hide();
    }
  }

  private setColumns(): void {
    const columns: Column[] = [];
    if (Array.isArray(this.fieldContainer.editorOptions.signatureColumns)) {
      const table: ISignatureColumn[] = clone(this.fieldContainer.editorOptions.signatureColumns);
      table.push({ type: 'iconTemplate', caption: '', visible: true });

      for (const column of table) {
        const gridColumn = this.getColumnOptions(column);
        columns.push(gridColumn);
      }
    }
    this.gridSignature.columns = columns;
  }

  private getColumnOptions(column: ISignatureColumn): Column {
    const width = column.width || null;
    const cssClass = column.dataField === 'historyEntry.variables.kommentar' ? this.commentColumnClass : null;
    switch (column.type) {
      case 'timeStamp':
        return {
          caption: column.caption,
          visible: column.visible,
          width,
          dataField: column.dataField,
          dataType: 'datetime',
          format: column.format
        };
      case 'user':
      case 'references':
        return {
          caption: column.caption,
          visible: column.visible,
          width,
          dataField: column.dataField,
          encodeHtml: false
        };
      case 'iconTemplate':
        return {
          caption: column.caption,
          visible: column.visible,
          width: '30',
          cellTemplate: 'iconTemplate',
          allowResizing: false
        };
      case 'signatureTemplate':
        return {
          caption: column.caption,
          visible: column.visible,
          width,
          cellTemplate: 'signatureTemplate',
          allowResizing: false
        };

      default:
        return {
          caption: column.caption,
          visible: column.visible,
          width,
          dataField: column.dataField,
          dataType: 'string',
          encodeHtml: false,
          cssClass
        };
    }
  }

  private assignVariables(document: IDocument, variables: Record<string, unknown>): void {
    Object.keys(document.fields).forEach(key => {
      document.fields[key].value = variables[key] || null;
    });
    this.edgeDocument = document;
  }
}
