import { Pipe, PipeTransform } from '@angular/core';
import { DocumentService } from '@services/document.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IDocument } from '@interfaces/siam';

@Pipe({
  name: 'parentInfo'
})
export class ParentInfoPipe implements PipeTransform {

  constructor(private documentService: DocumentService) {
  }

  transform(document: IDocument, fieldName?: string): Observable<string> {
    let value: string = null;
    return this.documentService.getParents(document)
      .pipe(map(parent => {
        if (fieldName) {
          switch (fieldName) {
            case 'document-type': {
              value = parent[0]?.template?.caption || null;
              break;
            }
            case 'document-icon': {
              const tagType = 'app:document-icon:';
              value = parent[0].tags.filter(tag => tag.startsWith(tagType))
                .map(tag => tag.split(new RegExp(`^${tagType}`))[1])[0] || null;
              break;
            }
            default:
              value = (parent[0]?.fields[fieldName]?.value as string) || null;
          }
        } else {
          value = (parent[0]?.fields?.subject?.value as string) || null;
        }
        return value;
      }));
  }
}
