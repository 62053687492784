import { Pipe, PipeTransform } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { DocumentService } from '@services/document.service';
import { IDocument } from '@interfaces/siam';

@Pipe({
  name: 'getTasks',
})
export class GetTasksPipe implements PipeTransform {
  constructor(private _documentService: DocumentService) {
  }

  async transform(id: string): Promise<IDocument[]> {
    let documentItem: IDocument = null;
    if (id) {
      const item = await lastValueFrom(this._documentService.getDocumentById(id));
      if (item) {
        documentItem = item;
      }
    }
    const tasks = documentItem?.references?.filter(doc => doc.tags.some(tag => tag === 'app:document-type:task'));
    if (tasks.length) {
      const tasksList: IDocument[] = [];
      for (const task of tasks) {
        let taskDocument: IDocument;
        const documentId = task.referencedDocumentId || (task.document && task.document.id);
        if (documentId) {
          taskDocument = await lastValueFrom(this._documentService.getDocumentById(documentId));
        }

        if (taskDocument) {
          tasksList.push(taskDocument);
        }
      }
      return tasksList;
    } else {
      return [];
    }
  }
}
