import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  SecurityContext,
  ViewChild
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, from, lastValueFrom, Observable, of, Subject, zip } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { copy } from '@factories/document.factory';
import { DOCUMENT_TAG_CATEGORY_TITLE, DOCUMENT_TAG_STICKY_HEADER } from '@factories/template.factory';
import { siamConst } from '@interfaces/siamConst';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { NotifyService } from '@services/notify.service';
import { DocumentService } from '@services/document.service';
import { LoggerService } from '@services/logger.service';
import { ListsService } from '@services/lists.service';
import * as UserFactory from '@factories/user.factory';
import { SharedataService } from '@services/sharedata.service';
import { TemplateService } from '@services/template.service';
import {
  IActionSheetAction,
  IActionStandard,
  IAgenda,
  IApprovalForm,
  IConfidentialPermission,
  IConfidentialPermissionClient,
  IConfidentialReference,
  IConfirmDialog,
  ICustomDialog,
  IDocument,
  IDocumentField,
  IDocumentFields,
  IError,
  IEventEmitter,
  IEventLabel,
  ILabel,
  IMeetingMinutesActionSheetItem,
  INameSelectorDialogInclude,
  INameSelectorDialogSettings,
  IPermissionTarget,
  IRole,
  ITask,
  IUser,
  IWorkflowAction,
  IWorkflowActionSheetItem,
  IWorkflowDocument,
  TDocumentUrlTag,
  TEditMode,
  TEffectivePermission,
  TNameSelectorDialogType
} from '@interfaces/siam';
import { ApprovalDialogComponent } from '../../dialogs/approval-dialog/approval-dialog.component';
import { BallotInfoComponent } from '../../dialogs/ballot-info/ballot-info.component';
import { DocumentHelperService } from '@services/document-helper.service';
import { LabelsIncludeComponent } from '../subforms/labels-include/labels-include.component';
import { confirm, custom } from 'devextreme/ui/dialog';
import { WINDOW } from '../../../../tokens/window.token';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { TasksFormComponent } from '../../../../feature_modules/tasks-board/tasks-form/tasks-form.component';
import { FieldPermissionTargetServ, FieldServer, LayoutEntryPlaceholderServ } from '@interfaces/fieldServer';
import { HttpErrorResponse } from '@angular/common/http';
import { DxFormComponent } from 'devextreme-angular';
import { DocumentIncludeComponent } from '../subforms/document-include/document-include.component';
import { ApprovalService } from '@services/approval.service';
import { SiamList } from '@interfaces/siamList';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { LoginService } from '@services/login.service';
import { DocumentEditorComponent } from '../../dialogs/document-editor/document-editor.component';
import { PopupSettings } from '@services/popup-helper.service';
import { actionDialog, closeSaveDialogAgenda, isMobileDevice, refreshDialog, resolveSubject } from '@factories/helpers';
import { LoadPanelService } from '@services/load-panel.service';
import { ItemClickEvent, ItemRenderedEvent } from 'devextreme/ui/action_sheet';
import { SelectionChangedEvent } from 'devextreme/ui/list';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NumberGroupsService } from '@services/number-groups.service';
import { NameSelectorComponent } from '@dialogs/name-selector/name-selector.component';
import { ConfirmPopupComponent } from '@dialogs/confirm-popup/confirm-popup.component';
import { WorkflowDialogComponent, WorkFlowDialogSettings } from '@dialogs/workflow-dialog/workflow-dialog.component';
import { WorkflowInfoComponent } from '@dialogs/workflow-info/workflow-info.component';
import { TreeViewItem } from '@interfaces/fieldAgenda';
import { _defaultDecisionDocument, _defaultDecisionId, IDecisionStamp } from '@interfaces/default-decision';
import { FieldDecisionsValue, FieldDecisionValue } from '@interfaces/fieldClient';
import { AgendaServiceService } from '@services/agenda-service.service';
import { AudioRecordComponent } from '@components/audio-record/audio-record.component';

interface IDocumentDecision {
  document: IDocument;
  isActive: boolean;
  color: 'red' | 'green';
  title: string;
}

interface IBrainLoop {
  username: string;
  password: string;
  pin?: string;
  totp?: string;
  expires?: string;
  receiver?: string;
}

interface IDeleteMessage {
  title: string;
  childCount?: number;
  type?: TDocumentUrlTag;
  referencedDocuments?: IDocument[];
}

interface IHistoryState {
  resolvedDocument: IDocument;
}

interface IUploadError901 {
  type: 'SMS' | 'TOTP';
  information?: Record<string, string>;
}

interface IWindow extends Window {
  // eslint-disable-next-line
  URL: {
    createObjectURL: (file: Blob) => string;
    revokeObjectURL: (url: string) => void;
  };
}

@Component({
  selector: 'app-document-base',
  templateUrl: './document-base.component.html',
  styleUrls: ['./document-base.component.scss']
})
export class DocumentBaseComponent implements OnInit, OnDestroy {
  @ViewChild(ApprovalDialogComponent) approvalDialog: ApprovalDialogComponent;
  @ViewChild(BallotInfoComponent, { static: false }) ballotDialog: BallotInfoComponent;
  @ViewChild('brainLoopLoginForm') brainLoopLoginFormComponent: DxFormComponent;
  @ViewChild(ConfirmPopupComponent) confirmPopupComponent: ConfirmPopupComponent;
  @ViewChild(DocumentIncludeComponent, { static: false }) documentIncludeComponent: DocumentIncludeComponent;
  @ViewChild(LabelsIncludeComponent) labelsIncludeComponent: LabelsIncludeComponent;
  @ViewChild(NameSelectorComponent) nameSelectorComponent: NameSelectorComponent;
  @ViewChild(TasksFormComponent) tasksFormComponent: TasksFormComponent;
  @ViewChild(WorkflowDialogComponent) workflowDialog: WorkflowDialogComponent;
  @ViewChild(WorkflowInfoComponent) workflowInfoDialog: WorkflowInfoComponent;
  @ViewChild(DocumentEditorComponent, { static: true }) documentEditorComponent: DocumentEditorComponent;
  @ViewChild(AudioRecordComponent, { static: false }) audioRecordComponent: AudioRecordComponent;
  @ViewChild('closeBtn') closeBtn: ElementRef<HTMLElement>;

  agendaActions: IActionSheetAction[] = [];
  ballotDocuments: IWorkflowDocument[];
  brainLoop: IBrainLoop = {
    username: '',
    password: ''
  };
  brainLoopPinType: 'mobile' | 'email' = null;
  categoryTitle: string;
  confidentialSelected: IConfidentialPermissionClient[];
  confidentialAllowed: IConfidentialPermissionClient[];
  confidentialMap: Record<string, IConfidentialPermissionClient>;
  currentDocument: IDocument;
  documentExist$ = new BehaviorSubject<boolean>(null);
  documentToShow: IDocument;
  decisionTitle: string;
  decisionDocuments: IDocumentDecision[] = [];
  editMode: TEditMode;
  isAgenda: boolean;
  isAgendaActionLoaded = false;
  isBrainLoopPinRequired: boolean;
  isBrainLoopTotpRequired: boolean;
  isCanCreatePattern: boolean;
  isCanDelete: boolean;
  isCanPrint: boolean;
  isCanSdrUpload: boolean;
  isDecision: boolean;
  isHasMeetingMinutes: boolean;
  isHasMeetingMinutesPermissions: boolean;
  isMeetingMinutes: boolean;
  isPattern: boolean;
  isSecureContext: boolean;
  isShowAgendaActionsSheet = false;
  isShowAudioRecordingDialog: boolean;
  isShowBrainLoopDialog: boolean;
  isShowCategoryTitle: boolean;
  isShowConfidentialDropDown: boolean;
  isShowStandardActionsSheet: boolean;
  isShowWorkflowActionSheet: boolean;
  isStickyHeader: boolean;
  meetingMinutes: IMeetingMinutesActionSheetItem[];
  meetingMinutesPermissions: TEffectivePermission[];
  parentDocuments: IDocument[];
  recordActiveClass = false;
  readonly standardActions: IActionStandard[] = [
    {
      text: 'Sitzungsprotokoll erstellen',
      type: 'standard',
      disabled: false,
      action: 'parent'
    },
    {
      text: 'Vorgang zur Information weiterleiten',
      type: 'standard',
      disabled: false,
      action: 'send-info'
    },
    {
      text: 'Aufgabe erstellen',
      type: 'standard',
      disabled: false,
      action: 'task',
      visible: false
    }
  ];
  targetElementConfidential: Element;
  targetElementWorkflowActionSheet: Element;
  // zwischenspeichern des getriggerten WF-Schritts, bis UI-Aktion ausgeführt wurde
  workflowActions: IWorkflowActionSheetItem[];
  workflowActionsWithoutVotes: IWorkflowActionSheetItem[];
  voteActions: IWorkflowActionSheetItem[];
  workflowDocument: IWorkflowDocument;
  workflowStatusLabel: string;
  workflowCurrentAssignee: IPermissionTarget[];

  private currentUser: IUser;
  private withSave$ = new BehaviorSubject<boolean>(null);
  private dialogVariables: Record<string, unknown>;
  private workflowAction: IWorkflowActionSheetItem = null;
  private workflowActionVariables: Record<string, unknown> = null;
  private workflowActionCreateDocumentVariables: Record<string, { id: string; done: boolean }> = null;
  private workflowActionCreateDecisionVariables: Record<string, { decisionStamp: IDecisionStamp; done: boolean }> =
    null;
  #destroyable$ = new Subject<void>();

  constructor(
    private approvalService: ApprovalService,
    private documentService: DocumentService,
    private documentHelperService: DocumentHelperService,
    private listsService: ListsService,
    private loadPanelService: LoadPanelService,
    private logger: LoggerService,
    private location: Location,
    private nameDataPipe: GetNameDataPipe,
    private route: ActivatedRoute,
    private router: Router,
    private agendaService: AgendaServiceService,
    private shareDataService: SharedataService,
    private templateService: TemplateService,
    private loginService: LoginService,
    private zone: NgZone,
    private titleService: Title,
    private numberGroupService: NumberGroupsService,
    private sanitizer: DomSanitizer,
    @Inject(WINDOW) private window: IWindow
  ) {
    this.setInitialValues();
    this.currentUser = UserFactory.getCurrentUser();
  }

  @HostListener('document:keydown.Control.b')
  openDebugPopup(): void {
    if (this.editMode !== 'ReadOnly') {
      this.closeBtn.nativeElement.focus();
      this.onModeChanged();
    }
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.#destroyable$)).subscribe(params => {
      this.editMode = params.editMode as TEditMode;
    });

    this.shareDataService.getScrollToTop$.pipe(takeUntil(this.#destroyable$)).subscribe(flag => {
      this.isStickyHeader = flag && this.isTagTrue(DOCUMENT_TAG_STICKY_HEADER);
    });

    const historyState = history.state as IHistoryState;
    if (historyState.resolvedDocument) {
      this.logger.debug('history.state: {@0}', historyState);
      this.setCurrentDocument(copy(historyState.resolvedDocument));
      historyState.resolvedDocument = null;
    } else {
      this.loginService.getCurrentUser
        .pipe(
          switchMap(user => {
            this.setInitialValues();
            this.currentUser = user;
            this.isCanSdrUpload = UserFactory.isCanSdrUpload(user);
            this.isCanPrint = UserFactory.isCanPrint(user);
            this.isCanCreatePattern = UserFactory.isCanCreatePattern(user);
            return this.route.params;
          }),
          switchMap(params => {
            const documentId = params.documentid as string;
            if (documentId) {
              return of(documentId);
            }
            return this.route.data;
          }),
          switchMap(data => {
            // Document ID prom URL params
            if (typeof data === 'string') {
              return this.documentService.getDocumentById(data);
            }

            const document = data.resolvedDocument as IDocument;
            // new document from template without ID
            if (document.id) {
              return this.documentService.getDocumentById(document.id);
            }
            return of(document);
          }),
          takeUntil(this.#destroyable$)
        )
        .subscribe({
          next: (document): void => {
            this.setCurrentDocument(document);
          }
        });
    }

    if (window.isSecureContext) {
      this.isSecureContext = true;
    }
  }

  ngOnDestroy(): void {
    this.setCurrentDocument(null);
    this.documentExist$.next(null);
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  changeDocumentLabels(labels: ILabel[]): void {
    const tags = this.currentDocument.tags
      .filter(tag => !tag.startsWith(`app:document-label:${siamConst.globalLabelsListName}:`))
      // clear label tag from tags
      .filter(tag => !tag.startsWith(`app:document-label:internal_globallabels:`));
    this.currentDocument.tags = tags.concat(labels?.map(l => l.value) || []);
    this.documentToShow.tags = this.currentDocument.tags;
  }

  closeDocument(): void {
    const history = this.shareDataService.getHistory();
    let backUrl = history.pop();
    if (this.location.path(false) === backUrl) {
      backUrl = history[history.length - 1];
    }

    if (history && history.length > 1) {
      if (history.includes('goBack')) {
        void this.router.navigate(backUrl.split('/'));
      } else {
        this.shareDataService.setHistory([...history, 'goBack']);

        void this.router.navigateByUrl(backUrl).then(proceed => {
          if (!proceed) {
            const sharedHistory = this.shareDataService.getHistory();
            const index = sharedHistory.findIndex(h => h === 'goBack');
            if (index > -1) {
              sharedHistory.splice(index, 1);
              this.shareDataService.setHistory(sharedHistory);
            }
          }
        });
      }
    } else {
      this.shareDataService.setHistory(['/start']);
      void this.router.navigate(['application', 'home']);
    }
  }

  closeAudioRecord = (): void => {
    this.audioRecordComponent.abortRecording();
    this.isShowAudioRecordingDialog = false;
  };

  isRecordingInProgress = (value: boolean): void => {
    this.recordActiveClass = value;
  };

  isDocumentHasChanges(): Observable<boolean> {
    return this.documentIncludeComponent?.isDocumentHasChanges();
  }

  onActionSheetItemRendered(e: ItemRenderedEvent<IWorkflowActionSheetItem>): void {
    if (!e.itemData.action.isUsable) {
      e.itemElement.classList.add('siam-workflow-action-disabled');
    }
  }

  onCancelUpload(): void {
    this.loadPanelService.hide();
    this.isShowBrainLoopDialog = false;
    this.brainLoop = {
      username: '',
      password: ''
    };
    this.isBrainLoopPinRequired = false;
    this.isBrainLoopTotpRequired = false;
    this.brainLoopLoginFormComponent.instance.resetValues();
  }

  onDeleteDocument(): void {
    const docType = this.documentService.getTypeName(this.currentDocument);
    const childCount = this.documentService.countChildOfTag(this.currentDocument, siamConst.childTag);
    if (childCount) {
      const referencedDocuments = this.documentService.getChildDocumentsWithTag(
        this.currentDocument,
        siamConst.childTag
      );
      switch (docType?.toLowerCase()) {
        case 'agenda':
        case 'sitzung': {
          void this.openDeletionConfirmDialogWithReferences({
            title: 'Sitzung löschen',
            type: 'agenda',
            childCount,
            referencedDocuments
          }).show();
        }
          break;

        case 'vorlage':
        case 'submission': {
          void this.openDeletionConfirmDialogWithReferences({
            title: 'Vorlage löschen',
            type: 'submission',
            childCount,
            referencedDocuments
          }).show();
        }
          break;

        case 'projekt':
        case 'project': {
          void this.openDeletionConfirmDialogWithReferences({
            title: 'Projekt löschen',
            type: 'project',
            childCount,
            referencedDocuments
          }).show();
        }
          break;

        default: {
          void this.openDeletionConfirmDialogWithReferences({
            title: 'Vorgang löschen',
            type: 'Vorgang',
            childCount,
            referencedDocuments
          }).show();

          break;
        }
      }
    } else {
      this.openConfirmDialog('Vorgang löschen');
    }
  }

  onEmitApprovalDialog(result: IApprovalForm): void {
    const withSaveValue = this.withSave$.getValue();
    if (withSaveValue === null) {
      return;
    }
    this.loadPanelService.show('Workflowaktivitäten werden ausgeführt...');
    of(withSaveValue)
      .pipe(
        switchMap(withSave => {
          if (withSave) {
            return this.documentIncludeComponent?.prepareForSave$();
          }
          return of(this.currentDocument);
        }),
        tap(document => {
          this.logger.debug('Zu speicherndes Dokument: {@doc}', document);
        }),
        map(document => this.approvalService.bindApprovalForm(result, document)),
        switchMap(document => this.documentService.save(document)),
        switchMap(document => {
          this.logger.debug('Beschlussdialog positiv bestätigt, speichere Beschlussdaten im Dokument:\n {@a}', result);

          if (!this.documentService.isDecision(this.currentDocument)) {
            return zip(of(document), this.numberGroupService.getNext(result.category));
          }
          return zip(of(document));
        }),
        map(response => response[0]),
        // "Frontend-Document" mit Backenddocument ersetzen
        tap(savedDocument => {
          const template = this.currentDocument.template;
          this.currentDocument = copy(savedDocument);
          this.currentDocument.template = template;

          if (this.workflowAction && this.workflowAction.action) {
            this.loadPanelService.hide();
            this.documentService
              .executeWorkflowAction(this.workflowAction.action, this.workflowActionVariables)
              .pipe(takeUntil(this.#destroyable$))
              .subscribe({
                next: (doc): void => {
                  this.resetLoading();
                  this.setCurrentDocument(doc);
                  this.logger.debug('WF-Schritt ausgeführt.');
                  NotifyService.global.success(`Der Workflowschritt "${this.workflowAction.text}" wurde ausgeführt.`);
                },
                error: (): void => {
                  this.resetLoading();
                  NotifyService.global.error(
                    `Der Workflowschritt '${this.workflowAction.text}' konnte nicht ausgeführt werden.`
                  );
                }
              });
          } else {
            this.logger.debug('Kein Workflowschritt zur Ausführung nach Beschlussmarkierung vorhanden.');
          }
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: (document): void => {
          this.resetLoading();
          if (document) {
            NotifyService.global.success('Erfolgreich gespeichert');
            this.documentHelperService.openDocument(document);
          }
        },
        error: (error: IError) => {
          this.resetLoading();
          NotifyService.component.error(error);
        }
      });
  }

  onEmitApprovalDialogClose(): void {
    // Dialog abgebrochen, nichts zu tun ...
    this.logger.debug('Beschlussdialog abgebrochen.');

    if (this.workflowAction) {
      NotifyService.global.warn(`Der Workflowschritt "${this.workflowAction.text}" wurde abgebrochen.`);
      this.workflowAction = null;
    }
  }

  onEmitEvent(e: IEventEmitter<ITask>): void {
    switch (e.command) {
      case 'startLoading':
      case 'endLoading':
        this.loadPanelService.show(null, null, 120);
        break;

      case 'reload':
        this.loadPanelService.show(null, null, 120);
        this.documentService
          .getDocumentById(this.currentDocument.id)
          .pipe(takeUntil(this.#destroyable$))
          .subscribe({
            next: document => {
              if (document) {
                this.setCurrentDocument(document);
              } else {
                this.resetLoading();
              }
            },
            error: () => {
              this.resetLoading();
            }
          });
        break;

      default:
        this.onSaveDocument();
        break;
    }
  }

  onEmitLabelIncludeComponent(event: IEventLabel): void {
    if (event) {
      switch (event.command) {
        case 'select': {
          this.changeDocumentLabels(event.labels);
          this.onSaveDocument();
          break;
        }
      }
    }
  }

  onEmitNameSelectorDialog(result: (IUser | IRole)[]): void {
    const users = result
      .filter(item => item.compositeId.startsWith('user:'))
      .map(item => ({ targetId: item.id, type: 'user' }));

    const roles = result
      .filter(item => item.compositeId.startsWith('role:'))
      .map(item => ({ targetId: item.id, type: 'role' }));

    const variables: Record<string, unknown[]> = { assignee: [].concat(users, roles) };
    this.workflowActionVariables = variables;
    const userInputTemplateLink = this.workflowAction?.action.userInputTemplateLink;

    if (userInputTemplateLink) {
      this.dialogVariables = variables;
      const layout = this.documentService.createSubForm(userInputTemplateLink, this.workflowAction?.action?.userInput);
      const settings: WorkFlowDialogSettings = {
        title: this.workflowAction?.action?.label || 'Formular',
        fullScreen: false,
        readonly: false,
        parentDocumentId: this.currentDocument?.id
      };
      this.workflowDialog.show(layout, settings);
    } else if (this.isShowApprovalDialog()) {
      this.logger.debug(
        'ActionName [{0}] match!: Beschlussmarkierungsdialog wird aufgerufen',
        this.workflowAction?.action.name
      );
      if (!this.currentDocument.effectivePermissions.includes('update')) {
        NotifyService.global.warn(
          'Sie verfügen über keine ausreichenden Berechtigungen zum Ändern des Vorgangs.',
          5000
        );
        return;
      }
      this.approvalDialog.approve(this.currentDocument);
    } else {
      this.proceedWorkflowAction(variables);
    }
  }

  async onEmitWorkflowDialog(result: IEventEmitter<IDocument>): Promise<void> {
    if (result.command === 'save') {
      if (result.variableCreateDocumentName?.length) {
        this.workflowActionVariables[result.variableCreateDocumentName] = result.object.fields;
        this.workflowActionCreateDocumentVariables[result.variableCreateDocumentName].done = true;

        const createDocument = Object.entries(this.workflowActionCreateDocumentVariables).find(
          ([, value]) => value.done === false
        );
        if (createDocument) {
          const documentFromTemplate = await lastValueFrom(this.createDocumentFromTemplateId(createDocument[1].id));
          const settings: WorkFlowDialogSettings = {
            title: this.workflowAction.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDocumentInputName: createDocument[0],
            parentDocumentId: this.currentDocument?.id
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else {
          this.proceedWorkflowAction(this.workflowActionVariables);
        }
      } else if (result.variableCreateDocisionName?.length) {
        const decisionStamp =
          this.workflowActionCreateDecisionVariables[result.variableCreateDocisionName].decisionStamp;
        const fieldIds: IDocumentFields = {
          decisionCategoriesId: { value: decisionStamp.decisionCategoriesId },
          decisionChoicesId: { value: decisionStamp.decisionChoicesId }
        };
        this.workflowActionVariables[result.variableCreateDocisionName] = Object.assign(result.object.fields, fieldIds);
        this.workflowActionCreateDecisionVariables[result.variableCreateDocisionName].done = true;

        const createDocument = Object.entries(this.workflowActionCreateDecisionVariables).find(
          ([, value]) => value.done === false
        );
        if (createDocument) {
          const documentFromTemplate = await lastValueFrom(
            this.createDecisionDocumentFromDecisionStamp(createDocument[1].decisionStamp)
          );
          const settings: WorkFlowDialogSettings = {
            title: this.workflowAction.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDecisionInputName: createDocument[0],
            parentDocumentId: this.currentDocument?.id
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else {
          this.proceedWorkflowAction(this.workflowActionVariables);
        }
      } else {
        const doc = result.object;
        const variables: Record<string, unknown> = {};
        Object.keys(doc.fields).forEach(key => {
          variables[key] = doc.fields[key].value;
        });
        if (this.dialogVariables) {
          Object.keys(this.dialogVariables).forEach(key => {
            variables[key] = this.dialogVariables[key];
          });
          this.dialogVariables = null;
        }
        this.workflowActionVariables = variables;
        const createDocument = Object.entries(this.workflowActionCreateDocumentVariables).find(
          ([, value]) => value.done === false
        );
        if (createDocument) {
          const documentFromTemplate = await lastValueFrom(this.createDocumentFromTemplateId(createDocument[1].id));
          const settings: WorkFlowDialogSettings = {
            title: this.workflowAction.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDocumentInputName: createDocument[0],
            parentDocumentId: this.currentDocument?.id
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else {
          this.proceedWorkflowAction(variables);
        }
      }
    }
  }

  onPrintDocument(): void {
    const printDocument = () => {
      this.loadPanelService.show('Druck wird zusammengestellt.');
      const isMobile = isMobileDevice();
      this.documentService
        .print(this.currentDocument)
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          next: data => {
            this.loadPanelService.hide();
            const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
            const blobUrlData = URL.createObjectURL(blob);
            const fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrlData);
            const url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, fileURL);

            if (isMobile) {
              const dialog = custom({
                title: 'Drucken...',
                messageHtml: 'Das angeforderte PDF wurde fertiggestellt. Sie können es nun verwenden.',
                buttons: [
                  {
                    text: 'Fortfahren',
                    onClick: () => {
                      this.window.open(url, '_blank');
                      return true;
                    }
                  }
                ]
              }) as ICustomDialog<boolean>;
              dialog.show();
            } else {
              this.window.open(url, '_blank');
            }
          },
          error: () => {
            this.loadPanelService.hide();
          }
        });
    };

    const closeSubject$ = new Subject<boolean>();
    const dialog: ICustomDialog<boolean> = actionDialog(
      'Dokument drucken',
      'Es gibt ungespeicherte Änderungen auf dem Dokument. Was möchten Sie tun?',
      closeSubject$,
      (subject$: Subject<boolean>) => {
        void lastValueFrom(this.saveChanges(subject$).pipe(tap(() => printDocument())));
      },
      () => {
        void printDocument();
      }
    );
    lastValueFrom(this.isDocumentHasChanges()).then(
      hasChanges => {
        if (hasChanges) {
          dialog.show();
          return lastValueFrom(closeSubject$);
        }
        void printDocument();
        return true;
      },
      () => {
        dialog.show();
        return lastValueFrom(closeSubject$);
      }
    );
  }

  onModeChanged(): void {
    if (this.editMode === 'EditPreview') {
      const documentToShow = copy(this.documentToShow);
      this.documentToShow = null;
      setTimeout(() => {
        this.editMode = 'Edit';
        this.documentToShow = documentToShow;
      }, 100);
    } else {
      const setEditPreviewmode = () => {
        const documentToShow = copy(this.documentToShow);
        this.documentToShow = null;
        setTimeout(() => {
          this.editMode = 'EditPreview';
          this.documentToShow = documentToShow;
        }, 100);
      };
      const refreshDocument = () => {
        this.documentService
          .getDocumentById(this.currentDocument.id)
          .pipe(takeUntil(this.#destroyable$))
          .subscribe({
            next: document => {
              this.setCurrentDocument(document);
              this.editMode = 'EditPreview';
            }
          });
      };
      const closeSubject$ = new Subject<boolean>();
      const dialog: ICustomDialog<boolean> = actionDialog(
        'Umschalten in Lesemodus',
        'Es gibt ungespeicherte Änderungen auf dem Dokument. Was möchten Sie tun?',
        closeSubject$,
        (subject$: Subject<boolean>) => {
          void lastValueFrom(this.saveChanges(subject$).pipe(tap(() => refreshDocument())));
        },
        () => {
          void setEditPreviewmode();
        }
      );
      lastValueFrom(this.isDocumentHasChanges()).then(
        hasChanges => {
          if (hasChanges) {
            dialog.show();
            return lastValueFrom(closeSubject$);
          }
          void setEditPreviewmode();
          return true;
        },
        () => {
          dialog.show();
          return lastValueFrom(closeSubject$);
        }
      );
    }
  }

  onRefreshDocument(): void {
    if (this.currentDocument?.id) {
      const refreshDocument = () => {
        this.documentService
          .getDocumentById(this.currentDocument.id)
          .pipe(takeUntil(this.#destroyable$))
          .subscribe({
            next: document => {
              this.setCurrentDocument(document);
            }
          });
      };
      const closeSubject$ = new Subject<boolean>();

      const dialog = refreshDialog(
        'Dokument neu laden',
        'Es gibt ungespeicherte Änderungen auf dem Dokument. Was möchten Sie tun?',
        closeSubject$,
        (subject$: Subject<boolean>) => {
          void lastValueFrom(this.saveChanges(subject$).pipe(tap(() => refreshDocument())));
        },
        () => {
          void refreshDocument();
        }
      );

      lastValueFrom(this.isDocumentHasChanges()).then(
        hasChanges => {
          if (hasChanges) {
            dialog.show();
            return lastValueFrom(closeSubject$);
          }
          void refreshDocument();
          return true;
        },
        () => {
          dialog.show();
          return lastValueFrom(closeSubject$);
        }
      );
    }
  }

  onSaveDocument(): void {
    this.logger.debug('saveDocument() from document-base.component called.');
    this.loadPanelService.show('Speichern...', null, 120);

    this.documentIncludeComponent
      ?.prepareForSave$()
      .pipe(
        switchMap(document => {
          return this.agendaService.prepareAgendaToSave(this.currentDocument, document).pipe(map(() => document));
        }),
        switchMap(document => {
          this.agendaService.mapAgendaItemsToServer(this.currentDocument, document);
          this.logger.debug('Zu speicherndes Dokument: {@doc}', document);

          if (document.fields['--siam-agendaitems'] && document.fields['--siam-agendaitems'].value) {
            (document.fields['--siam-agendaitems'].value as IAgenda[]).forEach((element: IAgenda) => {
              delete element.document;
              if (element.children) {
                element.children.forEach(child => {
                  delete child.document;
                });
              }
            });
          }
          if (document.references) {
            document.references.forEach(reference => {
              delete reference.document;
            });
          }
          const agendaDocId = document?.fields[siamConst.agendaDocumentId]?.value as string;
          if (agendaDocId) {
            const parentId = copy(agendaDocId);
            delete document.fields[siamConst.agendaDocumentId];
            return this.documentService.save(document, parentId);
          } else {
            return this.documentService.save(document);
          }
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: (savedDocument): void => {
          this.resetLoading();
          // Falls es sich um ein Sitzungsprotokoll handelt, soll es als Tochterdokument an die Agenda gehängt werden.
          if (savedDocument) {
            NotifyService.global.success('Erfolgreich gespeichert');
            if (this.currentDocument.id !== savedDocument.id) {
              // here we need to set fields or current document to the new fields,
              // to make future comparison of fields on document close correct, otherwise it may compare fields of
              // the template with saved document's fields with values
              this.currentDocument = null;
              this.documentToShow = null;
              this.documentHelperService.openDocument(savedDocument);
            } else {
              this.setCurrentDocument(savedDocument);
            }
          }
        },
        error: (error: string | boolean): void => {
          this.resetLoading();
          // prepareForSave$ method can return false, in case of form validation errors
          if (error !== false) {
            NotifyService.component.error(error as string);
          }
        }
      });
  }

  onSavePatternDocument = (): void => {
    this.logger.debug('onSavePatternDocument() from document-base.component called.');
    this.loadPanelService.show('Mustersitzung wird erstellt!', null, 250);
    this.documentIncludeComponent
      ?.prepareForSave$()
      .pipe(
        switchMap(document => {
          const value = (document.fields[siamConst.agendaField]?.value as TreeViewItem[]) || [];
          const flattenValue = this.flattenHierarchy(value);
          return this.agendaService.createPatternFromDocument(this.currentDocument, flattenValue);
        })
      )
      .subscribe({
        next: (): void => {
          NotifyService.global.success('Muster-Dokument wurde erstellt!');
        },
        complete: () => {
          this.resetLoading();
        },
        error: (error: string | boolean): void => {
          this.resetLoading();
          if (error !== false) {
            NotifyService.component.error(error as string);
          }
        }
      });
  }

  onSelectConfidential = (
    e: SelectionChangedEvent<IConfidentialPermissionClient, IConfidentialPermissionClient>
  ): void => {
    const confidentialId = e.addedItems[0]?.confidentialId;
    let confidential: IConfidentialPermission;
    if (confidentialId) {
      if (confidentialId === '00000000-0000-0000-0000-000000000000') {
        confidential = {
          confidentialId,
          allowHardening: false,
          allowWeakening: false
        };
      } else {
        confidential = this.currentDocument.template.confidentialReferences.find(
          c => c.confidentialId === confidentialId
        ) as IConfidentialPermission;

        if (!confidential) {
          for (const c of this.currentDocument.template.confidentialReferences) {
            confidential = c.children.find(a => a.confidentialId === confidentialId);
            if (confidential) {
              break;
            }
          }
        }
      }
    }

    this.currentDocument.confidential = confidential || null;
    this.documentToShow.confidential = this.currentDocument.confidential;
    this.isShowConfidentialDropDown = false;
  };

  onSelectStandardAction(e: ItemClickEvent<IActionStandard>): void {
    const itemData = e.itemData;
    this.logger.debug('selected standardAction: {@w}', itemData);

    switch (itemData.action) {
      case 'task':
        this.tasksFormComponent.editTask();
        break;

      case 'people':
        this.nameSelectorComponent.show();
        break;

      case 'meeting-minutes':
        // notthing
        break;

      default:
        NotifyService.global.warn(`unbekannte Aktion ausgewählt: ${itemData.action}`);
    }
    this.isShowStandardActionsSheet = false;
  }

  onSelectAgendaAction = (e: ItemClickEvent<IActionSheetAction>): void => {
    switch (e.itemData.action) {
      case 'create-meeting-minutes':
        this.createMeetingMinutesAction(e.itemData);
        break;
      case 'open-meeting-minutes':
        this.openMeetingMinutesAction();
        break;
      case 'create-pattern':
        this.onSavePatternDocument();
        break;
      case 'sdr-upload':
        this.isShowBrainLoopDialog = true;
        break;

      default:
        break;
    }
  };

  onSelectWorkflowAction(e: ItemClickEvent<IWorkflowActionSheetItem> | IWorkflowActionSheetItem): void {
    const actionItem = (e as ItemClickEvent<IWorkflowActionSheetItem>)?.itemData || (e as IWorkflowActionSheetItem);
    if (!actionItem) {
      return;
    }
    if (!actionItem.action.isUsable) {
      this.confirmPopupComponent.show({
        title: 'Workflowinformation',
        message: `Der Workflowschritt "${actionItem.action.label}" kann nicht ausgeführt werden: ${actionItem.action.reason}`,
        items: [
          {
            text: 'Workflowinformation',
            name: 'cancel',
            location: 'before',
            toolbar: 'top'
          },
          {
            widget: 'dxButton',
            name: 'cancel',
            location: 'before',
            toolbar: 'bottom',
            options: {
              text: 'Ok',
              type: 'primary',
              icon: 'check'
            }
          }
        ],
        noInit: true
      });
      return;
    }
    this.logger.debug('selected workflowAction: {@w}', actionItem);
    const executeAction = () => {
      void this.zone.run(async () => {
        this.workflowActionVariables = {};
        this.workflowActionCreateDocumentVariables = {};
        this.workflowActionCreateDecisionVariables = {};
        this.workflowAction = actionItem;

        // mittels userInputTemplate wird gesteuert, ob ein WorkflowDialog angezeigt werden soll,
        // z.B. um zusätzliche Informationen aufzufragen, oder dem User anzuzeigen
        // Beispiel: Abstimmungsdialog
        // derzeit kann hier nur gesteuert werden, ob der NameSelector angezeigt werden soll
        // dazu wird in der WF-Konfiguration ein Template mit nur einem Feld "assignee" erzeugt und zugeordnet,
        // später kann man in der WF-Konfiguration auch Template zuordnen.
        const userInputTemplateLink = actionItem.action.userInputTemplateLink;
        const userInputTemplate = actionItem.action.userInputTemplate;
        const createDocumentInputTemplates = actionItem.action.createDocumentInputTemplates;
        const createDecisionDocumentTemplates = actionItem.action.decisionStamps;
        let createDocumentInputTemplatesArray: { key: string; value: string }[] = [];
        let createDecisionDocumentTemplatesArray: { key: string; value: string; decisionStamp: IDecisionStamp }[] = [];
        if (createDocumentInputTemplates) {
          createDocumentInputTemplatesArray = Object.entries(createDocumentInputTemplates).map(([key, value]) => ({
            key,
            value
          }));
          Object.entries(createDocumentInputTemplates).forEach(([key, value]) => {
            this.workflowActionCreateDocumentVariables[key] = { id: value, done: false };
          });
        }
        if (createDecisionDocumentTemplates) {
          createDecisionDocumentTemplatesArray = Object.entries(createDecisionDocumentTemplates).map(
            ([key, value]) => ({
              key,
              value: value.dialogId || _defaultDecisionId,
              decisionStamp: value
            })
          );
          Object.entries(createDecisionDocumentTemplates).forEach(([key, value]) => {
            this.workflowActionCreateDecisionVariables[key] = {
              decisionStamp: value,
              done: false
            };
          });
        }

        if (userInputTemplate) {
          const nameSelector =
            (Array.isArray(userInputTemplate.tags) && userInputTemplate.tags.includes(siamConst.siamAssigneeTag)) ||
            userInputTemplate.name === 'user-fields';
          if (nameSelector) {
            this.nameSelectorComponent.show(
              this.getNameSelectorSettings(actionItem.action.userInputTemplate.fields[0])
            );
          } else {
            const needle = 'vote:user:';
            const layout = this.documentService.createSubForm(userInputTemplate, actionItem.action.userInput);
            let title = actionItem.action.label || layout.template.caption;

            if (actionItem.action.name?.includes(needle)) {
              const user = UserFactory.create();
              user.id = actionItem.action.name?.split(needle).pop();
              const userPipe = await lastValueFrom(this.nameDataPipe.transform(user));
              title = `${title} für ${userPipe.fullName}`;
            }

            const settings: WorkFlowDialogSettings = {
              title,
              fullScreen: false,
              readonly: false,
              parentDocumentId: this.currentDocument?.id
            };
            this.workflowDialog.show(layout, settings);
          }
        } else if (userInputTemplateLink) {
          const layout = this.documentService.createSubForm(userInputTemplateLink, actionItem.action.userInput);
          const settings: WorkFlowDialogSettings = {
            title: actionItem.action.label || layout.template.caption,
            fullScreen: false,
            readonly: false,
            parentDocumentId: this.currentDocument?.id
          };
          this.workflowDialog.show(layout, settings);
        } else if (createDocumentInputTemplatesArray?.length) {
          const first = createDocumentInputTemplatesArray[0];
          const documentFromTemplate = await lastValueFrom(this.createDocumentFromTemplateId(first.value));
          const settings: WorkFlowDialogSettings = {
            title: actionItem.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDocumentInputName: first.key,
            parentDocumentId: this.currentDocument?.id
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else if (createDecisionDocumentTemplatesArray?.length) {
          const first = createDecisionDocumentTemplatesArray[0];
          const documentFromTemplate = await lastValueFrom(
            this.createDecisionDocumentFromDecisionStamp(first.decisionStamp)
          );
          const settings: WorkFlowDialogSettings = {
            title: actionItem.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDecisionInputName: first.key,
            parentDocumentId: this.currentDocument?.id
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else {
          // -- feste Empfängerauswahl, keine Userinteraktion notwendig
          this.executeWorkflowAction(actionItem.action);
        }
      });
    };

    const closeSubject$ = new Subject<boolean>();

    const dialog: ICustomDialog<boolean> = actionDialog(
      'Worfklow ausführen',
      'Es gibt ungespeicherte Änderungen auf dem Dokument. Was möchten Sie tun?',
      closeSubject$,
      (subject$: Subject<boolean>) => {
        void lastValueFrom(
          this.saveChanges(subject$).pipe(
            tap({
              next: () => {
                this.withSave$.next(true);
                executeAction();
              }
            }),
            catchError(() => {
              this.withSave$.next(null);
              return of(null);
            })
          )
        );
      },
      () => {
        this.withSave$.next(false);
        void executeAction();
      }
    );

    lastValueFrom(this.isDocumentHasChanges()).then(
      hasChanges => {
        if (hasChanges) {
          dialog.show();
          return lastValueFrom(closeSubject$);
        }
        this.withSave$.next(false);

        void executeAction();
        return true;
      },
      () => {
        dialog.show();
        return lastValueFrom(closeSubject$);
      }
    );

    this.isShowWorkflowActionSheet = false;
  }

  onWorkflowItemHold() {
    const body = this.window.document.querySelector('body');
    if (body) {
      body.classList.add('body-shaking');
      body.addEventListener('animationend', () => body.classList.remove('body-shaking'));
    }
  }

  onShowBallotInformation(): void {
    this.ballotDialog.show();
  }

  onShowConfidentialsDropDown(event: Event): void {
    if (this.editMode === 'Edit' && this.confidentialAllowed.length) {
      this.isShowConfidentialDropDown = true;
      this.targetElementConfidential = event.target as Element;
    }
  }

  onShowDecisionInformation(doc: IDocument): void {
    if (!this.documentHelperService || !doc) {
      return;
    }
    const settings: PopupSettings = {};
    settings.editMode = 'ReadOnly';
    settings.openNewWindow = false;
    settings.title = 'Beschlussmarkierung' + doc.template.caption;
    void this.documentEditorComponent.show(doc, settings);
  }

  onShowLabelsDropDown(event: Event): void {
    if (this.editMode === 'Edit') {
      this.labelsIncludeComponent.showLabelSelector(event.target);
    }
  }

  onShownPopup(): void {
    if (this.brainLoopLoginFormComponent) {
      const editorUsername = this.brainLoopLoginFormComponent.instance.getEditor('username');
      if (editorUsername) {
        // Sicheren Datenraum Eingabefokus in erstes Feld setzen
        editorUsername.focus();
      } else {
        console.error('Konnte Editor "Benutzer" nicht identifizieren.');
      }
    }
  }

  createMeetingMinutesAction = (data: IActionSheetAction): void => {
    if (data?.document) {
      void this.router.navigate(['application', 'template', data.document.templateId], {
        state: { resolvedDocument: data.document }
      });
    }
  }

  openMeetingMinutesAction = (): void => {
    const child = this.documentService.getChildDocumentWithTag(
      this.currentDocument,
      'app:document-type:meetingMinutes'
    );
    if (child) {
      const documentId = (child.document && child.document.id) || child.documentId;
      void this.router.navigate(['application', 'documents', 'meetingMinutes', documentId], {
        queryParams: { editMode: 'Edit' }
      });
    } else {
      NotifyService.global.error('Das bereits bestehende Sitzungsprotokolldokument konnte nicht gefunden werden.');
    }
    return;
  }

  onShowAgendaActions = (): void => {
    if (this.isHasMeetingMinutes) {
      this.isShowAgendaActionsSheet = true;
      return;
    }

    const fetchAgendaActions = (): void => {
      if (this.isAgendaActionLoaded) {
        this.isShowAgendaActionsSheet = true;
        return;
      }
      this.loadPanelService.show();
      this.templateService
        .getActiveTemplates('execute', ['app:document-type:meetingMinutes'])
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          next: response => {
            void (async templates => {
              this.loadPanelService.hide();
              if (!templates?.length) {
                NotifyService.global.error(
                  'Es wurde keine Vorlage für die Erstellung eines Sitzungsprotokolls gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
                );
                return;
              }
              let documentFromTemplate: IDocument;
              let rapidDocumentFromTemplate: IDocument;
              let templateFromConfig: ITemplateServer = null;
              let rapidProtocol: ITemplateServer = null;
              let rapidProtocolNew: ITemplateServer = null;
              this.meetingMinutes = [];

              const agendaField = this.currentDocument?.template?.fields?.find(field => field.type === 'documents');
              if (agendaField) {
                const agendaFieldSettings = this.currentDocument?.template?.layouts[0]?.entries?.find(entry =>
                  entry.type === 'placeholder' && (entry as LayoutEntryPlaceholderServ).name === agendaField.name) as LayoutEntryPlaceholderServ;
                const subTemplates = agendaFieldSettings?.additionalData?.agendaSettings?.agendaSubTempates;
                const templateMeetingMinutesIdNew = subTemplates?.templateMeetingMinutesId;
                const templateRapidMeetingMinutesIdNew = subTemplates?.templateRapidMeetingMinutesId;

                templateFromConfig = templates.find(t => t.id === templateMeetingMinutesIdNew);
                rapidProtocolNew = templates.find(t => t.id === templateRapidMeetingMinutesIdNew);

                if (templateFromConfig) {
                  documentFromTemplate = await lastValueFrom(this.documentService.createDocumentByTemplateId(templateFromConfig));
                  await this.addDocument(documentFromTemplate, 'description', true);
                }

                if (rapidProtocolNew) {
                  rapidDocumentFromTemplate = await lastValueFrom(this.documentService.createDocumentByTemplateId(rapidProtocolNew));
                  await this.addDocument(rapidDocumentFromTemplate, 'fast_forward', true);
                }
              }

              const agendaContainer = this.currentDocument?.template?.layouts[0]?.entries?.find(
                entry =>
                  entry.type === 'placeholder' &&
                  ((entry as LayoutEntryPlaceholderServ).placeholderType === 'toc:agenda' ||
                    (entry as LayoutEntryPlaceholderServ).name === 'toc:agenda')
              ) as LayoutEntryPlaceholderServ;
              if (agendaContainer) {
                const templateFromCurrentDocument = templates.find(t => t.name === this.currentDocument.template.name);
                const templateMeetingMinutesId =
                  agendaContainer?.additionalData?.agendaAllowedTemplates?.templateMeetingMinutesId;
                if (templateMeetingMinutesId) {
                  templateFromConfig = templates.find(t => t.id === templateMeetingMinutesId);
                  if (templateFromConfig) {
                    documentFromTemplate = await lastValueFrom(
                      this.documentService.createDocumentByTemplateId(templateFromConfig)
                    );
                  }
                } else {
                  if (templateFromCurrentDocument) {
                    documentFromTemplate = await lastValueFrom(
                      this.documentService.createDocumentByTemplateId(templateFromCurrentDocument)
                    );
                  }
                }

                if (this.currentDocument?.references?.length && documentFromTemplate) {
                  await this.addDocument(documentFromTemplate, 'description', false);
                }

                const templateRapidMeetingMinutesId =
                  agendaContainer?.additionalData?.agendaAllowedTemplates?.templateRapidMeetingMinutesId;
                if (templateRapidMeetingMinutesId) {
                  rapidProtocol = templates.find(t => t.id === templateRapidMeetingMinutesId);
                }

                if (rapidProtocol) {
                  rapidDocumentFromTemplate = await lastValueFrom(
                    this.documentService.createDocumentByTemplateId(rapidProtocol)
                  );
                  await this.addDocument(rapidDocumentFromTemplate, 'fast_forward', false);
                }
              }
              this.getAgendaActions();
              this.isShowAgendaActionsSheet = true;
              this.isAgendaActionLoaded = true;
            })(response);
          },
          error: () => {
            this.loadPanelService.hide();
            NotifyService.component.error(
              'Keine passender Dokumenttyp zur Erstellung des Sitzungsprotokolls gefunden.'
            );
          }
        });
    };

    const closeSubject$ = new Subject<boolean>();

    const dialog: ICustomDialog<boolean> = closeSaveDialogAgenda(
      'Agendaktionen',
      'Es gibt ungespeicherte Änderungen auf dem Dokument. Sie müssen zuerst das Dokument speichen.',
      closeSubject$,
      (subject$: Subject<boolean>) => {
        void lastValueFrom(
          this.saveChanges(subject$, true).pipe(
            tap({
              next: () => {
                fetchAgendaActions();
              }
            }),
            catchError(() => {
              return of(null);
            })
          )
        );
      }
    );

    lastValueFrom(this.isDocumentHasChanges()).then(
      hasChanges => {
        if (hasChanges) {
          dialog.show();
          return lastValueFrom(closeSubject$);
        }

        fetchAgendaActions();
        return true;
      },
      () => {
        dialog.show();
        return lastValueFrom(closeSubject$);
      }
    );
  }

  /**
   * wird getriggert, wenn auf den Button "Workflow" oder "weitere Optionen" geklickt wurde
   * blendet ein Widget mit den verfügbaren Aktionen ein
   *
   * @param e
   */
  onShowStandardActions = (e: Event): void => {
    const target = e.target as Element;
    this.targetElementWorkflowActionSheet = e.target as Element;
    this.logger.debug('buttondata: {@e}', target);
    // Scroll für Workflowschritte
    setTimeout(() => {
      const popup = this.window.document.querySelector('.dx-actionsheet-container');
      if (popup) {
        (popup as HTMLElement).style.cssText = 'height: 100%; overflow-y: auto';
      }
    }, 100);
    const buttonId = target.id || target.parentElement.id;
    this.logger.debug('clicked on Button-Id: ', buttonId);
    switch (buttonId) {
      case 'workflowActions': {
        this.isShowStandardActionsSheet = false;
        if (this.workflowActionsWithoutVotes.length > 0) {
          this.isShowWorkflowActionSheet = true;
        } else {
          NotifyService.global.warn('Es sind keine Workflow-Aktionen verfügbar.');
        }
      }
        break;

      case 'standardActions': {
        this.isShowWorkflowActionSheet = false;
        if (this.standardActions.length > 0) {
          this.isShowStandardActionsSheet = true;
        } else {
          NotifyService.global.warn('Es sind keine Standardaktionen verfügbar.');
        }
      }
        break;

      default:
        NotifyService.global.warn('Unbekannte Aktionsliste.');
    }
  };

  onShowWorkflowInformation(): void {
    this.workflowInfoDialog.show();
  }

  openDeletionConfirmDialog(deleteMessage: IDeleteMessage): Promise<boolean> {
    const documentName =
      (this.currentDocument.fields.subject && (this.currentDocument.fields.subject.value as string)) ||
      (this.currentDocument.template && this.currentDocument.template.caption) ||
      '';

    const confirmMsg = `Möchten Sie den Vorgang "${documentName}" wirklich löschen?`;

    return confirm(confirmMsg, deleteMessage.title);
  }

  openDeletionConfirmDialogWithReferences(deleteMessage: IDeleteMessage): IConfirmDialog {
    const documentName =
      (this.currentDocument.fields.subject && (this.currentDocument.fields.subject.value as string)) ||
      (this.currentDocument.template && this.currentDocument.template.caption) ||
      '';
    const childCount = deleteMessage.childCount || 0;

    const textChildren = childCount > 0 ? `Zu diesem Vorgang gehören ${childCount} weitere Dokumente.` : '';

    const confirmMsg = `Möchten Sie den Vorgang "${documentName}" wirklich löschen? <br><br> ${textChildren} <br> Wie möchten Sie fortfahren? <br> `;
    return custom({
      title: deleteMessage.title,
      messageHtml: confirmMsg,
      buttons: [
        {
          text: `Nur aktuelles Dokument löschen`,
          icon: 'custom-icon-size material-icons delete',
          onClick: () => this.deleteDocument()
        },
        {
          text: 'Alle Dokumente löschen',
          icon: 'custom-icon-size material-icons delete_sweep',
          onClick: () => this.deleteDocumentWithReferences()
        },
        {
          text: 'Abbrechen',
          icon: 'clear',
          onClick: () => false
        }
      ]
    }) as IConfirmDialog;
  }

  openParentDocument(parent: IDocument): void {
    this.documentHelperService.openDocument(parent);
  }

  processUpload = (): void => {
    const result = this.brainLoopLoginFormComponent?.instance?.validate();
    if (result && !result.isValid) {
      return;
    }
    this.loadPanelService.show(
      `Die Unterlagen werden in den Sicheren Datenraum hochgeladen, bitte warten...`,
      'auto',
      270
    );
    this.documentService
      .upload(this.currentDocument.id, this.brainLoop)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        error: (error: HttpErrorResponse) => {
          this.loadPanelService.hide();
          switch (error.status) {
            case 901:
              NotifyService.global.warn('2-Faktor-Authentifizierung ist Erforderlich.');
              if ((error.error as IUploadError901).type === 'TOTP') {
                this.isBrainLoopTotpRequired = true;
              }
              if ((error.error as IUploadError901).type === 'SMS') {
                this.isBrainLoopPinRequired = true;
                if ((error.error as IUploadError901).information?.expires) {
                  this.brainLoop.expires = (error.error as IUploadError901).information?.expires;
                }
                if ((error.error as IUploadError901).information?.receiver) {
                  this.brainLoop.receiver = (error.error as IUploadError901).information?.receiver;
                  if (this.brainLoop.receiver.includes('@')) {
                    this.brainLoopPinType = 'email';
                  } else {
                    this.brainLoopPinType = 'mobile';
                  }
                }
              }
              break;

            default:
              break;
          }
        },
        complete: () => {
          NotifyService.global.success(`Das Hochladen in den Sicheren Datenraum war erfolgreich.`);
          this.onCancelUpload();
        }
      });
  };

  saveChanges(closeSubject$?: Subject<boolean>, withReload = false): Observable<IDocument> {
    this.loadPanelService.show(null, null, 120);
    return this.documentIncludeComponent?.prepareForSave$().pipe(
      switchMap(document => {
        if (document.fields['--siam-agendaitems'] && document.fields['--siam-agendaitems'].value) {
          (document.fields['--siam-agendaitems'].value as IAgenda[]).forEach((element: IAgenda) => {
            delete element.document;
            if (element.children) {
              element.children.forEach(child => {
                delete child.document;
              });
            }
          });
        }
        return this.agendaService.prepareAgendaToSave(this.currentDocument, document).pipe(map(() => document));
      }),

      switchMap(document => {
        this.agendaService.mapAgendaItemsToServer(this.currentDocument, document);
        return this.documentService.save(document);
      }),
      tap({
        next: savedDocument => {
          if (withReload) {
            this.setCurrentDocument(savedDocument);
          }
          this.resetLoading();
          resolveSubject<boolean>(true, closeSubject$);
        },
        error: () => {
          this.resetLoading();
          resolveSubject<boolean>(false, closeSubject$);
        }
      })
    );
  }

  getWorkflowStatusColor = (): string => {
    if (!this.currentDocument) {
      return '';
    }
    const workflowDocument = this.currentDocument.workflowDocuments.find(
      x => !x.tags.length || x.tags.includes('app:document-type:document-workflow')
    );

    return this.documentService.getWorkflowStateColor(workflowDocument);
  };

  showAudioRecording = (): void => {
    this.isShowAudioRecordingDialog = true;
  };

  private async addDocument(source: IDocument, icon: string, isNewAgenda = false): Promise<IDocument> {
    try {
      const document = await this.documentService.createFromParent(source, this.currentDocument);
      document.fields[siamConst.agendaDocumentId] = { value: this.currentDocument.id };
      if (!isNewAgenda) {
        document.fields['--siam-agendaitems'] = {
          value: this.currentDocument.fields['--siam-agendaitems']?.value as unknown
        };
      }
      const text = document?.template?.caption || '';
      this.meetingMinutes.push({
        document,
        text: isNewAgenda ? text.concat(' (Neu)') : text,
        hint: document?.template?.description || '',
        icon
      });
      return document;
    } catch (error) {
      this.logger.error('--- document-base.component:addDocument() called: createFromParent(): {@error}: ', error);

      NotifyService.global.error(
        `Das Übernehmen der Inhalte ist fehlgeschlagen, die Erstellung des Sitzungsprotokolls wurde abgebrochen. Möglicherweise liegt ein Konfigurationsfehler im entsprechenden Dokumenttyp vor!
        <br> ${(error as TypeError).message}`
      );
    }
    return null;
  }

  private deleteDocument = (): void => {
    this.loadPanelService.show('Löschen...', 90, 120);
    this.documentService
      .delete(this.currentDocument, false, true)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (): void => {
          this.closeDocument();
          NotifyService.global.success('Dokument ist gelöscht...');
        },
        error: () => {
          this.resetLoading();
        }
      });
  };

  private deleteDocumentWithReferences = (): void => {
    this.documentService
      .delete(this.currentDocument, false, true, 1)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (): void => {
          NotifyService.global.success('Dokument wurde gelöscht...');
          this.closeDocument();
          this.resetLoading();
        },
        error: (error: string): void => {
          this.logger.error(error, 'Error deleting the Document', 'error');
        }
      });
  };

  private executeWorkflowAction(action: IWorkflowAction, variables: Record<string, unknown> = {}): void {
    this.loadPanelService.show('Workflowaktivitäten werden ausgeführt...', 130, 270);
    const workflowParameters = this.documentService.getRereferencesObject(this.currentDocument);
    workflowParameters[siamConst.conditionAgendaExistsTag] = false;
    const workflowVariables = Object.assign(variables, workflowParameters);
    this.documentService
      .executeWorkflowAction(action, workflowVariables)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (document): void => {
          this.resetLoading();
          if (document) {
            this.workflowActionVariables = null;
            this.workflowAction = null;
            this.setCurrentDocument(document);
            this.logger.debug('WF-Schritt ausgeführt.');
            NotifyService.global.success(`Der Workflowschritt "${action.label}" wurde ausgeführt.`);
          } else {
            this.closeDocument();
          }
        },
        error: (): void => {
          this.resetLoading();
          NotifyService.global.error(`Der Workflowschritt '${action.label}' konnte nicht ausgeführt werden.`);
        }
      });
  }

  /**
   * Return the Document URL based on the Document Type
   *
   * @param document
   */
  private getDocumentUrl(document: IDocument): string[] {
    if (!document) {
      return null;
    }
    const type = this.documentService.getTypeName(document);
    this.logger.debug('documentform-view.component: getDocumentUrl() for documentType "{@type}"', type);

    const url = ['', 'application', 'documents', type, document.id];
    this.logger.debug('documentform-view.component: getDocumentUrl() for documentUrl "{@url}"', url);

    return url;
  }

  private getNameSelectorSettings(_templateFields: FieldServer): INameSelectorDialogSettings {
    if (!_templateFields) {
      return null;
    }
    const templateFields = copy(_templateFields);
    let include = templateFields.include as INameSelectorDialogInclude[];
    let exclude = templateFields.exclude as INameSelectorDialogInclude[];
    let title = 'Bitte wählen Sie den Empfänger aus:';
    const selectionMode = templateFields.allowMultiple ? 'multiple' : 'single';
    const minSelection =
      selectionMode === 'single' ? 1 : (templateFields as FieldPermissionTargetServ).customValues?.minSelection || 1;
    const forbidRoles = templateFields.forbidRoles;
    const forbidUsers = templateFields.forbidUsers;
    const targetField = (templateFields as FieldPermissionTargetServ).customValues?.targetField || null;
    const targetFieldExclude = (templateFields as FieldPermissionTargetServ).customValues?.targetFieldExclude || null;
    const currentUserExclude = (templateFields as FieldPermissionTargetServ).customValues?.currentUserExclude || null;
    const currentAssigneeExclude =
      (templateFields as FieldPermissionTargetServ).customValues?.currentAssigneeExclude || null;
    if (targetField && targetField?.startsWith('fields')) {
      const fieldName = targetField.split('.')[1];
      if (fieldName) {
        const fieldValue = this.currentDocument.fields[fieldName]?.value as INameSelectorDialogInclude[];
        if (fieldValue) {
          include = fieldValue.concat(include);
        }
      }
    }
    if (targetFieldExclude && targetFieldExclude?.startsWith('fields')) {
      const fieldName = targetFieldExclude.split('.')[1];
      if (fieldName) {
        const fieldValue = this.currentDocument.fields[fieldName]?.value as INameSelectorDialogInclude[];
        if (fieldValue) {
          exclude = fieldValue.concat(exclude);
        }
      }
    }
    if (currentUserExclude) {
      const fieldValue = { type: 'user', targetId: this.currentUser.id } as IPermissionTarget;
      exclude.push(fieldValue);
    }
    if (currentAssigneeExclude) {
      const fieldValue =
        (this.workflowCurrentAssignee as INameSelectorDialogInclude[]) || ([] as INameSelectorDialogInclude[]);
      exclude = fieldValue.concat(exclude);
    }
    let selectType: TNameSelectorDialogType;
    if (forbidRoles && forbidUsers) {
      selectType = 'Personen';
    }
    if (forbidRoles && !forbidUsers) {
      selectType = 'Personen';
    }
    if (forbidUsers && !forbidRoles) {
      selectType = 'Rollen';
      title = 'Bitte wählen Sie die Rolle aus:';
    }
    if (!forbidUsers && !forbidRoles) {
      selectType = 'Beide';
    }

    return {
      title,
      selectType,
      include,
      exclude,
      selectionMode,
      minSelection
    };
  }

  private isTagTrue(tag: string): boolean {
    const key = this.currentDocument?.template?.tags
      .filter(t => t.startsWith(tag))
      .map(t => t.split(new RegExp(`^${tag}`))[1])[0];

    if (key) {
      return key?.toLowerCase() === 'true';
    }

    return false;
  }

  private openConfirmDialog(title: string): void {
    void this.openDeletionConfirmDialog({ title }).then(dialogResult => {
      if (dialogResult) {
        this.deleteDocument();
      }
    });
  }

  private proceedWorkflowAction(variables: Record<string, unknown>): void {
    this.executeWorkflowAction(this.workflowAction.action, variables);
  }

  private setActionOption(action: string, option: string, value: unknown): void {
    const index = this.standardActions.findIndex(item => item.action === action);
    if (index > -1) {
      this.logger.debug('setze ActionOption [{a}] für Action [{b}]', option, action);
      this.standardActions[index][option] = value;
    }
  }

  private setAllowedConfidentials(list: IConfidentialPermission[]): void {
    const noneConfidentialId = '00000000-0000-0000-0000-000000000000';
    const allowedConfidentials: IConfidentialPermissionClient[] = [];
    // in allowed confidentiality array there is always one present - which represents "NONE",
    // and it means, that no other confidentiality levels are set for the document,
    // because even if send to server NULL value, back it sends "NONE" object
    const length = list.length;
    if (length === 1) {
      if (list[0].confidentialId === noneConfidentialId) {
        this.currentDocument.confidential = null;
        this.confidentialAllowed = [];
        return;
      }
    }

    const defaultConfidential = list.find(conf => conf.isDefault);
    // choose default confidential
    if (!this.currentDocument.confidential) {
      let selected: IConfidentialReference;
      if (defaultConfidential) {
        selected = this.currentDocument.template.confidentialReferences.find(
          conf => conf.confidentialId === defaultConfidential.confidentialId
        );
      } else {
        selected = {
          confidentialId: noneConfidentialId,
          allowHardening: true,
          allowWeakening: false,
          children: []
        };
      }

      this.currentDocument.confidential = selected;
    }

    let index = -1;
    if (this.currentDocument.confidential) {
      index = list.findIndex(l => l.confidentialId === this.currentDocument.confidential.confidentialId);
    }

    for (let i = 0; i < length; i++) {
      const l = list[i];
      let name = l.confidential.name;
      let icon = '';

      if (!name.length) {
        name = 'keine Sicherheitsstufe';
        icon = 'lock_open';
      } else {
        if (i < index) {
          icon = 'north_west';
        }
        if (i > index) {
          icon = 'south_east';
        }
        if (i === index) {
          icon = 'east';
          this.confidentialSelected = [
            {
              confidentialId: l.confidentialId,
              name,
              icon
            }
          ];
        }
      }
      if (l.confidentialId === noneConfidentialId) {
        if (defaultConfidential && !defaultConfidential.allowWeakening) {
          continue;
        }
      }

      const confidential: IConfidentialPermissionClient = {
        confidentialId: l.confidentialId,
        name,
        icon
      };
      this.confidentialMap[l.confidentialId] = confidential;
      allowedConfidentials.push(confidential);
    }
    this.confidentialAllowed = allowedConfidentials;
  }

  private setCurrentDocument(document: IDocument): void {
    this.documentToShow = null;
    if (!document) {
      this.resetLoading();
      this.currentDocument = null;
      this.documentExist$.next(false);
      return;
    }
    this.documentExist$.next(true);
    this.loadPanelService.show('Dokumentinformationen werden geladen...', 130, 270);
    this.currentDocument = copy(document);
    this.workflowActions = [];
    this.isAgendaActionLoaded = false;
    this.workflowDocument = null;
    const streams$: Observable<unknown>[] = [];
    this.isShowCategoryTitle = this.isTagTrue(DOCUMENT_TAG_CATEGORY_TITLE);
    if (this.isShowCategoryTitle) {
      streams$.push(
        this.listsService.getList(siamConst.globalTagsListName).pipe(
          tap((list: SiamList) => {
            if (list) {
              const tags = list.entries.map(entry => this.listsService.getTagEntryData(entry));
              const tagType = document.template.tags?.filter(tag => tag.startsWith('app:document-type:'))[0];
              const category = tags.find(tag => tag.key === tagType);
              this.categoryTitle = category ? category.value : '';
            } else {
              this.categoryTitle = '';
            }
          })
        )
      );
    }

    if (!this.currentDocument.id) {
      this.editMode = 'Edit';

      streams$.push(
        this.templateService.getTemplateConfidential(this.currentDocument.templateId).pipe(
          tap((list: IConfidentialPermission[]) => {
            this.setAllowedConfidentials(list);
          })
        )
      );

      BreadcrumbComponent.resetSubBreadcrumb();
      BreadcrumbComponent.addToSubBreadcrumb(this.currentDocument.template.caption, null, 0);
    } else {
      // effektive Berechtigungen ermitteln
      if (!this.currentDocument.effectivePermissions.includes('update')) {
        this.editMode = 'ReadOnly';
      } else {
        if (!this.editMode) {
          this.editMode = 'EditPreview';
        } else {
          this.editMode = 'Edit';
        }
      }

      // Beschlüss Information holen
      streams$.push(from(this.getDecisions()));
      this.decisionTitle = this.documentService.getDecisionInfo(this.currentDocument);
      this.isDecision = this.documentService.isDecision(this.currentDocument);
      this.isAgenda = this.documentService.isAgenda(this.currentDocument);
      this.isMeetingMinutes = this.documentService.isMeetingMinutes(this.currentDocument);
      this.isPattern = this.documentService.isPattern(this.currentDocument);
      // only in case if document has ID already
      this.isCanDelete = document.effectivePermissions.includes('delete');
      this.isShowWorkflowActionSheet = false;
      this.isShowStandardActionsSheet = false;
      this.isHasMeetingMinutes = this.documentService.hasChildOfTag(
        this.currentDocument,
        'app:document-type:meetingMinutes'
      );
      if (this.isHasMeetingMinutes) {
        void this.verifyMeetingMinutesPermissions();
      }

      // add to breadcrumb
      BreadcrumbComponent.resetSubBreadcrumb();
      BreadcrumbComponent.addToSubBreadcrumb(this.currentDocument.template.caption, null, 0);
      const refObject = this.documentService.getRereferencesObject(this.currentDocument);
      streams$.push(
        this.documentService.getDocumentWorkFlow(this.currentDocument, refObject).pipe(
          tap(entry => {
            // mögliche Aktionsobjekte
            this.logger.debug('verfügbare WF-Schritte: {@a}', entry.actions);
            // Liste für die Wf-Auswahl in der GUI
            this.logger.debug('*** zugefügte WF-Aktion {@0}', entry.actionItems);

            this.workflowActions = entry.actionItems;
            this.checkVoteActions();

            // set wf-document
            const documents = this.currentDocument.workflowDocuments;
            this.workflowDocument = documents.find(
              x => !x.tags.length || x.tags.includes('app:document-type:document-workflow')
            );
            this.workflowStatusLabel =
              this.documentService.getWorkflowStateLabel(this.workflowDocument) || '[unbekannt]';
            this.workflowCurrentAssignee = this.documentService.getWorkflowCurrentAssignee(this.workflowDocument);
            this.ballotDocuments = documents.filter(x => x.tags.find(tag => tag === 'app:document-type:ballot'));

            // set Allowed Confidentials
            const list = this.currentDocument.confidentialAllowed;
            this.setAllowedConfidentials(list);
          }),
          switchMap(() =>
            // Mutterdokument holen, falls vorhanden
            this.documentService.getParents(document)
          ),
          tap({
            next: (documents: IDocument[]): void => {
              if (!documents?.length) {
                this.parentDocuments = null;
              }
              if (documents && documents[0] && documents[0].fields) {
                this.parentDocuments = documents;
                for (const parent of documents) {
                  const parentBreadcrumb =
                    (parent.fields?.subject &&
                      `${parent.template.caption} - "${parent.fields.subject.value as string}"`) ||
                    parent.template.caption;
                  const currentBreadcrumb =
                    (this.currentDocument.fields?.subject && (this.currentDocument.fields.subject.value as string)) ||
                    this.currentDocument.template.caption;
                  BreadcrumbComponent.resetSubBreadcrumb();
                  BreadcrumbComponent.addToSubBreadcrumb(parentBreadcrumb, this.getDocumentUrl(parent), 0);
                  BreadcrumbComponent.addToSubBreadcrumb(currentBreadcrumb, null, 1);
                  this.logger.debug('-- ParentDocument: {@0}', parent);
                  this.setActionOption('parent', 'disabled', false);
                }
              }
            },
            error: (): void => {
              this.logger.debug('Fehler beim Ermitteln des parentDocuments.');
            }
          })
        )
      );
    }

    zip(streams$)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: () => {
          this.setAppTitle();
          this.resetLoading();
          this.documentToShow = copy(this.currentDocument);
          this.getAgendaActions();
        },
        error: (error: string): void => {
          this.resetLoading();
          NotifyService.component.error(error);
        },
        complete: () => {
          this.resetLoading();
        }
      });
  }

  /**
   * Set initial values for the component
   * Used every time the document type changed and in constructor
   *
   * @private
   */
  private setInitialValues(): void {
    this.confidentialSelected = [];
    this.confidentialAllowed = [];
    this.confidentialMap = {};
    this.isAgenda = false;
    this.isDecision = false;
    this.isBrainLoopPinRequired = false;
    this.isBrainLoopTotpRequired = false;
    this.isCanDelete = false;
    this.isCanPrint = false;
    this.isHasMeetingMinutes = false;
    this.isHasMeetingMinutesPermissions = false;
    this.isSecureContext = false;
    this.isShowBrainLoopDialog = false;
    this.isShowAudioRecordingDialog = false;
    this.isShowCategoryTitle = false;
    this.isShowConfidentialDropDown = false;
    this.isShowStandardActionsSheet = false;
    this.isShowWorkflowActionSheet = false;
    this.isStickyHeader = false;
    this.meetingMinutes = [];
    this.meetingMinutesPermissions = null;
    this.workflowActions = [];
    this.workflowActionsWithoutVotes = [];
    this.voteActions = [];
  }

  private checkVoteActions(): void {
    this.voteActions = [];
    this.workflowActionsWithoutVotes = [];
    this.voteActions = this.workflowActions.filter(action =>
      action?.action?.userInputTemplate?.tags?.some(t => t.includes('ballot'))
    );
    this.workflowActionsWithoutVotes = this.workflowActions.filter(
      action => !action?.action?.userInputTemplate?.tags?.some(t => t.includes('ballot'))
    );
  }

  private async verifyMeetingMinutesPermissions(): Promise<void> {
    const child = this.documentService.getChildDocumentWithTag(
      this.currentDocument,
      'app:document-type:meetingMinutes'
    );
    if (child) {
      const documentId = (child.document && child.document.id) || child.documentId;
      try {
        const permissions = await lastValueFrom(this.documentService.getPermissions(documentId));
        this.isHasMeetingMinutesPermissions = permissions?.includes('read') || permissions?.includes('list');
        this.meetingMinutesPermissions = permissions;
      } catch (error) {
        this.isHasMeetingMinutesPermissions = false;
        this.meetingMinutesPermissions = [];
      }
    }
  }

  private isShowApprovalDialog(): boolean {
    const isAgenda = this.documentService.isAgenda(this.currentDocument);
    const isMeetingMinutes = this.documentService.isMeetingMinutes(this.currentDocument);
    const isProtocol = this.documentService.isProtocol(this.currentDocument);
    const isTask = this.documentService.isTask(this.currentDocument);
    return (
      this.workflowAction?.action.name.startsWith('approv') && !isAgenda && !isMeetingMinutes && !isProtocol && !isTask
    );
  }

  private resetLoading(): void {
    this.loadPanelService.hide();
  }

  private setAppTitle(): void {
    if (this.currentDocument) {
      const title = this.categoryTitle?.length
        ? `${this.categoryTitle} - ${this.currentDocument.template.caption}`
        : `${this.currentDocument.template.caption}`;
      this.titleService.setTitle(`${title} | ${siamConst.appTitle}`);
    }
  }

  private createDocumentFromTemplateId(templateId: string): Observable<IDocument> {
    if (templateId === _defaultDecisionId) {
      return of(copy(_defaultDecisionDocument) as unknown as IDocument);
    }
    return this.templateService.getTemplate(templateId).pipe(
      switchMap(template => this.documentService.createDocumentByTemplateId(template)),
      takeUntil(this.#destroyable$)
    );
  }

  private createDecisionDocumentFromDecisionStamp(decisionStamp: IDecisionStamp): Observable<IDocument> {
    const templateId = decisionStamp.dialogId;
    const isDefault = templateId === _defaultDecisionId || !templateId;
    let stream$: Observable<IDocument>;
    if (isDefault) {
      stream$ = of(copy(_defaultDecisionDocument) as unknown as IDocument);
    } else {
      stream$ = this.templateService
        .getTemplate(templateId)
        .pipe(switchMap(template => this.documentService.createDocumentByTemplateId(template)));
    }
    return stream$.pipe(
      switchMap(document => zip(this.listsService.getListsWithEntries(), of(document))),
      map(tuple => ({ lists: tuple[0], document: tuple[1] })),
      map(response => {
        const document = response.document;
        const lists = response.lists;
        const decisionCategoryList = lists.find(list => list.id === decisionStamp.decisionCategoriesId);
        const decisionChoicesList = lists.find(list => list.id === decisionStamp.decisionChoicesId);
        const fieldCategory = isDefault ? 'category' : decisionStamp.decisionDialogFieldMapping['category'];
        const fieldType = isDefault ? 'decision' : decisionStamp.decisionDialogFieldMapping['decision'];
        if (!decisionCategoryList || !decisionChoicesList || !fieldCategory || !fieldType) {
          NotifyService.global.warn('Beschluss kann nicht erstellt werden');
          return null;
        }
        document.template.fields.map(field => {
          if (field.name === fieldCategory) {
            field.choices = decisionCategoryList.entries;
          }
          if (field.name === fieldType) {
            field.choices = decisionChoicesList.entries;
          }
        });
        return document;
      }),
      takeUntil(this.#destroyable$)
    );
  }

  private createDecisionDocumentFromDecisionValue(
    decisionStamp: FieldDecisionValue,
    category: string
  ): Observable<IDocument> {
    const templateId = decisionStamp.documentTemplateId;
    const isDefault = templateId === _defaultDecisionId || !templateId;
    let stream$: Observable<IDocument>;
    if (isDefault) {
      stream$ = of(copy(_defaultDecisionDocument) as unknown as IDocument);
    } else {
      stream$ = this.templateService
        .getTemplate(templateId)
        .pipe(switchMap(template => this.documentService.createDocumentByTemplateId(template)));
    }
    return stream$.pipe(
      switchMap(document => zip(this.listsService.getListsWithEntries(), of(document))),
      map(tuple => ({ lists: tuple[0], document: tuple[1] })),
      map(response => {
        const document = response.document;
        const lists = response.lists;
        const decisionCategoryList = lists.find(list => list.id === decisionStamp.fields.decisionCategoriesId?.value);
        const decisionChoicesList = lists.find(list => list.id === decisionStamp.fields.decisionChoicesId?.value);
        const fieldCategory = isDefault ? 'category' : decisionStamp.fieldMap['category'];
        const fieldType = isDefault ? 'decision' : decisionStamp.fieldMap['decision'];
        if (!decisionCategoryList || !decisionChoicesList || !fieldCategory || !fieldType) {
          NotifyService.global.warn('Beschluss kann nicht erstellt werden');
          return null;
        }
        document.template.fields.map(field => {
          if (field.name === fieldCategory) {
            field.choices = decisionCategoryList.entries;
          }
          if (field.name === fieldType) {
            field.choices = decisionChoicesList.entries;
          }
        });
        const fields = copy(decisionStamp.fields);
        Object.keys(decisionStamp.fieldMap).forEach(key => {
          const fieldName = decisionStamp.fieldMap[key];
          if (key === 'decision') {
            fields[fieldName] = { value: decisionStamp.decision };
          }
          if (key === 'category') {
            fields[fieldName] = { value: category };
          }
          if (key === 'date') {
            fields[fieldName] = { value: decisionStamp.date };
          }
          if (key === 'number') {
            fields[fieldName] = { value: decisionStamp.number };
          }
        });
        const resultFields: IDocumentFields = {};

        for (const key in fields) {
          const currentItem = fields[key];
          const newItem: IDocumentField = { value: null, effectivePermissions: ['read'] };
          if (currentItem.value !== undefined) {
            newItem.value = currentItem.value as unknown;
          }

          resultFields[key] = newItem;
        }
        document.fields = resultFields;
        return document;
      }),
      takeUntil(this.#destroyable$)
    );
  }

  private async getDecisions(): Promise<IDocumentDecision[]> {
    const result: IDocumentDecision[] = [];
    const decisionValue = this.currentDocument.fields[siamConst.decisionField]?.value as FieldDecisionsValue;
    if (!decisionValue) {
      return result;
    }
    for (const key of Object.keys(decisionValue)) {
      const decision = decisionValue[key];
      const document = await lastValueFrom(this.createDecisionDocumentFromDecisionValue(decision, key));
      document.template.caption = decision.caption;
      if (decision.status !== 'deleted') {
        const isActive = decision.status === 'final';
        result.push({
          document,
          isActive,
          color: isActive ? 'green' : 'red',
          title: decision.number
        });
      }
    }
    this.decisionDocuments = result;
    return result;
  }

  private flattenHierarchy(hierarchicalArray: TreeViewItem[]): TreeViewItem[] {
    if (!hierarchicalArray?.length) {
      return [];
    }
    const flatArray: TreeViewItem[] = [];
    hierarchicalArray.forEach(item => {
      const flattenedItem = {
        ...item,
        children: [] as TreeViewItem[]
      };

      flatArray.push(flattenedItem);

      if (item.children && item.children.length > 0) {
        const childrenFlatArray = this.flattenHierarchy(item.children);
        flatArray.push(...childrenFlatArray);
      }
      if (item.tasks && item.tasks.length > 0) {
        const childrenFlatArray = this.flattenHierarchy(item.tasks);
        flatArray.push(...childrenFlatArray);
      }
      if (item.submissions && item.submissions.length > 0) {
        const childrenFlatArray = this.flattenHierarchy(item.submissions);
        flatArray.push(...childrenFlatArray);
      }
      if (item.protocol) {
        flatArray.push(item.protocol);
      }
    });
    // Aufgaben und TOP-Protokolle ohne Parent element enfernen
    return [...new Set(flatArray)].filter(item => item.type !== 'top-submissions');
  }

  private getAgendaActions = (): void => {
    const _defaultAgendaActions: IActionSheetAction[] = [
      {
        text: 'Sitzungsprotokoll öffnen',
        visible: this.isHasMeetingMinutes && this.isHasMeetingMinutesPermissions,
        icon: 'material-icons edit',
        disabled: false,
        action: 'open-meeting-minutes'
      },
      {
        text: 'Muster erstellen',
        visible: this.isCanCreatePattern,
        disabled: false,
        icon: 'material-icons view_timeline',
        action: 'create-pattern'
      },
      {
        text: `"${this.currentDocument.template.caption}" im Datenraum bereitstellen`,
        icon: 'upload',
        visible: this.isCanSdrUpload,
        disabled: false,
        action: 'sdr-upload'
      }
    ];
    const meetingMinutesActions = this.meetingMinutes.map(
      meetingMinutes =>
        ({
          action: 'create-meeting-minutes',
          icon: 'plus',
          text: meetingMinutes.text,
          hint: meetingMinutes.hint,
          document: meetingMinutes.document,
          visible: !this.isHasMeetingMinutes
        } as IActionSheetAction)
    );
    this.agendaActions = meetingMinutesActions.concat(_defaultAgendaActions);
  };
}
