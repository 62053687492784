import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  mainTag: HTMLElement;
  wrapperTag: HTMLElement;

  ngOnInit(): void {
    this.toggleClass();
  }

  ngOnDestroy() {
    this.toggleClass();
  }
  private toggleClass(): void {
    this.mainTag = document.querySelector('main');
    this.wrapperTag = document.querySelector('.app-wrapper');
    if (this.mainTag) {
      this.mainTag.classList.toggle('fade404');
    } else {
      this.wrapperTag.style.backgroundImage = "url('/assets/img/background-pic/space_2.jpg')";
    }
  }
}
