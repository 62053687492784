import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { RoleService } from '@services/roles.service';
import { UserService } from '@services/user.service';
import { IPermissionTarget, IRole, IUser, IUserPipe } from '@interfaces/siam';

@Pipe({
  name: 'getNameData'
})
export class GetNameDataPipe implements PipeTransform {
  constructor(private userService: UserService, private roleService: RoleService) {}

  transform(
    value: IPermissionTarget | IUser | IRole | Record<string, string> | string,
    logonUserId?: string
  ): Observable<IUserPipe> {
    let target: IPermissionTarget | IUser | IRole;
    let streamUser$: Observable<IUser>;
    let streamLogonUser$: Observable<IUser>;
    let streamRole$: Observable<IRole>;
    if (value) {
      if ((value as IUser).profile) {
        streamUser$ = of(value as IUser);
      } else if (((value as IRole).users || (value as IRole).compositeId?.startsWith('role')) && (value as IRole).id) {
        streamRole$ = of(value as IRole);
      } else {
        if (typeof value === 'string') {
          value = JSON.parse(value) as IPermissionTarget | IUser | IRole;
        }
        if (Array.isArray(value)) {
          target = value[0] as IPermissionTarget | IUser | IRole;
        } else {
          target = value as IPermissionTarget | IUser | IRole;
        }
      }
    }

    if (logonUserId) {
      streamLogonUser$ = this.userService.getUser(logonUserId);
    } else {
      streamLogonUser$ = of(null);
    }
    if (target) {
      switch ((target as IPermissionTarget).type) {
        case 'user':
          {
            if (!streamUser$) {
              const id = (target as IPermissionTarget).targetId;
              if (id) {
                streamUser$ = this.userService.getUser(id);
              } else {
                streamUser$ = of(null);
              }
            }
          }
          break;

        case 'role':
          {
            const id = (target as IPermissionTarget).targetId;
            if (id) {
              streamRole$ = this.roleService.getRole(id);
            } else {
              streamRole$ = of(null);
            }
          }
          break;

        default: {
          if ((target as IUser).id) {
            streamUser$ = this.userService.getUser((target as IUser).id);
          }
        }
      }
    }

    if (streamUser$) {
      return streamUser$.pipe(
        switchMap(user => zip(streamLogonUser$, of(user))),
        map(tuple => ({ user: tuple[1], logonUser: tuple[0] })),
        take(1),
        map(response => {
          const user = response.user;
          const logonUser = response.logonUser;
          let separator = '';
          if (logonUser && logonUser.id !== user?.id) {
            separator = '<span class="user-substitute-tooltip"> *</span>';
          }

          let id = '';
          let name = '';
          let loginName = '';
          let avatarUrl = '';
          let avatar = '';
          let avatarName = '';
          let avatarNameBig = '';
          let initials = '';
          let department = '';
          let fullName = '';
          let lastname = '';
          if (user) {
            const archivedClass = user.isArchived ? ' is-archived-user' : '';
            id = user.id;
            name = id ? user.displayName : '[Benutzer wurde nicht gefunden]';
            loginName = user.name;
            fullName = name;
            lastname = name;
            avatarUrl = this.userService.getAvatarUrl(user);
            avatar = `<div class="name-container${archivedClass}" data-user-id="${id}"><img class="user-avatar" src="${avatarUrl}"></div>`;
            avatarName = `<div class="name-container${archivedClass}" data-user-id="${id}"><img class="user-avatar-name" src="${avatarUrl}">${name}${separator}</div>`;
            avatarNameBig = `<div class="name-container${archivedClass}" data-user-id="${id}"><img class="user-avatar-name-big" src="${this.userService.getAvatarUrl(
              user
            )}">${name}</div>`;
            const profile = user.profile;
            if (profile) {
              if (profile.surname && profile.forename) {
                const forename = profile.forename || '';
                const surname = profile.surname || '';
                initials = forename.charAt(0) + surname.charAt(0);
                fullName = `${forename} ${surname}`;
                if (surname) {
                  lastname = surname;
                }
              }
              department = profile.department || '';
              if (profile?.picture?.hasDefaultPicture) {
                avatar = `<div class="name-container${archivedClass}" data-user-id="${id}"><span class="no-avatar" data-initials="${profile.picture.displayNamePicture}"></span></div>`;
                avatarName = `<div class="name-container${archivedClass}" data-user-id="${id}"><span class="no-avatar-name" data-initials="${profile.picture.displayNamePicture}"></span>${name}${separator}</div>`;
                avatarNameBig = `<div class="name-container${archivedClass}" data-user-id="${id}"><span class="no-avatar-name-big" data-initials="${profile.picture.displayNamePicture}"></span>${name}</div>`;
              }
            }
          }
          return {
            id,
            name,
            loginName,
            fullName,
            lastname,
            avatarName,
            avatar,
            avatarNameBig,
            avatarUrl,
            initials,
            department
          };
        })
      );
    } else if (streamRole$) {
      return streamRole$.pipe(
        take(1),
        map(role => {
          let name = '';
          let avatar = '';
          let avatarName = '';
          let id = '';
          let fullName = '';
          let lastname = '';
          if (role) {
            const archivedClass = role.isArchived ? ' is-archived-user' : '';
            id = role.id;
            name = role.name;
            fullName = name;
            lastname = name;
            avatar = `<div class="name-container${archivedClass}"><i class="material-icons mr-1">group</i></div>`;
            avatarName = `<div class="name-container${archivedClass}"><i class="material-icons mr-1">group</i>${name}</div>`;
          }
          return {
            id,
            name,
            avatar,
            avatarName,
            fullName,
            lastname,
            avatarNameBig: '',
            avatarUrl: '',
            initials: '',
            department: ''
          };
        })
      );
    }
    return of({
      id: '',
      name: '',
      avatarName: '',
      avatar: '',
      avatarNameBig: '',
      avatarUrl: '',
      initials: '',
      department: '',
      fullName: '',
      lastname: ''
    });
  }
}
