import { FeedbackService } from '@services/feedback.service';
import { NotifyService } from '@services/notify.service';
import { DxFormComponent } from 'devextreme-angular';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { ConfigurationService } from '@services/configuration.service';
import { IError, IFeedback } from '@interfaces/siam';
import { SharedataService } from '@services/sharedata.service';
import { editorToolbarItems } from '@factories/helpers';
import { LogsService } from '@services/logs.service';
import { LoginService } from '@services/login.service';
import { SiamInfoService } from '@services/siam-info.service';
import { DateTime } from 'luxon';
import { LoadPanelService } from '@services/load-panel.service';
import { ValueChangedEvent } from 'devextreme/ui/select_box';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnDestroy {
  @ViewChild('feedbackForm') feedbackForm: DxFormComponent;

  currentFeedback: IFeedback;
  feedbackEmail: Observable<string>;
  sendButtonVisible = true;
  showFeedback = false;
  showFeedbackForm: boolean;
  subjects = ['Verbesserungsvorschlag', 'Wünsche', 'Fehler!', 'Allgemein'];
  editorToolbarItems = editorToolbarItems();

  private version: string;
  #isFehler = false;
  #subscription$: Subscription;
  #destroyable$ = new Subject<void>();

  constructor(
    private configurationService: ConfigurationService,
    private feedbackService: FeedbackService,
    private sharedData: SharedataService,
    private loadPanelService: LoadPanelService,
    private logService: LogsService,
    private loginService: LoginService,
    private siamInfoService: SiamInfoService
  ) {
    this.feedbackEmail = this.configurationService.getFeedbackEmail();
    this.siamInfoService
      .productVersion()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({ next: info => (this.version = info.version) });
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
    if (this.#subscription$) {
      this.#subscription$.unsubscribe();
    }
  }

  show(): void {
    this.currentFeedback = {
      subject: this.subjects[0],
      body: null,
      isHtmlBody: false,
      attachments: []
    };
    this.showFeedback = true;
    this.showFeedbackForm = true;
    this.sendButtonVisible = true;
  }

  closeFeedback = (): void => {
    this.showFeedback = false;
    this.showFeedbackForm = false;
  }

  onSubjectChange = (e: ValueChangedEvent): void => {
    this.#isFehler = (e.value as string) === this.subjects[2];
    if (this.#isFehler) {
      const toDate = new Date();
      const fromDate = new Date(Math.abs(Date.now() - 60 * 60 * 1000));
      const forUser = this.loginService.currentUser$?.value?.name;
      const maxEntries = 1000;
      this.logService
        .download(fromDate, toDate, forUser, maxEntries, null)
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          next: data => {
            const fileName = `AgiliCision_V${this.version}_LogExport_${this.getDate()}.zip`;
            const attachment = new File([data.zip], fileName);
            const attachments = this.currentFeedback.attachments || [];
            attachments.push(attachment);
            this.feedbackForm.instance.updateData('attachments', attachments);
          },
          error: NotifyService.component.error
        });
    }
  }

  sendFeedback = (): void => {
    const result = this.feedbackForm.instance.validate();
    if (!result.isValid) {
      NotifyService.component.error('Bitte füllen Sie alle Pflichtfelder aus.');
      return;
    }
    this.loadPanelService.show('Die Nachricht wird versendet...', 130, 270);
    this.#subscription$ = this.feedbackService.sendFeedback(this.feedbackForm.formData as IFeedback).subscribe({
      next: () => {
        this.loadPanelService.hide();
        this.showFeedbackForm = false;
        this.sendButtonVisible = false;
        if (this.#isFehler) {
          this.sharedData.clearNotifications();
        }
      },
      error: error => {
        this.loadPanelService.hide();
        NotifyService.component.error(error as IError);
      }
    });
  };

  private getDate(): string {
    return DateTime.now().toFormat('MM-dd-yyyy_HH-mm');
  }
}
