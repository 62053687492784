import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { IError, ILogServer, TLogLevel } from '@interfaces/siam';
import { MessageTemplate } from '@factories/messageTemplate';
import { SharedataService } from '@services/sharedata.service';
import 'ulog';
import anylogger, { Logger } from 'anylogger';
import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';
import loglevel from 'loglevel'
import Timeout = NodeJS.Timeout;

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  private readonly apiBaseUrl: string;
  private readonly isSuppressErrors: boolean;
  private readonly logBatchSize: number;
  private readonly logBatchTimeout: number;
  private readonly logger: Logger;
  private readonly loggerName = 'AgiliCision';
  private readonly event$ = new Subject<number>();
  private events: ILogServer[] = [];
  private bufferedLogs: ILogServer[] = [];
  private interval: Timeout;

  constructor(private http: HttpClient,
              private sharedDataService: SharedataService,
              @Inject('BASE_URL') private baseUrl: string) {
    this.apiBaseUrl = `${baseUrl}api/log`;
    this.logger = anylogger(this.loggerName);
    this.logBatchSize = environment.logBatchSize;
    this.logBatchTimeout = environment.logBatchTimeout * 1000;
    this.isSuppressErrors = environment.logSuppressErrors;

    // set log level in the localstorage only once, and never override the value
    if (!localStorage.getItem('log')) {
      localStorage.setItem('log', environment.logLevel)
    }

    this.event$
      .pipe(
        filter(() => this.logBatchSize && this.events.length >= this.logBatchSize),
        switchMap(() => this.postEvents())
      ).subscribe();

    this.resetInterval();
  }

  private static convertLogLevel(level: TLogLevel): loglevel.LogLevelDesc {
    switch (level) {
      case 'debug':
        return loglevel.levels.DEBUG;

      case 'information':
        return loglevel.levels.INFO;

      case 'warning':
        return loglevel.levels.WARN;

      case 'error':
      case 'fatal':
        return loglevel.levels.ERROR;

      case '':
        return loglevel.levels.SILENT;

      default:
        return loglevel.levels.TRACE;
    }
  }

  /**
   * CryptoJS WordArray to ArrayBuffer
   *
   * https://github.com/brix/crypto-js/issues/274
   *
   * @param wordArray
   * @private
   */
  private static cryptJsWordArrayToUint8Array(wordArray: CryptoJS.lib.WordArray): ArrayBuffer {
    const length = wordArray.sigBytes;
    const words = wordArray.words;
    const result = new Uint8Array(length);
    let i = 0 /*dst*/;
    let j = 0 /*src*/;
    /* eslint-disable */
    while (true) {
      // here i is a multiple of 4
      if (i === length) {
        break;
      }

      const w = words[j++];

      result[i++] = (w & 0xff000000) >>> 24;

      if (i === length) {
        break;
      }

      result[i++] = (w & 0x00ff0000) >>> 16;

      if (i === length) {
        break;
      }

      result[i++] = (w & 0x0000ff00) >>> 8;

      if (i === length) {
        break;
      }

      result[i++] = (w & 0x000000ff);
    }
    /* eslint-enable */
    return result.buffer;
  }

  /**
   * Try to get caller name
   *
   * @private
   */
  private static getCallerName(): string {
    try {
      throw new Error();
    } catch (e) {
      try {
        const splits = (e as Error).stack.split('at ');
        return splits[4].split(' ')[0].split('.').slice(-2).join('.');
      } catch (ex) {
        return '';
      }
    }
  }

  /**
   * Erstellung der Details einer Meldung basierend für einen Fehler
   *
   * @param error
   * @private
   */
  private static getDetails(error: Error): string {
    if (error) {
      try {
        return JSON.stringify(error);
      } catch {
        try {
          if (error.stack) {
            return `${error.name}: ${error.message}\n${error.stack}`;
          }

          return `${error.name}: ${error.message}`;
        } catch {
          return error.toString();
        }
      }
    }
    return null;
  }

  getLogLevel(): Observable<TLogLevel> {
    return this.http.get<TLogLevel>(`${this.apiBaseUrl}/level/standard`)
      .pipe(
        tap({
          next: level => {
            this.sharedDataService.setLogLevel(level);
          }
        }),
        catchError(() => of(null))
      );
  }

  public info(template: Error | string, ...properties: unknown[]): void {
    this.proceedEvent('information', template, properties);
  }

  public debug(template: Error | string, ...properties: unknown[]): void {
    this.proceedEvent('debug', template, properties);
  }

  public warn(template: Error | string, ...properties: unknown[]): void {
    this.proceedEvent('warning', template, properties);
  }

  public error(template: Error | string, ...properties: unknown[]): void {
    this.proceedEvent('error', template, properties);
  }

  public log(template: Error | string, ...properties: unknown[]): void {
    this.proceedEvent('verbose', template, properties);
  }

  public trace(template: Error | string, ...properties: unknown[]): void {
    this.proceedEvent('verbose', template, properties);
  }

  private proceedEvent(level: TLogLevel, template: Error | string, ...properties: unknown[]): void {

    this.resetInterval();

    let logLevel: loglevel.LogLevelDesc;
    const serverLogLevel = this.sharedDataService.getLogLevel();
    if (serverLogLevel) {
      logLevel = LoggerService.convertLogLevel(serverLogLevel);
    } else {
      logLevel = LoggerService.convertLogLevel(environment.logLevel as TLogLevel);
    }

    if (LoggerService.convertLogLevel(level) >= logLevel) {
      const callerName = LoggerService.getCallerName();
      // eslint-disable-next-line
      properties.push({ SourceContext: callerName });
      try {
        let event: ILogServer;
        if (template instanceof Error) {
          event = this.addEvent(level, `SourceContext: ${callerName}`, properties.slice(1), template);
        } else {
          event = this.addEvent(level, template, properties);
        }
        this.logger(level, event.message);
      } catch (error) {
        if (!this.isSuppressErrors) {
          throw error;
        }
      }
    }
  }

  private addEvent(
    logLevel: TLogLevel,
    template: string,
    unboundProperties: unknown[],
    error?: Error): ILogServer {

    const messageTemplate = new MessageTemplate(template);
    const properties = messageTemplate.bindProperties(unboundProperties);
    const event = {
      timestamp: new Date().toISOString(),
      logLevel,
      userName: '',
      message: messageTemplate.render(properties),
      details: LoggerService.getDetails(error),
      properties
    }

    this.events.push(event);
    this.event$.next(Date.now());

    return event;
  }

  /**
   * Send batch of events to server
   *
   * @private
   */
  private postEvents(): Observable<unknown> {
    const encryption = this.sharedDataService.getEncryption();
    const collectedEvents = [...this.bufferedLogs, ...this.events];
    if (!collectedEvents.length || !encryption?.key) {
      this.resetInterval();
      return of(null);
    }

    this.removeInterval();

    const eventsToSend = collectedEvents.splice(0, this.logBatchSize || 10);
    this.bufferedLogs = [];
    this.events = collectedEvents;

    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(eventsToSend)),
      CryptoJS.enc.Base64.parse(encryption.key),
      {
        iv: CryptoJS.enc.Base64.parse(encryption.iv)
      });

    const headers = new HttpHeaders({
      // eslint-disable-next-line
      'Content-Type': 'application/encrypted-json'
    });

    return this.http.post(this.apiBaseUrl, LoggerService.cryptJsWordArrayToUint8Array(encrypted.ciphertext), { headers })
      .pipe(
        tap(() => {
          this.resetInterval();
        }),
        catchError((error: IError) => this.handleError(error, eventsToSend))
      );
  }

  private handleError(error: IError, logEntries: ILogServer[]): Observable<never> {
    this.resetInterval();

    this.bufferedLogs = logEntries;

    if (error.error instanceof ErrorEvent) {
      // Einfach durchreichen
      if (!this.isSuppressErrors) {
        throwError(() => error);
      }
    } else if (error.status === 401) {
      if (!this.isSuppressErrors) {
        // 401: Nicht authentifiziert
        console.warn(`${logEntries.length} Log-Eintrag wurde zwischengespeichert.`);
      }
    } else {
      if (!this.isSuppressErrors) {
        throwError(() => error);
      }
    }
    return EMPTY;
  }

  private setBatchTimeout(): void {
    let subscription: Subscription;
    if (this.logBatchTimeout) {
      this.interval = setInterval(() => {
        subscription = this.postEvents()
          .subscribe(() => {
            if (subscription) {
              subscription.unsubscribe();
            }
          });
      }, this.logBatchTimeout);
    }
  }

  private resetInterval(): void {
    this.removeInterval();
    this.setBatchTimeout();
  }

  private removeInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}
