import { UserState } from '../entities/user.entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { copy } from '@factories/user.factory';
import { IUser } from '@interfaces/siam';


export const getUserState = createFeatureSelector<UserState>('userState');

export const allUsers = createSelector(
  getUserState,
  users => Object.values(users.entities).map(user => copy(user))
);

// eslint-disable-next-line
export const getUserById = (id: string): MemoizedSelector<object, IUser> => createSelector(
  getUserState,
  state => state.entities[id] ? copy(state.entities[id]) : null
);
