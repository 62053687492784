import { Component } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { IHomePageSetting, ISiamInfo } from '@interfaces/siam';
import { SiamInfoService } from '@services/siam-info.service';
import { SharedataService } from '@services/sharedata.service';

interface Info {
  product: ISiamInfo;
  server: ISiamInfo;
  webapp: ISiamInfo;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {
  showHelp = false;
  homePageSetting: IHomePageSetting;
  info$: Observable<Info>;

  constructor(private siamInfoService: SiamInfoService, public sharedData: SharedataService) {
    this.info$ = zip(
      this.siamInfoService.productVersion(),
      this.siamInfoService.serverVersion(),
      this.siamInfoService.webAppVersion(),
      this.siamInfoService.environment(),
    ).pipe(
      map(response => ({
        product: response[0],
        server: response[1],
        webapp: response[2],
        environment: response[3],
      }))
    );
    this.sharedData.getHomePageSetting$.subscribe({
      next: data => {
        this.homePageSetting = data;
      }
    });
  }

  show(): void {
    this.showHelp = true;
  }

  closeHelp = (): void => {
    this.showHelp = false;
  };
}
