<ol class="breadcrumbs" *ngIf="breadcrumbs && breadcrumbs.length">
  <li class="breadcrumbs-item">
    <a [routerLink]="['home']"><i class="material-icons">home</i></a>
  </li>
  <li class="breadcrumbs-item" *ngFor="let breadcrumb of breadcrumbs">
    <a *ngIf="(breadcrumb && breadcrumb.url && breadcrumb.url.length) && (breadcrumb.label !== 'Inhalte'); else elseblock"
      [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
    <ng-template #elseblock></ng-template>
  </li>
  <li class="breadcrumbs-item" *ngFor="let cBreadcrumb of subBreadcrumbs">
    <a *ngIf="cBreadcrumb && cBreadcrumb.url && cBreadcrumb.url.length; else elseblock"
      [routerLink]="cBreadcrumb.url">{{ cBreadcrumb.label }}</a>
    <ng-template #elseblock>{{ cBreadcrumb.label }}</ng-template>
  </li>
</ol>
