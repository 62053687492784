import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IRole } from '@interfaces/siam';
import * as fromStore from '../../store';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { RoleApi } from '../api/role.api';
import { Store } from '@ngrx/store';
import * as Factory from '../factories/role.factory';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  #isAllLoaded = false;

  constructor(private api: RoleApi,
              private store: Store<fromStore.AppState>) {
  }

  create(role: IRole): Observable<IRole> {
    return this.api.create(role).pipe(
      tap(createdRole => {
        this.store.dispatch(new fromStore.CreateRole({ role: createdRole }));
      })
    );
  }

  /**
   * deleting the role from DB
   *
   * @param role
   */
  delete(role: IRole): Observable<IRole> {
    return this.api.delete(role).pipe(
      tap(() => {
        this.store.dispatch(new fromStore.DeleteRole({ role }));
      })
    );
  }

  /**
   * Get all roles
   *
   * @params force?: force to get data from DB directly
   * @return list of roles
   */
  getAllRoles(force = false): Observable<IRole[]> {
    if (!this.#isAllLoaded) {
      force = true;
    }
    return this.getRoles(null, force);
  }

  getActiveRoles(): Observable<IRole[]> {
    return this.getAllRoles().pipe(
      switchMap(roles => of(roles.filter(role => role.isArchived === false).map(user => Factory.copy(user))))
    );
  }

  getAllVisibleRoles(force = false): Observable<IRole[]> {
    if (!this.#isAllLoaded) {
      force = true;
    }
    return this.getRoles(null, force).pipe(
      map(roles => roles.filter(r => r.tags.some(t => t !== 'interne Rollen') || !r.tags.length))
    );
  }

  getRole(roleId: string): Observable<IRole> {
    return this.store.select(fromStore.getRoleById(roleId)).pipe(
      first(),
      switchMap(role => {
        if (!role) {
          return this.api.getRole(roleId);
        }
        return of(role);
      }),
      map(role => Factory.copy(role))
    );
  }

  /**
   * Get certain roles
   *
   * @return list of users
   * @param roleIds
   * @param force
   */
  getRoles(roleIds?: string[], force = false): Observable<IRole[]> {
    let stream$: Observable<IRole[]>;
    let isUpdateCache = false;
    if (force) {
      isUpdateCache = true;
      stream$ = this.api.getRoles(roleIds);
    } else {
      stream$ = this.store.select(fromStore.getAllRoles).pipe(first());
    }
    return stream$.pipe(
      switchMap(roles => {
        if (!roles || !roles.length) {
          isUpdateCache = true;
          return this.api.getRoles(roleIds);
        }
        return of(roles);
      }),
      tap(roles => {
        if (isUpdateCache) {
          this.store.dispatch(new fromStore.SetRoles({ roles }));
          isUpdateCache = false;
          if (!roleIds || !roleIds.length) {
            this.#isAllLoaded = true;
          }
        }
      }),
      map(roles => {
        if (Array.isArray(roleIds) && roleIds.length) {
          roles = roles.filter(role => roleIds.indexOf(role.id) !== -1);
        }
        return roles.map(user => Factory.copy(user));
      })
    );
  }

  /**
   * Get all Tags used in an Role
   */
  getRoleTagList(roles: IRole[]): string[] {
    return roles
      .reduce((pv, r) => pv.concat(r.tags), ['interne Rollen'])
      .filter((value, index, tags) => tags.indexOf(value) === index);
  }

  onLogout(): void {
    this.#isAllLoaded = false;
  }

  /**
   * update a role
   *
   * @param role
   */
  update(role: IRole): Observable<IRole> {
    return this.api.update(role).pipe(
      tap(updatedRole => {
        this.store.dispatch(new fromStore.UpdateRole({ role: updatedRole }));
      })
    );
  }
}
