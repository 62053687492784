import { Component } from '@angular/core';
import { NotifyService } from '@services/notify.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ISiamNotification } from '@interfaces/siam';

@Component({
  selector: 'app-global-notification',
  templateUrl: './global-notification.component.html',
  styleUrls: ['./global-notification.component.scss']
})
export class GlobalNotificationComponent {

  isShowDetails: boolean[] = [];
  notifications: ISiamNotification[] = [];

  constructor(private router: Router) {
    /**
     * Notification: automatisch ausblenden: when the route changed
     */
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeGlobalNotifications();
      });

    NotifyService.getGlobalNotifications().subscribe(notifications => {
      this.notifications = notifications;
    });
  }

  /**
   * empty the notifier
   */
  closeGlobalNotifications(): void {
    this.isShowDetails = [];
    NotifyService.destroyNotifications();
  }
}
