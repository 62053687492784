import { Pipe, PipeTransform } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { DocumentService } from '@services/document.service';
import { IAgenda, IDocument } from '@interfaces/siam';

@Pipe({
  name: 'getProtocol',
})
export class GetProtocolPipe implements PipeTransform {
  constructor(private _documentService: DocumentService) {
  }

  async transform(item: IAgenda): Promise<IDocument> {
    if (item.documentId) {
      const document = await lastValueFrom(this._documentService.getDocumentById(item.documentId));
      const protocolRef = document.references.filter(
        ref => ref.tags.findIndex(tag => tag === 'app:document-type:protocol') !== -1,
      )[0];
      if (protocolRef) {
        const protocolId =
          (protocolRef.document && protocolRef.document.id) ||
          protocolRef.documentId ||
          protocolRef.referencedDocumentId;
        return await lastValueFrom(this._documentService.getDocumentById(protocolId));
      }
    }
    return null;
  }
}
