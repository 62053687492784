import { Injectable } from '@angular/core';
import { UserNotification } from '@interfaces/userNotification';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client';
import { IGQLResult } from '@interfaces/siam';

interface IGraphqlResponse {
  notification?: UserNotification;
  create?: UserNotification;
  update?: UserNotification;
  result?: UserNotification;
  userNotifications?: UserNotification[];
}

interface IGraphqlCreateNotification {
  notifications: IGraphqlResponse;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private apollo: Apollo) {
  }

  getNotifications(): Observable<UserNotification[]> {
    const query = gql`{
      userNotifications(all: true) {
        id
        validSince
        validUntil
        ietfLanguageTag
        subject
        htmlContent
        attachment {
          contentType
          data
          name
          size
        }
      }
    }`;

    return this.apollo
      .query<IGraphqlResponse>({
        query,
      })
      .pipe(
        map(({ data }) => data.userNotifications.map(notification => new UserNotification(notification)))
      );
  }

  create(notification: UserNotification): Observable<UserNotification> {
    const variables = {
      subject: notification.subject,
      htmlContent: notification.htmlContent,
      validSince: notification.validSince,
      validUntil: notification.validUntil,
      attachment: notification.attachment,
    };
    const mutation = gql`
      mutation(
        $subject: String!
        $htmlContent: String!
        $validSince: DateTimeOffset
        $validUntil: DateTimeOffset
        $attachment: Upload
      ) {
        notifications {
          create(
            subject: $subject
            htmlContent: $htmlContent
            validSince: $validSince
            validUntil: $validUntil
            attachment: $attachment
          ) {
            id
            subject
            htmlContent
            validSince
            validUntil
            attachment {
              contentType
              data
              name
              size
            }
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables,
      })
      .pipe(map(result => new UserNotification((result as IGQLResult<IGraphqlCreateNotification>).data.notifications.create)));
  }

  update(notification: UserNotification): Observable<UserNotification> {
    // in request only file can be sent
    const isAttachment = notification.attachment instanceof File || notification.attachment === null;
    const variables = {
      subject: notification.subject,
      htmlContent: notification.htmlContent,
      validSince: notification.validSince,
      validUntil: notification.validUntil,
      attachment: notification.attachment,
    };
    const mutation = gql`mutation($subject: String! , $htmlContent: String!, $validSince: DateTimeOffset, $validUntil: DateTimeOffset${isAttachment ? ', $attachment: Upload' : ''}) {
      notifications {
        update(id: "${notification.id}") {
          setSubject(value: $subject) {
            id
          }
          setHtmlContent(value:$htmlContent) {
            id
          }
          setValidSince(value: $validSince) {
            id
          }
          ${isAttachment ? `
          setAttachment(file: $attachment) {
            id
          }` : ''}
          result: setValidUntil(value: $validUntil) {
            ...notif
          }
        }
      }
    }
    fragment notif on UserNotificationGraphType {
      id
      subject
      htmlContent
      validUntil
      validSince
      ${isAttachment ? `
      attachment {
        contentType
        data
        name
        size
      }` : ''}
    }`;

    // TODO: change GraphQL schema to remove everything, because we are not interested in any data returned from server
    // currently update has result property
    return this.apollo
      .mutate({
        mutation,
        variables,
      })
      .pipe(map(result => (result as IGQLResult<IGraphqlCreateNotification>).data.notifications.update));
  }

  delete(id: string): Observable<unknown> {
    const mutation = gql`mutation {
      notifications {
        delete(id: "${id}")
      }
    }`;
    return this.apollo.mutate<IGraphqlCreateNotification>({
      mutation,
    });
  }
}
