import { clone } from './helpers';
import { IRole } from '@interfaces/siam';
import * as FactoryUser from './user.factory';

export const copy = (value: IRole): IRole => {
  if (!value) {
    return null;
  }
  const role = clone(value);
  if (value?.users?.length) {
    role.users = value.users.map(user => FactoryUser.copy(user));
  }
  role.compositeId = `role:${role.id}`;
  return role;
};

export const create = (): IRole => ({
  id: null,
  name: '',
  description: '',
  capabilities: [],
  tags: [],
  compositeId: 'role:'
});
