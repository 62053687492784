import { Injectable } from '@angular/core';
import { TEditMode } from '@interfaces/siam';

export class PopupSettings {
  title ? = 'Dokument';
  preview ? = false;
  fullScreen ? = false;
  openNewWindow ? = true;
  readOnly ? = true;
  editMode ?: TEditMode;
}
export interface IAdditionalPopupSettings {
  parentDocumentId?: string;
  submissionIds?: string[];
  topType?: string
}

export class ElementAttribute {
  class?: string;
  id?: string;
}

export class ToolbarItemOptions {
  type?: 'dxButton' | string;
  icon?: string;
  hint?: string;
  elementAttr?: ElementAttribute;

  [key: string]: any;
}

export class ToolbarItem {
  name: string;
  text?: string;
  widget?: string;
  options?: ToolbarItemOptions;
  toolbar?: 'top' | 'bottom' | string;
  location?: 'before' | 'after' | string;
  visible?: boolean;
  disabled?: boolean;
  onClick?: any;

  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class PopupHelperService {

  setDefaultToolbar(): ToolbarItem[] {
    // Aktionen für Popup registrieren ...
    return <ToolbarItem[]>[
      {
        name: 'TitleIcon',
        widget: 'dxButton', location: 'before', toolbar: 'top', visible: true, disabled: true,
        options: {type: 'normal', icon: 'file'}
      },
      {
        name: 'Title',
        text: '', location: 'before', toolbar: 'top', visible: true
      }
    ];
  }

  // Toolbar Helpers
  setToolbarOption(toolbar: ToolbarItem[], widgetName: string, option: string, value: unknown) {
    const index = toolbar.findIndex(item => item.name === widgetName);
    if (index !== -1) {
      toolbar[index][option] = value;
    }
  }
}
