import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IChartSettingGroup, IDynamicCard, IRole, IUser } from '@interfaces/siam';
import { PieChartSeries } from 'devextreme/viz/pie_chart';
import { ChartSeries } from 'devextreme/viz/common';
import { CommonSeriesSettings } from 'devextreme/viz/chart';
import { checkIfValidUUID, createPermissionTargetWithoutCompositeId, isCompositeId } from '@factories/document.factory';
import { SiamList } from '@interfaces/siamList';
import { isHasProperty } from '@factories/helpers';
import { ToolbarItem } from '@services/popup-helper.service';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  @Input() chart: IDynamicCard;
  @Input() lists: SiamList[];
  @Input() users: IUser[];
  @Input() roles: IRole[];
  @ViewChild('popupChart', { static: true }) popup: DxPopupComponent;


  infoPopupToolbarItems: ToolbarItem[];
  showInfoPopup = false;
  series: ChartSeries[] = [];
  pieChartSeries: PieChartSeries[] = [];
  commonSeriesSettings: CommonSeriesSettings;
  argumentField: string;
  serieName: string;
  dataSource: Record<string, unknown>[] = [];
  noDataText: string;

  ngOnInit() {
    this.loadChart();
  }

  loadChart(): void {
    switch (this.chart.type) {
      case 'chart': {
        const allItems: Record<string, unknown> = {};
        const dataSource = this.chart.dataSource
          .map(d => {
            const obj: Record<string, unknown> = {};
            const parentKey = this.getKeyValue(d.key, this.chart.chartSettings.groups[0]);
            obj['key'] = parentKey;
            obj['count'] = d.count;
            obj['items'] = d.items;
            if (d.items?.length) {
              d.items.forEach(i => {
                const key = this.getKeyValue(i.key, this.chart.chartSettings.groups[1]);
                if (key) {
                  if (isHasProperty(obj, key)) {
                    obj[key] = (obj[key] as number) + i.count;
                  } else {
                    obj[key] = i.count;
                  }
                  if (!isHasProperty(allItems, key)) {
                    allItems[key] = i.count;
                  }
                }
              });
            }
            if (!d.items?.length && d.count) {
              obj['Leer'] = d.count;
            }
            return obj;
          })
          .map(d => {
            const obj: Record<string, unknown> = {};
            for (const key of Object.keys(allItems)) {
              if (!isHasProperty(d, key)) {
                obj[key] = 0;
              }
            }
            return Object.assign(d, obj);
          });
        if (!dataSource?.length) {
          this.noDataText = 'Für diese Auswertung liegen keine ausreichenden Daten vor.';
        }
        this.dataSource = dataSource;
        switch (this.chart.chartSettings.groups[0].type) {
          case 'fieldValue':
            if (this.chart.chartSettings.groups[0].fieldType === 'string') {
              this.argumentField = this.extractFieldName(this.chart.chartSettings.groups[0].fieldName);
            } else {
              this.argumentField = 'key';
            }
            this.serieName = this.extractFieldName(this.chart.chartSettings.groups[0].fieldName);

            break;
          case 'creatorName':
            this.serieName = 'Ersteller';
            this.argumentField = 'key';
            break;
          case 'template':
            this.serieName = 'Dokumenttemplate';
            this.argumentField = 'key';
            break;
          case 'currentAssignee':
            this.serieName = 'Worflow-Empfänger';
            this.argumentField = 'key';
            break;
          case 'workflowStatus':
            this.serieName = 'Workflow-Status';
            this.argumentField = 'key';
            break;
          default:
            this.argumentField = 'key';
            break;
        }
        switch (this.chart.chartSettings.type) {
          case 'piechart':
          case 'doughnut':
            {
              const series: PieChartSeries[] = [];

              if (this.chart.chartSettings?.groups?.length === 1) {
                series.push({
                  argumentField: this.argumentField,
                  valueField: 'count',
                  label: {
                    visible: this.chart.chartSettings.label?.visible,
                    connector: { visible: true, width: 1 },
                    customizeText: this.customizeLabel
                  }
                });
              } else {
                this.chart.dataSource.forEach(item => {
                  if (item.items?.length) {
                    item.items.forEach(child => {
                      const key = this.getKeyValue(child.key, this.chart.chartSettings.groups[1]);
                      if (key && !series.find(s => s.name === key)) {
                        series.push({
                          name: key,
                          argumentField: this.argumentField,
                          valueField: key,
                          label: {
                            visible: this.chart.chartSettings.label?.visible,
                            connector: { visible: true, width: 1 },
                            customizeText: this.customizeLabel
                          }
                        });
                      }
                    });
                  }
                });
              }
              this.pieChartSeries = series;
            }
            break;
          case 'stackedbar': {
            const series: ChartSeries[] = [];
            this.chart.dataSource.forEach(item => {
              if (item.items?.length) {
                item.items.forEach(child => {
                  const key = this.getKeyValue(child.key, this.chart.chartSettings.groups[1]);
                  if (key && !series.find(s => s.name === key)) {
                    series.push({
                      name: key,
                      valueField: key,
                      label: {
                        visible: this.chart.chartSettings.label?.visible,
                        connector: { visible: true, width: 1 },
                        customizeText: this.customizeLabel
                      }
                    });
                  }
                });
              } else {
                if (item.count && !series.find(s => s.name === 'Leer')) {
                  series.push({
                    name: 'Leer',
                    valueField: 'Leer',
                    label: {
                      visible: this.chart.chartSettings.label?.visible,
                      connector: { visible: true, width: 1 },
                      customizeText: this.customizeLabel
                    }
                  });
                }
              }
            });
            if (this.chart.chartSettings.groups?.length === 1) {
              this.series = [
                { argumentField: this.argumentField, valueField: 'count', name: this.serieName, type: 'bar' }
              ];
            } else if (this.chart.chartSettings.groups?.length === 2) {
              this.series = series;
              this.commonSeriesSettings = { type: 'stackedbar', argumentField: this.argumentField };
            } else {
              //nothing
            }

            break;
          }
        }
        break;
      }

      default:
        break;
    }
  }

  customizeTooltip = (arg: {
    argumentText: string;
    seriesName: string;
    valueText: string;
    percentText: string;
  }): { text: string } => {
    const percent = this.chart?.chartSettings?.tooltip?.percent ? `(${arg.percentText})` : '';
    let seriesName = '';
    if (this.chart.chartSettings.type === 'stackedbar') {
      seriesName = this.series?.length > 1 ? arg.seriesName : '';
    } else {
      seriesName = this.pieChartSeries?.length > 1 ? arg.seriesName : '';
    }
    return { text: `${arg.argumentText}<br>${seriesName}: ${arg.valueText} ${percent}` };
  };

  customizeLabel = (arg: { value: number; valueText: string; percentText: string }): string => {
    const percent = this.chart?.chartSettings?.label?.percent ? `(${arg.percentText})` : '';
    const ignoreNullValue = this.chart?.chartSettings?.ignoreNullValue;
    return ignoreNullValue && !arg.value ? null : `${arg.valueText} ${percent}`;
  };

  openChart(): void {
    this.infoPopupToolbarItems = [
      {
        name: 'TitleIcon',
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        options: {
          type: 'normal',
          icon: 'material-icons stacked_bar_chart',
          elementAttr: {
            class: 'button-icon'
          }
        }
      },
      {
        name: 'Title',
        text: this.chart.chartSettings?.title,
        location: 'before',
        toolbar: 'top',
        visible: true
      },
      {
        name: 'Close',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'top',
        options: { type: 'normal', icon: 'close', hint: 'Schließen' },
        onClick: this.closeInfoPopup
      }
    ];
    this.showInfoPopup = true;
  }

  onShowingChartPopup = (): void => {
    const chart = this.popup.instance.content().querySelector('.card-chart');
    if (chart) {
      (chart as HTMLElement).style.height = '80vh';
    }
  };

  closeInfoPopup = (): void => {
    this.showInfoPopup = false;
  };

  private extractFieldName = (fieldName: string): string => {
    if (fieldName?.includes('fields.')) {
      return fieldName.split('.')[1];
    }
    return null;
  };

  private getKeyValue(key: string, group: IChartSettingGroup): string {
    switch (group.type) {
      case 'fieldValue':
        switch (group.fieldType) {
          case 'userRole':
            if (isCompositeId(key)) {
              const PermissionTarget = createPermissionTargetWithoutCompositeId(key);
              if (PermissionTarget.type === 'user') {
                return this.users.find(u => u.id === PermissionTarget.targetId)?.displayName || key;
              } else if (PermissionTarget.type === 'role') {
                return this.roles.find(u => u.id === PermissionTarget.targetId)?.name || key;
              } else {
                return 'Role';
              }
            } else {
              return key;
            }

          case 'object':
            if (group.fieldListName) {
              const list = this.lists.find(l => l.name === group.fieldListName);
              return list.entries.find(entry => entry.value === key)?.label || key;
            } else {
              return key;
            }

          default:
            return key;
        }
      case 'creatorName': {
        if (checkIfValidUUID(key)) {
          return this.users.find(u => u.id === key)?.displayName || key;
        } else {
          return key;
        }
      }
      case 'currentAssignee': {
        if (isCompositeId(key)) {
          const PermissionTarget = createPermissionTargetWithoutCompositeId(key);
          if (PermissionTarget.type === 'user') {
            return this.users.find(u => u.id === PermissionTarget.targetId)?.displayName || key;
          } else if (PermissionTarget.type === 'role') {
            return this.roles.find(u => u.id === PermissionTarget.targetId)?.name || key;
          } else {
            return 'Role';
          }
        } else {
          return key;
        }
      }

      default:
        return key;
    }
  }
}
