import { ToolbarItem } from '@services/popup-helper.service';
import { Component, EventEmitter, Output } from '@angular/core';

export interface IConfirmPopupSettings {
  title: string;
  message: string;
  items: ToolbarItem[];
  noInit?: boolean;
}

const initSetting: IConfirmPopupSettings = {
  items: [
    {
      widget: 'dxButton', name: 'icon', location: 'before', toolbar: 'top',
      options: { type: 'normal', icon: 'material-icons bookmark', elementAttr: { class: 'button-icon' } }
    },
    {
      widget: 'dxButton', name: 'save', location: 'before', toolbar: 'bottom',
      options: { text: 'Löschen', icon: 'check' },
    },
    {
      widget: 'dxButton', name: 'cancel', location: 'after', toolbar: 'bottom',
      options: { text: 'Abbrechen', icon: 'clear' },
    }],
  title: 'Löschen von Dokument',
  message: 'Löschen von Dokument',
};

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent {
  @Output() dialogHidden = new EventEmitter<boolean>();
  @Output() dialogResult = new EventEmitter<(boolean)>();
  @Output() dialogResultExtra = new EventEmitter<(boolean)>();

  buttons: ToolbarItem[];
  currentSetting: IConfirmPopupSettings;
  confirmOpen = false;

  show(setting?: IConfirmPopupSettings): void {
    this.confirmOpen = true;
    this.currentSetting = {
      title: '',
      items: [],
      message: ''
    };

    if (setting) {
      this.currentSetting.title = setting.title;
      this.currentSetting.message = setting.message;
      if (!setting.noInit) {
        initSetting.items.forEach(item => {
          this.currentSetting.items.push(item);
        });
      }
      setting.items.forEach(item => {
        this.currentSetting.items.push(item);
        if (item.name === 'saveExtra') {
          item.onClick = this.saveExtra;
        }
      });
    }
    this.currentSetting.items.forEach(item => {
      if (item.name === 'save') {
        item.onClick = this.save;
      }
      if (item.name === 'cancel') {
        item.onClick = this.cancel;
      }
    });
  }

  save = (): void => {
    this.confirmOpen = false;
    this.dialogResult.emit(true);
  }

  saveExtra = (): void => {
    this.confirmOpen = false;
    this.currentSetting = null;
    this.dialogResultExtra.emit(true);

  }

  cancel = (): void => {
    this.confirmOpen = false;
    this.dialogHidden.emit(false);
  }
}
