import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { DxSlider } from '@interfaces/fieldClient';
import { ValidationRule } from 'devextreme/common';
import { ValueChangedEvent } from 'devextreme/ui/slider';

@Component({
  selector: 'app-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss']
})
export class FormSliderComponent {
  @Input() isEditing = false;

  @Input() field: DxSlider;

  @Input() mask: string = null;

  @Input() validationGroupId: string = null;

  @Input() validators: ValidationRule[] = [];

  @Input() value!: string;

  @Output() valueChange = new EventEmitter<string>();

  valueChanged(e: ValueChangedEvent) {
    const inputValue = e.element.querySelector('input').value;
    if (inputValue) {
      const sliderLabels = Array.from(e.element.querySelectorAll('.dx-slider-label'));
      const div = sliderLabels[1] as HTMLElement;
      if (div) {
        const span = div.querySelector('span');
        if (span) {
          span.textContent = `${inputValue}/`;
        } else {
          const newSpan = this.renderer.createElement('span') as Element;
          const text = this.renderer.createText(`${inputValue}/`) as string;
          this.renderer.appendChild(newSpan, text);
          this.renderer.insertBefore(div, newSpan, div?.firstChild);
        }
      }
    }
    this.valueChange.emit(e.value as string);
  }
  constructor(private renderer: Renderer2) {}
}
