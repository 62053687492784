/**
 * Interfaces of DevExtreme library
 */
import dxDataGrid, { Column as DxDataGridColumn } from 'devextreme/ui/data_grid';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import dxForm from 'devextreme/ui/form';
import dxValidator from 'devextreme/ui/validator';
import dxFileUploader from 'devextreme/ui/file_uploader';
import dxButton from 'devextreme/ui/button';
import dxTextBox from 'devextreme/ui/text_box';
import { template } from 'devextreme/core/templates/template';
import dxCheckBox from 'devextreme/ui/check_box';
import { Field } from 'devextreme/ui/filter_builder';
import { ICustomValuesSignature, ISignatureColumn, IWorkflowAction } from './siam';
import dxTagBox from 'devextreme/ui/tag_box';
import { FieldBaseClient, FieldLabel, IAgendaAllowedTemplates } from './fieldClient';
import dxPopup from 'devextreme/ui/popup';

export enum Operator {
  contains = 'contains',
  notcontains = 'notcontains',
  equals = '=',
  notequals = '<>',
  startsWith = 'startswith',
  endsWith = 'endswith',
  smallerThan = '<',
  greaterThan = '>',
  smallerOrEquals = '<=',
  greaterOrEquals = '>='
}

export type FilterValue = string | FilterValue[];
export type TFilterExpr = [string, Operator, string];
export type TFilterGrid = TFilterExpr | [TFilterGrid, TGroupOperator, TFilterGrid];
export type TGroupOperator = 'or' | 'and';

export interface IDxToolbarItem {
  value: number;
}

export interface IDxTemplateDataObject<T, V> {
  data: T;
  value: V;
}

export interface IDxEventValidateAsync<T> {
  value: T;
  validator: dxValidator;
  column: IDxDataGridColumn;
  data?: Record<string, unknown>;
}

interface IDxDataGridColumn extends DxDataGridColumn {
  command: string;
}

export interface IDxEditorToolbarItemOption {
  text?: string;
  hint?: string;
  height?: string | number;
  width?: string | number;
  switchedOffText?: string;
  switchedOnText?: string;
  elementAttr?: Record<string, unknown>;
  icon?: unknown;
  stylingMode?: string;
  onClick?: unknown;
  useSelectMode?: boolean;
  selectedItemKey?: number | string;
  displayExpr?: string;
  valueExpr?: string;
  keyExpr?: string;
  items?: unknown[];
  onItemClick?: unknown;
  onValueChanged?: unknown;
  value?: unknown;
  disabled?: boolean;
  placeholder?: string;
  onInitialized?: unknown;
  onContentReady?: unknown;
  onOptionChanged?: unknown;
  type?: string;
  visible?: boolean;
}

export interface IDxEditorToolbarItem {
  cssClass?: string;
  disabled?: boolean;
  formatName?: string;
  formatValues?: unknown;
  acceptedValues?: unknown;
  location?: 'after' | 'before' | 'center';
  showText?: 'always' | 'inMenu';
  locateInMenu?: 'always' | 'auto' | 'never';
  text?: string;
  name?: string;
  type?:string;
  visible?: boolean;
  widget?: unknown;
  options?: IDxEditorToolbarItemOption;
  template?: template | (() => string | Element | JQuery);
}

export interface IDxEditorToolbarItemOptions {
  font: boolean;
  table: boolean;
  link: boolean;
  image: boolean;
}

export interface IDxEditorSettings {
  fontType?: string;
  fontSize?: string;
  dynamicHeight?: boolean;
  maxHeight?: number;
}

export interface IDxTagBoxGroupTemplate<T, K> {
  key: K;
  items: T[];
}

export interface IDxDataGridCellTemplate<T, L> {
  cellElement: HTMLElement;
  column: IDxDataGridColumn;
  columnIndex: number;
  component: dxDataGrid;
  data: T;
  displayValue?: T;
  element: HTMLElement;
  event: Event;
  key: unknown;
  row: { data?: T; key?: T };
  rowIndex: number;
  rowType: string;
  text: string;
  value: L;
}

export interface IDxDataGridColumnCustomizeText<T> {
  groupInterval: string | number;
  target: string;
  value: T;
  valueText: string;
}

export interface IDxUIComponent {
  component: unknown;
  element: HTMLElement;
}

export interface IDxDataGridOnCustomItemCreating<T> {
  component: DxDataGridColumn;
  customItem: string | T | Promise<T>;
  element: HTMLElement;
  text: string;
}

export interface IDxDataGridOnCustomItemHeaderFilter<T> {
  component: DxDataGridComponent;
  dataSource: IDxDataGridOnCustomItemHeaderFilterDataSource<T>;
}

export interface IDxDataGridOnCustomItemHeaderFilterDataSource<T> {
  postProcess: (data: T) => T;
  group: IGroupSelector[];
}

export interface IGroupSelector {
  selector: string;
}

export interface IDxPopupOnContentReady {
  component: dxPopup;
  element: HTMLElement;
}

export interface IDxFileUploaderComponent extends dxFileUploader {
  _selectButton: dxButton;
}

export interface IDxEventComponentCheckBox extends dxCheckBox {
  skipOnValueChanged: boolean;
  setUndefinedNextTime: boolean;
  isValid: boolean;
}

export interface IDxFormItemTemplate<T = string> {
  component?: dxForm;
  dataField?: string;
  editorOptions?: IDxFormItemTemplateEditoOptions<T>;
  editorType?: string;
  name?: string;
}

export interface IDxFormItemTemplateEditoOptions<T> {
  label?: FieldLabel;
  fieldVisible?: boolean | 'formular'; // as default True
  readOnly?: boolean;
  width: string;
  value?: T;
  disabled?: boolean;
  required?: boolean;
  elementAttr?: { id: string };
  visibleFormula?: { id?: string; language: string; code: string };
  cssClassColor?: string;
  isGroupClosed?: boolean;
  childrenTags?: string[];
  dynamicListName?: string;
  childrenAllowedTemplates?: string[];
  agendaAllowedTemplates?: IAgendaAllowedTemplates;
  allowCreateChild?: boolean;
  allowAddReference?: boolean;
  showParentDocuments?: boolean;
  taskType?: 'list' | 'gantt' | 'both';
  taskTemplateId?: string;
  signatureColumns?: ISignatureColumn[];
  customValuesSignature?: ICustomValuesSignature;
}

export interface IDxFormValidationCallback<T> {
  column?: DxDataGridColumn;
  data: T;
  rule: unknown;
  validator: dxValidator;
  value: string | number;
}

export interface IDxCustomRule {
  /**
   * If true, the validationCallback is not executed for null, undefined, false, and empty strings.
   */
  ignoreEmptyValue?: boolean;
  /**
   * Specifies the message that is shown if the rule is broken.
   */
  message?: string;
  /**
   * Indicates whether the rule should be always checked for the target value or only when the target value changes.
   */
  reevaluate?: boolean;
  /**
   * Specifies the rule type. Set it to &apos;custom&apos; to use the CustomRule.
   */
  type: 'custom';
  /**
   * A function that validates the target value.
   */
  validationCallback?: (options: IDxItemValidationCallback<string>) => boolean;
}

export interface IDxRequiredRule {
  /**
   * Specifies the message that is shown if the rule is broken.
   */
  message?: string;
  /**
   * Indicates whether to remove the Space characters from the validated value.
   */
  trim?: boolean;
  /**
   * Specifies the rule type. Set it to &apos;required&apos; to use the RequiredRule.
   */
  type: 'required';
}

export interface IDxItemValidationCallback<T> {
  formItem: FieldBaseClient;
  rule: unknown;
  validator: dxValidator;
  value: T;
}

export interface IDxTextBoxCell<T> {
  cellElement: HTMLElement;
  column: Record<string, unknown>;
  columnIndex: number;
  component: dxTextBox;
  data: Record<string, unknown>;
  displayValue: string;
  isAltRow: boolean;
  isEditing: boolean;
  key: Record<string, unknown>;
  oldValue: T | undefined;
  rowIndex: number;
  rowType: string;
  setValue: (value: T, text?: string) => void;
  summaryItems: undefined;
  text: string;
  value: T;
  values: T[];
}

export interface IDxLookup {
  calculateCellValue: (source: string) => string;
}

export interface IDxTab {
  index: number;
  text: string;
  icon: string;
  section?: Record<string, boolean>;
}

export interface IDxTagBoxOnCustomItemCreating {
  component: dxTagBox;
  customItem: string;
  element: HTMLElement;
  text: string;
}

export interface IDxGroupItem {
  state?: string;
  key: string;
  items: IDxGroupItem[] | null;
  count?: number;
  summary?: (number | string)[];
  diyplayValue?: string;
}

export interface IDxResolvedGroupData {
  data: IDxGroupItem[];
  totalCount?: number;
  summary?: (number | string)[];
  groupCount?: number;
}


export interface IDxFilterBuilderCustomOperation {
  name: string;
  caption: string;
  icon: string;
  editorTemplate?: string;
  calculateFilterExpression: (filterValue: FilterValue[], field: Field) => FilterValue[];
}

export interface IDxCollectionWidgetItem {
  value: string;
  label: string;
  icon?: string;
  html?: string;
  template?: (data: unknown, index: number, element: HTMLElement) => string;
  disabled?: boolean;
  action?: IWorkflowAction;

}

export interface IDxDropDownItem<T> {
  value: T;
  name: string;
  icon: string;
}
