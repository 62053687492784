import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { DxFormComponent } from 'devextreme-angular';
import { DocumentService } from '@services/document.service';
import { TemplateService } from '@services/template.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { NotifyService } from '@services/notify.service';
import { ListsService } from '@services/lists.service';
import { Observable, Subject } from 'rxjs';
import {
  IDocument,
  IEventEmitter,
  IPermissionTarget,
  ITask,
  IUser,
  SiamListItem,
  TNavigationSource
} from '@interfaces/siam';
import * as Factory from '@factories/document.factory';
import { UserService } from '@services/user.service';
import { LoggerService } from '@services/logger.service';
import { DocumentHelperService } from '@services/document-helper.service';

@Component({
  selector: 'app-tasks-form',
  templateUrl: './tasks-form.component.html',
  styleUrls: ['./tasks-form.component.scss'],
})
export class TasksFormComponent implements OnInit, OnDestroy {
  @ViewChild('taskForm') taskForm: DxFormComponent;
  @Output() dialogResult: EventEmitter<IEventEmitter<ITask>> = new EventEmitter<IEventEmitter<ITask>>();
  @Input() parentDocument: IDocument; // optional Document to link task to
  @Input() dialogTaskVisible: boolean;
  @Input() navigationSource: TNavigationSource = 'application';

  usersAndRolesDataSource: DataSource;
  usersAndRolesDataStore: ArrayStore<IUser, string>;
  isFullscreen = false;
  taskTemplate: ITemplateServer;
  taskDocument: IDocument;
  targetsByCompositeId: { compositeId: string; name: string }[];
  taskPriorities: Observable<SiamListItem[]>;
  task: ITask;
  currentDate: Date;

  private destroyed$ = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private templateService: TemplateService,
    private listsService: ListsService,
    private logger: LoggerService,
    private helperService: DocumentHelperService,
    private userService: UserService,
  ) {
    this.currentDate = new Date();
    this.usersAndRolesDataStore = new ArrayStore({ key: 'id', data: [] as IUser[] });
    this.usersAndRolesDataSource = new DataSource({ store: this.usersAndRolesDataStore });
  }

  ngOnInit(): void {
    this.getTaskTemplate();
    this.fillUsersAndRoles().pipe(takeUntil(this.destroyed$)).subscribe();
    this.taskPriorities = this.listsService.getListEntries('Prioritäten');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onShownPopup(): void {
    if (this.taskForm && !this.task) {
      this.taskForm.instance.resetValues();
      const editorSubject = this.taskForm.instance.getEditor('subject');
      if (editorSubject) {
        editorSubject.focus();
      } else {
        console.error('Konnte Editor "Subject" nicht identifizieren.');
      }
    }
  }

  editTask(taskdoc?: IDocument): boolean {
    if (!taskdoc) {
      if (this.taskTemplate) {
        this.taskDocument = this.documentService.create(this.taskTemplate);
        this.task = this.convertObjectToViewModel(this.taskDocument);
        if (this.task) {
          this.dialogTaskVisible = true;
          return true;
        } else {
          NotifyService.global.error('Konnte Aufgabe nicht erzeugen.');
          return false;
        }
      } else {
        NotifyService.global.error('Es ist kein Aufgaben-Template verfügbar.');
        return false;
      }
    } else {
      this.taskDocument = taskdoc;
      this.task = this.convertObjectToViewModel(this.taskDocument);
      this.dialogTaskVisible = true;
      if (this.taskForm) {
        this.taskForm.instance.focus();
      }
      return true;
      // TODO: Kopie erstellen....
    }
  }

  openTask = (): void => {
    if (!this.task) {
      this.logger.warn('kein passende Aufgabe übergeben: {@o}', this.task.id);
      return;
    }
    this.helperService.openDocument(this.taskDocument, 'Edit', this.navigationSource);
  }

  saveTask = (): void => {
    const result = this.taskForm.instance.validate();
    if (result.isValid) {
      if (this.task) {
        this.logger.debug('zu speicherndes Objekt: {@t}', this.task);
        this.dialogResult.emit({
          command: 'loading',
        });
        const documentToSave = this.setObjectFromViewModel(this.task, Factory.copy(this.taskDocument));
        this.documentService
          .save(documentToSave)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (savedDocument) => {
              this.task.document = savedDocument;
              // falls Mutterdokument mitgegeben wurde, soll die Aufgabe damit verlinkt werden...
              if (this.parentDocument) {
                this.documentService
                  .setReference(savedDocument.id, this.parentDocument.id)
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe({
                    next: (response) => {
                      this.task.document = response;
                      this.dialogResult.emit({
                        command: 'saved',
                        object: this.task,
                      });
                    }, error: NotifyService.global.error
                  });
              }

              NotifyService.global.success('Erfolgreich gespeichert');
              this.dialogTaskVisible = false;
              this.dialogResult.emit({
                command: 'store',
                object: this.task
              });
            },
            error: NotifyService.global.error
          });
      }
    } else {
      this.logger.warn('Pflichtfeldfehler!');
      NotifyService.global.warn('Bitte alle Pflichtfelder ausfüllen');
    }
  };

  cancelTask = (): void => {
    this.dialogTaskVisible = false;
  };

  toggleFullscreen = (): void => {
    this.isFullscreen = !this.isFullscreen;
  }

  fillUsersAndRoles(): Observable<void> {
    // -- User & Rollendaten laden, falls benötigt

    return this.userService.getAllActiveUsers().pipe(
      map(users => ({
        type: 'users',
        entries: users.map(user => ({
          id: user.compositeId,
          name: user.displayName,
          avatar: user.profile && user.profile.picture && user.profile.picture.url,
        })),
      })),
      map(item => {
        this.usersAndRolesDataStore.push(item.entries.map(entry => ({ type: 'insert', data: entry })));
      }),
    );
  }

  // Dokumenttyp für "Aufgabe" vom Server holen
  private getTaskTemplate() {
    this.templateService
      .getActiveTemplates('execute', ['app:document-type:task'])
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe({
        next: (templateServer) => {
          this.taskTemplate = templateServer[0];
        },
        complete: () => {
          if (!this.taskTemplate) {
            this.logger.warn('getTaskTemplate(): Kein Task-Template verfügbar.');
          }
        }
      });
  }

  private convertObjectToViewModel(doc?: IDocument): ITask {
    const getFieldValue = <T>(fieldName: string, defaultValue: T = null): T => {
      const fieldObject = doc ? doc.fields[fieldName] : null;
      return !fieldObject || fieldObject.value === null ? defaultValue : (fieldObject.value as T);
    };

    return {
      subject: getFieldValue('subject'),
      enddate: getFieldValue('enddate'),
      body: getFieldValue('body'),
      chair: getFieldValue('chair', []).map((pt: IPermissionTarget) => Factory.permissionTarget(pt).compositeId),
      priority: getFieldValue('priority'),
    };
  }

  private setObjectFromViewModel(task: ITask, document?: IDocument): IDocument {
    if (!document) {
      document = this.documentService.create(this.taskTemplate);
    }

    document.fields.subject = { value: task.subject };
    document.fields.enddate = { value: task.enddate };
    document.fields.body = { value: task.body };
    document.fields.chair = { value: task.chair.map(compositeId => Factory.createPermissionTarget(compositeId)) };
    document.fields.priority = { value: task.priority };

    return document;
  }
}
