<section class="json-list">
  <section *ngFor="let segment of segments" [ngClass]="['segment', 'segment-type-' + segment.type]">
    <section
      (click)="toggle(segment)"
      [ngClass]="{
        'segment-main': true,
        expandable: isExpandable(segment),
        expanded: segment.expanded
      }">
      <div *ngIf="isExpandable(segment)" class="toggler"></div>
      <span class="segment-key">{{ segment.key }}</span>

      <span *ngIf="!isExpandable(segment) || segment.expanded || (isExpandable(segment) && preview)" class="segment-separator">: </span>
      <span *ngIf="!isExpandable(segment) || (isExpandable(segment) && preview)" class="segment-value">{{ segment.description }}</span>

      <span *ngIf="showTypeHeadings && segment.expanded && segment.type === 'array'" class="segment-value">
        Array[{{ segment.value.length }}]
      </span>
      <span *ngIf="showTypeHeadings && segment.expanded && segment.type === 'object'" class="segment-value">
        Object
      </span>
      
    </section>
    <section *ngIf="segment.expanded && isExpandable(segment)" class="children">
      <app-json-list
        [json]="segment.value"
        [expanded]="expanded"
        [preview]="preview"
        [depth]="depth"
        [showTypeHeadings]="showTypeHeadings"
        [_key]="segment.key"
        [_currentDepth]="_currentDepth"
        [_previouslyOpenKeys]="_previouslyOpenKeys && _previouslyOpenKeys[segment.key]"></app-json-list>
    </section>
  </section>
</section>
