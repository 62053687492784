<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [message]="panel.message | async"
  [visible]="panel.loading | async"
  [height]="panel.height | async"
  [width]="panel.width | async"
  [position]="{ of: '.app-wrapper' }"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false">
</dx-load-panel>
