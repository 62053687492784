import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serialize',
  pure: false
})
export class SerializePipe implements PipeTransform {

  transform(value: Record<string, unknown> | string, args?: string): Record<string, unknown> | string {
    if (typeof args === 'string') {
      const fields = args.split('.');
      const finalValue = fields.slice(0, fields.length - 1).reduce((pv, key) => pv[key] as Record<string, unknown>, value as Record<string, unknown>);
      if (typeof finalValue === 'object') {
        const finalKey = fields.pop();
        const valueToConvert = finalValue[finalKey];
        if (typeof valueToConvert === 'object' || Array.isArray(valueToConvert)) {
          finalValue[finalKey] = JSON.stringify(valueToConvert);
        }
      }
    } else if (typeof args === 'undefined' && typeof value === 'object') {
      value = JSON.stringify(value);
    }
    return value;
  }

}
