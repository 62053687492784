import {
  Component,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  Renderer2,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  IDocument,
  IError,
  IEventEmitter,
  IRole,
  IUser,
  TEditMode,
  IDynamicGridSettings,
  TSelectionMode,
  IConfirmDialog,
  IPermissionTarget,
  TEffectivePermission,
  IUserRoleEntry,
  IEventEmitterValueChaned,
  IWorkflowActionSheetItem,
  IDocumentsSearch,
  ISearch
} from '@interfaces/siam';
import { DocumentService } from '@services/document.service';
import { RoleService } from '@services/roles.service';
import { UserService } from '@services/user.service';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import {
  concat,
  concatMap,
  first,
  from,
  interval,
  lastValueFrom,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
  toArray
} from 'rxjs';
import DevExpress from 'devextreme';

import Column = DevExpress.ui.dxDataGrid.Column;
import {
  CellClickEvent,
  ColumnCellTemplateData,
  ColumnEditCellTemplateData,
  ContentReadyEvent,
  RowClickEvent,
  SelectionChangedEvent,
  ToolbarPreparingEvent
} from 'devextreme/ui/data_grid';
import { NotifyService } from '@services/notify.service';
import { TemplateService } from '@services/template.service';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { DocumentEditorComponent } from '../../../dialogs/document-editor/document-editor.component';
import { PopupSettings } from '@services/popup-helper.service';
import { WINDOW } from 'src/app/tokens/window.token';
import dxTreeView, { Node, ContentReadyEvent as ContentReadyEventTreeView } from 'devextreme/ui/tree_view';
import { ItemClickEvent as ItemClickEventDropDown } from 'devextreme/ui/drop_down_button';
import dxSortable, { AddEvent, DragChangeEvent, DragStartEvent } from 'devextreme/ui/sortable';
import dxDraggable from 'devextreme/ui/draggable';
import { deleteTOPDialog, isHasProperty, isUndefinedValue, resolveSubject } from '@factories/helpers';
import { LoadPanelService } from '@services/load-panel.service';
import { confirm, custom } from 'devextreme/ui/dialog';
import {
  IDxFormItemTemplateAgenda,
  IEventEmitterAgenda,
  INewAgendaServer,
  IProtocolFields,
  ISubmissionFields,
  ITaskFields,
  ITopAction,
  ITopGridSettingsColumn,
  ITopSubmissionGridSettingsColumn,
  TAgendaItemType,
  TTopAction,
  TTopGridColumnDataType,
  TreeViewItem,
  convertToPlainArray,
  defaultAgendaItemField,
  defaultAgendaItemSubmissionField,
  defaultAgendaItemTaskField
} from '@interfaces/fieldAgenda';
import {
  checkIfValidUUID,
  copy,
  createPermissionTargetWithoutCompositeId,
  isCompositeId,
  permissionTarget
} from '@factories/document.factory';
import DataSource from 'devextreme/data/data_source';
import { siamConst } from '@interfaces/siamConst';
import { ItemRenderedEvent, Item as ActionSheetItem } from 'devextreme/ui/action_sheet';
import * as templateFactory from '@factories/template.factory';
import { FieldTypes, PermissionTarget, FieldDecisionsValue } from '@interfaces/fieldClient';
import { _defaultDecisionDocument, _defaultDecisionId } from '@interfaces/default-decision';
import { ItemClickEvent as ItemClickEventMenu } from 'devextreme/ui/menu';
import { create } from '@factories/user.factory';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { ClickEvent } from 'devextreme/ui/button';

type TActionAgenda = 'add-submissions' | 'new-break' | 'add-free-top' | 'add-old-top' | 'new-top' | 'ref-top' | 'none';
interface IActionAgenda extends ActionSheetItem {
  actionType: TActionAgenda;
}
const selectedFieldsTOP = [
  'fields.subject',
  'fields.attachments',
  'fields.body',
  'template.caption',
  'documentWorkflowDocument.currentStatusLabel',
  'creation.timestamp',
  'creation.userId',
  'tags',
  'references'
];
interface ITopFromAgenda {
  id: string;
  subject: string;
  document: IDocument;
  creatordata?: string;
  creatorname?: string;
  attachment?: boolean;
  agendaSubject?: string;
  agendaStartDate: string;
  agendaType?: string;
}
@Component({
  selector: 'app-top-include',
  templateUrl: './top-include.component.html',
  styleUrls: ['./top-include.component.scss']
})
export class TopIncludeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('treeViewAgendaItems') treeViewAgendaItems: DxTreeViewComponent;
  @ViewChildren('itemTopDataGrid') grids: QueryList<DxDataGridComponent>;
  @ViewChild('freeTopSelectionGrid') freeTopSelectionGrid: DxDataGridComponent;
  @ViewChild(DocumentEditorComponent, { static: true }) documentEditorComponent: DocumentEditorComponent;

  @Input() agendaDocument: IDocument = null;
  @Input() topField: IDxFormItemTemplateAgenda = null;
  @Input() formDataChanged: IEventEmitterValueChaned<string> = null;
  @Input() isMeetingMinutes = false;
  @Input() referencedDocuments: IDocument[];
  @Input() editMode: TEditMode = 'ReadOnly';
  @Input() formData: Record<string, unknown> = {};

  @Output() agendaResult = new EventEmitter<IEventEmitterAgenda>();

  isCanUpdate = true;

  timerVisible = false;
  timerTitle: string;
  exceededTime = false;
  popupHeight = 150;

  agendaStartDate: Date;
  agendaItems: TreeViewItem[] = [];
  treelistItemsFiltered: DataSource;
  topColumnsConfig: ITopGridSettingsColumn[];
  topColumns: Column[];
  breakColumns: Column[];
  submissionFields: ISubmissionFields;
  protocolFields: IProtocolFields;
  taskFields: ITaskFields;
  maxDepth = 1;
  startDateFieldName: string;

  agendaTotalTime = '';
  agendaEndTime: string;

  dynamicGridListener: Observable<IEventEmitter<unknown>>;

  /* template Ids */
  topTemplate: ITemplateServer;
  topTemplateDocument: IDocument;
  topTemplateId: string;
  templateDecisionId: string;
  templateProtocolId: string;
  templateSubmissionIds: string[];
  templateTaskId: string;
  dynamicListName = 'submission';
  templateMeetingMinutesId: string;
  templateRapidMeetingMinutesId: string;
  topDurationDefaultValue = 15;
  topDurationStep = 5;

  /* TOP Actions */
  selectedTOP: TreeViewItem = null;
  selectedTOPAction: TTopAction = null;
  selectedWorkflowActionDocument: IDocument = null;
  selectedWorkflowAction: IWorkflowActionSheetItem = null;

  /* Freien TOPs */
  popupFreeTopsListVisible = false;
  popupFreeTopsListTitle = 'Bitte wählen Sie die gewünschten Tops';
  dynamicGridFreeTOPList: IDynamicGridSettings = null;
  selectedFreeTOPListIds: string[] = [];
  selectedFreeTOPList: IDocument[] = [];
  isSelectedFreeTOPList = true;

  /* Referenced TOPs */
  popupReferencedFreeTopsListVisible = false;
  popupReferencedTopsListTitle = 'Bitte wählen Sie die gewünschten Tops';
  dynamicGridReferencedTOPList: IDynamicGridSettings = null;
  selectedReferencedTOPListIds: string[] = [];
  isSelectedReferencedTOPList = true;

  /* TOPs from other Agenda */
  popupTOPsFromAgendaListVisible = false;
  popupTOPsFromAgendaListExpanded = false;
  popupTOPsFromAgendaList: ITopFromAgenda[] = [];
  popupTOPsFromAgendaListTitle = 'Bitte wählen Sie die gewünschten Tops';
  selectedTOPsFromAgendaListIds: string[] = [];
  selectedTOPsFromAgendaList: IDocument[] = [];
  isSelectedTOPsFromAgendaList = true;

  /* Agenda Actions */
  agendaActionsTarget: EventTarget;
  agendaActionsVisible: boolean;
  actionsDataSource: IActionAgenda[] = [];
  /* submissionList */
  popupSubmissionsListVisible = false;
  popupSubmissionsListTitle = 'Bitte wählen Sie die gewünschten Vorlagen aus.';
  dynamicGridSubmissionList: IDynamicGridSettings = null;
  selectionModeSubmissionList: TSelectionMode = 'multiple';
  selectedSubmissionListIds: string[] = [];
  selectedSubmissionList: IDocument[] = [];
  isSelectedSubmissionList = true;

  displayColumns = 2;
  collapseAll = false;

  usersDataSource: IUserRoleEntry[];

  currentAgendaItem: TreeViewItem;
  countdown: string;
  currentItem: TreeViewItem;
  currentItem$: Observable<TreeViewItem>;
  countdown$: Observable<string>;

  #dynamicGridListener = new Subject<IEventEmitter<unknown>>();

  private roles: Map<string, IRole>;
  private users: Map<string, IUser>;

  private currentTimeObservable$: Subscription;

  private destroyed$ = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private templateService: TemplateService,
    private userService: UserService,
    private roleService: RoleService,
    private loadPanelService: LoadPanelService,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private nameDataPipe: GetNameDataPipe,

    @Inject(WINDOW) private window: Window
  ) {
    this.dynamicGridListener = this.#dynamicGridListener.asObservable();
    this.fetchUsersAndRoles().subscribe();
  }

  ngOnInit(): void {
    this.actionsDataSource = [
      {
        text: 'Neuen TOP hinzufügen',
        actionType: 'new-top',
        icon: 'plus',
        stylingMode: 'text',
        onClick: this.onCreateItem
      },
      {
        text: 'Vorlage hinzufügen',
        actionType: 'add-submissions',
        icon: 'plus',
        stylingMode: 'text',
        onClick: this.onAddSubmissions
      },
      {
        text: 'Pause hinzufügen',
        actionType: 'new-break',
        icon: 'coffee',
        stylingMode: 'text',
        onClick: this.onCreateBreakItem
      },
      {
        text: 'Freien TOP hinzufügen',
        actionType: 'add-free-top',
        icon: 'plus',
        stylingMode: 'text',
        onClick: this.onAddFreeTop
      },
      {
        text: 'TOP aus Sitzung übernehmen',
        actionType: 'add-old-top',
        icon: 'plus',
        stylingMode: 'text',
        onClick: this.onAddTopFromAgenda
      },
      {
        text: 'Fehlende TOPs neu referenzieren',
        actionType: 'ref-top',
        icon: 'material-icons sync',
        stylingMode: 'text',
        onClick: this.onAddReferencedTop
      }
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.fetchAgendaStartDate();
    if (changes.formDataChanged?.currentValue) {
      if (this.formDataChanged?.fieldName === this.startDateFieldName && this.agendaItems?.length) {
        this.updateAgendaTime();
        if (!this.isMeetingMinutes) {
          if (this.currentTimeObservable$) {
            this.timerVisible = false;
            this.countdown = '';
            this.currentTimeObservable$.unsubscribe();
          }
          this.startAgendaTimeCountDown();
        }
      }
    }
    if (changes.topField?.currentValue) {
      this.isCanUpdate = this.editMode === 'Edit' && !this.topField.editorOptions.readOnly;
      this.loadPanelService.show('Agenda Daten werden geladen.');
      this.getData().subscribe({
        next: () => {
          if (!this.isMeetingMinutes) {
            this.startAgendaTimeCountDown();
          }
          this.resetLoading();
        },
        error: () => {
          this.resetLoading();
        }
      });
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.currentTimeObservable$) {
      this.currentTimeObservable$.unsubscribe();
    }
  }
  getData(): Observable<TreeViewItem[]> {
    return of(this.topField).pipe(
      tap(() => {
        this.topColumnsConfig = this.topField.editorOptions.agendaSettings.topColumns || defaultAgendaItemField;
        this.submissionFields =
          this.topField.editorOptions.agendaSettings.submissionFields || defaultAgendaItemSubmissionField;
        this.protocolFields =
          this.topField.editorOptions.agendaSettings.protocolFields || defaultAgendaItemSubmissionField;
        this.taskFields = this.topField.editorOptions.agendaSettings.taskFields || defaultAgendaItemTaskField;

        this.displayColumns = this.protocolFields?.columns || 2;
        if (!this.isMeetingMinutes) {
          this.displayColumns = this.submissionFields?.columns || 2;
          this.topTemplateId = this.topField.editorOptions.agendaSettings.topSubTemplates?.templateTopId;
          this.templateDecisionId = this.topField.editorOptions.agendaSettings.topSubTemplates?.templateDecisionId;
          this.templateProtocolId = this.topField.editorOptions.agendaSettings.topSubTemplates?.templateProtocolId;
          this.templateSubmissionIds =
            this.topField.editorOptions.agendaSettings.topSubTemplates?.templateSubmissionIds;
          this.templateTaskId = this.topField.editorOptions.agendaSettings.topSubTemplates?.templateTaskId;
          this.dynamicListName = this.topField.editorOptions.agendaSettings.topSubTemplates?.dynamicListName;
          this.templateMeetingMinutesId =
            this.topField.editorOptions.agendaSettings.agendaSubTempates?.templateMeetingMinutesId;
          this.templateRapidMeetingMinutesId =
            this.topField.editorOptions.agendaSettings.agendaSubTempates?.templateRapidMeetingMinutesId;
          const maxDepth = this.topField.editorOptions.agendaSettings.maxDepth;
          this.maxDepth = Number.isInteger(maxDepth) ? maxDepth : 1;
          this.startDateFieldName = this.topField.editorOptions.agendaSettings.startDateFieldName;
        }

        this.setColumns();
      }),
      switchMap(() => {
        const items = this.formData[siamConst.agendaField] as INewAgendaServer[];
        return this.getItemDocuments(items);
      }),
      tap(items => {
        this.agendaItems = this.mapAgendaItems(items);
        this.updateFilteredTreeViewItems();
        if (this.isMeetingMinutes) {
          this.agendaResult.emit({
            command: 'agendaItems',
            dataField: siamConst.agendaField,
            field: this.topField,
            value: this.agendaItems
          });
        } else {
          this.updateAgendaTime();
        }
      })
    );
  }
  updateItemsValidity(items: TreeViewItem[]): void {
    items.map(item => {
      item.isValid = true;
      if (!item.document) {
        let documentExist = false;
        if (!item.parentId) {
          documentExist = this.documentService.isChildDocument(this.agendaDocument, item.id);
        } else {
          const parent = items.find(r => r.id === item.parentId);
          documentExist = parent?.document ? this.documentService.isChildDocument(parent.document, item.id) : false;
        }
        if (!documentExist) {
          item.isValid = false;
          item.isActive = false;
          if (item.type === 'submission') {
            item.itemSubmissionAction = this.getItemBaseActions();
          } else if (item.type === 'top') {
            item.itemTOPAction = this.getItemBaseActions();
          }
          item.duration = 0;
        }
      }
      return item;
    });
  }
  addClassToPopupActions(e: ItemRenderedEvent<IActionAgenda>): void {
    e.itemElement.classList.add('agenda-item-actionsheet');
  }
  showAgendaActions = (event: Event): void => {
    if (event?.target) {
      this.selectedTOP = null;
      this.agendaActionsTarget = event.target;
      this.agendaActionsVisible = true;
    }
  };
  updateAgendaTime(): void {
    const parentItems = this.agendaItems.filter(r => r.type === 'top' || r.type === 'pause');
    this.calculateAgendaTimes(parentItems, this.agendaStartDate);
    this.setDocumentFields();
    this.setAgendaTotalTime();
    this.agendaResult.emit({
      command: 'agendaItems',
      dataField: siamConst.agendaField,
      field: this.topField,
      value: this.agendaItems
    });
  }

  /**
   * calculate start/endTime and duration of all items
   *
   * @param agendaItems
   * @param parentEndTime
   */
  calculateAgendaTimes(agendaItems: TreeViewItem[], parentEndTime: Date) {
    let previousEndTime = new Date(parentEndTime);
    agendaItems.forEach(item => {
      if (!item.parentId) {
        item.startTime = previousEndTime;
        item.duration = Number.isInteger(item.duration) ? item.duration : this.topDurationDefaultValue;
        item.endTime = this.addMinutes(previousEndTime, item.duration);
        previousEndTime = item.endTime;
      } else {
        const parentItem = this.agendaItems.find(
          parent => parent.id === item.parentId && (item.type === 'top' || 'pause') && item.isActive
        );
        const isParentinItems = agendaItems.find(
          parent => parent.id === item.parentId && (item.type === 'top' || 'pause') && item.isActive
        );
        if (parentItem && !isParentinItems) {
          item.startTime = previousEndTime;
          item.duration = Number.isInteger(item.duration) ? item.duration : this.topDurationDefaultValue;
          item.endTime = this.addMinutes(previousEndTime, item.duration);
          previousEndTime = item.endTime;
        }
      }
      const children = this.agendaItems.filter(
        childItem =>
          childItem.parentId === item.id && (childItem.type === 'top' || childItem.type === 'pause') && item.isActive
      );
      if (children && children.length > 0) {
        this.calculateAgendaTimes(children, new Date(item.startTime));
        const childrenDuration = children.reduce((acc, child) => acc + child.duration, 0);
        item.duration = childrenDuration;
        item.endTime = this.addMinutes(item.startTime, childrenDuration);
      }
    });
  }

  /**
   * set duration and speakers Fields to item-documents
   */
  setDocumentFields(): void {
    this.agendaItems?.forEach(item => {
      if (!item?.document?.fields) {
        return;
      }
      if (!this.documentHasPermission(item.document, 'update')) {
        return;
      }

      if (item.type === 'top' || item.type === 'pause') {
        item.document.fields[siamConst.topDuration] = { value: item.duration, valueType: 'double' };
        item.document.fields[siamConst.topStartTime] = { value: item.startTime, valueType: 'timeStamp' };
        item.document.fields[siamConst.topEndTime] = { value: item.endTime, valueType: 'timeStamp' };
        const speakers = copy(item.speakers)?.map(speaker => {
          if (checkIfValidUUID(speaker)) {
            return { type: 'user', targetId: speaker } as IPermissionTarget;
          } else if (isCompositeId(speaker)) {
            return createPermissionTargetWithoutCompositeId(speaker);
          } else {
            return speaker;
          }
        });
        item.document.fields[siamConst.topSpeakers] = { value: speakers || [], valueType: 'references' };
        if (item.position) {
          item.document.fields[siamConst.topPosition] = { value: item.position, valueType: 'text' };
        }
        item.document.fields[siamConst.topActive] = { value: item.isActive, valueType: 'boolean' };
        item.document.fields[siamConst.topType] = { value: item.type, valueType: 'text' };
      }
    });
  }

  /**
   * set totalendtime
   */
  setAgendaTotalTime(): void {
    const items = this.agendaItems.filter(
      r => !r.parentId && (r.type === 'top' || r.type === 'pause') && r.isActive && r.isValid
    );
    if (items && items.length) {
      const startTime: Date = new Date(items[0].startTime);
      const endTime: Date = new Date(items[items.length - 1].endTime);
      this.agendaEndTime = endTime.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit'
      });
      this.agendaTotalTime = this.calculateTimeInHours(startTime, endTime);
      // Run change detection explicitly after the change
      this.cdRef.detectChanges();
    }
  }

  /**
   * increase duration of an Item
   * @param item
   * @returns
   */
  increaseTime = (item: TreeViewItem): void => {
    const selectedItem = this.agendaItems.find(el => el.id === item.id);
    if (!selectedItem) {
      return;
    }
    const oldDuration = Number.isInteger(item.duration) ? item.duration : this.topDurationDefaultValue;
    const newDuration = oldDuration + this.topDurationStep;
    selectedItem.duration = newDuration;
    this.updateAgendaTime();
  };

  /**
   * decrease duration of an Item
   *
   * @param item
   * @returns
   */
  decreaseTime = (item: TreeViewItem): void => {
    const selectedItem = this.agendaItems.find(el => el.id === item.id);
    if (!selectedItem) {
      return;
    }
    const oldDuration = Number.isInteger(item.duration) ? item.duration : this.topDurationDefaultValue;
    if (oldDuration > this.topDurationStep) {
      const newDuration = oldDuration - this.topDurationStep;
      selectedItem.duration = newDuration;
      this.updateAgendaTime();
    }
  };

  /**
   * get item-documents from server und map items to Client
   *
   * @param items
   *
   */
  getItemDocuments(items: INewAgendaServer[]): Observable<TreeViewItem[]> {
    const documentsIds: string[] = [];
    for (const item of items) {
      if (!documentsIds.find(id => id === item.documentId)) {
        documentsIds.push(item.documentId);
      }
    }
    return this.documentService
      .getDocumentsByIds(documentsIds)
      .pipe(map(documents => this.mapAgendaItemsServerToClient(items, documents)));
  }

  /**
   * map agendaItems from Server to Client
   *
   * @param items
   * @param documents
   *
   */
  mapAgendaItemsServerToClientMeetingMinutes(items: INewAgendaServer[], documents: IDocument[]): TreeViewItem[] {
    const result = items
      .filter(item => documents.find(d => d.id === item.documentId))
      .map(item => {
        const document = documents.find(d => d.id === item.documentId);
        const documentSpeakers = document.fields[siamConst.topSpeakers]?.value as IPermissionTarget[];
        const speakers: string[] =
          documentSpeakers?.map((speaker: string | IPermissionTarget) => {
            if (checkIfValidUUID(speaker as string)) {
              return `user:${speaker as string}`;
            } else if ((speaker as IPermissionTarget).type && (speaker as IPermissionTarget).targetId) {
              return permissionTarget(speaker as IPermissionTarget)?.compositeId;
            } else {
              return null;
            }
          }) || [];
        const type = document.fields[siamConst.topType]?.value as TAgendaItemType;
        const isActive = document.fields[siamConst.topActive]?.value as boolean;
        const treeViewItem: TreeViewItem = {
          id: item.documentId,
          parentId: item.parentDocumentId !== '-1' ? item.parentDocumentId : null,
          document,
          originalDocument: copy(document),
          type,
          isDirectory: type === 'top',
          duration: document.fields[siamConst.topDuration]?.value as number,
          startTime: document.fields[siamConst.topStartTime]?.value as Date,
          endTime: document.fields[siamConst.topEndTime]?.value as Date,
          speakers,
          isActive: !isUndefinedValue(isActive) ? item.isActive : true
        };
        return treeViewItem;
      })
      .filter(item => item.type !== 'pause');

    return result;
  }
  /**
   * map agendaItems from Server to Client
   *
   * @param items
   * @param documents
   *
   */
  mapAgendaItemsServerToClient(items: INewAgendaServer[], documents: IDocument[]): TreeViewItem[] {
    if (this.isMeetingMinutes) {
      return this.mapAgendaItemsServerToClientMeetingMinutes(items, documents);
    }
    const uniq = items.filter((item, index, self) => index === self.findIndex(t => t.documentId === item.documentId));

    const result = uniq.map(item => {
      const document = documents.find(d => d.id === item.documentId);
      const speakers: string[] =
        item.speakers?.map((speaker: string | IPermissionTarget) => {
          if (checkIfValidUUID(speaker as string)) {
            return `user:${speaker as string}`;
          } else if ((speaker as IPermissionTarget).type && (speaker as IPermissionTarget).targetId) {
            return permissionTarget(speaker as IPermissionTarget)?.compositeId;
          } else {
            return null;
          }
        }) || [];
      const treeViewItem: TreeViewItem = {
        id: item.documentId,
        parentId: item.parentDocumentId !== '-1' ? item.parentDocumentId : null,
        document,
        originalDocument: copy(document),
        type: item.type,
        isDirectory: item.type === 'top',
        duration: item.duration,
        startTime: item.startTime,
        endTime: item.endTime,
        speakers,
        isActive: isHasProperty(item, 'isActive') ? item.isActive : true
      };
      return treeViewItem;
    });
    this.updateItemsValidity(result);
    const array = result.map(treeViewItem => {
      if (treeViewItem.type === 'submission') {
        treeViewItem.itemSubmissionAction = this.getItemBaseActions();
      } else if (treeViewItem.type === 'top') {
        treeViewItem.itemTOPAction = this.getItemBaseActions();
      }
      return treeViewItem;
    });
    return array;
  }

  onSubmissionDragStart = (e: DragStartEvent, parentItem: TreeViewItem): void => {
    if (!parentItem?.isActive || this.isMeetingMinutes || !this.isCanUpdate) {
      e.cancel = true;
    }
    e.itemData = (e.fromData as TreeViewItem[])[e.fromIndex];
  };
  onSubmissionDragChange = (e: DragChangeEvent): void => {
    const getComponentType = (component: dxSortable | dxDraggable): 'agenda' | 'submission' => {
      if (component.element().id === 'treeview-agenda') {
        return 'agenda';
      }
      return 'submission';
    };
    const fromType = getComponentType(e.fromComponent);
    const toType = getComponentType(e.toComponent);
    if (fromType === 'agenda' && toType === 'submission') {
      e.cancel = true;
    }
  };

  onSubmissionDrop = (e: AddEvent, parentItem: TreeViewItem): void => {
    if (!parentItem?.isActive) {
      return;
    }
    let parentId: string = null;
    if (!(e.toData as TreeViewItem[])?.length && e.toComponent) {
      const elementId = e.component.element().id;
      if (elementId) {
        parentId = elementId.split('-')[2];
      }
    }
    if ((e.toData as TreeViewItem[])?.length) {
      parentId = (e.toData as TreeViewItem[])[0].parentId;
    }
    (e.itemData as TreeViewItem).parentId = parentId;
    (e.fromData as TreeViewItem[]).splice(e.fromIndex, 1);
    (e.toData as TreeViewItem[]).splice(e.toIndex, 0, e.itemData as TreeViewItem);
    this.agendaItems
      .filter(item => item.type === 'top-submissions')
      .map(item => {
        item.submissionsItems = this.splitArrayIntoChunks(item.submissionsItems, this.displayColumns);
      });
    this.agendaItems = this.updateSubmissions(this.agendaItems);
    // this.updateFilteredTreeViewItems();
  };

  onAddSubmission = (e: AddEvent): void => {
    const toNode = this.findNode(this.treeViewAgendaItems.instance, this.calculateToIndex(e));
    const fromItem = (e.fromData as TreeViewItem[])[e.fromIndex];
    const toItem = toNode?.itemData as TreeViewItem;

    if (!fromItem?.parentId || !toItem) {
      return;
    }
    if (toItem.type !== 'top') {
      NotifyService.global.warn(`Vorlage kann nur zu einem TOP verschoben werden!`, 5000);
      return;
    }
    const hasChildTop = this.hasChildDocument(toItem, 'top');
    if (hasChildTop) {
      return;
    }
    const fromIndexParent = this.agendaItems.findIndex(item => item.id === fromItem.parentId);
    const submissionIndex = this.agendaItems[fromIndexParent].submissions.findIndex(item => item.id === fromItem.id);
    this.agendaItems[fromIndexParent].submissions.splice(submissionIndex, 1);
    const fromSubmissions = this.agendaItems[fromIndexParent].submissions;
    this.updateItemSubmissions(fromSubmissions, fromItem.parentId);

    const toIndexParent = this.agendaItems.findIndex(item => item.id === toItem.id);
    fromItem.parentId = toItem.id;
    this.agendaItems[toIndexParent].submissions.push(fromItem);

    const toSubmissions = this.agendaItems[toIndexParent].submissions;
    this.updateItemSubmissions(toSubmissions, toItem.id);
    this.updateFilteredTreeViewItems();
  };
  onDragStart = (e: DragStartEvent): void => {
    if (this.isMeetingMinutes || !this.isCanUpdate) {
      e.cancel = true;
    }
    const treeView = this.treeViewAgendaItems.instance;
    const fromNode = this.findNode(treeView, e.fromIndex);
    if ((fromNode?.itemData as TreeViewItem).type === 'top-submissions') {
      e.cancel = true;
    }
  };
  /**
   * onDragChange of treeView
   *
   * @param e
   */
  onDragChange = (e: DragChangeEvent): void => {
    if (e.fromComponent === e.toComponent) {
      const fromNode = this.findNode(this.treeViewAgendaItems.instance, e.fromIndex);
      const toNode = this.findNode(this.treeViewAgendaItems.instance, this.calculateToIndex(e));
      if (toNode !== null && this.isChildNode(fromNode, toNode)) {
        e.cancel = true;
      }
    }
  };

  /**
   * onDragEnd of treeView
   *
   * @param e
   */
  onDragEnd = async (e: DragChangeEvent): Promise<void> => {
    if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
      return;
    }
    const toItemData = e.toData as TreeViewItem[];
    if (toItemData?.length && toItemData[0].type === 'submission') {
      return;
    }
    const treeView = this.treeViewAgendaItems.instance;
    const fromNode = this.findNode(treeView, e.fromIndex);
    const toNode = this.findNode(treeView, this.calculateToIndex(e));
    const previousNode = this.findNode(treeView, this.calculateToIndex(e) - 1);
    const fromItem = fromNode?.itemData as TreeViewItem;
    const toItem = toNode?.itemData as TreeViewItem;
    const allowDrop = this.dragExceptions(e.dropInsideItem, fromItem, toItem, previousNode);
    if (!allowDrop) {
      return;
    }

    const fromTopVisibleNode = this.getTopVisibleNode(e.fromComponent);

    this.moveNode(fromNode, toNode, previousNode, e.dropInsideItem);

    await treeView.scrollToItem(fromTopVisibleNode);
    this.agendaItems = this.mapAgendaItems(this.agendaItems);
    this.updateFilteredTreeViewItems();
    this.updateAgendaTime();
  };

  dragExceptions = (dropInside: boolean, fromItem: TreeViewItem, toItem: TreeViewItem, previousNode: Node): boolean => {
    if (dropInside && ['top', 'pause'].includes(fromItem.type) && toItem.type === 'top') {
      const hasSubmissions = toItem.submissions?.length;
      if (hasSubmissions) {
        NotifyService.global.warn(`Nur Vorlagen können zu diesem TOP verschoben werden!`, 5000);
        return false;
      }
    }
    if (dropInside && !toItem?.isDirectory) {
      let type = 'TOP';
      switch (fromItem?.type) {
        case 'submission':
          type = 'Vorlage';
          break;
        case 'pause':
          type = 'Pause';
          break;
        default:
          break;
      }
      NotifyService.global.warn(`${type} kann nur zu einem TOP verschoben werden!`, 5000);
      return false;
    }

    if (!dropInside) {
      if (!toItem) {
        if (fromItem?.type === 'submission') {
          if (
            previousNode?.itemData.type === 'top' ||
            (previousNode?.itemData.type === 'submission' && previousNode.parent.expanded === false)
          ) {
            NotifyService.global.warn(`Vorlage kann nur zu einem TOP verschoben werden!`, 5000);
            return false;
          } else {
            return true;
          }
        }
        if (fromItem?.type === 'top' || fromItem?.type === 'pause') {
          if (previousNode?.itemData.type === 'submission' && previousNode.parent.expanded) {
            NotifyService.global.warn(`TOP kann nur zu einem TOP verschoben werden!`, 5000);
            return false;
          } else {
            return true;
          }
        }
      }

      if (
        !toItem &&
        fromItem?.type === 'submission' &&
        previousNode.itemData?.type === 'submission' &&
        !previousNode?.parent?.expanded
      ) {
        NotifyService.global.warn(`Vorlage kann nur zu einem TOP verschoben werden!`, 5000);
        return false;
      }

      if (['top', 'pause'].includes(fromItem.type) && ['submission', 'top-submissions'].includes(toItem.type)) {
        NotifyService.global.warn(`Nur Vorlagen können zu diesem TOP verschoben werden!`, 5000);
        return false;
      }
      if (fromItem.type === 'submission' && toItem.type === 'top' && toItem.parentId) {
        NotifyService.global.warn(`Nur TOPs können zu diesem TOP verschoben werden!`, 5000);
        return false;
      }
      if (!fromItem.isDirectory && fromItem?.type === 'submission' && !toItem.parentId) {
        NotifyService.global.warn(`Vorlage kann nur zu einem TOP verschoben werden!`, 5000);
        return false;
      }
      return true;
    }
    if (!fromItem && !toItem) {
      return true;
    }
    const depth = this.getLevel(this.agendaItems, toItem?.id, fromItem.id);

    if (toItem.type === 'top' && dropInside && depth >= this.maxDepth) {
      NotifyService.global.warn(`Maximale Tiefe erreicht = ${depth}`, 5000);
      return false;
    }

    if (toItem.type === 'top' && ['top', 'submission', 'pause'].includes(fromItem.type)) {
      const topHasSubmission = this.hasChildDocument(toItem, 'submission');
      const topHasTOP = this.hasChildDocument(toItem, 'top');
      if ((fromItem.type === 'top' || fromItem.type === 'pause') && topHasSubmission) {
        NotifyService.global.warn(`Nur Vorlagen können zu diesem TOP verschoben werden!`, 5000);
        return false;
      }
      if (fromItem.type === 'submission' && topHasTOP) {
        NotifyService.global.warn(`Nur TOPs können zu diesem TOP verschoben werden!`, 5000);
        return false;
      }
    }
    return true;
  };
  isChildNode = (parentNode: Node, childNode: Node): boolean => {
    let parent = childNode.parent;
    while (parent !== null) {
      if (parent.itemData.id === parentNode.itemData.id) {
        return true;
      }
      parent = parent.parent;
    }
    return false;
  };
  findNode = (treeView: dxTreeView, index: number) => {
    const nodeElement = treeView.element().querySelectorAll('.dx-treeview-node')[index];
    if (nodeElement) {
      return this.findNodeById(treeView.getNodes(), nodeElement.getAttribute('data-item-id'));
    }
    return null;
  };
  findNodeById = (nodes: Node[], id: string): Node => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].itemData.id == id) {
        return nodes[i];
      }
      if (nodes[i].children) {
        const node = this.findNodeById(nodes[i].children, id);
        if (node != null) {
          return node;
        }
      }
    }
    return null;
  };

  calculateToIndex = (e: DragChangeEvent): number => {
    if (e.fromComponent != e.toComponent || e.dropInsideItem) {
      return e.toIndex;
    }

    return e.fromIndex >= e.toIndex ? e.toIndex : e.toIndex + 1;
  };

  getTopVisibleNode = (component: dxSortable | dxDraggable): Element => {
    const treeViewElement = component.element();
    const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
    const nodes = treeViewElement.querySelectorAll('.dx-treeview-node');
    for (let i = 0; i < nodes.length; i++) {
      const nodeTopPosition = nodes[i].getBoundingClientRect().top;
      if (nodeTopPosition >= treeViewTopPosition) {
        return nodes[i];
      }
    }

    return null;
  };

  moveNode = (
    fromNode: Node,
    toNode: Node,
    previousToNode: Node,

    isDropInsideItem: boolean
  ): void => {
    const fromIndex = this.agendaItems.findIndex(item => item.id == fromNode.itemData.id);
    this.agendaItems.splice(fromIndex, 1);

    const toIndex =
      toNode === null || isDropInsideItem
        ? this.agendaItems.length
        : this.agendaItems.findIndex(item => item.id == toNode.itemData.id);
    this.agendaItems.splice(toIndex, 0, fromNode.itemData as TreeViewItem);

    this.moveChildren(fromNode);
    if (isDropInsideItem) {
      fromNode.itemData.parentId = toNode.itemData.id;
    } else {
      if (toNode?.itemData) {
        fromNode.itemData.parentId = toNode.itemData.parentId;
      } else {
        if (previousToNode.parent?.expanded && fromNode?.itemData?.type === previousToNode?.itemData?.type) {
          fromNode.itemData.parentId = previousToNode.itemData.parentId;
        } else {
          fromNode.itemData.parentId = null;
        }
      }
    }
  };

  moveChildren = (node: Node): void => {
    if (!node.itemData.isDirectory) {
      return;
    }

    node.children.forEach(child => {
      if (child.itemData.isDirectory) {
        this.moveChildren(child);
      }

      const fromIndex = this.agendaItems.findIndex(item => item.id == child.itemData.id);
      this.agendaItems.splice(fromIndex, 1);
      this.agendaItems.splice(this.agendaItems.length, 0, child.itemData as TreeViewItem);
    });
  };
  onSelectDocument = (event: RowClickEvent<TreeViewItem, TreeViewItem>): void => {
    const topProtocolItem = event.data;
    if (!topProtocolItem?.document?.id) {
      return;
    }
    this.documentService
      .getDocumentById(topProtocolItem.document.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: result => {
          if (result) {
            const settings: PopupSettings = {};
            settings.title = `${this.documentService.getTypeLabel(result)} : ${
              (result.fields?.subject?.value as string) || ''
            }`;

            void this.documentEditorComponent.show(result, settings);
          }
        }
      });
  };
  openDocument = (agendaItem: TreeViewItem, type: 'top-protocol' | 'decision'): void => {
    switch (type) {
      case 'top-protocol':
        {
          const parentItem = this.agendaItems.find(item => item.id === agendaItem.parentId);
          const documentExist = parent?.document
            ? this.documentService.isChildDocument(parentItem?.document, agendaItem.id)
            : false;
          if (documentExist && !parentItem?.protocol?.id) {
            NotifyService.global.warn('Sie haben keine Berechtigung um das TOP-Dokument zu öffnen.', 5000);
            return;
          }
          if (!documentExist && !agendaItem.document) {
            const messageDialog =
              'Das TOP-Dokument könnte nicht gefunden werden möchten das aus der Sitzung entfernen? <br>';
            void confirm(messageDialog, 'TOP-Protokoll entfernen').then(dialogResult => {
              if (dialogResult) {
                this.removeEntry(agendaItem.id, agendaItem.type, agendaItem.parentId);
                this.updateAgendaTime();
                this.updateFilteredTreeViewItems();
                NotifyService.global.success('Vorlage wurde entfernt.');
              }
            });
            return;
          }
          this.documentService
            .getDocumentById(parentItem.protocol.id)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: result => {
                if (result) {
                  const settings: PopupSettings = {};
                  settings.title = `${this.documentService.getTypeLabel(result)} : ${
                    (result.fields?.subject?.value as string) || ''
                  }`;

                  void this.documentEditorComponent.show(result, settings);
                }
              }
            });
        }
        break;
      case 'decision':
        {
          const doc = this.getDecisionDocument(agendaItem);
          if (doc.templateId === _defaultDecisionId) {
            const settings: PopupSettings = {};
            settings.editMode = 'ReadOnly';
            settings.openNewWindow = false;
            settings.title = 'Beschlussmarkierung' + doc.template.caption;
            void this.documentEditorComponent.show(doc, settings);
          } else {
            this.documentService
              .getDocumentById(doc.id)
              .pipe(takeUntil(this.destroyed$))
              .subscribe({
                next: document => {
                  if (document) {
                    const settings: PopupSettings = {};
                    settings.editMode = 'ReadOnly';
                    settings.openNewWindow = false;
                    settings.title =
                      'Beschlussmarkierung' + (document.template ? ' für ' + document.template.caption : '');
                    void this.documentEditorComponent.show(document, settings);
                  }
                }
              });
          }
        }
        break;
      default:
        break;
    }
  };
  onOpenItem = (item: TreeViewItem): void => {
    if (!item?.document) {
      return;
    }
    const agenddaDocument = item.document;
    this.documentService
      .getDocumentById(agenddaDocument.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (aiDocument): void => {
          if (!aiDocument) {
            NotifyService.global.warn('Kein Dokument gefunden.', 5000);
            return;
          }
          const iType = this.documentService.getType(aiDocument);
          if (!iType) {
            NotifyService.global.warn(
              'Der Typ des Agendapunktes konnte nicht ermittelt werden. Ggf. arbeitest Du mit veralteten Vorlagen?',
              5000
            );
          }

          const settings: PopupSettings = {};
          settings.title = `${this.documentService.getTypeLabel(aiDocument)} : ${
            (aiDocument.fields.subject?.value as string) || ''
          }`;

          // Setzen der PopupAktionen je nach dem, welcher TOP-Typ (TOP oder Vorlage) geöffnet werden soll
          if (!this.isCanUpdate) {
            settings.editMode = 'ReadOnly';
          } else {
            settings.editMode = 'EditPreview';
          }

          // EditorKomponente für angegebenes Dokumentout und den definierten Settings öffnen
          void this.documentEditorComponent.show(aiDocument, settings);
        },
        error: (error: string): void => {
          NotifyService.global.error(error);
        }
      });
  };
  /**
   * create a new break
   */
  onCreateBreakItem = (): void => {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }

    this.templateService
      .getActiveTemplates('create', ['app:document-type:pause'])
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (templates): void => {
          if (templates && !templates.length) {
            NotifyService.global.error(
              'Es wurde keine Vorlage für die Erstellung eine TOP-Pause gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
            );
            return;
          }
          this.saveBreakItem(templates[0]);
        },
        error: (error: string): void => {
          NotifyService.global.error(error);
        }
      });
  };
  saveBreakItem(template: ITemplateServer): void {
    this.documentService
      .createDocumentByTemplateId(template)
      .pipe(
        switchMap(document => this.documentService.save(document)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (document: IDocument): void => {
          if (document) {
            this.addEntry(document, 'pause');
            NotifyService.global.success('Erfolgreich gespeichert');
          }
        },
        error: NotifyService.component.error
      });
  }

  onAddSubmissions = (): void => {
    this.loadPanelService.show();
    const plainArray = convertToPlainArray(copy(this.agendaItems));
    const excludeDocumentsIds = plainArray.map(item => item.id).filter(id => checkIfValidUUID(id));
    const dynamicGridSettings: IDynamicGridSettings = {
      includeOneOfTemplateId: this.templateSubmissionIds,
      excludeDocumentIds: excludeDocumentsIds,
      excludeTags: [],
      excludeStatus: []
    };

    this.dynamicGridSubmissionList = dynamicGridSettings;
    this.popupSubmissionsListVisible = true;
  };

  onCollapseItems = (value: boolean): void => {
    this.collapseAll = value;
    if (value) {
      this.treeViewAgendaItems.instance.expandAll();
    } else {
      this.treeViewAgendaItems.instance.collapseAll();
    }
  };

  eventDynamicListResultSubmissionList(event: IEventEmitter<IDocument | IDocument[] | string>): void {
    if (event?.command === 'selection' && event.object) {
      this.resetLoading();
      this.isSelectedSubmissionList = !(event.object as IDocument[]).length;
      this.selectedSubmissionListIds = (event.object as IDocument[])?.map(e => e.id);
    }
    if (event?.command === 'error' && event?.object) {
      this.resetLoading();
      NotifyService.component.error(event?.object as string);
    }
  }

  /* ----------------------------------------
              handle referenced TOPs
   ----------------------------------------*/
  onAddReferencedTop = (): void => {
    this.loadPanelService.show();
    const plainArray = convertToPlainArray(copy(this.agendaItems));
    const excludeDocumentsIds = plainArray.map(item => item.id).filter(id => checkIfValidUUID(id));
    const dynamicGridSettings: IDynamicGridSettings = {
      excludeDocumentIds: excludeDocumentsIds,
      includeOneOfTemplateId: [this.topTemplateId],
      includeParentIds: [this.agendaDocument.id],
      excludeTags: [],
      excludeStatus: [],
      toolbar: {
        dateField: null,
        userField: null,
        isShowSearch: true,
        isShowDateFilter: false,
        isShowExportExcel: false,
        isShowGrouping: false,
        isShowPersonalMode: false,
        isShowPreviewSwitch: false,
        rowAlternationEnabled: true,
        isShowKanban: true,
        isShowTitle: false
      }
    };

    this.dynamicGridReferencedTOPList = dynamicGridSettings;
    this.popupReferencedFreeTopsListVisible = true;
  };

  eventDynamicListResultReferencedTOPsList(event: IEventEmitter<IDocument | IDocument[] | string>): void {
    this.resetLoading();
    if (event?.command === 'selection' && event.object) {
      this.isSelectedReferencedTOPList = !(event.object as IDocument[]).length;
      this.selectedReferencedTOPListIds = (event.object as IDocument[])?.map(e => e.id);
    }
    if (event?.command === 'error' && event?.object) {
      NotifyService.component.error(event?.object as string);
    }
  }

  onSubmitReferencedTOPsList = (): void => {
    this.documentService
      .getDocumentsByIds(this.selectedReferencedTOPListIds)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: documents => {
          for (const document of documents) {
            this.addEntry(document, 'top');
          }
          this.loadPanelService.hide();
          this.selectedReferencedTOPListIds = [];
          NotifyService.global.success('Die TOPs wurden neu referenziert.');
          this.popupReferencedFreeTopsListVisible = false;
        }
      });
  };

  onCancelReferencedTOPsList = (): void => {
    this.popupReferencedFreeTopsListVisible = false;
    this.selectedReferencedTOPListIds = [];
  };
  onRemoveReferencedTOPsList = (): void => {
    if (!this.selectedReferencedTOPListIds?.length) {
      return;
    }
    const itemsToRemove: { parentId: string; documentId: string }[] = [];
    for (const documentId of this.selectedReferencedTOPListIds) {
      itemsToRemove.push({ parentId: this.agendaDocument.id, documentId });
    }
    this.documentService
      .removeReferences(itemsToRemove)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.popupReferencedFreeTopsListVisible = false;
          NotifyService.global.success('Die Verknüpfung wurde gelöst.');
        },
        error: () => {
          this.popupReferencedFreeTopsListVisible = false;
        }
      });
  };
  /* ----------------------------------------
              handle free TOPs
   ----------------------------------------*/
  onAddFreeTop = (): void => {
    this.loadPanelService.show();
    const plainArray = convertToPlainArray(copy(this.agendaItems));
    const excludeDocumentsIds = plainArray.map(item => item.id).filter(id => checkIfValidUUID(id));
    const dynamicGridSettings: IDynamicGridSettings = {
      excludeDocumentIds: excludeDocumentsIds,
      includeOneOfTemplateId: [this.topTemplateId],
      hasNoChild: true,
      hasNoParent: true,
      excludeTags: [],
      excludeStatus: [],
      toolbar: {
        dateField: null,
        userField: null,
        isShowSearch: true,
        isShowDateFilter: false,
        isShowExportExcel: false,
        isShowGrouping: false,
        isShowPersonalMode: false,
        isShowPreviewSwitch: false,
        rowAlternationEnabled: true,
        isShowKanban: true,
        isShowTitle: false
      }
    };

    this.dynamicGridFreeTOPList = dynamicGridSettings;
    this.popupFreeTopsListVisible = true;
  };

  eventDynamicListResultFreeTOPsList(event: IEventEmitter<IDocument | IDocument[] | string>): void {
    this.resetLoading();
    if (event?.command === 'selection' && event.object) {
      this.isSelectedFreeTOPList = !(event.object as IDocument[]).length;
      this.selectedFreeTOPListIds = (event.object as IDocument[])?.map(e => e.id);
    }
    if (event?.command === 'error' && event?.object) {
      NotifyService.component.error(event?.object as string);
    }
  }

  onSubmitFreeTOPsList = (): void => {
    this.documentService
      .getDocumentsByIds(this.selectedFreeTOPListIds)
      .pipe(
        tap(documents => (this.selectedFreeTOPList = documents)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: () => {
          this.addFreeTopsRecursive();
          this.popupFreeTopsListVisible = false;
        }
      });
  };

  onCancelFreeTOPsList = (): void => {
    this.popupFreeTopsListVisible = false;
    this.selectedFreeTOPListIds = [];
  };
  onDeleteFreeTOPsList = (): void => {
    this.deleteFreeTopsRecursive();
    this.popupFreeTopsListVisible = false;
  };

  addFreeTopsRecursive = (iterator?: IterableIterator<IDocument>): void => {
    const selectedItems: IDocument[] = this.selectedFreeTOPList;
    if (!iterator) {
      iterator = selectedItems[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      this.updateAgendaTime();
      this.updateFilteredTreeViewItems();
      this.selectedFreeTOPListIds = [];
      NotifyService.global.success('Die Freien TOPs wurden hinzufügt.');
      return;
    }
    const freeTOP = result.value as IDocument;
    this.createTOPDocument()
      .pipe(
        switchMap(newTOP => from(this.documentService.createFromParent(newTOP, freeTOP))),
        switchMap(doc => this.documentService.save(doc)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (savedTopDocument): void => {
          //add TOP
          this.addEntry(savedTopDocument, 'top');
        },
        error: (error: string) => {
          this.loadPanelService.hide();
          NotifyService.global.error(error);
        },
        complete: () => {
          this.addFreeTopsRecursive(iterator);
        }
      });
  };

  deleteFreeTopsRecursive = (iterator?: IterableIterator<string>): void => {
    const selectedItems: string[] = this.selectedFreeTOPListIds;
    if (!iterator) {
      iterator = selectedItems[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      this.selectedFreeTOPListIds = [];
      NotifyService.global.success('Die Freien TOPs wurden gelöscht.');
      return;
    }
    const freeTOPId = result.value as string;
    this.documentService
      .getDocumentById(freeTOPId)
      .pipe(
        switchMap(doc => this.documentService.delete(doc, false, true)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (): void => {
          //Nothing todo
        },
        error: (error: string) => {
          this.loadPanelService.hide();
          NotifyService.global.error(error);
        },
        complete: () => {
          this.deleteFreeTopsRecursive(iterator);
        }
      });
  };
  /* ----------------------------------------
              handle TOPs From other Agenda
   ----------------------------------------*/
  onAddTopFromAgenda = (): void => {
    this.loadPanelService.show('Verfügbare Tagesordungpunkte werden geladen...');
    const properties: IDocumentsSearch = {
      includeTags: ['app:document-type:top'],
      excludeParentIds: [this.agendaDocument.id],
      isIncludeLists: false,
      hasParent: true,
      documentFields: selectedFieldsTOP
    };
    this.documentService
      .documentsSearch(properties)
      .pipe(
        switchMap(data => this.getReferencesFromDocuments(data.documents)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: documents => {
          void (async () => {
            const topList: ITopFromAgenda[] = [];
            for (const document of documents) {
              const user = create();
              user.id = document.creation.userId;
              const _userData = await lastValueFrom(this.nameDataPipe.transform(user, document.creation.logonUserId));

              const hasParent = this.documentService.hasChildOfTag(document, 'app:document-type:agenda');
              if (hasParent) {
                const parentDocument = document.references.find(
                  reference => reference.tags.some(tag => tag === 'app:document-type:agenda') === true
                );
                const subject = parentDocument?.document?.fields?.subject?.value
                  ? `(${parentDocument?.document?.fields?.subject?.value as string})`
                  : '';
                const topSubject = document?.fields?.subject?.value
                  ? `(${document?.fields?.subject?.value as string})`
                  : '';
                const agendaStartDateFieldValue = parentDocument?.document?.fields['startdate']?.value as string;
                const agendaStartDateValue = agendaStartDateFieldValue ? new Date(agendaStartDateFieldValue) : null;

                const agendaStartDate = agendaStartDateFieldValue
                  ? new Date(agendaStartDateFieldValue).toLocaleDateString().slice(0, 10)
                  : '';
                const parentCreationSubject = agendaStartDate + subject;
                const top: ITopFromAgenda = {
                  id: document.id,
                  subject: topSubject,
                  document,
                  creatorname: _userData.name,
                  creatordata: _userData.avatarName,
                  attachment: this.documentService.hasAttachment(document),
                  agendaSubject: parentCreationSubject,
                  agendaStartDate: agendaStartDateValue ? agendaStartDateValue.toISOString() + subject : subject,
                  agendaType: parentDocument?.document?.template?.caption || ''
                };
                if (parentDocument?.document?.id !== this.agendaDocument.id) {
                  topList.push(top);
                }
              }
            }
            this.popupTOPsFromAgendaList = topList;
            this.popupTOPsFromAgendaListVisible = true;
            this.loadPanelService.hide();
          })();
        },
        error: () => {
          this.loadPanelService.hide();
        }
      });
  };
  onContentReadyOldTops(e: ContentReadyEvent): void {
    const isGrouped = !!e.component.columnOption('groupIndex:0');
    const element: HTMLElement = e.element.querySelector('#collapseBtn');
    element.hidden = !isGrouped;
  }
  onToolbarPreparingOldTops(e: ToolbarPreparingEvent): void {
    e.toolbarOptions.items.unshift({
      location: 'before',
      widget: 'dxButton',
      options: {
        elementAttr: { id: 'collapseBtn' },
        icon: 'expand',
        height: '35px',
        stylingMode: 'outlined',
        onClick: this.onCollapseAll
      }
    });
  }
  onCollapseAll = (e: ClickEvent): void => {
    this.popupTOPsFromAgendaListExpanded = !this.popupTOPsFromAgendaListExpanded;
    e.component.option({
      icon: this.popupTOPsFromAgendaListExpanded ? 'collapse' : 'expand'
    });
  };
  getAgendaSubject = (data: ITopFromAgenda): string => {
    return data.agendaSubject || '(nicht kategorisiert)';
  };

  onSelectionChangedTopsFromAgenda(e: SelectionChangedEvent<ITopFromAgenda, ITopFromAgenda>): void {
    this.isSelectedTOPsFromAgendaList = e?.selectedRowKeys?.length === 0;
  }

  onSubmitTOPsFromAgendaList = (): void => {
    const items = this.freeTopSelectionGrid.instance.getSelectedRowsData() as ITopFromAgenda[];
    this.selectedTOPsFromAgendaListIds = items.map(item => item.id);
    this.documentService
      .getDocumentsByIds(this.selectedTOPsFromAgendaListIds)
      .pipe(
        tap(documents => (this.selectedTOPsFromAgendaList = documents)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: () => {
          this.addTOPsFromAgendaRecursive();
          this.popupTOPsFromAgendaListVisible = false;
        }
      });
  };

  onCancelTOPsFromAgendaList = (): void => {
    this.popupTOPsFromAgendaListVisible = false;
    this.selectedTOPsFromAgendaListIds = [];
  };

  addTOPsFromAgendaRecursive = (iterator?: IterableIterator<IDocument>): void => {
    const selectedItems: IDocument[] = this.selectedTOPsFromAgendaList;
    if (!iterator) {
      iterator = selectedItems[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      this.updateAgendaTime();
      this.updateFilteredTreeViewItems();
      this.selectedTOPsFromAgendaListIds = [];
      NotifyService.global.success('Die Freien TOPs wurden hinzufügt.');
      return;
    }
    const freeTOP = result.value as IDocument;
    this.createTOPDocument()
      .pipe(
        switchMap(newTOP => from(this.documentService.createFromParent(newTOP, freeTOP))),
        switchMap(doc => this.documentService.save(doc)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (savedTopDocument): void => {
          //add TOP
          this.addEntry(savedTopDocument, 'top');
        },
        error: (error: string) => {
          this.loadPanelService.hide();
          NotifyService.global.error(error);
        },
        complete: () => {
          this.addTOPsFromAgendaRecursive(iterator);
        }
      });
  };

  /**
   * Submission List
   */

  onSubmitSubmissionList = (): void => {
    this.popupSubmissionsListVisible = false;
    if (!this.selectedSubmissionListIds?.length) {
      return;
    }

    if (this.selectedTOP && this.selectedSubmissionListIds?.length) {
      this.documentService
        .getDocumentsByIds(this.selectedSubmissionListIds)
        .pipe(
          tap(documents => (this.selectedSubmissionList = documents)),
          takeUntil(this.destroyed$)
        )
        .subscribe({
          next: documents => {
            for (const submission of documents) {
              this.addEntry(submission, 'submission', this.selectedTOP.id);
            }
          }
        });

      return;
    }
    // to do : check if TOP document have required Fields
    const onAddTogether = () => {
      this.createTOPDocument().subscribe({
        next: document => {
          void this.documentEditorComponent.show(
            document,
            {
              editMode: 'Edit',
              title: 'Neuer Tagesordnungspunkt'
            },
            { submissionIds: this.selectedSubmissionListIds }
          );
        }
      });
    };
    const onAddseparated = () => {
      this.documentService
        .getDocumentsByIds(this.selectedSubmissionListIds)
        .pipe(
          tap(documents => (this.selectedSubmissionList = documents)),
          takeUntil(this.destroyed$)
        )
        .subscribe({
          next: () => {
            this.addSeparetedSubmissions();
          }
        });
    };
    const title = 'Vorlagen hinzufügen';
    const message = 'Möchten Sie die Vorlagen einzeln oder zusammen hinzufügen?';
    const dialog = custom({
      title: title,
      messageHtml: message,
      buttons: [
        {
          text: `Einzeln`,
          icon: 'custom-icon-size material-icons link_off',
          onClick: () => onAddseparated()
        },
        {
          text: 'Zusammen',
          icon: 'custom-icon-size material-icons link',
          onClick: () => onAddTogether()
        },
        {
          text: 'Abbrechen',
          icon: 'clear',
          onClick: () => false
        }
      ]
    }) as IConfirmDialog;
    if (this.selectedSubmissionListIds?.length === 1) {
      onAddseparated();
    } else {
      void dialog.show();
    }
  };

  onCancelSubmissionList = (): void => {
    this.popupSubmissionsListVisible = false;
  };
  /**
   * create a new document as from Template 'Top'
   */
  onCreateItem = (): void => {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }

    this.createTOPDocument().subscribe({
      next: document => {
        void this.documentEditorComponent.show(document, {
          editMode: 'Edit',
          title: 'Neuer Tagesordnungspunkt'
        });
      }
    });
  };
  onCreateSubItem = (parentItemId: string = null): void => {
    if (!this.agendaDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }

    this.createTOPDocument().subscribe({
      next: document => {
        void this.documentEditorComponent.show(
          document,
          {
            editMode: 'Edit',
            title: 'Neuer Tagesordnungspunkt'
          },
          { parentDocumentId: parentItemId }
        );
      }
    });
  };

  workflowAgendaResult(event: IDocument): void {
    if (event?.id && this.selectedTOP) {
      switch (this.selectedTOP.type) {
        case 'submission':
          {
            const parentIndex = this.agendaItems.findIndex(item => item.id === this.selectedTOP.parentId);
            const topSubmissionIndex = this.agendaItems.findIndex(
              item => item.id === `submissions-${this.selectedTOP.parentId}`
            );
            if (parentIndex > -1 && topSubmissionIndex > -1) {
              const submissionIndex = this.agendaItems[parentIndex].submissions.findIndex(
                item => item.id === this.selectedTOP?.id
              );
              if (submissionIndex > -1) {
                this.agendaItems[parentIndex].submissions[submissionIndex].document = event;
                this.agendaItems[parentIndex].submissions[submissionIndex].itemSubmissionAction =
                  this.getItemBaseActions();
                const submissions = this.agendaItems[parentIndex].submissions;
                this.agendaItems[topSubmissionIndex].submissionsItems = this.splitArrayIntoChunks(
                  submissions,
                  this.displayColumns
                );
                this.updateFilteredTreeViewItems();
                this.selectedTOP = null;
                this.selectedTOPAction = null;
              }
            }
          }

          break;
        case 'top':
          {
            const topIndex = this.agendaItems.findIndex(item => item.id === this.selectedTOP.id);
            if (topIndex > -1) {
              this.agendaItems[topIndex].document = event;
              this.agendaItems[topIndex].itemTOPAction = this.getItemBaseActions();
            }
          }
          break;
        default:
          break;
      }
    }
  }
  /**
   * eventemitter result of DocumentEditor
   *
   * @param e
   */
  documentEditorResult(e: IEventEmitter<IDocument>): void {
    switch (e.command) {
      case 'update': {
        this.updateEntry(e?.object);
        void this.updateAgendaTime();
        this.updateFilteredTreeViewItems();
        this.resetLoading();

        break;
      }
      case 'store': {
        switch (this.selectedTOPAction) {
          case 'task':
            {
              if (this.selectedTOP?.type === 'submission') {
                this.addEntry(e.object, 'task', this.selectedTOP.id);
              }
            }

            break;
          case 'protocol':
            {
              if (this.selectedTOP) {
                this.addEntry(e.object, 'top-protocol', this.selectedTOP.id);
              }
            }

            break;

          default:
            if (this.documentService.isTop(e.object)) {
              const selectedTopIndex = this.agendaItems.findIndex(item => item.id === e.object.id);
              if (selectedTopIndex > -1) {
                this.agendaItems[selectedTopIndex].document = e.object;
                void this.updateAgendaTime();
                this.updateFilteredTreeViewItems();
              } else {
                if (e.additionalData?.submissionIds?.length) {
                  this.addTogetherSubmissions(e.additionalData.submissionIds, e.object);
                } else if (e.additionalData?.parentDocumentId) {
                  this.addEntry(e.object, 'top', e.additionalData.parentDocumentId);
                } else {
                  this.addEntry(e.object, 'top');
                }
              }
            } else if (this.documentService.isSubmission(e.object)) {
              const index = this.agendaItems.findIndex(item => item.id === this.selectedTOP.id);
              if (index > -1) {
                this.agendaItems[index].refDocuments = [e.object];
                void this.updateAgendaTime();
                this.updateFilteredTreeViewItems();
              } else {
                this.addEntry(e.object, 'submission', this.selectedTOP.id);
              }
            }
            break;
        }
        this.resetLoading();
        break;
      }
      case 'remove': {
        if (e.object) {
          this.removeEntry(e.object.id, 'top-protocol', this.selectedTOP?.id);
          void this.updateAgendaTime();
          this.resetLoading();
        }
        break;
      }
      case 'cancel':
      case 'saved':
        this.resetLoading();
        break;

      case 'loading':
        this.loadPanelService.show('Dokument wird gespeichert.');
        break;

      case 'reload':
        this.loadPanelService.show('Dokument wird geladen.');
        this.documentService
          .getDocumentById(this.agendaDocument.id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: document => {
              this.agendaDocument = document;
              this.resetLoading();
            },
            error: (error: IError) => {
              this.resetLoading();
              NotifyService.component.error(error);
            }
          });
        break;
    }
  }
  /**
   * update start/endate based on previous item and duration
   *
   * @param toItem
   * @param fromItem
   */
  updateAgendaItemTime(toItem: TreeViewItem, fromItem?: TreeViewItem): void {
    const timeInterval = Number.isInteger(toItem.duration) ? toItem.duration : this.topDurationDefaultValue;
    const startTime = (fromItem && fromItem.endTime) || this.agendaStartDate;

    const endTime = this.addMinutes(startTime, timeInterval) || this.addMinutes(this.agendaStartDate, timeInterval);
    toItem.startTime = startTime;
    toItem.endTime = endTime;
    toItem.duration = timeInterval || this.calculateTimeInMinutes(startTime, endTime);
  }
  /**
   * returns new Date after adding duration
   *
   * @param date
   * @param minutes
   *
   */
  addMinutes(date: Date, minutes: number): Date {
    if (date) {
      const formatedDate = Date.parse(date.toString());
      return new Date(formatedDate + minutes * 60000);
    }
    return null;
  }
  /**
   * return duration as number
   *
   * @param startTime
   * @param endTime
   *
   */
  calculateTimeInMinutes(startTime: Date, endTime: Date): number {
    const msec = endTime.valueOf() - startTime.valueOf();
    return Math.floor(msec / 60000);
  }

  /**
   * Returs the total time
   *
   * @param startTime
   * @param endTime
   */
  calculateTimeInHours(startTime: Date, endTime: Date): string {
    const duration = endTime.valueOf() - startTime.valueOf();
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const hh = hours < 10 ? `0${hours}` : hours;
    const min = minutes < 10 ? `0${minutes}` : minutes;
    return `${hh}:${min}`;
  }

  itemMenuRendered(args: ItemRenderedEvent<ITopAction>) {
    const action = args.itemData.action?.action;
    if (args.itemData.action) {
      const container = args.itemElement.closest('.dx-menu-items-container');
      container.addEventListener('wheel', function (e) {
        e.stopPropagation();
      });
      container.classList.add('workflow-actions-container');
    }
    if (action && !action.isUsable) {
      args.itemElement.parentElement.classList.add('agilicision-workflow-action-disabled');
    }
    if (args.itemData.showSubMenu) {
      // re-open an item
      args.itemData.showSubMenu = false;
      args.itemElement.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
    }
  }
  /**
   * wird getriggert, wenn eine AgendaItem-Aktion in der UI aufgerufen wurde
   *
   * @param action: die ausgelöste Aktion
   */
  selectAgendaItemAction = (event: ItemClickEventMenu, item: TreeViewItem): void => {
    const action = event.itemData as ITopAction;
    this.selectedTOP = item;
    this.selectedTOPAction = action.value;
    if (!action || !this.selectedTOP) {
      return;
    }
    const documentId = this.selectedTOP.id;

    switch (action.value) {
      case 'execute-action':
        {
          this.selectedWorkflowActionDocument = this.selectedTOP?.document;
          this.selectedWorkflowAction = action.action;
        }
        break;
      case 'base-action':
        this.handleWorkflowActions(event, item);
        break;
      case 'wf-action':
        {
          this.selectedWorkflowActionDocument = this.selectedTOP?.document;
        }
        break;
      case 'add-submission':
        {
          this.onAddSubmissions();
        }
        break;
      case 'add-subtop':
        {
          const depth = this.getLevel(this.agendaItems, this.selectedTOP?.id, null);
          if (depth >= this.maxDepth) {
            NotifyService.global.warn(`Maximale Tiefe erreicht = ${depth}`, 5000);
          } else {
            this.onCreateSubItem(this.selectedTOP?.id);
          }
        }
        break;

      case 'protocol': {
        const hasProtocol = this.selectedTOP.protocol;
        if (hasProtocol) {
          this.onOpenItem(hasProtocol);
        } else {
          this.documentService
            .getDocumentById(documentId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: document => {
                this.documentEditorComponent.createDocument(
                  ['app:document-type:protocol'],
                  document,
                  this.templateProtocolId
                );
              }
            });
        }
        break;
      }
      case 'reactivate': {
        this.reactivateAgendaItem(this.selectedTOP);
        break;
      }
      case 'delete': {
        this.removeAgendaItem(this.selectedTOP);
        break;
      }
      default: {
        NotifyService.global.warn(`unbekannte Aktion: ${action.value}`, 5000);
      }
    }
  };
  onOpenTask = (event: ItemClickEventDropDown): void => {
    const item = event?.itemData as TreeViewItem;
    if (!item?.document) {
      return;
    }
    this.documentService
      .getDocumentById(item.document.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (aiDocument): void => {
          if (!aiDocument) {
            NotifyService.global.warn('Kein Dokument gefunden.', 5000);
            return;
          }
          const iType = this.documentService.getType(aiDocument);
          if (!iType) {
            NotifyService.global.warn(
              'Der Typ des Agendapunktes konnte nicht ermittelt werden. Ggf. arbeitest Du mit veralteten Vorlagen?',
              5000
            );
          }

          const settings: PopupSettings = {};
          settings.title = `${this.documentService.getTypeLabel(aiDocument)} : ${
            (aiDocument.fields.subject?.value as string) || ''
          }`;

          // Setzen der PopupAktionen je nach dem, welcher TOP-Typ (TOP oder Vorlage) geöffnet werden soll
          if (!this.isCanUpdate) {
            settings.editMode = 'ReadOnly';
          } else {
            settings.editMode = 'EditPreview';
          }

          // EditorKomponente für angegebenes Dokumentout und den definierten Settings öffnen
          void this.documentEditorComponent.show(aiDocument, settings);
        },
        error: (error: string): void => {
          NotifyService.global.error(error);
        }
      });
  };
  /**
   * wird getriggert, wenn eine AgendaItem-Aktion in der UI aufgerufen wurde
   *
   * @param action: die ausgelöste Aktion
   */
  selectAgendaItemSubmissionAction = (
    event: ItemClickEventMenu,
    parentSubmissionItem: TreeViewItem,
    submission: TreeViewItem = null
  ): void => {
    const action = event.itemData as ITopAction;

    this.selectedTOP = submission;
    this.selectedTOPAction = action.value;
    if (!action || !this.selectedTOP) {
      return;
    }
    const documentId = this.selectedTOP.id;

    switch (action.value) {
      case 'execute-action':
        {
          this.selectedWorkflowActionDocument = this.selectedTOP?.document;
          this.selectedWorkflowAction = action.action;
        }
        break;
      case 'base-action':
        this.handleWorkflowActions(event, submission);

        break;
      case 'wf-action':
        {
          this.selectedWorkflowActionDocument = this.selectedTOP?.document;
        }
        break;
      case 'task': {
        this.documentService
          .getDocumentById(documentId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: document => {
              this.documentEditorComponent.createDocument(['app:document-type:task'], document, this.templateTaskId);
            }
          });
        break;
      }
      case 'delete': {
        this.removeSubmissionItem(this.selectedTOP);
        break;
      }
      default: {
        NotifyService.global.warn(`unbekannte Aktion: ${action.value}`, 5000);
      }
    }
  };

  reactivateAgendaItem(aItem: TreeViewItem): void {
    const topIndex = this.agendaItems.findIndex(item => item.id === aItem.id);
    if (topIndex > -1) {
      this.agendaItems[topIndex].isActive = true;
      this.agendaItems[topIndex].itemTOPAction = this.getItemBaseActions();
      this.changeActivationItems(this.agendaItems, aItem.id, true);
      this.updateAgendaTime();
      this.updateFilteredTreeViewItems();
    }
  }
  /**
   * delete the agendlist by index number
   *
   * @param event
   * @param aItem
   */
  removeSubmissionItem(aItem: TreeViewItem): void {
    if (!aItem.document) {
      const messageDialog =
        'das Dokument der Vorlage könnte nicht gefunden werden möchten das aus der Sitzung entfernen? <br>';
      void confirm(messageDialog, 'Vorlage löschen').then(dialogResult => {
        if (dialogResult) {
          this.removeEntry(aItem.id, aItem.type, aItem.parentId);
          this.updateAgendaTime();
          this.updateFilteredTreeViewItems();
          NotifyService.global.success('Vorlage wurde entfernt.');
        }
      });
      return;
    }
    const tasks = aItem.tasks;
    const message = 'Möchten Sie diese Vorlage entfernen?';
    if (tasks?.length) {
      void confirm(message, 'Vorlage entfernen').then(dialogResult => {
        if (dialogResult) {
          this.removeReferencesItemsRecursive([aItem])
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: () => {
                this.deleteItemsRecursive([...tasks]);
                this.updateAgendaTime();
                this.updateFilteredTreeViewItems();
              },
              error: NotifyService.component.error
            });
        }
      });
    } else {
      void confirm(message, 'Vorlage entfernen').then(dialogResult => {
        if (dialogResult) {
          this.removeReferencesItemsRecursive([aItem])
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: () => {
                this.updateAgendaTime();
                this.updateFilteredTreeViewItems();
              },
              error: NotifyService.component.error
            });
        }
      });
    }
  }
  /**
   * delete the agendlist by index number
   *
   * @param event
   * @param aItem
   */
  removeAgendaItem(aItem: TreeViewItem): void {
    const deleteChildren = (itemsToRemove: TreeViewItem[], itemsToDelete: TreeViewItem[]): void => {
      this.loadPanelService.show();
      this.removeReferencesItemsRecursive([...itemsToRemove])
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.deleteItemsRecursive([...itemsToDelete, aItem]);
            this.updateAgendaTime();
            this.updateFilteredTreeViewItems();
            this.loadPanelService.hide();
          },
          error: () => {
            NotifyService.component.error;
            this.loadPanelService.hide();
          }
        });
    };
    if (!aItem.isValid) {
      const messageDialog =
        'das Dokument des TOPs könnte nicht gefunden werden möchten das aus der Sitzung entfernen? <br>';
      void confirm(messageDialog, 'TOP löschen').then(dialogResult => {
        if (dialogResult) {
          const children = this.getChildren(aItem.id);
          if (children.length) {
            // die zu dereferenzende Items
            const itemsToRemove = children.filter(child => child.type === 'submission');
            // die Items die gelöschen können
            const itemsToDelete = children.filter(
              child => child.type === 'task' || child.type === 'pause' || child.type === 'top'
            );
            deleteChildren(itemsToRemove, itemsToDelete);
          } else {
            this.removeEntry(aItem.id, aItem.type, aItem.parentId);
          }
          this.updateAgendaTime();
          this.updateFilteredTreeViewItems();
          NotifyService.global.success('TOP wurde entfernt.');
        }
      });
      return;
    }
    // wenn das TOP ist aktiv dann kann man nur steichen
    if (aItem.isActive && aItem.type !== 'pause') {
      void confirm('Möchten Sie diesen  TOP wirklich streichen?', 'TOP streichen').then(dialogResult => {
        if (dialogResult) {
          const topIndex = this.agendaItems.findIndex(item => item.id === aItem.id);
          if (topIndex > -1) {
            this.agendaItems[topIndex].isActive = false;
            this.agendaItems[topIndex].duration = 0;
            this.agendaItems[topIndex].itemTOPAction = this.getItemBaseActions();
            this.changeActivationItems(this.agendaItems, aItem.id, false);
            this.agendaItems = this.mapAgendaItems(this.agendaItems);
            this.updateAgendaTime();
            this.updateFilteredTreeViewItems();
          }
        }
      });
    } else {
      if (!aItem.document) {
        const messageDialog =
          'das Dokument des TOPs könnte nicht gefunden werden möchten das aus der Sitzung entfernen? <br>';
        void confirm(messageDialog, 'TOP löschen').then(dialogResult => {
          if (dialogResult) {
            this.removeEntry(aItem.id, aItem.type, aItem.parentId);
            this.updateAgendaTime();
            this.updateFilteredTreeViewItems();
            NotifyService.global.success('TOP wurde entfernt.');
          }
        });
        return;
      }
      // wenn das TOP hat einen Protokoll oder Beschluss dann darf man nicht löschen
      if (aItem.protocol) {
        NotifyService.global.error('Bitte löschen Sie zuerst die untergeordneten Beschlusse bzw. Protokolle');
        return;
      }
      // alle unterelemente holen
      const children = this.getChildren(aItem.id);
      let message = 'Möchten Sie diesen TOP wirklich löschen? <br>';
      if (children.length) {
        message = message.concat(
          '<br> <br> Beim Bestätigen werden die zugeordneten TOPs und Aufgaben ebenfalls gelöscht, die Vorlagen werden entfernt.'
        );
        // die zu dereferenzende Items
        const itemsToRemove = children.filter(child => child.type === 'submission');
        // die Items die gelöschen können
        const itemsToDelete = children.filter(
          child => child.type === 'task' || child.type === 'pause' || child.type === 'top'
        );

        const title = 'TOP löschen';
        const dialog = custom({
          title: title,
          messageHtml: message,
          buttons: [
            {
              text: `Löschen`,
              icon: 'check',
              onClick: () => deleteChildren(itemsToRemove, itemsToDelete)
            },
            {
              text: 'Abbrechen',
              icon: 'clear',
              onClick: () => false
            }
          ]
        }) as IConfirmDialog;
        void dialog.show();
      } else {
        const closeSubject$ = new Subject<boolean>();
        const deleteTop = (closeSubject$: Subject<boolean>) => {
          this.loadPanelService.show();
          this.documentService
            .delete(aItem.document, false, true)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: () => {
                this.removeEntry(aItem.id, aItem.type, aItem.parentId);
                this.updateAgendaTime();
                this.updateFilteredTreeViewItems();
                NotifyService.global.success('TOP wurde geparkt.');
                resolveSubject<boolean>(true, closeSubject$);
                this.loadPanelService.hide();
              },
              error: () => {
                NotifyService.component.error;
                resolveSubject<boolean>(false, closeSubject$);
                this.loadPanelService.hide();
              }
            });
        };
        const parkTop = (closeSubject$: Subject<boolean>) => {
          this.loadPanelService.show();
          this.documentService
            .park(aItem.document, false, true)
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: () => {
                this.removeEntry(aItem.id, aItem.type, aItem.parentId);
                this.updateAgendaTime();
                this.updateFilteredTreeViewItems();
                NotifyService.global.success('TOP wurde geparkt.');
                resolveSubject<boolean>(true, closeSubject$);
                this.loadPanelService.hide();
              },
              error: () => {
                NotifyService.component.error;
                resolveSubject<boolean>(false, closeSubject$);
                this.loadPanelService.hide();
              }
            });
        };
        const deleteDialog = deleteTOPDialog(
          'TOP löschen',
          'Möchten Sie diesen Tagesordnungspunkt endgültig löschen, oder für eine spätere Verwendung parken? <br> (Der TOP kann später über die Aktion "Freien TOP hinzufügen" erneut einer Tagesordnung zugeordnet werden)',
          closeSubject$,
          (subject$: Subject<boolean>) => {
            void deleteTop(subject$);
          },
          (subject$: Subject<boolean>) => {
            void parkTop(subject$);
          }
        );
        deleteDialog.show();
      }
      return;
    }
  }

  /**
   * check if an Item has Child from input type
   * @param item
   * @param type
   * @returns
   */
  hasChildDocument = (item: TreeViewItem, type: TAgendaItemType): boolean => {
    const find = this.agendaItems.find(elem => elem.type === type && elem.parentId === item.id);
    return !!find;
  };
  /**
   * check if an Item has decision from input type
   * @param item
   * @param type
   * @returns
   */
  hasDecision = (item: TreeViewItem): boolean => {
    return !!this.getDecisionDocument(item);
  };

  /**
   * check if an Item has decision from input type
   * @param item
   * @param type
   * @returns
   */
  getDecisionDocument = (item: TreeViewItem): IDocument => {
    const decisionValue = item.document?.fields[siamConst.decisionField]?.value as FieldDecisionsValue;
    if (!decisionValue) {
      return null;
    }
    const agendaId = this.getAgendaId();
    for (const key of Object.keys(decisionValue)) {
      const value = decisionValue[key];
      if (value?.fields[siamConst.agendaFieldId]?.value === agendaId) {
        const value = decisionValue[key];
        const document = copy(_defaultDecisionDocument);
        document.templateId = _defaultDecisionId;
        Object.keys(document.fields).forEach(field => {
          document.fields[field].value = value.fields[field].value as string;
        });
        document.fields['--approval_number'].value = value.number;

        return document;
      }
    }

    return null;
  };

  getAgendaId = (): string => {
    if (this.documentService.isAgenda(this.agendaDocument) && this.agendaDocument?.id) {
      return this.agendaDocument.id;
    }
    if (this.documentService.isMeetingMinutes(this.agendaDocument)) {
      if (!this.agendaDocument?.id) {
        return this.agendaDocument.fields[siamConst.agendaDocumentId]?.value as string;
      } else {
        const parents = this.agendaDocument.references
          .filter(p => p.tags.includes(siamConst.parentTag) && p.document)
          .map(ref => ref.document);
        if (parents?.length) {
          return parents[0].id;
        }
        return null;
      }
    }
    return null;
  };
  getDecisionNumberValue = (item: TreeViewItem): string => {
    const decisionDocument = this.getDecisionDocument(item);
    if (decisionDocument) {
      return decisionDocument.fields['--approval_number'].value as string;
    }
    return null;
  };

  /**
   * count how much child from input type
   * @param item
   * @param type
   * @returns
   */
  countChildDocument = (item: TreeViewItem, type: TAgendaItemType): number => {
    const countDocuments = this.agendaItems.filter(elem => elem.type === type && elem.parentId === item.id);
    return countDocuments?.length;
  };
  /**
   * get children from input type
   * @param item
   * @param type
   * @returns
   */

  getChildrenDocuments = (item: TreeViewItem, type: TAgendaItemType): IDocument[] => {
    const documents = this.agendaItems
      .filter(elem => elem.type === type && elem.parentId === item.id)
      .map(item => item.document);
    return documents;
  };
  getChildDocument = (item: TreeViewItem, type: TAgendaItemType): IDocument => {
    const documents = this.agendaItems
      .filter(elem => elem.type === type && elem.parentId === item.id)
      .map(item => item.document);
    if (!documents?.length) {
      return null;
    }
    return documents[0];
  };

  getAdditionalSettingsTask = (item: TreeViewItem, type: TAgendaItemType): IDynamicGridSettings => {
    return {
      includeDocumentIds: this.getChildrenDocuments(item, type)?.map(d => d.id),
      excludeTags: [],
      excludeStatus: [],
      toolbar: {
        dateField: null,
        userField: null,
        isShowSearch: true,
        isShowDateFilter: false,
        isShowExportExcel: false,
        isShowGrouping: false,
        isShowPersonalMode: false,
        isShowPreviewSwitch: false,
        rowAlternationEnabled: true,
        isShowKanban: true,
        isShowTitle: false
      }
    };
  };

  getColumnFeldConfig = (item: TreeViewItem, cell: ColumnCellTemplateData<ITopGridSettingsColumn>): FieldTypes => {
    if (item?.document && cell) {
      const parts = cell.column.dataField.split('.');
      const fieldName = parts[2];

      const templateCient = templateFactory.mapServerToClientDoc(item.document.template);
      const field = templateCient.fields.find(f => f.name === fieldName);
      return field;
    }
    return null;
  };

  getFieldSpeakers = (): PermissionTarget =>
    new PermissionTarget({
      name: 'speakers',
      dataField: 'speakers',
      editorOptions: { dataSource: this.usersDataSource, displayExpr: 'name', valueExpr: 'compositeId' }
    });

  /**
   * get field value of cell column
   * @param item
   * @param type
   * @returns
   */
  isHasAttachment = (
    item: TreeViewItem,
    cell: ColumnCellTemplateData<ITopSubmissionGridSettingsColumn, ITopSubmissionGridSettingsColumn>
  ): boolean => {
    if (
      !(cell?.column as ITopSubmissionGridSettingsColumn).showAttachment ||
      !(cell?.column as ITopSubmissionGridSettingsColumn).attachmentField
    ) {
      return false;
    }
    const documentFields = item.document.fields;
    const fieldName = this.extractFieldName((cell?.column as ITopSubmissionGridSettingsColumn).attachmentField);
    const value = documentFields[fieldName]?.value as unknown[];
    if (value?.length) {
      return true;
    }
    return false;
  };
  /**
   * get field value of cell column
   * @param item
   * @param type
   * @returns
   */
  getFieldValue = (item: TreeViewItem, cell: ColumnCellTemplateData<ITopGridSettingsColumn>): unknown => {
    if (item?.document && cell) {
      const parts = cell.column.dataField.split('.');
      let temporaryValue: unknown = item;
      for (const part of parts) {
        if (isHasProperty(temporaryValue, part)) {
          temporaryValue = (temporaryValue as Record<string, unknown>)[part];
        } else {
          temporaryValue = '';
          break;
        }
      }
      if (temporaryValue) {
        switch (cell.column.dataType) {
          case 'datetime':
            return new Date(temporaryValue as string);

          case 'number':
            return Number(temporaryValue as string);

          case 'boolean':
            return Boolean(temporaryValue as string);

          default:
            return temporaryValue;
        }
      }
      return '';
    }
    return '';
  };
  /**
   * get field value of cell column
   * @param item
   * @param type
   * @returns
   */
  getFieldValueFromDocument = (document: IDocument, field: string): unknown => {
    if (document && field) {
      if (field === 'template.caption') {
        return document.template.caption;
      }
      if (document.fields[field]) {
        return document.fields[field].value;
      }
    }
    return null;
  };

  /**
   * check if an item has child or not
   *
   * @param item
   * @returns
   */
  itemHasChild = (item: TreeViewItem): boolean => {
    return !!this.agendaItems.find(el => el.parentId === item.id && (el.type === 'top' || el.type === 'pause'));
  };

  /**
   * get workflow status to set it in cell-column
   *
   * @param item
   * @returns
   */
  getWorkflowStatus = (item: TreeViewItem): string => {
    if (!item?.document) {
      return '';
    }
    const workflowDocument = item.document.workflowDocuments.find(
      x => !x.tags.length || x.tags.includes('app:document-type:document-workflow')
    );

    return this.documentService.getWorkflowStateLabel(workflowDocument);
  };
  /**
   * get workflow status to set it in cell-column
   *
   * @param item
   * @returns
   */
  getWorkflowStatusColor = (item: TreeViewItem): string => {
    if (!item?.document) {
      return '';
    }
    const workflowDocument = item.document.workflowDocuments.find(
      x => !x.tags.length || x.tags.includes('app:document-type:document-workflow')
    );

    return this.documentService.getWorkflowStateColor(workflowDocument);
  };

  /**
   * update filtered Items to show only items from type 'top', 'submission', or 'pause'
   */

  updateFilteredTreeViewItems = (): void => {
    this.treelistItemsFiltered = new DataSource({
      store: this.agendaItems,
      sort: (e: TreeViewItem): unknown => {
        if (e.type !== 'top-protocol' && this.isMeetingMinutes) {
          return '!';
        }

        return e;
      }
    });
    if (this.isMeetingMinutes) {
      this.treelistItemsFiltered.filter([
        ['type', '=', 'top'],
        'or',
        ['type', '=', 'top-submissions'],
        'or',
        ['type', '=', 'top-protocol']
      ]);
    } else {
      this.treelistItemsFiltered.filter([
        ['type', '=', 'top'],
        'or',
        ['type', '=', 'pause'],
        'or',
        ['type', '=', 'top-submissions']
      ]);
    }
    void this.treelistItemsFiltered.load();
  };

  onRowClickDatagrid = (event: CellClickEvent<TreeViewItem>): void => {
    if (event.columnIndex === 0 || !this.isCanUpdate || this.isMeetingMinutes) {
      this.onOpenItem(event.data);
    }
  };
  onItemRendered = (event: ItemRenderedEvent<TreeViewItem>): void => {
    if (event?.itemData?.type === 'top-submissions') {
      event.itemElement.parentElement.classList.add('item-top-children');
    }
    if (event?.itemData?.type === 'top' || event?.itemData?.type === 'pause') {
      event.itemElement.parentElement.classList.add('item-top');
    }
  };
  /**
   * TreeView content ready to add class to submission datatgrid
   * @param event
   */
  onContentReadyTreeview = (event: ContentReadyEventTreeView): void => {
    const submissionItems = Array.from(event.element.querySelectorAll('.item-submission'));
    for (const submissionItem of submissionItems) {
      const parent = submissionItem.closest('.dx-treeview-node');
      parent.classList.add('submission-item-node');
    }
  };

  onFieldValueChanged = (event: unknown, cellInfo: ColumnEditCellTemplateData): void => {
    if (event && cellInfo) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      cellInfo.setValue(event as string);
      cellInfo.component?.updateDimensions();
    }
  };

  onNextTimer = (): void => {
    this.timerVisible = false;
    const currentIndex = this.agendaItems.findIndex(item => item === this.currentItem);
    if (currentIndex < this.agendaItems.length - 1) {
      this.currentTimeObservable$.unsubscribe();
      this.startAgendaTimeCountDown();
    } else {
      this.currentItem = null;
      this.timerVisible = false;
      this.currentTimeObservable$?.unsubscribe();
    }
  };

  protected removeEntry(itemId: string, type: TAgendaItemType, parentId: string): void {
    const plainArray = convertToPlainArray(copy(this.agendaItems));
    const index = plainArray.findIndex(item => item.id === itemId);
    if (index > -1) {
      plainArray.splice(index, 1);
      plainArray.forEach(item => {
        if (item.parentId === itemId) {
          item.parentId = parentId;
        }
      });
    }

    this.agendaItems = this.mapAgendaItems(plainArray);
    this.updateFilteredTreeViewItems();
  }

  protected updateEntry(document: IDocument): void {
    if (!document) {
      NotifyService.global.warn('TOP könnte nicht geändert werden!', 5000);
      return;
    }
    const plainArray = convertToPlainArray(this.agendaItems);
    const findIndex = plainArray.findIndex(item => item.id === document?.id);
    if (findIndex > -1) {
      plainArray[findIndex].document = document;
      plainArray[findIndex].originalDocument = copy(document);
      this.agendaItems = this.mapAgendaItems(plainArray);
    }
  }
  protected addEntry(document: IDocument, type: TAgendaItemType, parentId: string = null): void {
    if (!document && !type) {
      NotifyService.global.warn('TOP könnte nicht hinzufügt werden!', 5000);
      return;
    }
    const position = this.agendaItems.filter(item => !item.parentId)?.length + 1;
    const itemToAdd = {
      document: document,
      originalDocument: copy(document),
      id: document.id,
      parentId,
      type,
      isActive: true,
      isValid: true,
      isDirectory: false
    } as TreeViewItem;
    switch (type) {
      case 'top':
        itemToAdd.isDirectory = true;
        itemToAdd.position = position.toString();
        itemToAdd.duration = this.topDurationDefaultValue;
        itemToAdd.itemTOPAction = this.getItemBaseActions();
        break;
      case 'submission':
        itemToAdd.itemSubmissionAction = this.getItemBaseActions();
        break;
      case 'pause':
        itemToAdd.duration = this.topDurationDefaultValue;
        break;
      case 'top-protocol':
        {
          const parentItemIndex = this.agendaItems.findIndex(item => item.id === parentId);
          if (parentItemIndex > -1) {
            this.agendaItems[parentItemIndex].itemTOPAction = this.getItemBaseActions();
          }
        }
        itemToAdd.duration = this.topDurationDefaultValue;
        break;

      default:
        break;
    }

    const plainArray = convertToPlainArray(copy(this.agendaItems));
    plainArray.push(itemToAdd);
    this.agendaItems = this.mapAgendaItems(plainArray);
    void this.updateAgendaTime();
    this.updateFilteredTreeViewItems();
  }

  private setColumns(): void {
    const topColumns: Column[] = [];
    const breakColumns: Column[] = [];
    if (Array.isArray(this.topColumnsConfig)) {
      for (const column of this.topColumnsConfig) {
        const gridColumn = this.getColumnOptionsTOPs(column);
        const gridBreakColumn = this.getColumnOptionsTOPs(column, true);

        topColumns.push(gridColumn);
        breakColumns.push(gridBreakColumn);
      }
    }
    this.topColumns = topColumns;
    this.breakColumns = breakColumns;
  }

  private getColumnOptionsTOPs(column: ITopGridSettingsColumn, isBreak = false): ITopGridSettingsColumn {
    const width = column.isAutoWidth ? 'auto' : column.width || null;
    let allowEditing = false;
    switch (column.columnType) {
      case 'index': {
        return this.setProperties(column, {
          cellTemplate: 'indexTemplate',
          width,
          allowEditing
        });
      }
      case 'action': {
        return this.setProperties(column, {
          cellTemplate: 'actionTemplate',
          width: '60',
          allowEditing
        });
      }
      case 'workflow-status': {
        return this.setProperties(column, {
          cellTemplate: 'WorkflowStatusTemplate',
          width,
          allowEditing
        });
      }
      case 'protocol': {
        return this.setProperties(column, {
          cellTemplate: 'protocolTemplate',
          headerCellTemplate: 'headerActionTemplate',
          width: '50',
          allowEditing
        });
      }
      case 'speakers': {
        return this.setProperties(column, {
          cellTemplate: 'speakersTemplate',
          editCellTemplate: 'speakersTemplateEditor',
          allowEditing: !isBreak,
          dataField: 'speakers',
          minWidth: 400,
          width
        });
      }

      case 'duration': {
        this.topDurationDefaultValue = Number.isInteger(column.durationOptions.default)
          ? column.durationOptions.default
          : 15;
        this.topDurationStep = column.durationOptions.step || 5;
        return this.setProperties(column, {
          cellTemplate: 'durationTemplate',
          allowEditing,
          minWidth: 300,
          width
        });
      }
      default: {
        const fieldName = this.extractFieldName(column.dataField);
        const dataType = (column.dataType as TTopGridColumnDataType) || 'string';
        let dataField = `document.${column.dataField}`;

        if (fieldName) {
          dataField += '.value';
        }
        allowEditing = isBreak && column.columnType !== 'subject' ? false : true;
        const options = this.setProperties(column, {
          width: ' ',
          minWidth: 200,
          dataField,
          dataType,
          encodeHtml: false,
          cellTemplate: column.columnType === 'subject' ? 'subjectValueTemplate' : 'fieldValueTemplate',
          editCellTemplate: 'fieldValueTemplateEditor',
          allowEditing
        });

        return options;
      }
    }
  }

  private setProperties = (column: ITopGridSettingsColumn, addons: Record<string, unknown>): ITopGridSettingsColumn => {
    const properties: ITopGridSettingsColumn = {
      caption: column.caption,
      allowHiding: !!column.allowHiding,
      allowResizing: !!column.allowResizing,
      allowHeaderFiltering: !!column.allowHeaderFiltering,
      allowSorting: !!column.allowSorting,
      visible: true
    };

    return { ...column, ...properties, ...addons };
  };

  private extractFieldName = (fieldName: string): string => {
    if (fieldName?.includes('document.fields.')) {
      return fieldName.split('.')[2];
    }
    if (fieldName?.includes('fields.')) {
      return fieldName.split('.')[1];
    }
    return null;
  };

  private fetchUsersAndRoles(): Observable<void> {
    return this.userService.getAllUsers().pipe(
      switchMap(entries => {
        const usersDataSource = entries.sort((a, b) =>
          a.selectorName.toLowerCase() > b.selectorName.toLowerCase()
            ? 1
            : b.selectorName.toLowerCase() > a.selectorName.toLowerCase()
            ? -1
            : 0
        );
        this.usersDataSource = usersDataSource.map(
          user =>
            ({
              id: user.id,
              compositeId: user.compositeId,
              type: 'user',
              name: user.displayName,
              profile: user.profile,
              roles: user.roles,
              userName: user.name,
              selectorName: user.selectorName,
              visible: !user.isArchived
            } as IUserRoleEntry)
        );
        this.users = new Map();
        entries.forEach(entry => {
          this.users.set(entry.id, entry);
        });

        return this.roleService.getAllRoles();
      }),
      switchMap(entries => {
        this.roles = new Map();
        entries.forEach(entry => {
          this.roles.set(entry.id, entry);
        });

        return of(null);
      })
    );
  }

  private fetchAgendaStartDate(): void {
    const startDate = this.formData?.startdate;
    this.agendaStartDate = startDate ? new Date(startDate as string) : new Date();
  }

  private convertToHierarchy(flatArray: TreeViewItem[]): TreeViewItem[] {
    const map: Record<string, TreeViewItem> = {};
    const rootItems: TreeViewItem[] = [];

    // Create a mapping of items by their id
    flatArray.forEach(item => {
      map[item.id] = { ...item, children: [], submissions: item.submissions || [] };
    });

    // Populate the children array of each item based on parentId
    const convert = (items: TreeViewItem[]): void => {
      items.forEach(item => {
        if (item.parentId === null) {
          rootItems.push(map[item.id]);
        } else if (map[item.parentId]) {
          const parent = map[item.parentId];
          switch (item.type) {
            case 'submission':
              if (!parent.submissions) parent.submissions = [];
              parent.submissions.push(map[item.id]);
              break;
            case 'top-protocol':
              parent.protocol = map[item.id];
              break;
            case 'task':
              if (!parent.tasks) parent.tasks = [];
              parent.tasks.push(map[item.id]);
              break;
            case 'pause':
            case 'top':
              parent.children.push(map[item.id]);

              break;
            default:
              break;
          }
        }
      });
    };
    convert(flatArray);
    return rootItems;
  }

  private flattenHierarchy(hierarchicalArray: TreeViewItem[], parentIndex = ''): TreeViewItem[] {
    const flatArray: TreeViewItem[] = [];
    let childIndex = 1;
    hierarchicalArray.forEach(item => {
      const position = parentIndex ? `${parentIndex}.${childIndex}` : `${childIndex}`;
      if (item.type === 'top') {
        childIndex++;
      }

      const flattenedItem = {
        ...item,
        position,
        children: [] as TreeViewItem[]
      };

      flatArray.push(flattenedItem);

      if (item.children && item.children.length > 0) {
        const childrenFlatArray = this.flattenHierarchy(item.children, position);
        flatArray.push(...childrenFlatArray);
      }
      if (item.protocol && this.isMeetingMinutes) {
        const protocolItemExisit = flatArray.find(elem => elem.id === item.protocol.id);
        if (!protocolItemExisit) {
          flatArray.push(item.protocol);
        }
      }
    });

    const addSubbmissionItems = (items: TreeViewItem[]): TreeViewItem[] => {
      const result = items;
      items.forEach(item => {
        const submissionItemExisit = result.find(elem => elem.id === `submissions-${item.id}`);
        if (item.type === 'top' && item.submissions?.length && !submissionItemExisit) {
          const itemToAdd = {
            id: `submissions-${item.id}`,
            parentId: item.id,
            isActive: item.isActive,
            isValid: item.isValid,
            isDirectory: false,
            type: 'top-submissions',
            submissionsItems: this.splitArrayIntoChunks(item.submissions, this.displayColumns)
          } as TreeViewItem;
          result.push(itemToAdd);
        }
      });

      return result;
    };
    return addSubbmissionItems(flatArray);
  }

  private updateSubmissions(items: TreeViewItem[]): TreeViewItem[] {
    const result = items;
    items.forEach(item => {
      if (item.type === 'top-submissions') {
        const parentId = item.id.split('submissions-')[1];
        const parentItemIndex = result.findIndex(p => p.id === parentId);
        const itemIndex = result.findIndex(p => p.id === item.id);
        if (item.submissionsItems?.length) {
          result[parentItemIndex].submissions = item.submissionsItems.flat();
        } else {
          result[parentItemIndex].submissions = [];
          result.splice(itemIndex, 1);
          this.updateFilteredTreeViewItems();
        }
      }
    });

    return result;
  }
  private updateItemSubmissions(submissions: TreeViewItem[], parentId: string): void {
    const findItemIndexById = (id: string): number => {
      return this.agendaItems.findIndex(item => item.id === id);
    };
    const parentItemIndex = findItemIndexById(parentId);
    const submissionItemIndex = findItemIndexById(`submissions-${parentId}`);
    if (parentItemIndex < 0) {
      return;
    }
    this.agendaItems[parentItemIndex].submissions = submissions;
    if (submissions?.length) {
      const itemToAdd = {
        id: `submissions-${parentId}`,
        parentId: parentId,
        isDirectory: false,
        isActive: true,
        type: 'top-submissions',
        submissionsItems: this.splitArrayIntoChunks(submissions, this.displayColumns)
      } as TreeViewItem;
      if (submissionItemIndex < 0) {
        this.agendaItems.push(itemToAdd);
      } else {
        this.agendaItems[submissionItemIndex].submissionsItems = this.splitArrayIntoChunks(
          submissions,
          this.displayColumns
        );
      }
    } else {
      if (submissionItemIndex > -1) {
        this.agendaItems.splice(submissionItemIndex, 1);
      }
    }
  }
  private mapAgendaItems(inputArray: TreeViewItem[]): TreeViewItem[] {
    const hierarchicalArray = this.convertToHierarchy(inputArray);
    return this.flattenHierarchy(hierarchicalArray);
  }

  private getItemBaseActions = (): ITopAction[] => {
    return [{ text: '', icon: 'material-icons more_vert', value: 'base-action', items: [] }];
  };
  private getItemTopActions = (top: TreeViewItem): ITopAction[] => {
    let result: ITopAction[] = [];
    if (isHasProperty(top, 'isValid') && !top.isValid) {
      result.push({ text: 'Löschen', value: 'delete', icon: 'trash' });
      return result;
    }
    if (isHasProperty(top, 'isActive') && !top.isActive) {
      result = [
        { text: 'reaktivieren', value: 'reactivate', icon: 'revert' },
        { text: 'Löschen', value: 'delete', icon: 'trash' }
      ];
      return result;
    } else {
      const plainArray = convertToPlainArray(copy(this.agendaItems));
      const hasProtocol = plainArray.find(elem => elem.type === 'top-protocol' && elem.parentId === top.id);
      result = [
        {
          text: 'Inhalt hinzufügen',
          value: 'add-submission',
          icon: 'plus'
        },
        {
          text: hasProtocol ? 'Protokoll bearbeiten' : 'Protokoll erstellen',
          value: 'protocol',
          icon: 'edit'
        },
        { text: 'Workflow...', value: 'wf-action', icon: 'material-icons forward' },
        { text: 'TOP sctreichen', value: 'delete', icon: 'strike' }
      ];
      const hasSubmissions = plainArray.find(elem => elem.type === 'submission' && elem.parentId === top.id);
      if (!hasSubmissions) {
        const item = {
          text: 'Unter-TOP anlegen',
          value: 'add-subtop',
          icon: 'plus'
        } as ITopAction;
        result.splice(1, 0, item);
      }
      return result;
    }
  };

  private getItemSubmissionActions = (): ITopAction[] => {
    return [
      { text: 'Aufgabe erstellen', disabled: false, value: 'task', icon: 'material-icons alarm_on' },
      { text: 'Workflow...', value: 'wf-action', icon: 'material-icons forward' },
      { text: 'Löschen', value: 'delete', icon: 'trash' }
    ];
  };

  // calculate Level depth based on from and toItem
  private getLevel(items: TreeViewItem[], toItemId: string, fromItemId: string): number {
    const item = items.find(item => item.id === toItemId);
    if (!item) {
      return -1;
    }

    return this.calculateLevel(items, item) + this.getChildrenDepth(items, fromItemId);
  }

  /**
   * Recursive function to calculate level
   * @param items
   * @param item
   * @param level
   * @returns
   */
  private calculateLevel(items: TreeViewItem[], item: TreeViewItem, level = 1): number {
    if (item.parentId === null) {
      return level;
    }

    const parentItem = items.find(i => i.id === item.parentId);
    if (!parentItem) {
      return -1;
    }

    return this.calculateLevel(items, parentItem, level + 1);
  }

  /**
   * Recursive function to calculate childrenDepth
   * @param items
   * @param itemId
   * @returns
   */
  private getChildrenDepth(items: TreeViewItem[], itemId: string): number {
    const item = items.find(item => item.id === itemId);
    if (!item) {
      return 0;
    }

    const children = items.filter(child => child.parentId === item.id && child.type === 'top');

    if (children.length === 0) {
      return 0;
    }

    let maxChildDepth = 0;

    for (const child of children) {
      const childDepth = this.getChildrenDepth(items, child.id);
      if (childDepth > maxChildDepth) {
        maxChildDepth = childDepth;
      }
    }

    return maxChildDepth + 1; // Add 1 for the current depth
  }

  private documentHasPermission = (document: IDocument, permission: TEffectivePermission): boolean => {
    if (!document) {
      return false;
    }
    return document.effectivePermissions?.includes(permission);
  };

  private resetLoading(): void {
    this.loadPanelService.hide();
  }

  private addTogetherSubmissions(submissionIds: string[], parentTopDocument: IDocument): void {
    this.documentService
      .getDocumentsByIds(submissionIds)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: documents => {
          this.addEntry(parentTopDocument, 'top');
          for (const submission of documents) {
            this.addEntry(submission, 'submission', parentTopDocument.id);
          }
        }
      });
  }
  private addSeparetedSubmissions(iterator?: IterableIterator<IDocument>): void {
    const selectedItems: IDocument[] = this.selectedSubmissionList;
    if (!iterator) {
      iterator = selectedItems[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      this.updateAgendaTime();
      this.updateFilteredTreeViewItems();
      NotifyService.global.success('Die Vorlagen wurden hinzufügt.');
      return;
    }

    const submission = result.value as IDocument;

    this.createTOPDocument()
      .pipe(
        tap(doc => from(this.documentService.createFromParent(doc, submission))),
        switchMap(doc => this.documentService.save(doc)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (savedTopDocument): void => {
          //add TOP
          this.addEntry(savedTopDocument, 'top');

          // add submission
          this.addEntry(submission, 'submission', savedTopDocument.id);
        },
        error: (error: string) => {
          this.loadPanelService.hide();
          NotifyService.global.error(error);
        },
        complete: () => {
          this.addSeparetedSubmissions(iterator);
        }
      });
  }

  private changeActivationItems(items: TreeViewItem[], parentId: string, isActive: boolean): void {
    // Create a map of parentId to children for quick lookup
    const childrenMap = items.reduce((acc, item) => {
      if (!acc[item.parentId]) {
        acc[item.parentId] = [];
      }
      acc[item.parentId].push(item);
      return acc;
    }, {} as Record<string, TreeViewItem[]>);

    // Recursive function to update visibility
    const updateChildrenVisibility = (parentId: string, isActive: boolean): void => {
      if (childrenMap[parentId]) {
        childrenMap[parentId].forEach(child => {
          child.isActive = isActive;
          updateSubmissionVisibility(child, isActive);
          updateChildrenVisibility(child.id, isActive);
        });
      }
    };
    const updateSubmissionVisibility = (item: TreeViewItem, isActive: boolean): void => {
      item.submissions?.forEach(child => {
        child.isActive = isActive;
      });
    };

    // Update visibility for the target item and its children
    items.forEach(item => {
      if (item.id === parentId) {
        item.isActive = isActive;
        updateSubmissionVisibility(item, isActive);
        updateChildrenVisibility(item.id, isActive);
      }
    });
  }
  private getChildren(parentId?: string): TreeViewItem[] {
    const children = this.agendaItems.filter(item => item.parentId === parentId);
    const submissions = this.agendaItems.find(item => item.id === parentId)?.submissions;
    if (submissions?.length) {
      children.push(...submissions);
      submissions?.forEach(submission => {
        if (submission?.tasks?.length) {
          children.push(...submission.tasks);
        }
      });
    }
    children.forEach(child => {
      const grandchildren = this.getChildren(child.id);
      if (grandchildren.length > 0) {
        children.push(...grandchildren);
      }
    });
    return children;
  }

  private deleteItemsRecursive(items: TreeViewItem[], iterator?: IterableIterator<TreeViewItem>): void {
    if (!iterator) {
      iterator = items[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      NotifyService.global.success('TOPs sind gelöscht...');
      return;
    }

    const item = result.value as TreeViewItem;
    const document = item.document;
    if (!document) {
      this.removeEntry(item.id, item.type, item.parentId);
      this.deleteItemsRecursive(items, iterator);
    } else {
      this.documentService
        .delete(document, false, true)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (): void => {
            this.removeEntry(document.id, item.type, item.parentId);
          },
          error: (error: string) => {
            this.loadPanelService.hide();
            NotifyService.global.error(error);
          },
          complete: () => {
            this.deleteItemsRecursive(items, iterator);
          }
        });
    }
  }
  private parkItemsRecursive(items: TreeViewItem[], iterator?: IterableIterator<TreeViewItem>): void {
    if (!iterator) {
      iterator = items[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      NotifyService.global.success('TOPs sind gelöscht...');
      return;
    }

    const item = result.value as TreeViewItem;
    const document = item.document;
    if (!document) {
      this.removeEntry(item.id, item.type, item.parentId);
      this.parkItemsRecursive(items, iterator);
    } else {
      this.documentService
        .park(document, false, true)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (): void => {
            this.removeEntry(document.id, item.type, item.parentId);
          },
          error: (error: string) => {
            this.loadPanelService.hide();
            NotifyService.global.error(error);
          },
          complete: () => {
            this.parkItemsRecursive(items, iterator);
          }
        });
    }
  }

  private removeReferencesItemsRecursive(items: TreeViewItem[]): Observable<unknown> {
    const allItems = convertToPlainArray(copy(this.agendaItems));
    const referencesToRemove: { parentId: string; documentId: string }[] = [];
    for (const item of items) {
      switch (item.type) {
        case 'top':
        case 'pause':
          if (this.documentService.isDocumentReferenced(item.document, this.agendaDocument)) {
            referencesToRemove.push({ documentId: item.document.id, parentId: this.agendaDocument.id });
          }
          break;
        case 'submission':
        case 'top-protocol':
          {
            if (this.documentService.isDocumentReferenced(item.document, this.agendaDocument)) {
              referencesToRemove.push({ documentId: item.document.id, parentId: this.agendaDocument.id });
            }
            const parentItem = allItems.find(parent => parent.id === item.parentId);
            if (parentItem && this.documentService.isDocumentReferenced(item.document, parentItem.document)) {
              referencesToRemove.push({ documentId: item.document.id, parentId: parentItem.document.id });
            }
          }
          break;
        case 'task':
          {
            if (this.documentService.isDocumentReferenced(item.document, this.agendaDocument)) {
              referencesToRemove.push({ documentId: item.document.id, parentId: this.agendaDocument.id });
            }
            const parentSubmissionItem = allItems.find(parent => parent.id === item.parentId);
            if (
              parentSubmissionItem &&
              this.documentService.isDocumentReferenced(item.document, parentSubmissionItem.document)
            ) {
              referencesToRemove.push({ documentId: item.document.id, parentId: parentSubmissionItem.document.id });
            }
            const parentTOPItem = allItems.find(parent => parent.id === parentSubmissionItem.parentId);
            if (parentTOPItem && this.documentService.isDocumentReferenced(item.document, parentTOPItem.document)) {
              referencesToRemove.push({ documentId: item.document.id, parentId: parentTOPItem.document.id });
            }
          }
          break;

        default:
          break;
      }
    }
    if (referencesToRemove?.length) {
      return this.documentService.removeReferences(referencesToRemove).pipe(
        tap(() => {
          for (const item of items) {
            this.removeEntry(item.document.id, item.type, item.parentId);
          }
        }),
        takeUntil(this.destroyed$)
      );
    } else {
      for (const item of items) {
        this.removeEntry(item.document.id, item.type, item.parentId);
      }
    }
    return of(null);
  }

  private formatTime = (milliseconds: number): string => {
    const totalSeconds = Math.abs(milliseconds) / 1000;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    let min = `${minutes}`;
    if (minutes < 10 && minutes > -10) {
      min = `0${minutes}`;
    }
    const sec = seconds < 10 ? `0${seconds}` : seconds;
    return milliseconds > 0 ? `${min}:${sec}` : `-${min}:${sec}`;
  };

  private getCurrentTime(): Date {
    return new Date();
  }

  // Check if the current time is between start and end times
  private isBetween(currentTime: Date, startTime: Date, endTime: Date): boolean {
    return currentTime >= startTime && currentTime <= endTime;
  }

  // Observable that emits current time every second
  private getCurrentTimeObservable(): Observable<Date> {
    return interval(1000).pipe(map(() => this.getCurrentTime()));
  }

  private startAgendaTimeCountDown(): void {
    const currentTimeObservable = this.getCurrentTimeObservable();

    this.currentTimeObservable$ = currentTimeObservable.subscribe((currentTime: Date) => {
      const item = this.agendaItems.find(item => this.isBetween(currentTime, item.startTime, item.endTime));
      if (!this.timerVisible) {
        this.currentItem = item;
      }
      if (this.currentItem) {
        const subject = this.currentItem?.document?.fields?.subject?.value as string;
        const index = this.currentItem.position;
        this.timerTitle = `TOP-${index}: ${subject}`;
        this.timerVisible = true;
        const timeDiff = this.currentItem.endTime.getTime() - currentTime.getTime();
        if (timeDiff < 0) {
          this.exceededTime = true;
          this.popupHeight = 220;
        } else {
          this.popupHeight = 150;
          this.exceededTime = false;
        }
        this.countdown = this.formatTime(timeDiff);
      } else {
        this.timerVisible = false;
        this.countdown = '';
        this.currentTimeObservable$.unsubscribe();
      }
    });
  }

  private addPosition(array: TreeViewItem[]): TreeViewItem[] {
    const result = array.flat();
    return result.map((item, index) => {
      item.position = (index + 1).toString();
      return item;
    });
  }
  private splitArrayIntoChunks(array: TreeViewItem[], chunkSize: number): TreeViewItem[] {
    if (chunkSize <= 0) {
      throw new Error('Chunk size must be greater than 0');
    }
    const mapped = this.addPosition(array);
    const result: TreeViewItem[] = [];
    for (let i = 0; i < mapped.length; i += chunkSize) {
      const chunk = mapped.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  private handleWorkflowActions = (event: ItemClickEventMenu, item: TreeViewItem): void => {
    const items = event.component.option('items') as ITopAction[];
    if (!items[0].isLoaded) {
      if (item.type === 'top') {
        items[0].items = this.getItemTopActions(item);
      }
      if (item.type === 'submission') {
        items[0].items = this.getItemSubmissionActions();
      }
      if (!item?.isActive) {
        items[0].showSubMenu = true;
        items[0].isLoaded = true;
        setTimeout(() => {
          event.component.option('items', items);
        }, 300);
        return;
      }
      this.loadPanelService.show();
      const workflowParameters = this.documentService.getRereferencesObject(this.selectedTOP.document);
      workflowParameters[siamConst.conditionAgendaExistsTag] = true;
      this.documentService
        .getDocumentWorkFlow(this.selectedTOP.document, workflowParameters)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: entry => {
            const workflowActions = entry.actionItems;
            const index = items[0]?.items?.findIndex(item => item.value === 'wf-action');
            const actionTpl = (data: ITopAction, index: number, element: HTMLElement): unknown => {
              /* add span element for Text */
              const newSpan = this.renderer.createElement('span') as Element;
              const text = this.renderer.createText(data.text) as string;
              this.renderer.addClass(newSpan, 'dx-menu-item-text');
              this.renderer.appendChild(newSpan, text);
              this.renderer.setStyle(newSpan, 'padding-left', '25px');

              /* add i element for icon if exisits */
              const iconName = data.action?.action?.customValues?.icon;
              if (iconName) {
                const newIcon = this.renderer.createElement('i') as Element;
                this.renderer.addClass(newIcon, `dx-icon`);
                this.renderer.addClass(newIcon, `dx-icon-${iconName}`);
                this.renderer.appendChild(element, newIcon);
                this.renderer.addClass(element.parentElement, `dx-menu-item-has-icon`);
              }
              /* add background color of Edge if exisits */
              const colorName = data.action?.action?.customValues?.type;
              if (colorName) {
                this.renderer.addClass(element.parentElement, `types-selectbox_background-${colorName}`);
              }
              this.renderer.appendChild(element, newSpan);
              this.renderer.addClass(element.parentElement, `dx-menu-item-has-text`);
              return element;
            };
            if (index > -1) {
              if (workflowActions?.length) {
                items[0].items[index].items = workflowActions.map(
                  action =>
                    ({
                      text: action.text,
                      value: 'execute-action',
                      icon: action.icon,
                      action: action,
                      template: actionTpl
                    } as ITopAction)
                );
              } else {
                items[0].items.splice(index, 1);
              }
            }
            items[0].showSubMenu = true;
            items[0].isLoaded = true;
            setTimeout(() => {
              event.component.option('items', items);
              this.loadPanelService.hide();
            }, 300);
          },
          error: () => {
            this.loadPanelService.hide();
          }
        });
    }
  };
  private getTOPTemplate(): Observable<ITemplateServer> {
    if (this.topTemplate) {
      return of(this.topTemplate);
    }
    return this.templateService.getActiveTemplates('create', ['app:document-type:top']).pipe(
      first(),
      map(templates => {
        if (templates && !templates.length) {
          NotifyService.global.error(
            'Es wurde keine Vorlage für die Erstellung eines TOP-Dokuments gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
          );
        }
        let topTemplateAgenda: ITemplateServer = null;
        const topId = this.topTemplateId;
        if (topId) {
          topTemplateAgenda = templates.find(template => template?.id === topId);
        } else {
          topTemplateAgenda = templates.find(template =>
            template?.name?.toLowerCase().includes(this.agendaDocument?.template?.name?.toLocaleLowerCase())
          );
        }
        this.topTemplate = topTemplateAgenda || templates[0];
        return this.topTemplate;
      }),
      takeUntil(this.destroyed$)
    );
  }
  private createTOPDocument(): Observable<IDocument> {
    if (this.topTemplateDocument) {
      return of(this.topTemplateDocument);
    }
    return this.getTOPTemplate().pipe(
      switchMap(template => this.documentService.createDocumentByTemplateId(template)),
      tap(document => {
        this.topTemplateDocument = document;
      }),
      takeUntil(this.destroyed$)
    );
  }
  private getReferencesFromDocuments(documents: IDocument[]): Observable<IDocument[]> {
    if (!documents.length) {
      return of([] as IDocument[]);
    }
    return of(documents).pipe(
      map(docs => docs.filter(d => d.references.length)),
      map(docs => docs.map(d => d.id)),
      switchMap(ids => this.getSearchDocumentsByIds(ids)),
      concatMap(response => response),
      toArray(),
      map(parents =>
        documents.map(doc => {
          doc.references = doc.references.map(r => {
            r.document = parents.find(p => p.id === r.referencedDocumentId);
            return r;
          });
          return doc;
        })
      )
    );
  }
  private getSearchDocumentsByIds(ids: string[]): Observable<IDocument[]> {
    if (!ids.length) {
      return of([] as IDocument[]);
    }

    // not more than 1024 OR conditions per request is allowed
    // every ID generate 2 OR conditions, that's why step should be MAX ALLOWED / 2
    const step = 500;
    let batch = step;
    const uniqueIds = Array.from(new Set(ids));
    const streams$: Observable<IDocument[]>[] = [];
    const length = uniqueIds.length;

    for (let i = 0; i < length; i += step) {
      const part = uniqueIds.slice(i, batch);

      const query = part
        .map(id => {
          const _id = JSON.stringify(id);
          return `references.child:${_id} || references.parent:${_id}`;
        })
        .join(' || ');
      const search: ISearch = {
        query,
        resultConfiguration: {
          selectedFields: [
            'fields.subject',
            'fields.startdate',
            'template.caption',
            'tags',
            'documentWorkflowDocument.currentStatus'
          ]
        }
      };
      streams$.push(this.documentService.searchDocuments(search).pipe(map(data => data.documents)));

      batch += step;
    }
    return concat(...streams$);
  }
}
