import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { siamConst } from '@interfaces/siamConst';
import { IUser } from '@interfaces/siam';
import { LoggerService } from './logger.service';
import * as UserFactory from '@factories/user.factory';
import { UserApi } from '../api/user.api';

@Injectable({ providedIn: 'root' })
export class LoginService {
  currentUser$ = new BehaviorSubject<IUser>(null);
  getCurrentUser = this.currentUser$.asObservable();

  impersonatedUser$ = new BehaviorSubject<IUser>(null);
  getImpersonatedUser = this.impersonatedUser$.asObservable();
  isImpersonated = new BehaviorSubject<boolean>(false);

  logonUser$ = new BehaviorSubject<IUser>(null);
  redirectUrl: string;

  constructor(private logger: LoggerService, private userApi: UserApi) {
  }

  login(userName: string, password: string, isPersistent: boolean): Observable<IUser> {
    return this.userApi.login(userName, password, isPersistent).pipe(
      tap({
        next: this.handleUser
      })
    );
  }

  logOut(): Observable<void> {
    return this.userApi.logOut().pipe(
      tap({
        next: () => {
          localStorage.removeItem(siamConst.currentUser);
          localStorage.removeItem(siamConst.currentUserTimestamp);
          this.redirectUrl = null;
        }
      })
    );
  }

  info(logError?: boolean): Observable<IUser> {
    return this.userApi.getInfo(logError).pipe(
      tap({
        next: user => {
          UserFactory.storeLocally(user);
          this.setUserData(user);
        }
      })
    );
  }

  stopImpersonation(): Observable<IUser> {
    return this.userApi.stopImpersonation().pipe(
      tap(user => {
        UserFactory.storeLocally(user.logonUser);
        this.setUserData(user);
      })
    );
  }

  loginImpersonation(userId: string): Observable<IUser> {
    return this.userApi.loginImpersonation(userId).pipe(
      tap(user => {
        UserFactory.storeLocally(user)
        this.setUserData(user);
      })
    );
  }

  windowsAuth(): Observable<IUser> {
    return this.userApi.windowsAuth().pipe(
      tap({
        next: this.handleUser
      })
    );
  }

  private setUserData(data: IUser): void {
    const user = UserFactory.copy(data);
    const current = UserFactory.copy(this.currentUser$.getValue());
    if (JSON.stringify(user) === JSON.stringify(current)) {
      return;
    }
    if (user.id === user.logonUser?.id) {
      this.currentUser$.next(user);
      this.logonUser$.next(user);
      this.impersonatedUser$.next(null);
      this.isImpersonated.next(false);
    } else {
      this.logonUser$.next(UserFactory.copy(user.logonUser));
      this.currentUser$.next(UserFactory.copy(user));
      this.impersonatedUser$.next(user);
      this.isImpersonated.next(true);
    }
  }

  private handleUser = (user: IUser): void => {
    this.logger.info('LoginService: success, userinfo = {@userinfo}', user);
    this.logonUser$.next(UserFactory.copy(user));
    UserFactory.storeLocally(user)
  };
}
