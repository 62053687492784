import { AppState, ClearStore } from '..';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as usersReducer from './users.reducer';
import * as rolesReducer from './roles.reducer';
import * as listsReducer from './lists.reducer';
import * as reducersStore from '../actions/index';

export const clearState = (reducer: ActionReducer<AppState>): (state: AppState, action: ClearStore) => AppState =>
  (state: AppState, action: reducersStore.StateActions): AppState => {

    if (action.type === reducersStore.StateActionTypes.clearStore) {
      state = undefined;
    }
    return reducer(state, action);
  };

//eslint-disable-next-line
export const reducers: ActionReducerMap<AppState, any> = {
  userState: usersReducer.reducer,
  roleState: rolesReducer.reducer,
  listState: listsReducer.reducer
};

export const logger = (reducer: ActionReducer<AppState>): ActionReducer<AppState> =>
  (state: AppState, action: Action): AppState => reducer(state, action);

//eslint-disable-next-line
export const metaReducers: MetaReducer<AppState, any>[] = [logger, clearState];
