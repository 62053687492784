<dx-slider
  [hint]="field.editorOptions.hint"
  [min]="field.editorOptions?.customValues?.min"
  [max]="field.editorOptions?.customValues?.max"
  [step]="field.editorOptions?.customValues?.step"
  [(value)]="value"
  [readOnly]="!isEditing"
  (onValueChanged)="valueChanged($event)">
  <dxo-label
    [visible]="field.editorOptions?.sliderLabel?.visible"
    [format]="field.editorOptions?.sliderLabel?.format"
    [position]="field.editorOptions?.sliderLabel?.position">
  </dxo-label>
  <dxo-tooltip
    [enabled]="field.editorOptions?.tooltip?.enabled"
    [format]="field.editorOptions?.tooltip?.format"
    [showMode]="field.editorOptions?.tooltip?.showMode"
    [position]="field.editorOptions?.tooltip?.position">
  </dxo-tooltip>
</dx-slider>
