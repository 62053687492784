import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hidden'
})
export class HiddenPipe implements PipeTransform {

  transform(value: boolean): string {
    return value ? 'dx-hidden' : '';
  }
}
