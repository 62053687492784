import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[appScrollPosition]'
})
export class ScrollPositionDirective implements OnDestroy {
  @Output() scrollPosition = new EventEmitter<number>();

  private scrollEvent$: Subscription;

  constructor(private targetElement: ElementRef<Node>) {
    this.scrollEvent$ = fromEvent(this.targetElement.nativeElement, 'scroll').subscribe((event: Event) => {
      this.scrollPosition.emit((event.target as Element).scrollTop);
    });
  }

  ngOnDestroy(): void {
    this.scrollEvent$.unsubscribe();
  }
}
