import { FieldTypes } from '@interfaces/fieldClient';
import {} from '@interfaces/permission';
import { siamConst } from '@interfaces/siamConst';
import { IAuditInformation, IConfidentialReference, TEffectivePermission, TPermission } from './siam';

export class TemplateClient {
  id?: string;
  caption: string;
  name: string;
  autoUpdate?: boolean;
  description?: string;
  fields: FieldTypes[];
  defaultLayoutId?: string;
  permissions?: TPermission[];
  documentPermissions?: TPermission[];
  fieldPermissions?: TPermission[];
  confidentialReferences?: IConfidentialReference[];
  effectivePermissions?: TEffectivePermission[];
  tagType?: string;
  tagTypeName?: string;
  tags: string[];
  isDisabled?: boolean;
  icon?: string;
  stickyHeader?: boolean;
  labelLocation?: string;
  categoryTitel?: boolean;
  label?: string;
  workflowId: string;
  creation?: IAuditInformation;
  modification?: IAuditInformation;
  constructor(init?: Partial<TemplateClient>) {
    this.id = init && init.id ? init.id : '';
    this.caption = init && init.caption ? init.caption : '';
    this.name = init && init.name ? init.name : '';
    this.description = init && init.description ? init.description : '';
    this.permissions = init && init.permissions ? init.permissions : [];
    this.documentPermissions = init && init.documentPermissions ? init.documentPermissions : [];
    this.fieldPermissions = init && init.fieldPermissions ? init.fieldPermissions : [];
    this.effectivePermissions = init && init.effectivePermissions ? init.effectivePermissions : [];
    this.fields = init && init.fields ? init.fields : [];
    this.tags = init && init.tags ? init.tags : [];
    this.icon = init && init.icon ? init.icon : undefined;
    this.stickyHeader = init && init.stickyHeader ? init.stickyHeader : false;
    this.labelLocation = init && init.labelLocation ? init.labelLocation : 'left';
    this.categoryTitel = init && init.categoryTitel ? init.categoryTitel : false;
    this.label = init && init.label ? init.label : undefined;
    this.workflowId = init && init.workflowId ? init.workflowId : siamConst.defaultWorkflowId;
  }
}
