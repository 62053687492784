<!-- Vorgangsinformationen Popup -->
<dx-popup
  *ngIf="docInfos"
  class="current-workflow-status"
  width="60vw"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [(visible)]="dialogOpen"
  [height]="'auto'"
  [fullScreen]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [shading]="true"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'material-icons md-24 flag', elementAttr: { class: 'button-icon' } }
    },
    { text: 'Vorgangsinformationen', location: 'before', toolbar: 'top' },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: close
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <dx-form [formData]="docInfos" readOnly="true">
      <dxi-item itemType="group" caption="Allgemeine Vorgangsdaten" colCount="2">
        <dxi-item
          [label]="{ text: 'Erstelldatum' }"
          dataField="creation.timestamp"
          editorType="dxDateBox"
          [editorOptions]="{ displayFormat: 'dd.MM.yyyy HH:mm' }">
        </dxi-item>

        <dxi-item
          [label]="{ text: 'Ersteller' }"
          dataField="creator"
          editorType="dxTagBox"
          [editorOptions]="{
            dataSource: docInfos.creator,
            displayExpr: 'name',
            valueExpr: 'name',
            tagTemplate: userItemTemplate
          }">
        </dxi-item>

        <dxi-item [label]="{ text: 'Status' }" dataField="stateLabel"></dxi-item>

        <dxi-item
          [label]="{ text: 'letzter Begünstigter' }"
          dataField="assignee"
          editorType="dxTagBox"
          [editorOptions]="{
            dataSource: docInfos.assignee,
            displayExpr: 'name',
            valueExpr: 'name',
            tagTemplate: userItemTemplate
          }">
        </dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Details">
        <button type="submit" class="btn btn-secondary btn-sm mr-2" (click)="showDiagram()">
          <i class="material-icons">crop_free</i>Workflowdiagramm anzeigen
        </button>
        <button type="submit" class="btn btn-secondary btn-sm" (click)="showPermission()">
          <i class="material-icons">verified</i>aktuelle Berechtigungen
        </button>
      </dxi-item>

      <dxi-item itemType="group" caption="Vorgangshistorie" cssClass="worfkflow">
        <div class="worfkflow-history" *ngIf="workflowHistory.length > 0; else noHistory">
          <ng-container *ngFor="let hist of workflowHistory; index as i; last as isLast">
            <div
              class="worfkflow-history__index"
              (click)="hist.activitiesVisible = !hist.activitiesVisible"
              [class.history-last-item]="isLast">
              {{ i + 1 }}
            </div>
            <div class="worfkflow-history__time">{{ hist.time | date : 'dd.MM.y - HH:mm' }}</div>
            <div
              class="worfkflow-history__user"
              (mouseenter)="hist.onMouseEnterUser($event)"
              (mouseleave)="hist.onMouseLeaveUser()">
              <img class="user-avatar-name" src="{{ hist.user.picture }}" />{{ hist.user.name }}
            </div>
            <div *ngIf="hist.assignee.length > 0; else noAssigneeTemplate">
              <span *ngIf="hist.sourceVertex" class="worfkflow-history__edge">{{ hist.sourceVertex }}</span>
              <span [ngClass]="hist.sourceVertex ? 'worfkflow-history__vertex' : 'worfkflow-history__edge'">
                {{ hist.edge }}
              </span>
              <span>
                (an:
                <span *ngFor="let assignee of hist.assignee" class="worfkflow-history__assignee">
                  {{ assignee.name }}
                </span>
                )
              </span>
              <span class="worfkflow-history__vertex">{{ hist.targetVertex }}</span>
              <i
                class="material-icons md-18 dx-icon-doc"
                id="subformName-{{ i }}"
                *ngIf="hist.isDocument"
                (click)="hist.onDocument()"></i>

              <dx-tooltip target="#subformName-{{ i }}" showEvent="dxhoverstart" hideEvent="dxhoverend">
                <div *dxTemplate="let data = data; of: 'content'">
                  {{ hist.subformName ? 'Dialogformular: ' + hist.subformName : 'ausgefülltes Workflowformular' }}
                </div>
              </dx-tooltip>
            </div>

            <ng-template #noAssigneeTemplate>
              <div>
                <span *ngIf="hist.sourceVertex" class="worfkflow-history__edge">{{ hist.sourceVertex }}</span>
                <span [ngClass]="hist.sourceVertex ? 'worfkflow-history__vertex' : 'worfkflow-history__edge'">
                  {{ hist.edge }}
                </span>
                <span class="worfkflow-history__vertex">{{ hist.targetVertex }}</span>
                <i
                  class="material-icons md-18 dx-icon-doc"
                  id="subformName-{{ i }}"
                  *ngIf="hist.isDocument"
                  (click)="hist.onDocument()"></i>
                <dx-tooltip target="#subformName-{{ i }}" showEvent="dxhoverstart" hideEvent="dxhoverend">
                  <div *dxTemplate="let data = data; of: 'content'">
                    {{ hist.subformName ? 'Dialogformular: ' + hist.subformName : 'ausgefülltes Workflowformular' }}
                  </div>
                </dx-tooltip>
              </div>
            </ng-template>

            <ng-container *ngIf="hist.activitiesVisible">
              <ng-container *ngFor="let activity of hist.actionLogs">
                <div></div>
                <div></div>

                <div class="worfkflow-history__activity">
                  <i
                    id="actiontype-{{ activity.actionId }}"
                    *ngIf="activity.actionType"
                    class="worfkflow-history__activity-name material-icons md-18 mr-1"
                    style="cursor: pointer"
                    >{{ activity.actionType }}</i
                  >
                  <i class="worfkflow-history__activity-name dx-icon-{{ activity.icon }}"></i>
                  <span id="comment-{{ activity.actionId }}" style="cursor: pointer">
                    {{ activity.type }}
                  </span>
                  <dx-popover
                    *ngIf="activity?.actionComment"
                    target="#comment-{{ activity.actionId }}"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position="top"
                    [width]="200">
                    <div *dxTemplate="let data = data; of: 'content'">
                      {{ activity?.actionComment || '' }}
                    </div>
                  </dx-popover>
                </div>

                <div class="worfkflow-history__activity-state">
                  {{ activity.message }}
                  <i *ngIf="activity.isSuccess" class="material-icons md-18 vote-value--yes">done</i>
                  <i *ngIf="!activity.isSuccess" class="material-icons md-18 vote-value--no">close</i>
                  <span *ngIf="activity.action && activity.actionId">
                    (<a id="popover-{{ activity.actionId + i }}" (click)="showActivityDetails(activity, i)">Details</a>)
                  </span>
                </div>
              </ng-container>

              <ng-container *ngFor="let vote of hist.votes; index as j">
                <div></div>
                <div></div>

                <div class="worfkflow-history__activity">
                  <img class="user-avatar-name" src="{{ vote.voter.picture }}" />
                  {{ vote.voter.name }}
                </div>

                <div class="worfkflow-history__activity-state">
                  {{ vote.time | date : 'dd.MM.y HH:mm' }} Stimme abgegeben ( {{ vote.vote }} )
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>

        <ng-template #noHistory>
          <span class="no-history">keine Vorgangshistorie vorhanden.</span>
        </ng-template>
      </dxi-item>
    </dx-form>
    <div id="tooltip" display="none" style="position: absolute; display: none"></div>
  </div>
</dx-popup>

<dx-popover #popOverCreatorField id="popOverCreatorField" [(target)]="popoverElement">
  <div *dxTemplate="let data of 'content'">
    <span [innerHtml]="popoverCreatorText | safeHtml"></span>
  </div>
</dx-popover>

<dx-popover #popOverUserHistory id="popOverUserHistory" [(target)]="popoverElementUser">
  <div *dxTemplate="let data = model; of: 'content'">
    <span [innerHtml]="popoverTextUser | safeHtml"></span>
  </div>
</dx-popover>

<dx-popover
  [target]="currentTargetId"
  position="top"
  [width]="80 + 'vw'"
  [shading]="false"
  shadingColor="rgba(0, 0, 0, 0.5)"
  (onHidden)="onHidden()"
  (onShowing)="onShowing($event)"
  [(visible)]="currentPopupActivityVisible">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <dx-form
        #detailsActionForm
        *ngIf="currentAction?.formData && currentAction?.formItems"
        class="mt-2"
        labelLocation="left"
        [showColonAfterLabel]="true"
        readOnly="true"
        [items]="currentAction.formItems"
        [formData]="currentAction.formData">
        <!-- checkbox group  -->
        <div *dxTemplate="let field of 'checkboxGroup'" class="checkbox-group">
          <div
            *ngIf="currentAction?.formData"
            [ngClass]="{ 'checkbox-group-column': field.editorOptions.checkboxGroupColumns > 0 }"
            [ngStyle]="{
              'grid-template-columns':
                field.editorOptions.checkboxGroupColumns > 0
                  ? 'repeat(auto-fill, max(' +
                    field.editorOptions.checkboxGroupColumnWidth +
                    'px, 100% /' +
                    field.editorOptions.checkboxGroupColumns +
                    '))'
                  : null
            }">
            <div *ngFor="let checkbox of field.editorOptions.choices; let i = index" class="checkbox-group__items">
              <div
                *ngIf="
                  field.dataField &&
                    currentAction?.formData[field.dataField] &&
                    currentAction?.formData[field.dataField] | find : checkbox.value;
                  else checkboxGroupFalseContainer
                ">
                <dx-check-box [text]="checkbox.label" [value]="true" readOnly="true"> </dx-check-box>
              </div>
              <ng-template #checkboxGroupFalseContainer>
                <ng-container *ngTemplateOutlet="checkboxGroupFalse; context: { index: i }"></ng-container>
              </ng-template>
              <ng-template #checkboxGroupFalse let-index="index">
                <div>
                  <dx-check-box [text]="checkbox.label" [value]="false" readOnly="true"> </dx-check-box>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </dx-form>
      <div *ngIf="!currentAction?.formItems">Keine Informationen verfügbar</div>
    </dx-scroll-view>
  </div>
</dx-popover>

<!-- Aktuelle Berechtigungen Popup -->
<ng-container *ngIf="dialogPermissionOpen">
  <dx-popup
    [(visible)]="dialogPermissionOpen"
    width="45vw"
    [height]="'auto'"
    [fullScreen]="false"
    [hideOnOutsideClick]="false"
    [showCloseButton]="false"
    [shading]="true"
    shadingColor="rgba(0, 0, 50, 0.5)"
    [toolbarItems]="[
      {
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        visible: true,
        options: { type: 'normal', icon: 'material-icons md-24 verified', elementAttr: { class: 'button-icon' } }
      },
      { text: 'Aktuelle Berechtigungen', location: 'before', toolbar: 'top' },
      {
        widget: 'dxButton',
        location: 'after',
        toolbar: 'top',
        visible: true,
        options: { type: 'normal', icon: 'close', hint: 'Schließen' },
        onClick: closePermissions
      }
    ]">
    <div *dxTemplate="let data of 'content'">
      <div *ngIf="documentConfidential" class="confidential__msg alert alert-warning">
        <i class="material-icons">warning</i><strong>Warnhinweis:</strong>
        <span>
          Für diesen Vorgang gilt die Sicherheitsstufe: <b>{{ documentConfidential }}</b> <br />
          <span class="confidential__msg_s"
            >Möglicherweise sind hier aufgeführte Einzelberechtigungen unwirksam, wenn keine Freischaltung für die
            geltende Sicherheitsstufe besteht.</span
          >
        </span>
      </div>

      <dx-data-grid
        [dataSource]="permissionsData"
        [showBorders]="true"
        [showRowLines]="true"
        [showColumnLines]="true"
        [hoverStateEnabled]="false"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
        [selectedRowKeys]="[]">
        <!-- columns -->
        <dxi-column
          caption="Name"
          dataField="data.avatarName"
          minWidth="120"
          cssClass="cell-voter"
          [encodeHtml]="false"
          [width]="40 + '%'"
          [allowSorting]="false">
        </dxi-column>
        <div *dxTemplate="let cell of 'cellUser'">
          <div *ngIf="cell.data.user">
            <div [innerHtml]="(cell.data.user | getNameData | async).avatarName | safeHtml"></div>
          </div>
        </div>

        <dxi-column
          caption="Lesen"
          dataField="read"
          alignment="center"
          [allowSorting]="true"
          [width]="30 + '%'"
          cellTemplate="readTemplate">
        </dxi-column>
        <div *dxTemplate="let cell of 'readTemplate'" class="h-center">
          <i *ngIf="cell.data.read" class="material-icons md-18 vote-value--yes">done</i>
          <i *ngIf="!cell.data.read" class="material-icons md-18 vote-value--no">close</i>
        </div>

        <dxi-column
          caption="Bearbeiten"
          dataField="update"
          alignment="center"
          [allowSorting]="true"
          [width]="30 + '%'"
          cellTemplate="updateTemplate">
        </dxi-column>
        <div *dxTemplate="let cell of 'updateTemplate'" class="h-center">
          <i *ngIf="cell.data.update" class="material-icons md-18 vote-value--yes">done</i>
          <i *ngIf="!cell.data.update" class="material-icons md-18 vote-value--no">close</i>
        </div>
      </dx-data-grid>
    </div>
  </dx-popup>
</ng-container>
<!-- Benutzerangaben zum Workflowschritt Popup -->
<dx-popup
  title="Benutzerangaben zum Workflowschritt"
  width="90vw"
  maxHeight="100%"
  [fullScreen]="false"
  [showCloseButton]="false"
  [(visible)]="this.edgeDocument"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: onHideFormular
    }
  ]">
  <dx-scroll-view>
    <app-document-include *ngIf="this.edgeDocument" [currentDocument]="edgeDocument" [editMode]="'ReadOnly'">
    </app-document-include>
  </dx-scroll-view>
</dx-popup>

<app-workflow-diagram
  *ngIf="workflowDocument && workflowDocument.workflow && docInfos"
  [workflow]="workflowDocument.workflow"
  [stateLabel]="docInfos.stateLabel"
  [workflowHistory]="workflowHistory">
</app-workflow-diagram>
