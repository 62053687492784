import { Pipe, PipeTransform } from '@angular/core';
import { DocumentService } from '@services/document.service';
import { IWorkflowDocument } from '@interfaces/siam';

@Pipe({
  name: 'getWorkflowStatus'
})
export class GetWorkflowstatusPipe implements PipeTransform {

  constructor(private _documentService: DocumentService) {
  }

  transform(workflowDocuments: IWorkflowDocument[], arg?: string): string {
    return this._documentService.fetchWorkflowStatus(workflowDocuments, arg);
  }
}

