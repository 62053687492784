import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentEditorComponent } from '../../../dialogs/document-editor/document-editor.component';
import { PopupSettings } from '@services/popup-helper.service';
import { NotifyService } from '@services/notify.service';
import { LoggerService } from '@services/logger.service';
import { DocumentService } from '@services/document.service';
import { IAgenda, IDocument, IEventTask, TEditMode } from '@interfaces/siam';
import { flatArray } from '@factories/helpers';
import { LoadPanelService } from '@services/load-panel.service';

@Component({
  selector: 'app-protocol-include',
  templateUrl: './protocol-include.component.html',
  styleUrls: ['./protocol-include.component.scss']
})
export class ProtocolIncludeComponent implements OnInit, OnDestroy {
  @ViewChild(DocumentEditorComponent, { static: true }) documentEditorComponent: DocumentEditorComponent;

  @Input() meetingsMinutesDoc: IDocument = null;
  @Input() parentDocumentID: string;
  @Input() editMode: TEditMode = 'ReadOnly';

  agendaItems: IAgenda[] = [];
  parentDocument: IDocument = null;
  selectedItem: IAgenda = null;
  selectAllItems = false;
  validDocuments: IDocument[] = [];

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private documentService: DocumentService,
    private loadPaneLService: LoadPanelService,
    private logger: LoggerService
  ) {}

  async ngOnInit(): Promise<void> {
    // Updates the Fields with new meetingsminute Document
    this.agendaItems = await this.getAgendaItems();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openAItem(aItem: IAgenda): void {
    if (aItem === this.selectedItem) {
      this.selectedItem = null;
      return;
    }
    this.selectedItem = aItem;
  }

  async openAItemDetails(aItem: IAgenda): Promise<void> {
    aItem.document = await lastValueFrom(this.documentService.getDocumentById(aItem.documentId));

    if (!aItem.document) {
      NotifyService.global.warn('Zu diesem TOP wurde kein Dokument gefunden.');
      return;
    }

    this.showAgendaItem(aItem);
  }

  openAItemProtocol(document: IDocument): void {
    if (!document) {
      NotifyService.global.warn('Zu diesem TOP wurde kein Dokument gefunden.');
      return;
    }

    const settings: PopupSettings = {};
    const subject = (document.fields?.subject?.value as string) || '';
    settings.title = `${this.documentService.getTypeLabel(document)}: ${subject}`;
    this.documentService
      .getDocumentById(document.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(doc => {
        void this.documentEditorComponent.show(doc, settings);
      });
  }

  showAgendaItem(aItem: IAgenda): void {
    const aiDocument = aItem.document;
    const settings: PopupSettings = {};
    const iType = this.documentService.getType(aiDocument);
    this.logger.debug('Dokumenttyp des selektierten Agendaeintrags:', iType);
    if (iType === '') {
      NotifyService.global.warn(
        'Der Typ des Agendapunktes konnte nicht ermittelt werden. Ggf. arbeitest Du mit veralteten Vorlagen?'
      );
    }

    settings.title = `${this.documentService.getTypeLabel(aiDocument)}: ${
      (aiDocument?.fields?.subject?.value as string) || aiDocument?.template?.caption || ''
    }`;

    // --- Setzen der PopupAktionen je nach dem, welcher TOP-Typ (TOP oder Vorlage) geöffnet werden soll
    switch (iType) {
      case 'app:document-type:submission': {
        settings.editMode = 'ReadOnly';
        break;
      }
      case 'app:document-type:top': {
        settings.editMode = 'ReadOnly';
        break;
      }
      default:
        settings.editMode = 'ReadOnly';
    }
    // --- EditorKomponente für angegebenes Dokumentout und den definierten Settings öffnen
    this.documentService
      .getDocumentById(aiDocument.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(document => {
        void this.documentEditorComponent.show(document, settings);
      });
  }

  async documentEditorResult(e: IEventTask): Promise<void> {
    switch (e.command) {
      case 'loading':
        this.loadPaneLService.show();
        this.agendaItems = null;
        break;
      case 'store':
      default:
        this.agendaItems = await this.getAgendaItems();
        this.loadPaneLService.hide();
        break;
    }
  }

  hasAttachment(doc: IDocument): boolean {
    if (!doc?.template) {
      return false;
    }
    const templateFields = doc.template.fields;
    const docFields = doc.fields;
    return (
      templateFields
        .filter(fields => fields.type === 'attachments')
        .filter(field => !!docFields[field.name])
        .map(field => docFields[field.name].value as unknown)
        .filter(fieldValue => !!fieldValue && Array.isArray(fieldValue))
        .filter(fieldValue => (fieldValue as unknown[]).length > 0).length !== 0
    );
  }

  isDecision(source: IDocument): boolean {
    return this.documentService.isDecision(source);
  }

  private async getAgendaItems(): Promise<IAgenda[]> {
    const document = this.meetingsMinutesDoc;
    if (!document) {
      return [];
    }

    let filteredItems: IAgenda[] = [];
    const items: IAgenda[] = ((document.fields['--siam-agendaitems']?.value as IAgenda[]) || []).filter(
      (item: IAgenda) => item.index !== null
    );
    // AgendaItems from hierarchical array into a flat array
    const flatItems = items.flatMap(flatArray);

    const ids = flatItems.map(i => i.documentId);
    if (ids.length) {
      const validDocuments = await lastValueFrom(this.documentService.getDocumentsByIds(ids));
      filteredItems = flatItems.filter(i => validDocuments.some(d => d.id === i.documentId));

      this.validDocuments = validDocuments;
    }
    return filteredItems;
  }
}
