import { Component, Input, isDevMode, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-debug-info',
  templateUrl: './debug-info.component.html',
  styleUrls: ['./debug-info.component.scss']
})
export class DebugInfoComponent implements OnDestroy {
  @Input() debugVisible = false;
  debugInfo: Record<string, unknown>[] = null;

  #subscription$: Subscription;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    if (isDevMode()) {
      this.#subscription$ = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          const route = this.activatedRoute.root;
          this.debugInfo = this.getComponentInfo(route);
        });
    }
  }

  getComponentInfo(route: ActivatedRoute, debugInfo: Record<string, unknown>[] = []): Record<string, unknown>[] {
    const children: ActivatedRoute[] = route.children;

    // iterate over each child
    for (const child of children) {
      if (child.routeConfig && child.routeConfig.component) {
        const component = child.routeConfig.component;
        const keys = Object.keys(component.prototype as Record<string, unknown>);
        debugInfo.push({
          component: component.name,
          functions: keys
        });
      }

      this.getComponentInfo(child, debugInfo);
    }

    return debugInfo;
  }

  ngOnDestroy(): void {
    if (this.#subscription$) {
      this.#subscription$.unsubscribe();
    }
  }
}
