import { Action } from '@ngrx/store';

export enum StateActionTypes {
  clearStore = '[Store] clear',
}

export class ClearStore implements Action {
  public readonly type = StateActionTypes.clearStore;
}

export type StateActions = ClearStore;
export * from './users.action';
export * from './roles.action';
export * from './lists.action';
