import { Pipe, PipeTransform } from '@angular/core';
import { IDocument } from '@interfaces/siam';
import { DocumentService } from '@services/document.service';

@Pipe({
  name: 'isPause'
})
export class IsPausePipe implements PipeTransform {

  constructor(private _documentService: DocumentService) {
  }

  transform(document: IDocument): boolean {
    return this._documentService.isPause(document);
  }
}
