<ng-container *ngIf="errors$ | async as errors">
  <div
    *ngIf="errors.length > 0"
    class="notification-container notification-container_component errors animated slideInUp">
    <div i18n class="notification-header">
      <span class="material-icons notification-image">error</span>
      Der folgende Anwendungsfehler ist aufgetreten:
      <button class="notification-close-btn" (click)="closeComponentNotifications()">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="notification-text" *ngFor="let message of errors">
      <div [innerHtml]="message | safeHtml"></div>
    </div>
    <div class="notification-text">
      <a *ngIf="detailError" class="notification-details-link" (click)="isShowDetails = !isShowDetails">Details</a>
      <div [innerHtml]="detailError" *ngIf="detailError && isShowDetails"></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="warnings$ | async as warnings">
  <div *ngIf="warnings.length > 0" class="notification-container warning animated slideInUp">
    <div i18n class="notification-header">
      <span class="material-icons notification-image">warning</span>
      Hinweis:&nbsp;
      <div *ngFor="let message of warnings">
        <div [innerHtml]="message | safeHtml"></div>
      </div>
      <button class="notification-close-btn" (click)="closeComponentNotifications()">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="infos$ | async as infos">
  <div *ngIf="infos.length > 0" class="notification-container info animated slideInUp">
    <div i18n class="notification-header">
      <span class="material-icons notification-image">info</span>
      Der folgende Anwendungsfehler ist aufgetreten:
      <button class="notification-close-btn" (click)="closeComponentNotifications()">
        <i class="material-icons">close</i>
      </button>
    </div>
    <div class="notification-text" *ngFor="let message of infos">
      <div [innerHtml]="message | safeHtml"></div>
    </div>
  </div>
</ng-container>
