import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Edge, Node } from '@swimlane/ngx-graph';
import { IWorkflowClient, IWorkflowHistory } from '@interfaces/siam';
import * as Shape from 'd3-shape';
import * as UserFactory from '@factories/user.factory';
import { Subject } from 'rxjs';
import SvgSaver from 'svgsaver';

interface IEdgeData {
  history: boolean;
  historyStep: string;
  wfHistory: IWorkflowHistory[];
}

interface IEdge extends Edge {
  data: IEdgeData;
}

@Component({
  selector: 'app-workflow-diagram',
  templateUrl: './workflow-diagram.component.html',
  styleUrls: ['./workflow-diagram.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowDiagramComponent {
  @Input() workflow: IWorkflowClient;
  @Input() stateLabel: string;
  @Input() workflowHistory: IWorkflowHistory[];

  update$ = new Subject<boolean>();
  center$ = new Subject<boolean>();
  zoomToFit$ = new Subject<boolean>();

  svgSaver = new SvgSaver();

  isVisible = false;
  nodes: Node[] = [];
  links: IEdge[] = [];
  shape = Shape;
  defaultVisible = false;
  withTemplateVisible = false;
  withAnimationVisible = false;
  userIsAdmin = false;
  dialogFullscreen = false;

  constructor(private window: Window) {
    this.userIsAdmin = UserFactory.isAdmin();
  }

  show(): void {
    this.isVisible = true;
    this.nodes = [];
    this.links = [];
    this.workflow.vertices.forEach(v => {
      this.nodes.push({ id: v.name, label: v.label });
    });
    this.workflow.edges.forEach((e, index) => {
      let isHistory = false;
      let historyStep = '';
      let wfHistory: IWorkflowHistory[] = [];
      if (this.workflowHistory) {
        const indexes = this.getAllIndexes(this.workflowHistory, e.edgeId);
        wfHistory = this.workflowHistory.filter(w => w.edgeId === e.edgeId);
        if (indexes.length > 0) {
          isHistory = true;
          historyStep = indexes.join(' + ');
        }
      }
      this.links.push({
        source: e.source,
        target: e.target,
        label: `${e.label} ${this.userIsAdmin ? `(${index + 1})` : ''}`,
        data: {
          wfHistory,
          history: isHistory,
          historyStep
        }
      });
    });
  }

  // return index of Steps  from executed Edge
  getAllIndexes(arr: IWorkflowHistory[], val: string): number[] {
    const indexes: number[] = [];

    let i: number;
    for (i = 0; i < arr.length; i++) {
      if (arr[i].edgeId === val) {
        indexes.push(i + 1);
      }
    }
    return indexes;
  }

  onShown(): void {
    this.center$.next(true);
    this.update$.next(true);
  }

  dialogExport = (): void => {
    const element = this.window.document.querySelector('ngx-graph');
    this.svgSaver.asSvg(element, 'Workflowdiagramm.svg');
  };

  dialogCancel = (): void => {
    this.isVisible = false;
    this.links = [];
    this.nodes = [];
    this.center$.next(true);
    this.update$.next(true);
  };

  showTooltip(evt: MouseEvent, link: IEdge): void {
    const steps = link.data?.historyStep?.split('+').map(item => item.trim());
    const tooltip = document.getElementById('tooltipEdge');
    let outerHTML = `<h3>${link.label}</h3>`;
    for (const [index, data] of link.data.wfHistory.entries()) {
      let assigneeHtml = '';
      let actionsHtml = '';
      let userHtml = '';
      let innerHTML = `<div class="tooltip-edges__edge">
                      <div class="tooltip-edges__step">Schritt Nr. ${steps[index]}</div>`;

      if (data.assignee?.length > 0) {
        assigneeHtml = '<div>Empfänger:</div><div>';
        data.assignee.forEach(assignee => {
          if (assignee.type === 'user') {
            assigneeHtml += `<div class="v-center"><img alt="" class="user-avatar-name" src="${assignee.picture}">${assignee.name}</div>`;
          } else {
            assigneeHtml += `<div class="v-center"><i class="material-icons md-16 mr-1">group</i>${assignee.name}</div>`;
          }
        });
        assigneeHtml += '</div>';
      }

      if (data.actionLogs?.length > 0) {
        actionsHtml = '<div>Aktivitäten:</div><div>';
        data.actionLogs.forEach(action => {
          let isSuccessHtml = '<i class="material-icons md-18 vote-value--no">close</i>';
          if (action.isSuccess) {
            isSuccessHtml = '<i class="material-icons md-18 vote-value--yes">done</i>';
          }
          actionsHtml += `<div class="v-center">${isSuccessHtml}${action.type}</div>`;
        });
        actionsHtml += '</div>';
      }

      if (data.user) {
        userHtml = `<div>Ausgeführt durch:</div>
        <div class="v-center">
            <img alt="" class="user-avatar-name" src="${data.user.picture}">${data.user.name}
        </div>`;
      }

      innerHTML += `${userHtml}
                    ${assigneeHtml}
                    ${actionsHtml}
              </div>`;

      outerHTML += innerHTML;
    }
    tooltip.innerHTML = outerHTML;
    tooltip.style.display = 'block';
    tooltip.style.left = `${evt.pageX - 80}px`;
    tooltip.style.top = `${evt.pageY - 60}px`;
  }

  hideTooltip(): void {
    const tooltip = document.getElementById('tooltipEdge');
    tooltip.style.display = 'none';
  }

  dialogToogleFullscreen = (): void => {
    this.show();
    this.dialogFullscreen = !this.dialogFullscreen;
  };

  dialogZoom = (): void => {
    this.zoomToFit$.next(true);
  };
}
