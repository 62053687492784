<div [ngClass]="{ 'bg-container': !embeddedMode }">
  <app-component-notification></app-component-notification>

  <div class="bg-header" *ngIf="!embeddedMode">
    <h3>
      <i class="material-icons mr-1">{{ documentInfo?.icon }}</i>
      {{ documentInfo?.name }}
    </h3>
  </div>

  <div [ngClass]="{ 'bg-content': !embeddedMode }">

    <app-component-notification></app-component-notification>

    <dx-toolbar>
      <dxi-item
        *ngIf="this.settings?.toolbar.isShowDateFilter"
        location="after"
        locateInMenu="auto"
        widget="dxDropDownButton"
        [options]="{
              useSelectMode: true,
              selectedItemKey: this.selectedDateRange,
              width: 150,
              displayExpr: 'name',
              keyExpr: 'value',
              items: dateRangeValues,
              onItemClick: onSelectDateRange
            }">
      </dxi-item>
      <dxi-item
        *ngFor="let button of this.buttons"
        location="{{button.location || 'after'}}"
        locateInMenu="auto"
        widget="{{button.widget || 'dxButton'}}"
        [options]="button.options">
      </dxi-item>
    </dx-toolbar>

    <dx-box class="box" direction="col" width="100%" height="500">

      <dxi-item class="box-item green" [ratio]="20">
        <dx-box crossAlign="stretch" direction="row" width="100%" height="100%">
          <!-- PieChart -->
          <dxi-item class="box-item lightgrey" [ratio]="1">
            <dx-pie-chart
              palette="bright"
              [dataSource]="chartData"
              (onPointClick)="onPieChartPointClick($event)"
              (onLegendClick)="onPieChartLegendClick($event)">
              <dxi-series argumentField="key" valueField="count">
                <dxo-label [visible]="true">
                  <dxo-connector [visible]="true" [width]="1"></dxo-connector>
                </dxo-label>
              </dxi-series>
              <dxo-legend horizontalAlignment="center" verticalAlignment="top"></dxo-legend>
              <dxo-export [enabled]="false"></dxo-export>
              <dxo-tooltip [enabled]="true" [zIndex]="9999"></dxo-tooltip>
            </dx-pie-chart>
          </dxi-item>

          <!-- BarChart -->
          <dxi-item class="box-item" [ratio]="1">
            <dx-chart palette="bright" [dataSource]="chartData" [rotated]="true">
              <dxi-series argumentField="key" valueField="count" name="Anzahl Vorlagen"></dxi-series>

              <dxo-common-series-settings argumentField="key" type="bar" valueField="count">
              </dxo-common-series-settings>
              <dxo-series-template nameField="key"></dxo-series-template>

              <dxo-common-axis-settings>
                <dxo-grid [visible]="true"></dxo-grid>
              </dxo-common-axis-settings>

              <dxo-argument-axis [allowDecimals]="false" [axisDivisionFactor]="60">
                <dxo-label>
                  <dxo-format type="decimal"></dxo-format>
                </dxo-label>
              </dxo-argument-axis>

              <dxo-legend verticalAlignment="top" horizontalAlignment="right"></dxo-legend>

              <dxo-export [enabled]="false"></dxo-export>
              <dxo-tooltip [enabled]="true" [zIndex]="9999"></dxo-tooltip>
            </dx-chart>
          </dxi-item>
        </dx-box>
      </dxi-item>
    </dx-box>
  </div>
</div>

