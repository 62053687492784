import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IRole } from '@interfaces/siam';

export function selectRoleId(doc: IRole): string {
  return doc.id;
}

export const RoleAdapter: EntityAdapter<IRole> = createEntityAdapter<IRole>();

export type RoleState = EntityState<IRole>
