import { Component, EventEmitter, forwardRef, Output, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { IDocument, IEventEmitter } from '@interfaces/siam';
import { LoggerService } from '@services/logger.service';
import {
  ComponentNotificationComponent
} from '@components/notify/component-notification/component-notification.component';
import {
  DocumentIncludeComponent
} from '../../../application/components/forms/subforms/document-include/document-include.component';

export class WorkFlowDialogSettings {
  title? = 'WorkFlow Dialog';
  fullScreen? = false;
  readonly? = false;
  createDocumentInputName?: string = null;
  createDecisionInputName?: string = null;
  parentDocumentId?: string = null;
}

@Component({
  selector: 'app-workflow-dialog',
  templateUrl: './workflow-dialog.component.html',
  styleUrls: ['./workflow-dialog.component.scss']
})
export class WorkflowDialogComponent {
  @ViewChild(forwardRef(() => DocumentIncludeComponent)) documentInclude: DocumentIncludeComponent;
  @ViewChild(forwardRef(() => ComponentNotificationComponent)) notifComponent: ComponentNotificationComponent;
  @ViewChild('workflowDialog', { static: true }) dialogEditor: DxPopupComponent;

  @Output() dialogResult = new EventEmitter<IEventEmitter<IDocument>>();

  dialogDocument: IDocument = null;
  parentDocumentId: string = null;
  dialogTitle: string;
  dialogFullscreen: boolean;
  dialogReadOnly: boolean;
  dialogVisible = false;
  createDocumentInputName: string;
  createDecisionInputName: string;

  constructor(private logger: LoggerService) {
  }

  public show = (document: IDocument, settings?: WorkFlowDialogSettings): void => {
    this.logger.debug('workflow-dialog.show() aufgerufen.');
    if (!document) {
      this.logger.error('Es wurde kein Formulardokument übergeben.');
      return;
    }
    this.dialogDocument = null;
    this.dialogDocument = document;
    this.logger.debug('übergebenes Dokument {@0}', this.dialogDocument);
    if (settings) {
      this.dialogReadOnly = settings.fullScreen || false;
      this.dialogTitle = settings.title ? settings.title : document.template.caption;
      this.dialogFullscreen = settings.fullScreen ? settings.fullScreen : false;
      this.createDocumentInputName = settings.createDocumentInputName ? settings.createDocumentInputName : null;
      this.createDecisionInputName = settings.createDecisionInputName ? settings.createDecisionInputName : null;
      this.parentDocumentId = settings.parentDocumentId ? settings.parentDocumentId : null;
    }
    this.dialogVisible = true;
  };

  dialogOk = (): void => {
    this.documentInclude.prepareForSave$().subscribe(doc => {
      this.dialogResult.emit({
        command: 'save',
        object: doc,
        variableCreateDocumentName: this.createDocumentInputName,
        variableCreateDocisionName: this.createDecisionInputName
      });
      this.dialogVisible = false;
      this.dialogDocument = null;
    });
  };

  dialogCancel = (): void => {
    this.logger.debug('dialogCancel() called, clearing popup-data');
    this.notifComponent.closeComponentNotifications();
    this.dialogVisible = false;
    this.dialogDocument = null;
  };
}
