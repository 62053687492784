import { Injectable } from '@angular/core';
import { DocumentApi } from '../api/document.api';
import { concatMap, from, map, Observable, of, switchMap, tap, toArray, zip } from 'rxjs';
import { IAttachment, IDocument, IPermissionTarget } from '@interfaces/siam';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { TemplateService } from './template.service';
import { NotifyService } from './notify.service';
import { LayoutEntryPlaceholderServ } from '@interfaces/fieldServer';
import { INewAgendaServer, TAgendaItemType, TreeViewItem } from '@interfaces/fieldAgenda';
import { clone, flattAgendaHierarchy, isHasProperty, processObservablesRecursively } from '@factories/helpers';
import { checkIfValidUUID, createPermissionTargetWithoutCompositeId, isCompositeId } from '@factories/document.factory';
import { DocumentService } from './document.service';
import { siamConst } from '@interfaces/siamConst';

@Injectable({
  providedIn: 'root'
})
export class AgendaServiceService {
  private topTemplateDocument: IDocument;
  private topTemplate: ITemplateServer;
  private patternAgendaItems: INewAgendaServer[] = [];
  private mapAgendaIds: Record<string, string> = {};
  private agendaTOPsToSave: IDocument[];
  private referencesToSave: { parentId: string; documentId: string }[];

  constructor(
    private documentApi: DocumentApi,
    private templateService: TemplateService,
    private documentService: DocumentService
  ) {}

  setReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    return this.documentApi.setReferences(items);
  }
  removeReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    return this.documentApi.removeReferences(items);
  }

  prepareAgendaToSave(agendaDocument: IDocument, documentClient: IDocument): Observable<IDocument> {
    return of(agendaDocument).pipe(
      switchMap(() => {
        const agendaField = agendaDocument.template.fields.find(field => field.type === 'documents');
        if (agendaField) {
          const fieldLayout = agendaDocument.template.layouts[0].entries.find(
            entry => (entry as LayoutEntryPlaceholderServ)?.name === agendaField.name
          );
          if ((fieldLayout as LayoutEntryPlaceholderServ)?.additionalData?.isMeetingMinutes) {
            this.agendaTOPsToSave = [];
            this.referencesToSave = [];
            return of(documentClient);
          } else {
            const value = documentClient.fields[agendaField.name].value as TreeViewItem[];
            const plainArray = flattAgendaHierarchy(value);
            return this.fetchAgendaItemsDataToSave(plainArray, documentClient).pipe(map(() => documentClient));
          }
        }
        return of(documentClient);
      })
    );
  }

  fetchAgendaItemsDataToSave(items: TreeViewItem[], agendaDocument: IDocument): Observable<unknown> {
    return of(agendaDocument).pipe(
      switchMap(() => {
        this.getTOPDocumentsToSave(items, agendaDocument);
        return this.processObservablesSaveDocumentRecursively(this.agendaTOPsToSave);
      }),
      switchMap(() => this.setReferences(this.referencesToSave))
    );
  }

  createPatternFromDocument(agendaDocument: IDocument, agendaItemsClient: TreeViewItem[]): Observable<unknown> {
    return of(agendaDocument).pipe(
      switchMap(document => zip(this.createTOPDocument(agendaDocument), of(document))),
      map(response => response[1]),
      switchMap(document => {
        let agendaItems: TreeViewItem[] = [];

        agendaItems = clone(agendaItemsClient).filter(t => t.type === 'top' || t.type === 'pause');

        const observables: Observable<IDocument>[] = [];
        this.mapAgendaIds = {};
        this.patternAgendaItems = [];
        for (const item of agendaItems) {
          const observable = this.createTOPDocument(agendaDocument).pipe(
            switchMap(newTOP => from(this.documentService.createFromParent(newTOP, item.document))),
            switchMap(doc => this.documentService.save(doc, null, false)),
            tap((savedTopDocument): void => {
              //add TOP
              this.mapAgendaIds[item.id] = savedTopDocument.id;
              const parentIdNew = this.mapAgendaIds[item.parentId];
              this.addAgendaEntry(savedTopDocument.id, 'top', parentIdNew);
            })
          );
          observables.push(observable);
        }

        return zip(processObservablesRecursively(observables), of(document));
      }),
      map(tuple => ({ savedDocument: tuple[0], document: tuple[1] })),
      switchMap(response => {
        return zip(this.documentService.createDocumentByTemplateId(agendaDocument.template), of(response.document));
      }),
      map(tuple => ({ newDocumentTemplate: tuple[0], document: tuple[1] })),
      switchMap(response =>
        zip(
          from(this.documentService.createFromParent(response.newDocumentTemplate, agendaDocument)),
          of(response.document)
        )
      ),
      switchMap(result => {
        const newDocument = result[0];
        newDocument.fields[siamConst.agendaField] = { value: this.patternAgendaItems, type: 'documents' };
        newDocument.tags.push(siamConst.patternTag);
        newDocument.tags.push(`${siamConst.patternTemplateIdTag}:${agendaDocument.template.id}`);
        return this.documentService.save(newDocument);
      }),
      switchMap(savedDocument => {
        const references = clone(this.patternAgendaItems).map(
          item =>
            ({ parentId: savedDocument.id, documentId: item.documentId } as { parentId: string; documentId: string })
        );
        return this.documentService.setReferences(references);
      })
    );
  }

  createDocumentFromPattern(agendaDocument: IDocument): Observable<IDocument> {
    return of(agendaDocument).pipe(
      switchMap(document => {
        const originalItems = document.fields[siamConst.agendaField].value as INewAgendaServer[];
        const itemIds = originalItems.map(item => item.documentId);
        const uniqueIds = Array.from(new Set(itemIds));
        return this.documentService.getDocumentsByIds(uniqueIds);
      }),
      switchMap(documents => {
        const agendaItems: TreeViewItem[] = [];
        const originalItems = agendaDocument.fields[siamConst.agendaField].value as INewAgendaServer[];
        for (const item of originalItems) {
          const document = documents.find(d => d.id === item.documentId);
          if (document) {
            agendaItems.push({ id: document.id, document, parentId: item.parentDocumentId });
          }
        }
        const observables: Observable<IDocument>[] = [];
        this.patternAgendaItems = [];
        for (const item of agendaItems) {
          const observable = this.createTOPDocument(agendaDocument).pipe(
            switchMap(newTOP => from(this.documentService.createFromParent(newTOP, item.document))),
            switchMap(doc => this.documentService.save(doc, null, false)),
            tap((savedTopDocument): void => {
              //add TOP
              this.mapAgendaIds[item.id] = savedTopDocument.id;
              const parentIdNew = this.mapAgendaIds[item.parentId];
              this.addAgendaEntry(savedTopDocument.id, 'top', parentIdNew);
            })
          );
          observables.push(observable);
        }

        return processObservablesRecursively(observables);
      }),
      switchMap(() => {
        return this.documentService.createDocumentByTemplateId(agendaDocument.template);
      }),
      switchMap(newDocumentTemplate =>
        from(this.documentService.createFromParent(newDocumentTemplate, agendaDocument))
      ),
      switchMap(newDocument => {
        newDocument.fields[siamConst.agendaField] = { value: this.patternAgendaItems, type: 'documents' };
        return this.documentService.save(newDocument);
      }),
      switchMap(savedDocument => {
        const references = clone(this.patternAgendaItems).map(
          item =>
            ({ parentId: savedDocument.id, documentId: item.documentId } as { parentId: string; documentId: string })
        );
        return zip(this.documentService.setReferences(references), of(savedDocument));
      }),
      map(data => data[1])
    );
  }

  mapAgendaItemsToServer(currentDocument: IDocument, document: IDocument): void {
    const agendaFields = currentDocument.template.fields.filter(field => field.type === 'documents');
    if (agendaFields?.length) {
      for (const agendaField of agendaFields) {
        const value = document.fields[agendaField.name].value as TreeViewItem[];
        const plainArray = flattAgendaHierarchy(value);

        if (plainArray?.length) {
          document.fields[agendaField.name].value = plainArray.map(item => this.mapAgendaItemClientToServer(item));
        }
      }
    }
  }

  mapAgendaItemClientToServer(item: TreeViewItem): INewAgendaServer {
    return {
      position: item.position,
      documentId: item.id,
      parentDocumentId: item.parentId,
      type: item.type,
      duration: item.duration,
      startTime: item.startTime,
      endTime: item.endTime,
      speakers:
        item.speakers?.map(speaker => {
          if (checkIfValidUUID(speaker)) {
            return { type: 'user', targetId: speaker } as IPermissionTarget;
          } else if (isCompositeId(speaker)) {
            const permissionTarget = createPermissionTargetWithoutCompositeId(speaker);
            return {
              targetId: permissionTarget.targetId,
              type: permissionTarget.type
            } as IPermissionTarget;
          } else {
            return speaker;
          }
        }) || [],
      isActive: isHasProperty(item, 'isActive') ? item.isActive : true
    } as INewAgendaServer;
  }

  private createDocumentByTemplateId(template: ITemplateServer): Observable<IDocument> {
    return this.documentApi.createDocumentByTemplateId(template.id).pipe(
      map(document => {
        document.template = template;
        return document;
      })
    );
  }

  private getTOPTemplate(agendaDocument: IDocument): Observable<ITemplateServer> {
    if (this.topTemplate) {
      return of(clone(this.topTemplate));
    }
    return this.templateService.getActiveTemplates('create', ['app:document-type:top']).pipe(
      map(templates => {
        if (templates && !templates.length) {
          NotifyService.global.error(
            'Es wurde keine Vorlage für die Erstellung eines TOP-Dokuments gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
          );
        }
        let topTemplateAgenda: ITemplateServer = null;
        const agendaField = agendaDocument?.template?.fields?.find(field => field.type === 'documents');

        const agendaFieldSettings = agendaDocument?.template?.layouts[0]?.entries?.find(
          entry => entry.type === 'placeholder' && (entry as LayoutEntryPlaceholderServ).name === agendaField.name
        ) as LayoutEntryPlaceholderServ;
        const topId = agendaFieldSettings?.additionalData?.agendaSettings?.topSubTemplates?.templateTopId;
        if (topId) {
          topTemplateAgenda = templates.find(template => template?.id === topId);
        } else {
          topTemplateAgenda = templates.find(template =>
            template?.name?.toLowerCase().includes(agendaDocument?.template?.name?.toLocaleLowerCase())
          );
        }
        this.topTemplate = topTemplateAgenda || templates[0];
        return this.topTemplate;
      })
    );
  }
  private createTOPDocument(agendaDocument: IDocument): Observable<IDocument> {
    if (this.topTemplateDocument) {
      return of(clone(this.topTemplateDocument));
    }
    return this.getTOPTemplate(agendaDocument).pipe(
      switchMap(template => this.createDocumentByTemplateId(template)),
      tap(document => {
        this.topTemplateDocument = document;
      })
    );
  }
  private addAgendaEntry = (documentId: string, type: TAgendaItemType, parentId: string = null): void => {
    this.patternAgendaItems.push({ type, documentId, parentDocumentId: parentId });
  };

  private getTOPDocumentsToSave(items: TreeViewItem[], agendaDocument: IDocument): void {
    this.agendaTOPsToSave = [];
    this.referencesToSave = [];
    items
      ?.filter(item => item.document && item.type !== 'submission')
      ?.forEach(item => {
        const documentHasChanged = Object.keys(item.document.fields).map(key => {
          const field = item.document.fields[key];
          // in parsed document "parsedDocument" field might be does not exists, because it was filtered out
          // accordingly to the effective permissions

          if (!isHasProperty(item.originalDocument.fields, key)) {
            return 'hasChange';
          }

          let currentField = item.document.fields[key].value as unknown;
          let sourceField = item.originalDocument.fields[key].value as unknown;
          switch (field.valueType) {
            case 'datetime':
            case 'timeStamp':
              currentField = new Date(currentField as string).toISOString();
              sourceField = new Date(sourceField as string).toISOString();
              break;
            case 'attachments':
              return this.documentService.isAttachmentChanged(
                currentField as IAttachment[],
                sourceField as IAttachment[]
              )
                ? 'hasChange'
                : 'noChange';
            default:
              break;
          }
          currentField = this.documentService.removeCompositeId(currentField as IPermissionTarget);
          sourceField = this.documentService.removeCompositeId(sourceField as IPermissionTarget);
          if (currentField === null && sourceField === null) {
            return 'noChange';
          }
          return JSON.stringify(currentField) === JSON.stringify(sourceField) ? 'noChange' : 'hasChange';
        });
        if (documentHasChanged.includes('hasChange')) {
          this.agendaTOPsToSave.push(item.document);
        }
        switch (item.type) {
          case 'top':
          case 'pause':
            if (!this.documentService.isDocumentReferenced(item.document, agendaDocument)) {
              this.referencesToSave.push({ documentId: item.document.id, parentId: agendaDocument.id });
            }
            break;
          case 'submission':
          case 'top-protocol':
            {
              if (!this.documentService.isDocumentReferenced(item.document, agendaDocument)) {
                this.referencesToSave.push({ documentId: item.document.id, parentId: agendaDocument.id });
              }
              const parentItem = items.find(parent => parent.id === item.parentId);
              if (parentItem && !this.documentService.isDocumentReferenced(item.document, parentItem.document)) {
                this.referencesToSave.push({ documentId: item.document.id, parentId: parentItem.document.id });
              }
            }
            break;
          case 'task':
            {
              if (!this.documentService.isDocumentReferenced(item.document, agendaDocument)) {
                this.referencesToSave.push({ documentId: item.document.id, parentId: agendaDocument.id });
              }
              const parentSubmissionItem = items.find(parent => parent.id === item.parentId);
              if (
                parentSubmissionItem &&
                !this.documentService.isDocumentReferenced(item.document, parentSubmissionItem.document)
              ) {
                this.referencesToSave.push({
                  documentId: item.document.id,
                  parentId: parentSubmissionItem.document.id
                });
              }
            }
            break;

          default:
            break;
        }
      });
  }

  private processObservablesSaveDocumentRecursively(observables: IDocument[]): Observable<IDocument[]> {
    if (!observables || observables?.length === 0) {
      // If there are no more observables, return an observable of an empty array
      return of([] as IDocument[]);
    } else {
      // Take the first observable from the array
      const document = observables[0];
      const firstObservable = this.documentService.save(document);
      // Recurse with the rest of the observables in the array
      const remainingDocuments = observables.slice(1);
      // Use concatMap to process the first observable and concatenate it with the results of the recursive call
      return firstObservable.pipe(
        concatMap(response => {
          // Process the response if necessary
          // For example, you can map the response to extract specific data
          // response = response.someProperty;
          // Recurse with the rest of the observables and concatenate the current response to the array
          return this.processObservablesSaveDocumentRecursively(remainingDocuments).pipe(
            // Use toArray to collect the responses into an array and complete the observable
            toArray(),
            // Concatenate the current response to the array of responses
            concatMap(responses => of(([...responses, response] as IDocument[]).flat()))
          );
        })
      );
    }
  }
}
