<div class="wrapper">
  <header class="header header-bg">
    <app-header-container [applicationMode]="applicationMode"></app-header-container>
  </header>

  <div id="main-view" class="main-wrapper homepage-bg">
    <app-navigation-pane *ngIf="naviDataSource" [naviDataSource]="naviDataSource" [applicationMode]="applicationMode"></app-navigation-pane>
    <main class="main-content">
      <app-history-api></app-history-api>
      <!--  <app-breadcrumb></app-breadcrumb> -->
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
