import { Pipe, PipeTransform } from '@angular/core';
import { isWorkflowFormular } from '@factories/workflow.factory';
import { IEdgeClient } from '@interfaces/edgeClient';

@Pipe({
  name: 'isWorkflowFormular'
})
export class IsWorkflowFormularPipe implements PipeTransform {

  transform(edge: IEdgeClient): boolean {
    return isWorkflowFormular(edge);
  }

}
