import { Component, Input,Output, OnDestroy, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IDocument } from '@interfaces/siam';
import { AudioRecordService } from '@services/audio-record.service';

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Component({
  selector: 'app-audio-record',
  templateUrl: './audio-record.component.html',
  styleUrls: ['./audio-record.component.scss']
})
export class AudioRecordComponent implements OnDestroy {
  @Input() document: IDocument;
  @Output() isRecordingInProgress = new EventEmitter<boolean>();

  isRecording = false;
  recordedTime = '00:00';
  blobUrl: SafeResourceUrl;
  teste: RecordedAudioOutput;

  constructor(private audioRecordingService: AudioRecordService, private sanitizer: DomSanitizer) {
    this.audioRecordingService.recordingFailed().subscribe(() => (this.isRecording = false));
    this.audioRecordingService.getRecordedTime().subscribe(time => (this.recordedTime = time));
    this.audioRecordingService.getRecordedBlob().subscribe(data => {
      this.teste = data;
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
    });
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.isRecordingInProgress.emit(true);
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.isRecordingInProgress.emit(false);
      this.recordedTime = '00:00';
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording(this.document.template.caption);
      this.isRecording = false;
      this.isRecordingInProgress.emit(false);
      this.recordedTime = '00:00';
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  download(): void {
    const url = window.URL.createObjectURL(this.teste.blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = this.teste.title;
    link.click();
  }
}
