import { Inject, Injectable } from '@angular/core';
import { NumberGroup } from '@interfaces/numberGroup';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class NumberGroupsService {
  private apiBaseUrl: string;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    @Inject('BASE_URL') private baseUrl: string,
  ) {
    this.apiBaseUrl = `${this.baseUrl}api/numbers`;
    logger.info('Number Lists Service started');
  }

  /**
   * Get list of number groups
   */
  getNumberGroups(): Observable<NumberGroup[]> {
    return this.http.get<NumberGroup[]>(`${this.apiBaseUrl}/`).pipe(
      tap({
        next: groups => {
          this.logger.debug('geladene Nummernkreise: {@groups}', groups);
        },
        error: error => {
          this.logger.debug('Fehler beim get list of number groups: {@error}', error);
        }
      }),
      map(groups => groups.map(numberGroup => new NumberGroup(numberGroup)))
    );
  }

  /**
   * Get the next number of the group named
   *
   * @param name
   * @param nextNumber
   */
  reset(name: string, nextNumber: number): Observable<string> {
    const headers = new HttpHeaders({
      accept: 'text/plain',
    });
    const params = new HttpParams().set('next-number', nextNumber.toString());
    // workaround for reponseType: https://github.com/angular/angular/issues/18672
    const options = {
      headers,
      responseType: 'text' as 'json',
      params,
    };

    return this.http.post<string>(`${this.apiBaseUrl}/reset/${name}`, null, options).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Ermitteln der nächsten Nummer: {@error}', error);
        }
      })
    );
  }

  /**
   * Get the next number of the group named
   *
   * @param name as string
   */
  getNext(name: string): Observable<string> {
    const headers = new HttpHeaders({
      accept: 'text/plain',
    });
    // workaround for reponseType: https://github.com/angular/angular/issues/18672
    const options = {
      headers,
      responseType: 'text' as 'json',
    };

    return this.http.post<string>(`${this.apiBaseUrl}/next/${name}`, null, options).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Ermitteln der nächsten Nummer: {@error}', error);
        }
      })
    );
  }

  /**
   * peek the next number of the group named
   *
   * @param name as string
   */

  peekNext(name: string): Observable<string> {
    const headers = new HttpHeaders({
      accept: 'text/plain',
    });
    // workaround for reponseType: https://github.com/angular/angular/issues/18672
    const options = {
      headers,
      responseType: 'text' as 'json',
    };

    return this.http.post<string>(`${this.apiBaseUrl}/peek/${name}`, null, options).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Ermitteln der nächsten Nummer: {@error}', error);
        }
      })
    );
  }

  /**
   * Save list of number from api
   */
  saveNumberGroup(numberGroup: NumberGroup): Observable<NumberGroup> {
    const headers = new HttpHeaders({
      yccept: 'text/json',
    });
    return this.http.post<NumberGroup>(`${this.apiBaseUrl}`, numberGroup, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Speichern des Nummerkreises: {@error}', error);
        }
      }),
      map(() => new NumberGroup(numberGroup))
    );
  }

  /**
   * delete die nummer grp from db
   *
   * @param name
   */
  deleteNumberGroup(name: string): Observable<NumberGroup> {
    return this.http.delete<NumberGroup>(`${this.apiBaseUrl}/${name}`).pipe(
      tap({
        next: () => {
          this.logger.info('DeleteNumberGroup: Nummerkreis wird gelöscht = {@name}', name);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des Nummerkreis: {@error}', error);
        }
      })
    );
  }
}
