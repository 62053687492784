<dx-number-box
  stylingMode="filled"
  [(value)]="value"
  [format]="field.editorOptions.format"
  [readOnly]="!isEditing"
  (onValueChanged)="valueChanged($event)">
  <dx-validator
    validationGroup="{{ validationGroupId }}"
    [validationRules]="field.editorOptions.validationRules"></dx-validator>
</dx-number-box>
