<dx-text-box
  stylingMode="filled"
  valueChangeEvent="change"
  [(value)]="value"
  [readOnly]="!isEditing"
  (onValueChanged)="valueChanged($event)">
  <dx-validator
    validationGroup="{{ validationGroupId }}"
    [validationRules]="field.editorOptions.validationRules"></dx-validator>
</dx-text-box>
