import { Injectable } from '@angular/core';
import { ICategory } from '@interfaces/siam';
import { ListsService } from './lists.service';
import { SiamList } from '@interfaces/siamList';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  globalTagsList = new BehaviorSubject<SiamList>(null);
  getGlobalTagsList = this.globalTagsList.asObservable();

  constructor(private listsService: ListsService) {}

  siamTags(): ICategory[] {
    const list = this.globalTagsList.getValue();
    let result: ICategory[] = [];
    if (list) {
      result = list.entries.map(entry => this.listsService.getCategoryEntryData(entry));
    }
    return result;
  }

  setGlobalTagsList(tags: SiamList): void {
    this.globalTagsList.next(tags);
  }

  getTypeLabel(tagKey: string): string {
    const tag = this.findTag(tagKey);
    return tag?.label || '';
  }

  getTypeName(tagKey: string): string {
    let result = '';
    const tag = this.findTag(tagKey);
    const key = tag?.tag || '';
    if (key) {
      result = key.substring(key.lastIndexOf(':') + 1) || '';
    }
    return result;
  }

  private findTag(key: string): ICategory | undefined {
    const tags = this.siamTags();
    let result: ICategory;
    if (Array.isArray(tags)) {
      result = tags.find(tag => tag.tag === key);
    }
    return result;
  }
}
