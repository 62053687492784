<dx-popup
  #userSettings
  [(visible)]="showHelp"
  [minWidth]="750"
  [fullScreen]="false"
  [dragEnabled]="true"
  [showCloseButton]="false"
  [showTitle]="false"
  [shading]="true"
  height="auto"
  shadingColor="rgba(0, 0, 50, 0.5)"
  width="50vw"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: {
        type: 'normal',
        icon: 'material-icons md-24 help',
        elementAttr: {
          class: 'button-icon'
        }
      }
    },
    {
      text: 'Hilfe',
      location: 'before',
      toolbar: 'top'
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      options: {
        type: 'normal',
        icon: 'close',
        hint: 'Schließen'
      },
      onClick: closeHelp
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <img class="logo" src="../../../../assets/img/ventuno-logo.svg" alt="ventuno21 Logo" />
    ventuno GmbH<br />
    Georgswall 5<br />
    30159 Hannover

    <div class="kontakt">
      <span class="kontakt__icon"> <i class="material-icons">phone_in_talk</i>Support Telefon: </span>
      <span>
        <strong>{{ homePageSetting ? homePageSetting.supportPhoneNumber : '+49 511 763536-21' }}</strong>
      </span>
      <span class="kontakt__icon"> <i class="material-icons">alternate_email</i>Support E-Mail: </span>
      <span>
        <strong>{{ homePageSetting ? homePageSetting.supportEmail : 'info@agilicision.de' }} </strong>
      </span>
    </div>

    <hr />

    <h4 class="mt-2">Anwendungsinformationen</h4>
    <div class="grid-list" *ngIf="info$ | async as info">
      <div>Produkt Version:</div>
      <div>
        <strong class="pr-2">{{ info.product.version }} {{ info.product.codename }}</strong>
        <span>({{ info.product.timestamp }})</span>
      </div>
      <div>Server Version:</div>
      <div>
        <strong class="pr-2">{{ info.server.version }} {{ info.server.codename }}</strong>
        <span>({{ info.server.timestamp }})</span>
      </div>
      <div>Betriebsumgebung:</div>
      <div>
        <strong class="pr-2">{{ info.environment }}</strong>
      </div>
    </div>

    <hr />

    <h4 class="mt-2">Tastenkombinationen</h4>
    <div class="grid-list">
      <div>Alle Gruppen öffnen:</div>
      <div><strong>Strg + &#8595;</strong></div>
      <div>Alle Gruppen schließen:</div>
      <div><strong>Strg + &#8593;</strong></div>
      <div>Dokumenteigenschaften:</div>
      <div><strong>Alt + Enter</strong></div>
      <div>Feld erstellen:</div>
      <div><strong>Strg + Alt + F</strong></div>
      <div>Feldgruppe erstellen:</div>
      <div><strong>Strg + Alt + G</strong></div>
      <div>Feld/-gruppe bestätigen:</div>
      <div><strong>Strg + Alt + Enter</strong></div>
      <div>Listeneintrag hinzufügen:</div>
      <div><strong>Strg + Alt + A</strong></div>
      <div>Pause:</div>
      <div><strong>Strg + Alt + S</strong></div>
      <div>Umschalten in Lesemodus:</div>
      <div><strong>Strg + B</strong></div>
    </div>
  </div>
</dx-popup>
