import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SecurityContext,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DxFormComponent, DxPopoverComponent } from 'devextreme-angular';
import { TemplateClient } from '@interfaces/templateClient';
import {
  CheckBoxGroup,
  Container,
  DxSlider,
  Empty,
  FieldClient,
  fieldConstant,
  FieldTypes,
  FileUploader,
  Group,
  PermissionTarget,
  RichTextEditor,
  SelectBox,
  SignatureField,
  TextBox
} from '@interfaces/fieldClient';
import { NotifyService } from '@services/notify.service';
import { UserService } from '@services/user.service';
import { catchError, filter, first, map, reduce, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, concat, from, lastValueFrom, Observable, of, Subject, throwError, zip } from 'rxjs';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import {
  BaseLayoutEntryServ,
  FieldServer,
  IFieldDataSourceList,
  LayoutEntryPlaceholderServ,
  LayoutFieldEntryServ,
  LayoutGroupEntryServ,
  LayoutSpacerEntryServ
} from '@interfaces/fieldServer';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import ValidationEngine from 'devextreme/ui/validation_engine';
import {
  IAgenda,
  IAgendaServer,
  IAttachment,
  ICustomDialog,
  IDocument,
  IDocumentField,
  IDocumentFields,
  IEventEmitter,
  IEventEmitterFieldsDrag,
  IEventEmitterSignature,
  IEventEmitterValueChaned,
  IHistoryField,
  IPermissionTarget,
  IResultDiagram,
  IRole,
  ISelectableNames,
  ITask,
  IUploadAttachment,
  IUser,
  IUserRoleEntry,
  IWorkflowDocument,
  SiamListItem,
  TEditMode,
  TSelectableNames
} from '@interfaces/siam';
import * as Factory from '@factories/document.factory';
import { RoleService } from '@services/roles.service';
import { LoggerService } from '@services/logger.service';
import { IDxFileUploaderComponent, IDxItemValidationCallback } from '@interfaces/devextreme';
import {
  createContainerField,
  createField,
  DOCUMENT_TAG_LABEL_LOCATION,
  mapClientToServerDoc,
  mapServerToClientDoc
} from '@factories/template.factory';
import { TemplateService } from '@services/template.service';
import { DocumentService } from '@services/document.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from 'src/app/tokens/window.token';
import * as uuid from 'uuid';
import { sortBy } from 'lodash';
import { FileDownloadLinkPipe } from '@pipes/file-download-link.pipe';
import Sval from 'sval';
import { ValueChangedEvent as CheckBoxValueChangedEvent } from 'devextreme/ui/check_box';
import { ValueChangedEvent as SwitchValueChangedEvent } from 'devextreme/ui/switch';
import dxSortable, { AddEvent, DragStartEvent, Orientation, ReorderEvent } from 'devextreme/ui/sortable';
import { OptionChangedEvent } from 'devextreme/ui/tag_box';
import {
  clone,
  dateToIsoString,
  getStringLength,
  isFastDeepEqual,
  isHasProperty,
  isMobileDevice,
  isUndefinedValue
} from '@factories/helpers';
import { DragTemplateData } from 'devextreme/ui/draggable';
import { ListsService } from '@services/lists.service';
import { LoadPanelService } from '@services/load-panel.service';
import { loadMessages } from 'devextreme/localization';
import { custom } from 'devextreme/ui/dialog';
import { RowDraggingReorderEvent, RowRemovingEvent } from 'devextreme/ui/data_grid';
import { ContentReadyEvent as ButtonContentReadyEvent } from 'devextreme/ui/button';
import {
  ContentReadyEvent as HtmlEditorContentReadyEvent,
  InitializedEvent as HtmlEditorInitializedEvent,
  ValueChangedEvent as HTMLEditorValueChangedEvent
} from 'devextreme/ui/html_editor';
import {
  ContentReadyEvent as FileUploaderContentReadyEvent,
  ValueChangedEvent as FileUploaderValueChangedEvent
} from 'devextreme/ui/file_uploader';
import { ContentReadyEvent as FormContentReadyEvent, FieldDataChangedEvent } from 'devextreme/ui/form';
import { ValueChangedEvent as SliderValueChangedEvent } from 'devextreme/ui/slider';
import { IReachTextBoxEditorOptions, ISelectBoxEditorOptions, ITagBoxEditorOptions } from '@interfaces/fields';
import { Clipboard } from '@angular/cdk/clipboard';
import { IEventEmitterAgenda, TreeViewItem } from '@interfaces/fieldAgenda';
import { siamConst } from '@interfaces/siamConst';

const FIELDNAME_AGENDALIST = '--siam-agendaitems';
const FIELDNAME_AGENDASPEAKERS = 'allspeakers';
const FIELDNAME_AGENDAENDDATE = 'enddate';

type TDraggableType = 'field' | 'spacer' | 'group' | 'container';

interface RootEntries {
  containers: Record<string, Group>;
  entries: FieldTypes[];
}

interface IFieldElement {
  index: number;
  entry: FieldTypes;
}

interface IAgendaData {
  list: IAgendaServer[];
  speakers: IPermissionTarget[];
  endDate: string;
}

@Component({
  selector: 'app-document-include',
  templateUrl: './document-include.component.html',
  styleUrls: ['./document-include.component.scss']
})
export class DocumentIncludeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('formInclude') formInclude: DxFormComponent;
  @ViewChild('popOverUserTagBox') popOverUserTagBox: DxPopoverComponent;
  @Input() currentDocument: IDocument;
  @Input() parentDocumentId: string;
  @Input() isPopupDocument = false;
  @Input() templateLayout = 'default';
  @Input() editMode: TEditMode = 'ReadOnly';
  @Input() draggableAllow = false;
  @Input() previewMode = false;
  @Input() ballotDocuments: IWorkflowDocument[];
  @Input() workflowDocument: IWorkflowDocument;
  @Input() childElement = false;
  @Output() eventResult = new EventEmitter<IEventEmitter<ITask>>();
  @Output() documentIncludeEmitter = new EventEmitter<IEventEmitterFieldsDrag<FieldTypes>>();

  // TODO: drag&drop überarbeiten und dieses Objekt entfernen

  agendaData: IAgenda[] = null;
  agendaStartDateChanged$ = new BehaviorSubject<string>(null);
  formDataChanged$ = new BehaviorSubject<IEventEmitterValueChaned<string>>(null);
  allActiveUsers: IUser[];
  allAvtiveRoles: IRole[];
  attachmentChanged$ = new BehaviorSubject<boolean>(null);
  avatarPopup: HTMLElement;
  decisionDocument: IDocument = null;
  expandButtonText = 'Alles aufklappen';
  expandButtonIcon = 'expand';
  isAgenda = false;
  isDecision = false;
  isHasSignature = false;
  isMeetingMinutes = false;
  isDebugPopup: boolean;
  isJsonExpanded = false;
  isJsonPreview = false;
  isFullscreen = false;
  isJsonRestoreExpanded = true;
  jsonPreviouslyOpenKeys = {};
  formData: Record<string, unknown> = {};
  formItems: FieldTypes[] = [];
  labelLocation: string;
  mapShowSignatureDialog = new Map<string, boolean>();
  placeHolderField: FieldClient = null;
  popoverElement: HTMLElement;
  popoverContent: string;
  rolesDataSource: DataSource;
  rolesDataStore: ArrayStore<IRole, string>;
  selectedDraggableItem: FieldTypes = null;
  templateClient: TemplateClient;
  usersAndRolesArray: IUserRoleEntry[] = [];
  usersAndRolesDataSource: DataSource;
  usersAndRolesDataStore: ArrayStore<IUser | IRole, string>;
  usersDataSource: DataSource;
  usersDataStore: ArrayStore<IUser, string>;
  validationGroupId: string = uuid.v4();

  temporary: string[] = [];

  #destroyable$ = new Subject<void>();
  #interpreter = new Sval({
    ecmaVer: 6, // ECMA Version of the code (5 | 6 | 7 | 8 | 9 | 10 | 2015 | 2016 | 2017 | 2018 | 2019)
    sandBox: true // Whether the code runs in a sandbox
  });
  #mapFieldNames = new Map<string, DataSource>();
  #mapFieldList = new Map<string, DataSource>();
  #mapFieldVisible = new Map<string, boolean>();
  #mapFieldDraggable = new Map<string, IFieldElement>();
  #mapFieldDraggableClickEvent = new Map<string, () => void>();
  #mapClassField: Record<string, IFieldElement> = {};
  #unlistener: Array<() => void> = [];
  #unlistenMouseOut: Array<() => void> = [];

  constructor(
    private documentService: DocumentService,
    private listsService: ListsService,
    private renderer: Renderer2,
    private elem: ElementRef<HTMLElement>,
    private loadPanelService: LoadPanelService,
    private logger: LoggerService,
    private roleService: RoleService,
    private userService: UserService,
    private templateService: TemplateService,
    private sanitizer: DomSanitizer,
    private fileDownloadLink: FileDownloadLinkPipe,
    private clipboard: Clipboard,
    @Inject(WINDOW) private window: Window
  ) {
    this.usersDataStore = new ArrayStore({ key: 'id', data: [] as IUser[] });
    this.usersDataSource = new DataSource({ store: this.usersDataStore });
    this.rolesDataStore = new ArrayStore({ key: 'id', data: [] as IRole[] });
    this.rolesDataSource = new DataSource({ store: this.rolesDataStore });
    this.usersAndRolesDataStore = new ArrayStore({ key: 'id', data: [] as (IUser | IRole)[] });
    this.usersAndRolesDataSource = new DataSource({ store: this.usersAndRolesDataStore });
    loadMessages({
      de: {
        /* eslint-disable */
        'dxHtmlEditor-dialogImageCaption': 'Bild einfügen',
        'dxHtmlEditor-dialogImageAddButton': 'Einfügen',
        'dxHtmlEditor-insertRowAbove': 'Zeile oberhalb einfügen',
        'dxHtmlEditor-insertRowBelow': 'Zeile unterhalb einfügen'
        /* eslint-enable */
      }
    });
  }

  @HostListener('document:keydown.control.arrowup')
  closeAllGroups(): void {
    const allGroups = this.elem.nativeElement.querySelectorAll('.group-item') as NodeList;
    allGroups.forEach(group => {
      this.renderer.addClass(group, 'close-group');
    });
  }

  @HostListener('document:keydown.control.arrowdown')
  openAllGroups(): void {
    const allGroups = this.elem.nativeElement.querySelectorAll('.group-item') as NodeList;
    allGroups.forEach(group => {
      this.renderer.removeClass(group, 'close-group');
    });
  }

  @HostListener('document:keydown.Alt.enter')
  openDebugPopup(): void {
    this.isDebugPopup = true;
  }

  async ngOnInit(): Promise<void> {
    this.logger.debug('=== document-base-component started.');
    this.allActiveUsers = await lastValueFrom(this.userService.getAllActiveUsers());
    this.allAvtiveRoles = await lastValueFrom(this.roleService.getAllRoles());
  }

  ngOnDestroy(): void {
    this.currentDocument = null;
    this.#destroyable$.next();
    this.#destroyable$.complete();
    if (this.#unlistener.length) {
      this.#unlistener.forEach(fn => {
        if (fn) {
          fn();
        }
      });
    }
    if (this.#unlistenMouseOut.length) {
      this.#unlistenMouseOut.forEach(fn => {
        if (fn) {
          fn();
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.logger.debug('=== document-include: CHANGES');

    if (changes.currentDocument?.currentValue || changes.editMode?.currentValue) {
      this.initDocument();
    }
    if (changes.workflowDocument?.currentValue) {
      this.checkSignature();
    }
  }

  initDocument(): void {
    if (!this.currentDocument?.template) {
      return;
    }
    this.isAgenda = this.documentService.isAgenda(this.currentDocument);
    this.isMeetingMinutes = this.documentService.isMeetingMinutes(this.currentDocument);
    this.isDecision = this.documentService.isDecision(this.currentDocument);

    this.templateClient = mapServerToClientDoc(this.currentDocument.template);

    // get label location from tag
    const tagType = DOCUMENT_TAG_LABEL_LOCATION;
    this.labelLocation =
      this.currentDocument?.template?.tags
        .filter(tag => tag.startsWith(tagType))
        .map(tag => tag.split(new RegExp(`^${tagType}`))[1])[0] || 'left';

    // -- im Template definierte Feldinhalte aus Dokument laden
    this.loadPanelService.show('Formular wird vorbereitet...');
    this.fillUsersAndRoles()
      .pipe(
        switchMap(() => {
          const templateId = this.currentDocument.fields['--approval_templateId']?.value as string;
          if (this.isDecision && templateId) {
            return this.templateService.getTemplate(templateId).pipe(catchError(() => of(null)));
          }
          return of(null);
        }),
        tap(dialogTemplate => {
          if (dialogTemplate) {
            this.decisionDocument = this.documentService.createSubForm(dialogTemplate, this.currentDocument.fields);
          }
        }),
        switchMap(() => this.getFormData()),
        switchMap(formData => {
          this.formData = formData;
          this.agendaData = Factory.copy(this.currentDocument.fields[FIELDNAME_AGENDALIST]?.value as IAgenda[]) || null;
          return this.getFormItems(this.currentDocument.template);
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: (formItems): void => {
          this.formItems = formItems;
          this.loadPanelService.hide();
          this.formInclude?.instance?.repaint();
        },
        error: (error: string | Error): void => {
          this.loadPanelService.hide();
          if (error instanceof Error) {
            this.logger.error(error.message);
            NotifyService.component.error(`Fehler beim Generieren des Formulars: ${error.message}`);
          } else {
            this.logger.error(error);
            NotifyService.component.error(`Fehler beim Generieren des Formulars: ${error}`);
          }
        },
        complete: (): void => {
          this.loadPanelService.hide();
        }
      });
  }

  fieldDataChanged(e?: FieldDataChangedEvent): void {
    if (this.previewMode) {
      return;
    }
    if (e?.dataField) {
      this.formDataChanged$.next({ fieldName: e.dataField, value: e.value as string });
    }
    if (e?.dataField === 'startdate') {
      this.agendaStartDateChanged$.next(e.value as string);
    }
    const mapFields = this.mapFormDataToDocumentFields();
    const fields: Record<string, unknown> = {};
    // temporary map to hold results of the formula
    const mapper = new Map<string, boolean>();
    Object.keys(mapFields).forEach(key => {
      fields[key] = mapFields[key].value;
    });
    this.templateClient.fields.forEach(field => {
      if (field.editorOptions?.visibleFormula) {
        // search in Fields
        const formula = field.editorOptions.visibleFormula.code;

        if (!formula.startsWith('media')) {
          let result: boolean;
          if (mapper.has(formula)) {
            result = mapper.get(formula);
          } else {
            result = this.interpret(this.getCodeToInterpret(fields, formula), field);
            mapper.set(formula, result);
          }
          field.editorOptions.fieldVisible = result;

          // search Feld per Name
          let fieldEditor = document.getElementById(field.id);
          if (!fieldEditor) {
            fieldEditor = e?.component.getEditor(field.name)?.element();
          }
          if (fieldEditor) {
            const parent = fieldEditor.closest('.dx-field-item');

            if (result) {
              if (field.editorOptions.requiredOption === 'required-if-visible') {
                const label = parent?.querySelector('.dx-field-item-label-content');
                if (label) {
                  const span = label.querySelector('.dx-field-item-required-mark');
                  if (!span) {
                    const newSpan = this.renderer.createElement('span') as Element;
                    const text = this.renderer.createText(` *`) as string;
                    this.renderer.addClass(newSpan, 'dx-field-item-required-mark');
                    this.renderer.appendChild(newSpan, text);
                    this.renderer.appendChild(label, newSpan);
                  }
                }
              }
              parent?.classList.add('field-visible');
              parent?.classList.remove('field-invisible');
            } else {
              parent?.classList.add('field-invisible');
              parent?.classList.remove('field-visible');
            }

            const y = fieldEditor?.getBoundingClientRect().top + this.window.scrollY;
            this.window.scroll({
              top: y,
              behavior: 'smooth'
            });
          }
          this.#mapFieldVisible.set(field.id, result);
        }
      }
      if ((field as FieldClient).editorOptions.treeListParentFieldName === e?.dataField) {
        const mappedField = this.#mapFieldList.get(field.id);
        if (mappedField) {
          const fieldValue = this.formData[field.name];
          mappedField.filter(['properties.parentId', '=', e.value]);
          void mappedField.load();
          const items = mappedField.items() as SiamListItem[];
          if (!items.find(item => item.value === fieldValue)) {
            this.formData[field.name] = null;
          }
        }
      }
    });
  }

  prepareForSave$(): Observable<IDocument> {
    try {
      // DxValidator aufrufen
      if (!this.formInclude) {
        this.logger.error('formInclude nicht verfügbar !?');
      } else {
        this.logger.debug('führe Eingabevalidierung durch...');

        // Informationen über definierte Validatoren holen
        // könnte man benutzten, um zu prüfen, ob überhaupt validatoren vorhanden sind
        // nur dann muss validate() überhaupt aufgerufen werden...
        const validationRules = ValidationEngine.getGroupConfig(this.formInclude.validationGroup) as unknown;
        if (validationRules) {
          const result = this.formInclude.instance && this.formInclude.instance.validate();
          if (result && !result.isValid) {
            const summary = this.window.document.getElementsByClassName('dx-form-validation-summary');
            let summaryNew: HTMLElement;
            if (this.isPopupDocument) {
              summaryNew = this.window.document.getElementById('summaryNewPopupDocument');
              summaryNew.insertAdjacentElement('afterbegin', summary[1]);
            } else {
              summaryNew = this.window.document.getElementById('summaryNew');
              summaryNew.insertAdjacentElement('afterbegin', summary[0]);
              // Scroll to top
              const mainElement = this.window.document.querySelector('main');
              mainElement.scrollTop = 0;
            }
            // Here return false, because need to prevent to show the error on the screen
            // just check for false wherever needed
            return throwError(() => false);
          }
        }
      }

      return this.prepareDocument();
    } catch (e) {
      return throwError(e);
    }
  }

  /**
   * Dokument-Kopie erzeugen, mit UI-Felddaten füllen und Dokuments Observable zum Speichern zurückgeben
   *
   * @returns Observable<IDocument>
   */
  prepareDocument(): Observable<IDocument> {
    const document = Factory.copy(this.currentDocument);
    document.templateId = document.template?.id;
    document.fields = this.mapFormDataToDocumentFields();

    delete document.template;
    delete document.creation;
    delete document.effectivePermissions;

    const data: IAgendaData = {
      list: undefined,
      speakers: undefined,
      endDate: undefined
    };

    if (this.documentService.isAgenda(this.currentDocument) && this.agendaData?.length) {
      this.setAgendaData(document, data);
    }

    if (this.currentDocument?.fields) {
      Object.keys(this.currentDocument.fields).forEach(key => {
        if (key.startsWith('--siam') || key.startsWith('--approval')) {
          switch (key) {
            case FIELDNAME_AGENDALIST: {
              this.setAgendaData(document, data);
            }
              break;

            default: {
              if (!document.fields[key]) {
                document.fields[key] = this.currentDocument.fields[key];
              }
            }
              break;
          }
        }
      });
    }
    return of(document);
  }

  customizeItem = (item: FieldClient): void => {
    // Item-Eigenschaften je nach Typ verändern

    // A4 format for HTML editor
    if (item.editorType === 'dxHtmlEditor') {
      const modeButton = item.editorOptions?.htmlEditorToolbarItems?.find(
        el => el.options?.elementAttr?.id === 'modeBtn'
      );
      if (modeButton) {
        modeButton.options.onContentReady = (e: ButtonContentReadyEvent): void => {
          if (this.currentDocument.id) {
            e.component.option({ icon: 'edit', hint: 'Bearbeiten' });
          }
        };
      }
    }

    if (item.editorType === 'dxTagBox') {
      const options = item.editorOptions as ITagBoxEditorOptions;
      // ItemTemplate reinpatchen, zur Anzeige der Bilder in der Auswahlliste
      options.itemTemplate = this.nameFieldItemTemplate;
      options.tagTemplate = item.editorOptions.readOnly ? this.nameFieldTagTemplateReadOnly : this.nameFieldTagTemplate;

      options.onOptionChanged = (e: OptionChangedEvent) => {
        /*------ avatar tooltip -------*/
        const avatars = e.element.querySelectorAll('.user-avatar-tooltip');
        avatars.forEach((avatar, idx) => {
          this.#unlistener[idx] = this.renderer.listen(avatar, 'mouseenter', evtIn => {
            this.avatarMouseOver(evtIn as MouseEvent);
            this.#unlistenMouseOut[idx] = this.renderer.listen(avatar, 'mouseleave', () => {
              this.avatarMouseOut();
            });
          });
        });

        /*------ user tooltip -------*/
        const userIds = e.element.querySelectorAll('[data-user-id]');
        userIds.forEach((userId, idx) => {
          this.#unlistener[idx] = this.renderer.listen(userId, 'mouseenter', () => {
            this.popoverElement = userId as HTMLElement;
            const user = this.usersAndRolesArray.find(({ id }) => id === (userId as HTMLElement).dataset?.userId);
            if (user) {
              if (user?.id?.startsWith('user:')) {
                this.popoverContent = `<div class="tooltip-user-info"><div class="tooltip-user-info__avatar" style="background-image:url(${
                  user.profile?.picture?.url
                })"></div><div><div><strong>${user.fullName.trim() ? user.fullName : user.name}</strong></div><div>(${
                  user.userName
                })</div><div>${user.profile?.department ? user.profile?.department : ''}</div></div></div>`;
              } else {
                const usersInRole = user.users;
                let userList = '';
                usersInRole.forEach(user => {
                  userList += `<div>${user.fullName}</div>`;
                });
                userList
                  ? (this.popoverContent = userList)
                  : (this.popoverContent = 'Dieser Rolle sind keine Benutzer zugeordnet.');
              }
              void this.popOverUserTagBox?.instance?.show();

              this.#unlistenMouseOut[idx] = this.renderer.listen(userId, 'mouseleave', () => {
                const isMouseInsidePopover = (
                  this.popOverUserTagBox?.instance.content().parentNode as HTMLElement
                ).matches(':hover');

                if (!isMouseInsidePopover) {
                  void this.popOverUserTagBox?.instance?.hide();
                }
              });
            }
          });
        });
      };
    }

    if (item.editorType === 'dxCheckBox' && item.editorOptions.required && item.editorOptions.threeState) {
      item.isRequired = item.editorOptions.required;
      item.validationRules = [
        {
          type: 'custom',
          message: `Das Feld "${item.caption}" sollte wahr oder falsch sein`,
          validationCallback: (options: IDxItemValidationCallback<string>): boolean =>
            !(options.value === undefined || options.value === null)
        }
      ];
    }
  };

  dragTemplate = (field: DragTemplateData): string => {
    if (field) {
      return `<div style="font-weight: bold; width: 200px; padding: 10px">
      ${(field.itemData as FieldTypes)?.caption}
      </div>`;
    }
    return '';
  };

  nameFieldItemTemplate = (user: IUser | IRole): string => {
    let result = '';
    if (user?.id?.startsWith('user:') && (user as IUser)?.profile) {
      if ((user as IUser).profile.picture && (user as IUser).profile.picture.url) {
        result = `<img class="user-avatar-name" src="${(user as IUser).profile.picture.url}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
      result = result + `<span>${(user as IUser).selectorName}</span>`;
      return result;
    }
    if (user?.id?.startsWith('role:')) {
      result = `<i class="material-icons mr-1">group</i><span>${user.name}</span>`;
      if ((user as IRole).users?.length) {
        let users = '...';
        if ((user as IRole).users?.length < 3) {
          users = (user as IRole).users.map(u => u.fullName).join(', ');
        }
        result = result + `<span class="user-job-title"> (${users})</span>`;
      }
    }
    return result;
  };

  nameFieldTagTemplate = (user: IUserRoleEntry): string => {
    let result = '';
    if (user?.id?.startsWith('user:')) {
      if (user && user.profile && user.profile.picture && user.profile.picture.url) {
        result = `<img class="user-avatar-name user-avatar-tooltip" src="${user.profile.picture.url}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
    }

    if (user?.id?.startsWith('role:')) {
      result = `<i class="material-icons mr-1">group</i>`;
    }

    let removeButton = '<div class="dx-tag-remove-button"></div>';
    if (this.editMode !== 'Edit') {
      removeButton = '';
    }

    if (user.visible) {
      return `<div class="dx-tag-content name-selector ">${result}<span data-user-id="${user.id}">${user.name}</span>${removeButton}</div>`;
    } else {
      return `<div class="dx-tag-content name-selector">${result}<span class="is-archived-user">${user.name}</span>${removeButton}</div>`;
    }
  };

  nameFieldTagTemplateReadOnly = (user: IUserRoleEntry): string => {
    let result = '';
    if (user?.id?.startsWith('user:')) {
      if (user && user.profile && user.profile.picture && user.profile.picture.url) {
        result = `<img class="user-avatar-name" src="${user.profile.picture.url}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
    }
    if (user?.id?.startsWith('role:')) {
      result = `<i class="material-icons mr-1">group</i>`;
    }
    return `<div class="dx-tag-content name-selector">${result}<span data-user-id="${user.id}">${user.name}</span></div>`;
  };
  // ----------------------------------------------------------------------------------------------
  // Methoden für HTML Editor-Widget
  // ----------------------------------------------------------------------------------------------
  async onHtmlEditorChanged(e: HTMLEditorValueChangedEvent, field: FieldClient): Promise<void> {
    if (e) {
      const value = e.value as string;
      const regExp = /<img [^>]*src="data:image[^"]*"[^>]*>/gim;
      const matches = regExp.exec(value);
      const isMatches = matches ? Array.from(matches)?.length > 0 : false;
      if (isMatches) {
        try {
          /* get cursor position */
          const cursor = e.component.getSelection(true) as { index: number; length: number };

          const newValue = await this.getNewHtmlValue(value);
          this.formData[field.dataField] = newValue;
          (field.editorOptions as IReachTextBoxEditorOptions).value = newValue;

          /* set cursor after image */
          setTimeout(() => {
            e.component.setSelection(cursor?.index + 1, 0);
          }, 10);
        } catch (error) {
          // error
        }
      } else {
        this.formData[field.dataField] = value;
        (field.editorOptions as IReachTextBoxEditorOptions).value = value;
      }
    }
  }

  async getNewHtmlValue(value: string): Promise<string> {
    this.loadPanelService.show('Daten werden gespeichert...');
    const regExp = /<img [^>]*src="data:image[^"]*"[^>]*>/gim;
    let result = value;
    let match: RegExpExecArray = null;
    while ((match = regExp.exec(value)) !== null) {
      try {
        const tmp = document.createElement('div');
        tmp.innerHTML = match[0];
        const img = tmp.querySelector('img');
        const src = img.getAttribute('src');
        const fileType = src.match(/:(.*?);/)[1];
        const file = await this.urlToFile(src, 'test', fileType);
        if (file) {
          const attachment = await lastValueFrom(this.documentService.uploadAttachment({ file }));

          if (attachment?.id) {
            img.setAttribute('src', `/api/attachment/${attachment?.id}`);
            const newImgTag = img.outerHTML;
            result = result.replace(match[0], newImgTag);
          }
        }
      } catch (error) {
        // error
      }
    }

    this.loadPanelService.hide();

    return result;
  }

  async urlToFile(url: string, fileName: string, type: string): Promise<File> {
    return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], fileName, { type }));
  }

  /**
   * Protocol white list for link
   *
   * @param e
   */
  onHtmlEditorInitialized(e: HtmlEditorInitializedEvent): void {
    /* eslint-disable */
    const Link = e.component.get('formats/link');
    Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'notes'];

    class CustomLinkSanitizer extends Link {
      static sanitize(url: string) {
        const sanitizedUrl = super.sanitize(url);
        if (!sanitizedUrl || sanitizedUrl === 'about:blank') {
          return sanitizedUrl;
        }

        const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (protocol: string) {
          return sanitizedUrl.startsWith(protocol);
        });

        if (hasWhitelistedProtocol) {
          return sanitizedUrl;
        }

        return `http://${sanitizedUrl}`;
      }
    }

    e.component.register({ 'formats/link': CustomLinkSanitizer });
    /* eslint-enable */
  }

  /**
   * Tab: replace spaces with non-breaking spaces
   *
   * @param e
   * @param field
   */
  onHtmlEditorContentReady(e: HtmlEditorContentReadyEvent, field: FieldClient): void {
    if (e) {
      /* eslint-disable */
      e.component.getModule('keyboard').bindings.tab.unshift({
        key: 9,
        handler: function (range: { index: number }) {
          this.quill.insertText(range.index, '\xA0\xA0\xA0\xA0');
          return false;
        }
      });
      /* eslint-enable */

      const options = (field as RichTextEditor).editorOptions;

      /*------ set editor font ------*/
      const fontType = options?.htmlEditorSettings?.fontType;
      const fontSize = options?.htmlEditorSettings?.fontSize;
      const htmlContent = e.element.querySelector('.dx-htmleditor-content');
      (htmlContent as HTMLElement).style.cssText = `font-size: ${fontSize}; font-family: ${fontType};`;

      /*------ set editor heigth -------*/
      const isDynamicHeight = options?.htmlEditorSettings?.dynamicHeight;
      const isMaxHeight = options?.htmlEditorSettings?.maxHeight;
      const toolbar = e.element.querySelector('.dx-htmleditor-toolbar-wrapper');
      if (toolbar) {
        const toolbarHeight = toolbar?.getBoundingClientRect().height;
        const editorHeight = parseInt(String(options.height), 10) + toolbarHeight + 2; // 2px Border top & bottom;
        if (isDynamicHeight) {
          e.component.option({ elementAttr: { style: `min-height:${editorHeight ? editorHeight : 90}px` } });
          if (isMaxHeight) {
            e.component.option({
              elementAttr: { style: `min-height:${editorHeight ? editorHeight : 90}px; max-height:${isMaxHeight}px` }
            });
          }
        } else {
          e.component.option({ height: editorHeight ? editorHeight : 90 });
        }
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  // Methoden für Attachment-Widget
  // ----------------------------------------------------------------------------------------------
  onContentReadyFileUpload(e: FileUploaderContentReadyEvent): void {
    const buttonInstance = (e.component as IDxFileUploaderComponent)._selectButton;
    if (buttonInstance) {
      buttonInstance.option('icon', 'runner');
      const icon = buttonInstance.element().querySelectorAll('i')[0];
      const parentNode = icon.parentNode;
      const newIcon = document.createElement('i');
      newIcon.setAttribute('class', 'material-icons');
      newIcon.textContent = 'cloud_upload';
      parentNode.insertBefore(newIcon, icon);
      parentNode.removeChild(icon);
    }
  }

  async onFileValueChanged(e: FileUploaderValueChangedEvent, field: FileUploader): Promise<void> {
    const fileSize = field.editorOptions?.fileuploaderMaxFileSizeBit;
    let attachments: IAttachment[] = (this.formData[field.dataField] as IAttachment[]) || [];
    attachments = attachments.slice();
    for (const file of e.value) {
      if (file && file.size < fileSize) {
        const allowedTypes = field.editorOptions.allowedFileMimeTypes;
        if (!allowedTypes.includes('*/*') && !allowedTypes.includes(file.type)) {
          continue;
        }
        await lastValueFrom(
          this.documentService.uploadAttachment({ file }).pipe(
            map(result => {
              if (result) {
                const index = attachments.findIndex(x => x.fileName === result.fileName);
                if (index > -1) {
                  attachments.splice(index, 1, result);
                } else {
                  attachments.push(result);
                }
              }
            })
          )
        );
        field.editorOptions.value = e.value;
        this.formData[field.dataField] = attachments;
        this.attachmentChanged$.next(true);
      }
    }
  }

  // ----------------------------------------------------------------------------------------------
  // Methoden für Slider-Widget
  // ----------------------------------------------------------------------------------------------
  onSliderChanged(e: SliderValueChangedEvent, field: DxSlider): void {
    if (e) {
      const value = e.value as number;
      this.formData[field.dataField] = value;
      field.editorOptions.value = value;
      const inputValue = e.element.querySelector('input').value;
      if (inputValue) {
        const sliderLabels = Array.from(e.element.querySelectorAll('.dx-slider-label'));
        const div = sliderLabels[1] as HTMLElement;
        if (div) {
          const span = div.querySelector('span');
          if (span) {
            span.textContent = `${inputValue}/`;
          } else {
            const newSpan = this.renderer.createElement('span') as Element;
            const text = this.renderer.createText(`${inputValue}/`) as string;
            this.renderer.appendChild(newSpan, text);
            this.renderer.insertBefore(div, newSpan, div?.firstChild);
          }
        }
      }
    }
  }

  /**
   * download or open document attachment
   *
   * @param file IAttachment
   * @param fileField FieldClient
   * @param index
   * @param option
   */
  downloadFile(file: IAttachment, fileField: FieldClient, index: number, option: 'open' | 'download'): void {
    this.loadPanelService.show();

    const isMobile = isMobileDevice();

    let returnType: 'fileName' | 'url' | 'filetype' | 'blobUrl' = 'url';
    if (isMobile) {
      option = 'open';
      returnType = 'blobUrl';
    } else {
      if (option === 'open') {
        switch (file.contentType) {
          case 'application/pdf':
          case 'text/plain':
          case 'image/jpeg':
          case 'image/jpg':
          case 'image/png':
          case 'image/gif':
            returnType = 'blobUrl';
            break;
          default:
            option = 'download';
        }
      }
    }

    this.fileDownloadLink
      .transform(file, returnType, this.currentDocument)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: fileUrl => {
          this.loadPanelService.hide();
          const link = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, fileUrl);
          switch (option) {
            case 'open': {
              const open = (): void => {
                const newTab = this.window.open(link, '_blank');
                // if popup blocker on it will be null
                if (newTab) {
                  const listener = () => {
                    newTab.document.title = file.fileName;
                    newTab.removeEventListener('load', listener);
                  };
                  newTab.addEventListener('load', listener);
                }
              };

              if (isMobile) {
                const dialog = custom({
                  title: 'Vorgangsanforderung',
                  messageHtml: 'Das angeforderte Dokument wurde fertiggestellt. Sie können es nun öffnen.',
                  buttons: [
                    {
                      text: 'Fortfahren',
                      onClick: () => {
                        open();
                        return true;
                      }
                    }
                  ]
                }) as ICustomDialog<void>;

                dialog.show();
              } else {
                open();
              }
            }
              break;

            case 'download': {
              const element = this.window.document.getElementById(
                `fileName-${fileField.dataField}-${index}`
              ) as HTMLAnchorElement;
              element.setAttribute('href', link);
              element.click();
            }
              break;
          }
        },
        error: () => {
          this.loadPanelService.hide();
        }
      });
  }

  onRemoveFile(e: RowRemovingEvent<IAttachment, IAttachment>, field: FieldClient): void {
    if (!field.dataField && !e.key) {
      e.cancel = true;
      return;
    }
    let attachments: IAttachment[] = (this.formData[field.dataField] as IAttachment[]) || [];
    attachments = attachments.slice();
    const index = attachments.findIndex(x => x.fileName === e.key.fileName);
    if (index !== -1) {
      attachments.splice(index, 1);
    }
    this.formData[field.dataField] = attachments;
    this.formInclude.instance?.repaint();
  }

  onReorderAttachments = (e: RowDraggingReorderEvent<IAttachment>): void => {
    let fieldName: string = null;
    const elementId = e?.component?.element()?.id;
    if (elementId) {
      const regExp = new RegExp(/gridAttachment-([^\s]+)/i);
      const matches = regExp.exec(elementId);
      if (matches?.length) {
        fieldName = matches[1] || '';
      }
    }
    if (fieldName && this.formData[fieldName]) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = (this.formData[fieldName] as IAttachment[]).indexOf(visibleRows[e.toIndex].data);
      const fromIndex = (this.formData[fieldName] as IAttachment[]).indexOf(e.itemData);

      (this.formData[fieldName] as IAttachment[]).splice(fromIndex, 1);
      (this.formData[fieldName] as IAttachment[]).splice(toIndex, 0, e.itemData);
    }
  };

  // ----------------------------------------------------------------------------------------------
  // Methoden für checkbox group
  // ----------------------------------------------------------------------------------------------
  onCheckboxGroupChanged(e: CheckBoxValueChangedEvent, field: CheckBoxGroup, currentCheckbox: SiamListItem): void {
    const value: (string | number | IPermissionTarget | IPermissionTarget[])[] =
      Factory.copy(this.formData[field.dataField] as string[]) || [];
    const index = value.findIndex(x => {
      if (typeof x === 'string') {
        return x === currentCheckbox.value;
      }
      if (typeof x === 'number') {
        return x === currentCheckbox.value;
      }
      if (typeof x === 'object' && (x as IPermissionTarget).targetId && (x as IPermissionTarget).type) {
        return (
          (x as IPermissionTarget).targetId === (currentCheckbox.value as IPermissionTarget).targetId &&
          (x as IPermissionTarget).type === (currentCheckbox.value as IPermissionTarget).type
        );
      }
      if (Array.isArray(x)) {
        const checkBoxValue = sortBy(
          (currentCheckbox.value as IPermissionTarget[]).map(check => {
            if (check.type && check.targetId) {
              return {
                type: check.type,
                targetId: check.targetId
              };
            }
            return check;
          }),
          'targetId'
        );
        const xTarget = sortBy(
          x.map(check => {
            if (check.type && check.targetId) {
              return {
                type: check.type,
                targetId: check.targetId
              };
            }
            return check;
          }),
          'targetId'
        );

        return JSON.stringify(xTarget) === JSON.stringify(checkBoxValue);
      } else {
        return false;
      }
    });
    if (e.value && index === -1) {
      value.push(currentCheckbox.value as string);
    } else if (!e.value && index > -1) {
      value.splice(index, 1);
    }

    this.formData[field.dataField] = value;
    field.editorOptions.value = value;
    this.fieldDataChanged();
  }

  // ----------------------------------------------------------------------------------------------
  // Methoden für Drag_&_Drop und Gruppen Style: Feldreihenfolge bearbeiten
  // ----------------------------------------------------------------------------------------------//
  onContentFormReady = (e: FormContentReadyEvent): void => {
    this.#mapFieldDraggableClickEvent.forEach(unlistner => {
      unlistner();
    });
    this.#mapFieldDraggableClickEvent = new Map<string, () => void>();
    /*------ html-editor styles -------*/
    const htmlEditors = Array.from(e.element.querySelectorAll('dx-html-editor'));
    for (const htmlEditor of htmlEditors) {
      const htmlContent = htmlEditor.querySelector('.dx-htmleditor-content');
      const toolbarContainer = htmlEditor.querySelector('.dx-toolbar-items-container');
      const allButtons = toolbarContainer.querySelectorAll('.dx-toolbar-button');
      const editButton = toolbarContainer.querySelector('#modeBtn');

      if (this.currentDocument.id && editButton) {
        // set content in read mode
        htmlEditor.classList.toggle('editor-read-mode');
        htmlContent.setAttribute('contenteditable', 'false');
        // hide toolbar buttons
        allButtons.forEach((btn, index) => {
          if (index !== 0) {
            (btn as HTMLElement).style.visibility = 'hidden';
          }
        });
      }
    }
    const requiredIfVisibleFields = Array.from(e.element.querySelectorAll('.required-if-visible'));
    for (const field of requiredIfVisibleFields) {
      if (field.classList.contains('field-visible')) {
        const parent = field.closest('.dx-field-item');
        const label = parent?.querySelector('.dx-field-item-label-content');
        if (label) {
          const span = label.querySelector('.dx-field-item-required-mark');
          if (!span) {
            const newSpan = this.renderer.createElement('span') as Element;
            const text = this.renderer.createText(` *`) as string;
            this.renderer.addClass(newSpan, 'dx-field-item-required-mark');
            this.renderer.appendChild(newSpan, text);
            this.renderer.appendChild(label, newSpan);
          }
        }
      }
    }
    /*------ slider label content -------*/
    const sliders = Array.from(e.element.querySelectorAll('.dx-slider'));
    for (const slider of sliders) {
      const inputValue = slider.querySelector('input').value;
      if (inputValue) {
        const sliderLabels = Array.from(slider.querySelectorAll('.dx-slider-label'));
        const span = this.renderer.createElement('span') as Element;
        const text = this.renderer.createText(`${inputValue}/`) as string;
        this.renderer.appendChild(span, text);
        this.renderer.insertBefore(sliderLabels[1], span, sliderLabels[1]?.firstChild);
      }
    }
    /*------ group styles -------*/
    const formGroups = Array.from(e.element.querySelectorAll('.group-item'));
    for (const element of formGroups) {
      const groupHasCaption = element.classList.contains('is-caption-visible');
      const groupHeader = element.querySelector('.dx-form-group-caption');
      const groupContent = element.querySelector('.dx-form-group-content');
      const accordionItem = element.querySelector('.dx-accordion-item');
      const accordionItemTitel = element.querySelector('.dx-accordion-item-title');
      const groupClassColor: string[] = element.classList.value.match(/group-color-#[^\s]+/gi) || [];
      const groupTitleIsRequired = element.classList.contains('group-title-required');

      // add tooltip für Gruppen und Container
      if (groupTitleIsRequired && groupHeader && groupHasCaption) {
        const classes = Array.from(element.classList);
        const draggableElement = classes.find(className => className.includes('draggable_'));
        const groupData = this.#mapClassField[draggableElement]?.entry as Group;
        if (groupData) {
          this.renderer.setAttribute(groupHeader, 'title', groupData?.editorOptions?.hint);
        }
      }
      /* add id to group */
      const regExp = new RegExp(/siam-group-id-([^\s]+)/i);
      const matches = regExp.exec(element.className);
      if (matches?.length) {
        element.id = matches[1] || '';
      }

      // GroupColor hinzufügen, wenn konfiguriert oder für Draggable Modus
      if (groupClassColor.length || this.draggableAllow) {
        this.renderer.addClass(element, 'group-with-color');
        this.renderer.removeClass(element, 'group-colorless');
        const color = groupClassColor?.length
          ? groupClassColor.map((e: string) => e.replace('group-color-', ''))[0]
          : 'hsl(var(--main-color))';

        /* add group color */
        // this.renderer.setStyle(element, 'border-color', color);
        if (groupContent) {
          this.renderer.setStyle(groupContent, 'border-color', color);
        }

        /* add group header backgroud-color */
        if (groupHeader && groupHasCaption) {
          this.renderer.setStyle(groupHeader, 'background-color', color);
        }

        /* add container color */
        if (accordionItem) {
          this.renderer.setStyle(accordionItem, 'border-color', color);
          this.renderer.setStyle(accordionItemTitel, 'background-color', color);
        }
      }

      /* add open-close event for not empty group */
      let isGroupClosed = element.classList.contains('close-group');

      if (groupContent?.children[0]?.children.length && groupHeader && groupHasCaption) {
        const classes = Array.from(element.classList);
        const draggableElement = classes.find(className => className.includes('draggable_'));
        const unlistener = this.renderer.listen(groupHeader, 'click', (clickEvent: Event) => {
          clickEvent.stopPropagation();
          clickEvent.preventDefault();
          if (this.draggableAllow) {
            // this.renderer.removeClass(element, 'close-group');
            this.selectedDraggableItem = null;

            // deselect all Elements
            const selectedItems = Array.from(e.element.querySelectorAll('.draggable-selected'));
            for (const selectedItem of selectedItems) {
              this.renderer.removeClass(selectedItem, 'draggable-selected');
            }

            if (draggableElement) {
              // emit the selected Group
              const row = this.#mapFieldDraggable.get(draggableElement)?.entry;
              this.selectedDraggableItem = Factory.copy(row);
              this.renderer.addClass(element, 'draggable-selected');
              this.documentIncludeEmitter.emit({ command: 'select', object: this.selectedDraggableItem });
            }
          } else {
            const action = isGroupClosed ? 'removeClass' : 'addClass';
            this.renderer[action](element, 'close-group');
            isGroupClosed = !isGroupClosed;
          }
        });
        this.#mapFieldDraggableClickEvent.set(draggableElement, unlistener);
      }
    }

    /*------ set focus on first element -------*/
    if (this.formItems?.length && !this.childElement) {
      const field = this.getFirstField((this.formItems[0] as Group).items as FieldClient[]);
      if (field) {
        switch (field.kind) {
          case 'dxHtmlEditor':
          case 'dxCheckboxGroup': {
            setTimeout(() => {
              e.component.focus();
            }, 500);
          }
            break;
          default:
            setTimeout(() => {
              e.component.getEditor(field.dataField)?.focus();
            }, 500);
        }
      }
    }

    if (this.draggableAllow) {
      /*------ EmptyFields styles -------*/
      const emptyFields = Array.from(e.element.querySelectorAll('.dx-field-empty-item'));
      for (const element of emptyFields) {
        this.renderer.setStyle(element, 'border', '1px solid');
        this.renderer.setStyle(element, 'border-radius', '10px');
        this.renderer.setStyle(element, 'border-style', 'dashed');
        this.renderer.setStyle(element, 'display', 'flex');
        this.renderer.setStyle(element, 'justify-content', 'center');
        this.renderer.setStyle(element, 'align-items', 'center');
        this.renderer.setStyle(element, 'height', '34px');
        this.renderer.setStyle(element.parentElement, 'margin', 'auto');
        this.renderer.setStyle(element.parentElement, 'padding-left', '1.5rem');
        const text = this.renderer.createText(`Platzhalter`) as string;
        this.renderer.appendChild(element, text);
      }

      // Suche von alle Boxes die Gruppen oder Felder enthalten
      const formElementsItems = Array.from(e.element.querySelectorAll('.dx-box.dx-widget'));
      for (const element of formElementsItems) {
        const rows: FieldTypes[] = [];
        // Orientation von DraggableItems in Box
        // wenn flexDirection ist column dann Vertical
        // und wenn flexDirection ist row dann horizontal
        let itemOrientation: Orientation = 'horizontal';
        if ((element as HTMLElement).style.flexDirection === 'column') {
          itemOrientation = 'vertical';
        }
        const formItems = Array.from(element.children);
        // suche von alle elemente in eine Box (felder und empty felder)
        for (const formItem of formItems) {
          const item = formItem.querySelector('.dx-field-item, .dx-field-empty-item');
          if (item) {
            const classes = Array.from(item.classList);
            const draggableElement = classes.find(className => className.includes('draggable_'));
            if (draggableElement) {
              this.selectedDraggableItem = null;
              const isGroupItem = classes.find(className => className.includes('group-item'));
              const row = this.#mapFieldDraggable.get(draggableElement)?.entry;
              rows.push(row);
              if (!isGroupItem) {
                this.renderer.setStyle(item, 'cursor', 'move');
                // add click event zu jeder Feld und Platzhalter
                if (!this.#mapFieldDraggableClickEvent.has(draggableElement)) {
                  const unlistener = this.renderer.listen(item, 'click', (clickEvent: Event) => {
                    clickEvent.stopPropagation();
                    clickEvent.preventDefault();
                    const selectedItems = Array.from(e.element.querySelectorAll('.draggable-selected'));
                    for (const selectedItem of selectedItems) {
                      this.renderer.removeClass(selectedItem, 'draggable-selected');
                    }
                    this.renderer.addClass(item, 'draggable-selected');
                    this.selectedDraggableItem = Factory.copy(row);
                    this.documentIncludeEmitter.emit({ command: 'select', object: this.selectedDraggableItem });
                  });

                  this.#mapFieldDraggableClickEvent.set(draggableElement, unlistener);
                }
              }
            }
          }
        }
        new dxSortable(element, {
          onDragStart: this.onDragStart,
          onReorder: this.onReorder,
          onAdd: this.onAdd,
          data: rows,
          dragTemplate: this.dragTemplate,
          handle: '.dx-field-item',
          group: '.dx-box',
          itemOrientation,
          scrollSpeed: 100,
          dropFeedbackMode: 'push'
        });
      }

      // suche von alle Empty feldGruppen und DropInside ermöglichen
      const groupItems = Array.from(e.element.querySelectorAll('.group-item'));
      const groupItemsEmpty = groupItems.filter(
        item => item.querySelector('.dx-layout-manager.dx-widget')?.children?.length === 0
      );
      for (const emptyGroup of groupItemsEmpty) {
        const classes = Array.from(emptyGroup.classList);
        const draggableElement = classes.find(className => className.includes('draggable_'));
        if (draggableElement) {
          const row = this.#mapFieldDraggable.get(draggableElement)?.entry;
          new dxSortable(emptyGroup, {
            onDragStart: this.onDragStart,
            onReorder: this.onReorder,
            onAdd: this.onDropInside,
            data: row,
            dragTemplate: this.dragTemplate,
            handle: '.dx-field-item',
            group: '.dx-box',
            allowDropInsideItem: true,
            itemOrientation: 'vertical',
            dropFeedbackMode: 'push'
          });
        }
      }
    }
  };

  onDragStart = (e: DragStartEvent): void => {
    e.itemData = (e.fromData as FieldTypes[])[e.fromIndex];
  };

  onReorder = (e: ReorderEvent): void => {
    if (!e?.fromData || typeof e?.fromIndex !== 'number' || typeof e?.toIndex !== 'number') {
      return;
    }
    if (e.fromData === e.toData && e.fromIndex === e.toIndex) {
      void this.formInclude?.instance?.repaint();
      return;
    }
    const grpItems = e.fromData as FieldTypes[];
    const fromFieldName = grpItems[e.fromIndex].name;
    const toFieldName = grpItems[e.toIndex].name;
    const fromFieldIndex = this.templateClient.fields.findIndex(f => f.name === fromFieldName);
    const toFieldIndex = this.templateClient.fields.findIndex(f => f.name === toFieldName);
    const fromField = this.templateClient.fields[fromFieldIndex];
    fromField.parentContainerName = grpItems[e.toIndex].parentContainerName;
    this.templateClient.fields.splice(fromFieldIndex, 1);
    this.templateClient.fields.splice(toFieldIndex, 0, fromField);
    void this.refreshFormItems(this.templateClient);
  };

  onDeleteDraggableItem = (e: AddEvent): void => {
    if (e.itemData && (e.itemData as FieldTypes).kind !== 'group') {
      const index = this.templateClient.fields.findIndex(f => f.name === (e.itemData as FieldTypes).name);
      this.templateClient.fields.splice(index, 1);
      void this.refreshFormItems(this.templateClient);
    }
  };

  onDropInside = (e: AddEvent): void => {
    if (!e?.fromData || typeof e?.fromIndex !== 'number' || typeof e?.toIndex !== 'number') {
      return;
    }
    if (e.dropInsideItem) {
      const toGrpItem = e.toData as Group;
      const toFieldIndex = this.templateClient.fields.findIndex(f => f.name === toGrpItem.name) + 1;

      switch (e.fromData as TDraggableType) {
        case 'spacer': {
          const newSpacer = new Empty({});
          newSpacer.parentContainerName = toGrpItem.name;
          this.documentIncludeEmitter.emit({ command: 'new', object: newSpacer, fieldIndex: toFieldIndex });
        }
          break;
        case 'field': {
          const newField = new TextBox({ caption: '' });
          newField.parentContainerName = toGrpItem.name;
          this.documentIncludeEmitter.emit({ command: 'new', object: newField, fieldIndex: toFieldIndex });
          break;
        }
        case 'group': {
          const newGroup = new Group({ caption: '' });
          newGroup.parentContainerName = toGrpItem.name;
          this.documentIncludeEmitter.emit({ command: 'new', object: newGroup, fieldIndex: toFieldIndex });
          break;
        }
        case 'container': {
          const newContainer = new Container('tasks');
          newContainer.parentContainerName = toGrpItem.name;
          this.documentIncludeEmitter.emit({ command: 'new', object: newContainer, fieldIndex: toFieldIndex });
          break;
        }
        default: {
          const fromGrpItems = e.fromData as FieldTypes[];

          const fromFieldName = fromGrpItems[e.fromIndex].name;
          const fromFieldIndex = this.templateClient.fields.findIndex(f => f.name === fromFieldName);
          const fromField = this.templateClient.fields[fromFieldIndex];
          fromField.parentContainerName = toGrpItem.name;
          const template = clone(this.templateClient);
          this.moveItem(template, fromField, toFieldIndex);
          this.templateClient = template;
          void this.refreshFormItems(template);
        }
          break;
      }
    }
  };

  onAdd = (e: AddEvent): void => {
    if (!e?.fromData || typeof e?.fromIndex !== 'number' || typeof e?.toIndex !== 'number') {
      return;
    }

    const toGrpItems = e.toData as FieldTypes[];
    const fromGrpItems = e.fromData as FieldTypes[];

    const toIndex = e.toIndex ? e.toIndex - 1 : e.toIndex;
    const fromFieldName = fromGrpItems[e.fromIndex].name;
    const toFieldName = toGrpItems[toIndex].name;
    const fromFieldIndex = this.templateClient.fields.findIndex(f => f.name === fromFieldName);
    const toFielNameIndex = this.templateClient.fields.findIndex(f => f.name === toFieldName);
    const toFieldIndex = e.toIndex ? toFielNameIndex + 1 : toFielNameIndex;
    const fromField = this.templateClient.fields[fromFieldIndex];
    switch (e.fromData as TDraggableType) {
      case 'spacer': {
        const newSpacer = new Empty({});
        newSpacer.parentContainerName = toGrpItems[toIndex].parentContainerName;
        newSpacer.colSpan = toGrpItems[toIndex].colSpan;
        this.documentIncludeEmitter.emit({
          command: 'new',
          object: newSpacer,
          fieldIndex: toFieldIndex
        });
      }
        break;
      case 'field': {
        const newField = new TextBox({ caption: '' });
        newField.parentContainerName = toGrpItems[toIndex].parentContainerName;
        newField.colSpan = toGrpItems[toIndex].colSpan;
        this.documentIncludeEmitter.emit({
          command: 'new',
          object: newField,
          fieldIndex: toFieldIndex
        });
        break;
      }
      case 'group': {
        const newGroup = new Group({ caption: '' });
        newGroup.parentContainerName = toGrpItems[toIndex].parentContainerName;
        newGroup.colSpan = toGrpItems[toIndex].colSpan;
        this.documentIncludeEmitter.emit({
          command: 'new',
          object: newGroup,
          fieldIndex: toFieldIndex
        });
        break;
      }
      case 'container': {
        const newContainer = new Container('tasks');
        newContainer.parentContainerName = toGrpItems[toIndex].parentContainerName;
        newContainer.colSpan = toGrpItems[toIndex].colSpan;
        this.documentIncludeEmitter.emit({
          command: 'new',
          object: newContainer,
          fieldIndex: toFieldIndex
        });
        break;
      }
      default: {
        fromField.parentContainerName = toGrpItems[toIndex].parentContainerName;
        const template = clone(this.templateClient);
        this.moveItem(template, fromField, toFieldIndex);
        this.templateClient = template;
        void this.refreshFormItems(template);
      }
        break;
    }
  };

  moveItem(template: TemplateClient, item: FieldTypes, toIndex: number): void {
    if (item.type === 'group') {
      const fromGroupIndex = this.templateClient.fields.findIndex(f => f.name === item.name);
      template.fields.splice(fromGroupIndex, 1);
      template.fields.splice(toIndex, 0, item);
      const items = template.fields.filter(f => f.parentContainerName === item.name);
      for (const itemInGroup of items) {
        const parentIndex = template.fields.findIndex(f => f.name === itemInGroup.parentContainerName);
        this.moveItem(template, itemInGroup, parentIndex + 1);
      }
    } else {
      const fromIndex = template.fields.findIndex(i => i.name === item.name);
      template.fields.splice(fromIndex, 1);
      template.fields.splice(toIndex, 0, item);
    }
  }

  eventDialogResult = (e: IEventEmitter<ITask | IDocument | IAgenda[]>): void => {
    if (e.command === 'agendaItems') {
      this.agendaData = e.object as IAgenda[];
    } else {
      this.eventResult.emit(e as IEventEmitter<ITask>);
    }
  };
  eventTopDialogResult = (e: IEventEmitterAgenda): void => {
    if (e.command === 'agendaItems' && this.currentDocument.fields[siamConst.agendaField]) {
      this.formData[siamConst.agendaField] = e.value;
    } else {
      this.eventResult.emit(e as IEventEmitter<ITask>);
    }
  };

  eventDiagramResult = (e: IEventEmitter<IResultDiagram>): void => {
    if (e.command === 'diagram' && this.currentDocument.fields[e.object.fieldName]) {
      this.formData[e.object.fieldName] = e.object.value;
      this.currentDocument.fields[e.object.fieldName].value = e.object.value;
      this.currentDocument.fields[e.object.fieldName].type = 'json';
    }
    this.eventResult.emit({ command: 'update-diagram' });
  };

  openSignatureDialog = (field: SignatureField): void => {
    if (!this.currentDocument?.id && !this.parentDocumentId) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.mapShowSignatureDialog.set(field.editorOptions.elementAttr.id, true);
  };

  signatureDialogClose(field: SignatureField): void {
    this.mapShowSignatureDialog.set(field.editorOptions.elementAttr.id, false);
  }

  async signatureDialogResult(event: IEventEmitterSignature): Promise<void> {
    if (event?.dataField) {
      this.mapShowSignatureDialog.set(event.field.editorOptions.elementAttr.id, false);
      if (event.attachment) {
        const documentId = this.parentDocumentId || this.currentDocument?.id || null;
        const isSecurityPatternEnabled = event.field?.editorOptions?.customValuesSignature?.isSecurityPatternEnabled || false;
        const properties: Record<string, unknown> = {
          documentId,
          isSignature: true,
          isSecurityPatternEnabled
        };
        const file = await this.urlToFile(event.attachment, 'signature', 'image/png');
        const fileData: IUploadAttachment = { file, properties };
        await lastValueFrom(
          this.documentService.uploadAttachment(fileData).pipe(
            map(result => {
              if (result) {
                this.formData[event.dataField] = [result];
              }
            })
          )
        );
      } else {
        this.formData[event.dataField] = null;
      }
    }
  }

  swap(template: TemplateClient, srcIndex: number, trgIndex: number): void {
    this.removePlaceHolder(template);
    const sourceField = template.fields[srcIndex];
    const targetField = template.fields[trgIndex];
    if (sourceField.name === targetField.name) {
      return;
    }
    if (targetField.type === 'group') {
      sourceField.parentContainerName = targetField.name || undefined;
    } else {
      this.swapFields(template, srcIndex, trgIndex);
    }
    void this.refreshFormItems(template);
  }

  removePlaceHolder(template: TemplateClient): void {
    const placeHolderFieldIndex = template.fields.findIndex(x => x === this.placeHolderField);
    if (placeHolderFieldIndex !== -1) {
      template.fields.splice(placeHolderFieldIndex, 1);
      this.placeHolderField = null;
    }
  }

  swapFields(template: TemplateClient, srcIndex: number, trgIndex: number): void {
    const temp = template.fields[srcIndex];
    const srcParent = template.fields[srcIndex].parentContainerName;
    const trgParent = template.fields[trgIndex].parentContainerName;
    template.fields[srcIndex] = template.fields[trgIndex];
    template.fields[trgIndex] = temp;
    template.fields[trgIndex].parentContainerName = trgParent;
    template.fields[srcIndex].parentContainerName = srcParent;
  }

  async refreshFormItems(template: TemplateClient): Promise<void> {
    this.formItems = await lastValueFrom(this.getFormItems(mapClientToServerDoc(template)));
  }

  getDataSourceForlistFields(field: SelectBox, fields: Record<string, unknown>): Observable<void> {
    const listName = (field.editorOptions.choicesDataSource as IFieldDataSourceList).name;
    if (listName) {
      return this.listsService.getList(listName).pipe(
        map(data => {
          const datasource = clone(data.entries);
          const fieldValue = fields[field.dataField];
          const isArray = Array.isArray(fieldValue);
          if (Array.isArray(data.entries)) {
            datasource.forEach(n => {
              const properties = n.properties as Record<string, boolean>;
              if (properties?.deactivated === true) {
                let isFound: boolean;
                if (isArray) {
                  isFound = fieldValue.some(value => value === n.value);
                } else {
                  isFound = fieldValue === n.value;
                }

                if (isFound) {
                  n.disabled = true;
                  n.visible = true;
                } else {
                  n.visible = false;
                }
              }
            });
          }
          this.#mapFieldList.set(field.id, new DataSource({ store: datasource }));
        }),
        takeUntil(this.#destroyable$)
      );
    } else {
      return of(null);
    }
  }

  getDataSourceForNames(field: PermissionTarget): Observable<void> {
    field.editorOptions.displayExpr = 'name';
    field.editorOptions.valueExpr = 'id';
    field.editorOptions.itemTemplate = this.nameFieldItemTemplate;
    field.editorOptions.tagTemplate = field.editorOptions.readOnly
      ? this.nameFieldTagTemplateReadOnly
      : this.nameFieldTagTemplate;

    let stream$: Observable<IPermissionTarget[]>;
    if (field.editorOptions.resolve?.length) {
      stream$ = of(field.editorOptions.resolve as IPermissionTarget[]);
    } else if (!field.editorOptions.resolve?.length && this.currentDocument?.id) {
      stream$ = this.documentService.getSelectableNamesFromField(this.currentDocument.id, field.name);
    } else {
      const includes = field.editorOptions.include?.map((val: string) => Factory.createPermissionTarget(val));
      const excludes = field.editorOptions.exclude?.map((val: string) => Factory.createPermissionTarget(val));
      const selection = field.editorOptions.selection as TSelectableNames;
      const data: ISelectableNames = { includes, excludes, selection };
      stream$ = this.templateService.getSelectableNames(data);
    }

    return stream$.pipe(
      map(selectables => {
        const dataResult: IUserRoleEntry[] = [];

        selectables
          .map(val => Factory.permissionTarget(val))
          .forEach(s => {
            const selectable = this.usersAndRolesArray.find(f => f.id === s.compositeId);
            if (selectable) {
              dataResult.push(selectable);
            }
          });
        dataResult.sort((a, b) => {
          const typeA = a.id.split(':')[0];
          const typeB = b.id.split(':')[0];

          if (typeA > typeB) {
            return -1;
          }
          if (typeA < typeB) {
            return 1;
          }
          if (typeA === 'user' && typeB === 'user') {
            return a.selectorName.toLowerCase() > b.selectorName.toLowerCase()
              ? 1
              : b.selectorName.toLowerCase() > a.selectorName.toLowerCase()
                ? -1
                : 0;
          }
          if (typeA === 'role' && typeB === 'role') {
            return a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : b.name.toLowerCase() > a.name.toLowerCase()
                ? -1
                : 0;
          }

          return 0;
        });
        // ItemTemplate reinpatchen, zur Anzeige der Bilder in der Auswahlliste
        this.#mapFieldNames.set(field.id, new DataSource({ store: dataResult }));
      })
    );
  }

  isDocumentHasChanges = (): Observable<boolean> => {
    if (this.editMode !== 'Edit') {
      return of(false);
    }

    return this.prepareDocument().pipe(
      map(parsedDocument => {
        if (parsedDocument.fields[FIELDNAME_AGENDALIST]) {
          if (!this.currentDocument.fields[FIELDNAME_AGENDALIST]) {
            return 'hasChange';
          }
          if (
            !this.isFastDeepEqualAgenda(
              parsedDocument.fields[FIELDNAME_AGENDALIST].value as IAgenda[],
              this.currentDocument.fields[FIELDNAME_AGENDALIST].value as IAgenda[]
            )
          ) {
            return 'hasChange';
          }
        }
        return this.currentDocument.template.fields.map(field => {
          const key = field.name;
          // in parsed document "parsedDocument" field might be does not exists, because it was filtered out
          // accordingly to the effective permissions

          if (
            !isHasProperty(parsedDocument.fields, key) ||
            (isUndefinedValue(this.currentDocument.fields[key].value) &&
              isUndefinedValue(parsedDocument.fields[key].value))
          ) {
            return 'noChange';
          }

          let currentField = this.currentDocument.fields[key].value as unknown;
          let sourceField = parsedDocument.fields[key].value as unknown;

          switch (field.type) {
            case 'datetime':
              currentField = new Date(currentField as string).toISOString();
              sourceField = new Date(sourceField as string).toISOString();
              break;
            case 'attachments':
              return this.documentService.isAttachmentChanged(
                currentField as IAttachment[],
                sourceField as IAttachment[]
              )
                ? 'hasChange'
                : 'noChange';
            case 'documents':
              return this.compareAgendaItems(sourceField as TreeViewItem[]) ? 'hasChange' : 'noChange';
            default:
              break;
          }
          currentField = this.documentService.removeCompositeId(currentField as IPermissionTarget);
          sourceField = this.documentService.removeCompositeId(sourceField as IPermissionTarget);
          return JSON.stringify(currentField) === JSON.stringify(sourceField) ? 'noChange' : 'hasChange';
        });
      }),
      map(checkChanges => checkChanges.includes('hasChange'))
    );
  };

  // ----------------------------------------------------------------------------------------------
  // Methoden für Debug Popup
  // ----------------------------------------------------------------------------------------------
  closeDebugPopup = (): void => {
    this.isDebugPopup = false;
  }

  toogleFullscreen = (): void => {
    this.isFullscreen = !this.isFullscreen;
  }

  copyDocId = (): void => {
    this.clipboard.copy(this.currentDocument.id);
    NotifyService.global.success('Die Dokument-ID wurde in die Zwischenablage kopiert.');
  }

  expandJson = (): void => {
    this.isJsonRestoreExpanded = false;
    this.jsonPreviouslyOpenKeys = {};
    this.isJsonExpanded = !this.isJsonExpanded;
    if (!this.isJsonExpanded) {
      this.expandButtonText = 'Alles aufklappen';
      this.expandButtonIcon = 'expand';
    } else {
      this.expandButtonText = 'Alles zuklappen';
      this.expandButtonIcon = 'collapse';
    }
  }

  previewJson = (event: SwitchValueChangedEvent): void => {
    this.isJsonRestoreExpanded = true;
    this.isJsonPreview = event.value as boolean;
  }

  private avatarMouseOver(event: MouseEvent): void {
    if (this.avatarPopup) {
      return;
    }
    const element = event.target as HTMLAnchorElement;
    const src = element.getAttribute('src');
    const x = event.pageX - 50;
    const y = event.pageY - 50;
    const popup = document.createElement('div');
    popup.setAttribute('class', `user-avatar-tooltip__active`);
    popup.style.backgroundImage = `url(${src})`;
    popup.style.top = y.toString() + 'px';
    popup.style.left = x.toString() + 'px';
    document.body.appendChild(popup);
    this.avatarPopup = popup;
  }

  private avatarMouseOut(): void {
    if (this.avatarPopup) {
      this.avatarPopup.remove();
      this.avatarPopup = null;
    }
  }

  private checkSignature(): void {
    const history = this.workflowDocument?.fields?.history;
    if (history && Array.isArray(history.value)) {
      for (const entry of history.value as IHistoryField[]) {
        if (entry.variables?.is_workflow_signature) {
          this.isHasSignature = true;
        }
        if (entry.targetVertex?.flags?.includes('resetWorkflowSignatures')) {
          this.isHasSignature = false;
        }
      }
    }
  }

  private correctFormula(formula: string): string {
    const prefix = 'exports.result';
    if (formula.includes(prefix)) {
      return formula;
    }
    return `${prefix} = ${formula}`;
  }

  private createFormItem(entry: BaseLayoutEntryServ, template: ITemplateServer): FieldTypes {
    // TODO: item.cssClass = `draggable_${btoa(item.name)}`;
    const type = entry.type as string;
    switch (type) {
      case 'group': {
        const grpEntry = entry as LayoutGroupEntryServ;
        let groupClasses = grpEntry?.additionalData?.cssClass
          ? `group-item group-color-${grpEntry.additionalData.cssClass}`
          : `group-item group-colorless`;
        groupClasses = groupClasses.concat(` siam-group-id-${grpEntry.id}`);
        groupClasses = grpEntry?.additionalData?.isGroupClosed ? groupClasses.concat(` close-group`) : groupClasses;
        groupClasses = grpEntry?.additionalData?.hint?.length
          ? groupClasses.concat(` group-title-required`)
          : groupClasses;
        groupClasses = grpEntry?.additionalData?.labelVisible
          ? groupClasses.concat(` is-caption-visible`)
          : groupClasses;
        const visible: boolean = grpEntry?.visibleFormula
          ? false
          : grpEntry.visible !== undefined
            ? grpEntry.visible
            : true;
        return new Group({
          id: grpEntry.id,
          name: grpEntry.name,
          colCount: grpEntry?.additionalData?.colCount,
          colSpan: grpEntry?.additionalData?.colSpan,
          cssClass: groupClasses,
          label: { text: grpEntry.label, visible: grpEntry?.additionalData?.labelVisible },
          parentContainerName: grpEntry.parentContainerName,
          caption: grpEntry.label,
          editorOptions: {
            elementAttr: { id: grpEntry.id },
            fieldVisible: visible,
            visibility: grpEntry.visibleFormula ? 'formular' : visible,
            visibleFormula: grpEntry ? grpEntry.visibleFormula : null,
            width: grpEntry && grpEntry.width ? grpEntry.width : fieldConstant.width,
            hint: grpEntry.additionalData?.hint
          }
        });
      }
      case 'placeholder': {
        const grpEntry = entry as LayoutEntryPlaceholderServ;
        let groupClasses = grpEntry?.additionalData?.cssClass
          ? `group-item group-color-${grpEntry.additionalData.cssClass}`
          : `group-item group-colorless`;
        groupClasses = grpEntry?.additionalData?.isGroupClosed ? groupClasses.concat(` close-group`) : groupClasses;
        groupClasses = grpEntry?.additionalData?.hint?.length
          ? groupClasses.concat(` group-title-required`)
          : groupClasses;
        groupClasses = grpEntry?.additionalData?.labelVisible
          ? groupClasses.concat(` is-caption-visible`)
          : groupClasses;

        const containerClient = createContainerField(grpEntry);
        containerClient.cssClass = groupClasses;
        return containerClient;
      }
      case 'spacer': {
        const spacerEntry = entry as LayoutSpacerEntryServ;
        const visible: boolean = spacerEntry?.visibleFormula
          ? false
          : spacerEntry.visible !== undefined
            ? spacerEntry.visible
            : true;
        return new Empty({
          id: spacerEntry.id,
          name: spacerEntry.name || `spacer-${spacerEntry.id}`,
          colCount: spacerEntry?.additionalData?.colCount,
          colSpan: spacerEntry?.additionalData?.colSpan,
          cssClass: spacerEntry?.additionalData?.cssClass,
          label: { text: spacerEntry.label, visible: spacerEntry?.additionalData?.labelVisible },
          parentContainerName: spacerEntry.parentContainerName,
          caption: spacerEntry.label,
          editorOptions: {
            elementAttr: { id: spacerEntry.id },
            fieldVisible: visible,
            visibility: spacerEntry.visibleFormula ? 'formular' : visible,
            visibleFormula: spacerEntry ? spacerEntry.visibleFormula : null,
            width: spacerEntry && spacerEntry.width ? spacerEntry.width : fieldConstant.width
          }
        });
      }
      case 'field': {
        const field = template.fields.find(f => f.name === (entry as LayoutFieldEntryServ).fieldName);
        return createField(template, field, entry as LayoutFieldEntryServ);
      }

      default:
        // TODO: Unbekannter Typ!
        throw Error(`Unknown entry type: ${type}.`);
    }
  }

  private fillUsersAndRoles(): Observable<unknown> {
    // -- User & Rollendaten laden, falls benötigt
    this.usersAndRolesArray = [];
    const users$ = this.userService.getAllUsers().pipe(
      map(users => ({
        type: 'users',
        entries: users
          .map(user => ({
            id: user.compositeId,
            name: user.displayName,
            fullName: user.fullName,
            visible: !user.isArchived && !!user.roles?.length,
            selectorName: user.selectorName,
            userName: user.name,
            profile: user.profile,
            roles: user.roles
          }))
          .sort((a, b) =>
            a.selectorName.toLowerCase() > b.selectorName.toLowerCase()
              ? 1
              : b.selectorName.toLowerCase() > a.selectorName.toLowerCase()
                ? -1
                : 0
          )
      })),
      tap(item => {
        this.usersDataStore.push(item.entries.map(entry => ({ type: 'insert', data: entry })));
        this.usersAndRolesDataStore.push(item.entries.map(entry => ({ type: 'insert', data: entry })));
        item.entries.forEach(i => this.usersAndRolesArray.push(i));
      })
    );

    const roles$ = this.roleService.getAllRoles().pipe(
      map(roles => ({
        type: 'roles',
        entries: roles
          .map(role => ({ id: role.compositeId, name: role.name, users: role.users, visible: !role.isArchived }))
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
          )
      })),
      tap(item => {
        this.rolesDataStore.push(item.entries.map(entry => ({ type: 'insert', data: entry })));
        this.usersAndRolesDataStore.push(item.entries.map(entry => ({ type: 'insert', data: entry })));
        item.entries.forEach(i => this.usersAndRolesArray.push(i));
      })
    );

    return zip(users$, roles$);
  }

  private getCodeToInterpret(fields: Record<string, unknown>, formula: string): string {
    // remove special characters (line seperator)
    const stringFields = JSON.stringify(fields).replace(/\u2028/g, '');
    const stringDocument = JSON.stringify(this.currentDocument).replace(/\u2028/g, '');
    return `var fields = ${stringFields},
    document = ${stringDocument},
    media = 'screen';
    ${this.correctFormula(formula)}`;
  }

  private getDefaultVisiblityField(field: FieldTypes, fields: Record<string, unknown>): Observable<void> {
    return of(field).pipe(
      map(f => {
        const formula = field.editorOptions.visibleFormula.code;
        let result = false;
        if (formula.startsWith('media')) {
          result = this.interpret(`var media = 'screen';${this.correctFormula(formula)}`, field);
        } else {
          result = this.interpret(this.getCodeToInterpret(fields, formula), field);
        }
        field.editorOptions.fieldVisible = result;
        this.#mapFieldVisible.set(f.id, result);
      })
    );
  }

  private getFieldData(field: IDocumentField, templateField: FieldServer): unknown {
    // Wert gesetzt?
    if ((field.value === null || field.value === undefined) && field.value !== false) {
      return null;
    }

    // Ist es ein im Template definiertes Feld?
    if (!templateField) {
      return field.value;
    }

    // Namensfeld?
    if (templateField.type === 'permission-targets') {
      const permissionTargets = field.value as IPermissionTarget[];
      return permissionTargets.map(target => `${target.type}:${target.targetId}`);
    }
    // Auswahlfeld und Optionsfeld, wenn references type vorhanden ist, muss der compositeId verwenden werden
    if (templateField.type === 'dropdownlist' || templateField.type === 'radiogroup') {
      if ((field.value as IPermissionTarget).targetId && (field.value as IPermissionTarget).type) {
        return Factory.permissionTarget(field.value as IPermissionTarget)?.compositeId;
      } else if (
        Array.isArray(field.value) &&
        field.value.length === 1 &&
        (field.value[0] as IPermissionTarget).targetId &&
        (field.value[0] as IPermissionTarget).type
      ) {
        const permissionTargets = field.value as IPermissionTarget[];
        return permissionTargets.map(permission =>
          permission.type && permission.targetId ? `${permission.type}:${permission.targetId}` : permission
        )[0];
      } else if (
        Array.isArray(field.value) &&
        field.value.length > 1 &&
        (field.value[0] as IPermissionTarget).targetId &&
        (field.value[0] as IPermissionTarget).type
      ) {
        const target = (field.value as IPermissionTarget[]).map(v => Factory.permissionTarget(v)?.compositeId);
        return JSON.stringify(target);
      } else {
        return field.value;
      }
    }

    return field.value;
  }

  private getFirstField(source: FieldClient[] | Group): FieldClient {
    if (!source) {
      return null;
    }
    let items: (FieldClient | Group)[] = source as FieldClient[];
    if (!Array.isArray(source) && source.kind === 'group') {
      items = source.items as FieldClient[];
    }
    for (const field of items) {
      switch (field?.kind) {
        case 'staticText':
        case 'linkText': {
          continue;
        }
        case 'group':
          return this.getFirstField(field as Group);
        default:
          break;
      }
      return field as FieldClient;
    }
    return null;
  }

  private getFormData(): Observable<Record<string, unknown>> {
    const templateFields = this.currentDocument.template.fields.reduce((value, item) => {
      value[item.name] = item;
      return value;
    }, {} as { [key: string]: FieldServer });

    return from(Object.keys(this.currentDocument.fields)).pipe(
      map(key => ({
        fieldName: key,
        field: this.currentDocument.fields[key],
        templateField: templateFields[key]
      })),
      filter(item => !!item.templateField), // 'benutzerdefinierte' Felder aus resolvedDoc.fields ignorieren
      reduce(
        (
          formData,
          item: {
            fieldName: string;
            field: IDocumentField;
            templateField: FieldServer;
          }
        ) => {
          const value = this.getFieldData(item.field, item.templateField);
          switch (typeof value) {
            case 'boolean':
              formData[item.fieldName] = value;
              break;

            case 'number':
              formData[item.fieldName] = value;
              break;

            default:
              // eslint-disable-next-line
              formData[item.fieldName] = value || (item.templateField && item.templateField.default) || null;
              break;
          }
          return formData;
        },
        {} as Record<string, unknown>
      )
    );
  }

  /**
   * creating data for devextreme form
   */
  private getFormItems(template: ITemplateServer): Observable<FieldTypes[]> {
    this.#mapClassField = {};
    this.#mapFieldList = new Map<string, DataSource>();
    this.#mapFieldDraggable = new Map<string, IFieldElement>();
    let fields: FieldTypes[] = [];
    const mapFields = this.mapFormDataToDocumentFields();
    const fieldsValues: Record<string, unknown> = {};
    Object.keys(mapFields).forEach(key => {
      fieldsValues[key] = mapFields[key].value;
    });
    return concat(
      from(template.layouts).pipe(filter(l => l.name === this.templateLayout)),
      from(template.layouts).pipe(filter(l => l.name === 'default')),
      from(template.layouts).pipe(first())
    ).pipe(
      // Nur das erste gefundene Layout verwenden
      first(),
      // Die Einträge des Layouts
      switchMap(l => {
        const promises: Promise<void>[] = [];
        fields = [];
        for (const entry of l.entries) {
          const field = this.createFormItem(entry, template);
          if (entry.type === 'field') {
            const fieldValue = fieldsValues[field.dataField];
            const isArray = Array.isArray(fieldValue);
            const editorOptions = (field as CheckBoxGroup).editorOptions as Record<string, unknown>;
            if (Array.isArray(editorOptions?.dataSource)) {
              (editorOptions.dataSource as Record<string, unknown>[]).forEach(n => {
                const properties = n.properties as Record<string, boolean>;
                if (properties?.deactivated === true) {
                  let isFound: boolean;
                  if (isArray) {
                    isFound = fieldValue.some(value => value === n.value);
                  } else {
                    isFound = fieldValue === n.value;
                  }

                  if (isFound) {
                    n.disabled = true;
                    n.visible = true;
                  } else {
                    n.visible = false;
                  }
                }
              });
            }
            // Tooltip für die Felder ausblenden
            if (this.editMode !== 'Edit') {
              (field as FieldClient).editorOptions.hint = '';
            }
          }

          if (!this.draggableAllow) {
            switch (field.type) {
              case 'dxTagBox': {
                if (!this.#mapFieldNames.has(field.id)) {
                  promises.push(lastValueFrom(this.getDataSourceForNames(field as PermissionTarget)));
                }
              }
                break;

              case 'signature': {
                if (!this.mapShowSignatureDialog.has(field.id)) {
                  this.mapShowSignatureDialog.set(field.id, false);
                }
              }
                break;

              case 'dxSelectBox':
              case 'dxRadioGroup': {
                if (!this.#mapFieldList.has(field.id)) {
                  this.#mapFieldList.set(field.id, new DataSource(null));
                  promises.push(lastValueFrom(this.getDataSourceForlistFields(field as SelectBox, fieldsValues)));
                }
              }
            }

            if (field.editorOptions?.visibleFormula) {
              if (!this.#mapFieldVisible.has(field.id)) {
                this.#mapFieldVisible.set(field.id, false);
                promises.push(lastValueFrom(this.getDefaultVisiblityField(field, fieldsValues)));
              }
            } else {
              if (!this.#mapFieldVisible.has(field.id)) {
                this.#mapFieldVisible.set(field.id, field.editorOptions?.fieldVisible);
              }
            }
          }
          fields.push(field);
        }
        return Promise.all(promises);
      }),
      map(() => {
        if (this.draggableAllow) {
          return fields;
        }
        for (const field of fields) {
          const editorOptions = (field as FieldClient).editorOptions;
          if (!editorOptions) {
            continue;
          }

          switch (field.type) {
            case 'dxTagBox':
              (editorOptions as ITagBoxEditorOptions).dataSource = this.#mapFieldNames.get(field.id);
              break;

            case 'dxSelectBox':
            case 'dxRadioGroup': {
              if (editorOptions.isTreeListParent && !editorOptions.isTreeListChild) {
                const choices = this.#mapFieldList.get(field.id);
                choices.filter(['properties.parentId', '=', null]);
                void choices.load();
                (editorOptions as ISelectBoxEditorOptions).dataSource = choices;
              } else if (editorOptions.isTreeListChild) {
                const parentFieldName = editorOptions.treeListParentFieldName;
                const choices = this.#mapFieldList.get(field.id);
                if (parentFieldName && fieldsValues[parentFieldName]) {
                  choices.filter(['properties.parentId', '=', fieldsValues[parentFieldName]]);
                } else {
                  choices.filter(['!', ['properties.parentId', '=', null]]);
                }
                void choices.load();
                (editorOptions as ISelectBoxEditorOptions).dataSource = choices;
              }
            }
              break;
          }

          if (editorOptions.visibleFormula) {
            editorOptions.fieldVisible = this.#mapFieldVisible.get(field.id);
          }
        }

        const result: FieldTypes[] = [];
        for (const field of fields) {
          // Da muss es doch was Besseres geben, um nur Felder zu prüfen?
          if (this.currentDocument.fields[field.name] && field.type !== 'group') {
            if (this.currentDocument.fields[field.name].effectivePermissions.includes('read')) {
              result.push(field);
            }
          } else {
            result.push(field);
          }
        }

        return result;
      }),
      map(items => {
        const rootEntry: RootEntries = {
          containers: {},
          entries: []
        };

        const length = items.length;
        for (let index = 0; index < length; index++) {
          const item = items[index];
          item.editorOptions.elementAttr = { id: item.id };
          if (!this.draggableAllow) {
            if (item.kind === 'placeholder') {
              const containerName = `--siam-container-${item.name}`;
              const containerField =
                this.currentDocument.fields[containerName] || this.currentDocument.fields[item.name];
              item.editorOptions.readOnly = containerField?.effectivePermissions
                ? !containerField.effectivePermissions.includes('update')
                : item.editorOptions.readOnly;
              item.editorOptions.fieldVisible = containerField?.effectivePermissions
                ? containerField.effectivePermissions.includes('read') && item.editorOptions.fieldVisible
                : item.editorOptions.fieldVisible;
            }
            if (
              item &&
              item.name &&
              this.currentDocument.fields[item.name] &&
              this.currentDocument.fields[item.name].effectivePermissions
            ) {
              if (item.kind !== 'group' && item.kind !== 'placeholder' && item.kind !== 'staticText') {
                item.editorOptions.readOnly =
                  !this.currentDocument.fields[item.name].effectivePermissions.includes('update');
              }
              if (
                item.kind !== 'group' &&
                !this.currentDocument.fields[item.name].effectivePermissions.includes('read')
              ) {
                item.editorOptions.fieldVisible = false;
              }
            }
            if (item.editorOptions.requiredOption === 'required-if-visible') {
              item.cssClass = item.cssClass.replaceAll('required-if-visible', '');
              item.cssClass = item.cssClass.concat(' required-if-visible');
              item.validationRules = [
                {
                  type: 'custom',
                  reevaluate: true,
                  message: `Bitte füllen Sie das Feld "${item.label.text}" aus.`,
                  validationCallback: (options: IDxItemValidationCallback<string>): boolean => {
                    const needleVisible = this.#mapFieldVisible.get(item.id);
                    if (!(this.isParentItemVisible(item) && needleVisible)) {
                      return true;
                    } else {
                      switch (item.type) {
                        case 'dxCheckboxGroup': {
                          const name = options.validator.option('name');
                          const fieldName = name.split('-')[0];
                          const indexItem = name.split('-')[2];
                          const value = this.formData[fieldName] as string[];
                          if (options.value) {
                            return true;
                          }
                          return !(!value?.length && indexItem === '0');
                        }
                        case 'dxFileUploader': {
                          const fieldName = item.name;
                          const attachments = this.formData[fieldName] as unknown[];
                          if (!attachments?.length) {
                            if (!options.value || !options.value.length) {
                              return false;
                            }
                          }

                          return true;
                        }

                        default: {
                          return !(
                            options.value === undefined ||
                            options.value === null ||
                            options.value === '' ||
                            (Array.isArray(options.value) && !options.value?.length)
                          );
                        }
                      }
                    }
                  }
                }
              ];
            } else {
              item.cssClass = item.cssClass.replaceAll('required-if-visible', '');
              if (item.editorOptions.requiredOption === true && item.type !== 'group' && item.type !== 'placeholder') {
                (item as FieldClient).isRequired = true;
                switch (item.type) {
                  case 'dxCheckboxGroup': {
                    item.validationRules = [
                      {
                        type: 'custom',
                        reevaluate: true,
                        message: `Bitte füllen Sie das Feld "${item.label.text}" aus.`,
                        validationCallback: (options: IDxItemValidationCallback<string>): boolean => {
                          const name = options.validator.option('name');
                          const fieldName = name.split('-')[0];
                          const indexItem = name.split('-')[2];
                          const value = this.formData[fieldName] as string[];
                          if (options.value) {
                            return true;
                          }
                          return !(!value?.length && indexItem === '0');
                        }
                      }
                    ];
                  }
                    break;

                  case 'dxFileUploader': {
                    item.validationRules = [
                      {
                        type: 'custom',
                        reevaluate: true,
                        message: `Bitte füllen Sie das Feld "${item.label.text}" aus.`,
                        validationCallback: (options: IDxItemValidationCallback<string>): boolean => {
                          const fieldName = item.name;
                          const attachments = this.formData[fieldName] as unknown[];
                          if (!attachments?.length) {
                            if (!options.value || !options.value.length) {
                              return false;
                            }
                          }
                          return true;
                        }
                      }
                    ];
                  }
                    break;
                  default:
                    break;
                }
              }
            }

            item.editorOptions.validationRules = item.validationRules;
            if (item.editorOptions.fieldVisible) {
              item.cssClass = item.cssClass.replaceAll('field-visible', '').replaceAll('field-invisible', '');
              item.cssClass = item.cssClass.concat(' field-visible');
            } else {
              item.cssClass = item.cssClass.replaceAll('field-visible', '').replaceAll('field-invisible', '');
              item.cssClass = item.cssClass.concat(' field-invisible');
            }
          }
          if (this.draggableAllow) {
            item.editorOptions.fieldVisible = true;
            if (item.kind === 'group') {
              item.cssClass = item.cssClass.replaceAll('close-group', '');
              item.label.visible = true;
            }
          }
          // CSS-Klasse setzen für Drag_&_Drop
          const draggableItem = `draggable_${btoa(item.name)}`;
          item.cssClass = item.cssClass.concat(` ${draggableItem}`);
          if (!this.#mapFieldDraggable.has(draggableItem)) {
            this.#mapFieldDraggable.set(draggableItem, { entry: item, index });
          }
          this.#mapClassField[draggableItem] = {
            entry: item,
            index
          };
          if (item.kind === 'group') {
            rootEntry.containers[item.name] = item as Group;
            if (item.label && !item.label.visible) {
              item.caption = undefined;
            }
          }
          if (item.kind === 'dxCheckboxGroup') {
            if ((item as FieldClient).editorOptions.checkboxGroupColumns > 0) {
              const longestWord = (item as FieldClient).editorOptions.choices?.reduce((c, v) =>
                c.label?.length > v.label?.length ? c : v
              );
              const element = this.elem.nativeElement.querySelector('.bg-content');
              (item as FieldClient).editorOptions.checkboxGroupColumnWidth = longestWord
                ? getStringLength(longestWord?.label, element, 'dx-widget') + 40
                : 200;
            }
          }
          if (this.editMode !== 'Edit') {
            item.editorOptions.readOnly = true;
          }
          if (!item.name.startsWith('--siam-container-')) {
            if (!item.parentContainerName) {
              rootEntry.entries.push(item);
            } else {
              if (rootEntry.containers[item.parentContainerName]?.items) {
                rootEntry.containers[item.parentContainerName].items.push(item);
              }
            }
          }
        }
        // Gruppen ohne Inhalt verstecken
        Object.values(rootEntry.containers)
          .reverse()
          .forEach(value => {
            if (
              !value.items?.length ||
              value.items?.every(childItem => childItem.type === 'empty') ||
              value.items?.every(childItem => childItem.type === 'group' && !(childItem as Group).items?.length)
            ) {
              rootEntry.containers[value.name].cssClass = rootEntry.containers[value.name].cssClass
                .replaceAll('field-visible', '')
                .replaceAll('field-invisible', '');
              rootEntry.containers[value.name].cssClass =
                rootEntry.containers[value.name].cssClass.concat(' field-invisible');
            }
          });
        return rootEntry;
      }),
      // Nur die Einträge auf der obersten Ebene als FormItems-Objekt zurückliefern
      switchMap(rootEntries =>
        of([
          new Group({
            id: `custom-${uuid.v4()}`,
            caption: null,
            items: rootEntries.entries
          })
        ])
      )
    );
  }

  private handleInterpreterError(field: FieldTypes, message: string): void {
    this.logger.warn('Fehler {0} in die Bedingung vom Feld {1}', message, field.label);
  }

  private isParentItemVisible(needle: FieldTypes): boolean {
    if (needle.parentContainerName) {
      for (const entry of this.templateClient.fields) {
        if (entry.kind === 'group') {
          if (entry.name === needle.parentContainerName) {
            if (entry.editorOptions.fieldVisible) {
              if (entry.parentContainerName) {
                return this.isParentItemVisible(entry);
              }
            }
            return this.#mapFieldVisible.get(entry.id);
          }
        }
      }
    }
    return this.#mapFieldVisible.get(needle.id);
  }

  private getAllSpeakers(): IPermissionTarget[] {
    const speakers: IPermissionTarget[] = [];
    const agendaItems = this.agendaData;
    if (Array.isArray(agendaItems)) {
      agendaItems.forEach(parentItem => {
        parentItem.speakers?.forEach(speaker => {
          if (!speakers.find(s => s.targetId === speaker.targetId && s.type === speaker.type)) {
            speakers.push(speaker);
          }
        });
        parentItem.children?.forEach(childItem => {
          childItem.speakers?.forEach(speaker => {
            if (!speakers.find(s => s.targetId === speaker.targetId && s.type === speaker.type)) {
              speakers.push(speaker);
            }
          });
        });
      });
    }
    return speakers;
  }

  private getAgendaEndDate(): string {
    const agendaItems = this.agendaData;
    const startdate = this.currentDocument.fields['startdate']?.value as string;
    let result: Date = startdate ? new Date(startdate) : null;
    if (agendaItems && agendaItems.length) {
      result = new Date(agendaItems[agendaItems.length - 1].endTime);
      // Run change detection explicitly after the change
    }
    return result instanceof Date ? dateToIsoString(result) : null;
  }

  private mapAgendaItemsToServer(): IAgendaServer[] {
    const items: IAgendaServer[] = [];
    const agendaItems = this.agendaData;
    if (Array.isArray(agendaItems)) {
      const documentIds: string[] = [];
      agendaItems.forEach(parent => {
        if (!documentIds.find(id => parent.documentId === id)) {
          items.push(this.mapAgendaItemClientToServer(parent));
          documentIds.push(parent.documentId);
        }
        if (parent.children?.length) {
          const parentIndex = items.findIndex(i => i.documentId === parent.documentId);
          if (parentIndex > -1) {
            items[parentIndex].children = [];
            parent.children.forEach(child => {
              if (!documentIds.find(id => child.documentId === id)) {
                items[parentIndex].children.push(this.mapAgendaItemClientToServer(child));
                documentIds.push(child.documentId);
              }
            });
          }
        }
      });
    }
    return items;
  }

  private mapAgendaItemClientToServer(item: IAgenda): IAgendaServer {
    const result: Record<string, unknown> = {};
    Object.keys(item).forEach(key => {
      switch (key) {
        case 'startTime':
        case 'endTime':
        case 'totalEndTime':
          result[key] = item[key] instanceof Date ? dateToIsoString(item[key]) : (item[key] as unknown as string);
          break;
        case 'children':
          result[key] = item[key].map(child => this.mapAgendaItemClientToServer(child));
          break;
        case 'document':
        case 'attachment':
        case 'invalid':
          // nothing to do
          break;
        default:
          result[key] = item[key as keyof IAgenda];
          break;
      }
    });
    return result as unknown as IAgendaServer;
  }

  private mapFormDataToDocumentFields(): IDocumentFields {
    return Object.keys(this.formData)
      .map(key => ({
        key,
        field: {
          value: this.formData[key]
        }
      }))
      .reduce((fields, item) => {
        const field = this.currentDocument.fields[item.key];
        // include or exclude document from the payload
        let isInclude = true;
        if (field && field.effectivePermissions) {
          const permissions = field.effectivePermissions;
          if (permissions.includes('list')) {
            // if field has 'list' value should be set to null
            item.field.value = null;
          } else if (!permissions.includes('read') && !permissions.includes('update')) {
            // if field does not have any permissions - it should be excluded from payload
            isInclude = false;
          }
        }

        if (isInclude) {
          const namesField = this.currentDocument.template.fields.find(
            f => f.name === item.key && f.type === 'permission-targets'
          );
          const choicesField = this.currentDocument.template.fields.find(
            f => f.name === item.key && ['radiogroup', 'dropdownlist', 'checkboxgroup'].includes(f.type)
          );
          if (choicesField !== undefined) {
            let permissions: IPermissionTarget[] = [];
            const fieldValue = item.field.value;
            if (
              fieldValue &&
              Array.isArray(fieldValue) &&
              fieldValue.length &&
              (fieldValue[0] as IPermissionTarget).type &&
              (fieldValue[0] as IPermissionTarget).targetId
            ) {
              for (const val of fieldValue as IPermissionTarget[]) {
                permissions.push({
                  targetId: val.targetId,
                  type: val.type
                });
              }
            } else if (fieldValue && this.isJsonString(fieldValue as string)) {
              const array = JSON.parse(fieldValue as string) as unknown[];
              if (Array.isArray(array)) {
                array?.map(a => {
                  if ((a as IPermissionTarget).targetId && (a as IPermissionTarget).type) {
                    permissions.push({
                      targetId: (a as IPermissionTarget).targetId,
                      type: (a as IPermissionTarget).type
                    });
                  } else if (typeof a === 'string') {
                    if (a.startsWith('user') || a.startsWith('role')) {
                      const permissionTarget: IPermissionTarget = Factory.createPermissionTargetWithoutCompositeId(a);
                      permissions.push({
                        targetId: permissionTarget.targetId,
                        type: permissionTarget.type
                      });
                    }
                  }
                });
              }
            } else if (fieldValue && typeof fieldValue === 'string') {
              if (fieldValue.startsWith('user') || fieldValue.startsWith('role')) {
                const permissionTarget: IPermissionTarget =
                  Factory.createPermissionTargetWithoutCompositeId(fieldValue);
                permissions.push({
                  targetId: permissionTarget.targetId,
                  type: permissionTarget.type
                });
              }
            } else {
              permissions = null;
            }

            fields[item.key] = { value: permissions?.length ? permissions : fieldValue };
          } else if (namesField !== undefined) {
            let permissions: IPermissionTarget[] = [];
            if (item.field.value) {
              for (const val of item.field.value as unknown[]) {
                let source = val;
                if ((source as IPermissionTarget).targetId && (source as IPermissionTarget).type) {
                  permissions.push({
                    type: (source as IPermissionTarget).type,
                    targetId: (source as IPermissionTarget).targetId
                  });
                } else {
                  if (typeof val !== 'string') {
                    source = (val as IUserRoleEntry).id || (val as IPermissionTarget).compositeId;
                    permissions.push(Factory.createPermissionTargetWithoutCompositeId(source as string));
                  }
                  if (typeof val === 'string' && (val.startsWith('user') || val.startsWith('role'))) {
                    source = val;
                    permissions.push(Factory.createPermissionTargetWithoutCompositeId(source as string));
                  }
                }
              }
            } else {
              permissions = null;
            }
            fields[item.key] = { value: permissions };
          } else {
            fields[item.key] = item.field;
          }
        }
        return fields;
      }, {} as IDocumentFields);
  }

  /* check if a string is of type JSON */
  private isJsonString = (data: string): boolean => {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return !(typeof data === 'number' || typeof JSON.parse(data) === 'number');
  };

  private interpret(code: string, field: FieldTypes): boolean {
    let result = false;
    try {
      this.#interpreter.run(code);
      result = this.#interpreter.exports.result as boolean;
    } catch (e) {
      // we can ignore errors inside interpreter
      this.handleInterpreterError(field, (e as Error).message);
    }
    return result;
  }

  private isFastDeepEqualAgenda = (x: IAgenda[], y: IAgenda[]): boolean => {
    x.forEach(i => {
      delete i.document;
      i.children.forEach(child => delete child.document);
    });
    y.forEach(i => {
      delete i.document;
      i.children.forEach(child => delete child.document);
    });
    return isFastDeepEqual(x, y);
  };

  private compareAgendaItems = (items: TreeViewItem[]): boolean => Factory.copy(items)
    ?.filter(item => item.document)
    ?.map(item => {
      return Object.keys(item.document.fields).map(key => {
        const field = item.document.fields[key];
        // in parsed document "parsedDocument" field might be not exist, because it was filtered out
        // accordingly to the effective permissions

        if (!isHasProperty(item.originalDocument.fields, key)) {
          return 'hasChange';
        }

        let currentField = item.document.fields[key].value as unknown;
        let sourceField = item.originalDocument.fields[key].value as unknown;
        switch (field.valueType) {
          case 'datetime':
          case 'timeStamp':
            currentField = new Date(currentField as string).toISOString();
            sourceField = new Date(sourceField as string).toISOString();
            break;
          case 'attachments':
            return this.documentService.isAttachmentChanged(
              currentField as IAttachment[],
              sourceField as IAttachment[]
            )
              ? 'hasChange'
              : 'noChange';
          default:
            break;
        }
        currentField = this.documentService.removeCompositeId(currentField as IPermissionTarget);
        sourceField = this.documentService.removeCompositeId(sourceField as IPermissionTarget);
        if (currentField === null && sourceField === null) {
          return 'noChange';
        }
        return JSON.stringify(currentField) === JSON.stringify(sourceField) ? 'noChange' : 'hasChange';
      });
    })
    .map(checkChanges => checkChanges.includes('hasChange'))
    .includes(true);

  private setAgendaData(document: IDocument, data: IAgendaData): void {
    if (isUndefinedValue(data.list)) {
      data.list = this.mapAgendaItemsToServer();
    }

    if (isUndefinedValue(data.speakers)) {
      data.speakers = this.getAllSpeakers();
    }

    if (isUndefinedValue(data.endDate)) {
      data.endDate = this.getAgendaEndDate();
    }

    document.fields[FIELDNAME_AGENDALIST] = { value: data.list };
    document.fields[FIELDNAME_AGENDASPEAKERS] = { value: data.speakers, valueType: 'references' };
    document.fields[FIELDNAME_AGENDAENDDATE] = { value: data.endDate, valueType: 'timeStamp' };
  }
}
