import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@services/user.service';
import { RoleService } from '@services/roles.service';
import { IPermissionTarget } from '@interfaces/siam';
import { isHasProperty } from '@factories/helpers';
import { createPermissionTarget } from '@factories/document.factory';

@Pipe({
  name: 'mapAuthorName'
})
export class MapAuthorNamePipe implements PipeTransform {
  constructor(private userService: UserService, private roleService: RoleService) {
  }

  transform(value: unknown): Observable<unknown> {
    if (Array.isArray(value)) {
      const users = (value as (IPermissionTarget | string)[]).filter(
        v =>
          (v as IPermissionTarget).type === 'user' ||
          (v as IPermissionTarget).compositeId?.startsWith('user:') ||
          (typeof v === 'string' && v?.startsWith('user:'))
      );
      const roles = (value as (IPermissionTarget | string)[]).filter(
        v =>
          (v as IPermissionTarget).type === 'role' ||
          (v as IPermissionTarget).compositeId?.startsWith('role:') ||
          (typeof v === 'string' && v?.startsWith('role:'))
      );
      const usersIds = users.map(
        user => (user as IPermissionTarget).targetId || createPermissionTarget(user as string)?.targetId
      );
      const rolesIds = roles.map(
        role => (role as IPermissionTarget).targetId || createPermissionTarget(role as string)?.targetId
      );
      if (!usersIds?.length && !rolesIds?.length) {
        return null;
      }

      const userNames = usersIds?.length
        ? this.userService.getUsers(usersIds).pipe(map(items => items.map(user => user.fullName)))
        : of([]);

      const roleNames = rolesIds.length
        ? this.roleService.getRoles(rolesIds).pipe(map(items => items.map(role => role.name)))
        : of([]);
      return zip(userNames, roleNames).pipe(
        map(data => {
          let newArray: string[] = [];
          data.filter(v => v.length).forEach(arr => (newArray = newArray.concat(arr)));
          return newArray.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).join(', ');
        })
      );
    } else if (isHasProperty(value, 'targetId') && isHasProperty(value, 'type')) {
      switch ((value as IPermissionTarget).type) {
        case 'user':
          return this.userService
            .getUser((value as IPermissionTarget).targetId)
            .pipe(map(userinfo => userinfo.displayName));

        case 'role':
          return this.roleService.getRole((value as IPermissionTarget).targetId).pipe(map(roleInfo => roleInfo.name));

        default:
          return of(null);
      }
    } else if (typeof value === 'string') {
      return this.userService.getUser(value).pipe(map(userinfo => userinfo.displayName));
    } else {
      return of(value);
    }
  }
}
