import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { LOCALE_ID } from '@angular/core';

export const getBaseUrl = (): string => document.getElementsByTagName('base')[0].href;

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: LOCALE_ID, useValue: 'de' },
  { provide: 'SIAM_CLIENT_VERSION', useValue: '3.5.0-alpha.4115'  }
] as StaticProvider[];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

