import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { lastValueFrom, map, Observable, of, switchMap } from 'rxjs';
import { UserService } from '@services/user.service';
import { RoleService } from '@services/roles.service';
import { LoggerService } from '@services/logger.service';
import { IAttachment, IDocument, IPossibleVote, IRole, IUser, IWorkflowDocument } from '@interfaces/siam';
import { DocumentService } from '@services/document.service';
import * as UserFactory from '@factories/user.factory';
import { RowPreparedEvent } from 'devextreme/ui/data_grid';

interface Voter {
  timestamp: string;
  name: string;
  vote: string;
  reason: string;
  avatarName?: string;
  user?: IUser;
  role?: IRole;
  voteLabel?: string;
}

interface BallotSummary {
  totalVotes: number;
  submittedVotes: number;
  positiveVotes: number;
  negativeVotes: number;
  possibleVotes: IPossibleVote;
  chartData: IChartData[];
}

interface IChartData {
  vote: string;
  count: number;
  isSuccess: boolean;
  voteDiagramColor?: string;
}

interface IPointData {
  argument: string;
  value: number;
  index: number;
  data: IChartData;
}

interface IBallot {
  caption: string;
  description: string;
  state: string;
  startTime: Date;
  lastChange: Date;
  ballotId: string;
  ballotDocument?: IWorkflowDocument;
  ballotStatus: Voter[];
  ballotSummary: BallotSummary;
  documentStatus?: IAttachment;
  hasReadAttachment?: boolean;
}

interface IVotes {
  description: string;
  reason: string;
  timestamp: string;
  vote: string;
  votedBy?: string;
}

@Component({
  selector: 'app-ballot-data-include',
  templateUrl: './ballot-data-include.component.html',
  styleUrls: ['./ballot-data-include.component.scss']
})
export class BallotDataIncludeComponent implements OnInit, OnChanges {
  @Input() currentDocument: IDocument;
  @Input() ballotDocuments: IWorkflowDocument[];

  dialogOpen = false;
  ballotDataStore: IBallot[] = [];
  voters: Voter[];
  currentUser: IUser;

  constructor(
    private logger: LoggerService,
    private userService: UserService,
    private roleService: RoleService,
    private documentService: DocumentService
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentUser = UserFactory.getCurrentUser();
    await this.getBallotData();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.ballotDocuments || changes?.ballotDocuments?.currentValue) {
      await this.getBallotData();
    }
  }

  /**
   * returns the ballot data for popup
   */
  async getBallotData(): Promise<void> {
    const _dataStore: IBallot[] = [];
    for (const ballotDocument of this.ballotDocuments) {
      const ballotStatus: Voter[] = [];
      const fields = ballotDocument.fields;
      this.logger.debug('current ballotDocument {@0}', ballotDocument);
      const votes: Record<string, IVotes> = ((fields.votes && fields.votes.value) as Record<string, IVotes>) || {};

      for (const key of Object.keys(votes)) {
        const [type, guid] = key.split(':', 2);
        const voteValue = votes[key] && votes[key].vote;
        let substitute = '';
        if (votes[key]?.votedBy) {
          const substitutedGuid = votes[key].votedBy.split(':', 2)[1];
          if (substitutedGuid !== guid) {
            const substitutedUser = await lastValueFrom(this.userService.getUser(substitutedGuid));
            substitute = `<div class="voter-substitute">(Vertretung durch ${substitutedUser.displayName})<div>`;
          }
        }
        const possibleVotes = fields.possibleVotes?.value as IPossibleVote[];
        const voteData = possibleVotes.find((value: IPossibleVote) => value.value === voteValue);

        if (guid) {
          if (type === 'user') {
            const user = await lastValueFrom(this.userService.getUser(guid));
            const userUrl = this.userService.getAvatarUrl(user);
            let name = '';
            let avatarName = '';

            if (!user.id) {
              name = '[Benutzer wurde nicht gefunden]';
              avatarName = `<div class="voter">
                              <img class="user-avatar-name" src="${userUrl}"/>
                              <div class="voter-profile">
                                <span>[Benutzer wurde nicht gefunden]</span>${substitute}
                              </div>
                            </div>`;
            } else {
              const department = user?.profile?.department || '';
              name = user.displayName;
              avatarName = `<div class="voter">
                                      <img class="user-avatar-name" src="${userUrl}"/>
                                        <div class="voter-profile">
                                          <span>${user.displayName}</span>
                                            ${department ? `<span class="voter-department">(${department})</span>` : ''}
                                            ${substitute}
                                       </div>
                                  </div>`;
            }

            ballotStatus.push({
              user,
              name,
              avatarName,
              timestamp: votes[key] && votes[key].timestamp,
              reason: votes[key] && votes[key].reason,
              vote: voteData && voteData.value,
              voteLabel: voteData && voteData.label
            });
          } else if (type === 'role') {
            const role = await lastValueFrom(this.roleService.getRole(guid));
            ballotStatus.push({
              role,
              name: role.name,
              timestamp: votes[key] && votes[key].timestamp,
              reason: votes[key] && votes[key].reason,
              vote: voteData && voteData.value,
              voteLabel: voteData && voteData.label
            });
          }
        }
      }

      let status = '';
      let statusDE = '';
      if (ballotDocument.fields.status) {
        const name = ballotDocument.fields.status.value as string;
        const workflow = ballotDocument.workflow.vertices.find(ver => ver.name === name);
        status = workflow && workflow.name;
        switch (status) {
          case 'in-progress':
            statusDE = 'In Abstimmung';
            break;
          case 'finished':
            statusDE = 'Beendet';
            break;
          case 'aborted':
            statusDE = 'Abgebrochen';
            break;
          default:
            break;
        }
      }

      let change: Date = new Date();
      if (ballotDocument.fields.lastChange) {
        change = new Date(ballotDocument.fields.lastChange.value as string);
        change = new Date(ballotDocument.fields.lastChange.value as string);
      }

      const startTime: Date = ballotDocument?.creation?.timestamp
        ? new Date(ballotDocument?.creation?.timestamp)
        : null;

      const totalVotes = Object.values(ballotDocument.fields.votes.value as IVotes).length;
      const submittedVotes = ballotDocument.fields['vote:total'].value as number;
      const possibleVotesValue = ballotDocument.fields.possibleVotes.value as IPossibleVote[];
      const isSuccessVotes = possibleVotesValue.filter((v: IPossibleVote) => v.isSuccess === true);
      const notSuccessVotes = possibleVotesValue.filter((v: IPossibleVote) => v.isSuccess === false);
      let totalSuccess = 0;
      let totalNegative = 0;
      isSuccessVotes.forEach((v: IPossibleVote) => {
        totalSuccess = totalSuccess + (ballotDocument.fields[`vote:by-name:${v.value}`].value as number);
      });
      notSuccessVotes.forEach((v: IPossibleVote) => {
        totalNegative = totalNegative + (ballotDocument.fields[`vote:by-name:${v.value}`].value as number);
      });

      const chartData: IChartData[] = [];
      possibleVotesValue.forEach((v: IPossibleVote) => {
        chartData.push({
          vote: v.label,
          count: ballotDocument.fields[`vote:by-name:${v.value}`].value as number,
          isSuccess: v.isSuccess,
          voteDiagramColor: v.properties?.voteDiagramColor
        });
      });

      const ballotSummary: BallotSummary = {
        totalVotes,
        submittedVotes,
        positiveVotes: totalSuccess,
        negativeVotes: totalNegative,
        possibleVotes: ballotDocument.fields.possibleVotes.value as IPossibleVote,
        chartData
      };
      const documentStatus = (ballotDocument.fields['audit-pdf-attachments']?.value as IAttachment[])?.length
        ? (ballotDocument.fields['audit-pdf-attachments']?.value as IAttachment[])[0]
        : null;
      let hasReadAttachment = false;
      hasReadAttachment = await lastValueFrom(this.checkAuditPermission(ballotDocument));

      _dataStore.push({
        state: statusDE,
        startTime,
        lastChange: change,
        caption: ballotDocument.fields.caption && (ballotDocument.fields.caption.value as string),
        description: ballotDocument.fields.description && (ballotDocument.fields.description.value as string),
        ballotId: ballotDocument.id,
        ballotDocument,
        ballotStatus,
        documentStatus,
        ballotSummary,
        hasReadAttachment
      });
      this.logger.debug('current voters: {@0}', ballotStatus);
    }
    _dataStore.sort((a, b) => (a.lastChange > b.lastChange ? 1 : b.lastChange > a.lastChange ? -1 : 0));
    this.ballotDataStore = _dataStore;
  }

  sortUsernames = (rowData: Voter): string => {
    if (rowData && rowData.user.profile) {
      return `${rowData.user.profile.surname}, ${rowData.user.profile.forename}`;
    } else {
      return rowData.name;
    }
  };

  onRowPrepared(e: RowPreparedEvent<Voter>): void {
    if (e?.data && !e.data.vote) {
      e.rowElement.style.opacity = '0.5';
    }
  }

  customizePoint = (arg: IPointData) => {
    if (arg.data.voteDiagramColor) {
      return { color: arg.data.voteDiagramColor };
    }
    return arg.data.isSuccess ? { color: '#38853b' } : { color: '#d73b30' };
  };

  private checkAuditPermission(ballotDocument: IWorkflowDocument): Observable<boolean> {
    return of(this.currentUser).pipe(
      switchMap(() => {
        if (!this.currentUser) {
          return of(false);
        }
        const auditData = (ballotDocument.fields['audit-pdf-attachments']?.value as Record<string, unknown>[])?.length
          ? (ballotDocument.fields['audit-pdf-attachments']?.value as Record<string, unknown>[])[0]
          : null;
        if (auditData) {
          const auditUser = auditData['audit-user'] as Record<string, string>;
          if (auditUser && this.currentUser.id === auditUser.UserId) {
            return of(true);
          }
          const auditVoters = auditData['audit-voters'] as Record<string, string>[];
          if (auditVoters?.length && auditVoters.find(v => v.UserId === this.currentUser.id)) {
            return of(true);
          }
          return this.currentUserHasDocumentPermission();
        }
        return of(false);
      })
    );
  }

  private currentUserHasDocumentPermission(): Observable<boolean> {
    return this.documentService.getDocumentTemplatePermissions(this.currentDocument).pipe(
      map(permissions => {
        for (const perm of permissions) {
          switch (perm.type) {
            case 'user':
              if (perm.user.id === this.currentUser.id) {
                return true;
              }
              break;
            case 'role':
              if (this.currentUser.roles.find(r => r.id === perm.role.id)) {
                return true;
              }
              break;
          }
        }
        return false;
      })
    );
  }
}
