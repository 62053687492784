import { Component, OnDestroy, OnInit } from '@angular/core';
import { isValidNotification } from '@factories/helpers';
import { UserNotification } from '@interfaces/userNotification';
import { NotificationsService } from '@services/notifications.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface NotificationStorage {
  id: string;
  status: string;
}

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  notifications: UserNotification[] = [];
  element: HTMLElement;
  totalNotifications: number;
  notificationStorage: NotificationStorage[] = [];
  notificationsVisible = false;

  private destroyed$ = new Subject<void>();
  dialogFullscreen = false;

  constructor(private userNotificationService: NotificationsService) {}

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.userNotificationService
      .getNotifications()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(data => {
        const notifications: UserNotification[] = [];
        data.forEach(notification => {
          if (isValidNotification(notification)) {
            notifications.push(notification);
          }
        });
        this.notifications = notifications;
        this.totalNotifications = notifications.length;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  showNotifications = (): void => {
    this.getData();
    if (this.totalNotifications) {
      this.notificationsVisible = true;
    }
  };

  closeNotifications = (): void => {
    this.notificationsVisible = false;
  };

  dialogToogleFullscreen = (): void => {
    this.dialogFullscreen = !this.dialogFullscreen;
  };
}
