import { Component } from '@angular/core';
import { INavigationGroup, TApplicationMode } from '@interfaces/siam';
import { environment } from '@env/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  applicationMode: TApplicationMode = 'dashboard';
  developmentMode: boolean;

  naviDataSource: INavigationGroup[];

  constructor() {
    this.developmentMode = !environment.production;

    this.naviDataSource = [
      {
        text: 'Managercockpit',
        isClosed: false,
        forAdminOnly: false,
        links: [
          {
            text: 'Aufgabenübersicht',
            name: 'aufgabenübersicht',
            icon: 'alarm_on',
            routerLink: ['tasks']
          }
        ]
      }
    ];

    if (this.developmentMode) {
      this.naviDataSource.push({
        text: 'Agilitätsindikatoren (dev)',
        isClosed: false,
        forAdminOnly: false,
        links: [
          {
            text: 'Durchlaufzeiten',
            name: 'durchlaufzeiten',
            icon: 'timeline',
            routerLink: ['general']
          },
          {
            text: 'Diagramme',
            name: 'diagramm',
            icon: 'timeline',
            routerLink: ['diagramm']
          },
          {
            text: 'Feedbackanalyse',
            name: 'feedbackanalyse',
            icon: 'feedback',
            routerLink: ['feedback']
          }
        ]
      });
    }
  }
}
