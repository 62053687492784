import { ThemeService } from '@services/theme.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { SharedataService } from '@services/sharedata.service';
import { LoginService } from '@services/login.service';
import { EMPTY, Subject, of } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DxFormComponent } from 'devextreme-angular';
import { LoggerService } from '@services/logger.service';
import { NotifyService } from '@services/notify.service';
import { IError } from '@interfaces/siam';
import { InitializedEvent } from 'devextreme/ui/form';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { siamConst } from '@interfaces/siamConst';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class LoginForm {
  username: string;
  password: string;
  isPersistent: boolean;
}

@Component({
  selector: 'app-startpage',
  templateUrl: './startpage.component.html',
  styleUrls: ['./startpage.component.scss']
})
export class StartpageComponent implements OnInit, OnDestroy {
  @ViewChild('formLogin') formLogin: DxFormComponent;

  loginForm: LoginForm;
  developmentMode: boolean;
  testingMode: boolean;
  submitDisabled = false;
  svgLogo: SafeHtml;
  pngLogo: string;

  #destroyable$ = new Subject<void>();

  constructor(
    public sharedData: SharedataService,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private themeService: ThemeService,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.sharedData.setstartpageClass(true);
    this.developmentMode = !environment.production;
    this.testingMode = environment.testing;
    this.pngLogo = siamConst.appLogoPng;
    this.route.fragment
      .pipe(
        filter(fragment => fragment !== 'reload'),
        switchMap(() => this.loginService.windowsAuth()),
        tap(user => {
          if (user) {
            this.logger.info('User info: {@info}', user);

            // reset start page css class
            this.sharedData.setstartpageClass(false);

            // set user and user avatar
            if (user) {
              this.sharedData.setUser(user);
            }
          }
        }),
        switchMap(() => this.themeService.getAppTheme()),
        tap(theme => {
          if (theme) {
            this.sharedData.setTheme(theme);
          }
        }),
        switchMap(() => this.themeService.getBackground()),
        tap(imageUrl => {
          if (imageUrl) {
            this.sharedData.setBackground(imageUrl);
          }
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: () => {
          void (async () => {
            // bei Redirect zu gespeicherter Seite springen, sonst 'normal' in den Userbereich
            const redirectTo = this.loginService.redirectUrl
              ? this.router.parseUrl(this.loginService.redirectUrl)
              : '/application/home';
            // Redirect the user
            this.logger.debug('Weiterleitung nach: {0}', redirectTo.toString());
            await this.router.navigateByUrl(redirectTo);
          })();
        }
      });

    this.route.fragment
      .pipe(
        filter(fragment => fragment === 'reload'),
        tap(() => {
          this.sharedData.setTheme(null);
          this.sharedData.setBackground(null);
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe();

    /* get app logo */
    this.httpClient
      .get(siamConst.appLogoSvg, { responseType: 'text' })
      .pipe(catchError(error => this.handleError(error as HttpErrorResponse)))
      .subscribe({
        next: value => {
          if (value) {
            this.svgLogo = this.sanitizer.bypassSecurityTrustHtml(value as string);
          }
        }
      });
  }

  ngOnInit(): void {
    this.submitDisabled = false;
    this.loginForm = { username: '', password: '', isPersistent: false };
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  setFocus(e: InitializedEvent): void {
    setTimeout(() => {
      e.component.focus();
    }, 0);
  }

  login(): void {
    const result = this.formLogin.instance.validate();
    if (!result) {
      return;
    }
    this.submitDisabled = true;
    this.loginService
      .login(this.loginForm.username, this.loginForm.password, this.loginForm.isPersistent)
      .pipe(
        tap(user => {
          if (user) {
            this.logger.info('User info: {@info}', user);

            // reset start page css class
            this.sharedData.setstartpageClass(false);

            // set user and user avatar
            if (user) {
              this.sharedData.setUser(user);
            }
          }
        }),
        switchMap(() => this.logger.getLogLevel()),
        switchMap(() => this.themeService.getAppTheme()),
        tap(theme => {
          if (theme) {
            this.sharedData.setTheme(theme);
          }
        }),
        switchMap(() => this.themeService.getBackground()),
        tap(imageUrl => {
          if (imageUrl) {
            this.sharedData.setBackground(imageUrl);
          }
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: () => {
          // bei Redirect zu gespeicherter Seite springen, sonst 'normal' in den Userbereich
          const redirectTo = this.loginService.redirectUrl
            ? this.router.parseUrl(this.loginService.redirectUrl)
            : '/application/home';
          // Redirect the user
          this.logger.debug('Weiterleitung nach: {0}', redirectTo.toString());
          void this.router.navigateByUrl(redirectTo);
          this.submitDisabled = false;
        },
        error: (error: IError) => {
          this.submitDisabled = false;
          NotifyService.component.error(error);
          this.loginForm.password = '';
          void this.formLogin.instance._refresh();
        }
      });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == 404 || error.status == 400) {
      NotifyService.destroyNotifications();
      return of(false);
    }
    return EMPTY;
  }
}
