import { Injectable } from '@angular/core';
import { IApprovalForm, IDocument } from '@interfaces/siam';
import { copy } from '@factories/document.factory';

type ApprovalFormKey = keyof IApprovalForm;

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {
  readonly #prefix = '--approval_';

  bindApprovalForm(formData: IApprovalForm, document: IDocument): IDocument {
    const copyDocument = copy(document);
    Object.keys(formData).forEach(fieldName => {
      copyDocument.fields[`${this.#prefix}${fieldName}`] = {
        value: this._getKeyValue(fieldName as ApprovalFormKey)(formData)
      };
    });
    copyDocument.tags.push('app:document-type:decision');
    return copyDocument;
  }

  removeApproval(document: IDocument): IDocument {
    const copyDocument = copy(document);
    const index = copyDocument.tags.indexOf('app:document-type:decision');
    Object.keys(document.fields).forEach(fieldName => {
      if (fieldName.includes(this.#prefix)) {
        copyDocument.fields[fieldName] = { value: null };
      }
    });
    if (index !== -1) {
      copyDocument.tags.splice(index, 1);
    }
    return copyDocument;
  }

  getApprovalData(doc: IDocument): IApprovalForm {
    const approvalFields = Object.keys(doc.fields).filter(fieldName => fieldName.startsWith(this.#prefix));

    if (!approvalFields.length) {
      return null;
    }

    const data: Record<string, unknown> = {};
    approvalFields.forEach(fieldName => {
      const key = fieldName.substring(this.#prefix.length);
      data[key] = doc.fields[fieldName];
    });

    return data as unknown as IApprovalForm;
  }

  private _getKeyValue = (key: ApprovalFormKey) => (obj: IApprovalForm) => obj[key];
}
