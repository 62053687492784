import { Pipe, PipeTransform } from '@angular/core';
import { UserApi } from '../api/user.api';
import { IUser } from '@interfaces/siam';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'getUserAvatar'
})
export class GetUserAvatarPipe implements PipeTransform {
  constructor(private _userApi: UserApi) {
  }

  transform(user: IUser): Observable<string> {
    return of(this._userApi.getAvatarUrl(user));
  }
}
