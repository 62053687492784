import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { IDxFormItemTemplate } from '@interfaces/devextreme';
import { IAttachment, IEventEmitterSignature, TEditMode } from '@interfaces/siam';
import { NotifyService } from '@services/notify.service';
import SignaturePad, { Options } from 'signature_pad';

@Component({
  selector: 'app-sign-signature',
  templateUrl: './sign-signature-include.component.html',
  styleUrls: ['./sign-signature-include.component.scss']
})
export class SignSignatureComponent implements OnChanges {
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  @Input() editMode: TEditMode = 'ReadOnly';
  @Input() currentField: IDxFormItemTemplate<IAttachment[]>;

  @Output() dialogHidden = new EventEmitter<boolean>();
  @Output() dialogResult = new EventEmitter<IEventEmitterSignature>();

  signature: SignaturePad;
  signSignature = false;

  ngOnChanges(): void {
    this.signSignature = true;
  }

  onContentReadyDialog(): void {
    const canvas = document.getElementById(
      `canvas-${this.currentField?.editorOptions?.elementAttr?.id}`
    ) as HTMLCanvasElement;
    if (canvas) {
      let penColor = this.currentField?.editorOptions?.customValuesSignature?.penColor;
      if (this.currentField?.editorOptions?.customValuesSignature?.isSecurityPatternEnabled) {
        penColor = 'black';
      }
      const options: Options = {
        penColor,
        maxWidth: this.currentField?.editorOptions?.customValuesSignature?.maxWidth
      };
      this.signature = new SignaturePad(canvas, options);
      this.resizeCanvas(canvas, this.signature);
    }
  }

  deleteSignature = (): void => {
    this.dialogResult.emit({ dataField: this.currentField.dataField, field: this.currentField, attachment: null });
  };

  saveSignature = (): void => {
    if (!this.signature) {
      return;
    }
    if (this.signature.isEmpty()) {
      NotifyService.global.info('Sie haben keine Unterschrift gezeichnet!');
      return;
    } else {
      const dataBase64 = this.signature.toDataURL('image/png');
      this.dialogResult.emit({
        dataField: this.currentField.dataField,
        field: this.currentField,
        attachment: dataBase64
      });
    }
  };

  clearSignature(): void {
    if (!this.signature) {
      return;
    }
    this.signature.clear();
    this.onContentReadyDialog();
  }

  cancelSignature = (): void => {
    this.signSignature = false;
    this.dialogHidden.emit(false);
  };

  private resizeCanvas(canvas: HTMLCanvasElement, signaturePad: SignaturePad): void {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }
}
