import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, NgZone } from '@angular/core';
import {
  IDocument,
  IEventEmitter,
  INameSelectorDialogInclude,
  INameSelectorDialogSettings,
  IPermissionTarget,
  IRole,
  IUser,
  IWorkflowAction,
  IWorkflowActionSheetItem,
  TNameSelectorDialogType
} from '@interfaces/siam';
import { DocumentService } from '@services/document.service';
import { Observable, Subject, lastValueFrom, of, switchMap, takeUntil } from 'rxjs';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { siamConst } from '@interfaces/siamConst';
import { NameSelectorComponent } from '../name-selector/name-selector.component';
import * as UserFactory from '@factories/user.factory';
import { FieldPermissionTargetServ, FieldServer } from '@interfaces/fieldServer';
import { copy } from '@factories/document.factory';
import { WorkFlowDialogSettings, WorkflowDialogComponent } from '../workflow-dialog/workflow-dialog.component';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { LoadPanelService } from '@services/load-panel.service';
import { NotifyService } from '@services/notify.service';
import { _defaultDecisionDocument, _defaultDecisionId } from '@interfaces/default-decision';
import { TemplateService } from '@services/template.service';

@Component({
  selector: 'app-workflow-agenda',
  templateUrl: './workflow-agenda.component.html',
  styleUrls: ['./workflow-agenda.component.scss']
})
export class WorkflowAgendaComponent implements OnChanges {
  @ViewChild(ConfirmPopupComponent, { static: true }) confirmPopupComponent: ConfirmPopupComponent;

  @ViewChild(NameSelectorComponent,{ static: true }) nameSelectorComponent: NameSelectorComponent;

  @ViewChild(WorkflowDialogComponent, { static: true }) workflowDialog: WorkflowDialogComponent;


  @Input() action: IWorkflowActionSheetItem = null;
  @Input() document: IDocument = null;
  @Input() agendaDocument: IDocument = null;

  @Output() dialogResult = new EventEmitter<unknown>();

  dialogOpen = false;

  isOkButtonDisabled = true;

  workflowActions: IWorkflowActionSheetItem[];

  workflowStatusLabel: string;

  private workflowCurrentAssignee: IPermissionTarget[];

  private currentUser: IUser;

  private workflowAction: IWorkflowActionSheetItem = null;

  private workflowActionVariables: Record<string, unknown> = null;

  private dialogVariables: Record<string, unknown>;

  private workflowActionCreateDocumentVariables: Record<string, { id: string; done: boolean }> = null;

  #destroyable$ = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private templateService: TemplateService,
    private zone: NgZone,
    private nameDataPipe: GetNameDataPipe,
    private loadPanelService: LoadPanelService
  ) {
    this.currentUser = UserFactory.getCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.action?.currentValue) {
      this.dialogOk(this.action);
    }
  }



  dialogOk = (actionItem?:IWorkflowActionSheetItem): void => {
    if (!actionItem) {
      return;
    }
    if (!actionItem.action.isUsable) {
      this.confirmPopupComponent.show({
        title: 'Workflowinformation',
        message: `Der Workflowschritt "${actionItem.action.label}" kann nicht ausgeführt werden: ${actionItem.action.reason}`,
        items: [
          {
            text: 'Workflowinformation',
            name: 'cancel',
            location: 'before',
            toolbar: 'top'
          },
          {
            widget: 'dxButton',
            name: 'cancel',
            location: 'before',
            toolbar: 'bottom',
            options: {
              text: 'Ok',
              type: 'primary',
              icon: 'check'
            }
          }
        ],
        noInit: true
      });
      return;
    }

    const executeAction = () => {
      void this.zone.run(async () => {
        this.workflowActionVariables = {};
        this.workflowActionCreateDocumentVariables = {};
        this.workflowAction = actionItem;

        // mittels userInputTemplate wird gesteuert, ob ein WorkflowDialog angezeigt werden soll,
        // z.B. um zusätzliche Informationen aufzufragen, oder dem User anzuzeigen
        // Beispiel: Abstimmungsdialog
        // Derzeit kann hier nur gesteuert werden, ob der NameSelector angezeigt werden soll
        // dazu wird in der WF-Konfiguration ein Template mit nur einem Feld "assignee" erzeugt und zugeordnet,
        // später kann man in der WF-Konfiguration auch Template zuordnen.
        const userInputTemplateLink = actionItem.action.userInputTemplateLink;
        const userInputTemplate = actionItem.action.userInputTemplate;
        /* create-document Aktivität */
        const createDocumentInputTemplates = actionItem.action.createDocumentInputTemplates;
        /* create-decision Aktivität */
        const createDecisionDocumentTemplates = actionItem.action.createDecisionDocumentTemplates;
        let createDocumentInputTemplatesArray: { key: string; value: string }[] = [];
        let createDecisionDocumentTemplatesArray: { key: string; value: string }[] = [];
        if (createDocumentInputTemplates) {
          createDocumentInputTemplatesArray = Object.entries(createDocumentInputTemplates).map(([key, value]) => ({
            key,
            value
          }));
          Object.entries(createDocumentInputTemplates).forEach(([key, value]) => {
            this.workflowActionCreateDocumentVariables[key] = { id: value, done: false };
          });
        }
        if (createDecisionDocumentTemplates) {
          createDecisionDocumentTemplatesArray = Object.entries(createDecisionDocumentTemplates).map(
            ([key, value]) => ({
              key,
              value
            })
          );
          Object.entries(createDecisionDocumentTemplates).forEach(([key, value]) => {
            this.workflowActionCreateDocumentVariables[key] = { id: value, done: false };
          });
        }
        if (userInputTemplate) {
          const nameSelector =
            (Array.isArray(userInputTemplate.tags) && userInputTemplate.tags.includes(siamConst.siamAssigneeTag)) ||
            userInputTemplate.name === 'user-fields';
          if (nameSelector) {
            this.nameSelectorComponent.show(
              this.getNameSelectorSettings(actionItem.action.userInputTemplate.fields[0])
            );
          } else {
            const needle = 'vote:user:';
            const layout = this.documentService.createSubForm(userInputTemplate, actionItem.action.userInput);
            let title = actionItem.action.label || layout.template.caption;

            if (actionItem.action.name?.includes(needle)) {
              const user = UserFactory.create();
              user.id = actionItem.action.name?.split(needle).pop();
              const userPipe = await lastValueFrom(this.nameDataPipe.transform(user));
              title = `${title} für ${userPipe.fullName}`;
            }

            const settings: WorkFlowDialogSettings = {
              title,
              fullScreen: false,
              readonly: false
            };
            this.workflowDialog.show(layout, settings);
          }
        } else if (userInputTemplateLink) {
          const layout = this.documentService.createSubForm(userInputTemplateLink, actionItem.action.userInput);
          const settings: WorkFlowDialogSettings = {
            title: actionItem.action.label || layout.template.caption,
            fullScreen: false,
            readonly: false
          };
          this.workflowDialog.show(layout, settings);
        } else if (createDocumentInputTemplatesArray?.length || createDecisionDocumentTemplatesArray?.length) {
          const first = createDocumentInputTemplatesArray?.length
            ? createDocumentInputTemplatesArray[0]
            : createDecisionDocumentTemplatesArray[0];
          const documentFromTemplate = await lastValueFrom(this.createDocumentFromTemplateId(first.value));
          const settings: WorkFlowDialogSettings = {
            title: actionItem.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDocumentInputName: first.key
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else {
          // -- feste Empfängerauswahl, keine Userinteraktion notwendig
          this.executeWorkflowAction(actionItem.action);
        }
      });
    };
    void executeAction();
    this.dialogResult.emit(true);
    this.dialogOpen = false;
  };
  
  dialogCancel = (): void => {
    this.dialogOpen = false;
    this.dialogResult.emit(false);
  };

  async onEmitWorkflowDialog(result: IEventEmitter<IDocument>): Promise<void> {
    if (result.command === 'save') {
      if (result.variableCreateDocumentName?.length) {
        const obj = result.object.fields;
        obj[siamConst.agendaFieldId] = { value: this.agendaDocument?.id };
        this.workflowActionVariables[result.variableCreateDocumentName] = obj;
        this.workflowActionCreateDocumentVariables[result.variableCreateDocumentName].done = true;

        const createDocument = Object.entries(this.workflowActionCreateDocumentVariables).find(
          ([, value]) => value.done === false
        );
        if (createDocument) {
          const documentFromTemplate = await lastValueFrom(this.createDocumentFromTemplateId(createDocument[1].id));
          const settings: WorkFlowDialogSettings = {
            title: this.workflowAction.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDocumentInputName: createDocument[0]
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else {
          this.proceedWorkflowAction(this.workflowActionVariables);
        }
      } else {
        const doc = result.object;
        const variables: Record<string, unknown> = {};
        Object.keys(doc.fields).forEach(key => {
          variables[key] = doc.fields[key].value;
        });
        if (this.dialogVariables) {
          Object.keys(this.dialogVariables).forEach(key => {
            variables[key] = this.dialogVariables[key];
          });
          this.dialogVariables = null;
        }
        this.workflowActionVariables = variables;
        const createDocument = Object.entries(this.workflowActionCreateDocumentVariables).find(
          ([, value]) => value.done === false
        );
        if (createDocument) {
          const documentFromTemplate = await lastValueFrom(this.createDocumentFromTemplateId(createDocument[1].id));
          const settings: WorkFlowDialogSettings = {
            title: this.workflowAction.action.label || documentFromTemplate.template.caption,
            fullScreen: false,
            readonly: false,
            createDocumentInputName: createDocument[0],
          };
          this.workflowDialog.show(documentFromTemplate, settings);
        } else {
          this.proceedWorkflowAction(variables);
        }
      }
    }
  }
  onEmitNameSelectorDialog(result: (IUser | IRole)[]): void {
    const users = result
      .filter(item => item.compositeId.startsWith('user:'))
      .map(item => ({ targetId: item.id, type: 'user' }));

    const roles = result
      .filter(item => item.compositeId.startsWith('role:'))
      .map(item => ({ targetId: item.id, type: 'role' }));

    const variables: Record<string, unknown[]> = { assignee: [].concat(users, roles) };
    this.workflowActionVariables = variables;
    const userInputTemplateLink = this.workflowAction?.action.userInputTemplateLink;

    if (userInputTemplateLink) {
      this.dialogVariables = variables;
      const layout = this.documentService.createSubForm(userInputTemplateLink, this.workflowAction?.action?.userInput);
      const settings: WorkFlowDialogSettings = {
        title: this.workflowAction?.action?.label || 'Formular',
        fullScreen: false,
        readonly: false
      };
      this.workflowDialog.show(layout, settings);
    } else {
      this.proceedWorkflowAction(variables);
    }
  }
  /* add this function to helper */
  private getNameSelectorSettings(_templateFields: FieldServer): INameSelectorDialogSettings {
    if (!_templateFields) {
      return null;
    }
    const templateFields = copy(_templateFields);
    let include = templateFields.include as INameSelectorDialogInclude[];
    let exclude = templateFields.exclude as INameSelectorDialogInclude[];
    let title = 'Bitte wählen Sie den Empfänger aus:';
    const selectionMode = templateFields.allowMultiple ? 'multiple' : 'single';
    const minSelection =
      selectionMode === 'single' ? 1 : (templateFields as FieldPermissionTargetServ).customValues?.minSelection || 1;
    const forbidRoles = templateFields.forbidRoles;
    const forbidUsers = templateFields.forbidUsers;
    const targetField = (templateFields as FieldPermissionTargetServ).customValues?.targetField || null;
    const targetFieldExclude = (templateFields as FieldPermissionTargetServ).customValues?.targetFieldExclude || null;
    const currentUserExclude = (templateFields as FieldPermissionTargetServ).customValues?.currentUserExclude || null;
    const currentAssigneeExclude =
      (templateFields as FieldPermissionTargetServ).customValues?.currentAssigneeExclude || null;
    if (targetField && targetField?.startsWith('fields')) {
      const fieldName = targetField.split('.')[1];
      if (fieldName) {
        const fieldValue = this.document.fields[fieldName]?.value as INameSelectorDialogInclude[];
        if (fieldValue) {
          include = fieldValue.concat(include);
        }
      }
    }
    if (targetFieldExclude && targetFieldExclude?.startsWith('fields')) {
      const fieldName = targetFieldExclude.split('.')[1];
      if (fieldName) {
        const fieldValue = this.document.fields[fieldName]?.value as INameSelectorDialogInclude[];
        if (fieldValue) {
          exclude = fieldValue.concat(exclude);
        }
      }
    }
    if (currentUserExclude) {
      const fieldValue = { type: 'user', targetId: this.currentUser.id } as IPermissionTarget;
      exclude.push(fieldValue);
    }
    if (currentAssigneeExclude) {
      const fieldValue =
        (this.workflowCurrentAssignee as INameSelectorDialogInclude[]) || ([] as INameSelectorDialogInclude[]);
      exclude = fieldValue.concat(exclude);
    }
    let selectType: TNameSelectorDialogType;
    if (forbidRoles && forbidUsers) {
      selectType = 'Personen';
    }
    if (forbidRoles && !forbidUsers) {
      selectType = 'Personen';
    }
    if (forbidUsers && !forbidRoles) {
      selectType = 'Rollen';
      title = 'Bitte wählen Sie die Rolle aus:';
    }
    if (!forbidUsers && !forbidRoles) {
      selectType = 'Beide';
    }

    return {
      title,
      selectType,
      include,
      exclude,
      selectionMode,
      minSelection
    };
  }
  private proceedWorkflowAction(variables: Record<string, unknown>): void {
    this.executeWorkflowAction(this.workflowAction.action, variables);
  }
  private executeWorkflowAction(action: IWorkflowAction, variables: Record<string, unknown> = {}): void {
    this.loadPanelService.show('Workflowaktivitäten werden ausgeführt...', 130, 270);
    const workflowParameters = this.documentService.getRereferencesObject(this.document);
    workflowParameters[siamConst.conditionAgendaExistsTag] = true;
    const workflowVariables = Object.assign(variables, workflowParameters);
    this.documentService
      .executeWorkflowAction(action, workflowVariables)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: (doc): void => {
          this.dialogResult.emit(doc);
          this.resetLoading();
          this.workflowActionVariables = null;
          this.workflowAction = null;
          NotifyService.global.success(`Der Workflowschritt "${action.label}" wurde ausgeführt.`);
        },
        error: (): void => {
          this.resetLoading();
          NotifyService.global.error(`Der Workflowschritt '${action.label}' konnte nicht ausgeführt werden.`);
        }
      });
  }
  private resetLoading(): void {
    this.loadPanelService.hide();
  }
  private createDocumentFromTemplateId(templateId: string): Observable<IDocument> {
    if (templateId === _defaultDecisionId) {
      return of(copy(_defaultDecisionDocument) as unknown as IDocument);
    }
    return this.templateService.getTemplate(templateId).pipe(
      switchMap(template => this.documentService.createDocumentByTemplateId(template)),
      takeUntil(this.#destroyable$)
    );
  }
}
