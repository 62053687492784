import { Pipe, PipeTransform } from '@angular/core';
import { DocumentService } from '@services/document.service';
import { EMPTY, Observable, of } from 'rxjs';
import { IAgenda, IDocument } from '@interfaces/siam';

@Pipe({
  name: 'getDocument'
})
export class GetDocumentPipe implements PipeTransform {

  constructor(private documentService: DocumentService) {
  }

  transform(value: IAgenda, source?: IDocument[]): Observable<IDocument> {
    if (!value.documentId) {
      return EMPTY;
    }

    if (Array.isArray(source)) {
      const document = source.find(entry => entry.id === value.documentId);
      if (document) {
        return of(document);
      }
    }
    return this.documentService.getDocumentById(value.documentId);
  }
}
