import { metaReducers, reducers } from './store';
import { environment } from '@env/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './_core/_core.module';
import { StartpageComponent } from './_core/components/startpage/startpage.component';
import { ApplicationComponent } from './application/application.component';
import { AdministrationComponent } from './administration/administration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GraphQLModule } from './graphql.module';
import { Apollo } from 'apollo-angular';
import { ErrorInterceptor } from './_core/error.interceptor';
import { SharedataService } from '@services/sharedata.service';
import { GetWorkflowstatusPipe } from '@pipes/get-workflowstatus.pipe';

@NgModule({
  declarations: [
    AppComponent,
    StartpageComponent,
    ApplicationComponent,
    AdministrationComponent,
    DashboardComponent,
  ],

  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    GraphQLModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],

  exports: [],

  providers: [
    Apollo,
    HttpClientModule,
    CoreModule,
    GetWorkflowstatusPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, deps: [SharedataService, Window] },
    { provide: Window, useValue: window }
  ],

  bootstrap: [AppComponent]
})

export class AppModule {
}
