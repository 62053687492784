import {
  TEffectivePermission,
  IPermission,
  IUserPermission,
  IRolePermission,
  TAssignmentUser,
  TAssignmentRole
} from './siam';

export class BasePermission implements IPermission {
  allows: TEffectivePermission[] = [];
  denies: TEffectivePermission[] = [];
  isInherited = false;
  name = '';

  constructor(init?: Partial<IPermission>) {
    if (init) {
      if (Array.isArray(init.allows)) {
        this.allows = [...init.allows];
      }
      if (Array.isArray(init.denies)) {
        this.denies = [...init.denies];
      }
      this.isInherited = !!init.isInherited;
      this.name = init.name;
    }
  }
}

export class UserPermission extends BasePermission implements IUserPermission {
  type: TAssignmentUser = 'user';
  userId = '';

  constructor(init?: Partial<IUserPermission>) {
    super(init);
    if (init) {
      this.userId = init.userId;
    }
  }
}

export class RolePermission extends BasePermission implements IRolePermission {
  type: TAssignmentRole = 'role';
  roleId = '';
  isArchived = false;

  constructor(init?: Partial<IRolePermission>) {
    super(init);
    if (init) {
      this.roleId = init.roleId;
      this.isArchived = init.isArchived;
    }
  }
}
