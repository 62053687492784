import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { LoggerService } from '@services/logger.service';
import { IRole, TGraphqlAcoountMode } from '@interfaces/siam';

interface IAccountData {
  role?: IRole;
  roles?: IRole[];
}

interface IResponse {
  account: IAccountData;
}

const QUERY_DATA = `id
name
isArchived
description
capabilities
tags
users {
  id
  name
  profile {
    forename
    surname
    picture {
      url
      originalFileName
    }
  }
}`;

export const QUERY_ROLE = gql`query($id: ID!) {
  account {
    role(id: $id) {
      ${QUERY_DATA}
    }
  }
}`;

export const QUERY_ROLES = gql`query ($ids: [ID!],  $mode: RoleFilterMode) {
  account {
    roles(ids: $ids, mode: $mode) {
      ${QUERY_DATA}
    }
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class RoleApi {
  private readonly apiBaseUrl: string;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private logger: LoggerService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.apiBaseUrl = `${baseUrl}api/account/roles/`;
  }

  /**
   * creating a new role
   *
   * @param role type RoleInfo
   */
  create(role: IRole): Observable<IRole> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });

    return this.http.post<IRole>(`${this.apiBaseUrl}`, role, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error(`Fehler beim Erstellen der Rolle: {@error}`, error);
        }
      })
    );
  }

  /**
   * deleting the role from DB
   *
   * @param role
   */
  delete(role: IRole): Observable<IRole> {
    return this.http.delete<IRole>(`${this.apiBaseUrl}${role.id}`).pipe(
      tap({
        next: deletedRole => {
          this.logger.info('Rollen Löschen= {@document}', deletedRole);
        },
        error: error => {
          this.logger.error(`Fehler beim Löschen der Rollen: {@error}`, error);
        }
      })
    );
  }

  /**
   * Returns the a role by role id
   *
   * @param id as string
   * @return roles
   */
  getRole(id: string): Observable<IRole> {
    return this.apollo
      .query<IResponse>({
        query: QUERY_ROLE,
        variables: { id }
      })
      .pipe(map(({ data }) => data.account.role));
  }

  /**
   * Returns certain roles
   *
   * @param ids?: list of requested role-ids
   * @return list of roles
   */
  getRoles(ids: string[], mode: TGraphqlAcoountMode = 'all'): Observable<IRole[]> {
    return this.apollo
      .query<IResponse>({
        query: QUERY_ROLES,
        variables: { ids, mode }
      })
      .pipe(map(({ data }) => data.account.roles));
  }

  /**
   * update a role with id
   *
   * @param role type RoleInfo
   */
  update(role: IRole): Observable<IRole> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.put<IRole>(`${this.apiBaseUrl}${role.id}`, role, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error(`Fehler beim Aktualisieren der Rolle: {@error}`, error);
        }
      })
    );
  }
}
