import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DxFileUploaderComponent, DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { EMPTY, Observable, of, Subject, zip } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SharedataService } from '@services/sharedata.service';
import { NotifyService } from '@services/notify.service';
import { UserService } from '@services/user.service';
import {
  IAttachment,
  IError,
  IErrorDetail,
  IPasswordRequirements,
  IRole,
  IUser,
  IUserRoleEntry
} from '@interfaces/siam';
import { RoleService } from '@services/roles.service';
import * as Factory from '@factories/user.factory';
import { IDxFileUploaderComponent } from '@interfaces/devextreme';
import { LoginService } from '@services/login.service';
import { onBrandSelectionChanged, sortBy } from '@factories/helpers';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import { ContentReadyEvent, ValueChangedEvent } from 'devextreme/ui/file_uploader';
import { copy } from '@factories/role.factory';
import { LoggerService } from '@services/logger.service';

interface IPassword {
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
}

interface IFormData {
  password: string;
}

interface ICustomRole extends IRole {
  visible?: boolean;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('userForm') userForm: DxFormComponent;
  @ViewChild('userPasswordForm') userPasswordForm: DxFormComponent;
  @ViewChild('fileUploaderUserAvatar') fileUploaderUserAvatar: DxFileUploaderComponent;
  @ViewChild('userPasswordPopup', { static: true }) userPasswordPopup: DxPopupComponent;

  @Input() currentUser: IUser;
  @Input() user: IUser;
  @Input() isMyProfile = false;
  @Input() changePasswordMode = false;
  @Input() createMode = false;

  password: IPassword = { newPassword: '', confirmPassword: '', oldPassword: '' };
  passwordRequirements: IPasswordRequirements;
  roles: ICustomRole[] = [];
  userPicture: File;
  attachment: IAttachment = null;
  fieldPasswordMode = true;
  defaultPassword = 'ventuno';
  emailPattern = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
  );
  canSubstitutesUpdate = false;
  connectedUser = false;
  usersRolesSource: IUserRoleEntry[] = [];
  onSelectionChanged = onBrandSelectionChanged;

  #destroyable$ = new Subject<void>();
  #substitutes: string[] = [];

  constructor(
    private data: SharedataService,
    private userService: UserService,
    private roleService: RoleService,
    private loginService: LoginService,
    private logger: LoggerService
  ) {
    this.fillUsersAndRoles().pipe(takeUntil(this.#destroyable$)).subscribe();
  }

  ngOnInit(): void {
    this.roleService
      .getAllRoles()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: roles => {
          this.roles = sortBy(roles, 'name').map(r => {
            const role = copy(r) as ICustomRole;
            role.visible = !r.isArchived;
            return role;
          });
        },
        error: NotifyService.component.error
      });

    this.userService
      .getPasswordRequirements()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: data => {
          this.passwordRequirements = data;
        },
        error: error => {
          this.logger.error('Fehler beim Erhalt des Passwortes Regeln: {@error}', error);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser?.currentValue) {
      this.canSubstitutesUpdate = Factory.isCanSubstitutesUpdate(this.currentUser) || this.isMyProfile;
    }
    if ((changes.user?.currentValue as IUser)?.id) {
      this.#substitutes = this.user.clientSubstitutes.slice();
    }
    this.connectedUser = this.user?.connectedLogins?.length > 0;
  }

  initUserPassword(): void {
    this.password = { newPassword: '', confirmPassword: '', oldPassword: '' };
    this.userPasswordForm?.instance?.repaint();
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  /**
   * Returns the given password.
   * Devextreme function 'comparisonTarget' compare the both passwords im template.
   */
  passwordComparison = (): string => this.password.newPassword;

  passwordComparisonNewUser = (): string => (this.userForm.formData as IFormData).password;

  /**
   * Liefert den von DevExtreme benötigten Modus-Wert zurück, damit
   * der Text von Passwort-Feldern maskiert wird.
   */
  getPasswordMode(): string {
    return this.fieldPasswordMode ? 'password' : 'text';
  }

  /**
   * on click the eye icon password visibility is
   */
  togglePasswordVisibility = (): void => {
    this.fieldPasswordMode = !this.fieldPasswordMode;
  };

  /**
   * Custom icon in upload button
   *
   * @param e
   */
  onContentReady(e: ContentReadyEvent): void {
    const buttonInstance = (e.component as IDxFileUploaderComponent)._selectButton;
    if (buttonInstance) {
      buttonInstance.option('icon', 'runner');
      const icon = buttonInstance.element().querySelectorAll('i')[0];
      const parentNode = icon.parentNode;
      const newIcon = document.createElement('i');
      newIcon.setAttribute('class', 'material-icons');
      newIcon.textContent = 'face';
      parentNode.insertBefore(newIcon, icon);
      parentNode.removeChild(icon);
    }
  }

  /**
   * It allows you to create new user After the validation of both password and confirm password.
   */
  createUser(): Observable<IUser> {
    const result = this.userForm.instance.validate();
    if (!result.isValid) {
      NotifyService.component.error('Bitte füllen Sie alle Pflichtfelder aus.');
      return EMPTY;
    }
    this.user.roles = this.user?.rolesIds?.map(id => this.roles?.find(r => r.compositeId === id)) || [];
    delete this.user.substitutes;
    this.#substitutes = [];
    return this.userService.createUser(this.user, (this.userForm.formData as IFormData).password);
  }

  onFileValueChanged(e: ValueChangedEvent): void {
    const file = e.value[0];
    this.userPicture = file;
    const reader = new FileReader();
    const attachment: IAttachment = {
      contentType: file.type,
      data: null,
      fileName: file.name,
      size: file.size
    };

    reader.onload = () => {
      const byteArray = new Uint8Array(reader.result as ArrayBuffer);
      const data = byteArray.reduce((acc, i) => (acc += String.fromCharCode.apply(null, [i])), '');
      attachment.data = btoa(data);
      this.attachment = attachment;
    };
    reader.readAsArrayBuffer(file);
  }

  removeAvatar(): void {
    this.userService
      .removeAvatar(this.user.id)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe(() => {
        this.attachment = null;
        this.userPicture = null;
        this.updateUserProfile();
      });
  }

  /**
   * update a user and returns a updated user
   */
  updateUser(): Observable<IUser> {
    // DxValdiator aufrufen
    const result = this.userForm.instance.validate();
    if (!result.isValid) {
      NotifyService.component.error('Bitte füllen Sie alle Pflichtfelder aus.');
      return EMPTY;
    }

    this.user.roles = this.user.rolesIds.map(id => this.roles.find(r => r.compositeId === id));

    return zip(this.onUpdate()).pipe(
      switchMap(() => this.userService.update(this.user)),
      map(user => {
        this.setCurrentUserData(user);
        NotifyService.global.success('Benutzerdaten aktualisiert');
        return user;
      })
    );
  }

  updateUserProfile(): void {
    const result = this.userForm.instance.validate();
    if (!result.isValid) {
      NotifyService.component.error('Bitte füllen Sie alle Pflichtfelder aus.');
      return;
    }

    zip(this.onUpdate())
      .pipe(
        switchMap(() => this.userService.updateProfile(this.user)),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: res => {
          this.user = Factory.copy(res);
          this.setCurrentUserData(this.user);
          NotifyService.global.success('Benutzerdaten aktualisiert');
        },
        error: NotifyService.component.error
      });
  }

  /**
   * Password change
   */
  changePassword = (): void => {
    // DxValdiator aufrufen
    const result = this.userPasswordForm.instance.validate();
    if (!result.isValid) {
      NotifyService.component.error('Bitte füllen Sie alle Pflichtfelder aus.');
      return;
    }
    if (this.password.newPassword === this.password.confirmPassword) {
      this.userService
        .changePassword(this.user, this.password.oldPassword, this.password.newPassword)
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          next: success => {
            if (success) {
              NotifyService.global.success('Kennwort aktualisiert');
              this.changePasswordMode = false;
              this.user.isLockedOut = false;
              this.password = null;
            } else {
              NotifyService.component.error('Dieser Fehler wurde leider nicht durch den Entwickler abgefangen.');
            }
          },
          error: this.handleError
        });
    }
  };

  /**
   * generate username from user surname and user forename wenn exist
   */
  onFieldDataChanged(e: FieldDataChangedEvent): void {
    let generateUserName = false;
    if (e.dataField === 'profile.forename' && e.value) {
      if (!this.user.name?.length && this.user.profile.surname?.length) {
        generateUserName = true;
      }
    }
    if (e.dataField === 'profile.surname' && e.value) {
      if (!this.user.name?.length && this.user.profile.forename?.length) {
        generateUserName = true;
      }
    }
    if (generateUserName) {
      const firstName = this.user.profile.forename;
      const lastName = this.user.profile.surname;
      this.user.name = lastName ? firstName.concat(' ', lastName) : firstName;
    }
  }

  dialogCancel = (): void => {
    this.changePasswordMode = false;
  };

  fillUsersAndRoles(): Observable<IUserRoleEntry[]> {
    return this.userService.getAllUsers().pipe(
      switchMap(users => {
        this.usersRolesSource = users.map(user => ({
          id: user.compositeId,
          name: user.displayName,
          profile: user.profile,
          roles: user.roles,
          userName: user.name,
          selectorName: user.selectorName,
          visible: !user.isArchived
        }));
        this.usersRolesSource.sort((a, b) =>
          a.selectorName.toLowerCase() > b.selectorName.toLowerCase()
            ? 1
            : b.selectorName.toLowerCase() > a.selectorName.toLowerCase()
            ? -1
            : 0
        );
        return this.roleService.getAllRoles();
      }),
      switchMap(roles => {
        const entries = roles.map(role => ({ id: role.compositeId, name: role.name, visible: !role.isArchived }));
        entries.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
        );
        this.usersRolesSource.push(...entries);

        return of(this.usersRolesSource);
      })
    );
  }

  userItemTemplate(user: IUser): string {
    let result = '';
    if (user?.id?.startsWith('user:') && user?.profile) {
      if (user.profile.picture && user.profile.picture.url) {
        result = `<img class="user-avatar-name" src="${user.profile.picture.url}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
      result = result + `<span>${user.selectorName}</span>`;
      return result;
    }
    if (user.id.startsWith('role:')) {
      result = `<i class="material-icons mr-1">group</i><span>${user.name}</span>`;
    }
    return result;
  }

  userTagTemplate = (user: IUserRoleEntry): string => {
    let result = '';
    if (user.id.startsWith('user:')) {
      if (user && user.profile && user.profile.picture && user.profile.picture.url) {
        result = `<img class="user-avatar-name" src="${user.profile.picture.url}" alt="">`;
      } else {
        result = `<i class="material-icons">account_circle</i>`;
      }
    }
    if (user.id.startsWith('role:')) {
      result = `<i class="material-icons mr-1">group</i>`;
    }
    const removeButton = '<div class="dx-tag-remove-button"></div>';
    if (user.visible) {
      return `<div class="dx-tag-content name-selector">${result}<span>${user.name}</span>${removeButton}</div>`;
    } else {
      return `<div class="dx-tag-content name-selector ">${result}<span class="is-archived-user">${user.name}</span>${removeButton}</div>`;
    }
  };

  private setCurrentUserData(user: IUser): void {
    if (user.id === this.currentUser.id) {
      this.loginService.info().pipe(takeUntil(this.#destroyable$)).subscribe();
      this.data.setUser(user);
    }
  }

  private onUpdate(): Observable<unknown>[] {
    const stream$: Observable<unknown>[] = [];
    if (!this.areSubtituesEqual()) {
      stream$.push(this.userService.setSubstitutes(this.user));
    }

    if (this.userPicture) {
      stream$.push(
        this.userService.uploadAvatar(this.user, this.userPicture).pipe(
          tap({
            next: () => {
              this.userPicture = null;
            }
          })
        )
      );
    }

    if (!stream$.length) {
      stream$.push(of(null));
    }
    return stream$;
  }

  private areSubtituesEqual(): boolean {
    if (this.#substitutes?.length !== this.user.clientSubstitutes?.length) {
      return false;
    }

    return this.#substitutes?.every(value => this.user.clientSubstitutes?.indexOf(value) !== -1);
  }

  private handleError = (error: IError) => {
    const errorDe: Record<string, string> = {
      PasswordMismatch: 'Falsches Passwort.',
      PasswordTooShort: `Passwörter müssen mindestens ${this.passwordRequirements.requiredLength} Zeichen lang sein.`,
      PasswordRequiresNonAlphanumeric: 'Passwörter müssen mindestens ein nicht-alphanumerisches Zeichen enthalten.',
      PasswordRequiresDigit: 'Passwörter müssen mindestens eine Ziffer (0-9) enthalten.',
      PasswordRequiresUpper: 'Passwörter müssen mindestens einen Großbuchstaben enthalten (A-Z).',
      PasswordRequiresLower: 'Passwörter müssen mindestens ein Kleinbuchstabe (a-z).'
    };
    const errorCodes = (error?.error as IErrorDetail[]).map(a => errorDe[a.code] || a.code).join('\r\n');
    NotifyService.destroyGlobalNotifications();
    NotifyService.component.error(errorCodes);
    this.initUserPassword();
    this.changePasswordMode = true;
  };
}
