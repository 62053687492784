<dx-popup
  height="auto"
  width="auto"
  [showTitle]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [(visible)]="signSignature"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: {
        type: 'normal',
        icon: 'material-icons md-24 draw',
        elementAttr: {
          class: 'button-icon'
        }
      }
    },
    {
      text: 'Unterschiftsdialog',
      location: 'before',
      toolbar: 'top'
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      options: {
        type: 'normal',
        icon: 'close',
        hint: 'Schließen'
      },
      onClick: cancelSignature
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <dx-box [visible]="signSignature" (onContentReady)="onContentReadyDialog($event)">
      <dxi-item [ratio]="1">
        <div *dxTemplate>
          <canvas
            #canvas
            class="canvas-content"
            id="canvas-{{ currentField.editorOptions.elementAttr.id }}"
            style="border: 1px solid var(--border-color)"
            [ngStyle]="{
              width: this.currentField?.editorOptions?.customValuesSignature?.width + 'px',
              height: this.currentField?.editorOptions?.customValuesSignature?.height + 'px'
            }"></canvas>
          <div class="canvas-items mt-2">
            <dx-button class="mr-2" type="normal" text="Leeren" icon="undo" (onClick)="clearSignature()"></dx-button>
            <dx-button
              class="mr-2"
              type="normal"
              text="Übernehmen"
              icon="check"
              (onClick)="saveSignature()"></dx-button>
            <dx-button
              class="mr-2"
              type="normal"
              text="Entfernen"
              icon="trash"
              (onClick)="deleteSignature()"></dx-button>
            <dx-button type="normal" text="Abbrechen" icon="clear" (onClick)="cancelSignature()"></dx-button>
          </div>
        </div>
      </dxi-item>
    </dx-box>
  </div>
</dx-popup>
