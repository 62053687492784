import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isAdmin } from '@factories/user.factory';
import { ILabelValue, IUser } from '@interfaces/siam';
import { LoginService } from '@services/login.service';
import { NotifyService } from '@services/notify.service';
import { DxFormComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-substitutes',
  templateUrl: './substitutes.component.html',
  styleUrls: ['./substitutes.component.scss']
})
export class SubstitutesComponent implements OnDestroy {
  @ViewChild('substitutionForm') substitutionForm: DxFormComponent;
  @Input() currentUser: IUser;

  showSubstitution = false;
  substitutionActivated: boolean;
  impersonatedUser: IUser;
  loginSubstitutes: { substitute: string };
  currentCandidates: ILabelValue[] = [];

  #destroyable$ = new Subject<void>();

  constructor(private loginService: LoginService, private router: Router) {}

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  show(candidates: IUser[], impersonatedUser: IUser): void {
    if (impersonatedUser) {
      this.substitutionActivated = true;
      this.showSubstitution = true;
      this.impersonatedUser = impersonatedUser;
      return;
    } else {
      this.substitutionActivated = false;
    }
    if (candidates?.length) {
      this.currentCandidates = candidates.map(candidate => ({
        value: candidate.id,
        label: `${candidate.name} (${candidate.email})`
      }));
      this.loginSubstitutes = { substitute: '' };
      this.showSubstitution = true;
      return;
    }
    this.loginSubstitutes = { substitute: '' };
    this.showSubstitution = true;
  }

  closeSubstitutesPopup = (): void => {
    this.showSubstitution = false;
    this.substitutionForm?.instance?.resetValues();
  };

  closeSubstitutes = (): void => {
    if (!this.substitutionActivated) {
      this.closeSubstitutesPopup();
    } else {
      this.loginService
        .stopImpersonation()
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          next: () => {
            this.closeSubstitutesPopup();
          }
        });
    }
  };

  processSubstitutes = (): void => {
    const result = this.substitutionForm.instance.validate();
    if (!result.isValid) {
      NotifyService.component.error('Bitte füllen Sie alle Pflichtfelder aus.');
      return;
    }
    this.loginService
      .loginImpersonation(this.loginSubstitutes.substitute)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: user => {
          if (this.router.url.startsWith('/administration') && !isAdmin(user)) {
            void this.router.navigate(['', 'application', 'home']);
          }
          this.closeSubstitutesPopup();
        }
      });
  };
}
