<div *ngIf="notifications.length" class="notification-container errors animated slideInUp">
  <div i18n class="notification-header">
    <span class="material-icons notification-image">error</span>
    Der folgende Anwendungsfehler ist aufgetreten:
    <button class="notification-close-btn" (click)="closeGlobalNotifications()">
      <i class="material-icons">close</i>
    </button>
  </div>
  <div class="notification-text" *ngFor="let notify of notifications; let i = index">
    <div *ngIf="notify">{{notify.message}}</div>
    <div *ngIf="notify?.details">
      <a class="notification-details-link" (click)="isShowDetails[i] = !isShowDetails[i]">Details</a>
      <div class="notification-details" *ngIf="isShowDetails[i]" [innerHtml]="notify.details | safeHtml"></div>
    </div>
  </div>
</div>
