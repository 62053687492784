import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apply'
})
export class ApplyPipe implements PipeTransform {
  transform(value: Record<string, unknown> | ((...args: unknown[]) => unknown), arg1: string, ...args: unknown[]): unknown {
    return typeof value === 'function'
      ? value(arg1, ...args)
      : // eslint-disable-next-line prefer-spread, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/ban-types
        (value[arg1] as Function).apply(value, args);
  }
}
