import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  IEncryption,
  IHomePageSetting,
  IPrintPageLayout,
  ISiamNotification,
  IStartPageSetting,
  IUser,
  TEnvironmentMode,
  TLogLevel
} from '@interfaces/siam';
import { NotifyService } from '@services/notify.service';
import { environment } from '@env/environment';
import { FieldTypes } from '@interfaces/fieldClient';

@Injectable({
  providedIn: 'root'
})
export class SharedataService {
  accentColorSource = new BehaviorSubject<string>('');
  history = new BehaviorSubject<string[]>(['/application/home']);
  headerLogo$ = new BehaviorSubject<string>('/api/configuration/logo');
  startOnce$ = new BehaviorSubject<boolean>(false);

  appTitle$ = new BehaviorSubject<string>('');
  getAppTitle = this.appTitle$.asObservable();

  user$ = new BehaviorSubject<IUser>(null);
  getUser = this.user$.asObservable();

  themeSource = new BehaviorSubject<string>('');
  getTheme = this.themeSource.asObservable();

  isThemeGlobalOnly$ = new BehaviorSubject<boolean>(false);
  getIsThemeGlobalOnly = this.isThemeGlobalOnly$.asObservable();

  isCustomBackground$ = new BehaviorSubject<boolean>(false);
  getIsCustomBackground = this.isCustomBackground$.asObservable();

  customBackground$ = new BehaviorSubject<string>(null);

  background$ = new BehaviorSubject<string>(null);
  getBackground$ = this.background$.asObservable();

  printPageLayout$ = new BehaviorSubject<IPrintPageLayout>(null);
  getPrintPageLayout$ = this.printPageLayout$.asObservable();

  startPageSetting$ = new BehaviorSubject<IStartPageSetting>(null);
  getStartPageSetting$ = this.startPageSetting$.asObservable();

  homePageSetting$ = new BehaviorSubject<IHomePageSetting>(null);
  getHomePageSetting$ = this.homePageSetting$.asObservable();

  startpageClassSrc = new BehaviorSubject<boolean>(false);
  getstartpageClass = this.startpageClassSrc.asObservable();

  homeClassSrc = new BehaviorSubject<boolean>(false);
  getHomeClass = this.homeClassSrc.asObservable();

  fullNavigationPane$ = new BehaviorSubject<boolean>(false);
  getFullNavigationPane = this.fullNavigationPane$.asObservable();

  activitiesHiddenClass = new BehaviorSubject<boolean>(true);
  getActivitiesHiddenClass = this.activitiesHiddenClass.asObservable();

  scrollToTop$ = new BehaviorSubject<boolean>(false);
  getScrollToTop$ = this.scrollToTop$.asObservable();

  copiedField$ = new BehaviorSubject<FieldTypes[]>(null);
  getCopiedField$ = this.copiedField$.asObservable();

  polling$ = new BehaviorSubject<boolean>(false);

  private encryption$ = new BehaviorSubject<IEncryption>(null);

  #logLevel$ = new BehaviorSubject<TLogLevel>(null);
  #notifications: ISiamNotification[] = [];

  constructor() {
    NotifyService.getNotifications().subscribe(notifications => {
      this.addNotifications(notifications);
    });

    NotifyService.getGlobalNotifications().subscribe(notifications => {
      this.addNotifications(notifications);
    });
  }

  getNotifications(): ISiamNotification[] {
    return [...this.#notifications];
  }

  clearNotifications(): void {
    this.#notifications = [];
    this.#notifications.length = 0;
  }

  startedOnce(): void {
    this.startOnce$.next(true);
  }

  resetStartedOnce(): void {
    this.startOnce$.next(false);
  }

  getAccentColor(): string {
    return this.accentColorSource.getValue();
  }

  getLogLevel(): TLogLevel {
    return this.#logLevel$.getValue();
  }

  getHistory(): string[] {
    return this.history.getValue();
  }

  // set user activities
  setActivitiesHiddenClass(data: boolean): void {
    this.activitiesHiddenClass.next(data);
  }

  setLogLevel(level: TLogLevel): void {
    this.#logLevel$.next(level);
  }

  // set user history
  setHistory(data: string[]): void {
    this.history.next(data);
  }

  // Application accent color
  setAccentColor(data: string): void {
    this.accentColorSource.next(data);
  }

  // Application header Logo
  setHeaderLogo(data: string): void {
    this.headerLogo$.next(data);
  }

  setUser(data: IUser): void {
    this.user$.next(data);
  }

  // Application theme
  setTheme(data: string): void {
    this.themeSource.next(data);
  }

  // Application Background Image
  setBackground(data: string): void {
    this.background$.next(data);
  }

  // Print Page Setting
  setPrintPageLayout(data: IPrintPageLayout): void {
    this.printPageLayout$.next(data);
  }

  // Application HomePageSetting
  setStartPageSetting(data: IStartPageSetting): void {
    this.startPageSetting$.next(data);
  }

  // Application HomePageSetting
  setHomePageSetting(data: IHomePageSetting): void {
    this.homePageSetting$.next(data);
  }

  // Application titel
  setAppTitle(data: string): void {
    this.appTitle$.next(data);
  }

  // Application Theme Global Only
  setisThemeGlobalOnly(data: boolean): void {
    this.isThemeGlobalOnly$.next(data);
  }

  // Application Custom Background
  setIsCustomBackground(data: boolean): void {
    this.isCustomBackground$.next(data);
  }

  // Application Custom Background
  setCustomBackground(data: string): void {
    this.customBackground$.next(data);
  }

  // startpageClass: style for user homepage and siam startpage
  setstartpageClass(data: boolean): void {
    this.startpageClassSrc.next(data);
  }

  // home class
  changeClass(data: boolean): void {
    this.homeClassSrc.next(data);
  }

  // navigation view mode
  hideNavigationPane(data: boolean): void {
    this.fullNavigationPane$.next(data);
  }

  getEncryption(): IEncryption | null {
    return this.encryption$.getValue();
  }

  setEncryption(encryption: IEncryption): void {
    this.encryption$.next(encryption);
  }

  // Scroll position to top
  setScrollToTop(data: boolean): void {
    this.scrollToTop$.next(data);
  }

  setCopiedField(data: FieldTypes[]): void {
    this.copiedField$.next(data);
  }

  resetBackgroundImage(): void {
    this.background$.next(null);
  }

  /**
   * get environment mode
   */
  environmentMode(): TEnvironmentMode {
    return environment.testing ? 'Testing' : environment.production ? 'Produktion' : 'Development';
  }

  private addNotifications(notifications: ISiamNotification[]): void {
    this.#notifications.push(...notifications.filter(notification => notification.class === 'error'));
  }
}
