import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { DocumentService } from '@services/document.service';
import { filter, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { IDxEditorToolbarItem, IDxFormItemTemplate } from '@interfaces/devextreme';
import {
  ICategory,
  IConfirmDialog,
  IDocument,
  IDynamicGridSettings,
  IEventEmitter,
  ITemplateSelector,
  IUser,
  TSelectionMode
} from '@interfaces/siam';
import dxButton, { InitializedEvent } from 'devextreme/ui/button';
import { NotifyService } from '@services/notify.service';
import { DocumentEditorComponent } from '../../dialogs/document-editor/document-editor.component';
import { TemplateService } from '@services/template.service';
import { TagsService } from '@services/tags.service';
import { copy } from '@factories/document.factory';
import { custom } from 'devextreme/ui/dialog';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { getDomId } from '@factories/helpers';
import { LoadPanelService } from '@services/load-panel.service';
import { ItemClickEvent } from 'devextreme/ui/action_sheet';
import { GetTagPipe } from '@pipes/get-tag.pipe';

@Component({
  selector: 'app-children-list',
  templateUrl: './children-list.component.html',
  styleUrls: ['./children-list.component.scss']
})
export class ChildrenListComponent implements OnChanges, OnDestroy {
  @ViewChild(DocumentEditorComponent, { static: true }) documentEditorComponent: DocumentEditorComponent;

  @Input() fieldContainer: IDxFormItemTemplate;
  @Input() currentDocument: IDocument = null;
  @Input() isDocumentHasChanges: () => Observable<boolean>;
  @Output() eventResult = new EventEmitter<IEventEmitter<boolean>>();

  actionSheetChildrenVisible: boolean;
  addChildBtn: dxButton;
  currentUser: IUser;
  dataSource: IDocument[] = [];
  dynamicGridListener: Observable<IEventEmitter<unknown>>;
  creatableChildTemplates: ITemplateSelector[] = [];
  canCreateChildTemplate: boolean;
  siamTags: ICategory[] = [];
  freeChildrenDokumentsSelected: IDocument[] = [];
  popupFreeChildrenListVisible = false;
  popupFreeChildrenListTitle = 'Bitte wählen Sie die gewünschte Vorlage';
  selectedChildDocument: IDocument;
  hasDeletePermission = false;
  allowAddReference = false;
  allowCreateChild = false;
  createChildButtonId = getDomId();
  dynamicListButtons: IDxEditorToolbarItem[] = [];
  documentType = 'submission';
  selectionMode: TSelectionMode = 'multiple';
  additionalSettings: IDynamicGridSettings = null;
  dynamicGridSettings: IDynamicGridSettings = null;

  #dynamicGridListener = new Subject<IEventEmitter<unknown>>();
  #destroyable$ = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private loadPanelService: LoadPanelService,
    private pipeTag: GetTagPipe,
    private tagsService: TagsService,
    private templateService: TemplateService
  ) {
    this.dynamicGridListener = this.#dynamicGridListener.asObservable();
    this.siamTags = this.tagsService.siamTags();
  }

  ngOnChanges(): void {
    this.setData();
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  setData(): void {
    if (this.fieldContainer) {
      const dynamicListButtons: IDxEditorToolbarItem[] = [];
      const templateIds = this.fieldContainer.editorOptions.childrenAllowedTemplates;
      const childrenTags = this.fieldContainer.editorOptions.childrenTags;

      this.documentType = this.fieldContainer.editorOptions.dynamicListName || 'submission';

      const additionalSettings: IDynamicGridSettings = {
        toolbar: {
          dateField: null,
          userField: null,
          isShowSearch: true,
          isShowDateFilter: false,
          isShowExportExcel: false,
          isShowGrouping: false,
          isShowPersonalMode: false,
          isShowPreviewSwitch: false,
          rowAlternationEnabled: true,
          isShowTitle: false
        }
      };
      const showParents = this.fieldContainer?.editorOptions?.showParentDocuments;
      if (showParents) {
        additionalSettings.includeChildIds = [this.currentDocument.id];
      } else {
        additionalSettings.includeParentIds = [this.currentDocument.id];
      }

      if (templateIds?.length) {
        additionalSettings.includeOneOfTemplateId = templateIds;
      } else if (childrenTags?.length) {
        additionalSettings.includeOneOfTags = childrenTags;
      }

      this.allowAddReference =
        this.currentDocument.effectivePermissions?.some(p => p === 'update') &&
        this.fieldContainer.editorOptions.allowAddReference &&
        !this.fieldContainer.editorOptions.readOnly;
      this.allowCreateChild =
        this.currentDocument.effectivePermissions?.some(p => p === 'update') &&
        this.fieldContainer.editorOptions.allowCreateChild &&
        !this.fieldContainer.editorOptions.readOnly;
      this.hasDeletePermission =
        this.currentDocument.effectivePermissions?.some(p => p === 'update') &&
        !this.fieldContainer.editorOptions.readOnly;

      if (showParents) {
        this.allowAddReference = false;
        this.allowCreateChild = false;
        this.hasDeletePermission = false;
      }

      if (this.allowCreateChild) {
        dynamicListButtons.push({
          location: 'before',
          widget: 'dxButton',
          options: {
            elementAttr: { id: this.createChildButtonId },
            icon: 'add',
            text: 'Referenzdokument erstellen',
            // Referenzdokument löschen / Referenzdokument lösen
            stylingMode: 'contained',
            onInitialized: (e: InitializedEvent) => {
              this.addChildBtn = e.component;
            },
            onClick: this.addChild
          }
        });
      }

      if (this.allowAddReference) {
        dynamicListButtons.push({
          location: 'before',
          widget: 'dxButton',
          options: {
            icon: 'custom-icon-size material-icons link',
            text: 'Referenzdokument verknüpfen',
            // Referenzdokument löschen / Referenzdokument lösen
            stylingMode: 'contained',
            onInitialized: (e: InitializedEvent) => {
              this.addChildBtn = e.component;
            },
            onClick: this.showFreeDokumentList
          }
        });
      }
      this.dynamicListButtons = dynamicListButtons;
      this.additionalSettings = additionalSettings;
    }
  }

  eventDynamicListResult(e: IEventEmitter<IDocument | IDocument[]>): void {
    if (e?.command === 'delete' && e.object) {
      this.deleteChildDocument(e.object as IDocument);
    }
    if (e?.command === 'documents' && e.object) {
      this.dataSource = e.object as IDocument[];
    }
  }

  eventDynamicListResultFreeChildren(event: IEventEmitter<IDocument | IDocument[] | string>): void {
    if (event?.command === 'selection' && event.object) {
      this.freeChildrenDokumentsSelected = event.object as IDocument[];
    }
    if (event?.command === 'error' && event?.object) {
      NotifyService.component.error(event?.object as string);
    }
  }

  addChild = (): void => {
    if (!this.currentDocument.id) {
      NotifyService.global.error('Bitte speichern Sie den Vorgang zuerst.');
      return;
    }
    this.isDocumentHasChanges()
      .pipe(
        switchMap(isChanged => {
          if (!this.currentDocument.id) {
            NotifyService.global.error('Bitte speichern Sie den Vorgang zuerst.');
            return Promise.resolve(false);
          }
          return isChanged ? this.confirmDialog().show() : Promise.resolve(true);
        }),
        filter(isChanged => isChanged),
        switchMap(() => this.templateService.getExecutableTemplates('execute')),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: templates => {
          const templateIds = this.fieldContainer.editorOptions.childrenAllowedTemplates;
          const tags = this.fieldContainer.editorOptions.childrenTags;

          if (templates && templates.length > 0) {
            this.creatableChildTemplates = [];
            this.canCreateChildTemplate = true;
            let filteredTemplates: ITemplateServer[] = [];
            if (templateIds?.length) {
              filteredTemplates = templates.filter(template => templateIds.some(id => template.id === id));
            } else if (tags?.length) {
              filteredTemplates = templates.filter(template => tags.find(t => template.tags.some(tmp => tmp === t)));
            }

            this.creatableChildTemplates = filteredTemplates.map(
              template =>
                ({
                  documentTemplate: template,
                  icon: this.pipeTag.transform(template.tags, 'icon'),
                  label: template.caption,
                  source: this.tagsService.siamTags()?.find(t => template.tags.includes(t.tag))?.label
                } as ITemplateSelector)
            );

            if (!this.creatableChildTemplates.length) {
              NotifyService.global.error(
                'Es wurde keine Vorlage für die Erstellung eines Kinddokuments gefunden. Mögliche Ursachen sind: Fehlende Berechtigung oder fehlende Vorlage.'
              );
            }
            this.actionSheetChildrenVisible = true;
          } else {
            this.canCreateChildTemplate = false;
          }
        },
        error: NotifyService.global.error
      });
  };

  createChildProcess = (e: ItemClickEvent<ITemplateSelector>): void => {
    const itemData = e.itemData;
    if (itemData) {
      const document = this.documentService.create(itemData.documentTemplate);
      const tag = this.documentService.getType(document);
      this.documentEditorComponent.createDocument([tag], this.currentDocument, itemData.documentTemplate.id);
    }
  };

  showFreeDokumentList = (): void => {
    if (!this.currentDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.isDocumentHasChanges()
      .pipe(
        switchMap(isChanged => {
          if (!this.currentDocument.id) {
            NotifyService.global.error('Bitte speichern Sie den Vorgang zuerst.');
            return Promise.resolve(false);
          }
          return isChanged ? this.confirmDialog().show() : Promise.resolve(true);
        }),
        filter(isChanged => isChanged),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: () => {
          this.loadPanelService.show();
          this.popupFreeChildrenListVisible = true;
          const dynamicGridSettings: IDynamicGridSettings = {
            excludeDocumentIds: [this.currentDocument.id],
            excludeTags: [],
            excludeStatus: [],
            toolbar: {
              dateField: null,
              userField: null,
              isShowSearch: true,
              isShowDateFilter: false,
              isShowExportExcel: false,
              isShowGrouping: false,
              isShowPersonalMode: false,
              isShowPreviewSwitch: false,
              rowAlternationEnabled: true,
              isShowKanban: true,
              isShowTitle: false
            }
          };
          const tags = this.fieldContainer.editorOptions.childrenTags;
          const templateIds = this.fieldContainer.editorOptions.childrenAllowedTemplates;
          this.documentType = this.fieldContainer.editorOptions.dynamicListName;

          if (templateIds?.length) {
            dynamicGridSettings.includeOneOfTemplateId = templateIds;
          } else if (tags?.length) {
            dynamicGridSettings.includeOneOfTags = tags;
          }
          if (this.dataSource?.length) {
            const excludeDocumentIds = this.dataSource.map(t => t.id);
            dynamicGridSettings.excludeDocumentIds = dynamicGridSettings.excludeDocumentIds.concat(excludeDocumentIds);
          }

          this.dynamicGridSettings = dynamicGridSettings;
        },
        complete: () => {
          this.loadPanelService.hide();
        },
        error: (error: string) => {
          NotifyService.global.error(error);
          this.loadPanelService.hide();
        }
      });
  };

  popupFreeChildrenListOk = (): void => {
    this.popupFreeChildrenListVisible = false;
    void this.setReferenceFreeChildren();
  };

  popupFreeChildrenListCancel = (): void => {
    this.popupFreeChildrenListVisible = false;
  };

  setReferenceFreeChildren(iterator?: IterableIterator<IDocument>): void {
    if (!this.freeChildrenDokumentsSelected?.length) {
      return;
    }

    if (!iterator) {
      iterator = this.freeChildrenDokumentsSelected[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      this.onReload();
      return;
    }

    const document = copy(result.value as IDocument);
    try {
      this.documentService
        .setReference(document.id, this.currentDocument.id)
        .pipe(takeUntil(this.#destroyable$))
        .subscribe({
          error: (error: string): void => {
            this.loadPanelService.hide();
            NotifyService.global.error(error);
          },
          complete: (): void => {
            void this.setReferenceFreeChildren(iterator);
          }
        });
    } catch (error) {
      this.loadPanelService.hide();
    }
  }

  dialogResult(e: IEventEmitter<IDocument>): void {
    if (e.command === 'store') {
      this.documentService
        .getDocumentById(e.object.id)
        .pipe(
          switchMap(task => this.documentService.setReference(task.id, this.currentDocument.id)),
          filter(document => !!document),
          takeUntil(this.#destroyable$)
        )
        .subscribe({
          next: (): void => {
            this.onReload();
          },
          error: NotifyService.global.error
        });
    }
  }

  deleteChildDocument(document: IDocument): void {
    if (document) {
      this.selectedChildDocument = document;
      const documentSubject =
        (this.selectedChildDocument?.fields?.subject?.value as string) ||
        this.selectedChildDocument?.template?.caption ||
        '';
      void this.deleteConfirmDialog(documentSubject).show();
    }
  }

  confirmDeleteClose = (): void => {
    this.loadPanelService.hide();
  };

  // entgültig löschen
  confirmDeleteOK = (): void => {
    this.documentService
      .removeReference(this.selectedChildDocument.id, this.currentDocument.id)
      .pipe(
        switchMap(() => this.documentService.delete(this.selectedChildDocument)),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: () => {
          this.onReload();
          NotifyService.global.success('Das referenzierte Dokument wurde endgültig gelöscht.');
        }
      });
  };

  // entfernen und parken
  confirmDeleteReference = (): void => {
    this.documentService
      .removeReference(this.selectedChildDocument.id, this.currentDocument.id)
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: () => {
          this.onReload();
          NotifyService.global.success('Die Verknüpfung wurde gelöst.');
        },
        error: (): void => {
          this.loadPanelService.hide();
        }
      });
  };

  private confirmDialog = (): IConfirmDialog =>
    custom({
      title: 'Referenzdokument',
      messageHtml:
        'Sie haben Änderungen am Dokument vorgenomen. <br> Wenn Sie diese behalten möchten speichern Sie vorher das Dokument.  <br> <br> Möchten Sie trotzdem fortfahren?',
      buttons: [
        {
          text: 'Fortfahren',
          icon: 'check',
          onClick: () => true
        },
        {
          text: 'Abbrechen',
          icon: 'clear',
          onClick: () => false
        }
      ]
    }) as IConfirmDialog;

  private deleteConfirmDialog = (subject: string): IConfirmDialog =>
    custom({
      title: `Referenzdokument entfernen (${subject})`,
      messageHtml: 'Möchten Sie dieses Referenzdokument endgültig löschen, oder nur die Verknüpfung lösen?',
      buttons: [
        {
          text: `Löschen`,
          icon: 'check',
          onClick: this.confirmDeleteOK
        },
        {
          text: 'Verknüpfung lösen',
          icon: 'custom-icon-size material-icons link_off',
          onClick: this.confirmDeleteReference
        },
        {
          text: 'Abbrechen',
          icon: 'clear',
          onClick: this.confirmDeleteClose
        }
      ]
    }) as IConfirmDialog;

  private onReload(): void {
    this.#dynamicGridListener.next({
      command: 'reload',
      object: true
    });
  }
}
