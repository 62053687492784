import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom, of, Subject } from 'rxjs';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { DocumentService } from '@services/document.service';
import { LabelsIncludeComponent } from '../../../application/components/forms/subforms/labels-include/labels-include.component';
import { TasksFormComponent } from '../tasks-form/tasks-form.component';
import { getCurrentUser } from '@factories/user.factory';
import { SiamList } from '@interfaces/siamList';
import { IAssignment, IDocument, IDocumentsSearch, IError, ILabel, ITask, IUser, SiamListItem } from '@interfaces/siam';
import { DocumentHelperService } from '@services/document-helper.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { NotifyService } from '@services/notify.service';
import { ListsService } from '@services/lists.service';
import { LoadPanelService } from '@services/load-panel.service';
import { AddEvent, DragStartEvent, ReorderEvent } from 'devextreme/ui/sortable';
import { createQueryObject, queryObjectToLucene } from '@factories/helpers';

interface ITaskPanel {
  name: string;
  label: string;
  tasks: ITask[];
}

@Component({
  selector: 'app-tasks-kanban-board',
  templateUrl: './tasks-kanban-board.component.html',
  styleUrls: ['./tasks-kanban-board.component.scss']
})
export class TasksKanbanBoardComponent implements OnInit, OnDestroy {
  @ViewChild(LabelsIncludeComponent) labelsInclude: LabelsIncludeComponent;
  @ViewChild(TasksFormComponent, { static: true }) taskForm: TasksFormComponent;

  @Input() embeddedMode = false;
  @Input() personalMode = false;

  tasks: IDocument[] = [];
  taskPriorities: SiamListItem[];
  tasksShowOpen: boolean;
  tasksShowDone: boolean;
  currentUser: IUser;
  taskPanels: ITaskPanel[] = [];
  filteredLabels: ILabel[] = [];

  #lists: Record<string, SiamList> = {};
  #destroyable = new Subject<void>();

  constructor(
    private documentService: DocumentService,
    private listsService: ListsService,
    private loadPanelService: LoadPanelService,
    private nameDataPipe: GetNameDataPipe,
    private helperService: DocumentHelperService
  ) {
    this.tasksShowOpen = true;
    this.tasksShowDone = true;
  }

  ngOnInit(): void {
    this.showLoadingPanel();
    this.currentUser = getCurrentUser();

    let user: IUser = null;
    if (this.personalMode) {
      user = this.currentUser;
    }

    const queryObject = createQueryObject();
    queryObject.and.include.push(`tags:${JSON.stringify('app:document-type:task')}`);

    const search: IDocumentsSearch = {
      userField: 'fields.chair',
      user,
      query: queryObjectToLucene(queryObject),
      documentFields: [
        'fields.enddate',
        'fields.subject',
        'fields.chair',
        'fields.progress',
        'fields.priority',
        'template.caption',
        'documentWorkflowDocument.currentStatusLabel',
        'creation.timestamp',
        'creation.userId',
        'tags',
        'references'
      ],
      isIncludeLists: true
    };

    this.documentService
      .documentsSearch(search)
      .pipe(
        switchMap(data => {
          this.#lists = data.lists;
          this.tasks = data.documents || [];
          const listName = 'list:Prioritäten';

          if (this.#lists && this.#lists[listName]?.name === 'Prioritäten') {
            return of(this.#lists[listName].entries);
          }
          return this.listsService.getListEntries('Prioritäten');
        }),
        takeUntil(this.#destroyable)
      )
      .subscribe({
        next: priorities => {
          this.taskPriorities = priorities;
          this.updateTaskLists();
        },
        error: (error: IError) => {
          NotifyService.component.error(error);
          this.loadPanelService.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this.#destroyable.next();
    this.#destroyable.complete();
  }

  selectTask(task: ITask): void {
    this.helperService.openDocument(task.document);
  }

  // Drag & Drop Funktionen
  onListReorder(e: ReorderEvent): void {
    const list = this.taskPanels.splice(e.fromIndex, 1)[0];
    this.taskPanels.splice(e.toIndex, 0, list);
  }

  onTaskDragStart(e: DragStartEvent): void {
    e.itemData = (e.fromData as ITask[])[e.fromIndex];
  }

  onTaskDrop(e: ReorderEvent | AddEvent): void {
    (e.fromData as ITask[]).splice(e.fromIndex, 1);
    (e.toData as ITask[]).splice(e.toIndex, 0, e.itemData as ITask);
  }

  private updateTaskLists(): void {
    this.showLoadingPanel();
    const taskPanels: Record<string, ITaskPanel> = {};
    this.tasks.map(async document => {
      let toAdd = false;
      const stateLabel =
        document.documentWorkflowDocument?.currentStatusLabel ||
        this.documentService.fetchWorkflowStatus(document.workflowDocuments, 'label');
      const stateKey =
        document.documentWorkflowDocument?.currentStatus ||
        this.documentService.fetchWorkflowStatus(document.workflowDocuments);
      const value =
        taskPanels[stateKey] ||
        (taskPanels[stateKey] = {
          name: stateKey,
          label: stateLabel,
          tasks: []
        });

      const _userData = document.fields?.chair?.value
        ? await lastValueFrom(this.nameDataPipe.transform(document.fields.chair.value as string))
        : null;
      const task: ITask = {
        id: document.id,
        document,
        state: stateKey,
        statelabel: stateLabel,
        priority: (document?.fields?.priority && (document.fields.priority.value as string)) || null,
        enddate: (document?.fields?.enddate && (document.fields.enddate.value as string)) || null,
        chairinitials: _userData?.initials || null,
        chairurl: _userData?.avatarUrl || null,
        subject: (document.fields?.subject?.value as string) || '',
        body: (document.fields?.body?.value as string) || '',
        labels: await lastValueFrom(this.documentService.getLabels(document))
      };

      // optional filtern nach User
      if (!this.personalMode) {
        toAdd = true;
      } else {
        //const state = this._workflowStatusPipe.transform(document.workflowDocuments);
        const chair = document.fields.chair.value as IAssignment[];
        let foundChair;
        if (Array.isArray(chair)) {
          foundChair = chair.findIndex(n => n.targetId === this.currentUser.id) !== -1;
        }
        if (foundChair) {
          toAdd = true;
        }
      }

      // optional filtern nach Labels
      if (this.filteredLabels.length) {
        if (task.labels.length) {
          if (task.labels.some(l => this.filteredLabels.findIndex(fl => fl.id === l.id) !== -1)) {
            toAdd = true;
          }
        }
      }

      if (toAdd) {
        value.tasks.push(task);
      }
    });

    this.taskPanels = Object.getOwnPropertyNames(taskPanels).map(name => taskPanels[name]);

    this.loadPanelService.hide();
  }

  private showLoadingPanel(): void {
    if (!this.embeddedMode) {
      this.loadPanelService.show();
    }
  }
}
