<dx-tab-panel
  id="votesTabPanel"
  [dataSource]="ballotDataStore"
  [selectedIndex]="ballotDataStore.length - 1"
  [animationEnabled]="false">
  <div *dxTemplate="let tab of 'title'">
    {{ tab.caption }}
    <span>{{ tab.startTime | date : 'dd.MM.y - HH:mm' }}</span>
  </div>

  <div *dxTemplate="let tab of 'item'">
    <!-- Tab -->
    <div class="ballots-data">
      <div class="ballots-data__description">{{ tab.description }}</div>
      <div class="ballots-data__summary">
        <h4 i18n>Status: {{ tab.state }}</h4>
        <div>
          Stimmen:&nbsp;
          <span>{{ tab.ballotSummary.submittedVotes }} von {{ tab.ballotSummary.totalVotes }}</span>
        </div>
        <div>
          <i class="material-icons md-20 vote-value--yes">thumb_up</i>&nbsp;positiv:&nbsp;
          <span>
            {{ tab.ballotSummary.positiveVotes }} ({{
              tab.ballotSummary.positiveVotes / tab.ballotSummary.totalVotes | percent
            }})
          </span>
        </div>
        <div>
          <i class="material-icons md-20 vote-value--no">thumb_down</i>&nbsp;negativ:&nbsp;
          <span>
            {{ tab.ballotSummary.negativeVotes }} ({{
              tab.ballotSummary.negativeVotes / tab.ballotSummary.totalVotes | percent
            }})
          </span>
        </div>
        <div *ngIf="tab?.documentStatus && tab?.hasReadAttachment">
          Dokumentstand:&nbsp;
          <a target="_blank" [href]="tab.documentStatus | fileDownloadLink : 'blobUrl' : tab.ballotDocument | async">
            <i class="material-icons">save_alt</i>
          </a>
        </div>
      </div>
      <dx-data-grid
        id="ballotsGrid"
        [dataSource]="tab.ballotStatus"
        [showBorders]="true"
        [showRowLines]="true"
        [showColumnLines]="true"
        [hoverStateEnabled]="false"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
        [selectedRowKeys]="[]"
        (onRowPrepared)="onRowPrepared($event)">
        <dxo-scrolling mode="virtual"></dxo-scrolling>

        <!-- columns -->
        <dxi-column
          caption="Teilnehmer"
          dataField="avatarName"
          cssClass="cell-voter"
          sortOrder="asc"
          [encodeHtml]="false"
          [width]="'auto'"
          minWidth="130"
          [calculateSortValue]="sortUsernames">
        </dxi-column>

        <dxi-column
          caption="Am"
          dataField="timestamp"
          dataType="date"
          alignment="center"
          [allowSorting]="true"
          [format]="{ type: 'dd.MM.yyyy - HH:mm' }"
          [width]="150">
        </dxi-column>

        <dxi-column caption="VOTUM" alignment="center">
          <dxi-column
            *ngFor="let column of tab.ballotSummary.possibleVotes"
            dataField="vote"
            alignment="center"
            cssClass="subcolumns-header"
            cellTemplate="cellVoteTemplate"
            [name]="column.label"
            [caption]="column.label"
            [width]="'auto'">
          </dxi-column>
        </dxi-column>

        <dxi-column caption="Begründung" dataField="reason" cellTemplate="cellCommentTemplate">
        </dxi-column>

        <!-- Templates -->
        <div class="vote-value" *dxTemplate="let cell of 'getVoteIcons'">
          <span [outerHTML]="cell.data.vote | getVoteIcons : tab.ballotSummary.possibleVotes"></span>&nbsp;
          <span>{{ cell.data.vote ? cell.data.vote : 'offen' }}</span>
        </div>

        <div *dxTemplate="let cell of 'cellVoteTemplate'">
          <span class="vote-sign" *ngIf="cell.data.voteLabel === cell.column.caption">X</span>
        </div>

        <div *dxTemplate="let cell of 'cellCommentTemplate'">
          <span
            *ngIf="cell.displayValue"
            style="text-align: left; font-size: 0.8rem"
            [innerHTML]="cell.displayValue | safeHtml"></span>
        </div>
      </dx-data-grid>

      <div class="ballots-data__chart" *ngIf="tab.ballotSummary?.submittedVotes > 0">
        <dx-pie-chart [dataSource]="tab.ballotSummary.chartData" type="doughnut" [customizePoint]="customizePoint">
          <dxi-series argumentField="vote" valueField="count"></dxi-series>
          <dxo-size [height]="150"></dxo-size>
          <dxo-legend verticalAlignment="right" horizontalAlignment="left" [columnItemSpacing]="20"></dxo-legend>
          <dxo-tooltip [enabled]="true" contentTemplate="content" zIndex="9999"></dxo-tooltip>
          <div *dxTemplate="let info of 'content'">
            {{ info.argumentText }} - {{ info.originalValue }} ({{ info.percentText }})
          </div>
        </dx-pie-chart>
      </div>
    </div>
  </div>
</dx-tab-panel>
