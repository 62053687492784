<dx-popup
  title="Workflowdiagramm"
  width="90vw"
  [showCloseButton]="false"
  (onShown)="onShown()"
  [fullScreen]="dialogFullscreen"
  [(visible)]="isVisible"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'material-icons md-24 fit_screen', hint: 'Passend zoomen' },
      onClick: dialogZoom
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'download', hint: 'Herunterladen' },
      onClick: dialogExport
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'expandform', hint: 'Vollbildmodus umschalten' },
      onClick: dialogToogleFullscreen
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: dialogCancel
    }
  ]">
  <div *dxTemplate="let data of 'content'" class="workflowdiagramm">
    <ngx-graph
      [nodes]="nodes"
      [links]="links"
      [curve]="shape.curveBundle"
      [autoZoom]="true"
      [draggingEnabled]="false"
      [autoCenter]="true"
      [center$]="center$"
      [update$]="update$"
      [zoomToFit$]="zoomToFit$">
      <ng-template #defsTemplate>
        <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
          <svg:path d="M0,-5L10,0L0,5" class="arrow-head" fill="#000" />
        </svg:marker>
        <svg:marker
          id="arrow-gray"
          viewBox="0 -5 10 10"
          refX="7"
          refY="0"
          markerWidth="4"
          markerHeight="4"
          orient="auto">
          <svg:path d="M0,-5L10,0L0,5" class="arrow-head" fill="#999" />
        </svg:marker>
      </ng-template>

      <ng-template #nodeTemplate let-node>
        <svg:g
          class="node"
          ngx-tooltip
          [attr.tooltipPlacement]="'top'"
          [attr.tooltipType]="'tooltip'"
          [attr.tooltipTitle]="node.label">
          <svg:ellipse
            [class.current-verticesSvgRect]="stateLabel === node.label"
            style="fill: hsl(var(--background-color)); stroke: var(--border-color); stroke-width: 3"
            [attr.rx]="node.dimension.width / 2"
            [attr.ry]="node.dimension.height * 0.57"
            [attr.cx]="node.dimension.width / 2"
            [attr.cy]="node.dimension.height / 2" />

          <svg:text
            style="fill: var(--text-color)"
            alignment-baseline="central"
            text-anchor="middle"
            [class.current-verticesSvgText]="stateLabel === node.label"
            [attr.x]="node.dimension.width / 2"
            [attr.y]="node.dimension.height / 2">
            {{ node.label }}
          </svg:text>
        </svg:g>
      </ng-template>

      <ng-template #linkTemplate let-link>
        <svg:g
          class="edge"
          (mousemove)="showTooltip($event, link)"
          (mouseout)="hideTooltip()"
          [class.hist-edge]="link.data.history === true"
          [class.notHist-edge]="link.data.history !== true">
          <svg:path
            class="line"
            [class.hist-edge]="link.data.history === true"
            [class.notHist-edge]="link.data.history !== true"
            stroke-width="3"></svg:path>

          <svg:text class="edge-label" text-anchor="middle">
            <textPath class="text-path text-path-after" [attr.href]="'#' + link.id" startOffset="50%">
              {{ link.label }}
            </textPath>
            <textPath class="text-path text-path-before" [attr.href]="'#' + link.id" startOffset="50%">
              {{ link.data.historyStep }}
            </textPath>
          </svg:text>
        </svg:g>
      </ng-template>
    </ngx-graph>
    <div id="tooltipEdge" class="tooltip-edges" style="position: absolute; display: none"></div>
  </div>
</dx-popup>
