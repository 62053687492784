import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationRule } from 'devextreme-angular/common';
import { ValueChangedEvent } from 'devextreme/ui/text_box';
import { TextBox } from '@interfaces/fieldClient';

@Component({
  selector: 'app-form-textbox',
  templateUrl: './form-textbox.component.html',
  styleUrls: ['./form-textbox.component.scss']
})
export class FormTextboxComponent {
  @Input() isEditing = false;

  @Input() field: TextBox;

  @Input() validationGroupId: string = null;

  @Input() validators: ValidationRule[] = [];

  @Input() value!: string;

  @Output() valueChange = new EventEmitter<string>();

  valueChanged = (e: ValueChangedEvent): void => {
    this.valueChange.emit(e.value as string);
  }
}
