<aside class="left-side" [class.left-side__menu-icons]="isNavHidden">
  <div class="dashboard top" [class.dashboard--hidden]="isDashboardHidden">
    <div (click)="toggleDashboard()" class="dashboard_toggler">
      <i class="material-icons">space_dashboard</i>
    </div>
    <dx-scroll-view height="100%">
      <div class="dashboard_content">
        <div class="dashboard_grid">
          <ng-container *ngFor="let card of cards">
            <div
              class="dashboard_card dashboard_card__small"
              [style.--card-color]="card.settings.cardColor"
              (click)="openInfoPopup(card)">
              <div class="dashboard_card_icon">
                <i class="material-icons md-36">{{ card.settings.icon }}</i>
              </div>
              <div class="dashboard_card_titel" title="{{ card.settings.text }} | {{ card.settings.subText }}">
                {{ card.settings.text }}
                <span class="dashboard_card_subtitel">{{ card.settings.subText }}</span>
              </div>
              <div class="dashboard_card_count">
                {{ card.documentCount }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="dashboard_grid">
          <ng-container *ngFor="let chart of bigCards">
            <div class="dashboard_card dashboard_card__big" [ngSwitch]="chart.type">
              <app-scheduler *ngSwitchCase="'calendar'" [card]="chart" [events]="eventsScheduler.asObservable()" class="dashboard-calendar"></app-scheduler>
              <app-chart
                *ngSwitchCase="'chart'"
                [chart]="chart"
                [lists]="lists"
                [users]="users"
                [roles]="roles"></app-chart>
            </div>
          </ng-container>
        </div>
      </div>
    </dx-scroll-view>
  </div>

  <div class="left-side_controls">
    <div class="create-control" (click)="actionSheetVisible = true" *ngIf="applicationMode !== 'administration'">
      <a class="create-control-link" id="createOf" *ngIf="canCreateTemplate && creatableDocTypes.length">
        <i class="material-icons md-20" id="create">add_box</i>
        <span *ngIf="!isNavHidden">Erstellen...</span>
      </a>
      <dx-tooltip
        *ngIf="!actionSheetVisible"
        target="#create"
        position="bottom"
        showEvent="dxhoverstart"
        hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">Dokument erstellen</div>
      </dx-tooltip>
    </div>
  </div>

  <dx-scroll-view height="100%" class="left-side__menu__container">
    <div class="left-side__menu">
      <div
        class="menu-section"
        *ngFor="let group of naviList; let i = index"
        [class.list-group-closed]="group.isClosed">
        <h3 *ngIf="group.text" (click)="closeGroup(group)">
          <i *ngIf="group.icon" class="material-icons md-20" [class.blank-icon]="group.icon === 'remove'">
            {{ group.icon }}
          </i>
          <span>{{ group.text }}</span>
        </h3>

        <div class="group-separator-wrapper" id="header-{{ i }}" (click)="closeGroup(group)">
          <i *ngIf="group.icon" class="material-icons md-20" [class.blank-icon]="group.icon === 'remove'">
            {{ group.icon }}
          </i>
          <div class="group-separator"></div>
        </div>
        <!-- tooltip -->
        <dx-tooltip target="#header-{{ i }}" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data = data; of: 'content'">
            {{ group.text }}
          </div>
        </dx-tooltip>

        <ul class="list-group left-side-navi">
          <li *ngFor="let link of group.links" class="left-side-navi-item">
            <a (click)="navigateTo(link)">
              <i
                *ngIf="link.icon"
                class="material-icons md-18"
                [class.blank-icon]="link.icon === 'remove'"
                id="{{ link.name }}">
                {{ link.icon }}
              </i>
              <span>{{ link.text }}</span>
            </a>
            <!-- tooltip -->
            <dx-tooltip
              *ngIf="isNavHidden"
              target="#{{ link.name }}"
              position="right"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend">
              <div *dxTemplate="let data = data; of: 'content'">
                {{ link.text }}
              </div>
            </dx-tooltip>
          </li>
        </ul>
      </div>
    </div>
  </dx-scroll-view>

  <div class="copyright">
    <div class="company-name">ventuno GmbH</div>

    <div class="toggle__pin" [class.toggle__pin-active]="isNavPinned" (click)="pinNavigation()">
      <i class="material-icons md-20" id="navPin">push_pin</i>
      <dx-tooltip target="#navPin" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">{{ pinTooltip }}</div>
      </dx-tooltip>
    </div>

    <div *ngIf="!isNavPinned" (click)="toggleNavigation()" class="toggle__switcher">
      <i id="navToggle" class="material-icons md-20">{{ chevronIcon }}</i>
      <dx-tooltip target="#navToggle" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">{{ chevronTooltip }}</div>
      </dx-tooltip>
    </div>
  </div>
</aside>

<dx-popup
  [width]="'auto'"
  [height]="'auto'"
  [shading]="false"
  [showTitle]="false"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="false"
  [(visible)]="actionSheetVisible"
  (onContentReady)="onContentReady($event)"
  [animation]="{
    show: {
      type: 'fade',
      from: { opacity: 0.5 },
      to: { opacity: 1 }
    }
  }">
  <dxo-position at="right top" my="left top" of="#createOf"> </dxo-position>
  <div *dxTemplate="let data of 'content'" class="create-items">
    <ul>
      <li *ngFor="let item of creatableDocTypes" (click)="onCreateProcessPopup(item)">
        <i *ngIf="item.icon" class="material-icons md-18 mr-2">{{ item.icon }}</i>
        <span>{{ item.text }}</span>
      </li>
    </ul>
  </div>
</dx-popup>

<dx-popup
  width="70vw"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [(visible)]="showInfoPopup"
  [height]="'auto'"
  maxHeight="80vh"
  [fullScreen]="false"
  [showTitle]="false"
  [showCloseButton]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [shading]="true"
  [toolbarItems]="infoPopupToolbarItems"
  (onHidden)="onHidden()">
  <div *dxTemplate="let data of 'content'">
    <app-dynamic-list
      *ngIf="selectedCard && selectedCard.type === 'list'"
      [embeddedMode]="true"
      [noState]="true"
      [personalMode]="false"
      [additionalSettings]="selectedCard.additionalSettings"
      [documentType]="selectedCard.settings.listName"></app-dynamic-list>
    <app-scheduler *ngIf="selectedCard && selectedCard.type === 'calendar'" [card]="selectedCard"></app-scheduler>
  </div>
</dx-popup>
