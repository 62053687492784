import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Apollo, gql } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import {
  IAttachment,
  IBrainloopLogin,
  IDocument,
  IDocumentField,
  IDocumentsSearch,
  IGQLResult,
  IGroup,
  IPermissionTarget,
  ISearch,
  ISearchGroupResponse,
  ISearchGroups,
  ISearchResponse,
  IUploadAttachment,
  IWorkflowAction,
  TEffectivePermission,
  TTag
} from '@interfaces/siam';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { LoggerService } from '@services/logger.service';
import { IDxGroupItem, IDxResolvedGroupData } from '@interfaces/devextreme';
import { combineQueries, createQueryObject, isHasProperty, queryObjectToLucene } from '@factories/helpers';

const QUERY_FRAGMENTS = `

fragment fieldPermission on TemplateFieldPermissionTargetListGraphType {

  include {
    type
    targetId
    compositeId
  }
  exclude {
    type
    targetId
    compositeId
  }
  selection
}

fragment sliderField on TemplateFieldSliderGraphType {
  minValue
  maxValue
  customValues
  underlyingType
}
fragment numberField on TemplateFieldNumberGraphType {
  decimalPlaces
  underlyingType
}
fragment checkboxField on TemplateFieldCheckBoxGraphType {
  threeState
}
fragment dateTimeField on TemplateFieldDateTimeGraphType {
  withTime
}
fragment radioGroupField on TemplateFieldRadioGroupGraphType {
  choices
  choicesDataSource {
    type
    ...listFieldDataSource
    ...staticFieldDataSource
  }
}
fragment checkboxGroupField on TemplateFieldCheckBoxGroupGraphType {
  choices
  choicesDataSource {
    type
    ...listFieldDataSource
    ...staticFieldDataSource
  }
}
fragment dropDownListField on TemplateFieldDropDownListGraphType {
  choices
  choicesDataSource {
    type
    ...listFieldDataSource
    ...staticFieldDataSource
  }
}
fragment attachmentField on TemplateFieldAttachmentListGraphType {
  supportedContentTypes
}
fragment nameField on TemplateFieldPermissionTargetListGraphType {
  allowMultiple
  forbidRoles
  forbidUsers
}
fragment fieldEntry on TemplateLayoutFieldEntryGraphType {
  fieldName
  orientation
  additionalData
}
fragment groupEntry on TemplateLayoutGroupEntryGraphType {
  name
  label
  orientation
}
fragment placeholderEntry on TemplateLayoutEntryPlaceholder {
  name
  placeholderType
  label
}
fragment listFieldDataSource on ListFieldDataSourceGraphType {
  name
}
fragment staticFieldDataSource on StaticFieldDataSourceGraphType {
  json
}
fragment createBallotAction on WorkflowCreateBallotActionGraphType {
  caption
  description
  voters {
    type
    ...documentParameter
    ...variableParameter

  }
  ballotIdStore {
    ...documentParameter
  }
}
fragment variableParameter on WorkflowVariableParameterGraphType {
  name
}
fragment staticParameter on WorkflowStaticParameterGraphType {
  value
}
fragment documentParameter on WorkflowDocumentParameterGraphType {
  source
}
`;

const QUERY_LIST_FRAGMENTS = `
fragment fieldPermission on TemplateFieldPermissionTargetListGraphType {
  include {
    type
    targetId
    compositeId
  }
  exclude {
    type
    targetId
    compositeId
  }
  selection
}
fragment sliderField on TemplateFieldSliderGraphType {
  minValue
  maxValue
}
fragment numberField on TemplateFieldNumberGraphType {
  decimalPlaces
}
fragment checkboxField on TemplateFieldCheckBoxGraphType {
  threeState
}
fragment dateTimeField on TemplateFieldDateTimeGraphType {
  withTime
}
fragment radioGroupField on TemplateFieldRadioGroupGraphType {
  choices
  choicesDataSource {
    type
    ...listFieldDataSource
    ...staticFieldDataSource
  }
}
fragment checkboxGroupField on TemplateFieldCheckBoxGroupGraphType {
  choices
  choicesDataSource {
    type
    ...listFieldDataSource
    ...staticFieldDataSource
  }
}
fragment dropDownListField on TemplateFieldDropDownListGraphType {
  choices
  choicesDataSource {
    type
    ...listFieldDataSource
    ...staticFieldDataSource
  }
}
fragment attachmentField on TemplateFieldAttachmentListGraphType {
  supportedContentTypes
}
fragment nameField on TemplateFieldPermissionTargetListGraphType {
  allowMultiple
  forbidRoles
  forbidUsers
}
fragment listFieldDataSource on ListFieldDataSourceGraphType {
  name
}
fragment staticFieldDataSource on StaticFieldDataSourceGraphType {
  json
}`;

interface IGQLData {
  documents?: IDocument[];
  document?: IDocument;
  documentsByTags?: IDocument[];
  documentsByIds?: IDocument[];
}

interface IGQLSetReference {
  document: {
    setParent: IGQLData;
  };
}

@Injectable({
  providedIn: 'root'
})
export class DocumentApi {
  private readonly apiBaseUrl: string;
  private readonly apiSearchUrl: string;
  private readonly apiSearchUrlV11: string;
  private readonly apiAttachmentUrl: string;
  private readonly graphQLUrl: string;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private logger: LoggerService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.apiBaseUrl = `${baseUrl}api/documents`;
    this.apiSearchUrl = `${baseUrl}api/document-search`;
    this.apiSearchUrlV11 = `${baseUrl}api/v1.1/document-search`;
    this.apiAttachmentUrl = `${baseUrl}api/attachment`;
    this.graphQLUrl = `${baseUrl}api/graphql`;
  }

  /**
   * delete the document from DB
   *
   * @param document
   * @param recursive
   * @param force
   * @param maxDepth
   */
  delete(document: IDocument, recursive?: boolean, force?: boolean, maxDepth = 10000): Observable<void> {
    const params = new HttpParams()
      .set('force', force ? 'true' : 'false')
      .set('recursive', recursive ? 'true' : 'false')
      .set('maxDepth', maxDepth);

    return this.http.delete<void>(`${this.apiBaseUrl}/${document.id}`, { params }).pipe(
      tap({
        next: (): void => {
          this.logger.debug('Document wurde gelöscht: {@document}', document.id);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des Dokumentes: {@error}', error);
        }
      })
    );
  }

  /**
   * executeWorkflowAction
   * action Document in DB
   *
   * @param documentId
   * @param workflowId
   * @param actionName
   * @param variables
   */
  executeWorkflowAction(
    documentId: string,
    workflowId: string,
    actionName: string,
    variables: Record<string, unknown> = null
  ): Observable<IDocument> {
    return this.http
      .post<IDocument>(
        `${this.apiBaseUrl}/${documentId}/workflows/${workflowId}/edges/${actionName}/execute`,
        variables || {}
      )
      .pipe(
        tap({
          next: () => {
            this.logger.debug('post document edge= {@document}', documentId);
          },
          error: error => {
            this.logger.error(
              'Fehler beim Ausführen der Workflow Aktion [{@action}] des Dokuments:\n {@error}',
              actionName,
              error
            );
          }
        })
      );
  }

  createDocumentByTemplateId(templateId: string): Observable<IDocument> {
    return this.http.get<IDocument>(`${this.apiBaseUrl}/create/by-template/${templateId}`).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Fetch neues Dokument bei Template ID: {@error}', error);
        }
      })
    );
  }

  getAttachmentFromDocument(documentId: string, attachmentId: string): Observable<BlobPart> {
    return this.http
      .get<BlobPart>(`${this.apiBaseUrl}/${documentId}/data/${attachmentId}`, { responseType: 'blob' as 'json' })
      .pipe(
        tap({
          error: error => {
            this.logger.error('Fehler beim Erhalten des Anhangs: {@error}', error);
          }
        })
      );
  }

  getAttachment(attachmentId: string): Observable<BlobPart> {
    return this.http.get<BlobPart>(`${this.apiAttachmentUrl}/${attachmentId}`, { responseType: 'blob' as 'json' }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Erhalten des Anhangs: {@error}', error);
        }
      })
    );
  }

  getPrintURL(document: IDocument, isDownload = false): string {
    const strDownload = isDownload ? 'true' : 'false';
    return `${this.apiBaseUrl}/${document.id}/print?download=${strDownload}`;
  }

  uploadAttachment(data: IUploadAttachment): Observable<IAttachment> {
    const body = new FormData();
    body.append('file', data.file);
    if (data.properties) {
      try {
        const prop = JSON.stringify(data.properties);
        body.append('properties', prop);
      } catch (error) {
        // error
      }
    }
    if (data.tags) {
      try {
        const tags = JSON.stringify(data.tags);
        body.append('properties', tags);
      } catch (error) {
        // error
      }
    }

    if (data.description) {
      body.append('description', data.description);
    }
    return this.http.post<IAttachment>(`${this.apiAttachmentUrl}/upload`, body).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim hochladen des Anhangs: {@error}', error);
        }
      })
    );
  }

  cloneAttachments(data: string[]): Observable<Record<string, IAttachment>> {
    const body = new FormData();
    if (data?.length) {
      data.forEach(id => {
        body.append('attachmentIds', id);
      });
    }
    return this.http.post<Record<string, IAttachment>>(`${this.apiAttachmentUrl}/clone`, body).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim hochladen des Anhangs: {@error}', error);
        }
      })
    );
  }

  /**
   * getting the document permission for current login user
   *
   * @param documentId
   */
  getPermissions(documentId: string): Observable<TEffectivePermission[]> {
    return this.http.options<TEffectivePermission[]>(`${this.apiBaseUrl}/${documentId}`).pipe(
      tap({
        error: error => {
          this.logger.error(`Fehler beim Laden der Permissions des Dokuments: {@error}`, error);
        }
      })
    );
  }

  /**
   * Get a document workflows edges with the document GUID
   *
   * @param documentId
   * @param workflowId
   */
  getWorkflowEdges(documentId: string, workflowId: string): Observable<IWorkflowAction[]> {
    return this.http.get<IWorkflowAction[]>(`${this.apiBaseUrl}/${documentId}/workflows/${workflowId}/edges`).pipe(
      tap({
        error: error => {
          this.logger.error(`Fehler beim Laden der Workflowsedges des Dokuments: {@error}`, error);
        }
      }),
      share()
    );
  }

  /**
   * Get a document workflows edges with the document GUID
   *
   * @param documentId
   * @param workflowId
   */
  getDocumentWorkflowEdges(documentId: string, workflowId: string, body: Record<string, unknown> = {}): Observable<IWorkflowAction[]> {
    return this.http.post<IWorkflowAction[]>(`${this.apiBaseUrl}/${documentId}/workflows/${workflowId}/edges`, body).pipe(
      tap({
        error: error => {
          this.logger.error(`Fehler beim Laden der Workflowsedges des Dokuments: {@error}`, error);
        }
      }),
      share()
    );
  }

  getWorkflowHistory(document: IDocument): Observable<IDocument> {
    const query = gql`{
      document(id: "${document.id}") {
        fields
        workflowDocuments {
          id
          tags
          fields
          creation{
            timestamp
          }
          workflow {
            edges {
              edgeId
              label
              name
              description
              customValues
              assignee {
                type
                ...documentParameter
                ...variableParameter
                ...staticParameter
              }
              userInputTemplate
              userInputTemplateLinkId
              source: sourceVertexName
              target: targetVertexName
              actions {
                actionId
                type
                variables
                ...createBallotAction
              }
            }
            vertices {
              name
              label
              enterActions{
                actionId
                type
                variables
                ...createBallotAction
              }
              leaveActions{
                actionId
                type
                variables
                ...createBallotAction
              }
            }
          }
        }
      }
    }

    fragment createBallotAction on WorkflowCreateBallotActionGraphType {
      caption
      description
      voters {
        type
        ...documentParameter
        ...variableParameter

      }
      ballotIdStore {
        ...documentParameter
      }
    }
    fragment variableParameter on WorkflowVariableParameterGraphType {
      name
    }
    fragment staticParameter on WorkflowStaticParameterGraphType {
      value
    }
    fragment documentParameter on WorkflowDocumentParameterGraphType {
      source
    }`;

    return this.apollo
      .query<IGQLData>({
        query
      })
      .pipe(
        map(({ data }) => data.document),
        share()
      );
  }

  getDokumentConfidential(document: IDocument): Observable<IDocument> {
    const query = gql`{
      document(id: "${document.id}") {
        confidential {
          confidentialId
        }
        confidentialAllowed(forUser: true) {
          allowHardening
          allowWeakening
          confidentialId
          confidential {
            name
            parentIds
          }
        }
      }
    }`;

    return this.apollo
      .query<IGQLData>({
        query
      })
      .pipe(
        map(({ data }) => data.document),
        share()
      );
  }

  getDocumentPermissions(document: IDocument): Observable<IDocument> {
    const query = gql`{
      document(id: "${document.id}") {
        effectivePermissions
        permissions {
          type
          allows
          denies
          ...rolePermission
          ...userPermission
        }
      }
    }

    fragment rolePermission on PermissionRoleGraphType {
      role {
        id
        name
      }
    }

    fragment userPermission on PermissionUserGraphType {
      user {
        id
        name
      }
    }`;

    return this.apollo
      .query<IGQLData>({
        query
      })
      .pipe(
        map(({ data }) => data.document),
        share()
      );
  }

  getDocumentTemplatePermissions(document: IDocument): Observable<IDocument> {
    const query = gql`{
      document(id: "${document.id}") {
        template {
          documentPermissions {
            type
            allows
            denies
            ...rolePermission
            ...userPermission
          }
        }
      }
    }

    fragment rolePermission on PermissionRoleGraphType {
      role {
        id
        name
      }
    }

    fragment userPermission on PermissionUserGraphType {
      user {
        id
        name
      }
    }`;

    return this.apollo
      .query<IGQLData>({
        query
      })
      .pipe(
        map(({ data }) => data.document),
        share()
      );
  }

  /**
   * Get a document with the document GUID
   *
   * @param documentId
   */
  getDocumentById(documentId: string): Observable<IDocument> {
    const query = gql`{
      document(id: "${documentId}") {
        ${this.buildQuery()}
      }
    }
    ${QUERY_FRAGMENTS}`;

    return this.apollo
      .query<IGQLData>({
        query
      })
      .pipe(
        map(({ data }) => data.document),
        share()
      );
  }

  getDocumentsByIds(ids: string[], fieldNames?: string[]): Observable<IDocument[]> {
    const query = gql`query ($ids: [ID!]!) {
      documentsByIds(ids: $ids) {
        ${this.buildQuery(fieldNames)}
      }
    }
    ${QUERY_FRAGMENTS}`;

    return this.apollo
      .query<IGQLData>({
        query,
        variables: { ids }
      })
      .pipe(map(({ data }) => data.documentsByIds));
  }

  getDocumentsByTags(tags?: TTag[], workflowFields?: string[]): Observable<IDocument[]> {
    const query = `query ($tags: [String!]) {
      documentsByTags(tags: $tags) {
        ${this.buildListQuery(null, workflowFields)}
      }
    }
    ${QUERY_LIST_FRAGMENTS}`;

    const body = {
      query,
      variables: { tags }
    };

    return this.http.post<IGQLResult<IGQLData>>(this.graphQLUrl, body).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Einlesen der Dokumente: {@error}', error);
        }
      }),
      map(data => data.data.documentsByTags)
    );
  }

  /**
   * Get a document workflows with the document GUID
   *
   * @param documentId
   * @return Observable of workflow IDs
   */
  getWorkflows(documentId: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiBaseUrl}/${documentId}/workflows`).pipe(
      tap({
        error: error => {
          this.logger.error(`Fehler beim Laden der Workflows des Dokuments: {@error}`, error);
        }
      }),
      share()
    );
  }

  removeReference(documentId: string, targetId: string): Observable<IDocument> {
    const mutation = gql`mutation {
      document(id: "${documentId}") {
        removeReference(targetId: "${targetId}") {
          document {
            references {
              documentId
              tags
            }
          }
        }
      }
    }`;

    return this.apollo
      .mutate({
        mutation
      })
      .pipe(map(result => (result as IGQLResult<IGQLData>).data.document));
  }

  /**
   * Save Document in DB
   *
   * @param document
   */
  save(document: IDocument, parentDocumentId?: string, loadDocument = true): Observable<IDocument> {
    let headers = new HttpHeaders();
    if (document && !document.id && parentDocumentId) {
      headers = new HttpHeaders({
        accept: 'application/json',
        // eslint-disable-next-line
        'X-Ventuno-Siam-Parent-Document-Id': parentDocumentId
      });
    }
    return this.http.post<IDocument>(`${this.apiBaseUrl}`, document, { headers }).pipe(
      switchMap(savedDocument => (loadDocument ? this.getDocumentById(savedDocument.id) : of(savedDocument))),
      tap({
        error: error => {
          this.logger.error('Fehler beim Speichern des Dokuments: {@error}', error);
        }
      })
    );
  }

  documentSearch(search: ISearch): Observable<ISearchResponse> {
    return this.http.post<ISearchResponse>(`${this.apiSearchUrl}`, search).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Suchen des Dokuments: {@error}', error);
        }
      })
    );
  }

  searchDocumentsGrouped(search: ISearchGroups): Observable<ISearchGroupResponse> {
    return this.http.post<ISearchGroupResponse>(`${this.apiSearchUrl}/groups`, search).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Suchen des Dokuments: {@error}', error);
        }
      })
    );
  }

  documentsSearch(properties: IDocumentsSearch): Observable<ISearchResponse> {
    const sortFields: string[] = [];
    if (properties.sortFields) {
      Object.keys(properties.sortFields).forEach(field => {
        const sortDirection = properties.sortFields[field]?.desc ? 'desc' : 'asc';
        sortFields.push(`${field} ${sortDirection}`);
      });
    }

    const body: ISearch = {
      query: this.buildQueryOfProperties(properties),
      count: properties.count || null,
      skip: properties.skip || 0,
      sortFields,
      resultConfiguration: {
        returnFieldLists: properties.isIncludeLists,
        selectedFields: properties.documentFields
      }
    };

    if (properties.fieldConfiguration) {
      body.fieldConfiguration = properties.fieldConfiguration;
    }

    return this.http.post<ISearchResponse>(`${this.apiSearchUrlV11}`, body).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Suchen des Dokuments: {@error}', error);
        }
      })
    );
  }

  documentsSearchGroup(properties: IDocumentsSearch): Observable<IDxResolvedGroupData> {
    const mapKey = (data: string | IDocumentField | IPermissionTarget[]): string => {
      if (data === null || data === undefined) {
        return null;
      } else if (Array.isArray(data)) {
        if (data.length === 1) {
          return data.map(d => `${d.type}:${d.targetId}`)[0];
        } else {
          return null;
        }
      } else if (typeof data === 'object') {
        return data.value as string;
      } else {
        return data;
      }
    };

    const body: ISearchGroups = {
      query: this.buildQueryOfProperties(properties),
      groupFields: properties.groupFields,
      countType: 'documents'
    };

    return this.http.post<ISearchGroupResponse>(`${this.apiSearchUrl}/groups`, body).pipe(
      map(response => {
        const groups: IGroup[] = [];
        response.groups.forEach(g => {
          if (Array.isArray(g.key)) {
            if (!g.key.length && !groups.find(s => s.key === '[Leer]')) {
              groups.push({ count: g.count, key: '[Leer]', subGroups: g.subGroups });
            }
            for (const key of g.key) {
              if (isHasProperty(key, 'type') && isHasProperty(key, 'targetId')) {
                const compositeId = `${(key as IPermissionTarget).type}:${(key as IPermissionTarget).targetId}`;
                if (!groups.find(gr => gr.key === compositeId)) {
                  groups.push({ count: g.count, key: compositeId, subGroups: g.subGroups });
                }
              } else {
                const findIndex = groups.findIndex(gr => gr.key === key);
                if (findIndex > -1) {
                  groups[findIndex].count += g.count;
                } else {
                  groups.push({ count: g.count, key: key as string, subGroups: g.subGroups });
                }
              }
              if (Array.isArray(key)) {
                for (const target of key) {
                  if (isHasProperty(target, 'type') && isHasProperty(target, 'targetId')) {
                    const compositeId = `${(target as IPermissionTarget).type}:${
                      (target as IPermissionTarget).targetId
                    }`;
                    const findIndex = groups.findIndex(gr => gr.key === compositeId);
                    if (findIndex > -1) {
                      groups[findIndex].count += g.count;
                    } else {
                      groups.push({ count: g.count, key: compositeId, subGroups: g.subGroups });
                    }
                  }
                }
              }
            }
          } else {
            groups.push({ count: g.count, key: mapKey(g.key), subGroups: g.subGroups });
          }
        });
        const data: IDxGroupItem[] = groups.map(grp => {
          let items: IDxGroupItem[] = null;
          if (grp.subGroups) {
            const subGroups: IGroup[] = [];
            grp.subGroups.forEach(g => {
              if (Array.isArray(g.key)) {
                if (!g.key.length && !subGroups.find(s => s.key === '[Leer]')) {
                  subGroups.push({ count: g.count, key: '[Leer]', subGroups: g.subGroups });
                }
                for (const key of g.key) {
                  if (isHasProperty(key, 'type') && isHasProperty(key, 'targetId')) {
                    const compositeId = `${(key as IPermissionTarget).type}:${(key as IPermissionTarget).targetId}`;
                    const findIndex = subGroups.findIndex(gr => gr.key === compositeId);
                    if (findIndex > -1) {
                      subGroups[findIndex].count += g.count;
                    } else {
                      subGroups.push({ count: g.count, key: compositeId });
                    }
                  } else {
                    const findIndex = subGroups.findIndex(gr => gr.key === key);
                    if (findIndex > -1) {
                      subGroups[findIndex].count += g.count;
                    } else {
                      subGroups.push({ count: g.count, key: key as string });
                    }
                  }
                  if (Array.isArray(key)) {
                    for (const target of key) {
                      if (isHasProperty(target, 'type') && isHasProperty(target, 'targetId')) {
                        const compositeId = `${(target as IPermissionTarget).type}:${
                          (target as IPermissionTarget).targetId
                        }`;
                        const findIndex = subGroups.findIndex(gr => gr.key === compositeId);

                        if (findIndex > -1) {
                          subGroups[findIndex].count += g.count;
                        } else {
                          subGroups.push({ count: g.count, key: compositeId });
                        }
                      }
                    }
                  }
                }
              } else {
                subGroups.push({ count: g.count, key: mapKey(g.key), subGroups: g.subGroups });
              }
            });
            items = subGroups.map(sub => ({
              count: sub.count,
              key: sub.key as string,
              items: null,
              summary: [sub.count]
            }));
          }
          return {
            key: grp.key as string,
            count: grp.count,
            items,
            summary: [grp.count],
            collapsedItems: null
          };
        });
        return {
          data,
          summary: [response.totalCount],
          totalCount: response.totalCount,
          groupCount: response.groups.length
        };
      }),
      map(groups => {
        groups.data.sort((a, b) => {
          if (typeof a?.key !== 'string' || typeof b?.key !== 'string') {
            return 0;
          }
          const nameA = a.key?.toLowerCase();
          const nameB = b.key?.toLowerCase();
          return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
        });
        return groups;
      }),
      tap({
        error: error => {
          this.logger.error('Fehler beim Suchen des Dokuments: {@error}', error);
        }
      })
    );
  }

  setReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    if (!items?.length) {
      return of(null);
    }
    const query = items
      .map((item, index) => {
        return `d${index}: document(id: "${item.documentId}") {
          setParent(parentId: "${item.parentId}") {
            ownerId
            document {
              references {
              referencedDocumentId
              tags
              }
            }
          }
        }`;
      })
      .join(' ');
    const mutation = gql`mutation {
        ${query}

    }`;

    return this.apollo
      .mutate({
        mutation
      })
      .pipe(map(result => (result as IGQLResult<IGQLSetReference>).data.document));
  }

  removeReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    if (!items?.length) {
      return of(null);
    }
    const query = items
      .map((item, index) => {
        return `d${index}: document(id: "${item.documentId}") {
          removeReference(targetId: "${item.parentId}") {
            ownerId
            document {
              references {
              referencedDocumentId
              tags
              }
            }
          }
        }`;
      })
      .join(' ');
    const mutation = gql`mutation {
        ${query}

    }`;

    return this.apollo
      .mutate({
        mutation
      })
      .pipe(map(result => (result as IGQLResult<IGQLSetReference>).data.document));
  }

  /**
   * set the document with parentId as the parent document of documentId
   *
   * @param documentId
   * @param parentId
   */
  setReference(documentId: string, parentId: string): Observable<IDocument> {
    const mutation = gql`mutation {
      document(id: "${documentId}") {
        setParent(parentId: "${parentId}") {
          ownerId
          document {
            references {
              referencedDocumentId
              tags
            }
          }
        }
      }
    }`;

    return this.apollo
      .mutate({
        mutation
      })
      .pipe(map(result => (result as IGQLResult<IGQLSetReference>).data.document.setParent.document));
  }

  /**
   * "print" the document
   *
   * @param document
   */
  print(document: IDocument): Observable<HttpResponse<Blob>> {
    const body = new HttpParams();
    const headers = new HttpHeaders({
      accept: 'application/pdf'
    });
    return this.http
      .post<Blob>(`${this.apiBaseUrl}/${document.id}/print`, body, {
        observe: 'response',
        headers,
        responseType: 'blob' as 'json'
      })
      .pipe(
        tap({
          error: error => {
            this.logger.error('Allgemeiner Fehler beim Drucken des Dokumentes: {@error}', error);
          }
        })
      );
  }

  /**
   * "upload" the document
   *
   * @param documentId
   * @param login
   * @param withReferencedDocuments
   */
  upload(documentId: string, login: IBrainloopLogin, withReferencedDocuments: boolean): Observable<void> {
    let body = new HttpParams().set('username', login.username).set('password', login.password);
    body.set('withReferencedDocuments', withReferencedDocuments);
    if (login.pin) {
      body = body.set('pin', login.pin);
    }
    if (login.totp) {
      body = body.set('totp', login.totp);
    }
    const headers = new HttpHeaders({
      accept: 'application/text'
    });
    return this.http.post<void>(`${this.apiBaseUrl}/${documentId}/upload`, body, { headers }).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          switch (error.status) {
            case 901:
              this.logger.info('2-Faktorauthentifizierung vom Server angefordert.');
              break;
            default:
              this.logger.error('Allgemeiner Fehler beim Upload des Dokumentes: {@error}', error);
              break;
          }
        }
      })
    );
  }

  getSelectableNamesFromField(documentId: string, fieldName: string): Observable<IPermissionTarget[]> {
    return this.http
      .get<IPermissionTarget[]>(`${this.apiBaseUrl}/${documentId}/template/selectable-names/${fieldName}`)
      .pipe(
        tap({
          error: error => {
            this.logger.error('Fehler beim Holen von SelectableNames des Feldes: {@error}', error);
          }
        })
      );
  }

  /**
   * Prepare the string for the lucene query.
   * If date is not provided or equals to NULL, then it will return *.
   * It might be useful if no need to set the upper limit for example
   *
   * @param source
   * @private
   */
  private getDate(source: Date): string {
    if (!source) {
      return '*';
    }
    return source.toISOString().split('T')[0];
  }

  private buildQuery(fields?: string[]): string {
    let _fields = new Set(['status']);
    if (Array.isArray(fields) && fields.length) {
      _fields = new Set([..._fields, ...fields]);
    }
    const _names: string[] = [];
    for (const field of _fields) {
      _names[_names.length] = `"${field}"`;
    }

    return `
    id
    fields(permissions:true)
    effectivePermissions
    tags
    confidential {
      confidentialId
      allowHardening
      allowWeakening
    }
    confidentialAllowed(forUser: true) {
      allowHardening
      allowWeakening
      confidentialId
      confidential {
        name
        parentIds
      }
    }
    creation {
      impersonationId
      logonUserId
      timestamp
      user {
        id
      }
    }
    modification {
      impersonationId
      timestamp
      user {
        id
      }
    }
    workflowDocuments {
      id
      tags
      fields
      creation {
        timestamp
      }
      workflow {
        edges {
          edgeId
          label
          name
          description
          customValues
          assignee {
            type
            ...documentParameter
            ...variableParameter
            ...staticParameter
          }
          userInputTemplate
          userInputTemplateLinkId
          source: sourceVertexName
          target: targetVertexName
          actions {
            type
            ...createBallotAction
          }
        }
        vertices {
          name
          label
          clientConfiguration
          enterActions{
            type
            ...createBallotAction
          }
          leaveActions{
            type
            ...createBallotAction
          }
        }
      }
    }
    template {
      id
      name
      caption
      tags
      confidentialReferences {
        confidentialId
        allowHardening
        allowWeakening
        children {
          confidentialId
          allowHardening
          allowWeakening
        }
      }
      fields {
        id
        name
        label
        validations {
          type
        }
        type
        customValues
        ...numberField
        ...checkboxField
        ...dateTimeField
        ...checkboxGroupField
        ...radioGroupField
        ...dropDownListField
        ...attachmentField
        ...nameField
        ...sliderField
        ...fieldPermission
      }
      layouts {
        name
        entries {
          id
          index
          type
          parentContainerName
          width
          height
          visible
          additionalData
          visibleFormula {
            id
            code
            language
          }
          ...fieldEntry
          ...groupEntry
          ...placeholderEntry
        }
      }
    }
    references {
      referencedDocumentId
      document {
        id
        fields(names: ["subject"])
        creation {
          timestamp
          user {
            id
          }
        }
        template {
          caption
        }
        tags
      }
      tags
    }`;
  }

  private buildListQuery(documentFields?: string[], workflowFields?: string[]): string {
    const workflowFieldNames = this.gqlFields(['status'], workflowFields).join(', ');
    let documentFieldNames = '';
    if (documentFields) {
      documentFieldNames = `names: [${this.gqlFields(['department'], documentFields).join(', ')}],`;
    }

    return `
    id
    fields(${documentFieldNames} permissions:true)
    effectivePermissions
    tags
    creation {
      timestamp
      user {
        id
      }
    }
    modification {
      timestamp
      user {
        id
      }
    }
    workflowDocuments {
      id
      fields(names: [${workflowFieldNames}])
      tags
      workflow {
        vertices {
          label
          name
        }
      }
    }
    template {
      id
      name
      caption
      tags
      fields {
        id
        name
        label
        validations {
          type
        }
        type
        customValues
        ...numberField
        ...checkboxField
        ...dateTimeField
        ...checkboxGroupField
        ...radioGroupField
        ...dropDownListField
        ...attachmentField
        ...nameField
        ...sliderField
        ...fieldPermission
      }
    }
    references {
      referencedDocumentId
      document {
        id
        fields(names: ["subject"])
        creation {
          timestamp
          user {
            id
          }
        }
        template {
          caption
        }
        tags
      }
      tags
    }`;
  }

  private gqlFields(mandatory: string[], fields?: string[]) {
    if (!Array.isArray(fields)) {
      fields = [];
    }
    const _fields = new Set([...mandatory, ...fields]);
    return Array.from(_fields).map(field => `"${field}"`);
  }

  private buildQueryOfProperties(properties: IDocumentsSearch): string {
    const queryObject = createQueryObject();

    if (properties.dateFrom && properties.dateField && properties.dateTo) {
      const dateField = properties.dateField || 'creation.timestamp';
      queryObject.and.include.push(
        `${dateField}:[${this.getDate(properties.dateFrom)} TO ${this.getDate(properties.dateTo)}]`
      );
    }

    if (properties.user && properties.userField) {
      if (properties.userField === 'creation.user' || properties.userField === 'creation.userId') {
        queryObject.and.include.push(`creation.userId:"${properties.user.id}"`);
      } else if (properties.userField === 'modification.user' || properties.userField === 'modification.userId') {
        queryObject.and.include.push(`modification.userId:"${properties.user.id}"`);
      } else if (properties.user?.rolesIds?.length && properties.userField !== 'creation.user') {
        queryObject.or.include.push(
          ...properties.user.rolesIds.map(roleId => `${properties.userField}:"${roleId}"`),
          `${properties.userField}:"${properties.user.compositeId}"`
        );
      } else {
        queryObject.and.include.push(`${properties.userField}:"${properties.user.compositeId}"`);
      }
    }

    if (Array.isArray(properties.excludeTags)) {
      queryObject.and.exclude.push(...properties.excludeTags.map(tag => `tags:"${tag}"`));
    }
    if (Array.isArray(properties.includeTags)) {
      queryObject.and.include.push(...properties.includeTags.map(tag => `tags:"${tag}"`));
    }
    if (Array.isArray(properties.includeOneOfTags) && properties.includeOneOfTags.length) {
      queryObject.or.include.push(...properties.includeOneOfTags.map(tag => `tags:"${tag}"`));
    }
    if (Array.isArray(properties.includeOneOfTemplateId) && properties.includeOneOfTemplateId.length) {
      queryObject.or.include.push(...properties.includeOneOfTemplateId.map(id => `template.id:"${id}"`));
    }
    if (Array.isArray(properties.excludeDocumentIds)) {
      queryObject.and.exclude.push(...properties.excludeDocumentIds.map(id => `id:"${id}"`));
    }
    if (Array.isArray(properties.includeDocumentIds)) {
      queryObject.or.include.push(...properties.includeDocumentIds.map(id => `id:"${id}"`));
    }
    if (Array.isArray(properties.includeChildIds)) {
      queryObject.and.include.push(...properties.includeChildIds.map(tag => `references.child:"${tag}"`));
    }
    if (Array.isArray(properties.includeParentIds)) {
      queryObject.and.include.push(...properties.includeParentIds.map(tag => `references.parent:"${tag}"`));
    }
    if (Array.isArray(properties.excludeChildIds)) {
      queryObject.and.exclude.push(...properties.excludeChildIds.map(tag => `references.child:"${tag}"`));
    }
    if (Array.isArray(properties.excludeParentIds)) {
      queryObject.and.exclude.push(...properties.excludeParentIds.map(tag => `references.parent:"${tag}"`));
    }
    if (properties.hasNoParent) {
      queryObject.and.exclude.push(`references.parent:[* TO *]`);
    }
    if (properties.hasParent) {
      queryObject.and.include.push(`references.parent:[* TO *]`);
    }
    if (properties.hasNoChild) {
      queryObject.and.exclude.push(`references.child:[* TO *]`);
    }
    if (properties.hasChild) {
      queryObject.and.include.push(`references.child:[* TO *]`);
    }
    if (Array.isArray(properties.excludeStatus)) {
      queryObject.and.exclude.push(
        ...properties.excludeStatus.map(status => `documentWorkflowDocument.currentStatusLabel:"${status}"`)
      );
      queryObject.and.exclude.push(
        ...properties.excludeStatus.map(status => `documentWorkflowDocument.currentStatus:"${status}"`)
      );
    }

    return combineQueries(properties.query, queryObjectToLucene(queryObject), 'and');
  }
}
