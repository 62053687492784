import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from '@services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class DangerZoneService {
  private readonly apiBaseUrl: string;

  constructor(private http: HttpClient, private logger: LoggerService, @Inject('BASE_URL') private baseUrl: string) {
    this.apiBaseUrl = `${this.baseUrl}api/danger-zone`;
  }

  /**
   * Call the API which delete database
   */
  destroyDatabase(confirmed: boolean, documents: boolean, logs: boolean): Observable<unknown> {
    let endpoint = `${this.apiBaseUrl}/database?`;
    const params: string[] = [];

    if (confirmed) {
      params.push('confirmed=true');
    }
    if (documents) {
      params.push('documents=true');
    }
    if (logs) {
      params.push('logs=true');
    }

    endpoint += params.join('&');

    return this.http.delete<unknown>(endpoint, {}).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Database Destroy: {@error}', error);
        }
      })
    );
  }
}
