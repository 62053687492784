import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { WINDOW } from '../../../tokens/window.token';
import * as UserFactory from '@factories/user.factory';
import {
  IDocumentsSearch,
  IDynamicCard,
  IDynamicList,
  INavigationGroup,
  INavigationLink,
  IRole,
  ITemplateTypes,
  IUser,
  TApplicationMode
} from '@interfaces/siam';
import { SharedataService } from '@services/sharedata.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { LoginService } from '@services/login.service';
import { concatMap, map, switchMap, takeUntil, toArray } from 'rxjs/operators';
import { TemplateService } from '@services/template.service';
import { TagsService } from '@services/tags.service';
import { ItemClickEvent } from 'devextreme/ui/action_sheet';
import { IDxGroupItem, IDxPopupOnContentReady } from '@interfaces/devextreme';
import { UserService } from '@services/user.service';
import { RoleService } from '@services/roles.service';
import { SiamList } from '@interfaces/siamList';
import { ListsService } from '@services/lists.service';
import { siamConst } from '@interfaces/siamConst';
import { clone, getDateRange, queryAdapter } from '@factories/helpers';
import { DocumentService } from '@services/document.service';
import { LoadPanelService } from '@services/load-panel.service';
import { PopupHelperService, ToolbarItem } from '@services/popup-helper.service';

@Component({
  selector: 'app-navigation-pane',
  templateUrl: './navigation-pane.component.html',
  styleUrls: ['./navigation-pane.component.scss']
})
export class NavigationPaneComponent implements OnInit, OnDestroy {
  @Input() naviDataSource: INavigationGroup[];
  @Input() applicationMode: TApplicationMode = 'application';

  isNavHidden: boolean;
  isDashboardHidden = true;
  showInfoPopup = false;
  infoPopupToolbarItems: ToolbarItem[];
  isNavPinned: boolean;
  chevronIcon: string;
  chevronTooltip: string;
  pinTooltip: string;
  naviList: INavigationGroup[] = [];
  actionSheetVisible = false;
  creatableDocTypes: ITemplateTypes[] = [];
  currentUser: IUser = null;
  canCreateTemplate: boolean;

  users: IUser[];
  roles: IRole[];
  dataSource: IDxGroupItem[] = [];
  cards: IDynamicCard[] = [];
  selectedCard: IDynamicCard = null;
  dynamicLists: IDynamicList[] = [];
  dynamicCards: IDynamicCard[] = [];
  bigCards: IDynamicCard[] = [];
  lists: SiamList[];

  #destroyable$ = new Subject<void>();
  #dashboardSubscription: Subscription;
  eventsScheduler = new Subject<void>();

  constructor(
    @Inject(WINDOW) private _window: Window,
    private router: Router,
    private route: ActivatedRoute,
    private sharedDataService: SharedataService,
    private loginService: LoginService,
    private templateService: TemplateService,
    private tagsService: TagsService,
    private userService: UserService,
    private roleService: RoleService,
    private listsService: ListsService,
    private documentService: DocumentService,
    private loadPanelService: LoadPanelService,
    private popupHelperService: PopupHelperService
  ) {
    this.isNavPinned = JSON.parse(localStorage.getItem('navigation-pinned')) as boolean;
    this.pinTooltip = this.isNavPinned ? 'Navigation lösen' : 'Navigation anheften';
    this.sharedDataService.getFullNavigationPane.pipe(takeUntil(this.#destroyable$)).subscribe(navStatus => {
      this.isNavHidden = navStatus;
      this.chevronIcon = this.isNavHidden ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left';
      this.chevronTooltip = this.isNavHidden ? 'Seitenleiste anzeigen' : 'Seitenleiste ausblenden';
    });

    this.loginService.getCurrentUser
      .pipe(
        switchMap(user => {
          this.currentUser = user;
          return this.templateService.getExecutableTemplates('execute');
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe(templates => {
        if (templates && templates.length > 0) {
          this.canCreateTemplate = true;
          const types: ITemplateTypes[] = [];
          this.tagsService.siamTags()?.forEach(tagCategory => {
            const tmpTag = templates.find(temp => temp.tags.some(t => t === tagCategory.tag));
            if (tmpTag && tagCategory.visibleCreation) {
              if (!types.find(c => c.type === tagCategory.name)) {
                types.push({
                  text: tagCategory.label,
                  type: tagCategory.name,
                  icon: tagCategory.icon
                });
              }
            }
          });
          this.creatableDocTypes = types;
        } else {
          this.canCreateTemplate = false;
        }
      });
  }

  ngOnInit(): void {
    const homepage = '/application/home';
    this.loginService.getCurrentUser
      .pipe(
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: user => {
          const navigationDataSource = clone(this.naviDataSource);
          if (UserFactory.isAdmin(user)) {
            this.naviList = navigationDataSource
              .map(group => {
                group.links = group.links.filter(link => {
                  if (Array.isArray(link.capabilities)) {
                    for (const capability of link.capabilities) {
                      if (UserFactory.isUserCapable(capability, user)) {
                        return true;
                      }
                    }
                    return false;
                  }
                  return true;
                });
                return group;
              })
              .filter(group => group.links?.length);
          } else {
            this.naviList = navigationDataSource
              .map(group => {
                group.links = group.links
                  .filter(link => !link.forAdminOnly)
                  .filter(link => {
                    if (Array.isArray(link.capabilities)) {
                      for (const capability of link.capabilities) {
                        if (UserFactory.isUserCapable(capability, user)) {
                          return true;
                        }
                      }
                      return false;
                    }
                    return true;
                  });
                return group;
              })
              .filter(group => group.links?.length);
          }
        }
      });

    if (this.router.url === homepage) {
      this.isDashboardHidden = false;
      this.#dashboardSubscription = this.loadDashboardData();
    }

    this.router.events.pipe(takeUntil(this.#destroyable$)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url === homepage) {
          this.isDashboardHidden = false;
          this.#dashboardSubscription = this.loadDashboardData();
        } else {
          this.isDashboardHidden = true;
          this.showInfoPopup = false;
          this.#dashboardSubscription?.unsubscribe();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  /** get Dashboard data
   *
   */
  getDashboardData(): Observable<IDynamicCard[]> {
    return this.loginService.getCurrentUser
      .pipe(
        switchMap(user => {
          if (user) {
            if (this.sharedDataService.startOnce$.getValue()) {
              this.loadPanelService.show('Ihr Cockpit wird vorbereitet...', 90, 260);
            }
          }
          return this.userService.getAllUsers();
        }),
        switchMap(users => {
          this.users = users;
          return this.roleService.getAllRoles();
        }),
        switchMap(roles => {
          this.roles = roles;
          return this.listsService.getListsWithEntries();
        }),
        switchMap(lists => {
          this.lists = lists;

          this.dynamicLists = lists.find(
            l => l.name.toLocaleLowerCase() === siamConst.dynamicListsListName.toLocaleLowerCase()
          )?.entries?.map(entry => this.listsService.getDynamicListEntryData(entry));

          this.dynamicCards = lists.find(
            l => l.name.toLocaleLowerCase() === siamConst.dynamicCardsListName.toLocaleLowerCase()
          )?.entries?.map(entry => this.listsService.getDynamicCardEntryData(entry));

          const items = this.dynamicCards
            .map(card => {
              const result = clone(card);
              if (result.type === 'list' && result.settings.listName) {
                const list = this.dynamicLists.find(entry => entry.name === card.settings.listName);
                result.selector = list?.selector;
                result.settings.gridSettings = list?.settings;
              }
              return result;
            })
            .filter(card => card.isVisible && this.isUserHasAccess(card));

          return of(items);
        }),
        switchMap(cards => {
          const observables: Observable<IDynamicCard>[] = [];
          const adapter = queryAdapter(this.getGroupField);

          for (const data of cards) {
            switch (data.type) {
              case 'list': {
                const properties: IDocumentsSearch = { isIncludeLists: false, documentFields: [] };
                if (data.settings.gridSettings.toolbar.isShowDateFilter) {
                  const selectedDateRange = data.settings.gridSettings.toolbar.dateFilterDefault || 'all';
                  if (selectedDateRange !== 'all') {
                    const dateRange = getDateRange(selectedDateRange);
                    properties.dateFrom = dateRange.startDate;
                    properties.dateTo = dateRange.endDate;
                    properties.dateField = data.settings.gridSettings.toolbar.dateField || null;
                  }
                }
                if (data.settings.gridSettings.toolbar.isShowPersonalMode) {
                  properties.user = this.currentUser;
                  properties.userField = data.settings.gridSettings.toolbar.userField || 'creation.user.id';
                }

                properties.query = adapter(data.selector);

                const obs = this.documentService
                  .getCounterDocuments(properties)
                  .pipe(map(count => Object.assign(data, { documentCount: count }) as IDynamicCard));
                observables.push(obs);
                break;
              }

              case 'chart': {
                if (data.chartSettings.groups?.length && data.chartSettings.groups[0].type) {
                  const properties: IDocumentsSearch = {
                    user: this.currentUser,
                    isIncludeLists: true,
                    groupFields: data.chartSettings.groups.map(item => {
                      let path: string;
                      switch (item.type) {
                        case 'fieldValue':
                          path = item.fieldName;
                          break;
                        case 'creatorName':
                          path = 'creation.userId';
                          break;
                        case 'currentAssignee':
                          path = 'documentWorkflowDocument.currentAssignee';
                          break;
                        case 'workflowStatus':
                          path = 'documentWorkflowDocument.currentStatusLabel';
                          break;
                        case 'template':
                        case 'template.caption':
                          path = 'template.caption';
                          break;

                        default:
                          path = '';
                          break;
                      }
                      return {
                        path,
                        descending: false,
                        sortType: 'text',
                      };
                    }),
                    documentFields: []
                  };
                  properties.query = adapter(data.selector);
                  if (data.chartSettings?.isDateFilter) {
                    const selectedDateRange = data.chartSettings.period;
                    if (selectedDateRange !== 'all') {
                      const dateRange = getDateRange(selectedDateRange);
                      properties.dateFrom = dateRange.startDate;
                      properties.dateTo = dateRange.endDate;
                      properties.dateField = data.chartSettings.dateField || null;
                    }
                  }

                  const obs = this.documentService.documentsSearchGroup(properties).pipe(
                    map(response => {
                      return Object.assign(data, { dataSource: response.data }) as IDynamicCard;
                    })
                  );
                  observables.push(obs);
                } else {
                  observables.push(of(data));
                }
                break;
              }
              default:
                observables.push(of(data));
            }
          }
          return this.processObservablesRecursively(observables);
        })
      );
  }

  /** load Dashboard
   *
   */
  loadDashboardData(): Subscription {
    return this.getDashboardData()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: data => {
          if (data) {
            data.sort((a, b) => {
              const aOriginalIndex = this.dynamicCards.findIndex(obj => obj.name === a.name);
              const bOriginalIndex = this.dynamicCards.findIndex(obj => obj.name === b.name);
              return aOriginalIndex - bOriginalIndex;
            });
            this.cards = data.filter(d => d.type === 'list');
            this.bigCards = data.filter(d => d.type !== 'list');
          }
          this.loadPanelService.hide();
        },
        error: () => {
          this.loadPanelService.hide();
        }
      });
  }

  /** close-open navigation group
   *
   * @param group
   */
  closeGroup(group: INavigationGroup): void {
    group.isClosed = !group.isClosed;
  }

  /** navigation to link
   *
   * @param link
   */
  navigateTo(link: INavigationLink): void {
    if (link.routerLink?.length) {
      void this.router.navigate(link.routerLink, { relativeTo: this.route });
    } else {
      this._window.open(link.href, '_blank');
    }
  }

  /**
   * styles for popup
   *
   */
  onContentReady(e: IDxPopupOnContentReady): void {
    const contentElement = e.component.content();
    contentElement.style.padding = '10px 0';
  }

  /**
   * action-sheet action
   *
   */
  onCreateProcess = (e: ItemClickEvent<ITemplateTypes>): void => {
    const navigationExtras: NavigationExtras = {
      state: {
        title: e.itemData.text,
        icon: e.itemData.icon
      }
    };
    void this.router.navigate(['', 'application', 'templates', e.itemData.type], navigationExtras);
    this.actionSheetVisible = false;
  };

  /**
   * popup action
   *
   */
  onCreateProcessPopup = (item: ITemplateTypes): void => {
    const navigationExtras: NavigationExtras = {
      state: {
        title: item.text,
        icon: item.icon
      }
    };
    void this.router.navigate(['', 'application', 'templates', item.type], navigationExtras);
    this.actionSheetVisible = false;
  };

  /**
   * pin navigation bar
   *
   */
  pinNavigation(): void {
    this.isNavPinned = !this.isNavPinned;
    localStorage.setItem('navigation-pinned', JSON.stringify(this.isNavPinned));
    this.pinTooltip = this.isNavPinned ? 'Navigation lösen' : 'Navigation anheften';
  }

  /**
   * hide-show navigation bar
   *
   */
  toggleNavigation(): void {
    this.eventsScheduler.next();
    this.isNavHidden = !this.isNavHidden;
    this.sharedDataService.hideNavigationPane(this.isNavHidden);
    this.chevronIcon = this.isNavHidden ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left';
    this.chevronTooltip = this.isNavHidden ? 'Seitenleiste anzeigen' : 'Seitenleiste ausblenden';
  }

  /**
   * hide-show dashboard
   *
   */
  toggleDashboard(): void {
    this.isDashboardHidden = !this.isDashboardHidden;
    this.isDashboardHidden ? this.#dashboardSubscription?.unsubscribe() : this.loadDashboardData();
  }

  // ==========================================================================================================
  // Popup-Aktionen
  // ==========================================================================================================

  openInfoPopup(card: IDynamicCard): void {
    if (!card) {
      return;
    }
    this.infoPopupToolbarItems = this.popupHelperService.setDefaultToolbar();
    this.selectedCard = card;

    this.infoPopupToolbarItems = [
      {
        name: 'TitleIcon',
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        options: {
          type: 'normal',
          icon: `material-icons ${this.selectedCard.settings?.icon}`,
          elementAttr: {
            class: 'button-icon'
          }
        }
      },
      {
        name: 'Title',
        text: this.selectedCard.label,
        location: 'before',
        toolbar: 'top',
        visible: true
      }
    ];
    this.showInfoPopup = true;
    this.infoPopupToolbarItems.push({
      name: 'Close',
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: this.closeInfoPopup
    });

    this.showInfoPopup = true;
  }

  closeInfoPopup = (): void => {
    this.showInfoPopup = false;
    this.selectedCard = null;
  };

  onHidden(): void {
    this.selectedCard = null;
  }

  private isUserHasAccess(category: IDynamicCard): boolean {
    if (!category.access) {
      return true;
    }
    if (!category.access.users?.length && !category.access.roles?.length) {
      return true;
    }
    if (category.access.users?.includes(this.currentUser.id)) {
      return true;
    }

    for (const role of this.currentUser.roles) {
      if (category.access.roles?.includes(role.id)) {
        return true;
      }
    }

    return false;
  }

  private getGroupField = (sortSelector: string): string => {
    switch (sortSelector) {
      case 'creatorNameWithAvatar':
        return 'creation.userId';

      case 'creation.user.surname':
      case 'creation.user.forename':
        return sortSelector;

      case 'modificationNameWithAvatar':
        return 'last_write.userId';

      case 'currentAssignee':
        return 'documentWorkflowDocument.currentAssignee';

      case 'creatorDepartment':
        return 'creation.user.profile.department';

      case 'creationDate':
        return 'creation.timestamp';

      case 'modificationDate':
        return 'modification.timestamp';

      case 'status':
        return 'documentWorkflowDocument.currentStatusLabel';

      case 'labels':
      case 'configuration-tag':
      case 'configuration-label':
        return 'tags';

      case 'configuration-confidential':
        return 'confidentialId';

      case 'configuration-template':
        return 'template.id';

      default: {
        if (sortSelector?.startsWith('document.')) {
          const source = sortSelector.split('.');
          source.shift();
          if (sortSelector.endsWith('.value')) {
            source.pop();
          }
          return source.join('.');
        }
      }
    }
    return null;
  };

  private processObservablesRecursively(observables: Observable<IDynamicCard>[]): Observable<IDynamicCard[]> {
    if (observables.length === 0) {
      // If there are no more observables, return an observable of an empty array
      return of([] as IDynamicCard[]);
    } else {
      // Take the first observable from the array
      const firstObservable = observables[0];
      // Recurse with the rest of the observables in the array
      const remainingObservables = observables.slice(1);
      // Use concatMap to process the first observable and concatenate it with the results of the recursive call
      return firstObservable.pipe(
        concatMap(response => {
          // Process the response if necessary
          // For example, you can map the response to extract specific data
          // response = response.someProperty;
          // Recurse with the rest of the observables and concatenate the current response to the array
          return this.processObservablesRecursively(remainingObservables).pipe(
            // Use toArray to collect the responses into an array and complete the observable
            toArray(),
            // Concatenate the current response to the array of responses
            concatMap(responses => of(([...responses, response] as IDynamicCard[]).flat()))
          );
        })
      );
    }
  }
}
