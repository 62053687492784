import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import { NotifyService } from '@services/notify.service';
import { IError, IErrorDetail } from '@interfaces/siam';

const getBaseUrl = () => document.getElementsByTagName('base')[0].href;

const uri = getBaseUrl() + 'api/graphql';
const errorLink = onError(({ graphQLErrors, networkError }) => {
  let error: IError = {
    error: [],
    message: '',
    name: '',
    ok: false,
    status: 500,
    statusText: '',
    url: ''
  };
  if (networkError) {
    (error.error as IErrorDetail[]).push({
      code: 'Network error',
      description: networkError.message,
      errors: undefined,
      status: 500,
      title: '',
      traceId: '',
      type: ''
    });
  }
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      if (extensions?.code === 'UNAUTHORIZED_ACCESS' || extensions?.code === 'authorization') {
        error = null;
      } else {
        (error.error as IErrorDetail[]).push({
          code: 'GraphQL error',
          description: message,
          errors: undefined,
          status: 500,
          title: '',
          traceId: '',
          type: ''
        });
      }
    });
  }

  if (error) {
    NotifyService.serverGlobalError(error);
  }
});

const uploadLink = createUploadLink({
  uri,
  credentials: 'include'
});

const httpLinkWithErrorHandling = ApolloLink.from([
  errorLink as unknown as ApolloLink, // workaround for version 2, after upgrade to newest version, can be removed
  uploadLink as unknown as ApolloLink
]);

const createApollo = (): ApolloClientOptions<unknown> => ({
  link: httpLinkWithErrorHandling,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    }
  }
});

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: []
    }
  ]
})
export class GraphQLModule {}
