import { Action } from '@ngrx/store';
import { IRole } from '@interfaces/siam';

export enum RoleActionTypes {
  CreateRole = '[Role] Create Role',
  DeleteRole = '[Role] Delete Role',
  UpdateRole = '[Role] Update Role',
  SetRoles = '[Role] set Roles in cache'
}

export class CreateRole implements Action {
  public readonly type = RoleActionTypes.CreateRole;

  constructor(public payload: { role: IRole }) {
  }
}

export class UpdateRole implements Action {
  public readonly type = RoleActionTypes.UpdateRole;

  constructor(public payload: { role: IRole }) {
  }
}

export class DeleteRole implements Action {
  public readonly type = RoleActionTypes.DeleteRole;

  constructor(public payload: { role: IRole }) {
  }
}

export class SetRoles implements Action {
  public readonly type = RoleActionTypes.SetRoles;

  constructor(public payload: { roles: IRole[] }) {
  }
}

export type RoleActions =
  CreateRole |
  UpdateRole |
  DeleteRole |
  SetRoles;
