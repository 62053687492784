import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Observable, Subject } from 'rxjs';
import { IError, IErrorDetail, ISiamNotification, TErrorType } from '@interfaces/siam';
import { HttpRequest } from '@angular/common/http';
import { replacer } from '@factories/helpers';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  static global = {
    error: (message: string, displayTime?: number): void => {
      NotifyService.notify('dx-notify', message, 'error', displayTime);
    },
    success: (message: string, displayTime?: number): void => {
      NotifyService.notify('dx-notify', message, 'success', displayTime);
    },
    warn: (message: string, displayTime?: number): void => {
      NotifyService.notify('dx-notify', message, 'warning', displayTime);
    },
    info: (message: string, displayTime?: number): void => {
      NotifyService.notify('dx-notify', message, 'info', displayTime);
    }
  };

  static component = {
    error: (error: IError | string): void => {
      NotifyService.notify('siam-notify', error, 'error');
    },
    warn: (message: string): void => {
      NotifyService.notify('siam-notify', message, 'warning');
    }
  };

  private static notifications$ = new Subject<ISiamNotification[]>();
  private static globalNotifications$ = new Subject<ISiamNotification[]>();

  static destroyNotifications(): void {
    this.notifications$.next([]);
    this.globalNotifications$.next([]);
  }

  static destroyGlobalNotifications(): void {
    this.globalNotifications$.next([]);
  }

  static createSiamNotification(message: string, errorType: TErrorType = 'error', details = ''): ISiamNotification {
    return {
      message: this.limitString(message, 1000),
      class: errorType,
      details
    };
  }

  static getNotifications(): Observable<ISiamNotification[]> {
    return this.notifications$.asObservable();
  }

  static getGlobalNotifications(): Observable<ISiamNotification[]> {
    return this.globalNotifications$.asObservable();
  }

  /**
   * Notify the server errors in template
   *
   * @param error
   * @param request
   * @return returns the error notifications back
   */
  static serverGlobalError(error: IError, request?: HttpRequest<unknown>): void {
    this.globalNotifications$.next(NotifyService.siamNotificationFromError(error, 'error', request));
  }

  /**
   * TODO after refactoring make it private
   *
   * @param notificationType
   * @param error
   * @param errorType
   * @param displayTime
   */
  private static notify(
    notificationType: 'siam-notify' | 'dx-notify',
    error: IError | string,
    errorType?: TErrorType,
    displayTime?: number
  ): void {
    let message = '';
    let isString = true;

    if (typeof error === 'boolean') {
      // ignore boolean error
      return;
    } else if (typeof error === 'string') {
      message = error;
    } else {
      const errorDetail = error.error as IErrorDetail;
      if (errorDetail?.errors) {
        message = Object.keys(errorDetail.errors)
          .map(key => errorDetail.errors[key].join('<br>'))
          .join('<br>');
      } else {
        message = errorDetail?.detail || '';
      }
      isString = false;
    }
    switch (notificationType) {
      case 'dx-notify':
        notify(message, errorType, displayTime);
        break;

      case 'siam-notify': {
        let notifications: ISiamNotification[];
        if (isString) {
          notifications = [NotifyService.createSiamNotification(message, errorType)];
        } else {
          notifications = NotifyService.siamNotificationFromError(error as IError, errorType);
        }
        this.notifications$.next(notifications);
      }
    }
  }

  private static siamNotificationFromError(
    error: IError,
    errorType: TErrorType = 'error',
    request?: HttpRequest<unknown>
  ): ISiamNotification[] {
    const errorDetail = error.error;
    let requestDetails = '';

    if (request) {
      requestDetails = `<strong>Aufruf:</strong> ${request.method} ${request.url}<br>                        
                        <div class="notification-params"><strong>Aufrufparameter:</strong>
                        ${request.params.toString() || JSON.stringify(request.body, replacer(request.body))}</div>
                        <strong>Fehler:</strong> ${error.status} - <strong>Meldung:</strong> ${error.statusText}<br>`;
    }

    if (typeof errorDetail === 'string') {
      return [
        NotifyService.createSiamNotification(
          errorDetail,
          errorType,
          requestDetails + this.detailsToFriendlyHtml(errorDetail)
        )
      ];
    }

    if (Array.isArray(errorDetail)) {
      return errorDetail.map(detail =>
        NotifyService.createSiamNotification(
          detail.code,
          errorType,
          requestDetails + this.detailsToFriendlyHtml(detail.description)
        )
      );
    }

    if (Array.isArray(errorDetail.exceptionDetails)) {
      return errorDetail.exceptionDetails.map(detail =>
        NotifyService.createSiamNotification(
          detail.message,
          errorType,
          requestDetails + this.detailsToFriendlyHtml(detail.raw)
        )
      );
    }

    if (errorDetail.errors) {
      return Object.keys(errorDetail.errors).map(key =>
        NotifyService.createSiamNotification(
          errorDetail.title,
          errorType,
          requestDetails + this.detailsToFriendlyHtml(errorDetail.errors[key].join('\r\n'))
        )
      );
    }
    if (Array.isArray(errorDetail.messages)) {
      return errorDetail.messages.map(detail =>
        NotifyService.createSiamNotification(detail, errorType, requestDetails + this.detailsToFriendlyHtml(detail))
      );
    }

    return [
      NotifyService.createSiamNotification(
        errorDetail.title || errorDetail.message,
        errorType,
        requestDetails + this.detailsToFriendlyHtml(errorDetail.detail || errorDetail.details)
      )
    ];
  }

  private static detailsToFriendlyHtml(detail?: string): string {
    if (detail) {
      detail = detail.replaceAll('\r\n', '<br>');
    }
    return detail;
  }

  /**
   * limit and truncate a string to a given length
   *
   * @param string
   * @param count
   * @return returns the restricted string
   */

  private static limitString(string: string, count: number): string {
    return string?.slice(0, count) + (string?.length > count ? '...' : '');
  }
}
