<dx-select-box
  stylingMode="filled"
  [label]="label"
  [value]="value"
  (onValueChanged)="valueChanged($event)"
  [dataSource]="field.editorOptions.dataSource"
  [displayExpr]="field.editorOptions.displayExpr"
  [valueExpr]="field.editorOptions.valueExpr"
  [searchEnabled]="field.editorOptions.searchEnabled"
  [hint]="field.editorOptions.hint"
  [placeholder]="field.editorOptions.placeholder"
  width="100%">
  <dx-validator
    validationGroup="{{ validationGroupId }}"
    [validationRules]="field.editorOptions.validationRules"></dx-validator>
</dx-select-box>
