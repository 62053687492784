import { Component, OnChanges, Input, ViewChildren, QueryList } from '@angular/core';

export interface ISegment {
  key: string;
  value: any;
  type: undefined | string;
  description: string;
  expanded: boolean;
}

@Component({
  selector: 'app-json-list',
  templateUrl: './json-list.component.html',
  styleUrls: ['./json-list.component.scss']
})
export class JsonListComponent implements OnChanges {
  @Input() public json: any;
  @Input() public expanded = true;
  @Input() public preview = false;
  @Input() public depth = -1;
  @Input() public restoreExpanded = false;
  @Input() public showTypeHeadings = false;

  @Input() public _key: string;
  @Input() public _previouslyOpenKeys?: { [key: string]: unknown };
  @Input() public _currentDepth = -1;

  public segments: ISegment[] = [];

  @ViewChildren(JsonListComponent)
  public childrenComponents: QueryList<JsonListComponent>;

  private getOpenKeysRecursive(): any {
    const openKeys: { [key: string]: any } = {};
    this.childrenComponents.forEach(component => {
      // Save key and length
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      openKeys[component._key] = component.getOpenKeysRecursive();
    });

    if (Object.keys(openKeys).length === 0) {
      return;
    }
    return openKeys;
  }

  private openSegments(): void {
    const keys = Object.keys(this._previouslyOpenKeys);
    keys.forEach(key => {
      // Check to see if the key exists, if so expands it
      const foundSegment = this.segments.find(segment => segment.key === key);

      if (!foundSegment) {
        return;
      }

      if (!this.isExpandable(foundSegment)) {
        return;
      }

      foundSegment.expanded = true;
    });
  }

  public ngOnChanges(): void {
    // Save open keys structure before processing new json
    // Will only run in top level
    if (this.restoreExpanded && this.childrenComponents) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this._previouslyOpenKeys = this.getOpenKeysRecursive();
    }

    this.segments = [];

    // remove cycles
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.json = this.decycle(this.json);

    this._currentDepth++;

    if (typeof this.json === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(this.json).forEach(key => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.segments.push(this.parseKeyValue(key, this.json[key]));
      });
    } else {
      this.segments.push(this.parseKeyValue(`(${typeof this.json})`, this.json));
    }
    this.segments.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));
    if (!this._previouslyOpenKeys) {
      return;
    } else {
      this.openSegments();
    }
  }

  public isExpandable(segment: ISegment): boolean {
    return segment.type === 'object' || segment.type === 'array';
  }

  public toggle(segment: ISegment): void {
    if (this.isExpandable(segment)) {
      segment.expanded = !segment.expanded;
    }
  }

  private parseKeyValue(key: any, value: any): ISegment {
    const segment: ISegment = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      key: key,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value: value,
      type: undefined,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      description: '' + value,
      expanded: this.isExpanded()
    };

    switch (typeof segment.value) {
      case 'number': {
        segment.type = 'number';
        break;
      }
      case 'boolean': {
        segment.type = 'boolean';
        break;
      }
      case 'function': {
        segment.type = 'function';
        break;
      }
      case 'string': {
        segment.type = 'string';
        segment.description = '"' + segment.value + '"';
        break;
      }
      case 'undefined': {
        segment.type = 'undefined';
        segment.description = 'undefined';
        break;
      }
      case 'object': {
        // yea, null is object
        if (segment.value === null) {
          segment.type = 'null';
          segment.description = 'null';
        } else if (Array.isArray(segment.value)) {
          segment.type = 'array';
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          segment.description = 'Array[' + segment.value.length + '] ' + JSON.stringify(segment.value);
        } else if (segment.value instanceof Date) {
          segment.type = 'date';
        } else {
          segment.type = 'object';
          segment.description = 'Object ' + JSON.stringify(segment.value);
        }
        break;
      }
    }

    return segment;
  }

  private isExpanded(): boolean {
    return this.expanded && !(this.depth > -1 && this._currentDepth >= this.depth);
  }

  // https://github.com/douglascrockford/JSON-js/blob/master/cycle.js
  private decycle(object: any): any {
    const objects = new WeakMap();
    return (function derez(value: any, path: any): any {
      let oldPath;
      let nu: any;

      if (
        typeof value === 'object' &&
        value !== null &&
        !(value instanceof Boolean) &&
        !(value instanceof Date) &&
        !(value instanceof Number) &&
        !(value instanceof RegExp) &&
        !(value instanceof String)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        oldPath = objects.get(value);
        if (oldPath !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          return { $ref: oldPath };
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        objects.set(value, path);

        if (Array.isArray(value)) {
          nu = [];
          value.forEach((element, i) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-plus-operands
            nu[i] = derez(element, path + '[' + i + ']');
          });
        } else {
          nu = {};
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Object.keys(value).forEach(name => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/restrict-plus-operands
            nu[name] = derez(value[name], path + '[' + JSON.stringify(name) + ']');
          });
        }
        return nu;
      }
      return value;
    })(object, '$');
  }
}
