import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileExtensions',
})
export class FileExtensionsPipe implements PipeTransform {
  /**
   * Just return empty array if there is * inside, otherwise returns array as is
   *
   * @param source string[]
   */
  transform(source: string[]): string[] {
    if (Array.isArray(source)) {
      if (source.includes('*')) {
        return [];
      }
    }
    return source;
  }
}
