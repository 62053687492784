import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DxPopupModule,
  DxButtonModule,
  DxTemplateModule,
  DxDataGridModule,
  DxNumberBoxModule,
  DxFormModule,
  DxSelectBoxModule,
  DxCheckBoxModule,
  DxRadioGroupModule,
  DxAutocompleteModule,
  DxTextBoxModule,
  DxTabsModule,
  DxTabPanelModule,
  DxDateBoxModule,
  DxTextAreaModule,
  DxTagBoxModule,
  DxValidatorModule,
  DevExtremeModule,
  DxGanttModule
} from 'devextreme-angular';
import { locale, loadMessages } from 'devextreme/localization';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import * as messagesDe from 'devextreme/localization/messages/de.json';

registerLocaleData(localeDe, localeDeExtra);
loadMessages(messagesDe);
locale(navigator.language);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DxButtonModule,
    DxGanttModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxTemplateModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxFormModule,
    DxRadioGroupModule,
    DxTabsModule,
    DxTabPanelModule,
    DxAutocompleteModule,
    DxDateBoxModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DevExtremeModule
  ],
  exports: [
    CommonModule,
    DxButtonModule,
    DxGanttModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxTemplateModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxFormModule,
    DxRadioGroupModule,
    DxTabsModule,
    DxTabPanelModule,
    DxAutocompleteModule,
    DxDateBoxModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DevExtremeModule
  ]
})
export class DevExtremeSharedModule {}
