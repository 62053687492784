import { Pipe, PipeTransform } from '@angular/core';
import { IDocument } from '@interfaces/siam';

@Pipe({
  name: 'getRoom'
})
export class GetRoomPipe implements PipeTransform {

  transform(document: IDocument): string {
    let value = '';
    if (document?.fields?.room) {
      const radioGroup = document.template.fields.find(f => f.type === 'radiogroup' && f.name === 'room');
      if (radioGroup) {
        const radio = radioGroup.choices.find(ch => ch.value === document.fields.room.value);
        if (radio) {
          value = radio.label;
        } else {
          value = document.fields.room.value as string;
        }
      } else {
        value = document.fields.room.value as string;
      }
    }
    return value;
  }
}
