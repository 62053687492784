import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapArray'
})
export class MapArrayPipe implements PipeTransform {
  transform(input: Record<string, unknown>[], key: string): unknown[] {
    return input.map(value => value[key]);
  }
}
