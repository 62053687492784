import { Component, HostListener, Input, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { EMPTY, Observable, Subject, Subscription, of, zip } from 'rxjs';
import { catchError, filter, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HelpComponent } from '../help/help.component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { IHomePageSetting, ITemplateTypes, IUser, TApplicationMode, TPageSetting } from '@interfaces/siam';
import { siamConst } from '@interfaces/siamConst';
import * as UserFactory from '@factories/user.factory';
import { SharedataService } from '@services/sharedata.service';
import { LoginService } from '@services/login.service';
import { TemplateService } from '@services/template.service';
import { UserService } from '@services/user.service';
import { RoleService } from '@services/roles.service';
import { TagsService } from '@services/tags.service';
import { ListsService } from '@services/lists.service';
import { LoggerService } from '@services/logger.service';
import { SubstitutesComponent } from '../substitutes/substitutes.component';
import { ConfigurationService } from '@services/configuration.service';
import { NotifyService } from '@services/notify.service';
import { ItemClickEvent } from 'devextreme/ui/action_sheet';
import { NotificationsListComponent } from 'src/app/application/components/lists/notifications-list/notifications-list.component';
import { NotificationsService } from '@services/notifications.service';
import { UserNotification } from '@interfaces/userNotification';
import { isValidNotification } from '@factories/helpers';
import { ThemeService } from '@services/theme.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { DxPopupComponent } from 'devextreme-angular';
import { ShowingEvent } from 'devextreme/ui/popup';
import { SiamInfoService } from '@services/siam-info.service';

@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrls: ['./header-container.component.scss']
})
export class HeaderContainerComponent implements OnInit, OnDestroy {
  @ViewChild(UserProfileComponent) userprofilecom: UserProfileComponent;
  @ViewChild(UserSettingsComponent) usersettingscom: UserSettingsComponent;
  @ViewChild(FeedbackComponent) feedbackcom: FeedbackComponent;
  @ViewChild(NotificationsListComponent) notificationsListComponent: NotificationsListComponent;
  @ViewChild(SubstitutesComponent) substitutescom: SubstitutesComponent;
  @ViewChild(HelpComponent) helpComponent: HelpComponent;
  @ViewChild('popupGame') popupGame: DxPopupComponent;
  @Input() applicationMode: TApplicationMode = 'application';

  actionSheetVisible = false;
  adminLink = 'info';
  canCreateTemplate: boolean;
  candidates: IUser[] = [];
  creatableDocTypes: ITemplateTypes[] = [];
  buildMode: string;
  environment: string;
  gameUrl: SafeResourceUrl;
  hasCandidates = false;
  impersonatedUser: IUser;
  isAdminPanelAllowed = false;
  isCustomBackground: boolean;
  isImpersonated = false;
  isThemeGlobalOnly: boolean;
  ldapUser = false;
  showActivities: boolean;
  showUserProfile: boolean;
  showGame: boolean;
  currentUser: IUser = null;
  logonUser: IUser = null;
  svgLogo: SafeHtml;
  pngLogo: string;
  notificationsCount: number;

  homePageSetting: IHomePageSetting;

  #destroyable$ = new Subject<void>();
  #interval: NodeJS.Timeout;
  #subscription: Subscription;

  constructor(
    public sharedData: SharedataService,
    private configurationService: ConfigurationService,
    private listsService: ListsService,
    private userNotificationService: NotificationsService,
    private logger: LoggerService,
    private loginService: LoginService,
    private roleService: RoleService,
    private router: Router,
    private tagsService: TagsService,
    private templateService: TemplateService,
    private themeService: ThemeService,
    private userService: UserService,
    private window: Window,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private siamInfoService: SiamInfoService
  ) {
    this.buildMode = sharedData.environmentMode();
    this.pngLogo = siamConst.appLogoPng;
    this.getSharedData();
    this.userService
      .getImpersonationCandidates()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe({
        next: result => {
          if (result?.length) {
            this.hasCandidates = true;
            this.candidates = result;
          }
        }
      });

    this.#interval = setInterval(() => {
      if (this.#subscription) {
        this.#subscription.unsubscribe();
      }
      this.#subscription = this.loginService
        .info()
        .pipe(
          tap({
            next: () => {
              if (this.sharedData.polling$.getValue()) {
                this.sharedData.polling$.next(false);
              }
            }
          }),
          catchError(() => {
            this.logger.warn('Die Sitzung ist abgelaufen.');
            localStorage.clear();
            void this.router.navigate(['start']);
            return EMPTY;
          })
        )
        .subscribe();
    }, 8 * 60 * 1000);

    this.loginService.getCurrentUser
      .pipe(
        tap(user => {
          this.currentUser = user;
        }),
        switchMap(() => this.siamInfoService.environment()),
        switchMap(environment => {
          this.environment = environment;
          return this.userNotificationService.getNotifications();
        }),
        tap(notificationsArray => {
          const notifications: UserNotification[] = [];
          notificationsArray.forEach(notification => {
            if (isValidNotification(notification)) {
              notifications.push(notification);
            }
          });
          this.notificationsCount = notifications?.length || 0;
        }),
        switchMap(() => this.listsService.getList(siamConst.globalTagsListName)),
        tap(list => {
          if (list !== null) {
            this.tagsService.setGlobalTagsList(list);
          }
        }),
        switchMap(() =>
          this.applicationMode === 'application' ? this.templateService.getExecutableTemplates('execute') : EMPTY
        ),
        takeUntil(this.#destroyable$)
      )
      .subscribe(templates => {
        if (templates && templates.length > 0) {
          this.canCreateTemplate = true;
          const types: ITemplateTypes[] = [];
          this.tagsService.siamTags()?.forEach(tagCategory => {
            const tmpTag = templates.find(temp => temp.tags.some(t => t === tagCategory.tag));
            if (tmpTag && tagCategory.visibleCreation) {
              if (!types.find(c => c.type === tagCategory.name)) {
                types.push({
                  text: tagCategory.label,
                  type: tagCategory.name,
                  icon: tagCategory.icon
                });
              }
            }
          });
          this.creatableDocTypes = types;
        } else {
          this.canCreateTemplate = false;
        }
      });

    this.userService
      .getAllActiveUsers()
      .pipe(
        switchMap(() => this.roleService.getAllVisibleRoles()),
        takeUntil(this.#destroyable$)
      )
      .subscribe();

    this.configurationService
      .getEncryption()
      .pipe(takeUntil(this.#destroyable$))
      .subscribe(encryption => {
        this.sharedData.setEncryption(encryption);
      });

    this.sharedData.polling$.pipe(skip(1), takeUntil(this.#destroyable$)).subscribe({
      next: isPolling => {
        if (isPolling === false) {
          this.onServerConnection();
        }
      }
    });

    /* get app logo */
    this.httpClient
      .get(siamConst.appLogoSvg, { responseType: 'text' })
      .pipe(catchError(error => this.handleError(error as HttpErrorResponse)))
      .subscribe({
        next: value => {
          if (value) {
            this.svgLogo = this.sanitizer.bypassSecurityTrustHtml(value as string);
          }
        }
      });
  }

  @HostListener('document:keydown.control.alt.s') openGame(): void {
    this.showGame = true;
  }

  ngOnInit(): void {
    this.getUserData();
    zip(
      this.getBackground(),
      this.getHomePageSetting(),
      this.getTheme(),
      this.getThemeGlobalOnly(),
      this.getCustomBackground()
    )
      .pipe(takeUntil(this.#destroyable$))
      .subscribe();
    this.sanitizeUrl();
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
    if (this.#subscription) {
      this.#subscription.unsubscribe();
    }
    if (this.#interval) {
      clearInterval(this.#interval);
    }
  }

  logOut(): void {
    void this.router.navigate(['start'], { queryParams: { logout: true }, fragment: 'reload' });
  }

  onServerConnection(): void {
    NotifyService.global.success('Verbindung zum Server wiederhergestellt.');
    const body = this.window.document.querySelector('body');
    if (body) {
      body.classList.remove('no-server');
    }
  }

  onCreateProcess = (e: ItemClickEvent<ITemplateTypes>): void => {
    const navigationExtras: NavigationExtras = {
      state: {
        title: e.itemData.text,
        icon: e.itemData.icon
      }
    };
    void this.router.navigate(['', 'application', 'templates', e.itemData.type], navigationExtras);
    this.actionSheetVisible = false;
  };

  cancelUserProfile = (): void => {
    this.showUserProfile = false;
    this.userprofilecom.attachment = null;
    this.userprofilecom.userPicture = null;
  };

  saveUserProfile = (): void => {
    this.userprofilecom.updateUserProfile();
    this.showUserProfile = false;
  };

  showUserSettings(): void {
    this.usersettingscom.show();
  }

  showFeedback(): void {
    this.feedbackcom.show();
  }

  showNotifications(): void {
    this.notificationsListComponent.showNotifications();
  }

  showSubstitution(): void {
    this.substitutescom.show(this.candidates, this.impersonatedUser);
  }

  showHelp(): void {
    this.helpComponent.show();
  }

  changePassword = (): void => {
    this.userprofilecom.changePasswordMode = !this.userprofilecom.changePasswordMode;
    this.userprofilecom.initUserPassword();
  };

  setFocus(e: ShowingEvent) {
    const frame = e.component.content().parentNode.querySelector('#gameFrame');
    (frame as HTMLElement)?.focus();
  }

  private getSharedData(): void {
    this.sharedData.getIsThemeGlobalOnly.pipe(takeUntil(this.#destroyable$)).subscribe(globalOnly => {
      this.isThemeGlobalOnly = globalOnly;
    });

    this.sharedData.getIsCustomBackground.pipe(takeUntil(this.#destroyable$)).subscribe(response => {
      this.isCustomBackground = !!response;
    });

    this.sharedData.getActivitiesHiddenClass.pipe(takeUntil(this.#destroyable$)).subscribe(result => {
      this.showActivities = result;
    });
    this.sharedData.getHomePageSetting$.pipe(takeUntil(this.#destroyable$)).subscribe(result => {
      this.homePageSetting = result;
    });
  }

  private getUserData(): void {
    this.loginService.logonUser$
      .pipe(
        filter(user => !!user),
        takeUntil(this.#destroyable$)
      )
      .subscribe(logonUser => {
        this.logonUser = null;
        setTimeout(() => {
          this.logonUser = logonUser;
        }, 10);
      });
    this.loginService.getCurrentUser
      .pipe(
        filter(user => !!user),
        tap({
          next: user => {
            this.currentUser = user;
            this.sharedData.setUser(user);
            this.ldapUser = UserFactory.isLDAP(user);
            this.isAdminPanelAllowed =
              this.applicationMode !== 'administration' &&
              (UserFactory.isAdmin(user) || UserFactory.isUserHasAdminCapabilities(user));
          }
        }),

        switchMap(() => this.loginService.getImpersonatedUser),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        next: impersonated => {
          if (impersonated) {
            this.impersonatedUser = impersonated;
            this.isImpersonated = true;
          } else {
            this.impersonatedUser = null;
            this.isImpersonated = false;
          }
        }
      });
  }

  private getTheme(): Observable<string> {
    return this.themeService.getAppTheme().pipe(
      tap({
        next: theme => {
          if (theme) {
            this.sharedData.setTheme(theme);
          }
        }
      })
    );
  }

  private getBackground(): Observable<string> {
    return this.themeService.getBackground().pipe(
      tap({
        next: imageUrl => {
          if (imageUrl) {
            this.sharedData.setBackground(imageUrl);
          }
        }
      })
    );
  }

  private getHomePageSetting(): Observable<TPageSetting> {
    return this.configurationService.getJson(siamConst.homePageConfig, 'global').pipe(
      tap({
        next: data => {
          if (data) {
            this.sharedData.setHomePageSetting(data as IHomePageSetting);
          }
        }
      })
    );
  }

  private getThemeGlobalOnly(): Observable<boolean> {
    return this.configurationService.getWebAppDesignGlobalOnly().pipe(
      tap({
        next: globalOnly => {
          if (globalOnly !== null && globalOnly !== undefined) {
            this.sharedData.setisThemeGlobalOnly(globalOnly);
          }
        }
      })
    );
  }

  private getCustomBackground(): Observable<string> {
    return this.configurationService.getBackgroundImage().pipe(
      tap({
        next: response => {
          this.sharedData.setIsCustomBackground(!!response);
          this.sharedData.setCustomBackground(response);
        }
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == 404 || error.status == 400) {
      NotifyService.destroyNotifications();
      return of(false);
    }
    return EMPTY;
  }

  private sanitizeUrl() {
    const url = this.sanitizer.sanitize(SecurityContext.URL, `assets/game.html`);
    this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
