import { DocumentService } from '@services/document.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IAttachment, IDocument } from '@interfaces/siam';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'fileDownloadLink'
})
export class FileDownloadLinkPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, private documentService: DocumentService) {
  }

  /**
   * create blob from base64 and returns the safeUrl
   *
   * @param file as string
   * @param option string
   * @param currentDocument
   */
  transform(file: IAttachment, option: 'fileName' | 'url' | 'filetype' | 'blobUrl', currentDocument?: IDocument): Observable<SafeUrl> {
    const isBlob = option === 'blobUrl';
    let result: SafeUrl;
    if (file instanceof File) {
      const subj = new Subject<SafeUrl>();
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const safeUrl = this.sanitizer.bypassSecurityTrustUrl(fileReader.result as string);
        subj.next(safeUrl);
      };
      fileReader.readAsDataURL(file);
      return subj.asObservable();
    }

    if (file.data) {
      switch (option) {
        case 'fileName':
          result = file.fileName;
          break;

        case 'filetype':
          result = file.contentType;
          break;

        case 'url':
        default: {
          const byteCharacters = atob(file.data as string);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const blobUrl = URL.createObjectURL(this.getFile(new Uint8Array(byteNumbers), file, isBlob));
          result = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
        }
          break;
      }
      return of(result);
    } else {
      if (file.dataId) {
        return this.documentService.getAttachmentFromField(currentDocument.id, file.dataId).pipe(
          map(data => {
            if (data) {
              const blobUrlData = URL.createObjectURL(this.getFile(data, file, isBlob));
              result = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrlData);
            }
            return result;
          })
        );
      } else {
        return this.documentService.getAttachment(file.id).pipe(
          map(data => {
            if (data) {
              const blobUrlData = URL.createObjectURL(this.getFile(data, file, isBlob));
              result = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrlData);
            }
            return result;
          })
        );
      }
    }
  }

  /**
   * In case when need to open document in new tab, need to return blob and not file,
   * otherwise it will be displayed as binary data in new window
   *
   * @param blobParts
   * @param file
   * @param isBlob
   * @private
   */
  private getFile(blobParts: BlobPart, file: IAttachment, isBlob = false): Blob | File {
    const blob = new Blob([blobParts], { type: file.contentType });
    if (isBlob) {
      return blob;
    }
    return new File([blob], file.fileName);
  }
}
