import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ILoadPanelSettings } from '@interfaces/siam';

@Injectable({
  providedIn: 'root'
})
export class LoadPanelService {
  #message$ = new Subject<string>();
  #loading$ = new Subject<boolean>();
  #height$ = new Subject<number | string>();
  #width$ = new Subject<number | string>();

  get panel(): ILoadPanelSettings {
    return {
      message: this.#message$.asObservable(),
      loading: this.#loading$.asObservable(),
      height: this.#height$.asObservable(),
      width: this.#width$.asObservable()
    };
  }

  hide(): void {
    this.#loading$.next(false);
  }

  show(message?: string, height?: number | string, width?: number | string): void {
    if (message) {
      this.#message$.next(message);
    } else {
      this.#message$.next('Laden...');
    }

    if (height) {
      this.#height$.next(height);
    } else {
      this.#height$.next(90);
    }

    if (width) {
      this.#width$.next(width);
    } else {
      this.#width$.next(220);
    }

    this.#loading$.next(true);
  }
}
