import { Action } from '@ngrx/store';
import { IUser } from '@interfaces/siam';

export enum UserActionTypes {
  CreateUser = '[User] Create User',
  DeleteUser = '[User] Delete User',
  UpdateUser = '[User] Update User',
  SetUsers = '[User] set Users in cache'
}

export class CreateUser implements Action {
  public readonly type = UserActionTypes.CreateUser;

  constructor(public payload: { user: IUser }) {
  }
}

export class DeleteUser implements Action {
  public readonly type = UserActionTypes.DeleteUser;

  constructor(public payload: { user: IUser }) {
  }
}

export class UpdateUser implements Action {
  public readonly type = UserActionTypes.UpdateUser;

  constructor(public payload: { user: IUser }) {
  }
}

export class SetUsers implements Action {
  public readonly type = UserActionTypes.SetUsers;

  constructor(public payload: { users: IUser[] }) {
  }
}

export type UserActions = CreateUser |
  UpdateUser |
  DeleteUser |
  SetUsers;
