import { Pipe, PipeTransform } from '@angular/core';
import { isUserHasCapability } from '@factories/user.factory';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {

  transform(group: string, type: string): boolean {
    return isUserHasCapability(group, type);
  }
}
