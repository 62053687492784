import { Component, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SharedataService } from '@services/sharedata.service';
import { ThemeService } from '@services/theme.service';
import { ConfigurationService } from '@services/configuration.service';
import { IBackground, IClientConfiguration, ITheme } from '@interfaces/siam';

class Tab {
  index: number;
  text: string;
}

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  showUserSettings: boolean;
  configuration: IClientConfiguration;
  themesList: ITheme[];
  backgroundsList: IBackground[];
  globalOnly: boolean;
  selectedTheme: string;
  currentTheme: string;
  tabs: Tab[];
  isCustomBackground = true;

  #destroyable$ = new Subject<void>();

  constructor(
    private sharedData: SharedataService,
    private themeService: ThemeService,
    private configurationService: ConfigurationService
  ) {
    this.backgroundsList = this.themeService.getBackgrounds();
    this.themesList = this.themeService.getThemes();
  }

  ngOnInit(): void {
    this.configuration = this.themeService.createConfiguration();
    this.configuration.color = this.sharedData.getAccentColor();
    void this.getTheme();
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  /**
   * show popup User Settings
   */
  show(): void {
    this.showUserSettings = true;
  }

  async getTheme(): Promise<void> {
    this.tabs = [
      { index: 0, text: 'Hintergrundbild' },
      { index: 1, text: 'Theme' }
    ];

    this.sharedData.isCustomBackground$
      .pipe(takeUntil(this.#destroyable$))
      .subscribe(isCustomImage => {
        this.isCustomBackground = isCustomImage;
        if (this.isCustomBackground) {
          const backgroundTabIndex = this.tabs.findIndex(tab => tab.index === 0);
          if (backgroundTabIndex > -1) {
            this.tabs.splice(backgroundTabIndex, 1);
          }
        }
      });

    this.globalOnly = await lastValueFrom(this.sharedData.getIsThemeGlobalOnly);
    if (this.globalOnly) {
      this.tabs.pop();
    }
    this.currentTheme = await lastValueFrom(this.sharedData.getTheme);
  }

  /**
   * close popup User Settings
   */
  cancelUserSettings = (): void => {
    this.showUserSettings = false;
  };

  /**
   * save popup User Settings
   */
  saveSettings = (): void => {
    const theme = this.selectedTheme ? this.selectedTheme : this.currentTheme;
    const themeBackground = this.themeService.getThemeBackground(theme);
    this.setBackground(themeBackground)
      .pipe(
        switchMap(() => this.configurationService.setWebAppDesign(theme, 'user')),
        switchMap(() => this.themeService.getAppTheme()),
        takeUntil(this.#destroyable$)
      )
      .subscribe(result => {
        if (result) {
          this.sharedData.setTheme(result);
        }
      });
  };

  /**
   * switch theme
   */
  switchTheme(event: ITheme): void {
    if (event.cssClass) {
      this.currentTheme = event.cssClass;
      this.saveSettings();
    }
  }

  /**
   * switch background
   */
  switchBackground(event: string): void {
    if (event) {
      this.setBackground(event).pipe(takeUntil(this.#destroyable$)).subscribe();
    }
  }

  /**
   * set background
   */
  private setBackground(background: string): Observable<string> {
    return this.configurationService.setWebAppBackgroundImageName(background, 'user').pipe(
      switchMap(() => this.themeService.getBackground()),
      tap(url => {
        this.sharedData.setBackground(url);
      })
    );
  }
}
