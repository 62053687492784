import DevExpress from 'devextreme';

import Column = DevExpress.ui.dxDataGrid.Column;
import { IDocument, IIcon, IPermissionTarget, IWorkflowActionSheetItem } from './siam';
import dxForm from 'devextreme/ui/form';
import { IAgendaEditorOptions } from './fields';
import { Item as DxTreeViewItem } from 'devextreme/ui/tree_view';
import { Item as MenuItem } from 'devextreme/ui/menu';

export type TTopGridTemplateName =
  | 'index'
  | 'documentValue'
  | 'subject'
  | 'duration'
  | 'action'
  | 'protocol'
  | 'workflow-status'
  | 'speakers';
export type TTopSubmissionGridTemplateName =
  | 'documentValue'
  | 'subject'
  | 'action'
  | 'decision'
  | 'tasks'
  | 'workflow-status'
  | 'type';

export type TTopGridColumnDataType = 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | 'userRole';

export type TTopGridHeaderType = 'icon' | 'text' | 'icon-text' | 'empty';

export const TopGridHeaderTypeDataSource: { label: string; value: TTopGridHeaderType }[] = [
  { value: 'icon', label: 'Icon' },
  { value: 'text', label: 'Text' },
  { value: 'icon-text', label: 'Text Und Icon' },
  { value: 'empty', label: 'Leer' }
];

export interface ITopGridSettingsColumn extends Column {
  isTemplate?: boolean;
  columnType?: TTopGridTemplateName;
  isAutoWidth?: boolean;
  templateIconName?: string;
  durationOptions?: {
    showDuration?: boolean;
    showStartTime?: boolean;
    showEndTime?: boolean;
    step?: number;
    default?: number;
  };
  header?: {
    type?: TTopGridHeaderType;
    icon?: string;
  };
  actions?: {
    executeWorkflow: boolean;
    createProtocol: boolean;
    addSubmission: boolean;
    editSpeakers: boolean;
  };
}
export interface ITopSubmissionGridSettingsColumn extends Column {
  attachmentField?: string;
  isTemplate?: boolean;
  columnType?: TTopSubmissionGridTemplateName;
  isAutoWidth?: boolean;
  templateIconName?: string;
  showAttachment?: boolean;
  actions?: {
    executeWorkflow: boolean;
    createDecision: boolean;
    createTask: boolean;
  };
}

export interface ISubmissionFields {
  subjectField?: string;
  subField?: string;
  columns?: number;
}
export interface ITaskFields {
  subjectField?: string;
  enddateField?: string;
  speakersField?: string;
  priorityField?: string;
}
export interface IProtocolFields {
  subjectField?: string;
  bodyField?: string;
  columns?: number;
}
export type TAgendaSettingType = 'submission' | 'protocol' | 'task';
export interface IAgendaSettings {
  topColumns?: ITopGridSettingsColumn[];
  submissionFields?: ISubmissionFields;
  protocolFields?: IProtocolFields;
  taskFields?: ITaskFields;
  maxDepth?: number;
  startDateFieldName?: string;
  topSubTemplates?: {
    templateTopId?: string;
    dynamicListName?: string;
    templateProtocolId?: string;
    templateSubmissionIds?: string[];
    templateDecisionId?: string;
    templateTaskId?: string;
  };
  agendaSubTempates?: {
    templateMeetingMinutesId?: string;
    templateRapidMeetingMinutesId?: string;
  };
}

export const defaultAgendaItemField: ITopGridSettingsColumn[] = [
  {
    caption: '',
    isAutoWidth: false,
    width: '60',
    isTemplate: true,
    columnType: 'index',
    header: {
      type: 'empty'
    }
  },
  {
    caption: 'Betrifft',
    isAutoWidth: true,
    isTemplate: false,
    dataField: 'fields.subject',
    dataType: 'string',
    columnType: 'subject',
    header: {
      type: 'text'
    }
  },
  {
    caption: 'Referenten',
    isAutoWidth: true,
    isTemplate: true,
    dataType: 'object',
    columnType: 'speakers',
    header: {
      type: 'text'
    }
  },
  {
    caption: 'geplante Zeit',
    isAutoWidth: true,
    isTemplate: true,
    columnType: 'duration',
    durationOptions: {
      default: 15,
      step: 5,
      showDuration: true,
      showStartTime: true,
      showEndTime: true
    },
    header: {
      type: 'text'
    }
  },

  {
    isAutoWidth: false,
    width: '40',
    isTemplate: true,
    caption: 'Protokoll erstellt',
    columnType: 'protocol',
    templateIconName: 'check',
    header: {
      icon: '',
      type: 'icon'
    }
  },
  {
    caption: '',
    isAutoWidth: false,
    width: '60',
    isTemplate: true,
    columnType: 'action',
    actions: {
      addSubmission: true,
      createProtocol: true,
      editSpeakers: true,
      executeWorkflow: true
    },
    header: {
      type: 'empty'
    }
  }
];
export const defaultAgendaItemSubmissionField: ISubmissionFields = {
  subjectField: 'subject',
  subField: null,
  columns: 2
};
export const defaultAgendaItemProtocolField: IProtocolFields = {
  subjectField: 'subject',
  bodyField: 'body',
  columns: 2
};
export const defaultAgendaItemTaskField: ITaskFields = {
  subjectField: 'subject',
  enddateField: 'enddate',
  speakersField: 'chair',
  priorityField: 'priority'
};

export const _defaultIcons: IIcon[] = [
  { key: 'how_to_vote', value: 'Abstimmung' },
  { key: 'alarm_on', value: 'Alarm' },
  { key: 'announcement', value: 'Announcement' },
  { key: 'assignment_turned_in', value: 'Auftrag erledigt' },
  { key: 'assignment', value: 'Auftrag' },
  { key: 'account_balance', value: 'Bank' },
  { key: 'build', value: 'Bauen' },
  { key: 'description', value: 'Beschreibung' },
  { key: 'local_post_office', value: 'Brief' },
  { key: 'book', value: 'Buch' },
  { key: 'business', value: 'Business' },
  { key: 'check_circle', value: 'Check' },
  { key: 'insert_drive_file', value: 'Dokument' },
  { key: 'insert_emoticon', value: 'Emoji' },
  { key: 'euro_symbol', value: 'Euro' },
  { key: 'event', value: 'Event' },
  { key: 'favorite', value: 'Favoriten' },
  { key: 'feedback', value: 'Feedback' },
  { key: 'group', value: 'Gruppe' },
  { key: 'info', value: 'Info' },
  { key: 'report_problem', value: 'Problem' },
  { key: 'person', value: 'Person' },
  { key: 'extension', value: 'Puzzle' },
  { key: 'contact_support', value: 'Sprechblase Fragezeichen' },
  { key: 'star', value: 'Stern' },
  { key: 'event_seat', value: 'Veranstaltung' },
  { key: 'gavel', value: 'Sitzung' },
  { key: 'calendar_today', value: 'Vorlage' },
  { key: 'bookmark', value: 'Beschlussbuch' },
  { key: 'route', value: 'Route' },
  { key: 'account_tree', value: 'Hierarchiebaum' },
  { key: 'settings', value: 'Zahnrad' },
  { key: 'shopping_cart', value: 'Einkaufswagen' },
  { key: 'lightbulb', value: 'Glühbirne' },
  { key: 'rocket', value: 'Rakete' }
];

export interface INewAgendaClient {
  index?: number;
  parentDocumentId: string;
  documentId: string;
  type: TAgendaItemType;
  document?: IDocument;
  isDirectory?: boolean;
  isActive?: boolean;
  duration?: number;
  startTime?: Date;
  endTime?: Date;
  speakers?: string[];
}
export type TTopAction =
  | 'add-submission'
  | 'add-subtop'
  | 'protocol'
  | 'decision'
  | 'task'
  | 'wf-action'
  | 'delete'
  | 'reactivate'
  | 'base-action'
  | 'execute-action'
  | '';

export interface ITopAction extends MenuItem {
  value: TTopAction;
  text?: string;
  showSubMenu?: boolean;
  isLoaded?: boolean;
  items?: ITopAction[];
  action?: IWorkflowActionSheetItem;
}

export interface TreeViewItem extends DxTreeViewItem {
  id?: string;

  parentId?: string;

  position?: string;

  document?: IDocument;

  //das Originale Dokument für das Vergleich beim Schließen für Agenda
  originalDocument?: IDocument;

  type?: TAgendaItemType;

  children?: TreeViewItem[];

  submissions?: TreeViewItem[];

  submissionsItems?: TreeViewItem[];

  tasks?: TreeViewItem[];

  protocol?: TreeViewItem;

  itemTOPAction?: ITopAction[];

  itemSubmissionAction?: ITopAction[];

  duration?: number;

  startTime?: Date;

  endTime?: Date;

  speakers?: string[];

  //auf false wenn das Item ist gestrichen
  isActive?: boolean;

  //auf false wenn das Dokument nicht mehr existiert
  isValid?: boolean;
}
export type TAgendaItemType = 'top' | 'submission' | 'task' | 'top-protocol' | 'pause' | 'top-submissions';
export interface INewAgendaServer {
  position?: string;
  parentDocumentId?: string;
  documentId: string;
  type: TAgendaItemType;
  duration?: number;
  startTime?: Date;
  endTime?: Date;
  speakers?: IPermissionTarget[];
  isActive?: boolean;
  children?: INewAgendaServer[];
}

export interface IAgendaCreateAction {
  value: number;
  action: 'newSubmission' | 'newBreak' | 'newFreeTop' | 'newOldTop';
  name: string;
  icon: string;
}

export interface IEventEmitterAgenda {
  command: string;
  dataField: string;
  field: IDxFormItemTemplateAgenda;
  value: unknown;
}

export interface IDxFormItemTemplateAgenda {
  component?: dxForm;
  dataField?: string;
  editorOptions?: IAgendaEditorOptions;
  editorType?: string;
  name?: string;
}
export const convertToPlainArray = (hierarchicalArray: TreeViewItem[]): TreeViewItem[] => {
  if (!hierarchicalArray?.length) {
    return [];
  }
  const flatArray: TreeViewItem[] = [];
  hierarchicalArray.forEach(item => {
    const flattenedItem = {
      ...item,
      children: [] as TreeViewItem[]
    };

    flatArray.push(flattenedItem);

    if (item.children && item.children.length > 0) {
      const childrenFlatArray = convertToPlainArray(item.children);
      flatArray.push(...childrenFlatArray);
    }
    if (item.tasks && item.tasks.length > 0) {
      const childrenFlatArray = convertToPlainArray(item.tasks);
      flatArray.push(...childrenFlatArray);
    }
    if (item.submissions && item.submissions.length > 0) {
      const childrenFlatArray = convertToPlainArray(item.submissions);
      flatArray.push(...childrenFlatArray);
    }
    if (item.protocol) {
      flatArray.push(item.protocol);
    }
  });
  flatArray.forEach(item => {
    switch (item.type) {
      case 'top':
        item.submissions = [];
        item.protocol = null;
        item.children = [];
        break;
      case 'submission':
        item.tasks = [];
        break;

      default:
        break;
    }
  });

  return [...new Set(flatArray)].filter(item => item.type !== 'top-submissions');
};
