import { clone } from './helpers';
import { IUser } from '@interfaces/siam';
import { siamConst } from '@interfaces/siamConst';

export const copy = (value: IUser): IUser => {
  if (!value) {
    return null;
  }
  let user = clone(value);
  let userName = '';
  let jobTitle = '';
  user.compositeId = `user:${user.id}`;
  if (user.profile && user.profile.surname && user.profile.forename) {
    user.displayName = `${user.profile.surname}, ${user.profile.forename}`;
    user.selectorName = `${user.profile.surname}, ${user.profile.forename}`;
    user.fullName = `${user.profile.forename} ${user.profile.surname}`;
  } else {
    user.displayName = user.name;
    user.selectorName = user.name;
    user.fullName = user.name;
  }
  if (user.profile?.surname && !user.name.includes(user.profile?.surname)) {
    userName = `${user.name}`;
  }
  if (user.profile?.jobTitle) {
    jobTitle = `${user.profile.jobTitle}`;
  }
  if (userName || jobTitle) {
    user.selectorName = userName
      ? user.selectorName + `<span class="user-job-title">(${userName}${jobTitle ? `, ${jobTitle}` : ''})</span>`
      : user.selectorName + `<span class="user-job-title">(${jobTitle})</span>`;
  }
  if (user.substitutes?.length) {
    user.clientSubstitutes = user.substitutes.map(u => {
      switch (u.type) {
        case 'user':
          return `user:${u.targetId}`;
        case 'role':
          return `role:${u.targetId}`;
        default:
          return '';
      }
    });
  } else {
    user.clientSubstitutes = [];
    if (user?.substituteRoles?.length || user?.substituteUsers?.length) {
      user.clientSubstitutes = ([] as string[]).concat(
        user.substituteUsers?.map(u => `user:${u.id}`),
        user.substituteRoles?.map(r => `role:${r.id}`)
      );
    }
  }
  user = getAvatar(user);
  user.rolesIds = user.roles?.map(role => `role:${role.id}`) || [];
  return user;
};

export const create = (): IUser => ({
  id: null,
  name: '',
  email: '',
  roles: [],
  profile: null,
  compositeId: 'user:',
  displayName: '',
  selectorName: '',
  fullName: '',

  capabilities: [],
  confidentialLevels: [],
  effectiveCapabilities: [],

  emailConfirmed: false,
  isAdministrator: false,
  isArchived: false,
  isIntern: false,
  isPasswordChangeRequired: false,
  isLockedOut: false,
  failedLoginCount: 0,

  connectedLogins: []
});

export const storeLocally = (user: IUser): void => {
  localStorage.setItem(siamConst.currentUser, JSON.stringify(copy(user)));
  localStorage.setItem(siamConst.currentUserTimestamp, JSON.stringify(new Date()));
};

export const getCurrentUser = (): IUser => {
  try {
    return JSON.parse(localStorage.getItem(siamConst.currentUser)) as IUser;
  } catch (exception) {
    return null;
  }
};

export const getUserCapabilitiesGroup = (user?: IUser): string[] => {
  if (!user) {
    user = getCurrentUser();
  }
  if (user && Array.isArray(user.effectiveCapabilities)) {
    const types = ['create', 'update', 'delete'];
    const groups: { [key: string]: boolean } = {};
    for (const capability of user.effectiveCapabilities) {
      const values = capability.split('/');
      const group = values[2];
      const type = values[3];
      if (types.indexOf(type) !== -1) {
        groups[group] = true;
      }
    }
    return Object.keys(groups);
  }
  return [];
};

export const isUserHasCapability = (group: string, type: string, user?: IUser): boolean => {
  if (!user) {
    user = getCurrentUser();
  }
  if (isAdmin(user)) {
    return true;
  }
  if (user && Array.isArray(user.effectiveCapabilities)) {
    for (const capability of user.effectiveCapabilities) {
      const values = capability.split('/');
      const _group = values[2];
      const _type = values[3];
      if (_group.toLowerCase() === group.toLowerCase()) {
        if (_type.toLowerCase() === type.toLowerCase()) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isOutdated = (): boolean => {
  const lastUserTimestampAsString = localStorage.getItem(siamConst.currentUserTimestamp);
  if (lastUserTimestampAsString) {
    const lastUserTimestamp = new Date(JSON.parse(lastUserTimestampAsString) as string);
    const differenceMillis = new Date().getTime() - lastUserTimestamp.getTime();
    if (differenceMillis <= 60000) {
      return false;
    }
  }
  return true;
};

export const isUserHasAdminCapabilities = (user?: IUser): boolean => {
  if (!user) {
    user = getCurrentUser();
  }
  if (user && Array.isArray(user.effectiveCapabilities)) {
    const adminCapabilities = [
      '/ventuno/account/account-administrator',
      '/ventuno/capabilities/',
      '/ventuno/configuration/',
      '/siam/templates/',
      '/siam/workflows/',
      '/siam/email-templates/',
      '/siam/lists/',
      '/siam/notifications/',
      '/siam/numbers/'
    ];

    const types = ['', 'read', 'create', 'update', 'delete', 'edit', 'export', 'import', 'list'];

    for (const capability of adminCapabilities) {
      for (const type of types) {
        if (user.effectiveCapabilities.includes(`${capability}${type}`)) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isAdmin = (user?: IUser): boolean => {
  if (!user) {
    user = getCurrentUser();
  }
  if (user && Array.isArray(user.effectiveCapabilities)) {
    const adminCapabilities = ['/ventuno/account/administrator'];

    const types = ['', 'read', 'create', 'update', 'delete', 'edit', 'export', 'import', 'list'];

    for (const capability of adminCapabilities) {
      for (const type of types) {
        if (user.effectiveCapabilities.includes(`${capability}${type}`)) {
          return true;
        }
      }
    }
  }
  return false;
};

export const isAccountAdmin = (user: IUser): boolean => {
  if (user && Array.isArray(user.effectiveCapabilities)) {
    return user.effectiveCapabilities.includes('/ventuno/account/account-administrator');
  }
  return false;
};

export const isUserCapable = (capability: string, user?: IUser): boolean => {
  if (!user) {
    user = getCurrentUser();
  }

  if (user && Array.isArray(user.effectiveCapabilities)) {
    const types = ['', 'read', 'create', 'update', 'delete', 'edit', 'export', 'import', 'list'];
    for (const type of types) {
      let needle = capability;
      if (type) {
        needle += `/${type}`;
      }
      if (user.effectiveCapabilities.includes(needle)) {
        return true;
      }
    }
  }

  return false;
};

export const isLDAP = (user: IUser): boolean => {
  if (Array.isArray(user.connectedLogins)) {
    return user.connectedLogins.indexOf('ldap') !== -1;
  }
  return false;
};

export const isImpersonated = (user: IUser): boolean => !!user.impersonationId;

export const isCanPrint = (user: IUser): boolean => {
  if (user && Array.isArray(user.effectiveCapabilities)) {
    return user.effectiveCapabilities.includes('/siam/documents/print');
  }
  return false;
};

export const isCanSdrUpload = (user: IUser): boolean => {
  if (user && Array.isArray(user.effectiveCapabilities)) {
    return user.effectiveCapabilities.includes('/siam/documents/sdr-upload');
  }
  return false;
};

export const isCanCreatePattern = (user: IUser): boolean => {
  if (user && Array.isArray(user.effectiveCapabilities)) {
    return user.effectiveCapabilities.includes('/siam/documents/create-pattern');
  }
  return false;
};

export const isCanEditPattern = (user: IUser): boolean => {
  if (user && Array.isArray(user.effectiveCapabilities)) {
    return user.effectiveCapabilities.includes('/siam/documents/edit-pattern');
  }
  return false;
};

export const isCanDeletePattern = (user: IUser): boolean => {
  if (user && Array.isArray(user.effectiveCapabilities)) {
    return user.effectiveCapabilities.includes('/siam/documents/delete-pattern');
  }
  return false;
};

export const isCanSubstitutesUpdate = (user: IUser): boolean => {
  if (user && Array.isArray(user.effectiveCapabilities)) {
    return user.effectiveCapabilities.includes('/ventuno/users/substitutes-update');
  }
  return false;
};

const getAvatar = (user: IUser): IUser => {
  const profile = user.profile;
  if (profile?.picture) {
    profile.picture.displayNamePicture = user.name.charAt(0).toLocaleUpperCase();
    switch (profile.picture.originalFileName) {
      /* case 'DefaultAdminImage-01.jpg': */
      case 'DefaultUserImage-01.png':
        profile.picture.hasDefaultPicture = true;
        if (profile.forename && profile.surname) {
          profile.picture.displayNamePicture = (profile.forename.charAt(0) + profile.surname.charAt(0)).toUpperCase();
        }
        break;
      default:
        profile.picture.hasDefaultPicture = false;
    }
  }
  return user;
};
