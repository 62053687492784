<div class="task">
  <div class="task-state">
    <div class="state-symbol"><i class="material-icons md-18">check_circle</i></div>

    <div class="state-label">{{ task.statelabel }}</div>

    <div class="priority" *ngIf="task.priority === '1'">
      <div class="priority-symbol"><i class="material-icons">notification_important</i></div>
      <div class="priority-label">{{ getPriorityLabel(task.priority) }}</div>
    </div>
    <div class="chair" *ngIf="task.chairinitials">
      {{ task.chairinitials }}
      <img *ngIf="task.chairurl" class="user-avatar-name" src="{{ task.chairurl }}" />
    </div>

    <div class="task-labels">
      <app-labels-include [currentDocument]="task.document" class="labels"> </app-labels-include>
    </div>
  </div>

  <div class="task-content" (click)="select($event)">
    <div class="content-title">{{ task.subject }}</div>

    <div class="content-footer">
      <div *ngIf="task.enddate"><i class="material-icons">date_range</i> {{ task.enddate | date }}</div>
      <div class="delete">
        <i (click)="deleteTask($event)" class="material-icons">delete</i>
      </div>
    </div>
  </div>
</div>
