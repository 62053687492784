import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { DxProgressBarComponent } from 'devextreme-angular';
import { NotifyService } from '@services/notify.service';
import { DocumentService } from '@services/document.service';
import { DocumentEditorComponent } from 'src/app/application/components/dialogs/document-editor/document-editor.component';
import { custom } from 'devextreme/ui/dialog';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { GetTagPipe } from '@pipes/get-tag.pipe';
import { DocumentHelperService } from '@services/document-helper.service';
import { IConfirmDialog, IDocument, IDynamicGridSettings, IEventEmitter, ITask, TEditMode } from '@interfaces/siam';
import { LoggerService } from '@services/logger.service';
import {
  IDxEditorToolbarItem,
  IDxFormItemTemplate,
  IDxTemplateDataObject,
  IDxUIComponent
} from '@interfaces/devextreme';
import dxButton from 'devextreme/ui/button';
import { copy } from '@factories/document.factory';
import { LoadPanelService } from '@services/load-panel.service';
import { TaskDblClickEvent } from 'devextreme/ui/gantt';
import { ValueChangedEvent } from 'devextreme/ui/check_box';

interface IGanttTask {
  id: string;
  document: IDocument;
  icon?: string;
  status?: string;
  chairdata?: string;
  chairname?: string;
  progress: number;
  title: string;
  startDate: Date;
  endDate: Date;
}

const taskTag = 'app:document-type:task';

@Component({
  selector: 'app-project-include',
  templateUrl: './project-include.component.html',
  styleUrls: ['./project-include.component.scss']
})
export class ProjectIncludeComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild(DocumentEditorComponent, { static: true }) documentEditorComponent: DocumentEditorComponent;
  @ViewChild(DxProgressBarComponent) progressBar: DxProgressBarComponent;

  @Input() currentDocument: IDocument = null;
  @Input() editMode: TEditMode = 'ReadOnly';
  @Input() fieldContainer: IDxFormItemTemplate;
  @Input() isDocumentHasChanges: () => Observable<boolean>;

  @Output() eventResult = new EventEmitter<IEventEmitter<boolean>>();

  dataSource: IGanttTask[] = [];
  selectedTaskDocument: IDocument = null;
  projectProgress = 0;
  tasksCount = 0;
  tasksCompleted = 0;
  addTaskBtn: dxButton;
  hasDeletePermission = false;
  allowAddReference = false;
  allowCreateChild = false;

  containerLabel = 'Aufgabe';
  columnVisible: boolean;
  isSubmission: boolean;
  showTaskList = false;
  showGantt = false;
  freeTaskDokumentsSelected: IDocument[] = [];

  popupFreeTasksListVisible = false;
  popupFreeTaskListTitle = 'Bitte wählen Sie die gewünschte Aufgabe';

  popOverText: string;
  popoverTarget: HTMLElement;

  dynamicListButtons: IDxEditorToolbarItem[] = [];
  documentType = 'task';
  selectionMode = 'multiple';
  additionalSettings: IDynamicGridSettings = null;
  additionalSettingsFreeTasks: IDynamicGridSettings = null;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private documentService: DocumentService,
    private loadPanelService: LoadPanelService,
    private logger: LoggerService,
    private _nameDataPipe: GetNameDataPipe,
    private _tagPipe: GetTagPipe,
    private documentHelperService: DocumentHelperService
  ) {
    this.columnVisible = false;
  }

  ngOnInit(): void {
    this.isSubmission = this.documentService.isSubmission(this.currentDocument);
  }

  ngOnChanges(): void {
    this.setData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setData(): void {
    this.dynamicListButtons = [];

    this.additionalSettings = {
      includeTags: [taskTag],
      includeParentIds: [this.currentDocument.id],
      toolbar: {
        dateField: null,
        userField: null,
        isShowSearch: true,
        isShowDateFilter: false,
        isShowExportExcel: false,
        isShowGrouping: false,
        isShowPersonalMode: false,
        isShowPreviewSwitch: false,
        rowAlternationEnabled: false,
        isShowKanban: true,
        isShowTitle: false
      }
    };

    if (this.fieldContainer) {
      this.allowAddReference =
        this.currentDocument.effectivePermissions?.some(p => p === 'update') &&
        this.fieldContainer.editorOptions.allowAddReference &&
        !this.fieldContainer.editorOptions.readOnly;
      this.allowCreateChild =
        this.currentDocument.effectivePermissions?.some(p => p === 'update') &&
        this.fieldContainer.editorOptions.allowCreateChild &&
        !this.fieldContainer.editorOptions.readOnly;
      this.containerLabel = this.fieldContainer.editorOptions.label.text;
      this.hasDeletePermission =
        this.currentDocument.effectivePermissions?.some(p => p === 'update') &&
        !this.fieldContainer.editorOptions.readOnly;
      this.documentType = this.fieldContainer.editorOptions.dynamicListName || 'task';
    }
    if (this.editMode === 'Edit') {
      this.updateTaskBtnStates();
    }
    this.checkVisiblity();
    if (this.allowCreateChild) {
      this.dynamicListButtons.push({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          text: 'Aufgabe erstellen',
          stylingMode: 'contained',
          onInitialized: (args: IDxUIComponent) => {
            this.addTaskBtn = args.component as dxButton;
          },
          onClick: this.addTask
        }
      });
    }
    if (this.allowAddReference) {
      this.dynamicListButtons.push({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'custom-icon-size material-icons link',
          text: 'Aufgabe verknüpfen',
          // Referenzdokument löschen / Referenzdokument lösen
          stylingMode: 'contained',
          onClick: this.showFreeTaskList
        }
      });
    }
  }

  /* check showGantt and showTaskList */
  checkVisiblity(): void {
    if (this.fieldContainer?.editorOptions?.taskType) {
      switch (this.fieldContainer.editorOptions.taskType) {
        case 'list':
          this.showGantt = false;
          this.showTaskList = true;
          break;
        case 'gantt':
          this.showGantt = true;
          this.showTaskList = false;
          break;
        case 'both':
          this.showGantt = true;
          this.showTaskList = true;
          break;

        default:
          break;
      }
    }
  }

  updateTaskBtnStates(): void {
    if (this.addTaskBtn) {
      this.addTaskBtn.option({
        disabled: false
      });
    }
  }

  async eventDynamicListResult(event: IEventEmitter<IDocument | IDocument[]>): Promise<void> {
    if (event?.command === 'delete' && event.object) {
      this.deleteTaskDocument(event.object as IDocument);
    }
    if (event?.command === 'documents' && event.object) {
      await this.loadData(event.object as IDocument[]);
    }
  }

  eventDynamicListResultFreeTasks(event: IEventEmitter<IDocument | IDocument[]>): void {
    if (event?.command === 'selection' && event.object) {
      this.freeTaskDokumentsSelected = event.object as IDocument[];
    }
  }

  deleteTaskDocument(document: IDocument): void {
    if (document) {
      this.selectedTaskDocument = document;
      const documentSubject =
        (this.selectedTaskDocument?.fields?.subject?.value as string) ||
        this.selectedTaskDocument?.template?.caption ||
        '';

      void this.deleteConfirmDialog(documentSubject).show();
    }
  }

  confirmDeleteClose = (): void => {
    this.loadPanelService.hide();
  };

  // entgültig löschen
  confirmDeleteOk = (): void => {
    this.documentService
      .removeReference(this.selectedTaskDocument.id, this.currentDocument.id)
      .pipe(
        switchMap(() => this.documentService.delete(this.selectedTaskDocument)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: () => {
          this.eventResult.emit({
            command: 'reload',
            object: true
          });
          NotifyService.global.success('Die Aufgabe wurde endgültig gelöscht.');
        }
      });
  };

  // entfernen und parken
  confirmDeleteReference = (): void => {
    this.documentService
      .removeReference(this.selectedTaskDocument.id, this.currentDocument.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.eventResult.emit({
            command: 'reload',
            object: true
          });
          NotifyService.global.success('Die Verknüpfung wurde gelöst.');
        },
        error: (): void => {
          this.loadPanelService.hide();
        }
      });
  };
  addTask = (): void => {
    if (!this.currentDocument.id) {
      NotifyService.global.error('Bitte speichern Sie den Vorgang zuerst.');
    }
    this.isDocumentHasChanges()
      .pipe(
        switchMap(isChanged => {
          if (!this.currentDocument.id) {
            NotifyService.global.error('Bitte speichern Sie den Vorgang zuerst.');
            return Promise.resolve(false);
          }
          return isChanged ? this.confirmDialog().show() : Promise.resolve(true);
        }),
        filter(isChanged => isChanged)
      )
      .subscribe({
        next: () => {
          this.documentEditorComponent.createDocument(
            [taskTag],
            this.currentDocument,
            this.fieldContainer?.editorOptions?.taskTemplateId
          );
        }
      });
  };

  showFreeTaskList = (): void => {
    if (!this.currentDocument.id) {
      NotifyService.global.error('Bitte speichern Sie das Dokument zuerst');
      return;
    }
    this.isDocumentHasChanges()
      .pipe(
        switchMap(isChanged => {
          if (!this.currentDocument.id) {
            NotifyService.global.error('Bitte speichern Sie den Vorgang zuerst.');
            return Promise.resolve(false);
          }
          return isChanged ? this.confirmDialog().show() : Promise.resolve(true);
        }),
        filter(isChanged => isChanged)
      )
      .subscribe({
        next: () => {
          this.loadPanelService.show();
          this.popupFreeTasksListVisible = true;
          const additionalSettingsFreeTasks: IDynamicGridSettings = {
            toolbar: {
              dateField: null,
              userField: null,
              isShowSearch: true,
              isShowDateFilter: false,
              isShowExportExcel: false,
              isShowGrouping: false,
              isShowPersonalMode: false,
              isShowPreviewSwitch: false,
              rowAlternationEnabled: false,
              isShowKanban: true,
              isShowTitle: false
            }
          };
          if (this.dataSource?.length) {
            const excludeDocumentIds = this.dataSource.map(t => t.id);
            additionalSettingsFreeTasks.excludeDocumentIds = excludeDocumentIds;
          }
          // alle zuordnenbaren Vorlagen einlesen
          const taskTemplateId = this.fieldContainer?.editorOptions?.taskTemplateId;
          if (taskTemplateId?.length) {
            additionalSettingsFreeTasks.includeOneOfTemplateId = [taskTemplateId];
          } else {
            additionalSettingsFreeTasks.includeTags = [taskTag];
          }
          this.additionalSettingsFreeTasks = additionalSettingsFreeTasks;
        },
        complete: () => {
          this.loadPanelService.hide();
        },
        error: (error: string) => {
          NotifyService.global.error(error);
          this.loadPanelService.hide();
        }
      });
  };

  popupFreeTaskListOk = (): void => {
    this.popupFreeTasksListVisible = false;
    void this.setReferenceFreeTasks();
  };

  popupFreeTaskListCancel = (): void => {
    this.popupFreeTasksListVisible = false;
  };

  setReferenceFreeTasks(iterator?: IterableIterator<IDocument>): void {
    const selectedDocuments = this.freeTaskDokumentsSelected;
    if (!selectedDocuments?.length) {
      return;
    }
    if (!iterator) {
      iterator = selectedDocuments[Symbol.iterator]();
      this.loadPanelService.show();
    }

    const result = iterator.next();
    if (result.done) {
      this.loadPanelService.hide();
      this.eventResult.emit({
        command: 'reload',
        object: true
      });
      return;
    }

    const document = copy(result.value as IDocument);
    try {
      this.documentService
        .setReference(document.id, this.currentDocument.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          error: (error: string): void => {
            this.loadPanelService.hide();
            NotifyService.global.error(error);
          },
          complete: (): void => {
            void this.setReferenceFreeTasks(iterator);
          }
        });
    } catch (error) {
      this.loadPanelService.hide();
    }
  }

  dialogResult(e: IEventEmitter<ITask>): void {
    if (e.command === 'store') {
      this.documentService
        .getDocumentById(e.object.id)
        .pipe(
          mergeMap(task => this.buildTask(task)),
          switchMap(task => this.documentService.setReference(task.id, this.currentDocument.id)),
          filter(document => !!document),
          takeUntil(this.destroyed$)
        )
        .subscribe({
          next: (): void => {
            this.eventResult.emit({
              command: 'reload',
              object: true
            });
          },
          error: NotifyService.global.error
        });
    }
  }

  async loadData(documents: IDocument[]): Promise<void> {
    this.logger.debug('Lade Projektreferenzen...');

    const tasks: IGanttTask[] = [];
    this.tasksCount = 0;
    this.tasksCompleted = 0;
    for (const reference of documents) {
      if (reference.tags.some(tag => tag.toLowerCase() === taskTag)) {
        tasks.push(await this.buildTask(reference));
      }
    }
    this.dataSource = tasks;
    this.updateProgressBar();
  }

  updateProgressBar(): void {
    this.projectProgress = (this.tasksCompleted / this.tasksCount) * 100;
    if (this.progressBar) {
      this.progressBar.instance.repaint();
    }
  }

  /**
   * Calculate the Statusmessage at progressbar
   *
   * @param value: the current progressbarvalue
   */
  progressBarFormat = (value: number): string => {
    if (this.tasksCount) {
      return `Fertigstellung: ${Math.round(value * 100)}% - ${this.tasksCompleted} / ${
        this.tasksCount
      } Aufgaben erledigt`;
    } else {
      return 'Keine Aufgaben definiert.';
    }
  };

  async buildTask(document: IDocument): Promise<IGanttTask> {
    const entry: IGanttTask = {
      id: document.id,
      document,
      icon: this._tagPipe.transform(document.tags, 'icon') && this._tagPipe.transform(document.tags, 'icon').toString(),
      status: document.documentWorkflowDocument?.currentStatusLabel,
      progress: document.fields.progress?.value as number,
      title: document.fields.subject?.value as string,
      startDate: new Date(document.fields.startdate?.value as string),
      endDate: new Date(document.fields.enddate?.value as string)
    };
    const state = document.documentWorkflowDocument?.currentStatus;
    if (state === 'completed') {
      this.tasksCompleted++;
    }
    this.tasksCount++;

    if (document.fields.chair && document.fields.chair.value) {
      const _userData = await lastValueFrom(this._nameDataPipe.transform(document.fields.chair.value as string));
      entry.chairname = _userData.name;
      entry.chairdata = _userData.avatarName;
    }

    return entry;
  }

  onTaskDblClick(e: TaskDblClickEvent): void {
    e.cancel = true;
    const task = this.dataSource.find(tsk => tsk.id === (e.key as string));
    if (task) {
      this.documentHelperService.openDocument(task.document);
    }
  }

  onShowGanttColumn = (e: ValueChangedEvent): void => {
    this.columnVisible = e.value as boolean;
  };

  getPriorityDataSource(data: IDxTemplateDataObject<IGanttTask, string>): string {
    let result = '';
    const document = data?.data?.document;
    if (document && document.fields?.priority?.value) {
      const priorityField = document.template.fields.find(f => f.name === 'priority');
      if (priorityField) {
        const dataSource = priorityField.choices || [];
        if (dataSource.length) {
          const find = dataSource.find(d => d.value === data.value);
          if (find) {
            result = find.label;
          }
        }
      }
    }
    return result;
  }

  private confirmDialog = () =>
    custom({
      title: this.containerLabel,
      messageHtml:
        'Sie haben Änderungen am Dokument vorgenomen. <br> Wenn Sie diese behalten möchten speichern Sie vorher das Dokument.  <br> <br> Möchten Sie trotzdem fortfahren?',
      buttons: [
        {
          text: 'Fortfahren',
          icon: 'check',
          onClick: () => true
        },
        {
          text: 'Abbrechen',
          icon: 'clear',
          onClick: () => false
        }
      ]
    }) as IConfirmDialog;

  private deleteConfirmDialog = (subject: string) =>
    custom({
      title: `Aufgaben entfernen (${subject})`,
      messageHtml: 'Möchten Sie dieses Aufgabe endgültig löschen, oder nur die Verknüpfung lösen?',
      buttons: [
        {
          text: `Löschen`,
          icon: 'check',
          onClick: this.confirmDeleteOk
        },
        {
          text: 'Verknüpfung lösen',
          icon: 'custom-icon-size material-icons link_off',
          onClick: this.confirmDeleteReference
        },
        {
          text: 'Abbrechen',
          icon: 'clear',
          onClick: this.confirmDeleteClose
        }
      ]
    }) as IConfirmDialog;
}
