export type TValidityPeriodType = 'manual' | 'automatic:yearly';

export class NumberGroup {
  id = '';
  description = '';
  format = '';
  lastNumber = '';
  name = '';
  ranges: RangeGroup[] = [];
  validityPeriod = new ValidityPeriod();
  tags: string[] = [];
  nextNumber?: string;

  constructor(init?: Partial<NumberGroup>) {
    if (init) {
      this.id = init.id;
      this.description = init.description;
      this.format = init.format;
      this.lastNumber = init.lastNumber;
      this.name = init.name;
      this.validityPeriod = new ValidityPeriod(init.validityPeriod);

      if (Array.isArray(init.tags)) {
        this.tags = [...init.tags];
      }

      if (Array.isArray(init.ranges)) {
        this.ranges = init.ranges.map(range => new RangeGroup(range));
      }
    }
  }
}

class RangeGroup {
  type: string;
  from: number;
  to: number;
  next: number;

  constructor(init?: Partial<RangeGroup>) {
    if (init) {
      this.type = `${init.type}`;
      this.from = +init.from;
      this.to = +init.to;
      this.next = +init.next;
    }
  }
}


class ValidityPeriod {
  type: TValidityPeriodType;
  year: number;
  quarter: number;
  month: number;
  day: number;

  constructor(init?: Partial<ValidityPeriod>) {
    this.type = (this.getValue(init?.type) as TValidityPeriodType) || 'manual';
    this.year = this.getValue(init?.year) as number;
    this.quarter = this.getValue(init?.quarter) as number;
    this.month = this.getValue(init?.month) as number;
    this.day = this.getValue(init?.day) as number;
  }

  getValue(value?: unknown): unknown {
    return value || null;
  }
}


