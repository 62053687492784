import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IFeedback } from '@interfaces/siam';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private readonly apiBaseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.apiBaseUrl = `${baseUrl}api`;
  }

  sendFeedback(feedback: IFeedback): Observable<IFeedback> {
    const body = new FormData();
    body.set('subject', feedback.subject);
    body.set('body', feedback.body);
    if (typeof feedback.isHtmlBody === 'boolean') {
      body.set('isHtmlBody', feedback.isHtmlBody.toString());
    }
    if (feedback.attachments?.length) {
      try {
        feedback.attachments.forEach(attachment => {
          body.append('attachments', attachment);
        });
      } catch (error) {
        // error
      }
    }

    return this.http.post<IFeedback>(`${this.apiBaseUrl}/feedback/`, body);
  }
}
