<dx-date-box
  stylingMode="filled"
  [(value)]="value"
  [displayFormat]="field.editorOptions.displayFormat"
  [type]="field.editorOptions.type"
  [readOnly]="!isEditing"
  (onValueChanged)="valueChanged($event)">
  <dx-validator
    validationGroup="{{ validationGroupId }}"
    [validationRules]="field.editorOptions.validationRules"></dx-validator>
</dx-date-box>
