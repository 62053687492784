import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLabel'
})
export class GetLabelPipe implements PipeTransform {

  transform(tags: string[]): string | string[] {
    const tagType = `app:document-label:`;
    return tags.filter(tag => tag.startsWith(tagType))
      .map(tag => tag.split(new RegExp(`^${tagType}`))[1])[0] || null;
  }
}
