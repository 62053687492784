import { Pipe, PipeTransform } from '@angular/core';

interface ObjectWithTags {
  tags: string[];
}

@Pipe({
  name: 'findTag'
})
export class FindTagPipe implements PipeTransform {
  /**
   * If tag exists in an arry or tagArray does not exists
   *
   * @returns boolean
   */
  transform(value: ObjectWithTags | ObjectWithTags[], args?: string): boolean {
    if (Array.isArray(value)) {
      return value
        .filter(element => element.tags)
        .flatMap(element => element.tags)
        .findIndex(tag => tag === args) !== -1;
    }
    return value?.tags && value.tags.findIndex(tag => tag === args) !== -1;
  }
}
